import { MeUpdateRequest, useMeService } from '@app/api/me';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import FormButton from '@app/components/shared/FormButton';
import FormSelect from '@app/components/shared/FormSelect';
import Loading from '@app/components/shared/Loading';
import MainPaper from '@app/components/shared/MainPaper';
import SaveOnExitDialog from '@app/components/shared/SaveOnExitDialog';
import { default as yup } from '@app/config/yup-locale';
import { useSystemOfMeasurementOptions } from '@app/hooks/useSystemOfMeasurementOptions';
import languages from '@app/locale';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { handleSendEvent } from '@app/utils';
import { APP_TITLE } from '@app/utils/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const useStyles = makeStyles(() =>
  createStyles({
    sectionTitle: {
      marginTop: 30,
    },
  }),
);

interface IFormInput {
  name: string;
  email: string;
  plant: string;
  role: string;
  language: string;
  measurementSystem: SystemOfMeasurementOptions;
}

const schema = yup.object().shape({
  language: yup.string().required(),
  measurementSystem: yup.string().required()
});

const Profile = (): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState<boolean>(true);

  const {
    state: { accountInfo, userInfoObject },
    loadUserInfo,
  } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const { updateUserInfo } = useMeService();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      language: '',
      plant: '',
      role: '',
      measurementSystem: SystemOfMeasurementOptions.Metric,
    },
  });

  useEffect(() => {
    if (userInfo && accountInfo) {
      reset({
        plant: userInfo.selectedPlant.name,
        role: userInfo.userProfileName,
        language: userInfo.languageName,
        name: accountInfo.name || '',
        email: accountInfo.username,
        measurementSystem: userInfo?.measurementSystem ?? SystemOfMeasurementOptions.Metric
      });
    }
  }, [reset, userInfo, accountInfo]);

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const { language, measurementSystem } = data;

    handleSendEvent({
      category: 'Profile',
      action: 'User Saved Profile Changes.',
    });

    try {
      setLoaded(false);

      const obj: MeUpdateRequest = {
        languageName: language,
        measurementSystem
      };

      await updateUserInfo(obj);

      await loadUserInfo();

      reset(data);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
    } finally {
      setLoaded(true);
    }
  }

  const handleConfirmSaveOnExit = async (): Promise<boolean> => {
    handleSendEvent({
      category: 'Profile',
      action: 'User Saved Profile Changes on Exit.',
    });

    let successful = false;

    await handleSubmit(
      async data => {
        try {
          await onSubmit(data);
          successful = true;
        } catch (err) {
          successful = false;
        }
      },
      () => {
        Swal.fire({
          position: 'top-right',
          icon: 'error',
          title: t('error-message.title'),
          text: t('dialog-save-on-exit.validation-error.text'),
          showConfirmButton: false,
          timer: 5000,
          html: ''
        });
      },
    )();

    return successful;
  };

  const formInputsContainer: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px'
  }

  const formInputWrapper: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    maxWidth: '300px',
    minWidth: '150px'
  }

  const { systemOfMeasurementOptions } = useSystemOfMeasurementOptions();

  return (
    <>
      <Helmet>
        <title>
          {t('user.profile.title')} | {APP_TITLE}
        </title>
      </Helmet>

      <Loading dataTestId='profile-circular-progress' promiseInProgress={!loaded} />

      <SaveOnExitDialog shouldBlockNavigation={isDirty} onSave={handleConfirmSaveOnExit} />

      <MainPaper
        title={t('user.profile.title')}
        footerActions={[
          <FormButton
            key="btn-submit"
            type="submit"
            variant="primary"
            disabled={!(isValid && isDirty)}
            onClick={handleSubmit(onSubmit)}>
            {t('button.save-changes')}
          </FormButton>,
        ]}>
        <form>
          <Typography variant="h6">{t('general-information')}</Typography>

          <Box
            style={formInputsContainer}
          >
            <Box style={formInputWrapper}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    InputProps={{ ...field }}
                    autoComplete="off"
                    label={t('name')}
                    disabled
                  />
                )}
              />
            </Box>

            <Box style={formInputWrapper}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    InputProps={{ ...field }}
                    autoComplete="off"
                    label={t('e-mail')}
                    disabled
                  />
                )}
              />
            </Box>

            <Box style={formInputWrapper}>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    InputProps={{ ...field }}
                    autoComplete="off"
                    label={t('user.profile')}
                    disabled
                  />
                )}
              />
            </Box>
          </Box>

          <Typography variant="h6" className={classes.sectionTitle}>
            {t('user.profile.title.preferences')}
          </Typography>

          <Box style={formInputsContainer}>
            <Box style={formInputWrapper}>
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    fullWidth
                    id='language-select'
                    label={t('language')}
                    error={!!errors.language?.message}
                    inputProps={{...field}}
                    options={Object.keys(languages).map(language => ({ value: language, name: language }))}
                    errorMessage={errors.language?.message}
                  />
                )}
              />
            </Box>
            <Box style={formInputWrapper}>
              <Controller
                name="measurementSystem"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    fullWidth
                    id='measurement-system'
                    label={t('user.profile.select.measurement-system.label')}
                    error={!!errors.measurementSystem?.message}
                    inputProps={{...field}}
                    options={systemOfMeasurementOptions}
                    errorMessage={errors.measurementSystem?.message}
                  />
                )}
              />
            </Box>
          </Box>
        </form>
      </MainPaper>
    </>
  );
};

export default Profile;
