import { PostSteelGradeCategoryModel } from "@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes";

export const newSteelGradeInitialState: PostSteelGradeCategoryModel = {
  name: '',
  steelGrades: [{
    name: '',
    saturationMode: 'DualSaturation',
    initialSulfurPercentage: null,
    finalOxygenInSteel: null
  }],
}