import { MIN_ALLOY_CHEMISTRY_TOTAL,  MAX_ALLOY_CHEMISTRY_TOTAL} from '@app/utils/constants';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loading from '@app/components/shared/Loading';
import { useState, useEffect, useMemo } from 'react';
import { AlloysRequest, useAlloysService } from '@app/api/alloys'
import { newAlloyInitialValue } from '../additionsUtils';
import Swal from 'sweetalert2';
import { handleSendEvent } from '@app/utils';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import useLoadAlloy from '@app/hooks/useLoadAlloy';
import FormValidationWarning from '@app/components/shared/FormValidationWarning';
import FormButton from '@app/components/shared/FormButton';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@app/config/yup-locale';
import CompositionTable from '@app/components/shared/CompositionTable';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';

interface CreateEditAlloysModalProp {
  open: boolean;
  onClose: () => void;
  onSubmitChange: () => void;
  id?: number;
}

const CreateEditAlloysModal: React.FC<CreateEditAlloysModalProp> = (props: CreateEditAlloysModalProp) => {
  const { open, onClose, onSubmitChange, id } = props;
  const { t } = useTranslation();
  const { saveAlloy, updateAlloy } = useAlloysService();

  const [alloyInfoObject, loadAlloy] = useLoadAlloy();

  const { loadPlantValidations } = usePlantContext();
  const plantId = useAuthenticationContext().state.userInfoObject?.data?.selectedPlant?.id;

  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(),
    si: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    mn: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    cr: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    al: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    c: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
  });

  const methods = useForm<AlloysRequest>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {...newAlloyInitialValue},
  });

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty: formIsDirty }
  } = methods;

  const formValues = watch();

  const compositionTotalIsInvalid = useMemo(() => {
    const totalSum = (formValues.si || 0) 
      + (formValues.mn || 0) 
      + (formValues.cr || 0) 
      + (formValues.al || 0) 
      + (formValues.c || 0);
      
    return totalSum < 10 || totalSum > 100 || isNaN(totalSum);
  }, [
    formValues.al,
    formValues.c,
    formValues.cr,
    formValues.mn,
    formValues.si
  ])


  const onSubmit = async (data: AlloysRequest) => {
    handleSendEvent({
      category: 'Additions/Alloys',
      action: 'User Saved the Alloy Changes.'
    })

    try {
      setLoading(true);

      if (id) {
        await updateAlloy(id, data);
      } else {
        await saveAlloy(data);
      }

      await loadPlantValidations(plantId ?? '');
      onSubmitChange();

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      reset(newAlloyInitialValue);
      onClose();
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSendEvent({
      category: 'Additions/Alloys',
      action: id ? 'User clicked in edit alloys button' : 'User clicked in Add New alloy button',
    })
    if (id && alloyInfoObject.status === 'idle') {
      loadAlloy(id);
    }
  }, [id, alloyInfoObject.status, loadAlloy]);

  useEffect(() => {
    if(alloyInfoObject.data)
      reset({
        id: alloyInfoObject.data.id,
        name: alloyInfoObject.data.name,
        externalId: alloyInfoObject.data.externalId,
        si: alloyInfoObject.data.si,
        mn: alloyInfoObject.data.mn,
        cr: alloyInfoObject.data.cr,
        al: alloyInfoObject.data.al,
        c: alloyInfoObject.data.c,
      });
  }, [alloyInfoObject.data, reset])

  return (
    <>
      <Loading dataTestId='alloys-modal-circular-progress' promiseInProgress={loading || alloyInfoObject.status === 'loading'} />

      <Dialog
        open={open}
        maxWidth="lg"
        data-testid="create-edit-alloy-modal"
      >
        <DialogTitle
          id="add-edit-alloy-modal"
        >
          {id ? t('alloy.edit') : t('alloys.title.add')}
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box paddingBottom={2}>
              <Box paddingBottom={2}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="alloy-name"
                      label={t('name')}
                      title={t('name')}
                      inputProps={{ "data-testid": "create-edit-alloy-modal.field.name" }}
                      autoComplete="off"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Box>
              {
                !!errors?.name &&
                <WarningMessageComponent message={t('alloys.text-field.error.name')}/>
              }
              <Box paddingTop={2}>
                <Grid container>
                  <FormProvider {...methods}>
                    <CompositionTable
                      testIdPrefix='create-edit-alloy-modal'
                      unitOfMeasurement="%"
                      composition={[
                        { inputName: 'si', label: 'Si' },
                        { inputName: 'mn', label: 'Mn' },
                        { inputName: 'cr', label: 'Cr' },
                        { inputName: 'al', label: 'Al' },
                        { inputName: 'c', label: 'C' },
                      ]}
                    />
                  </FormProvider>
                </Grid>
              </Box>
              {
                compositionTotalIsInvalid && formIsDirty &&
                <Grid item xs={12}>
                  <FormValidationWarning
                      message={t('form.error.total', { min: MIN_ALLOY_CHEMISTRY_TOTAL, max: MAX_ALLOY_CHEMISTRY_TOTAL})}
                  />
                </Grid>
              }
            </Box>
          </DialogContent>

          <DialogActions>
            <FormButton
              onClick={() => {
                onClose();
                reset(newAlloyInitialValue);
              }}
              type="button"
              variant="secondary"
            >
              {t('button.cancel')}
            </FormButton>
            <FormButton
              type="submit"
              variant="primary"
              disabled={Object.keys(errors).length > 0 || compositionTotalIsInvalid}
              data-testid="create-edit-alloy-modal.form-button.save"
            >
              {id ? t('button.save') : t('button.add')}
            </FormButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default CreateEditAlloysModal;
