import { MeasurementSystemActions } from './measurementSystemActions';
import { GenericContext } from '../genericContext';
import { MeasurementSystemState, measurementSystemInitialState } from './measurementSystemState'
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';

export type MeasurementSystemDispatch = (action: MeasurementSystemAction) => void;
export const MEASUREMENT_SYSTEM_INITIAL_DISPATCH = (action: MeasurementSystemAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action.type);
};
export function measurementSystemReducer(state: MeasurementSystemState, action: MeasurementSystemAction): MeasurementSystemState {
  switch (action.type) {
    case MeasurementSystemActions.UPDATE_MEASUREMENT_SYSTEM_ACTION: {
      return {...state, selectedMeasurementSystem: action.payload ?? measurementSystemInitialState.selectedMeasurementSystem };
    }

    default: {
      throw new Error(`Unidentified action: ${(action).type}`);
    }
  }
}

type MeasurementSystemAction =
  GenericContext<MeasurementSystemActions.UPDATE_MEASUREMENT_SYSTEM_ACTION, SystemOfMeasurementOptions>;

