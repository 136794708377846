import { AlloysResponse, useAlloysService } from '@app/api/alloys';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import { DeleteButton, EditButton } from '@app/components/shared/CustomIconButton';
import Loading from '@app/components/shared/Loading';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import { APP_TITLE, PAGINATION, PERMISSIONS } from '@app/utils/constants';
import TableNoResults from '@app/components/shared/TableNoResults';
import { withStyles } from "@material-ui/core/styles";
import MuiTableHead from "@material-ui/core/TableHead";

import {
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { handleSendEvent } from '@app/utils';
import useScrollTop from '@app/hooks/useScrollTop';
import { ListInfoObject } from '@app/hooks/types';

export interface AlloysTabProps {
  handleEdit: (id: number) => void,
  alloysInfoObject: ListInfoObject<AlloysResponse>,
  loadAlloys: () => void;
}

const AlloysList = (props: AlloysTabProps): React.ReactElement => {
  const { t } = useTranslation();

  const { loadPlantValidations } = usePlantContext();
  const { hasAccess } = useAuthenticationContext();
  const plantId = useAuthenticationContext().state.userInfoObject?.data?.selectedPlant?.id;
  const { deleteAlloy } = useAlloysService();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE_EXTENDED);

  const [loaded, setLoaded] = useState<boolean>(true);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState<AlloysResponse | undefined>(undefined);

  const { handleEdit, alloysInfoObject, loadAlloys } = props;
  const { data, status: alloysStatus } = alloysInfoObject;

  const TableHead = withStyles(() => ({
    root: {
      background: '#f1f1f1'
    }
  }))(MuiTableHead);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  useEffect(() => {
    if(alloysStatus === 'idle')
      loadAlloys();
  }, [alloysStatus, loadAlloys]);


  const onClickDelete = (alloy: AlloysResponse) => {
    handleSendEvent({
      category: 'Additions/Alloys',
      action: 'User Opened Delete Alloy Modal.'
    });

    setDeleteProduct(alloy);
    setOpenDeleteModal(true);
  };

  const onSubmitDelete = async () => {

    handleSendEvent({
      category: 'Additions/Alloys',
      action: 'User Deleted an Alloy.'
    });

    if (!deleteProduct) return;

    try {
      setLoaded(false);

      await deleteAlloy(Number(deleteProduct.id));
      await loadPlantValidations(plantId ?? '');

      setOpenDeleteModal(false);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('exclusion-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setPage(0);
      loadAlloys();
    } finally {
      setLoaded(true);
    }
  };

  const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop<HTMLDivElement>();

  return (
    <>
      <Helmet>
        <title>
          {t('alloys')} | {APP_TITLE}
        </title>
      </Helmet>

      <Loading dataTestId='alloys-tab-circular-progress' promiseInProgress={!loaded || alloysStatus === 'loading'} />

      <ConfirmDialog
        visible={openDeleteModal}
        setVisible={setOpenDeleteModal}
        title={t('alloys.dialog.title.remove', { name: deleteProduct?.name })}
        content={t('data.exclusion.message')}
        cancelText={t('button.cancel')}
        cancelAction={() => {
          handleSendEvent({
            category: 'Additions/Alloys',
            action: 'User Closed Delete Alloy Modal.'
          });
          setOpenDeleteModal(false);
        }}
        confirmText={t('button.delete')}
        confirmAction={onSubmitDelete}
        cancelButtonTestId="delete-alloy-dialog.cancel-button"
        confirmButtonTestId="delete-alloy-dialog.confirm-button"
      />

      <Paper style={{ maxHeight: '96%', overflow: 'auto' }}>
        <TableContainer style={{ maxHeight: 'calc(100vh - 334px)' }} innerRef={tableContainerRef}>
          <Table stickyHeader size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {t('name')}
                </TableCell>
                <TableCell align="left">
                  Si
                </TableCell>
                <TableCell align="left">
                  Mn
                </TableCell>
                <TableCell align="left">
                  Cr
                </TableCell>
                <TableCell align="left">
                  Al
                </TableCell>
                <TableCell align="left">
                  C
                </TableCell>
                <TableCell align="center" width="120" style={{ minWidth: 120 }}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody data-testid="alloys-list-table-body">
              {data.length ? (
                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                  <TableRow key={i} data-testid={`alloys-list-item-${row.name}-${row.id}`}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.si.toFixed(2)}</TableCell>
                    <TableCell align="left">{row.mn.toFixed(2)}</TableCell>
                    <TableCell align="left">{row.cr.toFixed(2)}</TableCell>
                    <TableCell align="left">{row.al.toFixed(2)}</TableCell>
                    <TableCell align="left">{row.c.toFixed(2)}</TableCell>
                    <TableCell className="table-actions" align="right">
                      {hasAccess([PERMISSIONS.ALLOYS_EDIT]) && (
                        <EditButton
                          data-testid={`alloy-list-item-${row.name}-${row.id}-edit-button`}
                          onClick={() => handleEdit(Number(row.id))}
                        />
                      )}
                      {hasAccess([PERMISSIONS.ALLOYS_DELETE]) && (
                        <DeleteButton
                          data-testid={`alloy-list-item-${row.name}-${row.id}-delete-button`}
                          onClick={() => onClickDelete(row)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow data-testid="no-alloys-to-display-warning">
                  <TableCell colSpan={12}>
                    <TableNoResults firstText='alloys.empty-table-first-text' secondText='alloys.empty-table-second-text' />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            scrollTop();
          }}
          onRowsPerPageChange={e => {
            setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE_EXTENDED));
          }}
          labelRowsPerPage={t('rows-per-page')}
          backIconButtonText={t('page-previous')}
          nextIconButtonText={t('page-next')}
        />
      </Paper>
    </>
  );
};

export default AlloysList;
