import { SystemOfMeasurementOptions } from "@app/api/me/MeGetResponse";
import FormInputEndAdornment from "@app/components/shared/FormInputEndAdornment";
import { UOM_CELSIUS_SYMBOL, UOM_FAHRENHEIT_SYMBOL, UOM_INCHES_SYMBOL, UOM_KILOGRAMS_SYMBOL } from "@app/utils/constants";

const useRenderInputEndAdornment = () => {

    const renderTemperatureInputEndAdornment = (measurementSystem: SystemOfMeasurementOptions | undefined, disabled?: boolean) => (
      <FormInputEndAdornment
        disabled={disabled}
        measurementSystem={measurementSystem as SystemOfMeasurementOptions}
        imperialUnitOfMeasurement={UOM_FAHRENHEIT_SYMBOL}
        metricUnitOfMeasurement={UOM_CELSIUS_SYMBOL}
      />
    )

    const renderWeightInputEndAdornment = (measurementSystem: SystemOfMeasurementOptions | undefined, imperialUnitOfMeasurement: 'lb' | 'st', disabled?: boolean) => (
      <FormInputEndAdornment 
        disabled={disabled}
        measurementSystem={measurementSystem as SystemOfMeasurementOptions} 
        imperialUnitOfMeasurement={imperialUnitOfMeasurement} 
        metricUnitOfMeasurement={UOM_KILOGRAMS_SYMBOL} 
      />
    )
    
    const renderLengthInputEndAdornment = (measurementSystem: SystemOfMeasurementOptions | undefined, metricUnitOfMeasurement: 'm' | 'mm' | 'cm', disabled?: boolean) => (
      <FormInputEndAdornment 
        disabled={disabled}
        measurementSystem={measurementSystem as SystemOfMeasurementOptions} 
        imperialUnitOfMeasurement={UOM_INCHES_SYMBOL} 
        metricUnitOfMeasurement={metricUnitOfMeasurement} 
      />
    )

    return ({
      renderTemperatureInputEndAdornment,
      renderWeightInputEndAdornment,
      renderLengthInputEndAdornment
    })
}

export default useRenderInputEndAdornment;