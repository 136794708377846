import { useFluxesService } from '@app/api/fluxes';
import { FluxModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { useState } from 'react';
import { InfoObject } from "./types"

const infoObjectInitialState: InfoObject<FluxModel> = {
  status: 'idle',
  data: undefined,
}

const useLoadFlux = (): [
  InfoObject<FluxModel>,
  (id: number) => Promise<void>,
] => {

  const { getFlux } = useFluxesService();

  const [infoObject, setInfoObject] = useState<InfoObject<FluxModel>>(infoObjectInitialState);

  const loadFlux = async (id: number) => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getFlux(id);
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {

      setInfoObject({data: undefined, status: 'error'});
    }
  };


  return [infoObject, loadFlux];
}

export default useLoadFlux;