import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const Loading = (props: Props) => {
  const { promiseInProgress = false, dataTestId = "circular-progress" } = props;
  const classes = useStyles()();
  const { backdrop,  circularProgress } = classes;
  return (
    <Box 
      className={backdrop} 
      style={promiseInProgress ? { display: 'flex' } : { display: 'none' }}
    >
      <CircularProgress 
        className={circularProgress} 
        data-testid={dataTestId}
      />
    </Box>
  );
};

interface Props {
  promiseInProgress: boolean;
  dataTestId: string;
}

const useStyles = () =>
  makeStyles(() => {
    return {
      backdrop: {
        zIndex: 1400,
        color: '#ffffff',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0, 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed'
      },
      circularProgress: {
        color: '#ffffff',
      },
    };
  });

export default Loading;
