import { LimitsDTO } from '@app/api/limits/limitsTypes';
import { MeGetResponse } from '@app/api/me';
import { InfoObject } from '@app/hooks/types';
import { AccountInfo } from '@azure/msal-browser';

const userInfoObjectInitialState: InfoObject<MeGetResponse> = {
  data: undefined,
  status: 'idle'
}

const limitsInfoObjectInitialState: InfoObject<LimitsDTO> = {
  data: undefined,
  status: 'idle'
}
export interface AuthenticationState {
  accountInfo?: AccountInfo;
  userInfoObject?: InfoObject<MeGetResponse>;
  limitsInfoObject?: InfoObject<LimitsDTO>;
  initialLoadComplete: boolean;
}

export const AUTHENTICATION_INITIAL_STATE: AuthenticationState = {
  initialLoadComplete: false,
  limitsInfoObject: limitsInfoObjectInitialState,
  userInfoObject: userInfoObjectInitialState,
};
