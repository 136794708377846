import { CarryoverSlagReferenceCreateRequest, CarryoverSlagReferenceGetResponse, useCarryoverSlagReferencesService } from '@app/api/carryoverSlagReferences';
import { usePlantsService } from '@app/api/plants';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import Loading from '@app/components/shared/Loading';
import MainPaper from '@app/components/shared/MainPaper';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext';
import CarryoverSlagUnitOfMeasurement, { UNITS_OF_MEASUREMENT } from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { handleSendEvent, ROUTES_PATH } from '@app/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { GeneralSettings, getReferenceValueLabel, mapValuesToApi, newCarryoverSlagInitialValue } from '../carryoverSlagsUtils';
import CarryoverSlagsTable from './CarryoverSlagsTable';
import CarryoverSlagInferenceModal from './modals/CarryoverSlagInferenceModal';
import CreateEditCarryoverSlagsModal from './modals/CreateEditCarryoverSlagsModal';
import { PERMISSIONS } from '@app/utils/constants';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import useLoadCarryoverSlags from '@app/hooks/useLoadCarryoverSlags';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import useLoadSteelGradeCategories from '@app/hooks/useLoadSteelGradeCategories';
import { FormSelectOptions } from '@app/components/shared/FormSelect';
import { CarryoverSlagType } from '@app/api/heats/HeatsResponse';
import FormButton from '@app/components/shared/FormButton';

const CarryoverSlagsContent = (): React.ReactElement => {
  const { t } = useTranslation();

  const { state: plantState, loadPlantValidations } = usePlantContext();

  const {
    state: { userInfoObject },
    loadUserInfo,
    hasAccess
  } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const plantId = userInfo?.selectedPlant?.id;

  const { deleteCarryoverSlagReference, getCarryoverSlagReference, saveCarryoverSlagReference, updateCarryoverSlagReference } = useCarryoverSlagReferencesService();
  const { updatePlantCarryoverSlagSettings } = usePlantsService();

  const [loaded, setLoaded] = useState<boolean>(true);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState<CarryoverSlagReferenceGetResponse | undefined>(undefined);

  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const editModalInitialState = { open: false, edit: { id: 0, item: newCarryoverSlagInitialValue } };
  const [openEditModal, setOpenEditModal] = useState(editModalInitialState);

  const [
    infoObject,
    loadCarryoverSlags,
    setOpenGeneralSettingsModal,
    setGeneralSettings
  ] = useLoadCarryoverSlags();

  const {
    data: carryoverSlags,
    generalSettings,
    openGeneralSettingsModal,
    status: carryoverSlagsStatus
  } = infoObject;

  useEffect(() => {
    if(carryoverSlagsStatus === 'idle') {
      loadCarryoverSlags();
    }
  }, [loadCarryoverSlags, carryoverSlagsStatus]);

  const [{ data: steelGradeCategories, status: steelGradeCategoriesStatus }, loadSteelGradeCategories] = useLoadSteelGradeCategories();
  const [steelGradeCategoriesOptions, setSteelGradeCategoriesOptions] = useState<FormSelectOptions<number>>([])

  useEffect(() => {
    if(carryoverSlagsStatus === 'succeeded' && steelGradeCategoriesStatus === 'idle') {
      loadSteelGradeCategories();
    }
  }, [
    carryoverSlagsStatus,
    loadSteelGradeCategories,
    steelGradeCategoriesStatus
  ]);

  useEffect(() => {
    if(steelGradeCategoriesStatus === 'succeeded') {
      setSteelGradeCategoriesOptions(
        steelGradeCategories?.map(
          steelGrade => ({
            name: steelGrade.name,
            value: steelGrade.id,
            disabled: carryoverSlags.some(item => item.steelGradeCategoryId === steelGrade.id)
          })
        ) ?? []
      )
    }
  }, [steelGradeCategories, carryoverSlags, steelGradeCategoriesStatus])

  const onSubmitDelete = async () => {
    handleSendEvent({
      category: 'BasicSettings/CarryoverSlag',
      action: 'User Deleted an CarryoverSlag.',
    });

    if (!deleteProduct) return;

    try {
      setLoaded(false);

      await deleteCarryoverSlagReference(deleteProduct.id);
      await loadPlantValidations(plantId ?? '');

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('exclusion-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      loadCarryoverSlags();
      setLoaded(true);
      setOpenDeleteModal(false);
    } catch (err) {

      setLoaded(true);
    }
  };

  const handleSelectEditCarryoverSlag = async (id: number) => {
    setLoaded(false);
    const carryoverSlag = await getCarryoverSlagReference(id);
    setLoaded(true);
    setOpenEditModal({ open: true, edit: { id, item: carryoverSlag } });
  }

  const handleChangeGeneralSettings = async (request: GeneralSettings) => {
    try {
      setLoaded(false);
      await updatePlantCarryoverSlagSettings({
        carryoverSlagParameterValueName: request.carryoverSlagParameterValueName || '',
        carryoverSlagReferenceInput: request.carryoverSlagParameterValueName || '',
        carryoverSlagUnitOfMeasurement: request.carryoverSlagUnitOfMeasurement as CarryoverSlagUnitOfMeasurement,
        carryoverSlagInferenceType: request.carryoverSlagInferenceType as CarryoverSlagInferenceType,
        carryoverSlagType: request.carryoverSlagType as CarryoverSlagType,
        minimumSlagCarryoverWeight: Number(request.minimumSlagCarryoverWeight),
      });
      await loadPlantValidations(plantId ?? '');
      await loadUserInfo();
      await loadCarryoverSlags();
      setGeneralSettings(request);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setOpenGeneralSettingsModal(false);
    } finally {
      setLoaded(true);
    }
  }

  const handleAddNewCarryoverSlag = async (request: CarryoverSlagReferenceCreateRequest) => {
    try {
      setLoaded(false);
      await saveCarryoverSlagReference(
        mapValuesToApi(request, userInfo?.selectedPlant?.carryoverSlagInferenceType as CarryoverSlagInferenceType)
      );
      await loadCarryoverSlags();
      await loadPlantValidations(plantId ?? '');
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setOpenAddNewModal(false);
    } finally {
      setLoaded(true);
    }
  }

  const handleUpdateCarryoverSlag = async (request: CarryoverSlagReferenceCreateRequest) => {
    try {
      setLoaded(false);
      await updateCarryoverSlagReference(
        openEditModal.edit.id,
        mapValuesToApi(request, userInfo?.selectedPlant?.carryoverSlagInferenceType as CarryoverSlagInferenceType)
      );
      await loadCarryoverSlags();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setOpenEditModal(editModalInitialState);
    } finally {
      setLoaded(true);
    }
  }

  const isLoading = carryoverSlagsStatus === 'loading' || steelGradeCategoriesStatus === 'loading' || !loaded;

  return (
    <>
      <Loading dataTestId='carryover-slag-content-circular-progress' promiseInProgress={isLoading} />

      {loaded && carryoverSlagsStatus === 'succeeded' && <CarryoverSlagInferenceModal
        generalSettigs={generalSettings}
        onClose={() => setOpenGeneralSettingsModal(false)}
        onConfirm={handleChangeGeneralSettings}
        open={openGeneralSettingsModal}
        hasNoCarryoverSlags={carryoverSlags?.length === 0}
      />}

      <CreateEditCarryoverSlagsModal
        initialValue={newCarryoverSlagInitialValue}
        onClose={() => setOpenAddNewModal(false)}
        onConfirm={handleAddNewCarryoverSlag}
        open={openAddNewModal}
        steelGradeCategoriesOptions={steelGradeCategoriesOptions}
      />

      <CreateEditCarryoverSlagsModal
        initialValue={openEditModal.edit.item}
        onClose={() => setOpenEditModal({ open: false, edit: { id: 0, item: newCarryoverSlagInitialValue } })}
        onConfirm={handleUpdateCarryoverSlag}
        open={openEditModal.open}
        edit={openEditModal.edit.id}
        steelGradeCategoriesOptions={steelGradeCategoriesOptions}
      />

      <ConfirmDialog
        dialogTestId='delete-carryover-slag-confirmation-modal'
        visible={openDeleteModal}
        setVisible={setOpenDeleteModal}
        title={t('carryover-slag.dialog.title.remove', {
          name: userInfo?.selectedPlant.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
            ? steelGradeCategories.find(sgc => sgc.id === deleteProduct?.steelGradeCategoryId)?.name
            : getReferenceValueLabel(
              userInfo?.selectedPlant?.carryoverSlagInferenceType === CarryoverSlagInferenceType.Range,
              deleteProduct,
          ),
        })}
        content={t('carryover-slag.dialog.content', {
          ref: t(userInfo?.selectedPlant?.carryoverSlagParameterValueName as string),
          name: userInfo?.selectedPlant.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
            ? steelGradeCategories.find(sgc => sgc.id === deleteProduct?.steelGradeCategoryId)?.name
            : getReferenceValueLabel(
              userInfo?.selectedPlant?.carryoverSlagInferenceType === CarryoverSlagInferenceType.Range,
              deleteProduct,
          ),
          uom: userInfo?.selectedPlant.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
            ? ''
            : ' ' + t(UNITS_OF_MEASUREMENT[userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement ?? CarryoverSlagUnitOfMeasurement.Points]),
        })}
        cancelText={t('button.cancel')}
        cancelButtonTestId="delete-carryover-slag-confirmation-modal.cancel-button"
        cancelAction={() => {
          handleSendEvent({
            category: 'BasicSettings/CarryoverSlag',
            action: 'User Closed Delete CarryoverSlag Modal.',
          });
          setOpenDeleteModal(false);
        }}
        confirmText={t('button.delete')}
        confirmButtonTestId="delete-carryover-slag-confirmation-modal.confirm-button"
        confirmAction={onSubmitDelete}
      />

      <MainPaper
        removePadding
        title={t('carryover-slags')}
        headerActions={[
          <FormButton
            data-testid="edit-carryoverslags-generalsettings-modal-button"
            variant="secondary"
            key='buttom-edit-general-settings-carryover-slag'
            onClick={() => {
              setOpenGeneralSettingsModal(true);
              handleSendEvent({
                category: 'BasicSettings/CarryoverSlag',
                action: 'User Clicked to Change Carryover Slag General Settings.',
              });
            }}
            disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_DETAIL,PERMISSIONS.PROCESS_CONSTRAINTS_LIST])}
          >
            {t('general-settings')}
          </FormButton>,
          <FormButton
            data-testid="add-carryoverslags-button"
            variant="primary"
            key='buttom-add-new-carryover-slag'
            onClick={() => {
              handleSendEvent({
                category: 'BasicSettings/CarryoverSlag',
                action: 'User Clicked to Create a New CarryoverSlag.',
              });
              setOpenAddNewModal(true)
            }}
            disabled={
              !hasAccess([PERMISSIONS.CARRYOVER_SLAG_CREATE])
              || Object.values({
              ...generalSettings.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
              ? {
                carryoverSlagInferenceType: generalSettings.carryoverSlagInferenceType,
                carryoverSlagParameterValueName: generalSettings.carryoverSlagParameterValueName,
                carryoverSlagReferenceInput: generalSettings.carryoverSlagReferenceInput,
                carryoverSlagType: generalSettings.carryoverSlagType,
                minimumSlagCarryoverWeight: generalSettings.minimumSlagCarryoverWeight,
              } : generalSettings}).some(ngs => ngs === null)
            }
          >
            {t('button.add-new')}
          </FormButton>
        ]}>
        {
          (
            !plantState.data.general
          ) && (
            <WarningMessageComponent
              withPadding
              testId="carryoverslags.plant-validation.warning"
              message={t('overview.empty-message')}
              link={{
                label: t('overview.view'),
                url: ROUTES_PATH.PLANT_OVERVIEW,
              }}
            />
          )
        }
        <CarryoverSlagsTable
          data={carryoverSlags}
          setDeleteProduct={(item) => {
            setDeleteProduct(item);
            setOpenDeleteModal(true);
          }}
          setEditCarryoverSlag={handleSelectEditCarryoverSlag}
          userInfo={userInfo}
        />
      </MainPaper>
    </>
  );
};

export default CarryoverSlagsContent;
