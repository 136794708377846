import { PlantStatus } from '@app/api/plants/PlantsGetResponse';
import FormButton from '@app/components/shared/FormButton';
import MainPaper from '@app/components/shared/MainPaper';
import yup from '@app/config/yup-locale';
import useLoadPlantById from '@app/hooks/useLoadPlantById';
import { ROUTES_PATH, handleSendEvent } from '@app/utils';
import { APP_TITLE } from '@app/utils/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardEventHandler, ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PlantManagementDetailsContent from './PlantManagementDetailsContent';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import { usePlantsService } from '@app/api/plants';
import { DateTimeUtils } from '@app/utils/dateTimeUtils';
import { StringUtils } from '@app/utils/stringUtils';
import { Customer } from '@app/api/customers';
import { PlantUpdateRequest } from '@app/api/plants/PlantUpdateRequest';
import Loading from '@app/components/shared/Loading';
import Swal from 'sweetalert2';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';

const GA_EVENT_CATEGORY = 'Users/AdminPanel';

export interface IPlantDetailsForm {
  name: string,
  description: string,
  customer?: Customer,
  status: PlantStatus,
  trialStart?: Date,
  trialExpiration?: Date,
  trialExpirationEnabled: boolean,
  contractStart?: Date,
  contractExpiration?: Date,
  contractExpirationEnabled: boolean,
  applicationRegistrationId?: string,
  applicationRegistrationIdEnabled: boolean,
  emailDomains: string[],
}

const createPlantDetailsFormShape = () => {
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().nullable(),
    customer: yup.object().required(),
    status: yup.string().required(),
    trialExpirationEnabled: yup.boolean(),
    trialStart: yup.date().nullable(),
    trialExpiration: yup.date().when('trialExpirationEnabled', {
      is: (value: boolean) => value,
      then: yup.date().required(),
      otherwise: yup.date().nullable(),
    }),
    contractExpirationEnabled: yup.boolean(),
    contractStart: yup.date().nullable(),
    contractExpiration: yup.date().when('contractExpirationEnabled', {
      is: (value: boolean) => value,
      then: yup.date().required(),
      otherwise: yup.date().nullable(),
    }),
    applicationRegistrationIdEnabled: yup.boolean(),
    applicationRegistrationId: yup.string().when('applicationRegistrationIdEnabled', {
      is: (value: boolean) => value,
      then: yup.string().uuid().required(),
      otherwise: yup.string().nullable(),
    }),
    emailDomains: yup.array().of(yup.string().required()).required(),
  });

  return schema;
}

const PlantManagementDetailsContainer = (): ReactElement => {
  const { id: plantId } = useParams<{ id: string }>();
  const { state: routeState } = useLocation<{ readOnly: boolean }>();
  const { t } = useTranslation();
  const { loadUserInfo } = useAuthenticationContext();
  const history = useHistory();
  const methods = useForm<IPlantDetailsForm>({
    resolver: yupResolver(createPlantDetailsFormShape()),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      customer: {},
      trialExpirationEnabled: false,
      contractExpirationEnabled: false,
      applicationRegistrationIdEnabled: false,
      emailDomains: []
    }
  });
  const { reset, handleSubmit, formState: { isValid: formIsValid } } = methods;
  
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [changedPlant, setChangedPlant] = useState<IPlantDetailsForm>();
  const [loading, setLoading] = useState(false);

  const [detailsInfoObject, activitiesInfoObject, loadPlantById] = useLoadPlantById();
  const { updatePlant } = usePlantsService();
  const { data: plant, status: plantStatus } = detailsInfoObject;
  const { data: activities } = activitiesInfoObject;
  
  useEffect(() => {
    if (plantStatus === 'idle') {
      loadPlantById(plantId);
    }
  }, [plantId, plantStatus, loadPlantById]);

  useEffect(() => {
    if (plantStatus === 'succeeded' && plant) {
      reset({
        name: plant.name,
        description: plant.description,
        customer: {
          id: plant.customerId,
          name: plant.customerName
        },
        status: plant.plantStatus,
        trialStart: DateTimeUtils.parseISO(plant.trialStart),
        trialExpiration: DateTimeUtils.parseISO(plant.trialExpiration),
        trialExpirationEnabled: plant.trialExpiration ? true : false,
        contractStart: DateTimeUtils.parseISO(plant.contractStart),
        contractExpiration: DateTimeUtils.parseISO(plant.contractExpiration),
        contractExpirationEnabled: plant.contractExpiration ? true : false,
        applicationRegistrationId: plant.applicationRegistrationId,
        applicationRegistrationIdEnabled: plant.applicationRegistrationId ? true : false,
        emailDomains: plant.emailDomains,
      })
    }
  }, [plantStatus, plant, reset]);

  const preventSubmitFromEnterKey = (): KeyboardEventHandler<HTMLFormElement> | undefined => {
    return (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };
  }

  const onSubmit: SubmitHandler<IPlantDetailsForm> = (data, event) => {
    event?.preventDefault();

    if (event?.target.id !== 'plant-management-details-container-form') {
      return;
    }

    setChangedPlant(data);
    setOpenConfirmationModal(true);
  }

  const onCancelConfirmationModal = () => {
    handleSendEvent({
      category: GA_EVENT_CATEGORY,
      action: 'User Closed Update Plant Confirmation Modal.'
    });
    setOpenConfirmationModal(false);
  }

  const onConfirmConfirmationModal = async (confirmationReason?: string) => {
    handleSendEvent({
      category: GA_EVENT_CATEGORY,
      action: 'User Updated a Plant.',
    });

    if (!changedPlant || !confirmationReason) {      
      return;
    }

    const request: PlantUpdateRequest = {
      name: changedPlant.name,
      description: changedPlant.description,
      reason: confirmationReason,
      plantStatus: changedPlant.status,
      emailDomains: changedPlant.emailDomains,
      applicationRegistrationId: StringUtils.undefinedIfEmpty(changedPlant.applicationRegistrationId),
      trialStart: DateTimeUtils.formatISO(changedPlant.trialStart),
      trialExpiration: DateTimeUtils.formatISO(changedPlant.trialExpiration),
      contractStart: DateTimeUtils.formatISO(changedPlant.contractStart),
      contractExpiration: DateTimeUtils.formatISO(changedPlant.contractExpiration),
    };

    if (changedPlant.customer && changedPlant.customer.id != 0) {
      request.customerId = changedPlant.customer.id;
      request.customerName = undefined;
    } else if (changedPlant.customer) {
      request.customerId = undefined;
      request.customerName = changedPlant.customer.name;
    }

    try {
      setLoading(true);
    
      await updatePlant(plantId, request);
      await loadPlantById(plantId);
  
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('admin-panel.plant-updated.notification'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
  
      setOpenConfirmationModal(false);
      loadUserInfo();
    } finally {
      setLoading(false);
    }
  }

  const renderActions = (): JSX.Element[] => {
    const actions = [
      <FormButton
        key='go-back-to-list-button'
        variant='link'
        type='button'
        onClick={() => history.push(ROUTES_PATH.USERS_PLANT_MANAGEMENT)}>
        {t('admin-panel.go.back.to.list')}
      </FormButton>
    ];

    if (routeState && !routeState.readOnly) {
      actions.push(
        <FormButton
          key='reset-edit-form-button'
          variant='secondary'
          type='button'
          onClick={() => {
            reset();
          } }>
          {t('button.cancel')}
        </FormButton>,
        <FormButton
          key='plant-form-edit-button'
          variant='primary'
          type='submit'
          disabled={!formIsValid}>
          {t('button.save')}
        </FormButton>
      );
    }

    return actions;
  }
    
  return (
    <FormProvider {...methods}>
      <form id='plant-management-details-container-form' onKeyDown={preventSubmitFromEnterKey()} onSubmit={handleSubmit(onSubmit)} role='presentation'>
        <Loading dataTestId='plant-management-screen-circular-progress' promiseInProgress={loading || plantStatus === 'loading'} />
        <Helmet>
          <title>
            {t('admin-panel.plant-management.details')} | {APP_TITLE}
          </title>
        </Helmet>

        <ConfirmDialog
          confirmationReasonEnabled
          visible={openConfirmationModal}
          setVisible={setOpenConfirmationModal}
          title={t('admin-panel.update-plant-modal.title')}
          content={t('admin-panel.update-plant-modal.content')}
          cancelText={t('button.cancel')}
          cancelAction={onCancelConfirmationModal}
          confirmText={t('button.save')}
          confirmAction={onConfirmConfirmationModal}
        />

        <MainPaper
          removePadding
          title={t('admin-panel.plant-management')}
          subtitle={plant?.name} 
          headerActions={renderActions()}>
          <PlantManagementDetailsContent 
            plant={plant}
            activities={activities}
            readOnly={routeState ? routeState.readOnly : true} />
        </MainPaper>
      </form>
    </FormProvider>
  );
}

export default PlantManagementDetailsContainer;
