import { useApi } from '..';
import { SystemOfMeasurementOptions } from '../me/MeGetResponse';
import { LimitsDTO } from './limitsTypes';

export function useLimitsService() {
  const { get } = useApi();
  const baseApi = `/v2/processConstraints/limits`;

  const getLimits = async (measurementSystem?: SystemOfMeasurementOptions) => {
    const { data } = await get<LimitsDTO>(`${baseApi}`, {
      headers: {
        ...measurementSystem !== undefined ? {
          'x-measurement-system': `${measurementSystem}`
        } : {}
      }
    });

    return data;
  };

  return {
    getLimits,
  };
}
