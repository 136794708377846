import { PlantsGetResponse } from '@app/api/plants';
import { UsersCreateRequest } from '@app/api/users/UserCreateRequest';
import FormSelect from '@app/components/shared/FormSelect';
import { default as yup } from '@app/config/yup-locale';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Roles, ROLES } from '../../userManagementUtils';
import FormButton from '@app/components/shared/FormButton';
import FormAutocompleteWithTags from '@app/components/shared/FormAutocompleteWithTags';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { useSystemOfMeasurementOptions } from '@app/hooks/useSystemOfMeasurementOptions';

interface InviteUserModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (user: UsersCreateRequest) => void;
  plants: PlantsGetResponse[];
  isPlantAdmin?: boolean;
}

interface IFormInput {
  logins: string[];
  roleId: string;
  measurementSystem: SystemOfMeasurementOptions;
  userPlants: string[] | string;
}

const InviteUserModal: React.FC<InviteUserModalProps> = (props: InviteUserModalProps) => {
  const { open, onClose, onConfirm, plants, isPlantAdmin } = props;
  const { t } = useTranslation();

  const { state: { userInfoObject } } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const schema = yup.object().shape({
    logins: yup.array().of(yup.string().email().required()).min(1),
    roleId: yup.string().required(),
    measurementSystem: yup.string().required(),
    userPlants: yup.mixed().when("roleId", {
      is: (value: string) => {
        return [
          Roles.Admin.toString(),
          Roles.KeyUser.toString(),
          Roles.TechnicalConsultant.toString()
        ].includes(value.toString())
      },
      then: yup.array().of(yup.string().required()).min(1),
      otherwise: yup.string().required(),
    }),
  });

  const options = plants.map((plant, index) => ({ value: index, name: plant.name, id: plant.id }));

  const {
    control,
    reset,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      logins: [],
      roleId: '',
      measurementSystem: undefined,
      userPlants: isPlantAdmin ? [userInfo?.selectedPlant.id] : []
    },
  });

  const watchRoleId = watch("roleId");
  const userPlants = watch("userPlants");
  const logins = watch("logins");
  const roleDropdownValues = ROLES
    .filter(r => (isPlantAdmin && [Roles.Admin, Roles.KeyUser, Roles.TechnicalConsultant].indexOf(r.value) === -1) || (!isPlantAdmin))
    .map(r => ({ ...r, name: t(r.name) }));
  const { systemOfMeasurementOptions } = useSystemOfMeasurementOptions();

  const handleClose = () => {
    reset({ logins: [], roleId: '', userPlants: undefined });
    onClose();
  };

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const { logins, roleId, userPlants, measurementSystem } = data;

    let userPlantsValue = Array.isArray(userPlants)
      ? userPlants.map(plantName => plants.find(plant => plant.name === plantName)?.id ?? '')
      : [plants[Number(userPlants)].id];

    if (!userPlantsValue)
      userPlantsValue = [userInfo?.selectedPlant.id || ''];

    const userToSend: UsersCreateRequest = {
      logins,
      userProfileId: Number(roleId) || 0,
      plants: userPlantsValue,
      measurementSystem: measurementSystem
    };

    onConfirm(userToSend);
  };

  const [loginsTooltipIsVisible, setLoginsTooltipIsVisible] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="admin-panel-invite-dialog-title"
        aria-describedby="admin-panel-invite-dialog-description"
        fullWidth
        maxWidth="xs"
        style={{ overflow: 'auto' }}
      >
        <DialogTitle id="admin-panel-invite-dialog-title">{t('invite-user')}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              paddingTop: 0,
              paddingBottom: 0,
              overflow: 'initial'
            }}
          >
            <Box>
              <Controller
                control={control}
                name="logins"
                render={({ field }) => (
                  <FormAutocompleteWithTags
                    id="logins"
                    fullWidth
                    label={t("e-mail")}
                    multiple
                    options={field.value}
                    limitTags={undefined}
                    freeSolo
                    onInputChange={(_, value) => {
                      setLoginsTooltipIsVisible(value !== '');
                    }}
                    {...field}
                    onChange={(
                      _,
                      value: string | string[] | null,
                      reason: AutocompleteChangeReason,
                    ) => {
                      if(
                        reason === 'clear'
                      ) {
                        setValue("logins", []);
                        field.onBlur();
                        return;
                      } else {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (
                          Array.isArray(value)
                          && value.every(item => emailRegex.test(item) === true)
                        ) {
                          setValue("logins", value as string[]);
                          field.onBlur();
                        }
                        return;
                      }
                    }}
                    handleCheckboxChange={(option) => {
                      setValue("logins", [...logins].filter(item => item !== option));
                      field.onBlur();
                    }}
                  />
                )}
              />
            </Box>
            {loginsTooltipIsVisible && (
              <WarningMessageComponent message={t("invite-user.logins-tooltip")} />
            )}
            <Box>
              <Controller
                name="roleId"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    id="user-invite-role-select"
                    label={t('user.profile')}
                    options={roleDropdownValues}
                    inputProps={{ ...field }}
                    error={!!errors.roleId?.message}
                    errorMessage={errors.roleId?.message}
                    onChange={(e) => {
                      if (e.target.value == Roles.Admin.toString() || e.target.value == Roles.KeyUser.toString() || e.target.value == Roles.TechnicalConsultant.toString() || isPlantAdmin) {
                        setValue('userPlants', isPlantAdmin ? [userInfo?.selectedPlant.id || ''] : []);
                      } else {
                        reset({ ...getValues(), userPlants: undefined });
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller 
                name="measurementSystem"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    fullWidth
                    id='measurement-system'
                    label={t('user.profile.select.measurement-system.label')}
                    error={!!errors.measurementSystem?.message}
                    inputProps={{...field}}
                    options={systemOfMeasurementOptions}
                    errorMessage={errors.measurementSystem?.message}
                  />
                )}
              />
            </Box>
            <Box>
              {!isPlantAdmin &&
                <>
                  {
                    watchRoleId === '' ?
                      (
                        <FormSelect
                          disabled
                          id="user-invite-plant-select-disabled"
                          label={t('admin-panel.plant')}
                          options={[]}
                        />
                      )
                      :
                      <Controller
                        name="userPlants"
                        control={control}
                        render={({ field }) => (
                          <>
                            {
                              watchRoleId != Roles.Admin.toString() && watchRoleId != Roles.KeyUser.toString() && watchRoleId != Roles.TechnicalConsultant.toString() ?
                                (
                                  <FormSelect
                                    disabled={watchRoleId === ''}
                                    id="user-invite-plant-select"
                                    label={t('admin-panel.plant')}
                                    options={options}
                                    inputProps={{ ...field }}
                                  />
                                ) : (
                                  <FormAutocompleteWithTags
                                    multiple
                                    label={t('admin-panel.plant')}
                                    id="checkboxes-tags-autocomplete"
                                    options={options.map(item => item.name)}
                                    disableCloseOnSelect
                                    limitTags={3}
                                    {...field}
                                    value={field.value as string[]}
                                    onChange={(
                                      _,
                                      value: string[],
                                    ) => {
                                      setValue("userPlants",value || [])
                                    }}
                                    handleCheckboxChange={
                                      ( option: string, checked: boolean) => {
                                        const tempValues = [...userPlants as string[]];

                                        if (checked) {
                                          setValue("userPlants", [...tempValues, option]);
                                        } else {
                                          setValue("userPlants", tempValues?.filter((item) => item !== option) ?? []);
                                        }
                                      }
                                    }
                                  />
                                )
                            }
                          </>
                        )}
                      />
                  }
                </>
              }
            </Box>
          </DialogContent>

          <DialogActions>
            <FormButton onClick={handleClose} variant="secondary">
              {t('button.cancel')}
            </FormButton>
            <FormButton type="submit" variant="primary" disabled={!(isValid && isDirty)}>
              {t('admin-panel.invite')}
            </FormButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default InviteUserModal;
