import { MeGetResponse } from '@app/api/me';
import { GenericContext } from '@app/store/genericContext';
import { AccountInfo } from '@azure/msal-browser';
import { AuthenticationActions } from './authenticationActions';
import { AuthenticationState } from './authenticationState';
import { LimitsDTO } from '@app/api/limits/limitsTypes';
import { InfoObject } from '@app/hooks/types';

export type AuthenticationDispatch = (action: AuthenticationAction) => void;
export const AUTHENTICATION_INITIAL_DISPATCH = (action: AuthenticationAction): void => {
  if(!Object.keys(AuthenticationActions).includes(action.type)) {
    throw Error('Dispatch not implemented. Action: ' + action.type);
  }
};

export function AuthenticationReducer(state: AuthenticationState, action: AuthenticationAction): AuthenticationState {
  const actionType = action.type
  switch (actionType) {
    case AuthenticationActions.SET_ACCOUNT_INFO: {
      return { ...state, accountInfo: action.payload };
    }

    case AuthenticationActions.SET_USER_INFO_OBJECT: {
      return { ...state, userInfoObject: action.payload as InfoObject<MeGetResponse> };
    }

    case AuthenticationActions.SET_LIMITS_OBJECT: {
      return { ...state, limitsInfoObject: action.payload as InfoObject<LimitsDTO> };
    }

    case AuthenticationActions.SET_INITIAL_LOAD_COMPLETE: {
      return { ...state, initialLoadComplete: !!action.payload };
    }

    default: {
      throw new Error(`Unidentified action: ${actionType}`);
    }
  }
}

type AuthenticationAction = GenericContext<AuthenticationActions.SET_ACCOUNT_INFO, AccountInfo | undefined>
  | GenericContext <AuthenticationActions.SET_USER_INFO_OBJECT, InfoObject<MeGetResponse>>
  | GenericContext <AuthenticationActions.SET_LIMITS_OBJECT,InfoObject<LimitsDTO>>
  | GenericContext<AuthenticationActions.SET_INITIAL_LOAD_COMPLETE, boolean>;
