export interface PlantState {
  loading: boolean;
  completedFirstLoad: boolean;
  error: boolean;
  errorMessage?: string | undefined;
  data: {
    general: boolean;
    equipment: {
      ladle: boolean;
      ladleFurnace: boolean;
    };
    additions: {
      fluxes: boolean;
      alloys: boolean;
      slagDeoxidizers: boolean;
    };
    carryoverSlag: {
      inference: boolean;
      composition: boolean;
    };
    steelGrade: {
      category: boolean;
    }
  }
}

export const initialPlantState = {
  loading: false,
  completedFirstLoad: false,
  error: false,
  errorMessage: undefined,
  data: {
    general: false,
    equipment: {
      ladle: false,
      ladleFurnace: false
    },
    additions: {
      fluxes: false,
      alloys: false,
      slagDeoxidizers: false,
    },
    carryoverSlag: {
      inference: false,
      composition: false,
    },
    steelGrade: {
      category: false
    }
  }
}