import { makeStyles, Typography, SvgIconTypeMap, Box, Theme, Tooltip } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { useTranslation } from "react-i18next";
import FormButton, { FormButtonProps } from "./shared/FormButton";

const useStyles = makeStyles((theme: Theme) => ({
  invisibleButton: {
    opacity: 0,
  },
  buttonTitle: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "14px",
    textAlign: "center",
    textTransform: "initial",
    whiteSpace: 'nowrap'
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.custom.stepperNavigationHoverColor.main
    },
    '&:hover *': {
      borderColor: theme.palette.custom.stepperNavigationHoverColor.main
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.custom.stepperNavigationActiveColor.main,
      filter: 'drop-shadow(2px 2px 3px grey)',
    },
    '&:active *': {
      borderColor: theme.palette.custom.stepperNavigationActiveColor.main,
    },
      border: `4px solid transparent`,
      borderRadius: '4px',
    '&:focus:not(:active)': {
      border: `4px solid ${theme.palette.custom.outlineColor.main}`,
      borderRadius: '4px',
    },
    '&:focus:not(:focus-visible)': {
      border: '4px solid transparent',
      borderRadius: '4px',
    },
    '&:disabled':{
      color: theme.palette.custom.disabledColor.main
    },
    '&:disabled *':{
      borderColor: theme.palette.custom.disabledColor.main
    },
    minWidth: "98px",
    height: "fit-content",
    boxSizing: "border-box",
    padding: "8px 15px 11px",
    cursor: "pointer",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      rowGap: "8px",
    }
  },
  icon: {
    width: "16px",
    height: "16px",
    "MuiButtonBase-root:hover &": {
      color: theme.palette.custom.stepperNavigationHoverColor.main,
    }
  },
  border: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    height: "24px",
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alingItems: "center !important",
    backgroundColor: "transparent"
  }
}));

interface StepperNavigationButtonProps extends FormButtonProps {
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  invisible: boolean;
  tooltip?: string;
}

function StepperNavigationButton(props: StepperNavigationButtonProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { Icon, title, invisible, tooltip = '', ...rest } = props

  return (
    <Tooltip 
      title={(invisible || !rest.disabled) ? '' : tooltip}
      placement="bottom"
    >
      <span style={{ height: 'fit-content' }}> {/*this span was placed here to make sure the tooltip is visible when the button is disabled**/}
        <FormButton 
          disableElevation
          disableRipple 
          style={{ backgroundColor: 'transparent', display: "flex", flexDirection: "column", gap: '8px' }} 
          className={invisible ? `${classes.button} ${classes.invisibleButton}` : classes.button} 
          {...rest}
        >
          <Box alignItems="center" justifyContent="center" className={classes.border}>
            <Icon className={classes.icon} color="inherit" />
          </Box>
          <Typography className={classes.buttonTitle} variant="button">{t(title)}</Typography>
        </FormButton>
      </span>
    </Tooltip>
  );
}

export default StepperNavigationButton;
