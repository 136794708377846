import RouteSection from '@app/model/RouteSection.model';
import { CarryoverSlags } from '@app/pages/CarryoverSlags';
import { SteelGrade } from '@app/pages/SteelGrade';
import { Equipment } from '@app/pages/Equipment';
import { Additions } from '@app/pages/Additions';
import { ArchivedHeats } from '@app/pages/Heats/ArchivedHeats';
import { ArchivedDrafts } from '@app/pages/Heats/ArchivedDrafts';
import HeatInfo from '@app/pages/Heats/HeatCalculation/HeatInfo';
import NewHeat from '@app/pages/Heats/HeatCalculation/NewHeat';
import { Overview } from '@app/pages/Overview';
import { PlantManagement } from '@app/pages/PlantManagement';
import Profile from '@app/pages/Profile';
import { UserManagement } from '@app/pages/UserManagement';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { ROUTES_PATH } from '@app/utils';
import { PERMISSIONS } from '@app/utils/constants';
import { ReactComponent as AdditionIcon } from '@app/assets/images/additions.svg'
import { ReactComponent as CarryOverSlagIcon } from '@app/assets/images/carryover-slag.svg'
import { ReactComponent as SteelGradeIcon } from '@app/assets/images/steel-grade.svg'
import { ReactComponent as EquipmentIcon } from '@app/assets/images/equipment.svg'
import heatCalculationIcon from '@app/assets/images/heat-calculation-icon.svg'
import { ReactComponent as LadlaFurnaceModeIcon} from '@app/assets/images/ladle-furnace-mode-icon.svg'
import managementIcon from '@app/assets/images/management-icon.svg'
import plantSetupIcon from '@app/assets/images/plant-setup-icon.svg'
import { ReactComponent as TappingModeIcon } from '@app/assets/images/tapping-mode-icon.svg'
import { ReactComponent as ArchivedHeatIcon } from '@app/assets/images/archived-heats.svg'
import { ReactComponent as DraftHeatIcon} from '@app/assets/images/draft-heats.svg'
import { ReactComponent as  PlantOverviewIcon } from '@app/assets/images/factory.svg'
import { BusinessOutlined, PeopleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import { SvgIcon } from '@material-ui/core';
import PlantManagementDetailsContainer from '@app/pages/PlantManagement/views/details/PlantManagementDetailsContainer';


export function useRouteConfig() {
  const { t } = useTranslation();
  const { logout } = useAuthenticationContext();
  const enableNewHeat = usePlantContext().state.data.general;

  const routeSections: Array<RouteSection> = [
    {
      key: 'section-applications',
      title: t('menu.item.calculation'),
      icon: heatCalculationIcon,
      items: [
        {
          key: 'router-new-heat-calculation.tapping',
          title: t('calculation.mode.tapping'),
          path: ROUTES_PATH.APPLICATIONS_HEATS_TAPPING_CREATE,
          enabled: enableNewHeat,
          tooltip: enableNewHeat ? t('calculation.mode.tapping') : t('overview.tooltip.new-heat', { heatMode: t('calculation.mode.tapping') }),
          component: NewHeat,
          icon: () => (
            <SvgIcon
              viewBox='0 0 20 20'
              style={{ width: '20px', height: '20px' }}
              component={TappingModeIcon}
            />
          ),
          permissions: [PERMISSIONS.FEATURE_CALCULATION_TAPPING_MODE],
        },
        {
          key: 'router-new-heat-calculation.ladle-furnace',
          title: t('calculation.mode.ladlefurnace'),
          path: ROUTES_PATH.APPLICATIONS_HEATS_LADLE_FURNACE_CREATE,
          enabled: enableNewHeat,
          tooltip: enableNewHeat ? t('calculation.mode.ladlefurnace') : t('overview.tooltip.new-heat', { heatMode: t('calculation.mode.ladlefurnace') }),
          component: NewHeat,
          icon: () => (
            <SvgIcon
              viewBox='0 0 14 19'
              style={{ width: '20px', height: '20px' }}
              component={LadlaFurnaceModeIcon}
            />
          ),
          permissions: [PERMISSIONS.DRAFT_CALCULATION_CREATE, PERMISSIONS.HEAT_CALCULATION_OPERATION_CREATE],
        },
        {
          key: 'router-heat-ladle-furnace-info',
          title: t('menu.item.calculation.new-heat.heat-info'),
          path: ROUTES_PATH.APPLICATIONS_HEATS_LADLE_FURNACE_VIEW,
          enabled: true,
          component: HeatInfo,
          routeOnly: true,
          permissions: [PERMISSIONS.HEAT_CALCULATION_DETAIL],
        },
        {
          key: 'router-heat-tapping-info',
          title: t('menu.item.calculation.new-heat.heat-info'),
          path: ROUTES_PATH.APPLICATIONS_HEATS_TAPPING_VIEW,
          enabled: true,
          component: HeatInfo,
          routeOnly: true,
          permissions: [PERMISSIONS.HEAT_CALCULATION_DETAIL],
        },
        {
          key: 'router-draft-tapping-info',
          title: t('menu.item.calculation.new-heat.draft-info'),
          path: ROUTES_PATH.APPLICATIONS_DRAFT_TAPPING_VIEW,
          enabled: true,
          component: HeatInfo,
          routeOnly: true,
          permissions: [PERMISSIONS.HEAT_CALCULATION_DETAIL],
        },
        {
          key: 'router-draft-ladle-furnace-info',
          title: t('menu.item.calculation.new-heat.draft-info'),
          path: ROUTES_PATH.APPLICATIONS_DRAFT_LADLE_FURNACE_VIEW,
          enabled: true,
          component: HeatInfo,
          routeOnly: true,
          permissions: [PERMISSIONS.HEAT_CALCULATION_DETAIL],
        },
        {
          key: 'router-draft-tapping-edit',
          title: t('heat.draft.edit'),
          path: ROUTES_PATH.APPLICATIONS_DRAFT_TAPPING_EDIT,
          enabled: true,
          component: NewHeat,
          routeOnly: true,
          permissions: [PERMISSIONS.HEAT_CALCULATION_DETAIL],
        },
        {
          key: 'router-draft-ladle-furnace-edit',
          title: t('heat.draft.edit'),
          path: ROUTES_PATH.APPLICATIONS_DRAFT_LADLE_FURNACE_EDIT,
          enabled: true,
          component: NewHeat,
          routeOnly: true,
          permissions: [PERMISSIONS.HEAT_CALCULATION_DETAIL],
        },
        {
          key: 'router-archived-draft-heats',
          title: t('heat.draft'),
          tooltip: t('heat.draft'),
          path: ROUTES_PATH.APPLICATIONS_DRAFTS,
          enabled: true,
          component: ArchivedDrafts,
          icon: () => (
            <SvgIcon
              viewBox='0 0 12 14'
              style={{ width: '20px', height: '20px' }}
              component={DraftHeatIcon}
            />
          ),
          permissions: [PERMISSIONS.HEAT_LIST],
        },
        {
          key: 'router-archived-heats',
          title: t('archived-heats'),
          tooltip: t('archived-heats'),
          path: ROUTES_PATH.APPLICATIONS_HEATS,
          enabled: true,
          component: ArchivedHeats,
          icon: () => (
            <SvgIcon
              viewBox='0 0 16 16'
              style={{ width: '20px', height: '20px' }}
              component={ArchivedHeatIcon}
            />
          ),
          permissions: [PERMISSIONS.HEAT_LIST],
        },
      ],
    },
    {
      key: 'section-plant-setup',
      title: t('menu.item.plant-setup'),
      icon: plantSetupIcon,
      items: [
        {
          key: 'router-overview',
          title: t('overview'),
          tooltip: t('overview'),
          path: ROUTES_PATH.PLANT_OVERVIEW,
          enabled: true,
          component: Overview,
          icon: () => (
            <SvgIcon
              viewBox='0 0 16 16'
              style={{ width: '20px', height: '20px' }}
              component={PlantOverviewIcon}
            />
          ),
          permissions: [PERMISSIONS.BASIC_ACCESS_READ],
        },
        {
          key: 'router-equipment',
          title: t('overview.equipment.title'),
          tooltip: t('overview.equipment.title'),
          path: ROUTES_PATH.PLANT_EQUIPMENT,
          enabled: true,
          component: Equipment,
          icon: () => (
            <SvgIcon
              viewBox='0 0 24 24'
              style={{ width: '20px', height: '20px' }}
              component={EquipmentIcon}
            />
          ),
          permissions: [PERMISSIONS.LADLE_FURNACE_CREATE, PERMISSIONS.LADLE_FURNACE_DELETE, PERMISSIONS.LADLE_FURNACE_DETAIL,
          PERMISSIONS.LADLE_FURNACE_EDIT, PERMISSIONS.LADLE_FURNACE_LIST]
        },
        {
          key: 'router-additions',
          title: t('overview.additions.title'),
          tooltip: t('overview.additions.title'),
          path: ROUTES_PATH.PLANT_ADDITIONS,
          enabled: true,
          component: Additions,
          icon: () => (
            <SvgIcon
              viewBox='0 0 17 20'
              style={{ width: '20px', height: '20px' }}
              component={AdditionIcon}
            />
          ),
          permissions: [PERMISSIONS.FLUXES_CREATE, PERMISSIONS.FLUXES_DELETE, PERMISSIONS.FLUXES_DETAIL, PERMISSIONS.FLUXES_EDIT, PERMISSIONS.FLUXES_LIST,
          PERMISSIONS.ALLOYS_CREATE, PERMISSIONS.ALLOYS_DELETE, PERMISSIONS.ALLOYS_EDIT, PERMISSIONS.ALLOYS_LIST,
          PERMISSIONS.SLAG_DEOXIDIZERS_EDIT, PERMISSIONS.SLAG_DEOXIDIZERS_LIST]
        },
        {
          key: 'router-carryover-slag',
          title: t('carryover-slags'),
          tooltip: t('carryover-slags'),
          path: ROUTES_PATH.SETTINGS_CARRYOVER_SLAGS,
          enabled: true,
          component: CarryoverSlags,
          icon: () => (
            <SvgIcon
              viewBox='0 0 14 14'
              style={{ width: '20px', height: '20px' }}
              component={CarryOverSlagIcon}
            />
          ),
          permissions: [PERMISSIONS.CARRYOVER_SLAG_LIST],
        },
        {
          key: 'router-steel-grade',
          title: t('steel-grade'),
          tooltip: t('steel-grade'),
          path: ROUTES_PATH.APPLICATIONS_STEEL_GRADE,
          enabled: true,
          component: SteelGrade,
          icon: () => (
            <SvgIcon
              viewBox='0 0 18 17'
              style={{ width: '20px', height: '20px' }}
              component={SteelGradeIcon}
            />
          ),
          permissions: [PERMISSIONS.STEEL_GRADE_LIST],
        },
      ]
    },
    {
      key: 'section-users',
      title: t('admin-panel.section.title').toUpperCase(),
      icon: managementIcon,
      items: [
        {
          key: 'router-profile',
          title: t('user.profile.title'),
          path: ROUTES_PATH.USERS_PROFILE,
          enabled: true,
          component: Profile,
          routeOnly: true,
        },
        {
          key: 'router-user-management',
          title: t('admin-panel.user-management'),
          tooltip: t('admin-panel.user-management'),
          path: ROUTES_PATH.USERS_USER_MANAGEMENT,
          enabled: true,
          component: UserManagement,
          icon: PeopleOutline,
          permissions: [PERMISSIONS.USER_MANAGEMENT_LIST],
        },
        {
          key: 'router-plant-management',
          title: t('admin-panel.plant-management'),
          tooltip: t('admin-panel.plant-management'),
          path: ROUTES_PATH.USERS_PLANT_MANAGEMENT,
          enabled: true,
          component: PlantManagement,
          icon: BusinessOutlined,
          permissions: [PERMISSIONS.PLANT_MANAGEMENT_LIST],
        },
        {
          key: 'router-plant-management-details',
          title: t('admin-panel.plant-management.details'),
          path: ROUTES_PATH.USERS_PLANT_MANAGEMENT_DETAILS,
          enabled: true,
          component: PlantManagementDetailsContainer,
          routeOnly: true,
          permissions: [PERMISSIONS.PLANT_MANAGEMENT_DETAIL],
        },
        {
          key: 'router-logout',
          title: 'Logout',
          enabled: true,
          onClick: logout,
          routeOnly: true,
        },
      ],
    },
  ];

  return { routeSections };
}
