import { flatten } from 'flat';
import _ from 'lodash';

export interface JsonProperty {
  name: string;
  value: string;
}

export interface EscapeWord {
  value: string;
  searchValue: RegExp;
  replaceValue: string;
}

type JsonObject = Record<string, string | number | boolean | object | null | undefined>;

const escapeWords: EscapeWord[] = [
  {
    value: 'CaO',
    searchValue: /[Cc]aO/,
    replaceValue: '__cao__',
  },
  {
    value: 'MgO',
    searchValue: /[Mm]gO/,
    replaceValue: '__mgo__',
  },
  {
    value: 'R2O3',
    searchValue: /[Rr]2O3/,
    replaceValue: '__r2o3__',
  },
];

const wordSeparator = ' ';

const splitAndCapitalizeText = (jsonProperty: string) => {
  escapeWords.forEach((escapeWord) => {
    jsonProperty = jsonProperty.replace(
      escapeWord.searchValue,
      escapeWord.replaceValue
    );
  });
  
  const words = jsonProperty
    .replace(/(?<!__)([a-z0-9])([A-Z0-9])(?!__)/g, '$1 $2')  
    .replace(/([a-z0-9]__)([A-Z])/g, '$1 $2')
    .replace(/([a-z0-9])(__[a-z])/g, '$1 $2')
    .split(wordSeparator);

  let capitalizedWords = words.map((word) => _.capitalize(word)).join(wordSeparator);

  escapeWords.forEach((escapeWord) => {
    capitalizedWords = capitalizedWords.replace(
      escapeWord.replaceValue,
      escapeWord.value
    );
  });

  return capitalizedWords;
};

const formatJsonPropertyName = (fullPathKey: string, propertySeparator: string): string => {
  const formattedKey = fullPathKey
    .split('.')
    .map((propertyName) => splitAndCapitalizeText(propertyName))
    .join(propertySeparator);

  return formattedKey;
};

const mapJsonAsTable = (json: JsonObject, propertySeparator = ' / '): JsonProperty[] => {
  const table: JsonProperty[] = [];
  const flatJson = flatten<JsonObject, JsonObject>(json);

  Object.keys(flatJson).forEach((key) => {
    const value = flatJson[key];
    let textValue = '-';

    if (value !== undefined && value !== null) {
      textValue = value.toString();
    }
    
    table.push({
      name: formatJsonPropertyName(key, propertySeparator),
      value: textValue,
    });
  });

  return table;
}

export const JsonUtils = {
  formatJsonPropertyName: formatJsonPropertyName,
  splitAndCapitalizeText: splitAndCapitalizeText,
  mapJsonAsTable: mapJsonAsTable
}