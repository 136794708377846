import { CarryoverSlagReferenceGetResponse, useCarryoverSlagReferencesService } from '@app/api/carryoverSlagReferences';
import { clearLocationState } from '@app/utils/clearLocationState';
import { CARRYOVERSLAGS } from '@app/utils/plantSetupRoutesPathTabs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ListInfoObject } from "./types"


interface LocationState {
  option?: CARRYOVERSLAGS,
}

const useLoadCarryoverSlagsReference = (): [
  NonNullable<ListInfoObject<CarryoverSlagReferenceGetResponse>>,
  (steelGradeCategoryId?: number) => Promise<void>,
  () => void,
] => {
  const location = useLocation<LocationState>();

  const infoObjectInitialState: NonNullable<ListInfoObject<CarryoverSlagReferenceGetResponse>> = {
    status: 'idle',
    data: [],
  }
  
  const { getCarryoverSlagReferences } = useCarryoverSlagReferencesService();

  const [infoObject, setInfoObject] = useState<NonNullable<ListInfoObject<CarryoverSlagReferenceGetResponse>>>(infoObjectInitialState);

  useEffect(() => {
    if (location.state?.option !== undefined && infoObject.status === 'succeeded') {
      clearLocationState();
    }
  }, [location.state, infoObject.status])

  const loadCarryoverSlagsReference = async (steelGradeCategoryId?: number) => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const carryoverSlagsReferences = await getCarryoverSlagReferences(steelGradeCategoryId);

      setInfoObject(prevState => ({ 
        ...prevState, 
        data: carryoverSlagsReferences, 
        status: 'succeeded',
      }));
    } catch (err) {
      
      setInfoObject({
        ...infoObjectInitialState, 
        status: 'error'
      });
    }
  };

  const resetStatus = () => {
    setInfoObject(lastInfoObjectState => ({...lastInfoObjectState, status: 'idle'}))
  }

  return [infoObject, loadCarryoverSlagsReference, resetStatus];
}

export default useLoadCarryoverSlagsReference;