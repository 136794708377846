import { GetLadleFurnaceResponse, useLadleFurnacesService } from '@app/api/ladleFurnaces';
import { useState } from 'react';
import { ListInfoObject } from "./types"

const infoObjectInitialState: ListInfoObject<GetLadleFurnaceResponse> = {
  status: 'idle',
  data: [],
}

const useLoadLadleFurnaces = (): [
  ListInfoObject<GetLadleFurnaceResponse>,
  () => Promise<void>,
] => {
  const { getLadleFurnaces } = useLadleFurnacesService();

  const [infoObject, setInfoObject] = useState<ListInfoObject<GetLadleFurnaceResponse>>(infoObjectInitialState);

  const loadLoadLadleFurnaces = async () => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getLadleFurnaces();
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {
      
      setInfoObject({data: [], status: 'error'});
    }
  };


  return [infoObject, loadLoadLadleFurnaces];
}

export default useLoadLadleFurnaces;