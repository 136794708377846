import { Box, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

interface FormValidationWarningProps {
  message: string;
}

const FormValidationWarning =  ({ message }: FormValidationWarningProps) =>  (
  <Box 
    borderRadius={4} 
    style={{ backgroundColor: '#FDCBCB' }} 
    display='flex' 
    flexDirection='row' 
    padding={1} 
    marginTop={3}
  >
    <InfoOutlined style={{ color: '#ED0909', marginRight: 8 }} />
    <Typography>{message}</Typography>
  </Box>
)

export default FormValidationWarning


