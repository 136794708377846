import { CarryoverSlagReferenceCreateRequest, CarryoverSlagReferenceGetResponse } from '.';
import { useApi } from '..';

export function useCarryoverSlagReferencesService() {
  const { get, remove, put, post } = useApi();
  const baseApi = `/v2/carryoverSlagReference`;

  const getCarryoverSlagReferences = async (steelGradeCategoryId?: number) => {
    const { data } = await get<CarryoverSlagReferenceGetResponse[]>(`${baseApi}`, { 
      params: steelGradeCategoryId && { targetValue: steelGradeCategoryId, inferenceType: 'SteelGradeCategory' } 
    });

    return data;
  };

  const getCarryoverSlagReference = async (id: number) => {
    const { data } = await get<CarryoverSlagReferenceGetResponse>(`${baseApi}/${id}`);

    return data;
  };

  const deleteCarryoverSlagReference = async (id: number) => {
    const { data } = await remove(`${baseApi}/${id}`);

    return data;
  };

  const updateCarryoverSlagReference = async (id: number, request: CarryoverSlagReferenceCreateRequest) => {
    const { data } = await put(`${baseApi}/${id}`, request);

    return data;
  };

  const saveCarryoverSlagReference = async (request: CarryoverSlagReferenceCreateRequest) => {
    const { data } = await post(`${baseApi}`, request);

    return data;
  };

  return {
    getCarryoverSlagReferences,
    getCarryoverSlagReference,
    deleteCarryoverSlagReference,
    updateCarryoverSlagReference,
    saveCarryoverSlagReference,
  };
}
