import { useRef } from "react";

const useScrollTop = <T extends HTMLElement>() => {
    const elementContainerRef = useRef<T>(null);

    const scrollTop = () => {
      if(elementContainerRef.current) {
        elementContainerRef.current.scrollTop = 0;
      }
    }

    return ({ scrollTop, elementContainerRef })
}

export default useScrollTop;