import { useApi } from '..';
import { CarryoverSlagType } from '../heats/HeatsResponse';
import { SystemOfMeasurementOptions } from '../me/MeGetResponse';


export enum SteelChemistryUnitOfMeasurement {
  Percent = "Percent",
  Points = "Points",
}

export const UNITS_OF_MEASUREMENT: { [key: string]: string } = {
  [SteelChemistryUnitOfMeasurement.Points]: 'unit-of-measurement.points',
  [SteelChemistryUnitOfMeasurement.Percent]: '%',
};

export interface ProcessConstraintsDTO {
  averageLadleLifetime: number;
  carryoverSlagType: CarryoverSlagType;
  finalLiningThickness: number;
  initialLiningThickness: number;
  ladleDiameter: number;
  ladleSteelWeight: number;
  maximumSlagDeoxidizerWeight: number;
  maximumSlagWeight: number;
  mgOSourceMaximumWeight: number;
  mgOSourceMinimumWeight: number;
  minimumSlagCarryoverWeight: number;
  minimumSlagWeightAfterSkimmerUse: number;
  alRecovery: number;
  mnRecovery: number;
  siRecovery: number;
  crRecovery: number;
  toleranceCalcMinSlagThickness: number;
  alkalisToFluorsparFactor: number;
  steelChemistryUnitOfMeasurement: SteelChemistryUnitOfMeasurement;
  steelChemistryPrimaryFurnaceCode: string;
  steelChemistryTappingCode: string;
  measurementSystem: SystemOfMeasurementOptions;
}

export type SteelChemistryConfigs = Pick<ProcessConstraintsDTO, "steelChemistryPrimaryFurnaceCode" | "steelChemistryTappingCode" | "steelChemistryUnitOfMeasurement">;

export function useProcessConstraintsService() {
  const { get, put } = useApi();
  const baseApiV2 = '/v2/processConstraints'

  const getProcessConstraints = async () => {
    const { data } = await get<ProcessConstraintsDTO>(`${baseApiV2}`);

    return data;
  };

  const updateProcessConstraint = async (request: ProcessConstraintsDTO) => {
    const { data } = await put<ProcessConstraintsDTO>(`${baseApiV2}`, request);

    return data;
  };

  return {
    getProcessConstraints,
    updateProcessConstraint,
  };
}
