import { CarryoverSlagReferenceCreateRequest, CarryoverSlagReferenceGetResponse, CarryoverSlagReferenceParameters } from '@app/api/carryoverSlagReferences';
import { CarryoverSlagType } from '@app/api/heats/HeatsResponse';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import CarryoverSlagUnitOfMeasurement from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import ISelectedCarryoverSlag from '@app/pages/Heats/HeatCalculation/ISelectedCarryoverSlag';

export interface GeneralSettings {
  carryoverSlagInferenceType?: CarryoverSlagInferenceType | null;
  carryoverSlagParameterValueName?: string | null;
  carryoverSlagUnitOfMeasurement?: CarryoverSlagUnitOfMeasurement | null;
  carryoverSlagReferenceInput?: string | null;
  carryoverSlagType?: CarryoverSlagType | null;
  minimumSlagCarryoverWeight?: number | null;
}

export const generalSettingsInitialState: GeneralSettings = {
  carryoverSlagInferenceType: null,
  carryoverSlagParameterValueName: null,
  carryoverSlagUnitOfMeasurement: null,
  carryoverSlagReferenceInput: null,
  carryoverSlagType: null,
  minimumSlagCarryoverWeight: null
}

export const newCarryoverSlagInitialValue: CarryoverSlagReferenceCreateRequest = {
  mgO: 0,
  caO: 0,
  al2O3: 0,
  siO2: 0,
  feO: 0,
  mnO: 0,
  cr2O3: 0,
  tiO2: 0,
  caF2: 0,
  na2O: 0,
  k2O: 0,
  value: undefined,
  minValue: 0,
  maxValue: 1,
  steelGradeCategoryId: null
}

export const SLAG_TYPE: Array<{ value: CarryoverSlagInferenceType, nameKey: string }> = [
  { value: CarryoverSlagInferenceType.Range, nameKey: 'admin-panel.plants.carryover-slags-inference-type.range' },
  { value: CarryoverSlagInferenceType.SingleValue, nameKey: 'admin-panel.plants.carryover-slags-inference-type.single' },
  { value: CarryoverSlagInferenceType.SteelGradeCategory, nameKey: 'admin-panel.plants.carryover-slags-inference-type.steel-grade-category' },
];

export const getReferenceValueLabel = (
  isRanged: boolean | null = false,
  carryoverSlag?: CarryoverSlagReferenceGetResponse | ISelectedCarryoverSlag | CarryoverSlagReferenceParameters,
): string => {

  return isRanged
    ? `${Number(carryoverSlag?.minValue || 0)} - ${Number(carryoverSlag?.maxValue || 0)}`
    : `${Number(carryoverSlag?.value || 0)}`;
};

export const getReferenceValueFromApi = (
  value?: number,
  unitOfMeasurement?: CarryoverSlagUnitOfMeasurement,
): string | undefined => {
  if (value == undefined) return undefined;

  switch (unitOfMeasurement) {
    case CarryoverSlagUnitOfMeasurement.Percent: {
      return value.toString();
    }
    case CarryoverSlagUnitOfMeasurement.Points: {
      return (value * 1000).toString();
    }
    default: {
      return value.toString();
    }
  }
};

export const getReferenceValueToApi = (
  value?: string,
  unitOfMeasurement?: CarryoverSlagUnitOfMeasurement,
): number | undefined => {
  if (value == undefined) return undefined;

  switch (unitOfMeasurement) {
    case CarryoverSlagUnitOfMeasurement.Percent: {
      return Number(value);
    }
    case CarryoverSlagUnitOfMeasurement.Points: {
      return Number(value) / 1000;
    }
    default: {
      return Number(value);
    }
  }
};

export const mapValuesToApi = (values: CarryoverSlagReferenceCreateRequest, inferenceType: CarryoverSlagInferenceType) => {
  return {
    mgO: Number(values.mgO),
    caO: Number(values.caO),
    al2O3: Number(values.al2O3),
    siO2: Number(values.siO2),
    feO: Number(values.feO),
    mnO: Number(values.mnO),
    cr2O3: Number(values.cr2O3),
    tiO2: Number(values.tiO2),
    caF2: Number(values.caF2),
    na2O: Number(values.na2O),
    k2O: Number(values.k2O),
    ...inferenceType === CarryoverSlagInferenceType.SingleValue ? {
      value: Number(values.value),
      minValue: undefined,
      maxValue: undefined
    } : {},
    ...inferenceType === CarryoverSlagInferenceType.Range ? {
      value: undefined,
      minValue: Number(values.minValue),
      maxValue: Number(values.maxValue)
    } : {},
    ...inferenceType === CarryoverSlagInferenceType.SteelGradeCategory ? {
      value: undefined,
      minValue: undefined,
      maxValue: undefined,
      steelGradeCategoryId: values.steelGradeCategoryId,
    } : {},
  }
}


