import { UsersGetResponse, UsersUpdateRequest } from '@app/api/users';

export enum Roles {
  Admin = 1,
  Operator = 2,
  Supervisor = 3,
  PlantAdmin = 4,
  TechnicalConsultant = 5,
  KeyUser = 6,
}

export interface UserRole {
  value: number,
  name: string,
  roleKey: string,
}

export const ROLES: UserRole[] = [
  {
    value: Roles.Admin,
    name: 'role.admin',
    roleKey: 'Admin'
  },
  {
    value: Roles.KeyUser,
    name: 'role.key-user',
    roleKey: 'KeyUser'
  },
  {
    value: Roles.TechnicalConsultant,
    name: 'role.technical-consultant',
    roleKey: 'TechnicalConsultant'
  },
  {
    value: Roles.PlantAdmin,
    name: 'role.plant-admin',
    roleKey: 'PlantAdmin'
  },
  {
    value: Roles.Supervisor,
    name: 'role.supervisor',
    roleKey: 'Supervisor'
  },
  {
    value: Roles.Operator,
    name: 'role.operator',
    roleKey: 'Operator'
  },
];

export const getFilteredUsers = (users: UsersGetResponse[], usersToChange: UsersUpdateRequest[]) => {
  const newUsers: UsersGetResponse[] = [...users];
  const changedUsers = newUsers?.map(item => {
    const changed = usersToChange.find(utc => utc.id === item.id);
    if (changed) {
      // item.plantId = changed.plantId;
      item.userProfileId = changed.userProfileId as number;
    }
    return item;
  });

  return changedUsers;
};


