import { ExistsHeatResponseDTO } from "@app/api/heats/useHeatsService";

export const parseHeatNameValidationsPayload = (payload: ExistsHeatResponseDTO): ExistsHeatResponseDTO => (
  !payload.canUseName ? {
    ...payload,
    canChangeLadle: true,
    ladleName: 0,
    ladleLife: 0,
    isNewCampaign: false
  } : payload
)