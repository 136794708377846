import { PostSteelGradeCategoryModel, SteelGradeCategoryModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { useApi } from '..';

export function useSteelGradeCategoriesService() {
  const { get, post, put, remove } = useApi();
  const baseApi = `/v2/steelGradeCategories`;

  const getSteelGradesCategories = async () => {
    const { data } = await get<Array<SteelGradeCategoryModel>>(`${baseApi}`);

    return data;
  };

  const saveSteelGradesCategory = async (steelGradeCategory: PostSteelGradeCategoryModel) => {
    const { data } = await post<SteelGradeCategoryModel>(`${baseApi}`, steelGradeCategory);

    return data;
  }

  const updateSteelGradesCategory = async (steelGradeCategory: Omit<SteelGradeCategoryModel, "id">, id: number) => {
    const { data } = await put<SteelGradeCategoryModel>(`${baseApi}/${id}`, steelGradeCategory);

    return data;
  }

  const deleteSteelGradesCategory = async (id: number) => {
    const { data } = await remove(`${baseApi}/${id}`);

    return data;
  }

  return {
    getSteelGradesCategories,
    saveSteelGradesCategory,
    updateSteelGradesCategory,
    deleteSteelGradesCategory
  };
}
