import { useApi } from '..';
import { FluxCategoryParameters } from '.'

export function useFluxesAvailabilityService() {
  const { get } = useApi();
  const baseApi = `/v2/fluxes/availabilities`;

  const getFluxAvailabilities = async () => {
    const { data } = await get<FluxCategoryParameters[]>(`${baseApi}`);
    return data;
  };

  return {
    getFluxAvailabilities,
  };
}
