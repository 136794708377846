import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from 'react-i18next';
import Loading from '@app/components/shared/Loading';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { PERMISSIONS } from '@app/utils/constants';
import CloseIcon from '@material-ui/icons/Close';
import { Box, TextField, Theme, withStyles } from '@material-ui/core';
import useDuplicateHeatAndRedirectToEditPage from '@app/hooks/useCopyHeatsAndRedirectToEditPage';
import FormButton from '@app/components/shared/FormButton';

interface CopyHeatModalProps {
  open: boolean;
  onClose: () => void;
  heatId: number;
  heatDescriptionInitialState: string;
  isDraft?: boolean;
}

const CopyHeatModal: React.FC<CopyHeatModalProps> = (props: CopyHeatModalProps) => {

  const { open, onClose, heatId, heatDescriptionInitialState, isDraft = false } = props;
  const { t } = useTranslation();
  const { hasAccess } = useAuthenticationContext();
  const [heatDescription, setHeatDescription] = useState(heatDescriptionInitialState);

  const [status, copyHeatAndRedirectToEditPage] = useDuplicateHeatAndRedirectToEditPage();

  const saveButtonIsDisabled = heatDescription.length === 0;

  const postSubmit = async () => {
    copyHeatAndRedirectToEditPage({description: heatDescription, heatId}, isDraft);
  }

  const StyledCloseIcon = withStyles((theme: Theme) => ({
    root: {
      '&:hover': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        transition: '0.25s'
      }
    },
  }))(CloseIcon);

  return (
    <>
      <Loading dataTestId='copy-heat-modal-circular-progress' promiseInProgress={status === 'loading'} />

      <Dialog
        open={open}
        maxWidth="sm"
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between', flex: 1, paddingRight: '24px', paddingTop: '20px' }}>
          <DialogTitle style={{ marginTop: '24px', maxWidth: '372px', paddingTop: '0'}}>{t('copy-heat-modal.title')}</DialogTitle>
          <StyledCloseIcon onClick={onClose} color="secondary" />
        </Box>
        <DialogContent>
          <TextField
            fullWidth
            id="copy-heat-description"
            value={heatDescription}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setHeatDescription(e.target.value)}
            label={t('heat.description')}
          />
        </DialogContent>
        <DialogActions style={{paddingTop: '43px'}}>
          <FormButton
            onClick={postSubmit}
            variant="primary"
            disabled={!hasAccess([PERMISSIONS.DRAFT_CALCULATION_CREATE]) || saveButtonIsDisabled}
          >
            {t('copy-heat-modal.button.duplicate.text')}
          </FormButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CopyHeatModal;
