import MainPaper from '@app/components/shared/MainPaper';
import { APP_TITLE, PERMISSIONS } from '@app/utils/constants';
import { makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import TabPanel from '../../../components/shared/TabPanel';
import { AdditionsTabs } from './additionsUtils';
import FluxesTab from './FluxesTab';
import AlloysTab from './AlloysTab';
import SlagDeoxidizersTab from './SlagDeoxidizersTab';
import AdditionsGeneralSettingModal from './modals/AdditionsGeneralSettingsModal';
import CreateEditFluxModal from './modals/CreateEditFluxModal';
import CreateEditAlloyModal from './modals/CreateEditAlloysModal';
import EditSlagDeoxidizerModal from './modals/EditSlagDeoxidizerModal';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import { useLocation } from 'react-router-dom';
import { ADDITIONS } from '@app/utils/plantSetupRoutesPathTabs';
import { clearLocationState } from '@app/utils/clearLocationState';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import { ROUTES_PATH } from '@app/utils';
import useLoadFluxesAvailabilitiesAndTypes from '@app/hooks/useLoadFluxesAvailabilitiesAndTypes';
import useLoadAlloys from '@app/hooks/useLoadAlloys';
import useLoadFluxes from '@app/hooks/useLoadFluxes';
import useLoadSlagDeoxidizers from '@app/hooks/useLoadSlagDeoxidizers';
import FormButton from '@app/components/shared/FormButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    borderBottom: 'none',
  },
  appBar: {
    boxShadow: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > :not(:first-child)': {
      marginLeft: 8,
    },
    justifyContent: 'center',
    padding: '24px',
    backgroundColor: theme.palette.background.paper,
  },

  cancelBtn: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#CFCFCF',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
}));

interface EditState {
  open: boolean,
  id?: number,
}

interface LocationState {
  option?: ADDITIONS,
}

const Additions = () => {

  const { state: plantState } = usePlantContext();
  const { hasAccess } = useAuthenticationContext();
  const location = useLocation<LocationState>();

  const classes = useStyles();
  const { t } = useTranslation();

  const currentTabInitialState =
    location.state?.option === ADDITIONS.ALLOYS ? AdditionsTabs.Alloys :
      location.state?.option === ADDITIONS.SLAG_DEOXIDIZERS ? AdditionsTabs.SlagDeoxidizers :
        AdditionsTabs.Fluxes;

  const [currentTab, setCurrentTab] = React.useState(currentTabInitialState);
  const [openGeneralSettingsModal, setOpenGeneralSettingsModal] = useState(false);
  const [openFluxModal, setOpenFluxModal] = useState<EditState>({ open: false, id: undefined });
  const [openAlloyModal, setOpenAlloyModal] = useState<EditState>({ open: false, id: undefined });
  const [openEditSlagDeoxidizerModal, setOpenEditSlagDeoxidizerModal] = useState<EditState>({ open: false, id: undefined });

  const [alloysInfoObject, loadAlloys] = useLoadAlloys();
  const [fluxesInfoObject, loadFluxes] = useLoadFluxes();
  const [slagDeoxidizersInfoObject, loadSlagDeoxidizers] = useLoadSlagDeoxidizers();

  const [
    loadFluxesDataStatus,
    loadFluxAvailabilitiesAndTypes,
    availabilities,
    types
  ] = useLoadFluxesAvailabilitiesAndTypes();

  useEffect(() => {
    if(loadFluxesDataStatus === 'idle') loadFluxAvailabilitiesAndTypes();
  }, [
    loadFluxesDataStatus,
    loadFluxAvailabilitiesAndTypes,
    availabilities,
    types
  ]);

  useEffect(() => {
    if (location.state?.option !== undefined) {
      clearLocationState();
    }
  }, [location.state])

  const handleChangeInTab = (event: React.ChangeEvent<{}>, newTab: AdditionsTabs) => { setCurrentTab(newTab) };

  const handleClickOnEditFlux = (id: number) => {
    setOpenFluxModal({
      open: true,
      id: id,
    })
  };

  const handleClickOnEditAlloy = (id: number) => {
    setOpenAlloyModal({
      open: true,
      id: id,
    })
  };

  const handleClickOnEditSlagDeoxidizer = (id: number) => {
    setOpenEditSlagDeoxidizerModal({
      open: true,
      id: id,
    })
  };

  const generalSettingsButton = (
    <FormButton
      data-testid="edit-additions-general-settings-button"
      key="btn-additions-general-settings"
      variant="secondary"
      title={t('general-settings')}
      onClick={() => setOpenGeneralSettingsModal(true)}
      disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_DETAIL,PERMISSIONS.PROCESS_CONSTRAINTS_LIST])}
    >
      {t('general-settings')}
    </FormButton>
  );

  const addNewButton = (
    <FormButton
      data-testid="add-new-flux-or-alloy"
      key="btn-additions-add-new"
      variant="primary"
      title={t('button.add-new')}
      onClick={() => {
        switch (currentTab) {
          case AdditionsTabs.Fluxes: {
            setOpenFluxModal({
              open: true,
              id: undefined,
            });
            break;
          }
          case AdditionsTabs.Alloys: {
            setOpenAlloyModal({
              open: true,
              id: undefined,
            });
            break;
          }
        }
      }}
      disabled={!hasAccess([PERMISSIONS.ALLOYS_CREATE, PERMISSIONS.FLUXES_CREATE])}
    >
      {t('button.add-new')}
    </FormButton>
  );

  return (
    <>
      <Helmet>
        <title>
          {t('overview.additions.title')} | {APP_TITLE}
        </title>
      </Helmet>
      {
        openGeneralSettingsModal && (
          <AdditionsGeneralSettingModal
            onClose={() => setOpenGeneralSettingsModal(false)}
          />
        )
      }

      {openFluxModal.open && (
        <CreateEditFluxModal
          open
          onClose={() => setOpenFluxModal({
            open: false,
            id: undefined,
          })}
          onSubmitChange={() => loadFluxes()}
          id={openFluxModal.id}
          availabilities={availabilities ?? []}
          types={types ?? []}
        />
      )}

      {openAlloyModal.open && (
        <CreateEditAlloyModal
          open
          onClose={() => setOpenAlloyModal({
            open: false,
            id: undefined,
          })}
          onSubmitChange={() => loadAlloys()}
          id={openAlloyModal.id}
        />
      )}

      {openEditSlagDeoxidizerModal.open && (
        <EditSlagDeoxidizerModal
          open
          onClose={() => setOpenEditSlagDeoxidizerModal({
            open: false,
            id: undefined,
          })}
          onSubmitChange={() => loadSlagDeoxidizers()}
          id={openEditSlagDeoxidizerModal.id}
        />
      )}

      <MainPaper
        title={t('overview.additions.title')}
        subheader={t('additions.subtitle')}
        removePadding
        headerActions={currentTab !== AdditionsTabs.SlagDeoxidizers ? [generalSettingsButton, addNewButton] : [generalSettingsButton]}
      >
        {
          (
            !plantState.data.general
          ) && (
            <WarningMessageComponent
              testId="additions-plant-setup-validations-warning-message"
              withPadding
              message={t('overview.empty-message')}
              link={{
                label: t('overview.view'),
                url: ROUTES_PATH.PLANT_OVERVIEW,
              }}
            />
          )
        }
        <Tabs
          value={currentTab}
          onChange={handleChangeInTab}
          className={classes.root}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab data-testid="additions.fluxes-tab.button" label={t('fluxes')} />
          <Tab data-testid="additions.alloys-tab.button" label={t('alloys')} />
          <Tab data-testid="additions.slagdeoxidizers-tab.button" label={t('slag-deoxidizers')} />
        </Tabs>
        <TabPanel value={currentTab} index={AdditionsTabs.Fluxes} style={{ height: '100%' }} childrenBoxSize="100%">
          <FluxesTab
            handleEdit={handleClickOnEditFlux}
            fluxesInfoObject={fluxesInfoObject}
            loadFluxes={loadFluxes}
            availabilities={availabilities ?? []}
            types={types ?? []}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={AdditionsTabs.Alloys} style={{ height: '100%' }} childrenBoxSize="100%">
          <AlloysTab
            handleEdit={handleClickOnEditAlloy}
            alloysInfoObject={alloysInfoObject}
            loadAlloys={loadAlloys}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={AdditionsTabs.SlagDeoxidizers} style={{ height: '100%' }} childrenBoxSize="100%">
          <SlagDeoxidizersTab
            handleEdit={handleClickOnEditSlagDeoxidizer}
            loadSlagDeoxidizers={loadSlagDeoxidizers}
            slagDeoxidizersInfoObject={slagDeoxidizersInfoObject}
          />
        </TabPanel>
      </MainPaper>

    </>
  );
};

export default Additions;
