import { FluxAvailability, FluxType } from "@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes";

export const mapAvailabilityKeyToTranslationKey = (
  availabilityKey: FluxAvailability
) => {
  switch (availabilityKey) {
    case FluxAvailability.Tapping:
      return 'tapping';
    case FluxAvailability.LadleFurnace:
      return 'ladlefurnace';
    case FluxAvailability.TappingAndLadleFurnace:
      return 'flux.table.body.availability.option.tapping-and-ladle-furnace';
    case FluxAvailability.FixedAdditionAtTapping:
      return 'flux.table.body.availability.option.fixed-at-tapping';
    case FluxAvailability.FixedAdditionAtLadleFurnace:
      return 'flux.table.body.availability.option.fixed-at-ladle-furnace';
    default:
      return '';
  }
}

export const mapTypeKeyToTranslationKey = (
  typeKey: FluxType
) => {
  switch (typeKey) {
    case FluxType.GeneralFlux:
      return 'fluxType.generalFlux';
  case FluxType.CaOSource:
    return 'fluxType.caoSource';
  case FluxType.DolomaSource:
      return 'fluxType.dolomaSource';
  case FluxType.MgOOnlySource:
    return 'fluxType.mgoOnlySource';
    default:
      return '';
  }
}
