import { Box, FormControl, InputAdornment, TextField } from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { PERMISSIONS, UOM_METERS_SYMBOL, UOM_MILLIMETERS_SYMBOL } from '@app/utils/constants';
import NumberFormat from 'react-number-format';
import { ProcessConstraintsDTO } from '@app/api/processConstraints/useProcessConstraintsService';
import SliderInput from '@app/components/SliderInput';
import FormWrapper from '@app/components/shared/FormWrapper';
import FormSectionContainer from '@app/components/shared/FormSectionContainer';
import FormSelect from '@app/components/shared/FormSelect';
import { useSystemOfMeasurementOptions } from '@app/hooks/useSystemOfMeasurementOptions';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { InfoObject } from '@app/hooks/types';
import useRenderInputEndAdornment from '@app/hooks/useRenderInputEndAdornment';
import { convertMetersIntoOtherUnitOfMeasurement } from '@app/utils/convertLengthUnits';
import { LimitInfo } from '@app/api/limits/limitsTypes';
import { Controller, useFormContext } from 'react-hook-form';

interface LadleTabProps {
  processConstraintsInfoObject: InfoObject<ProcessConstraintsDTO>;
  loadProcessConstraints: () => Promise<void>;
}

const LadleTab = (props: LadleTabProps): React.ReactElement => {
  const {
    processConstraintsInfoObject,
    loadProcessConstraints
  } = props;

  const { t } = useTranslation();
  const { hasAccess, state: {  userInfoObject } } = useAuthenticationContext();

  const userMeasurementSystem = userInfoObject?.data?.measurementSystem;

  const { status: processContraintsStatus } = processConstraintsInfoObject;

  useEffect(() => {
    if(processContraintsStatus === 'idle') {
      loadProcessConstraints();
    }
  }, [loadProcessConstraints, processContraintsStatus]);

  const { state: { limitsInfoObject } } = useAuthenticationContext();

  const limitsInfo = limitsInfoObject?.data;

  const ladleCapacityLimits = limitsInfo?.ladleCapacity as LimitInfo;
  const maximumSlagWeightLimits = limitsInfo?.maximumSlagWeight as LimitInfo;
  const minimumSlagWeightAfterSkimmerLimits = limitsInfo?.minimumSlagWeightAfterSkimmer as LimitInfo;
  const ladleDiameterLimits = limitsInfo?.ladleDiameter as LimitInfo;
  const initialLiningThicknessLimits = limitsInfo?.initialLiningThickness as LimitInfo;
  const finalLiningThicknessLimits = limitsInfo?.finalLiningThickness as LimitInfo;

  const isMeasurementSystemMetric = userMeasurementSystem === SystemOfMeasurementOptions.Metric;

  const initialLiningThicknessMaxValue = isMeasurementSystemMetric
    ? convertMetersIntoOtherUnitOfMeasurement(initialLiningThicknessLimits.maximum,UOM_MILLIMETERS_SYMBOL)
    : initialLiningThicknessLimits.maximum;
  const finalLiningThicknessMaxValue = isMeasurementSystemMetric
    ? convertMetersIntoOtherUnitOfMeasurement(finalLiningThicknessLimits.maximum,UOM_MILLIMETERS_SYMBOL)
    : finalLiningThicknessLimits.maximum;

  const formThicknessInputsStyles: React.CSSProperties = { height: 'fit-content', display: 'flex', flex: 1, minWidth: '100px' };

  const formSectionStyles: React.CSSProperties = { minWidth: '200px' };

  const { systemOfMeasurementOptions } = useSystemOfMeasurementOptions();

  const { renderLengthInputEndAdornment,  renderWeightInputEndAdornment} = useRenderInputEndAdornment();

  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <Box data-testid="equipment.ladle-tab.ladle-form-content">
        <FormWrapper
          style={{ padding: '8px', justifyContent: 'space-evenly', overflow: 'auto' }}
        >
          <FormSectionContainer
            title={t('general-settings')}
            subtitle={t('processConstraints.general-settings.subtitle')}
            style={formSectionStyles}
          >
            <Controller
              name='measurementSystem'
              control={control}
              render={({ field }) => (
                <FormSelect
                  fullWidth
                  id='measurement-system'
                  label={t('user.profile.select.measurement-system.label')}
                  options={systemOfMeasurementOptions}
                  {...field}
                  disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                />
              )}
            />
            <FormControl fullWidth>
              <Controller
                name="ladleSteelWeight"
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    autoComplete="off"
                    fullWidth
                    id="ladleSteelWeight"
                    label={t('processConstraints.text-field.label.steel-weight')}
                    title={t('processConstraints.text-field.title.steel-weight')}
                    error={!!errors?.ladleSteelWeight?.message}
                    helperText={errors?.ladleSteelWeight?.message}
                    InputProps={{
                      endAdornment: renderWeightInputEndAdornment(userMeasurementSystem, 'st'),
                    }}
                    inputProps={{
                      ['data-testid']: 'ladle-form.field.ladleSteelWeight'
                    }}
                    {...field}
                    onChange={
                      (e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.value === '' ? '' : Number(e.target.value))
                      }
                    }
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={ladleCapacityLimits?.decimalScale}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= ladleCapacityLimits.maximum}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name="maximumSlagWeight"
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    autoComplete="off"
                    fullWidth
                    id="maximumSlagWeight"
                    label={t('processConstraints.text-field.label.max-slag')}
                    title={t('processConstraints.text-field.title.max-slag')}
                    error={!!errors?.maximumSlagWeight?.message}
                    helperText={errors?.maximumSlagWeight?.message}
                    InputProps={{
                      endAdornment: renderWeightInputEndAdornment(userMeasurementSystem, 'lb'),
                    }}
                    inputProps={{
                      ['data-testid']: 'ladle-form.field.maximumSlagWeight'
                    }}
                    {...field}
                    onChange={
                      (e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.value === '' ? '' : Number(e.target.value))
                      }
                    }
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={maximumSlagWeightLimits?.decimalScale}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= maximumSlagWeightLimits.maximum}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name='minimumSlagWeightAfterSkimmerUse'
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    id="minimumSlagWeightAfterSkimmerUse"
                    label={t('processConstraints.text-field.label.min-slag-after-skimmer')}
                    title={t('processConstraints.text-field.title.min-slag-after-skimmer')}
                    error={!!errors?.minimumSlagWeightAfterSkimmerUse?.message}
                    helperText={errors?.minimumSlagWeightAfterSkimmerUse?.message}
                    InputProps={{
                      endAdornment: renderWeightInputEndAdornment(userMeasurementSystem, 'lb'),
                    }}
                    inputProps={{
                      ['data-testid']: 'ladle-form.field.minimumSlagWeightAfterSkimmerUse'
                    }}
                    {...field}
                    onChange={
                      (e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.value === '' ? '' : Number(e.target.value))
                      }
                    }
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                    autoComplete="off"
                    fullWidth
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={minimumSlagWeightAfterSkimmerLimits?.decimalScale}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= minimumSlagWeightAfterSkimmerLimits.maximum}
                  />
                )}
              />
            </FormControl>
            <Controller
              name='toleranceCalcMinSlagThickness'
              control={control}
              render={({field}) => {
                return(
                  <SliderInput
                    id='toleranceCalcMinSlagThickness'
                    label={t('processConstraints.text-field.label.tolerance-calc-min-slag')}
                    {...field}
                    onChange={(e: ChangeEvent<{}>, value: number | number[]) => {
                      field.onChange(value);
                    }}
                    testId='ladle-form.field.toleranceCalcMinSlagThickness'
                    maxValue={100}
                    minValue={-100}
                    calculateValue={(value) => value + 100}
                    valueLabelFormat={(value) => `${value}%`}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                    marks={[
                      {value: -80, label: '20%'},
                      {value: -40, label: '60%'},
                      {value: 0, label: '100%'},
                      {value: 40, label: '140%'},
                      {value: 80, label: '180%'},
                    ]}
                  />
                )
              }}
            />
          </FormSectionContainer>

          <FormSectionContainer
            title={t('dynamic-ladle-diameter')}
            subtitle={t('overview.equipment.dynamic-ladle-diameter.subtitle')}
            style={formSectionStyles}
          >
            <FormControl fullWidth>
              <Controller
                name="ladleDiameter"
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    id="ladleDiameter"
                    label={t('processConstraints.text-field.label.ladle-diameter')}
                    title={t('processConstraints.text-field.title.ladle-diameter')}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={ladleDiameterLimits?.decimalScale}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= (ladleDiameterLimits.maximum)}
                    error={errors?.ladleDiameter?.message}
                    helperText={errors?.ladleDiameter?.message}
                    InputProps={{
                      endAdornment: renderLengthInputEndAdornment(userMeasurementSystem, UOM_METERS_SYMBOL),
                    }}
                    inputProps={{
                      ['data-testid']: 'ladle-form.field.ladleDiameter'
                    }}
                    {...field}
                    onChange={
                      (e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.value === '' ? '' : Number(e.target.value))
                      }
                    }
                    autoComplete="off"
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name="averageLadleLifetime"
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    id="averageLadlelifetime"
                    label={t('processConstraints.text-field.label.average-ladlelifetime')}
                    title={t('processConstraints.text-field.title.average-ladlelifetime')}
                    error={!!errors?.averageLadleLifetime?.message}
                    helperText={errors?.averageLadleLifetime?.message}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                        {t('processConstraints.text-field.input-endorment.title.average-ladlelifetime')}
                        </InputAdornment>,
                    }}
                    inputProps={{
                      ['data-testid']: 'ladle-form.field.averageLadleLifetime'
                    }}
                    {...field}
                    onChange={
                      (e: ChangeEvent<HTMLInputElement>) => {
                        field.onChange(e.target.value === '' ? '' : Number(e.target.value))
                      }
                    }
                    autoComplete="off"
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                    fullWidth
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={0}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= 400}
                  />
                )}
              />
            </FormControl>
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', height: 'fit-content' }}>
              <FormControl style={formThicknessInputsStyles}>
                <Controller
                  name="initialLiningThickness"
                  control={control}
                  render={({ field }) => (
                    <NumberFormat
                      id="initialLiningThickness"
                      label={t('processConstraints.text-field.label.initial-lining-thickness')}
                      title={t('processConstraints.text-field.title.initial-lining-thickness')}
                      InputProps={{
                        endAdornment: renderLengthInputEndAdornment(userMeasurementSystem, UOM_MILLIMETERS_SYMBOL),
                      }}
                      inputProps={{
                        ['data-testid']: 'ladle-form.field.initialLiningThickness'
                      }}
                      {...field}
                      onChange={
                        (e: ChangeEvent<HTMLInputElement>) => {
                          field.onChange(e.target.value === '' ? '' : Number(e.target.value))
                        }
                      }
                      error={!!errors?.initialLiningThickness?.message}
                      helperText={errors?.initialLiningThickness?.message}
                      autoComplete="off"
                      disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                      fullWidth
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={initialLiningThicknessLimits?.decimalScale}
                      decimalSeparator={t('decimal-scale-separator')}
                      isAllowed={({ floatValue }) => (floatValue ?? 0) <= initialLiningThicknessMaxValue}
                    />
                  )}
                />
              </FormControl>
              <FormControl style={formThicknessInputsStyles}>
                <Controller
                  name="finalLiningThickness"
                  control={control}
                  render={({ field }) => (
                    <NumberFormat
                      id="finalLiningThickness"
                      label={t('processConstraints.text-field.label.final-lining-thickness')}
                      title={t('processConstraints.text-field.title.final-lining-thickness')}
                      InputProps={{
                        endAdornment: renderLengthInputEndAdornment(userMeasurementSystem, UOM_MILLIMETERS_SYMBOL),
                      }}
                      inputProps={{
                        ['data-testid']: 'ladle-form.field.finalLiningThickness'
                      }}
                      {...field}
                      error={!!errors?.finalLiningThickness?.message}
                      helperText={errors?.finalLiningThickness?.message}
                      onChange={
                        (e: ChangeEvent<HTMLInputElement>) => {
                          field.onChange(e.target.value === '' ? '' : Number(e.target.value))
                        }
                      }
                      autoComplete="off"
                      disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                      fullWidth
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={finalLiningThicknessLimits?.decimalScale}
                      decimalSeparator={t('decimal-scale-separator')}
                      isAllowed={({ floatValue }) => (floatValue ?? 0) <= finalLiningThicknessMaxValue}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </FormSectionContainer>
        </FormWrapper>
      </Box>
    </>
  );
};

export default LadleTab;
