import { SystemOfMeasurementOptions } from "@app/api/me/MeGetResponse";
import { UOM_CELSIUS_SYMBOL, UOM_FAHRENHEIT_SYMBOL, UOM_INCHES_SYMBOL, UOM_KILOGRAMS_SYMBOL } from "@app/utils/constants";

const useReturnUnitOfMeasurement = () => {
    
    const getUnitOfMeasurement = (measurementSystem: SystemOfMeasurementOptions | undefined, imperialUnitOfMeasurement: string, metricUnitOfMeasurement:string) => {
      return measurementSystem === SystemOfMeasurementOptions.Imperial ? imperialUnitOfMeasurement : metricUnitOfMeasurement;    
    }

    const returnTemperatureUnitOfMeasurement = (measurementSystem: SystemOfMeasurementOptions | undefined) => {
      return getUnitOfMeasurement(measurementSystem, UOM_FAHRENHEIT_SYMBOL, UOM_CELSIUS_SYMBOL )
    }

    const returnWeightUnitOfMeasurement = (measurementSystem: SystemOfMeasurementOptions | undefined, imperialUnitOfMeasurement: 'lb' | 'st') => { 
      return getUnitOfMeasurement(measurementSystem, imperialUnitOfMeasurement, UOM_KILOGRAMS_SYMBOL)
    }
    
    const returnLengthUnitOfMeasurement = (measurementSystem: SystemOfMeasurementOptions | undefined, metricUnitOfMeasurement: 'm' | 'mm' | 'cm') => {
      return getUnitOfMeasurement(measurementSystem, UOM_INCHES_SYMBOL, metricUnitOfMeasurement)
    }

    return ({
      returnTemperatureUnitOfMeasurement,
      returnWeightUnitOfMeasurement,
      returnLengthUnitOfMeasurement
    })
}

export default useReturnUnitOfMeasurement;