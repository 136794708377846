import { GetLadleFurnaceResponse } from "@app/api/ladleFurnaces";
import FormButton from "@app/components/shared/FormButton";
import { handleSendEvent } from "@app/utils";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface DeleteLadleFurnaceModalProps {
    onClose: () => void,
    onConfirm: (id: number) => void,
    ladleFurnace?: GetLadleFurnaceResponse
}

const DeleteLadleFurnaceModal = (props: DeleteLadleFurnaceModalProps) => {

    const { t } = useTranslation();

    const { onClose, onConfirm, ladleFurnace } = props;

    const handleClose = () => {
        handleSendEvent({
          category: 'BasicSettings/LadleFurnaces',
          action: 'User Closed Delete LadleFurnace Modal.',
        });
        onClose();
    };

    return (
        <Dialog
            open
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {t('exclusion-confirmation.dialog.title', { item: ladleFurnace?.name })}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t('data.exclusion.message')}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <FormButton
                data-testid="delete-ladle-furnace.dialog.button.cancel"
                onClick={handleClose}
                variant="secondary"
            >
                {t('button.cancel')}
            </FormButton>
            <FormButton
                data-testid="delete-ladle-furnace.dialog.button.confirm"
                onClick={() => onConfirm(ladleFurnace?.id ?? -1)}
                variant="primary"
            >
                {t('button.delete')}
            </FormButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteLadleFurnaceModal;
