import { PlantStatus } from "@app/api/plants/PlantsGetResponse";
import { UserStatus } from "@app/api/users/UsersGetResponse"
import { Box, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface StatusComponentProps {
  status: UserStatus | PlantStatus;
}

export const getStatusTranslationKey = (status: UserStatus | PlantStatus) => {
  switch(status) {
    case UserStatus.INVITATION_SENT:
        return 'admin-panel.status.invitation-sent'
    case UserStatus.ACTIVE:
        return 'admin-panel.status.active';
    case UserStatus.BLOCKED:
        return 'admin-panel.status.blocked';
    case UserStatus.BLOCKED_BY_PLANT:
        return 'admin-panel.status.blocked-by-plant';
    case PlantStatus.TRIAL:
        return 'plant-management-table.status.trial';
    default:
        return '-';
  }
}

export const getStatusByItsTranslationKey = (statusKey: string) => {
  switch(statusKey) {
    case 'admin-panel.status.invitation-sent':
      return UserStatus.INVITATION_SENT;
    case 'admin-panel.status.active':
      return UserStatus.ACTIVE;
    case 'admin-panel.status.blocked':
      return UserStatus.BLOCKED;
    case 'admin-panel.status.blocked-by-plant':
      return UserStatus.BLOCKED_BY_PLANT;
    case 'plant-management-table.status.trial':
      return PlantStatus.TRIAL;
    default:
      return '-';
  }
}

const StatusComponent = ({ status }: StatusComponentProps) => {
  const { t } = useTranslation();
  const {
    palette: {
      common: { white },
      custom: {
        uncheckedRadioButtonPrimaryColor: {
            main: primaryFontColor
        },
        userStatus: {
          invitationSent: invitationSentUserColor,
          active: activeUserColor,
          blocked: blockedUserColor,
          blockedByPlant: blockedByPlantUserColor
        },
        plantStatus: {
          trial: trialPlantColor
        }
      }
    }
  } = useTheme();

  const getStatusBackgroundColor = () => {
    switch(status) {
      case UserStatus.INVITATION_SENT:
        return invitationSentUserColor.main;
      case UserStatus.ACTIVE:
        return activeUserColor.main;
      case UserStatus.BLOCKED:
        return blockedUserColor.main;
      case UserStatus.BLOCKED_BY_PLANT:
        return blockedByPlantUserColor.main;
      case PlantStatus.TRIAL:
        return trialPlantColor.main;
      default:
        return white;
    }
  }

  const getStatusFontColor = () => {
    if(status === UserStatus.INVITATION_SENT) {
      return primaryFontColor;
    } else {
      return white;
    }
  }

  const statusBoxStyle = {
      backgroundColor: getStatusBackgroundColor(),
      color: getStatusFontColor(),
      padding: '4px 8px',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center'
  }

  return (
    <Box style={statusBoxStyle}>
      <Typography variant="body2">{t(getStatusTranslationKey(status))}</Typography>
    </Box>
  )
}

export default StatusComponent;
