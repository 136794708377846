import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Theme, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormButton from './FormButton';

interface ConfirmDialogProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: React.ReactNode | string;
  cancelText?: string;
  confirmButtonTestId?: string;
  cancelButtonTestId?: string;
  cancelAction: () => void;
  confirmText?: string;
  confirmAction: (confirmationReason?: string) => void;
  disableSaveButton?: boolean;
  dialogTestId?: string;
  checkboxEnabled?: boolean;
  confirmationReasonEnabled?: boolean;
  confirmationReasonText?: string;
  fullWidth?: boolean;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { t } = useTranslation();
  const {
    visible,
    setVisible,
    title,
    content,
    cancelText = t('dialog.button.cancel'),
    cancelButtonTestId = `dialog.cancel-button`,
    cancelAction,
    confirmText = t('dialog-confim.button.confirm'),
    confirmButtonTestId = `dialog.button.confirm`,
    confirmAction,
    disableSaveButton = false,
    checkboxEnabled = false,
    confirmationReasonEnabled = false,
    confirmationReasonText = t('dialog-confim.confirmation.reason'),
    fullWidth = false,
    dialogTestId = `${title.split(' ')[0]}-${confirmText?.split(' ')[0]}`
  } = props;
  
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [userConfirmationReason, setUserConfirmationReason] = useState<string>();
  
  useEffect(() => {
    if (visible) {
      setUserConfirmed(false);
      setUserConfirmationReason(undefined);
    }
  }, [visible])

  function handleClose() {
    setUserConfirmed(false);
    setVisible(false);
    setUserConfirmationReason(undefined);
  }

  const StyledCloseIcon = withStyles((theme: Theme) => ({
    root: {
      '&:hover': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        transition: '0.25s'
      }
    },
  }))(CloseIcon);

  return (
    <Dialog fullWidth={fullWidth} data-testid={dialogTestId} open={visible} onClose={handleClose}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1, paddingRight: '24px' }}>
        <DialogTitle style={{ flex: 1 }}>{title}</DialogTitle>
        <StyledCloseIcon onClick={handleClose} color='secondary' />
      </Box>

      <DialogContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
        {checkboxEnabled && (
          <Checkbox
            style={{ padding: 0 }}
            color='primary'
            checked={userConfirmed}
            onChange={
              (_, checked) => {
                setUserConfirmed(checked);
              }
            }
          />
        )}
        {typeof content === 'string' ? <DialogContentText style={{ margin: 0 }}>{content}</DialogContentText> : content}
      </DialogContent>

      {confirmationReasonEnabled &&
        <DialogContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', overflow: 'hidden', color: 'black' }}>
          <TextField
            name='confirmationReason'
            autoComplete='off'
            variant='outlined'
            minRows='4'            
            fullWidth
            multiline
            label={confirmationReasonText}
            value={userConfirmationReason}
            onChange={e => setUserConfirmationReason(e.target.value)}
            inputProps={{ maxLength: 150 }}
          />
        </DialogContent>
      }

      <DialogActions>
        <FormButton 
          data-testid={cancelButtonTestId} 
          variant='secondary' 
          onClick={cancelAction} >
          {cancelText}
        </FormButton>
        <FormButton 
          data-testid={confirmButtonTestId} 
          variant='primary' 
          onClick={() => confirmAction(userConfirmationReason)}
          disabled={
            disableSaveButton 
            || (checkboxEnabled && !userConfirmed)
            || (confirmationReasonEnabled && (!userConfirmationReason || !userConfirmationReason.trim().length))
          } >
          {confirmText}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
