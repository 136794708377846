import { useHeatsService } from '@app/api/heats';
import { IterationChartResponse, IterationChartStatus, IterationChartType } from '@app/api/heats/HeatsResponse';
import FormButton from '@app/components/shared/FormButton';
import TabPanel from '@app/components/shared/TabPanel';
import useCallApi from '@app/hooks/useCallApi';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tab, Tabs, Theme, Typography, makeStyles } from '@material-ui/core';
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import fdownload from 'js-file-download';
import CustomTooltip from '@app/components/shared/CustomTooltip';
import { CloudDownloadOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { IterationChartGenerationMode } from './IterationChartsButton';
import { HeatMode } from '../NewHeat';
import Swal from 'sweetalert2';

const chartSize = {
  width: '1000px',
  height: '500px',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabContent: {
    marginTop: '16px',
    width: chartSize.width,
    height: chartSize.height,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxWidth: chartSize.width, 
      maxHeight: chartSize.height,
    },
  },
  buttonEnabled: {
    color: '#0E93DD'
  },
  buttonDisabled: {
    color: theme.palette.custom.disabledColor.main,
  },
}));

enum IterationChartIndex {
  Weights,
  RelativeSaturation,
  Slags,
}

interface ChartMapEntry {
  type: IterationChartType,
  title: string,
}

interface IterationChartsModalProps {
  id: string | number;
  mode: IterationChartGenerationMode;
  heatMode: HeatMode;
  onClose: () => void;
  onLoadWithSuccess?: () => void;
}

const chartMap = new Map<number, ChartMapEntry>([
  [IterationChartIndex.Weights, {
    type: IterationChartType.Weights,
    title: 'Weights',
  }],
  [IterationChartIndex.RelativeSaturation, {
    type: IterationChartType.RelativeSaturation,
    title: 'Relative Saturation',
  }],
  [IterationChartIndex.Slags, {
    type: IterationChartType.Slags,
    title: 'Slags',
  }],
]);

const backOffTime = 2500;

const IterationChartsModal = (props: IterationChartsModalProps): ReactElement => {
  const classes = useStyles();
  
  const { t } = useTranslation();
  const { id, mode, heatMode, onClose, onLoadWithSuccess } = props;
  const { generateChartByCalculationId, getChartByHeatId, getChartByDraftId } = useHeatsService();

  const [downloadIconTooltipText] = useState(t('calculation-chart.modal.download.text'));
  const [currentTab, setCurrentTab] = useState(IterationChartIndex.Weights);
  const [weightsChart, setWeightsChart] = useState<IterationChartResponse>();
  const [relativeSaturationChart, setRelativeSaturationChart] = useState<IterationChartResponse>();
  const [slagsChart, setSlagsChart] = useState<IterationChartResponse>();

  const getIterationChartsByCalculationId = async (): Promise<IterationChartResponse[]> => {
    const charts = await Promise.all([
      generateChartByCalculationId(id as string, { type: IterationChartType.Weights }),
      generateChartByCalculationId(id as string, { type: IterationChartType.RelativeSaturation }),
      generateChartByCalculationId(id as string, { type: IterationChartType.Slags }),
    ]);

    return charts;
  }

  const getIterationChartsByHeatMode = async (): Promise<IterationChartResponse[]> => {
    let charts: IterationChartResponse[] = [];

    switch (heatMode) {
      case HeatMode.Draft:
        charts = (await Promise.all([
          getChartByDraftId(id as number, IterationChartType.Weights),
          getChartByDraftId(id as number, IterationChartType.RelativeSaturation),
          getChartByDraftId(id as number, IterationChartType.Slags),
        ])).flat();
        break;
      case HeatMode.Operation:
        charts = (await Promise.all([
          getChartByHeatId(id as number, IterationChartType.Weights),
          getChartByHeatId(id as number, IterationChartType.RelativeSaturation),
          getChartByHeatId(id as number, IterationChartType.Slags),
        ])).flat();
        break;
    }

    return charts;
  }

  const getIterationCharts = async (): Promise<IterationChartResponse[]> => {
    let charts: IterationChartResponse[] = [];
    
    switch (mode) {
      case IterationChartGenerationMode.GenerateEphemeralCharts:
        charts = await getIterationChartsByCalculationId();
        break;
      case IterationChartGenerationMode.RetrieveSavedCharts:
      case IterationChartGenerationMode.GenerateAndSaveCharts:
        charts = await getIterationChartsByHeatMode();        
        break;
    }

    return charts;
  }

  const [iterationChartsResult, callIterationCharts] = useCallApi(getIterationCharts, []);

  const closeCallback = useCallback(() => onClose(), [onClose]);

  const loadWithSuccessCallback = useCallback(() => onLoadWithSuccess && onLoadWithSuccess(), [onLoadWithSuccess]);

  useEffect(() => {
    if (iterationChartsResult.status === 'idle') {
      callIterationCharts();
    } else if (iterationChartsResult.status === 'error') {
      closeCallback();
    }
  }, [iterationChartsResult.status, callIterationCharts, closeCallback]);

  useEffect(() => {
    if (iterationChartsResult.status === 'succeeded' && iterationChartsResult.data) {
      if (iterationChartsResult.data.every(x => x.status === IterationChartStatus.Success)) {
        setWeightsChart(iterationChartsResult.data[IterationChartIndex.Weights]);
        setRelativeSaturationChart(iterationChartsResult.data[IterationChartIndex.RelativeSaturation]);
        setSlagsChart(iterationChartsResult.data[IterationChartIndex.Slags]);

        loadWithSuccessCallback();
      } else if (iterationChartsResult.data.every(x => [IterationChartStatus.Error, IterationChartStatus.ErrorLogAnalytics, IterationChartStatus.ErrorAzureFunction].includes(x.status))) {
        const errorResponse = iterationChartsResult.data.find(x => [IterationChartStatus.Error, IterationChartStatus.ErrorLogAnalytics, IterationChartStatus.ErrorAzureFunction].includes(x.status));

        Swal.fire({
          icon: 'error',
          title: t('error-message.title'),
          text: errorResponse?.errorDetails,
          html: '',
        });
        
        closeCallback();
      } else {
        setTimeout(() => {
          callIterationCharts();
        }, backOffTime);
      }
    }
  }, [iterationChartsResult.status, iterationChartsResult.data, callIterationCharts, closeCallback, loadWithSuccessCallback, t]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newTab: number) => setCurrentTab(newTab);

  const selectChartResponseByType = (type: IterationChartType): IterationChartResponse | undefined => {
    let chartResponse: IterationChartResponse | undefined;

    switch (type) {
      case IterationChartType.Weights:
        chartResponse = weightsChart;
        break;
      case IterationChartType.RelativeSaturation:
        chartResponse = relativeSaturationChart;
        break;
      case IterationChartType.Slags:
        chartResponse = slagsChart;
        break;
      default:
        chartResponse = undefined;
        break;
    }

    return chartResponse;
  }

  const renderChartTabPanel = (index: IterationChartIndex): JSX.Element => {
    const entry = chartMap.get(index);
    
    if (entry) {
      return (
        <TabPanel value={currentTab} index={index} className={classes.tabContent} childrenBoxSize='100%'>
          {renderChart(entry)}
        </TabPanel>
      );
    }

    return (
      <></>
    );
  }

  const renderChart = (chartEntry: ChartMapEntry): JSX.Element => {
    const chartResponse = selectChartResponseByType(chartEntry.type);

    if (chartResponse) {
      return (
        <Box className={classes.imageWrapper}>
          <img src={`data:${chartResponse.mimeType};base64,${chartResponse.data}`}
            alt={chartEntry.title} />
        </Box>
      );
    }

    return (
      <Box>
        <LinearProgress /> 
        <Typography variant="body2">{t('calculation-chart.modal.loading.text')}</Typography>
      </Box>
    );
  }

  const downloadCurrentChart = (): void => {
    const chartEntry = chartMap.get(currentTab);
    
    if (chartEntry) {
      const chartResponse = selectChartResponseByType(chartEntry.type);

      if (chartResponse) {
        const buffer = Buffer.from(chartResponse.data, 'base64');

        fdownload(buffer, `Iteration Chart - ${chartEntry.title}.png`, chartResponse.mimeType);
      }
    }
  }

  const renderDownloadAndCloseButton = (): JSX.Element => {
    const disabled = !weightsChart || !relativeSaturationChart || !slagsChart;

    return (
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <IconButton
          onClick={downloadCurrentChart}
          disabled={disabled} >
          <CustomTooltip title={downloadIconTooltipText} >
            <CloudDownloadOutlined 
              color='primary' 
              fontSize='large'
              className={clsx(classes.buttonEnabled, {
                [classes.buttonDisabled]: disabled,
              })} />
          </CustomTooltip>
        </IconButton>
        <FormButton
          onClick={onClose}
          variant='secondary' >
          {t('button.close')}
        </FormButton>
      </Box>
    );
  }

  return (
    <Dialog open maxWidth='xl'>
      <DialogTitle>
        {t('calculation-chart.modal.title')}
      </DialogTitle>

      <DialogContent>
        <Box className={classes.root}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            className={classes.tabs} 
            variant="fullWidth" >
            <Tab label={t('calculation-chart.modal.tab-weights.title')} />
            <Tab label={t('calculation-chart.modal.tab-relative-saturation.title')} />
            <Tab label={t('calculation-chart.modal.tab-slags.title')} />
          </Tabs>
          {renderChartTabPanel(IterationChartIndex.Weights)}
          {renderChartTabPanel(IterationChartIndex.RelativeSaturation)}
          {renderChartTabPanel(IterationChartIndex.Slags)}
        </Box>
      </DialogContent>

      <DialogActions>        
        {renderDownloadAndCloseButton()}
      </DialogActions>      
    </Dialog>
  )
}

export default IterationChartsModal;