import logo from '@app/assets/images/logo-big.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function Logo({open = true}: {open?: boolean}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={logo} width={open ? 84 : 55} height={open ? 50 : 33} alt="RHI Magnesita" />
    </div>
  );
}

export default Logo;
