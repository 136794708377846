import { SystemOfMeasurementOptions } from "@app/api/me/MeGetResponse";
import { FormSelectOptions } from "@app/components/shared/FormSelect";
import { useTranslation } from "react-i18next";

export const useSystemOfMeasurementOptions = () => {
    const { t } = useTranslation();
    const systemOfMeasurementOptions: FormSelectOptions<SystemOfMeasurementOptions> = [
        { 
          name: t('user.profile.select.measurement-system.option.imperial-system'),
          value: SystemOfMeasurementOptions.Imperial
        },
        {
          name: t('user.profile.select.measurement-system.option.metric-system'),
          value: SystemOfMeasurementOptions.Metric
        }
      ]
    return ({systemOfMeasurementOptions})
}