// APP TEXT
const envName = process.env.REACT_APP_ENVIRONMENT_NAME ? process.env.REACT_APP_ENVIRONMENT_NAME + ' ' : '';
export const APP_TITLE = envName + 'Ladle Slag Model';
export const ENV_NAME = envName;
export const NOT_APPLICABLE_TEXT = 'N/A';

// USER ROLES
export enum UserRoles {
  OPERATOR = 'OPERATOR',
  SUPERVISOR = 'SUPERVISOR',
  PLANT_ADMIN = 'PLANT ADMIN',
  TECHNICAL_CONSULTANT = 'TECHNICAL CONSULTANT',
  KEY_USER = 'KEY USER',
  ADMINISTRATOR = 'ADMINISTRATOR',
}

export const UOM_METERS_SYMBOL = 'm';
export const UOM_CENTIMETERS_SYMBOL = 'cm';
export const UOM_MILLIMETERS_SYMBOL = 'mm';
export const UOM_KILOGRAMS_SYMBOL = 'kg';
export const UOM_CELSIUS_SYMBOL = '°C';
export const UOM_INCHES_SYMBOL = 'in';
export const UOM_POUNDS_SYMBOL = 'lb';
export const UOM_SHORT_TONS_SYMBOL = 'st';
export const UOM_FAHRENHEIT_SYMBOL = '°F';

// UI CONSTANTS
export const FOOTER_HEIGHT = 45;
export const HEADER_HEIGHT = 64;
export const DRAWER_WIDTH = 290;
export const COLLAPSED_DRAWER_WIDTH = 95;
export const DRAWER_TOOLBAR_HEIGHT = 130;
export const MAIN_CONTENT_PADDING = 10;

// NUMERIC FIELDS CONSTANTS
export const MIN_ALLOY_CHEMISTRY_TOTAL = 10;
export const MAX_ALLOY_CHEMISTRY_TOTAL = 100;
export const MIN_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL = 70;
export const MAX_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL = 105;
export const MIN_INITIAL_SULFUR_PERCENTAGE = 0.0001;
export const MAX_INITIAL_SULFUR_PERCENTTAGE = 0.1;
export const MIN_OXYGEN_CONTENT = 10;
export const MAX_OXYGEN_CONTENT = 2000;
export const MAX_WEIGHT = 10000;
export const CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES = 2;
export const CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES = 3;
export const CHEMISTRY_AT_TAPPING_ALUMINUM_PERCENTAGE_DECIMAL_PLACES = 4;
export const CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR = 100;
export const CHEMISTRY_AT_TAPPING_ALUMINUM_POINTS_CONVERSION_FACTOR = 1000;
export const CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE = 100;
export const CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_PERCENTAGE = 100;
export const CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS = CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE * CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR;
export const CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_POINTS = CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_PERCENTAGE * CHEMISTRY_AT_TAPPING_ALUMINUM_POINTS_CONVERSION_FACTOR;

// METRIC SYSTEM CONVERSION FACTORS
export const CENTIMETERS_METERS_CONVERSION_FACTOR = 100;
export const MILLIMETERS_METERS_CONVERSION_FACTOR = 1000;

const convertStringToBoolean = (argument: string | undefined) => {
  if (!argument) {
    return false;
  } else if (argument.toLowerCase() === 'true' || argument === '1') {
    return true;
  } else {
    return false;
  }
};

// MSAL CONFIGURATION
export const MSAL_CLIENT_ID_B2B = process.env.REACT_APP_MSAL_CLIENT_ID_B2B || '';
export const MSAL_AUTHORITY_B2B = process.env.REACT_APP_MSAL_AUTHORITY_B2B || '';
export const MSAL_REDIRECT_URI_B2B = process.env.REACT_APP_MSAL_REDIRECT_URI_B2B || '';
export const MSAL_POST_LOGOUT_REDIRECT_URI_B2B = process.env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI_B2B || '';
export const MSAL_SCOPES_B2B = [process.env.REACT_APP_MSAL_SCOPE_1_B2B || ''];

export const MSAL_CLIENT_ID_B2C = process.env.REACT_APP_MSAL_CLIENT_ID_B2C || '';
export const MSAL_AUTHORITY_B2C = process.env.REACT_APP_MSAL_AUTHORITY_B2C || '';
export const MSAL_REDIRECT_URI_B2C = process.env.REACT_APP_MSAL_REDIRECT_URI_B2C || '';
export const MSAL_POST_LOGOUT_REDIRECT_URI_B2C = process.env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI_B2C || '';
export const MSAL_SCOPES_B2C = [process.env.REACT_APP_MSAL_SCOPE_1_B2C || ''];

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const APP_ON_PREMISES = convertStringToBoolean(process.env.REACT_APP_ON_PREMISES);
export const APP_LOCAL_ENVIRONMENT = convertStringToBoolean(process.env.REACT_APP_LOCAL_ENVIRONMENT);
export const APP_E2E_LOGIN_ENABLED = convertStringToBoolean(process.env.REACT_APP_E2E_LOGIN_ENABLED);
export const APP_DESULFURIZATION_RESULTS_ENABLED = convertStringToBoolean(process.env.REACT_APP_DESULFURIZATION_RESULTS_ENABLED);
export const APP_DESULFURIZATION_INPUT_ENABLED = convertStringToBoolean(process.env.REACT_APP_DESULFURIZATION_INPUT_ENABLED);
export const APP_NA2O_K2O_ENABLED = convertStringToBoolean(process.env.REACT_APP_NA2O_K2O_ENABLED);
export const APP_MGO_SATURATION_MODE_ENABLED = convertStringToBoolean(process.env.REACT_APP_MGO_SATURATION_MODE_ENABLED);
export const APP_ITERATION_CHARTS_ENABLED = convertStringToBoolean(process.env.REACT_APP_ITERATION_CHARTS_ENABLED);
export const APP_GOOGLE_ANALYTICS_ENABLED = convertStringToBoolean(process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED);
export const APP_GOOGLE_ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
export const APP_HOTJAR_ENABLED = convertStringToBoolean(process.env.REACT_APP_HOTJAR_ENABLED);
export const APP_HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID;

export const PERMISSIONS = {
  FEATURE_CALCULATION_TAPPING_MODE: 'Feature.Calculation.TappingMode',

  HEAT_LIST: 'HeatCalculation.List',
  HEAT_CALCULATION_DETAIL: 'HeatCalculation.Detail',
  DRAFT_CALCULATION_CREATE: 'HeatCalculation.Draft.Create',
  HEAT_CALCULATION_OPERATION_CREATE: 'HeatCalculation.Operation.Create',

  BASIC_ACCESS_READ: "BasicAccess.Read",
  BASIC_ACCESS_WRITE: "BasicAccess.Write",

  LADLE_FURNACE_LIST: 'LadleFurnace.List',
  LADLE_FURNACE_DETAIL: 'LadleFurnace.Detail',
  LADLE_FURNACE_CREATE: 'LadleFurnace.Create',
  LADLE_FURNACE_EDIT: 'LadleFurnace.Edit',
  LADLE_FURNACE_DELETE: 'LadleFurnace.Delete',

  FLUXES_LIST: 'Fluxes.List',
  FLUXES_DETAIL: 'Fluxes.Detail',
  FLUXES_CREATE: 'Fluxes.Create',
  FLUXES_EDIT: 'Fluxes.Edit',
  FLUXES_DELETE: 'Fluxes.Delete',

  ALLOYS_LIST: 'Alloys.List',
  ALLOYS_CREATE: 'Alloys.Create',
  ALLOYS_EDIT: 'Alloys.Edit',
  ALLOYS_DELETE: 'Alloys.Delete',

  SLAG_DEOXIDIZERS_LIST: 'SlagDeoxidizers.List',
  SLAG_DEOXIDIZERS_EDIT: 'SlagDeoxidizers.Edit',

  CARRYOVER_SLAG_LIST: 'CarryoverSlag.List',
  CARRYOVER_SLAG_DETAIL: 'CarryoverSlag.Detail',
  CARRYOVER_SLAG_CREATE: 'CarryoverSlag.Create',
  CARRYOVER_SLAG_EDIT: 'CarryoverSlag.Edit',
  CARRYOVER_SLAG_DELETE: 'CarryoverSlag.Delete',

  STEEL_GRADE_LIST: 'SteelGrades.List',
  STEEL_GRADE_CREATE: "SteelGrades.Create",
  STEEL_GRADE_EDIT: 'SteelGrades.Edit',
  STEEL_GRADE_DELETE: 'SteelGrades.Delete',
  STEEL_GRADE_DETAIL: 'SteelGrades.Detail',

  PROCESS_CONSTRAINTS_LIST: 'ProcessConstraints.List',
  PROCESS_CONSTRAINTS_DETAIL: 'ProcessConstraints.Detail',
  PROCESS_CONSTRAINTS_EDIT: 'ProcessConstraints.Edit',

  USER_MANAGEMENT_CREATE: "UserManagement.Create",
  USER_MANAGEMENT_EDIT: "UserManagement.Edit",
  USER_MANAGEMENT_DELETE: "UserManagement.Delete",
  USER_MANAGEMENT_LIST: "UserManagement.List",

  PLANT_MANAGEMENT_CREATE: "PlantManagement.Create",
  PLANT_MANAGEMENT_DETAIL: "PlantManagement.Detail",
  PLANT_MANAGEMENT_EDIT: "PlantManagement.Edit",
  PLANT_MANAGEMENT_DELETE: "PlantManagement.Delete",
  PLANT_MANAGEMENT_LIST: "PlantManagement.List"

};

// PAGINATION DEFAULTS
export const PAGINATION = {
  ROWS_PER_PAGE: 10,
  ROWS_PER_PAGE_EXTENDED: 25,
  ROWS_PER_PAGE_OPTIONS: [10, 25, 50],
};

export const FALLBACK_LANGUAGE = 'en';

export const EMPTY_FUNCTION = () => {
  // Empty function, do nothing
};
