import { useSlagDeoxidizersService } from '@app/api/slagDeoxidizers';
import { DeoxidizersModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { useState } from 'react';
import { ListInfoObject } from "./types"

const infoObjectInitialState: ListInfoObject<DeoxidizersModel> = {
  status: 'idle',
  data: [],
}

const useLoadSlagDeoxidizers = (): [
  ListInfoObject<DeoxidizersModel>,
  () => Promise<void>,
] => {

  const { getSlagDeoxidizers } = useSlagDeoxidizersService();

  const [infoObject, setInfoObject] = useState<ListInfoObject<DeoxidizersModel>>(infoObjectInitialState);


  const loadSlagDeoxidizers = async () => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getSlagDeoxidizers();
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {
      
      setInfoObject({data: [], status: 'error'});
    }
  };


  return [infoObject, loadSlagDeoxidizers];
}

export default useLoadSlagDeoxidizers;