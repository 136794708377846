import FormButton from '@app/components/shared/FormButton';
import { ReactElement, useEffect, useState } from 'react';
import IterationChartsModal from './IterationChartsModal';
import { useTranslation } from 'react-i18next';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { hasAlphaFeaturesPermission, isNumber } from '@app/utils';
import { APP_ITERATION_CHARTS_ENABLED, EMPTY_FUNCTION } from '@app/utils/constants';
import { HeatMode } from '../NewHeat';
import { useParams } from 'react-router-dom';
import { useHeatsService } from '@app/api/heats';
import { IterationChartResponse, IterationChartStatus, IterationChartType } from '@app/api/heats/HeatsResponse';
import useCallApi from '@app/hooks/useCallApi';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';

interface IterationChartsButtonProps {
  heatMode: HeatMode,
  calculationId?: string,
}

export enum IterationChartGenerationMode {
  Unspecified,
  GenerateEphemeralCharts,
  GenerateAndSaveCharts,
  RetrieveSavedCharts,
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const IterationChartsButton = (props: IterationChartsButtonProps): ReactElement => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { heatMode, calculationId } = props;
  const { state: { userInfoObject } } = useAuthenticationContext();
  const { getChartByHeatId, getChartByDraftId, generateChartByHeatId, generateChartByDraftId } = useHeatsService();
  const userInfo = userInfoObject?.data; 
  
  const [featureEnabled] = useState(APP_ITERATION_CHARTS_ENABLED && hasAlphaFeaturesPermission(userInfo));
  const [iterationChartsModalVisible, setIterationChartsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generationMode, setGenerationMode] = useState<IterationChartGenerationMode>(IterationChartGenerationMode.Unspecified);

  const getCharts = async (): Promise<IterationChartResponse[]> => {
    let charts: IterationChartResponse[] = [];

    if (!calculationId && isNumber(id)) {
      switch (heatMode) {
        case HeatMode.Operation:
          charts = await getChartByHeatId(parseFloat(id), undefined, true);
          break;
        case HeatMode.Draft:
          charts = await getChartByDraftId(parseFloat(id), undefined, true);
          break;
      }
    }

    return charts;
  }

  const [chartsResult, callCharts] = useCallApi(getCharts, []);

  useEffect(() => {
    if (featureEnabled && calculationId) {
      setGenerationMode(IterationChartGenerationMode.GenerateEphemeralCharts);
    } else if (featureEnabled && chartsResult.status === 'idle') {
      callCharts();
    }
  }, [featureEnabled, calculationId, chartsResult.status, callCharts]);

  useEffect(() => {
    if (chartsResult.status === 'succeeded' && chartsResult.data) {
      const existingCharts = chartsResult.data.filter(x => x.status === IterationChartStatus.Success);

      if (existingCharts.length === Object.keys(IterationChartType).length) {
        setGenerationMode(IterationChartGenerationMode.RetrieveSavedCharts);
      } else {
        setGenerationMode(IterationChartGenerationMode.GenerateAndSaveCharts);
      }
    }
  }, [chartsResult.status, chartsResult.data]);

  const registerChartCreationForHeat = async () => {
    if (isNumber(id)) {
      await Promise.all([
        generateChartByHeatId(parseFloat(id), { type: IterationChartType.Weights }),
        generateChartByHeatId(parseFloat(id), { type: IterationChartType.RelativeSaturation }),
        generateChartByHeatId(parseFloat(id), { type: IterationChartType.Slags }),
      ]);
    }
  }

  const registerChartCreationForDraft = async () => {
    if (isNumber(id)) {
      await Promise.all([
        generateChartByDraftId(parseFloat(id), { type: IterationChartType.Weights }),
        generateChartByDraftId(parseFloat(id), { type: IterationChartType.RelativeSaturation }),
        generateChartByDraftId(parseFloat(id), { type: IterationChartType.Slags }),
      ]);
    }
  }

  const registerAndOpenIterationChartsModal = async () => {
    try {
      setLoading(true);

      switch (heatMode) {
        case HeatMode.Operation:
          await registerChartCreationForHeat();
          break;
        case HeatMode.Draft:
          await registerChartCreationForDraft();
          break;
      }
  
      openIterationChartsModal();
    } finally {
      setLoading(false);
    }
  };

  const openIterationChartsModal = () => {
    setIterationChartsModalVisible(true)
  };

  const renderButtonWithLoading = (buttonText: string, onClick: React.MouseEventHandler<HTMLButtonElement>): JSX.Element => {
    return (
      <div className={classes.wrapper}>
        <FormButton variant="primary" onClick={onClick} disabled={loading}>
          {buttonText}
        </FormButton>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  }

  const renderIterationChartButton = (): JSX.Element => {
    let button = (<></>);

    if (generationMode === IterationChartGenerationMode.Unspecified) {
      return button;
    }

    switch (generationMode) {
      case IterationChartGenerationMode.GenerateEphemeralCharts:
        button = renderButtonWithLoading(t('calculation-chart.button-generate.text'), openIterationChartsModal);
        break;
      case IterationChartGenerationMode.RetrieveSavedCharts:
        button = renderButtonWithLoading(t('calculation-chart.button-show.text'), openIterationChartsModal);
        break;
      case IterationChartGenerationMode.GenerateAndSaveCharts:
        button = renderButtonWithLoading(t('calculation-chart.button-generate.text'), registerAndOpenIterationChartsModal);
        break;
    }

    return button;
  }

  const renderIterationChartsModal = (): JSX.Element => {
    const onClose = () => setIterationChartsModalVisible(false);
    let changeGenerationMode: () => void = EMPTY_FUNCTION;

    if (generationMode === IterationChartGenerationMode.GenerateAndSaveCharts) {
      changeGenerationMode = () => setGenerationMode(IterationChartGenerationMode.RetrieveSavedCharts);
    }

    return (
      <IterationChartsModal
        id={calculationId ?? parseFloat(id)}
        mode={generationMode}  
        heatMode={heatMode}
        onClose={onClose}
        onLoadWithSuccess={changeGenerationMode} />
    )
  }

  return (
    <>
      {featureEnabled && renderIterationChartButton()}      
      {featureEnabled && iterationChartsModalVisible && renderIterationChartsModal()}
    </>
  );
}

export default IterationChartsButton;