import yup from "@app/config/yup-locale";
import { APP_NA2O_K2O_ENABLED } from "./constants";

export const defaultCompositionTableSchemaObjectShape = {
    mgO: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    caO: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    al2O3: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    siO2: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    feO: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    mnO: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    cr2O3: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    tiO2: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    caF2: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
    ...APP_NA2O_K2O_ENABLED ? {
        na2O: yup.number().transform((value) => Number.isNaN(value) ? 0 : value ),
        k2O: yup.number().transform((value) => Number.isNaN(value) ? 0 : value ),
      } : {
        na2O: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
        k2O: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
      },
  }