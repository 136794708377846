import { APP_TITLE } from '@app/utils/constants';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CarryoverSlagsContent from './CarryoverSlagsContent';

const CarryoverSlagsContainer = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('carryover-slags')} | {APP_TITLE}
        </title>
      </Helmet>
      <CarryoverSlagsContent />
    </>
  );
};

export default CarryoverSlagsContainer;
