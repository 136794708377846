import { Button, ButtonBase, ButtonProps } from "@material-ui/core"

export interface FormButtonProps extends Omit<ButtonProps, "variant" | "color" | "size"> {
    size?: "small" | "medium";
    variant?: "primary" | "secondary" | "link";
}

const FormButton = ({ children, size="medium", variant, disableElevation, ...rest }: FormButtonProps) => {
    const returnPropsAccordingToVariant = (variant: FormButtonProps['variant']): ButtonProps => {
        switch (variant) {
            case 'link':
                return ({
                    variant: "text",
                    color: "primary"
                });
            case 'primary':
                return ({
                    variant: 'contained',
                    color: "primary",
                });
            case 'secondary':
                return ({
                    variant: 'outlined',
                    color: "primary",
                });
            default:
                return ({});
        }
    }

    const variantProps = returnPropsAccordingToVariant(variant);

    if(!variant) {
        return (
            <ButtonBase {...rest}>{children}</ButtonBase>
        )
    } else {
        return (
            <Button
                {...rest}
                {...variantProps}
                disableElevation={disableElevation}
                size={size}
            >
                {children}
            </Button>
        )
    }

}

export default FormButton