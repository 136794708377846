import { CarryoverSlagType } from "../heats/HeatsResponse";
import { SystemOfMeasurementOptions } from "../me/MeGetResponse";
import { SteelChemistryUnitOfMeasurement, ProcessConstraintsDTO } from "./useProcessConstraintsService";

export const processConstraintsInitialValue: ProcessConstraintsDTO = {
  ladleSteelWeight: 0,
  ladleDiameter: 0,
  carryoverSlagType: CarryoverSlagType.Weight,
  toleranceCalcMinSlagThickness: 0,
  initialLiningThickness: 0,
  finalLiningThickness: 0,
  averageLadleLifetime: 0,
  minimumSlagCarryoverWeight: 0,
  minimumSlagWeightAfterSkimmerUse: 0,
  maximumSlagWeight: 0,
  maximumSlagDeoxidizerWeight: 0,
  mgOSourceMinimumWeight: 0,
  mgOSourceMaximumWeight: 0,
  alRecovery: 0,
  siRecovery: 0,
  mnRecovery: 0,
  crRecovery: 0,
  alkalisToFluorsparFactor: 0,
  steelChemistryUnitOfMeasurement: SteelChemistryUnitOfMeasurement.Percent,
  steelChemistryPrimaryFurnaceCode: "",
  steelChemistryTappingCode: "",
  measurementSystem: SystemOfMeasurementOptions.Metric
}