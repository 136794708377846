import { useRouteConfig } from '@app/config';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { Box, Collapse, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, useTheme } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DefaultIcon from '@material-ui/icons/FileCopy';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import RouteItem from '../model/RouteItem.model';
import RouteSection from '../model/RouteSection.model';
import MenuItem from './MenuItem';
import { Location } from 'history'
import lsmIcon from '@app/assets/images/lsm-white-icon.svg'
import lsmTitle from '@app/assets/images/lsm-title.svg'
import CustomTooltip from './shared/CustomTooltip';

const SubRouteListItem = ({ route, open: isOpen }: { route: RouteItem, open: boolean }) => {
  const location = useLocation<Location>();

  const isSelected = route
    .subRoutes?.filter(f => !f.routeOnly)
    .some((item: RouteItem) => item.path === location.pathname) ?? false;

  const [open, setOpen] = useState<boolean>(isSelected);

  const handleClick = (): void => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <IconButton size="small">
            <Icon component={route.icon || DefaultIcon} htmlColor="#fff" fontSize="small" />
          </IconButton>
        </ListItemIcon>

        <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={route.title} style={{ color: '#fff' }} />

        <Tooltip title={`${open ? 'Collapse' : 'Expand'}`} placement="bottom">
          {open ? <ExpandLess htmlColor="#fff" /> : <ExpandMore htmlColor="#fff" />}
        </Tooltip>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {route
            .subRoutes?.filter(f => !f.routeOnly)
            .map((sRoute: RouteItem) => (
              <MenuItem linkTestId={sRoute.key} key={`${sRoute.key}`} open={isOpen} route={sRoute} nested />
            ))}
        </List>
      </Collapse>
    </>
  );
};

const Menu = (props:{ open: boolean }) => {
  const { open } = props;
  const { routeSections } = useRouteConfig();
  const { hasAccess } = useAuthenticationContext();

  const flatFilterRoutes = (routes: RouteItem[]): RouteItem[] =>
    routes.filter(o => {
      if (o.routeOnly) {
        return false;
      } else if (o.subRoutes) {
        o.subRoutes = flatFilterRoutes(o.subRoutes);
        return o.subRoutes.length;
      } else {
        return hasAccess(o.permissions);
      }
    });

  const routesSectionsWithAccess: RouteSection[] = routeSections
    // Filter item's subroutes
    .map(s => ({ ...s, items: flatFilterRoutes(s.items) }))
    // Remove sections with no allowed subroutes
    .filter(f => f.items.length);

  const theme = useTheme();

  return (
    <List style={{ padding: 0 }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: '13px',
          margin: '10px 0',
        }}
      >
        <img src={lsmIcon} alt='RHI white logo' width='35px' height='35px'/>
        {open && <img src={lsmTitle} alt='RHI title' width='88px' height='33px'/>}
      </Box>
      {routesSectionsWithAccess.map(({ items, key, title, icon }: RouteSection, index) => (
        <React.Fragment key={key}>
          {index > 0 && (
            <Box
              style={{
                display: 'flex',
                width: 'calc(100% - 20px)',
                margin: '0 10px',
                height: '2px',
                content: '',
                borderRadius: '2px',
                backgroundColor: theme.palette.custom.grey3.main,
              }}
            />
          )}
          <CustomTooltip
            title={!open ? title.toUpperCase() : ''}
            placement="right"
          >
            <Box
              style={{
                display: 'flex',
                columnGap: '6px',
                padding: '10px',
                ...!open ? {
                  justifyContent: 'center',
                } : {},
              }}
              data-testid={key}
            >
              <img src={icon} alt='RHI white logo' width='25px' height='25px'/>
              {open && (
                <Typography style={{ color: theme.palette.custom.grey3.main, fontWeight: 800 }}>
                  {title.toUpperCase()}
                </Typography>
              )}
            </Box>
          </CustomTooltip>

          {items
            .filter(f => !f.routeOnly)
            .map(route =>
              route.subRoutes ? (
                <SubRouteListItem
                  key={route.key}
                  route={route}
                  open={open}
                />
              ) : (
                <MenuItem
                  linkTestId={route.key}
                  key={route.key}
                  route={route}
                  open={open}
                />
              ),
            )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Menu;
