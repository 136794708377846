import React, { ForwardedRef } from 'react';
import { Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import { ChangeEvent } from 'react';

interface FormCheckboxProps {
  id: string;
  value: boolean;
  onChange?: (checked: boolean) => void;
  label: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const FormCheckbox = ({
  id,
  value,
  onChange,
  label,
  disabled = false,
  style = {},
}: FormCheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  const handleValueChange = (e: ChangeEvent<{}>, checked: boolean) => {
    onChange?.(checked);
  };

  const handleLabelClick = () => {
    onChange?.(!value);
  };

  return (
    <FormControlLabel
      id={id}
      ref={ref}
      value={value}
      style={{ gap: '5.5px', ...style }}
      control={
        <Checkbox color="primary" size="small" checked={value} onChange={onChange ? handleValueChange : undefined} />
      }
      label={
        <InputLabel
          className="checkboxLabel"
          style={{ position: 'initial', cursor: 'pointer' }}
          disabled={disabled}
          htmlFor={id}
          onClick={!disabled ? handleLabelClick : undefined}
        >
          {label}
        </InputLabel>
      }
      disabled={disabled}
      onChange={onChange ? handleValueChange : undefined}
      labelPlacement="end"
    />
  );
};

const ForwaredFormCheckbox = React.forwardRef(FormCheckbox);

export default ForwaredFormCheckbox;
