import { useFluxesService } from '@app/api/fluxes';
import { FluxModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { useState } from 'react';
import { ListInfoObject } from './types';


const infoObjectInitialState: ListInfoObject<FluxModel> = {
  status: 'idle',
  data: [],
}

const useLoadFluxes = (): [
  ListInfoObject<FluxModel>,
  () => Promise<void>,
] => {

  const { getFluxes } = useFluxesService();

  const [infoObject, setInfoObject] = useState<ListInfoObject<FluxModel>>(infoObjectInitialState);

  const loadFluxes = async () => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getFluxes();
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {
      
      setInfoObject({data: [], status: 'error'});
    }
  };


  return [infoObject, loadFluxes];
}

export default useLoadFluxes;