import { Customer } from '.';
import { useApi } from '..';

export function useCustomersService() {
  const { get } = useApi();
  const baseApi = `/v2/customers`;

  const getCustomers = async () => {
    const { data } = await get<Customer[]>(`${baseApi}`);

    return data;
  };

  return {
    getCustomers,
  };
}
