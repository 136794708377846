import yup from "@app/config/yup-locale";
import useReturnUnitOfMeasurement from "./useReturnUnitOfMeasurement";
import { useTranslation } from "react-i18next";
import { LimitInfo } from "@app/api/limits/limitsTypes";
import { useAuthenticationContext } from "@app/store/authentication/authenticationContext";

const useLadleTabValidationSchema = () => {
    const { state: authenticationContextData } = useAuthenticationContext();

    const { limitsInfoObject, userInfoObject } = authenticationContextData;
    const measurementSystem = userInfoObject?.data?.measurementSystem;

    const limitsInfo = limitsInfoObject?.data;

    const ladleCapacityLimits = limitsInfo?.ladleCapacity as LimitInfo;
    const maximumSlagWeightLimits = limitsInfo?.maximumSlagWeight as LimitInfo;
    const minimumSlagWeightAfterSkimmerLimits = limitsInfo?.minimumSlagWeightAfterSkimmer as LimitInfo;
    const ladleDiameterLimits = limitsInfo?.ladleDiameter as LimitInfo;
        
    const { returnLengthUnitOfMeasurement, returnWeightUnitOfMeasurement } = useReturnUnitOfMeasurement();
    const { t } = useTranslation();

    const ladleDiameterFeedbackMessage = t(
        'overview.equipment.ladle.error-ladle-diameter', 
        {
            uom: returnLengthUnitOfMeasurement(measurementSystem, "m"),
            min: ladleDiameterLimits.minimum,
            max: ladleDiameterLimits.maximum
        }
    );

    const ladleCapacityFeedbackMessage = t(
        'overview.equipment.ladle.error-ladle-capacity', 
        {
            uom: returnWeightUnitOfMeasurement(measurementSystem, 'st'),
            min: ladleCapacityLimits.minimum,
            max: ladleCapacityLimits.maximum
        }
    );

    const maxSlagLFWeightFeedbackMessage = t(
        'overview.equipment.ladle.error-max-slag-lf-weight', 
        {
            uom: returnWeightUnitOfMeasurement(measurementSystem, 'lb'),
            min: maximumSlagWeightLimits.minimum,
            max: maximumSlagWeightLimits.maximum
        }
    );

    const minSlagAfterSkimmerFeedbackMessage = t(
        'overview.equipment.ladle.error-min-slag-after-skimmer', 
        {
            uom: returnWeightUnitOfMeasurement(measurementSystem,'lb'),
            min: minimumSlagWeightAfterSkimmerLimits.minimum,
            max: minimumSlagWeightAfterSkimmerLimits.maximum
        }
    );

    const schema = yup.object().shape({
    averageLadleLifetime: 
        yup
        .number()
        .typeError(t('overview.equipment.ladle.error-ladle-lifetime'))
        .min(1, t('overview.equipment.ladle.error-ladle-lifetime'))
        .max(400, t('overview.equipment.ladle.error-ladle-lifetime'))
        .required(),
    initialLiningThickness: 
        yup
        .number()
        .typeError(t('ladle-furnace.text-field.error.empty-field'))
        .min(1,t('overview.equipment.ladle.error-initial-lining-thickness-zero'))
        .required(),
    finalLiningThickness: 
        yup
        .number()
        .typeError(t('ladle-furnace.text-field.error.empty-field'))
        .max(yup.ref('initialLiningThickness'), t('overview.equipment.ladle.error-initial-lining-thickness'))
        .required(), // min <= value <= max
    ladleDiameter: 
        yup
        .number()
        .typeError(ladleDiameterFeedbackMessage)
        .max(
            ladleDiameterLimits.maximum, 
            ladleDiameterFeedbackMessage
        )
        .min(
            ladleDiameterLimits.minimum + 1, 
            ladleDiameterFeedbackMessage
        )
        .required(),
    ladleSteelWeight: 
        yup
        .number()
        .typeError(
            ladleCapacityFeedbackMessage
        )
        .max(
            ladleCapacityLimits.maximum,  
            ladleCapacityFeedbackMessage
        )
        .min(
            ladleCapacityLimits.minimum + 1,
            ladleCapacityFeedbackMessage
        )
        .required(),
    maximumSlagWeight: 
        yup
        .number()
        .typeError(
            maxSlagLFWeightFeedbackMessage
        )
        .max(maximumSlagWeightLimits.maximum, 
            maxSlagLFWeightFeedbackMessage
        )
        .min(maximumSlagWeightLimits.minimum + 1, 
            maxSlagLFWeightFeedbackMessage
        )
        .required(),
    minimumSlagWeightAfterSkimmerUse: 
        yup
        .number()
        .typeError(
            minSlagAfterSkimmerFeedbackMessage
        )
        .min(
            minimumSlagWeightAfterSkimmerLimits.minimum,
            minSlagAfterSkimmerFeedbackMessage
        )
        .max(
            minimumSlagWeightAfterSkimmerLimits.maximum,
            minSlagAfterSkimmerFeedbackMessage
        ).required(),
    maximumSlagDeoxidizerWeight: yup.number().nullable(), // no field
    mgOSourceMaximumWeight: yup.number().nullable(), // no field
    mgOSourceMinimumWeight: yup.number().nullable(), // no field
    minimumSlagCarryoverWeight: yup.number().nullable(), // no field
    alRecovery: yup.number().nullable(), // no field
    mnRecovery: yup.number().nullable(), // no field
    siRecovery: yup.number().nullable(), // no field
    crRecovery: yup.number().nullable(), // no field
    toleranceCalcMinSlagThickness: yup.number().nullable(), // no validation necessary
    alkalisToFluorsparFactor: yup.number().nullable(), // no field
    steelChemistryUnitOfMeasurement: yup.string().nullable(), // no field
    steelChemistryPrimaryFurnaceCode: yup.string().nullable(), // no field
    steelChemistryTappingCode: yup.string().nullable(), // no field
    carryoverSlagType: yup.string().nullable(), // no carryoverslagtype on form
    measurementSystem: yup.string().required(),
    })

    return {
        schema
    };
}

export default useLadleTabValidationSchema;