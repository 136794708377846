import { PlantState } from './plantValidationState';
import { PlantValidationActions } from './plantValidationActions';
import { GenericContext } from '../genericContext';

import { PlantState as PlantSetupValidations } from '@app/api/pageValidations/pageValidationsTypes'

export type PlantDispatch = (action: PlantValidationAction) => void;
export const PLANT_INITIAL_DISPATCH = (action: PlantValidationAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action.type);
};
export function PlantValidationReducer(state: PlantState, action: PlantValidationAction): PlantState {
  const actionType = action.type
  switch (actionType) {
    case PlantValidationActions.FETCH_PLANT_VALIDATIONS_LOADING: {
      return { ...state, error: false, loading: true };
    }

    case PlantValidationActions.FETCH_PLANT_VALIDATIONS_SUCCEEDED: {
      return { ...state, loading: false, completedFirstLoad: true, data: action.payload as PlantSetupValidations };
    }

    case PlantValidationActions.FETCH_PLANT_VALIDATIONS_FAILED: {
      return { ...state, loading: false, completedFirstLoad: true, error: true, errorMessage: action.payload?.errorMessage  }
    }

    default: {
      throw new Error(`Unidentified action: ${actionType}`);
    }
  }
}

type PlantValidationAction =
| GenericContext<PlantValidationActions.FETCH_PLANT_VALIDATIONS_LOADING>
| GenericContext<PlantValidationActions.FETCH_PLANT_VALIDATIONS_FAILED, { errorMessage: string }> 
| GenericContext<PlantValidationActions.FETCH_PLANT_VALIDATIONS_SUCCEEDED, PlantSetupValidations | undefined>;
