import { FALLBACK_LANGUAGE } from "@app/utils/constants"

const STORAGE_LANGUAGE_KEY = 'rhim.lsm.language';
const storedLanguagePreference = localStorage.getItem(STORAGE_LANGUAGE_KEY);

export type LanguageState = {
  selectedLanguage: 'en' | 'pt-BR' | 'es'
}

export const languageInitialState: LanguageState = {
  selectedLanguage: (storedLanguagePreference || FALLBACK_LANGUAGE) as 'en' | 'pt-BR' | 'es',
}
