import { MeGetResponse, MeUpdateRequest } from '.';
import { useApi } from '..';
import { UserNotificationResponse } from './UserNotificationResponse';

export function useMeService() {
  const { get, put } = useApi();
  const baseApi = `/v2/me`;

  const getUserInfo = async () => {
    const { data } = await get<MeGetResponse>(`${baseApi}`);

    return data;
  };

  const updateUserInfo = async (request: MeUpdateRequest) => {
    const { data } = await put(`${baseApi}`, request);

    return data;
  };

  const changePlant = async (request: { plantId: string }) => {
    const { data } = await put(`${baseApi}/ChangePlant`, request);

    return data;
  };

  const getNotifications = async () => {
    const { data } = await get<UserNotificationResponse[]>(`${baseApi}/notifications`);

    return data;
  }

  return {
    getUserInfo,
    updateUserInfo,
    changePlant,
    getNotifications,
  };
}
