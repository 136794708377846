import { getSvg } from '@app/utils/getSvg';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

interface TableNoResultsProps {
  searchInput?: string;
  firstText?: string;
  secondText?: string;
  children?: React.ReactNode;
}

const TableNoResults = ({ searchInput = '', firstText = '', secondText = '', children }: TableNoResultsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid className={classes.emptyStateContainer}>
      <svg width="76" height="76" viewBox="0 0 76 76" fill="none">
        <path d={getSvg('emptyState')} fill="#CFCFCF" />
      </svg>

      <Grid item container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 28 }}>
        {searchInput !== '' && (
          <>
            <Typography className={classes.emptyStateText}>
              {t('table.no-results.text1', { search: searchInput })}
            </Typography>

            <Typography className={classes.emptyStateText}>
              {t('table.no-results.text2')}
            </Typography>
          </>
        )}
        {(firstText !== '' || secondText) !== '' && (
          <>
            <Typography className={classes.emptyStateText}>
              {t(firstText)}
            </Typography>

            <Typography className={classes.emptyStateText}>
              {t(secondText)}
            </Typography>
          </>
        )}
        <Box className={classes.emptyStateText}>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    emptyStateContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 32,
      marginBottom: 32,
    },
    emptyStateText: {
      color: theme.palette.custom.grey1.main,
    },
  }),
);

export default TableNoResults;
