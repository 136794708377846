enum CarryoverSlagUnitOfMeasurement {
  Points = "Points",
  Percent = "Percent",
}

export const UNITS_OF_MEASUREMENT: { [key: string]: string } = {
  [CarryoverSlagUnitOfMeasurement.Points]: 'unit-of-measurement.points',
  [CarryoverSlagUnitOfMeasurement.Percent]: '%',
};

export default CarryoverSlagUnitOfMeasurement;
