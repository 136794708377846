import { Tooltip } from "@material-ui/core"
import { ReactElement } from "react";

export interface ConditionalTooltipProps {
    children: ReactElement;
    message: string;
    condition: boolean;
    messagePlacement?: "left" 
        | "bottom-end" 
        | "bottom-start" 
        | "bottom" 
        | "left-end" 
        | "left-start" 
        | "right-end" 
        | "right-start" 
        | "right" 
        | "top-end" 
        | "top-start" 
        | "top"; 
}

const ConditionalTooltip = ({
    children,
    condition,
    message,
    messagePlacement = "left"
}: ConditionalTooltipProps) => {

    if(condition) {
        return (
        <Tooltip 
            placement={messagePlacement} 
            title={<>{message}</>}
        >
            {children}
        </Tooltip>
        )
    } else {
        return children
    }
}
export default ConditionalTooltip;