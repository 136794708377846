import yup from "@app/config/yup-locale";
import { FluxAvailability, FluxModel, FluxType } from "@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes";
import { defaultCompositionTableSchemaObjectShape } from "@app/utils/defaultCompositionTableSchemaObjectShape";
import { useTranslation } from "react-i18next";
import { AnyObject, SchemaLike } from "yup/lib/types";

export const useCreateEditFluxValidationSchema = () => {
    const {t} = useTranslation();

    const IsFixedAdditionCallback = (fluxAvailability: FluxAvailability | undefined) => {
        return fluxAvailability === FluxAvailability.FixedAdditionAtTapping || fluxAvailability === FluxAvailability.FixedAdditionAtLadleFurnace;
    }

    const isMgoOrDolomaSourceCallback = (fluxType: FluxType) => {
        return fluxType === "MgOOnlySource" || fluxType === "DolomaSource"
    };

    const returnConditionalValidation = <K extends keyof FluxModel>(
        fluxKey: K, 
        conditionCallBack: (fluxKeyValue: FluxModel[K]) => boolean, 
        then?: SchemaLike | ((schema: yup.NumberSchema<number | undefined, AnyObject, number | undefined>) => SchemaLike) | undefined
    ) => {
        return yup.number().when(fluxKey, {
            is: conditionCallBack,
            then: then === undefined ? yup.number().required() : then,
            otherwise: yup.number().nullable(),
        });
    }
    const fixedAdditionAmmountValidation = returnConditionalValidation(
        'availability', 
        IsFixedAdditionCallback,
        yup
            .number()
            .min(1, t('flux.params.form.error.fix-amount-zero'))
            .typeError(t('flux.params.form.error.fix-amount'))
    );

    const fixedAdditionRecoveryValidation = returnConditionalValidation(
        'availability', 
        IsFixedAdditionCallback,
        yup.number().typeError(t('flux.params.form.error.percent-recovery'))
    );

    const mgOSourceMinimumWeightValidation = returnConditionalValidation(
        'type', 
        isMgoOrDolomaSourceCallback,
        yup
            .number()
            .required(t('ProcessConstraints.text-field.title.min-amount-mgo-source'))
            .typeError(t('ProcessConstraints.text-field.title.min-amount-mgo-source'))
    );

    const MgOSourceMaximumWeightValidation = returnConditionalValidation(
        'type', 
        isMgoOrDolomaSourceCallback, 
        yup
            .number()
            .min(
                yup.ref('mgOSourceMinimumWeight'), 
                t('ProcessConstraints.text-field.error.min-amount-mgo-source-bigger-than-max')
            )
            .required(t('ProcessConstraints.text-field.title.max-amount-mgo-source'))
            .typeError(t('ProcessConstraints.text-field.title.max-amount-mgo-source'))
    );

    const objectShape = {
        ...defaultCompositionTableSchemaObjectShape,
        al: yup.number().nullable().transform((value) => Number.isNaN(value) ? 0 : value ),
        name: yup.string().required(),
        availability: yup.string().required(),
        type: yup.string().required(),
        externalId: yup.string().nullable().notRequired(), // no field
        fixedAdditionAmount: fixedAdditionAmmountValidation,
        fixedAdditionRecovery: fixedAdditionRecoveryValidation,
        mgOSourceMinimumWeight: mgOSourceMinimumWeightValidation,
        mgOSourceMaximumWeight: MgOSourceMaximumWeightValidation
    };

    return { objectShape };
}