import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import FormSelect from '@app/components/shared/FormSelect';
import CarryoverSlagUnitOfMeasurement, { UNITS_OF_MEASUREMENT } from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { PERMISSIONS } from '@app/utils/constants';
import { InputAdornment, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { GeneralSettings, SLAG_TYPE } from '../../carryoverSlagsUtils';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import { CarryoverSlagType } from '@app/api/heats/HeatsResponse';
import FormButton from '@app/components/shared/FormButton';
import useRenderInputEndAdornment from '@app/hooks/useRenderInputEndAdornment';

interface CarryoverSlagInferenceModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (generalSettigs: GeneralSettings) => void;
  generalSettigs: GeneralSettings;
  hasNoCarryoverSlags: boolean;
}

const CarryoverSlagInferenceModal: React.FC<CarryoverSlagInferenceModalProps> = (props: CarryoverSlagInferenceModalProps) => {
  const { open, onClose, onConfirm, generalSettigs, hasNoCarryoverSlags } = props;
  const { t } = useTranslation();
  const { hasAccess, state: { userInfoObject } } = useAuthenticationContext();

  const measurementSystem = userInfoObject?.data?.measurementSystem;

  const { renderWeightInputEndAdornment } = useRenderInputEndAdornment();

  const [newGeneralSettings, setNewGeneralSettings] = useState<GeneralSettings>(generalSettigs);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{open: boolean; field: string | Array<string>; value: string | number | Array<string | number>}>({ open: false, field: '', value: '' });

  useEffect(() => {
    setNewGeneralSettings(generalSettigs);
  }, [generalSettigs]);

  const handleClose = () => {
    setNewGeneralSettings(generalSettigs);
    onClose();
  };

  const onSubmit = () => {
    const payload: GeneralSettings = {
      ...newGeneralSettings.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory ?
      {
        carryoverSlagInferenceType: newGeneralSettings.carryoverSlagInferenceType,
        carryoverSlagParameterValueName: newGeneralSettings.carryoverSlagParameterValueName,
        carryoverSlagReferenceInput: newGeneralSettings.carryoverSlagReferenceInput,
        carryoverSlagType: newGeneralSettings.carryoverSlagType,
        minimumSlagCarryoverWeight: newGeneralSettings.minimumSlagCarryoverWeight
      } : newGeneralSettings
    };
    setNewGeneralSettings(payload);

    onConfirm(payload);
  };

  const referenceInputTitle = t('admin-panel.plants.carryover-slags-inference-type.steel-grade-category');

  const handleChangeValue = <K extends keyof GeneralSettings>(field: K, value: GeneralSettings[K]) => {
    const changedGeneralSettings = {...newGeneralSettings};
    changedGeneralSettings[field] = value;

    if (field === 'carryoverSlagParameterValueName') {
      changedGeneralSettings['carryoverSlagReferenceInput'] = value as string | null | undefined;
    } else if(field === 'carryoverSlagInferenceType' && value === CarryoverSlagInferenceType.SteelGradeCategory) {
      changedGeneralSettings.carryoverSlagParameterValueName = referenceInputTitle;
      changedGeneralSettings.carryoverSlagReferenceInput = referenceInputTitle;
    } else if(field === 'carryoverSlagInferenceType' && value !== CarryoverSlagInferenceType.SteelGradeCategory) {
      changedGeneralSettings.carryoverSlagParameterValueName = '';
      changedGeneralSettings.carryoverSlagReferenceInput = '';
    }

    setNewGeneralSettings(changedGeneralSettings);
  }

  const parameterNameIsDisabled = newGeneralSettings.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
    || !hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT]);

  const formInputContainerStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '16px',
    flex: 1
  };

  const formInputWrapperStyles: React.CSSProperties = {
    display: 'flex',
    flex: 1
  }

  const unitOfMeasurementOptions = useMemo(() => {
      return Object.keys(UNITS_OF_MEASUREMENT).sort().map(key => {
        return {
          value: key,
          name: t(UNITS_OF_MEASUREMENT[key]),
        };
      })
    }, [t]
  )

  return (
    <>
      <ConfirmDialog
        dialogTestId='infefrence-modal-confirm-change-dialog'
        visible={openConfirmationModal.open}
        setVisible={() => setOpenConfirmationModal({ open: false, field: '', value: '' })}
        checkboxEnabled
        title={t('carryover-slags.general-settings.dialog-update-inference.confirm-dialog.title')}
        content={t('carryover-slags.general-settings.dialog-update-inference.confirm-dialog.content')}
        cancelText={t('button.cancel')}
        cancelButtonTestId="infefrence-modal-confirm-change-dialog.cancel-button"
        cancelAction={() => setOpenConfirmationModal({ open: false, field: '', value: '' })}
        confirmText={t('carryover-slags.general-settings.dialog-update-inference.confirm-dialog.button.confirm')}
        confirmButtonTestId="infefrence-modal-confirm-change-dialog.confirm-button"
        confirmAction={() => {
          handleChangeValue(openConfirmationModal.field as keyof GeneralSettings, openConfirmationModal.value as string);
          setOpenConfirmationModal({ open: false, field: '', value: '' });
        }}
      />

      <Dialog
        open={open}
        aria-labelledby="general-settings-title"
        aria-describedby="general-settings-description"
        maxWidth="sm">
        <DialogTitle id="general-settings">{t('general-settings')}</DialogTitle>
        <DialogContent data-testid="carryoverslag-inference-modal.content">
          <Box paddingBottom={3} minWidth={400}>
            <Box paddingBottom={1} minWidth={400}>
              <Typography variant="body2"><b>{t('inference')}</b></Typography>
              <Typography variant="body2">{t('carryover-slags.general-settings-inference-description')}</Typography>
            </Box>
            <Box style={formInputContainerStyles}>
              <Box style={formInputWrapperStyles}>
                <FormSelect
                  fullWidth
                  inputProps={{ "data-testid": "carryoverslags-inference-form.field.carryoverSlagInferenceType" }}
                  id="general-settings-carryover-slag-type"
                  label={t('carryover-slags.general-settings-carryover-slag-type')}
                  options={SLAG_TYPE.map(i => ({ value: i.value, name: t(i.nameKey) }))}
                  value={newGeneralSettings?.carryoverSlagInferenceType?.replace(CarryoverSlagInferenceType.None, '')}
                  onChange={(event: ChangeEvent<{ name?: string; value: unknown; }>) => {
                    hasNoCarryoverSlags
                    ? handleChangeValue('carryoverSlagInferenceType', t(event.target.value as string) as CarryoverSlagInferenceType)
                    : setOpenConfirmationModal({ open: true, field: 'carryoverSlagInferenceType', value: t(event.target.value as string) as CarryoverSlagInferenceType });
                  }}
                  disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                />
              </Box>
              {newGeneralSettings.carryoverSlagInferenceType !== CarryoverSlagInferenceType.SteelGradeCategory && (
                <Box style={formInputWrapperStyles}>
                  <FormSelect
                    fullWidth
                    inputProps={{ "data-testid": "carryoverslags-inference-form.field.unitofmeasure" }}
                    id="unit-of-measurement"
                    label={t('unit-of-measurement')}
                    options={unitOfMeasurementOptions}
                    value={newGeneralSettings?.carryoverSlagUnitOfMeasurement ?? ''}
                    onChange={event => {
                      hasNoCarryoverSlags
                      ? handleChangeValue('carryoverSlagUnitOfMeasurement', event.target.value as CarryoverSlagUnitOfMeasurement)
                      : setOpenConfirmationModal({ open: true, field: 'carryoverSlagUnitOfMeasurement', value: event.target.value as CarryoverSlagUnitOfMeasurement });
                    }}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Box>
              )}
                <Box style={formInputWrapperStyles}>
                  <TextField
                    label={t('carryover-slags.general-settings-carryover-slag-parameter-name')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t(newGeneralSettings?.carryoverSlagParameterValueName as string)?.length || 0}/30</InputAdornment>,
                    }}
                    inputProps={{ maxLength: 30, 'data-testid': 'carryoverslags-inference-form.field.carryoverSlagParameterValueName' }}
                    InputLabelProps={{ shrink: true }}
                    autoComplete="off"
                    fullWidth
                    value={t(newGeneralSettings?.carryoverSlagParameterValueName as string) ?? ''}
                    onChange={(e) => handleChangeValue('carryoverSlagParameterValueName', e.target.value)}
                    disabled={parameterNameIsDisabled}
                  />
                </Box>
            </Box>

            <Box paddingBottom={2} paddingTop={3}>
              <Typography variant="body2"><b>{t('carryover-slags.general-settings.customization')}</b></Typography>
              <Typography variant="body2">{t('processConstraints.general-settings.subtitle')}</Typography>
            </Box>
            <Box style={formInputContainerStyles}>
              <Box style={formInputWrapperStyles}>
                <FormSelect
                  fullWidth
                  options={[
                    { name: t('weight'), value: CarryoverSlagType.Weight },
                    { name: t('depth'), value: CarryoverSlagType.Thickness },
                  ]}
                  id='processConstraints.text-field.label.slag-information'
                  inputProps={{ "data-testid": "carryoverslags-inference-form.field.carryoverSlagType" }}
                  value={newGeneralSettings?.carryoverSlagType}
                  onChange={e => handleChangeValue('carryoverSlagType', e.target.value as CarryoverSlagType)}
                  label={t('processConstraints.text-field.label.slag-information')}
                  disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                />
              </Box>
              <Box style={formInputWrapperStyles}>
                <NumberFormat
                  autoComplete="off"
                  fullWidth
                  id="MinimumSlagCarryoverWeight"
                  label={
                    t('processConstraints.text-field.label.min-slag-carryover-weight')
                  }
                  title={
                    t('processConstraints.text-field.title.min-slag-carryover-weight')
                  }
                  value={newGeneralSettings?.minimumSlagCarryoverWeight ?? ''}
                  customInput={TextField}
                  InputLabelProps={{ shrink: true }}
                  allowNegative={false}
                  decimalSeparator={t('decimal-scale-separator')}
                  decimalScale={0}
                  InputProps={{endAdornment: renderWeightInputEndAdornment(measurementSystem,"lb")}}
                  inputProps={{ "data-testid": "carryoverslags-inference-form.field.minimumSlagCarryoverWeight" }}
                  isAllowed={({ floatValue }) => (floatValue ?? 0) <= 10000}
                  disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  onValueChange={e => handleChangeValue('minimumSlagCarryoverWeight', e.floatValue)}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <FormButton
            data-testid="carryoverslags-inference-form.button.cancel"
            onClick={handleClose}
            variant="secondary"
          >
            {t('button.cancel')}
          </FormButton>
          <FormButton
            data-testid="carryoverslags-inference-form.button.save"
            onClick={onSubmit}
            variant="primary"
            disabled={
              !hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])
              || Object.values({
                ...newGeneralSettings.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
                ? {
                  carryoverSlagInferenceType: newGeneralSettings.carryoverSlagInferenceType,
                  carryoverSlagParameterValueName: newGeneralSettings.carryoverSlagParameterValueName,
                  carryoverSlagReferenceInput: newGeneralSettings.carryoverSlagReferenceInput,
                  carryoverSlagType: newGeneralSettings.carryoverSlagType,
                  minimumSlagCarryoverWeight: newGeneralSettings.minimumSlagCarryoverWeight,
                } : newGeneralSettings}).some(cs => cs === '' || cs === null || cs === undefined)
            }
          >
            {t('button.save')}
          </FormButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CarryoverSlagInferenceModal;
