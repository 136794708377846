import { useArchivedHeatContext } from '@app/store/archivedHeat/archivedHeatContext';
import {
  Box,
  createStyles,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import RouteItem from '../model/RouteItem.model';

import CustomTooltip from './shared/CustomTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      background: '#0E93DD',
      borderRadius: '0 20px 20px 0',
    },
    nested: {
      marginLeft: theme.spacing(2),
    },
    defaultMenuItem: {
      '&:hover': {
        backgroundColor: 'transparent',
        filter: 'brightness(78%)'
      }
    },
    listItemDisabled: {
      cursor: 'default',
    },
    listItemText: {
      color: theme.palette.common.white,
      fontSize: '0.875Rem',
      lineHeight: '0.875Rem',
    },
    listItemTextBold: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: '0.875Rem',
      lineHeight: '0.875Rem',
    },
  }),
);

const Item = ({ route, nested, open }: { route: RouteItem; nested: boolean, open: boolean }) => {
  const classes = useStyles();
  const isTooltipVisible = !route.enabled || !open;
  const title = isTooltipVisible  ? (route.tooltip || '') : '';

  const { pathname } = useLocation();
  const theme = useTheme();

  const isRouteActive = pathname === route.path;

  return (
    <CustomTooltip title={title} placement="right" onClick={route.onClick}>
      <span>
        <ListItem
          className={classes.defaultMenuItem}
          button
          disabled={!route.enabled}
        >
          <Box 
            className={clsx({ [classes.nested]: nested })} 
            display="flex" 
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{
              ...!open ? { flex: 1 } : {},
              ...isRouteActive ? {
                  position: 'relative',
              } : {},
            }}
          >
            {isRouteActive && (
              <Box 
                style={{
                  position: 'absolute',
                  bottom: 0,
                  ...open ? {
                    right: 0,
                    width: 'calc(100% - 14px)',
                    transform: 'translateX(2.5px)',
                  } : {
                    right: '50%',
                    width: '40%',
                    transform: 'translateX(50%)',
                  },
                  height: '2px',
                  backgroundColor: theme.palette.common.white,
                  content: '',
                }}
              />
            )}
            {route.icon && (
              <ListItemIcon style={{justifyContent:'center', display: 'flex', flex: 1}}>
                <IconButton size="small">
                  <Icon component={route.icon} style={{ color: '#fff' }} fontSize="small" />
                </IconButton>
              </ListItemIcon>
            )}

            {open && (
              <Typography
                className={!isRouteActive ? classes.listItemText : classes.listItemTextBold}
              >
                {route.title}
              </Typography>
            )}
          </Box>
        </ListItem>

      </span>
    </CustomTooltip>
  );
};

interface MenuItemProps {
  route: RouteItem;
  nested?: boolean;
  linkTestId: string;
  open: boolean;
}

const MenuItem = ({ route, nested = false, linkTestId, open }: MenuItemProps) => {
  const classes = useStyles();

  const { resetDate } = useArchivedHeatContext();

  const handleNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (!route.enabled) e.preventDefault();
    resetDate()
  };

  return route.path ? (
    <NavLink
      data-testid={`${linkTestId}.link`}
      to={`${route.path}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
      key={route.key}
      onClick={handleNavigate}
      className={clsx({ [classes.listItemDisabled]: !route.enabled })}>
      <Item open={open} route={route} nested={nested} />
    </NavLink>
  ) : (
    <Item route={route} nested={nested} open={open} />
  );
};

export default MenuItem;
