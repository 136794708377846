import useLoadCustomers from '@app/hooks/useLoadCustomers';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from '@app/api/customers';
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Paper, PaperProps, TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab';

export interface CustomerOptionType extends Customer {
  inputValue?: string;
}

interface CustomerAutocompleteProps {
  value?: Customer;
  onChange?: (customer?: Customer) => void;
  disabled?: boolean;
}

const CustomPaper = (props: PaperProps) => (
  <Paper elevation={8} {...props} />
);

const CustomerAutocomplete = ({
  value,
  onChange: customOnChange,
  disabled,
}: CustomerAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  
  const [customersInfoObject, loadCustomers] = useLoadCustomers();
  const { data: customers, status: customerStatus } = customersInfoObject;
  
  useEffect(() => {
    if (customerStatus === 'idle') {
      loadCustomers();
    }
  }, [loadCustomers, customerStatus]);

  const filter = createFilterOptions<CustomerOptionType>();

  const getOptionLabel = (option: CustomerOptionType): string => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name || '';
  }

  const filterOptions = (options: CustomerOptionType[], state: FilterOptionsState<CustomerOptionType>): CustomerOptionType[] => {
    const filtered = filter(options, state);

    const { inputValue } = state;
    // Suggest the creation of a new value
    const alreadyExists = options.some(option => inputValue === option.name);
    if (inputValue !== '' && !alreadyExists) {
      filtered.push({
        inputValue: state.inputValue,
        name: `Add "${state.inputValue}"`,
        id: 0,
      });
    }

    return filtered;
  }

  const defaultOnChange = (_: React.ChangeEvent<{}>, newValue: string | CustomerOptionType | null) => {
    if (typeof newValue === 'string') {
      const selected = { id: 0, name: newValue };

      customOnChange && customOnChange(selected);
      customers.push(selected);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      const selected = { id: 0, name: newValue.inputValue };

      customOnChange && customOnChange(selected);
      customers.push(selected);
    } else {
      const selected = newValue || undefined;

      customOnChange && customOnChange(selected);
    }
  }

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
    return (
      <TextField
        {...params}
        label={t('admin-panel.customer-name')}
        fullWidth
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          ...params.InputProps,
        }}
        inputProps={{
          ...params.inputProps,
          maxLength: 50,
        }} />
    );
  }
  
  return (
    <Autocomplete
      fullWidth
      PaperComponent={CustomPaper}
      selectOnFocus
      handleHomeEndKeys
      freeSolo
      clearOnBlur
      value={value as CustomerOptionType}
      options={customers as CustomerOptionType[]}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      renderOption={option => option.name}
      renderInput={renderInput}
      onChange={defaultOnChange}
      disabled={disabled}
    />
  );
}

export default CustomerAutocomplete;