import { useSlagDeoxidizersService } from '@app/api/slagDeoxidizers';
import { DeoxidizersModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { useState } from 'react';
import { InfoObject } from "./types"

const infoObjectInitialState: InfoObject<DeoxidizersModel> = {
  status: 'idle',
  data: undefined,
}

const useLoadSlagDeoxidizer = (): [
  InfoObject<DeoxidizersModel>,
  (id: number) => Promise<void>,
] => {

  const { getSlagDeoxidizer } = useSlagDeoxidizersService();

  const [infoObject, setInfoObject] = useState<InfoObject<DeoxidizersModel>>(infoObjectInitialState);

  const loadSlagDeoxidizer = async (id: number) => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getSlagDeoxidizer(id);
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {

      setInfoObject({data: undefined, status: 'error'});
    }
  };


  return [infoObject, loadSlagDeoxidizer];
}

export default useLoadSlagDeoxidizer;