import useCompositionTableCellStyles from "@app/hooks/useCompositionTableStyles";
import { text2SubscriptAsString } from "@app/utils";
import { APP_NA2O_K2O_ENABLED } from "@app/utils/constants";
import { numberFormatOnChangeHandler } from "@app/utils/numberFormatOnChangeHandler";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import { ChangeEvent, ReactNode } from "react";
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

interface CompositionTableProps {
    unitOfMeasurement: ReactNode;
    composition?: Array<{label: string, inputName: string}>;
    testIdPrefix: string;
    total?: number;
}

export const defaultComposition = [
    {label: 'MgO', inputName: 'mgO'},
    {label: 'CaO', inputName: 'caO'},
    {label: 'Al2O3', inputName: 'al2O3'},
    {label: 'SiO2', inputName: 'siO2'},
    {label: 'FeO', inputName: 'feO'},
    {label: 'MnO', inputName: 'mnO'},
    {label: 'Cr2O3', inputName: 'cr2O3'},
    {label: 'TiO2', inputName: 'tiO2'},
    {label: 'CaF2', inputName: 'caF2'},
    ...APP_NA2O_K2O_ENABLED ? [
      {label: 'Na2O', inputName: 'na2O'},
      {label: 'K2O', inputName: 'k2O'}
    ]: [],
  ]

const CompositionTable = ({ 
    unitOfMeasurement, 
    composition = defaultComposition, 
    testIdPrefix, 
    total 
}: CompositionTableProps) => {
    const {t} = useTranslation();
    const { 
        control, 
    } = useFormContext();
    const classes = useCompositionTableCellStyles().useStyles()();

    return (
        <TableContainer>
              <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell className={`${classes.root} ${classes.head}`}>
                            {t('composition')}
                        </TableCell>
                        {composition.map((item) => (
                            <TableCell key={item.inputName} className={`${classes.root} ${classes.head}`}>
                                {text2SubscriptAsString(item.label)}
                            </TableCell>
                        ))}
                        {total !== undefined && (
                            <TableCell className={`${classes.root} ${classes.head}`}>
                                Total
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell>
                            <b>{unitOfMeasurement}</b>
                        </TableCell>
                        {
                        composition.map((item) => (
                            <TableCell 
                                key={item.label}
                                className={classes.root}
                            >

                                <Controller
                                    name={item.inputName}
                                    control={control}
                                    render={({ field }) => (
                                        <NumberFormat
                                            inputProps={{
                                                "data-testid": `${testIdPrefix}.field.${item.inputName.toLowerCase()}`
                                            }}
                                            InputProps={{ className: classes.input }}
                                            {...field}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => 
                                                numberFormatOnChangeHandler(e, field.onChange)
                                            }
                                            customInput={TextField}
                                            decimalScale={2}
                                            allowNegative={false}
                                            decimalSeparator={t('decimal-scale-separator')}
                                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                                        />
                                    )}
                                />
                            </TableCell>
                        ))}
                        {(total !== undefined && (
                            <TableCell>
                                <NumberFormat
                                    inputProps={{ "data-testid": `${testIdPrefix}.field.total` }}
                                    InputProps={{ className: classes.input }}
                                    value={total}
                                    customInput={TextField}
                                    decimalScale={2}
                                    decimalSeparator={t('decimal-scale-separator')}
                                    disabled
                                />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CompositionTable;