import * as React from 'react';
import { Box, Slider, FormControl, FormHelperText, Mark, InputLabel, SliderProps } from '@material-ui/core'
import { useState, useEffect } from 'react';

interface SliderInputProps extends Omit<SliderProps, "onChange"> {
  calculateValue?: (value: number) => number;
  valueLabelFormat?: (value: number) => string;
  displayValueAtLable?: boolean;
  label: string;
  value: number;
  minValue: number;
  maxValue: number;
  onChange: (event:  React.ChangeEvent<{}>, newValue: number | number[]) => void;
  id: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  marks?: boolean | Mark[];
  testId?: string;
}

const SliderInput = ({
  calculateValue = (value: number) => value,
  valueLabelFormat = (value: number) => `${value.toFixed(2)}`,
  displayValueAtLable = false,
  label,
  value,
  minValue,
  maxValue,
  onChange,
  id,
  error = false,
  errorMessage = '',
  disabled = false,
  marks,
  testId = '', 
  ...rest
}: SliderInputProps, ref: React.ForwardedRef<HTMLDivElement>) => {

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(prevState => prevState !== value ? value : prevState);
  }, [value]);

  return (
    <Box style={{ marginBottom: 10, marginRight: 0, widows: '100%' }}>
      <FormControl 
        variant="standard" 
        fullWidth 
        error={error} 
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'initial'
        }}
      >
        { 
          label && (
            <InputLabel 
              htmlFor={id}
            >
              {`${label}${displayValueAtLable ? `: ${valueLabelFormat(calculateValue(value))}` : ''}`}
            </InputLabel>
          )
        }
        <Slider
          innerRef={ref}
          {...rest}
          id={id}
          data-testid={testId}
          value={inputValue}
          min={minValue}
          max={maxValue}
          step={1}
          scale={calculateValue}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          onChange={(e, newValue) => setInputValue(Array.isArray(newValue) ? newValue[0] : newValue)}
          onChangeCommitted={onChange}
          valueLabelDisplay="on"
          aria-labelledby="linear-slider"
          disabled={disabled}
          marks={marks}
        />
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

const ForwardedSliderInput = React.forwardRef(SliderInput);

export default ForwardedSliderInput