import PageTitle from '@app/components/PageTitle';
import { Box, Card, CardActions, CardContent, CardHeader, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, PropsWithChildren } from 'react';

interface MainPaperHeaderTitleProps {
  title: React.ReactNode;
  subtitle?: string;
  headerActions?: React.ReactNode[];
  headerActionsBellow?: boolean;
  isNewHeatScreen: boolean;
}

const MainPaperHeaderTitle = ({ title, subtitle, headerActions, headerActionsBellow, isNewHeatScreen }: MainPaperHeaderTitleProps) => {
  const classes = useStyles(false)();

  return (
    <Box
      style={
        headerActionsBellow ? {
          display: "flex",
          flexDirection: "column",
          rowGap: "30px"
        } : {
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
          flexWrap: 'wrap'
        }}
    >
      {typeof title === 'string' ? <PageTitle title={title} subtitle={subtitle} /> : title}

        {!isNewHeatScreen ? (
          <>
            {headerActions?.some(s => !!s) && (
              <Box className={classes.headerActions}>{headerActions.filter(f => !!f).map((node, index) => <Fragment key={index}>{node}</Fragment>)}</Box>
            )}
          </>
        ) : (
          <>
            {headerActions?.filter(f => !!f).map((node, index) => <Fragment key={index}>{node}</Fragment>)}
          </>
        )}
    </Box>
  );
};

interface MainPaperProps extends PropsWithChildren<{}> {
  title: React.ReactNode;
  subtitle?: string;
  subheader?: React.ReactNode;
  headerActions?: React.ReactNode[];
  footerActions?: React.ReactNode[];
  removePadding?: boolean;
  headerActionsBellow?: boolean;
  isNewHeatScreen?: boolean;
}

const MainPaper = ({
  children,
  title,
  subtitle,
  subheader,
  headerActions,
  footerActions,
  removePadding,
  headerActionsBellow,
  isNewHeatScreen = false,
}: MainPaperProps) => {
  const classes = useStyles(removePadding)();

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <MainPaperHeaderTitle
            title={title}
            subtitle={subtitle}
            headerActions={headerActions}
            headerActionsBellow={headerActionsBellow}
            isNewHeatScreen={isNewHeatScreen}
          />
        }
        subheader={subheader}
      />

      <CardContent
        className={clsx(classes.content)}
        style={{ flex: 1 }}>
        {children}
      </CardContent>

      {footerActions?.some(s => !!s) && (
        <CardActions className={classes.footerActions}>
          {footerActions.filter(f => !!f).map(node => node)}
        </CardActions>
      )}
    </Card>
  );
};

const useStyles = (removePadding = false) => makeStyles(theme =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    content: {
      padding: removePadding ? 0 : '8px 32px 32px 32px',
      backgroundColor: '#fff',
      overflow: 'auto'
    },
    footerActions: {
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.secondary.main}`,
    },
    headerActions: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      gap: '8px',
    },
  }),
);

export default MainPaper;
