import { format as formatFunction, formatISO, parseISO } from 'date-fns';

const formatDateTime = (date: string | undefined, format: string, valueIfEmpty = '-'): [string, boolean] => {
  let hasValue: boolean;
  let formattedDate: string;

  if (date && date.trim().length > 0) {
    hasValue = true;
    formattedDate = formatFunction(new Date(date), format);
  } else {
    hasValue = false;
    formattedDate = valueIfEmpty;
  }

  return [
    formattedDate,
    hasValue
  ];
}

const formatISODateTime = (date?: Date | number): string | undefined => {
  if (date) {
    return formatISO(date);
  }

  return undefined;
}

const parseISODateTime = (date?: string): Date | undefined => {
  if (date) {
    return parseISO(date);
  }

  return undefined;
}

export const DateTimeUtils = {
    format: formatDateTime,
    formatISO: formatISODateTime,
    parseISO: parseISODateTime,
}