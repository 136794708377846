import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { CheckCircleOutline, ReportProblemOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";
import { PlantSetupRoutesTabs } from "@app/utils/plantSetupRoutesPathTabs";


interface StatusChipProp {
    title: string,
    isValid: boolean,
    url: string,
    state?: { option: PlantSetupRoutesTabs }
    linkId: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        chip: {
            borderRadius: 4,
            color: theme.palette.common.black,
            width: '100%',
            padding: '8px 10px',
            marginBottom: 8
        },

        warnig: {
            backgroundColor: '#FBF5CC'
        },

        valid: {
            backgroundColor: '#DBF2FF'
        },

        warningIcon: {
            color: '#CDB622',
            marginRight: '8px'
        },

        checkedIcon: {
            color: '#0E93DD',
            marginRight: '8px'
        },

        lastItem: {
            marginLeft: 'auto'
        }
    })
);

const OverviewStatusChip = (props: StatusChipProp) => {

    const { title, isValid, url, state, linkId } = props;

    const { t } = useTranslation();

    const styles = useStyles();

    const statusStyle = isValid ? clsx(styles.chip, styles.valid) : clsx(styles.chip, styles.warnig);
    const statusIcon = isValid ?
        <CheckCircleOutline fontSize="small" className={styles.checkedIcon} /> : <ReportProblemOutlined fontSize="small" className={styles.warningIcon} />

    return (
        <Box className={statusStyle}>
            <Grid container item justifyContent='space-between'>
                <Box style={{ display: 'flex' }}>
                    {statusIcon}
                    {t(title)}
                </Box>
                <Link 
                    data-testid={linkId}
                    className={styles.lastItem} 
                    to={{ pathname: url, state }}
                >
                    {t('button.view')}
                </Link>
            </Grid>
        </Box>
    );
};

export default OverviewStatusChip;