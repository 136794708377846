import { FluxCategoryParameters, useFluxesAvailabilityService, useFluxesTypeService } from '@app/api/fluxes';
import { useState } from 'react';
import { HooksStatus } from "./types"

const useLoadFluxesAvailabilitiesAndTypes = (): [
  HooksStatus,
  () => void,
  FluxCategoryParameters[] | undefined,
  FluxCategoryParameters[] | undefined
] => {
  const initialState: HooksStatus = 'idle';

  const [status, setStatus] = useState<HooksStatus>(initialState);
  const [fluxAvailabilities, setFluxAvailabilities] = useState<FluxCategoryParameters[] | undefined>();
  const [fluxTypes, setFluxTypes] = useState<FluxCategoryParameters[] | undefined>();

  const { getFluxAvailabilities } = useFluxesAvailabilityService();
  const { getFluxTypes } = useFluxesTypeService();

  const loadFluxAvailabilitiesAndTypes = async () => {
    try {
      setStatus("loading");
      const availabilitiesServiceResponse = await getFluxAvailabilities();
      const typesServiceResponse = await getFluxTypes();
      setFluxAvailabilities(availabilitiesServiceResponse);
      setFluxTypes(typesServiceResponse);
      setStatus("succeeded");
    } catch (err) {

      setStatus("error");
    }
  };
 
  return [status, loadFluxAvailabilitiesAndTypes, fluxAvailabilities, fluxTypes];
}

export default useLoadFluxesAvailabilitiesAndTypes;