import { JsonReturnModel } from '@app/api';
import { CarryoverSlagReferenceGetResponse, useCarryoverSlagReferencesService } from '@app/api/carryoverSlagReferences';
import { useHeatsService } from '@app/api/heats';
import { Tap, useLadleFurnacesService } from '@app/api/ladleFurnaces';
import { LadleFurnaceModel } from "@app/api/ladleFurnaces/LadleFurnaceModel"
import { GetLadlesResponse, useLadlesService } from '@app/api/ladles';
import { useSteelGradesService } from '@app/api/steelGrades';
import MainPaper from '@app/components/shared/MainPaper';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import {
  APP_TITLE,
  APP_DESULFURIZATION_INPUT_ENABLED,
  CHEMISTRY_AT_TAPPING_ALUMINUM_POINTS_CONVERSION_FACTOR,
  CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR,
  MAX_INITIAL_SULFUR_PERCENTTAGE,
  MAX_OXYGEN_CONTENT,
  MIN_INITIAL_SULFUR_PERCENTAGE,
  MIN_OXYGEN_CONTENT,
  UserRoles,
  UOM_CENTIMETERS_SYMBOL,
  PERMISSIONS
} from '@app/utils/constants';
import { Step, StepLabel, StepIcon, Stepper, Theme, withStyles, Box, Tooltip, Typography, useTheme, List, ListItem } from '@material-ui/core';
import { ArrowBackRounded, ArrowForwardRounded } from '@material-ui/icons';
import React, { createRef, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Swal from 'sweetalert2';
import SwalModalComponent, { SweetAlert2Props } from 'react-sweetalert2';
import { getStep, InitialState, initialState, stepsInitialState } from '../heatsUtils';
import { isFixedAdditionAmountAtTapping, isFixedAdditionAmountAtLF, isLimeSource, isMgOSource, isDolomaSource, handleSendEvent } from '@app/utils';
import ISelectedCarryoverSlag from './ISelectedCarryoverSlag';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
import useSaveOrUpdateHeatDraft from '@app/hooks/useSaveHeatDraft';
import Loading from '@app/components/shared/Loading';
import SaveOnExitDialog from '@app/components/shared/SaveOnExitDialog';
import { calculateB2andB3, useLoadHeatInfoIntoFormStates } from '@app/hooks/useLoadHeatDataIntoFormStates';
import {
  CalculatedSlags,
  CarryoverSlagType,
  DeoxidizerType,
  HeatChargeWeightArray,
  HeatRecommendationsDTO,
  HeatRecommendationStageDTO,
  OutputDetailsDTO,
  ResultDTO,
  SaturationMode,
  SaveHeatRequestPayload,
  Stage,
  StageName,
  StageType,
  SteelChemistryStageName
} from '@app/api/heats/HeatsResponse';
import { AlloyListStateDTO, FluxModel, SteelGradeModel, DeoxidizersModel, ParsedFluxModel, FluxType } from './Steps/heatCalculationTypes';
import useSaveHeat from '@app/hooks/useSaveHeat';
import { SteelChemistryUnitOfMeasurement, ProcessConstraintsDTO, SteelChemistryConfigs } from '@app/api/processConstraints/useProcessConstraintsService';
import useLoadAlloys from '@app/hooks/useLoadAlloys';
import useLoadFluxes from '@app/hooks/useLoadFluxes';
import useLoadSlagDeoxidizers from '@app/hooks/useLoadSlagDeoxidizers';
import useLoadProcessConstraints from '@app/hooks/useLoadProcessConstraints';
import { CalculationMode } from '@app/utils/calculationModeOptions';
import useCheckIfHeatNameExists from '@app/hooks/useVerifyIfHeatNameExists';
import { useConfirmRedirectContext } from '@app/store/confirmRedirectContext/confirmRedirectContext';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';

import {
  ReportProblemOutlined as ReportProblemOutlinedIcon,
  CheckCircleRounded as CheckIcon,
} from '@material-ui/icons';
import StepperNavigationButton from '@app/components/StepperNavigationButton';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import CustomAccordion from '@app/components/shared/CustomAccordion';
import FormButton from '@app/components/shared/FormButton';
import { convertMetersIntoOtherUnitOfMeasurement, convertOtherUnitOfMeasurementIntoMeters } from '@app/utils/convertLengthUnits';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { LimitInfo } from '@app/api/limits/limitsTypes';
import { StringUtils } from '@app/utils/stringUtils';

export interface SweetAlertComponentProps extends SweetAlert2Props {
  details?: Array<string>;
}

export enum HeatMode {
  Draft = 'draft',
  Operation = 'operation',
}

const getDefaultHeatMode = (userProfile: string | undefined, isEditing = false): HeatMode => {
  if (!isEditing && (userProfile === UserRoles.SUPERVISOR || userProfile === UserRoles.OPERATOR)) {
    return HeatMode.Operation;
  } else {
    return HeatMode.Draft;
  }
}

const NewHeat = (): ReactElement => {
  const history = useHistory();
  const { id: draftEditModeId } = useParams<{ id?: string }>();

  const { t } = useTranslation();
  const {
    state, hasAccess
  } = useAuthenticationContext();

  const userInfoObject = state.userInfoObject;
  const limitsInfoObject = state.limitsInfoObject;

  const userInfo = userInfoObject?.data;
  const measurementSystem = userInfo?.measurementSystem;

  const {
    state: { userConfirmedRedirect, shouldDisplayPrompt },
    updateConfirmRedirectState,
    updateShouldDisplayPromptState
  } = useConfirmRedirectContext();

  const isCarryoverSlagInferenceRanged = userInfo?.selectedPlant?.carryoverSlagInferenceType === CarryoverSlagInferenceType.Range;

  const { getLadleFurnaces } = useLadleFurnacesService();
  const { getSteelGrades } = useSteelGradesService();
  const { getCarryoverSlagReferences } = useCarryoverSlagReferencesService();
  const { calculateHeat: calculate, getDraftHeatById } = useHeatsService();
  const [saveHeatStatus, savingHeat] = useSaveHeat();

  const { getLadles } = useLadlesService();

  const [hasUnsavedFluxesAdditions, setHasUnsavedFluxesAdditions] = useState(false);
  const [hasUnsavedAlloysAdditions, setHasUnsavedAlloysAdditions] = useState(false);
  const [hasUnsavedDeoxidizersAdditions, setHasUnsavedDeoxidizersAdditions] = useState(false);
  const [lastAttemptStepChangeValue, setLastAttemptStepChangeValue] = useState(-1);
  const [userClickedToAddUnaddedAdditions, setUserClickedToAddUnaddedAdditions] = useState(false);
  const [confirmDialogIsVisible, setConfirmDialogIsVisible] = useState(false);

  // heatMode toggle state ('operation' | 'draft')
  const [heatMode, setHeatMode] = useState<HeatMode>(getDefaultHeatMode(userInfo?.userProfileName, StringUtils.hasValue(draftEditModeId)));

  const [hasUnsavedDraftChanges, setHasUnsavedDraftChanges] = useState(false);
  const [hasUncalculatedChanges, setHasUncalculatedChanges] = useState<boolean | null>(null);

  const [initialLoadFinalized, setInitialLoadFinalized] = useState(false);

  const [preventChangeScreenModal, setPreventChangeScreenModal] = useState(false);

  const [loadedFluxesData, setLoadedFluxesData] = useState(false);

  const swalPropsInitialState: SweetAlertComponentProps = {
    show: false,
    details: undefined,
  }

  const [swalProps, setSwalProps] = useState<SweetAlertComponentProps>(swalPropsInitialState);

  const { details, ...swalComponentProps } = swalProps;

  // step state
  const [step, setStep] = useState(0);

  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if(
      (!hasUnsavedFluxesAdditions || !hasUnsavedAlloysAdditions || !hasUnsavedDeoxidizersAdditions)
      && confirmDialogIsVisible
      && userClickedToAddUnaddedAdditions
    ) {
      setConfirmDialogIsVisible(false);
      setStep(lastAttemptStepChangeValue);
      setLastAttemptStepChangeValue(-1);
      setUserClickedToAddUnaddedAdditions(false);
    }
  }, [
    hasUnsavedFluxesAdditions,
    hasUnsavedAlloysAdditions,
    hasUnsavedDeoxidizersAdditions,
    confirmDialogIsVisible,
    setLastAttemptStepChangeValue,
    lastAttemptStepChangeValue,
    setConfirmDialogIsVisible,
    setStep,
    userClickedToAddUnaddedAdditions,
    setUserClickedToAddUnaddedAdditions
  ]);

  // step 1
  const [changedHeatMode, setChangedHeatMode] = useState(false);
  const [ladlesList, setLadlesList] = useState<GetLadlesResponse[]>([]);
  const [ladleFurnaceList, setLadleFurnaceList] = useState<LadleFurnaceModel[]>([]);
  const [steelGradeList, setSteelGradeList] = useState<SteelGradeModel[]>([]);
  const [alloyList, setAlloyList] = useState<AlloyListStateDTO[]>([]);
  const [deoxidizerList, setDeoxidizerList] = useState<DeoxidizersModel[]>([]);
  const [fluxList, setFluxList] = useState<FluxModel[]>([]);
  const [tapList, setTapList] = useState<Tap[]>([]);
  const [processConstraintsList, setProcessConstraintsList] = useState<ProcessConstraintsDTO>();
  const [carryoverSlagReferenceList, setCarryoverSlagReferenceList] = useState<CarryoverSlagReferenceGetResponse[]>([]);
  const [isHeatChanged, setIsHeatChanged] = useState(false);
  const [isDesulfurizationEnabled, setIsDesulfurizationEnabled] = useState(false);

  // step 2
  const [alloysChargeWeightList, setAlloyChargeWeightList] = useState<HeatChargeWeightArray>([]);
  const [fluxesChargeWeightList, setFluxesChargeWeightList] = useState<HeatChargeWeightArray>([]);
  const [fluxesChargeWeightFixedList, setFluxesChargeWeightFixedList] = useState<HeatChargeWeightArray>([]);
  const [deoxidizersChargeWeightList, setDeoxidizersChargeWeightList] = useState<HeatChargeWeightArray>([]);

  // step 3
  const [alloysChargeWeightListAtLF, setAlloyChargeWeightListAtLF] = useState<HeatChargeWeightArray>([]);
  const [fluxesChargeWeightListAtLF, setFluxesChargeWeightListAtLF] = useState<HeatChargeWeightArray>([]);
  const [deoxidizersChargeWeightListAtLF, setDeoxidizersChargeWeightListAtLF] = useState<HeatChargeWeightArray>([]);
  const [fluxesChargeWeightFixedListAtLF, setFluxesChargeWeightFixedListAtLF] = useState<HeatChargeWeightArray>([]);

  const [siRecovery, setSiRecovery] = useState('');
  const [alRecovery, setAlRecovery] = useState('');
  const [mnRecovery, setMnRecovery] = useState('');
  const [crRecovery, setCrRecovery] = useState('');

  // step 4
  const [steelChemistryConfigs, setSteelChemistryConfigs] = useState<SteelChemistryConfigs>();
  const [steelChemistryPrimaryFurnaceSi, setSteelChemistryPrimaryFurnaceSi] = useState('');
  const [steelChemistryPrimaryFurnaceDissolvedAl, setSteelChemistryPrimaryFurnaceDissolvedAl] = useState('');
  const [steelChemistryPrimaryFurnaceMn, setSteelChemistryPrimaryFurnaceMn] = useState('');
  const [steelChemistryPrimaryFurnaceCr, setSteelChemistryPrimaryFurnaceCr] = useState('');
  const [steelChemistryTappingSi, setSteelChemistryTappingSi] = useState('');
  const [steelChemistryTappingDissolvedAl, setSteelChemistryTappingDissolvedAl] = useState('');
  const [steelChemistryTappingMn, setSteelChemistryTappingMn] = useState('');
  const [steelChemistryTappingCr, setSteelChemistryTappingCr] = useState('');
  const [selectedCarryoverSlag, setSelectedCarryoverSlag] = useState<ISelectedCarryoverSlag | undefined>(undefined);
  const [fluxSelection1, setFluxSelection1] = useState('');
  const [deoxidizerSelection, setDeoxidizerSelection] = useState<DeoxidizerType | ''>('');
  const [useR2O3Target, setUseR2O3Target] = useState(false);
  const [r2O3Target, setR2O3Target] = useState('');
  const [carryoverSlagType, setCarryoverSlagType] = useState(CarryoverSlagType.Weight);
  const [isManganeseEnabled, setIsManganeseEnabled] = useState(false);
  const [isChromiumEnabled, setIsChromiumEnabled] = useState(false);

  const [
    ignoreInvalidPlantSetupThatEnablesCalculation,
    setIgnoreInvalidPlantSetupThatEnablesCalculation
  ] = useState(false);

  // step 5
  const [result, setResult] = useState<ResultDTO>();
  const [outputDetails, setOutputDetails] = useState<OutputDetailsDTO>();
  const [recommendations, setRecommendations] = useState<HeatRecommendationsDTO | undefined>();
  const [step5FormIsDirty, setStep5FormIsDirty] = useState(false);
  const [didUserAcceptedRecommendations, setDidUserAcceptedRecommendations] = useState(false);

  const [recommendedAdditions, setRecommendedAdditions] = useState<HeatRecommendationsDTO  | undefined>(undefined);
  const [isRecalculation, setIsRecalculation] = useState(false);

  // step 6
  const [calculatedSlags, setCalculatedSlags] = useState<CalculatedSlags | undefined>(undefined);

  // step 7
  const [slagAppearance, setSlagAppearance] = useState('');
  const [slagAppearanceText, setSlagAppearanceText] = useState('');
  const [heatCalculation, setHeatCalculation] = useState<InitialState>(initialState);

  const [changedHeatNameInput, setChangedHeatNameInput] = useState(false);
  const [heatNameInputIsFocused, setHeatNameInputIsFocused] = useState(false);

  const [loadedCarryoverSlagsReferenceList, setLoadedCarryoverSlagsReferenceList] = useState(false);

  const { pathname } = useLocation();

  const [checkIfHeatNameExistsInfoObject, checkIfHeatNameExists] = useCheckIfHeatNameExists();
  const {
    status: checkIfHeatNameExistsStatus,
    data: checkIfHeatNameExistsData
  } = checkIfHeatNameExistsInfoObject;

  const isTappingMode = pathname.includes("tapping");

  const isCalculationSetupStep = isTappingMode ? 2 : 3;
  const isRecommendedAdditonsStep = isTappingMode ? 3 : 4;
  const isCalculatedSlagsStep = isTappingMode ? 4 : 5;
  const isFeedbackStep = isTappingMode ? 5 : 6;
  const hasLadleFurnaceAdditions = isTappingMode? -1 : 2;


  useEffect(() => {
    setTapList(ladleFurnaceList.find(ladleItem => ladleItem.name === heatCalculation?.ladleFurnace)?.tapLadleFurnaces ?? [])
  }, [heatCalculation.ladleFurnace, ladleFurnaceList]);

  const handleChangeHeatCalculation = useCallback((
    field: keyof InitialState | string[],
    value?:
      string | number | boolean | null | Array<string | number | undefined | boolean | null>
  ) => {
    const newInitialState = {...heatCalculation} as InitialState;

    if (field === 'heatName') {
      setIsHeatChanged(true);
      setChangedHeatNameInput(true);
      setHeatNameInputIsFocused(true);
    } else {
      setChangedHeatNameInput(false);
    }

    if (typeof field === 'object') {
      field.forEach((f: string, id: number) => {
        //@ts-ignore
        newInitialState[f] = value[id];
      });
    }
    else {
      //@ts-ignore
      newInitialState[field] = value;
    }

    setHeatCalculation(newInitialState);
  }, [heatCalculation]);

  useEffect(() => {
    if (step === 3) {
      setIsRecalculation(false);
    }
  }, [step]);

  const stepsList = React.useMemo(
    () => isTappingMode ? [
      t('general-information'),
      t('new-heat.title.step-2'),
      t('new-heat.title.step-4'),
      t('new-heat.title.step-5'),
      t('calculated-slags'),
      t('feedback'),
    ] : [
      t('general-information'),
      t('new-heat.title.step-2'),
      t('new-heat.title.step-3'),
      t('new-heat.title.step-4'),
      t('new-heat.title.step-5'),
      t('calculated-slags'),
      t('feedback'),
    ],
    [t, isTappingMode],
  );

  const verifyHeatName = async (name: string) => {
    setIsHeatChanged(false);
    await checkIfHeatNameExists({
      heatName: name,
      calculationMode:
        isTappingMode
        ? CalculationMode.Tapping
        : CalculationMode.LadleFurnace
    });
    setHeatNameInputIsFocused(false);
  }

  const { canChangeLadle, canUseName, ladleLife, ladleName, isNewCampaign } = checkIfHeatNameExistsData;

  const [lastCheckedHeatNameCouldChangeLadle, setLastCheckedHeatNameCouldChangeLadle] = useState(false);

  useEffect(() => {
    if(
      lastCheckedHeatNameCouldChangeLadle
      && canChangeLadle
      && checkIfHeatNameExistsStatus === 'succeeded'
      && changedHeatNameInput
      && !heatNameInputIsFocused
    ) {
      handleChangeHeatCalculation('isHeatNameInvalid', !canUseName);
      return;
    } else if(
      checkIfHeatNameExistsStatus === 'succeeded'
      && changedHeatNameInput
      && !heatNameInputIsFocused
    ) {
      setLastCheckedHeatNameCouldChangeLadle(canChangeLadle);
      handleChangeHeatCalculation(
        [
          'isHeatNameInvalid',
          'ladleLifeTime',
          'ladleName',
          'isNewCampaign'
        ],
        [
          !canUseName,
          ladleLife || '',
          ladleName || '',
          isNewCampaign ?? false
        ])
    }
  }, [
    checkIfHeatNameExistsStatus,
    canUseName,
    handleChangeHeatCalculation,
    isNewCampaign,
    ladleLife,
    ladleName,
    changedHeatNameInput,
    heatNameInputIsFocused,
    lastCheckedHeatNameCouldChangeLadle,
    canChangeLadle
  ])

  const loadLadles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getLadles();
      setLadlesList(response.map((r: GetLadlesResponse) => ({ ...r, ladleName: r.ladleName.toString() })));
    } finally {
      setLoading(false)
    }
  }, [getLadles]);

  const loadLadleFurnace = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getLadleFurnaces();

      setLadleFurnaceList(response as LadleFurnaceModel[]);
    } finally {
      setLoading(false)
    }
  }, [getLadleFurnaces]);

  const [alloysInfoObject, loadAlloy] = useLoadAlloys();
  const [fluxesInfoObject, loadFlux] = useLoadFluxes();
  const [slagDeoxidizersInfoObject, loadDeoxidizers] = useLoadSlagDeoxidizers();
  const [processConstraintsInfoObject, loadProcessConstraints] = useLoadProcessConstraints();
  const [draftState, saveAsDraft, updateDraft, setDraftId] = useSaveOrUpdateHeatDraft({
    setDetailedSwalFire: setSwalProps,
    onConfirmError: () => {
      setSwalProps(swalPropsInitialState);
    }
  });
  const [status, loadHeatDataIntoFormStates, savedDraftHeatData ] = useLoadHeatInfoIntoFormStates();

  const loadSteelGrade = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getSteelGrades(userInfo?.selectedPlant.carryoverSlagInferenceType as CarryoverSlagInferenceType);

      setSteelGradeList(response);
    } finally {
      setLoading(false);
    }
  }, [getSteelGrades, userInfo?.selectedPlant.carryoverSlagInferenceType]);

  const { data: alloysData, status: alloysStatus } = alloysInfoObject;
  const { data: fluxesData, status: fluxesStatus } = fluxesInfoObject;
  const { data: slagDeoxidizersData, status: slagDeoxidizersStatus } = slagDeoxidizersInfoObject;
  const { data: processConstraintsData, status: processConstraintsStatus} = processConstraintsInfoObject;

  const selectedCategory = useMemo(() => heatCalculation.steelGradeCategory, [heatCalculation.steelGradeCategory]);

  useEffect(() => {
    if(alloysStatus === 'succeeded' || alloysStatus === 'error')
      setAlloyList(alloysData.map(a => ({ ...a, fe: 0 })))
  }, [alloysData, alloysStatus]);

  useEffect(() => {
    const checkFixedAmountWeightGreaterThanZero = (flux: FluxModel) : boolean => {
      return ((flux.fixedAdditionAmount ?? 0) * ((flux.fixedAdditionRecovery ?? 0) / 100)) > 0;
    };

    if(fluxesStatus === 'succeeded' || fluxesStatus === 'error') {
      setFluxList(fluxesData);
      setFluxesChargeWeightFixedList(
        fluxesData
          .filter(f => isFixedAdditionAmountAtTapping(f.availability ?? '') && checkFixedAmountWeightGreaterThanZero(f))
          .map(f => ({
            name: f.name,
            weight: (f?.fixedAdditionAmount ?? 0) * ((f?.fixedAdditionRecovery ?? 0) / 100),
            externalId: f.externalId,
          })),
      );
      setFluxesChargeWeightFixedListAtLF(
        fluxesData
          .filter(f => isFixedAdditionAmountAtLF(f?.availability ?? '') && checkFixedAmountWeightGreaterThanZero(f))
          .map(f => ({
            name: f.name,
            weight: (f?.fixedAdditionAmount ?? 0) * ((f?.fixedAdditionRecovery ?? 0)/ 100),
            externalId: f.externalId,
          })),
      );
      setLoadedFluxesData(true);
    }
  }, [fluxesData, fluxesStatus])

  useEffect(() => {
    if(slagDeoxidizersStatus === 'succeeded' || slagDeoxidizersStatus === 'error')
      setDeoxidizerList(slagDeoxidizersData.filter(d => d.isEnabled));
  }, [slagDeoxidizersStatus, slagDeoxidizersData]);


  const loadCarryoverSlagReference = useCallback(async () => {
    const response = await getCarryoverSlagReferences();

    setCarryoverSlagReferenceList(response);
    setLoadedCarryoverSlagsReferenceList(true);
  }, [getCarryoverSlagReferences]);

  const inputChargeChemistries = savedDraftHeatData?.input?.chargeChemistries;

  const carryoverSlagTypeChanged =
    savedDraftHeatData?.input?.processConstraints?.carryoverSlagType !== undefined
    && savedDraftHeatData?.input?.processConstraints?.carryoverSlagType !== null
    && carryoverSlagType !== savedDraftHeatData?.input?.processConstraints?.carryoverSlagType

  const carryoverSlagWeightLimits = limitsInfoObject?.data?.carryoverSlagWeight as LimitInfo;
  const slagThicknessLimits = limitsInfoObject?.data?.slagThickness as LimitInfo;
  const finalTemperatureLimits = limitsInfoObject?.data?.finalTemperature as LimitInfo;

  const isMeasurementSystemMetric = measurementSystem === SystemOfMeasurementOptions.Metric;
  const slagThicknessMaximum = isMeasurementSystemMetric
    ? convertMetersIntoOtherUnitOfMeasurement(slagThicknessLimits?.maximum, UOM_CENTIMETERS_SYMBOL)
    : slagThicknessLimits?.maximum;
  const slagThicknessMinimum = isMeasurementSystemMetric
    ? convertMetersIntoOtherUnitOfMeasurement(slagThicknessLimits?.minimum, "cm")
    : slagThicknessLimits?.minimum;

  const maxCarryoverSlagWeightOrThickness =
    Number(
      processConstraintsList?.carryoverSlagType === CarryoverSlagType.Thickness
        ? slagThicknessMaximum
        : carryoverSlagWeightLimits?.maximum
    );
  const minCarryoverSlagWeightOrThickness =
    Number(
      processConstraintsList?.carryoverSlagType === CarryoverSlagType.Thickness
        ? slagThicknessMinimum
        : carryoverSlagWeightLimits?.minimum
    );

  const carryoverSlagWeightOrThicknessIsInvalid = Number(heatCalculation.carryoverSlagWeight) < minCarryoverSlagWeightOrThickness
  || Number(heatCalculation.carryoverSlagWeight) > maxCarryoverSlagWeightOrThickness;

  const isInvalidCarryoverSlagWeight =
  (carryoverSlagTypeChanged || heatCalculation.carryoverSlagWeight === '')
  || carryoverSlagType === CarryoverSlagType.Thickness
  ? carryoverSlagWeightOrThicknessIsInvalid
  : Number(heatCalculation.carryoverSlagWeight) < Number((processConstraintsList?.minimumSlagCarryoverWeight ?? 0))
  || carryoverSlagWeightOrThicknessIsInvalid;

  const isDesulfurizationDisabled = !isDesulfurizationEnabled || !APP_DESULFURIZATION_INPUT_ENABLED;

  const shouldEnableNextStep1 = useMemo(
    () =>
      // heat name
      !!heatCalculation.heatName &&
      // steel grade
      !!heatCalculation.steelGrade &&
      !!steelGradeList.some(steelGradeItem => steelGradeItem.name === heatCalculation?.steelGrade) &&
      // final temperature
      !!heatCalculation.finalTemperature &&
      Number(heatCalculation.finalTemperature) >= finalTemperatureLimits?.minimum &&
      Number(heatCalculation.finalTemperature) <= finalTemperatureLimits?.maximum &&
      // saturation mode
      !!heatCalculation.saturationMode &&
      // initial sulfur
      (
        (
          isDesulfurizationDisabled
        ) ? true : (
          !!heatCalculation.initialSulfurPercentage &&
          heatCalculation.initialSulfurPercentage >= MIN_INITIAL_SULFUR_PERCENTAGE &&
          heatCalculation.initialSulfurPercentage <= MAX_INITIAL_SULFUR_PERCENTTAGE
        )
      ) &&
      // oxygen content
      (
        (
          isDesulfurizationDisabled
        ) ? true : (
          !!heatCalculation.finalOxygenInSteel &&
          heatCalculation.finalOxygenInSteel >= MIN_OXYGEN_CONTENT &&
          heatCalculation.finalOxygenInSteel <= MAX_OXYGEN_CONTENT
        )
      ) &&
      // carryoverslag reference input
      !!selectedCarryoverSlag &&
      // carryoverslag weight
      (
        !!heatCalculation.carryoverSlagWeight &&
        carryoverSlagType === CarryoverSlagType.Thickness ||
        Number(heatCalculation.carryoverSlagWeight) > 0 &&
        !isInvalidCarryoverSlagWeight
      ) &&
      // skimmer use
      !!(!heatCalculation.skimmerActive || !!heatCalculation.skimmerPostSlagWeight) &&
      // ladle furnace and tap configs
      (
        isTappingMode
        ? true
        : !isNaN(Number(heatCalculation.tap)) &&
        !!tapList.length
      ) &&
      // heatname (acording to API)
      !heatCalculation.isHeatNameInvalid &&
      // ladleLife (acording to API)
      !heatCalculation.isLadleLifeInvalid &&
      // ladle lifetime
      !!heatCalculation.ladleLifeTime &&
      // ladle name
      !!heatCalculation.ladleName &&
      checkIfHeatNameExistsStatus !== 'loading' &&
      !isHeatChanged
    , [
      heatCalculation.heatName,
      heatCalculation.steelGrade,
      steelGradeList,
      heatCalculation.finalTemperature,
      heatCalculation.carryoverSlagWeight,
      selectedCarryoverSlag,
      isTappingMode,
      heatCalculation.tap,
      heatCalculation.skimmerActive,
      heatCalculation.skimmerPostSlagWeight,
      heatCalculation.isHeatNameInvalid,
      heatCalculation.isLadleLifeInvalid,
      heatCalculation.saturationMode,
      heatCalculation.initialSulfurPercentage,
      heatCalculation.finalOxygenInSteel,
      tapList.length,
      heatCalculation.ladleLifeTime,
      heatCalculation.ladleName,
      checkIfHeatNameExistsStatus,
      isHeatChanged,
      isInvalidCarryoverSlagWeight,
      carryoverSlagType,
      isDesulfurizationDisabled,
      finalTemperatureLimits
    ]
  );

  const shouldEnableStep1SaveAsDraft = useMemo(
    () =>
      // heat name
      !!heatCalculation.heatDescription &&
      // steel grade
      !!heatCalculation.steelGrade &&
      !!steelGradeList.some(steelGradeItem => steelGradeItem.name === heatCalculation?.steelGrade) &&
      // final temperature
      !!heatCalculation.finalTemperature &&
      Number(heatCalculation.finalTemperature) >= finalTemperatureLimits?.minimum &&
      Number(heatCalculation.finalTemperature) <= finalTemperatureLimits?.maximum &&
      // saturation mode
      !!heatCalculation.saturationMode &&
      // initial sulfur
      (
        (
          isDesulfurizationDisabled
        ) ? true : (
          !!heatCalculation.initialSulfurPercentage &&
          heatCalculation.initialSulfurPercentage >= MIN_INITIAL_SULFUR_PERCENTAGE &&
          heatCalculation.initialSulfurPercentage <= MAX_INITIAL_SULFUR_PERCENTTAGE
        )
      ) &&
      // oxygen content
      (
        (
          isDesulfurizationDisabled
        ) ? true : (
          !!heatCalculation.finalOxygenInSteel &&
          heatCalculation.finalOxygenInSteel >= MIN_OXYGEN_CONTENT &&
          heatCalculation.finalOxygenInSteel <= MAX_OXYGEN_CONTENT
        )
      ) &&
      // carryoverslag reference input
      !!selectedCarryoverSlag &&
      // carryoverslagWeight
      (
        !!heatCalculation.carryoverSlagWeight &&
        carryoverSlagType === CarryoverSlagType.Thickness ||
        Number(heatCalculation.carryoverSlagWeight) > 0 &&
        !isInvalidCarryoverSlagWeight
      ) &&
      // skimmer use
      !!(!heatCalculation.skimmerActive || !!heatCalculation.skimmerPostSlagWeight) &&
      // ladleFurnace and tap configs
      (
        isTappingMode
        ? true
        : !isNaN(Number(heatCalculation.tap)) &&
        !!tapList.length
      ) &&
      !heatCalculation.isHeatNameInvalid &&
      !heatCalculation.isLadleLifeInvalid &&
      !!heatCalculation.ladleLifeTime &&
      checkIfHeatNameExistsStatus !== 'loading' &&
      !isHeatChanged
    , [
      heatCalculation.carryoverSlagWeight,
      selectedCarryoverSlag,
      heatCalculation.finalTemperature,
      heatCalculation.isHeatNameInvalid,
      heatCalculation.isLadleLifeInvalid,
      heatCalculation.ladleLifeTime,
      heatCalculation.skimmerActive,
      heatCalculation.skimmerPostSlagWeight,
      heatCalculation.steelGrade,
      heatCalculation.saturationMode,
      heatCalculation.initialSulfurPercentage,
      heatCalculation.finalOxygenInSteel,
      steelGradeList,
      isTappingMode,
      heatCalculation.tap,
      heatCalculation.heatDescription,
      isHeatChanged,
      checkIfHeatNameExistsStatus,
      tapList.length,
      isInvalidCarryoverSlagWeight,
      carryoverSlagType,
      isDesulfurizationDisabled,
      finalTemperatureLimits
    ])

  useEffect(() => {
    const displayPrompt = heatMode === 'draft'
      ? shouldEnableStep1SaveAsDraft && hasUnsavedDraftChanges
      : shouldEnableNextStep1 && !preventChangeScreenModal;
    if(shouldDisplayPrompt !== displayPrompt) {
      updateShouldDisplayPromptState(displayPrompt);
    }
  }, [
    shouldEnableStep1SaveAsDraft,
    shouldDisplayPrompt,
    updateShouldDisplayPromptState,
    heatMode,
    shouldEnableNextStep1,
    hasUnsavedDraftChanges,
    preventChangeScreenModal
  ])

  const shouldEnableNextStep5 = useMemo(() => {
    return recommendedAdditions?.alloys?.every(alloyRecommendedAddition => String(alloyRecommendedAddition.realAddedWeight ?? "") !== "")
      && recommendedAdditions?.deoxidizers?.every(deoxidizerRecommendedAddition => String(deoxidizerRecommendedAddition.realAddedWeight ?? "") !== "")
      && recommendedAdditions?.fluxes?.every(fluxRecommendedAddition => String(fluxRecommendedAddition.realAddedWeight ?? "") !== "")
  }, [recommendedAdditions]);

  const foundSelectedLadleFurnace: LadleFurnaceModel | undefined = ladleFurnaceList?.find(ladleFurnaceItem => {
    return ladleFurnaceItem.name === heatCalculation?.ladleFurnace
    && ladleFurnaceItem.tapLadleFurnaces.some(tapLadleFurnaceItem =>
        tapLadleFurnaceItem.arcLength === Number(heatCalculation.arcLength)
        && tapLadleFurnaceItem.code === Number(heatCalculation.tap)
        && tapLadleFurnaceItem.minimumSlagDepth === Number(heatCalculation.minimumSlagDepth)
        && tapLadleFurnaceItem.minimumSlagWeight === Number(heatCalculation.minimumSlagWeight)
      )
  })

  //==================== CHECK IF PLANT VALIDATION CHANGES HAS ANY IMPACT ON SAVED DRAFTS

  const plantIsInvalidOnStep1: boolean =
    // check if saved ladle furnace is still on ladleFurnaceList on current plant configuration, or if the tap ladle furnace does not correspond to the current plant setup configuration.
    (
      isTappingMode
      ? false : (
        !foundSelectedLadleFurnace || (
            !foundSelectedLadleFurnace?.tapLadleFurnaces.find(tapLadleFurnaceItem => {
            return tapLadleFurnaceItem.code === Number(heatCalculation?.tap)
            && tapLadleFurnaceItem.arcLength === Number(heatCalculation?.arcLength)
            && tapLadleFurnaceItem.minimumSlagDepth === Number(heatCalculation?.minimumSlagDepth)
            && tapLadleFurnaceItem.minimumSlagWeight === Number(heatCalculation?.minimumSlagWeight)
          })
        )
      )
    )
    // check if saved steelgrade is still on steelgradeList on current plant configuration
    || !steelGradeList.some(steelGradeItem => steelGradeItem.name === heatCalculation?.steelGrade)
    // check if saved data has a selectedCarryoverSlag (carryoverslag reference parameter value)
    || (
      (
        !!savedDraftHeatData?.input.heatSettings.carryoverSlagTargetReferenceMinValue
        || (
          !!savedDraftHeatData?.input.heatSettings.carryoverSlagTargetReferenceInferenceType
          && savedDraftHeatData?.input.heatSettings.carryoverSlagTargetReferenceInferenceType !== userInfo?.selectedPlant.carryoverSlagInferenceType
        )
      )
      && !selectedCarryoverSlag
    )
    // check if the minimum carryoverslag has changed and the current minimum value is greater than the saved value
    || (
      processConstraintsList?.carryoverSlagType
        ? false
        : savedDraftHeatData?.input.processConstraints.weightLimits.minimumSlagCarryoverWeight !== processConstraintsList?.minimumSlagCarryoverWeight
          && Number(processConstraintsList?.minimumSlagCarryoverWeight) > Number(heatCalculation.carryoverSlagWeight)
    ) || carryoverSlagTypeChanged && heatCalculation.carryoverSlagWeight === ''

  const plantIsInvalidOnStep2: boolean =
    // saved alloys no longer exists on current plant setup configuration
    alloysChargeWeightList.some(alloyItem => !alloyList.some(alloy => alloy.name === alloyItem.name))
    // saved fluxes no longer exists on current plant setup configuration
    || fluxesChargeWeightList.some(fluxItem => !fluxList.some(flux => flux.name === fluxItem.name))
    // saved deoxidizer no longer exists on current plant setup configuration
    || deoxidizersChargeWeightList.some(deoxidizerItem => !deoxidizerList.some(deoxidizer => deoxidizer.name === deoxidizerItem.name))

  const plantIsInvalidOnStep3: boolean =
    // saved alloys no longer exists on current plant setup configuration
    !alloysChargeWeightListAtLF?.every(alloy => alloyList.some(alloyItem => alloyItem.name === alloy.name))
    // saved fluxes no longer exists on current plant setup configuration
    || !fluxesChargeWeightListAtLF?.every(flux => fluxList.some(fluxItem => fluxItem.name === flux.name))

  const currentCaoSource = fluxList.find(fluxItem => fluxItem.type === FluxType.CaOSource);
  const oldCaoSource = inputChargeChemistries?.fluxes.find(fluxItem => fluxItem.type === FluxType.CaOSource);
  const currentMgoOnlyOrDolomaSource = fluxList.find(fluxItem => [FluxType.DolomaSource, FluxType.MgOOnlySource].includes(fluxItem.type));
  const oldMgoOnlyOrDolomaSource = inputChargeChemistries?.fluxes.find(fluxItem => [FluxType.DolomaSource, FluxType.MgOOnlySource].includes(fluxItem.type));

  const loadedPlantConfigurationValidations = status === 'succeeded';

  const invalidPlantSetupDisablesCalculationOnStep4: boolean =
  // check if the form is on draft edit mode
  !!draftEditModeId &&
  // has a invalid selected flux addition
  (
    // Tapping mode does not count
    isTappingMode
    ? false
    : !!fluxSelection1
    && !fluxList.some(fluxItem => fluxItem.name === fluxSelection1)
  );

  const caOSourceInvalidatesPlantSetup: boolean =
    !ignoreInvalidPlantSetupThatEnablesCalculation
    && !!draftEditModeId
    && oldCaoSource?.name !== currentCaoSource?.name;

  const mgOOrDolomaSourceInvalidatesPlantSetup: boolean =
    !ignoreInvalidPlantSetupThatEnablesCalculation
    && !!draftEditModeId
    && oldMgoOnlyOrDolomaSource?.name !== currentMgoOnlyOrDolomaSource?.name;

  const invalidPlantSetupEnablesCalculationOnStep4: boolean =
      // check if CaO and Doloma (or MgO) source are the same since the draft was saved compared to the current plant configurations
    caOSourceInvalidatesPlantSetup
    || mgOOrDolomaSourceInvalidatesPlantSetup;

  const plantIsInvalidOnStep4: boolean =
    invalidPlantSetupEnablesCalculationOnStep4
    || invalidPlantSetupDisablesCalculationOnStep4;

  const plantIncompatibilityMessage = useMemo(() => {
    if(
      !loadedPlantConfigurationValidations
      || !plantIsInvalidOnStep4
    ) {
      return '';
    } else if (
      plantIsInvalidOnStep4
      && invalidPlantSetupDisablesCalculationOnStep4
    ) {
      return t('draft-edit.plant-config-error.message');
    } else if (
      caOSourceInvalidatesPlantSetup
      && mgOOrDolomaSourceInvalidatesPlantSetup
    ) {
      return t('draft-edit.plant-config-error.calculation-disabled-by-all-flux-source.step4.message', {
        mgoOrDolomaSource:
          oldMgoOnlyOrDolomaSource?.type === FluxType.MgOOnlySource
          ? t('new-heat.form.text-field.MgoSource')
          : t('fluxType.dolomaSource')
        }
      );
    } else if (
      caOSourceInvalidatesPlantSetup
    ) {
      return t('draft-edit.plant-config-error.calculation-disabled-by-one-flux-source.step4.message', { fluxSource: t('fluxType.caoSource') });
    } else {
      return t('draft-edit.plant-config-error.calculation-disabled-by-one-flux-source.step4.message', {
        fluxSource: oldMgoOnlyOrDolomaSource?.type === FluxType.MgOOnlySource ?  t('new-heat.form.text-field.MgoSource') : t('fluxType.dolomaSource') });
    }
  }, [
    caOSourceInvalidatesPlantSetup,
    invalidPlantSetupDisablesCalculationOnStep4,
    loadedPlantConfigurationValidations,
    mgOOrDolomaSourceInvalidatesPlantSetup,
    oldMgoOnlyOrDolomaSource?.type,
    plantIsInvalidOnStep4,
    t
  ])

  const currentPlantSetupConfigurationInvalidatesSteps = useMemo(() => {
    return isTappingMode
      ? [plantIsInvalidOnStep1, plantIsInvalidOnStep2, plantIsInvalidOnStep4]
      : [plantIsInvalidOnStep1, plantIsInvalidOnStep2, plantIsInvalidOnStep3, plantIsInvalidOnStep4];
  }
  ,[plantIsInvalidOnStep1, plantIsInvalidOnStep2, plantIsInvalidOnStep3, plantIsInvalidOnStep4, isTappingMode]);

  const plantConfigurationsInvalid = !!savedDraftHeatData && (plantIsInvalidOnStep1 || plantIsInvalidOnStep2 || plantIsInvalidOnStep3 || plantIsInvalidOnStep4);

    //======================== CHECK IF THE USER HAS NO UNSAVED CHANGES AND HAS CALCULATED (ON NEW HEAT SCREEN AND ON EDIT SCREEN)
    const calculationMadeIsStillValid =
      !hasUncalculatedChanges
      && ((calculatedSlags?.length ?? 0) > 0 || !!recommendations)
      && !plantConfigurationsInvalid;

    const compareWeights = useCallback(() => {
      const alloys = recommendedAdditions?.alloys?.some((a) => a.weight != a.realAddedWeight);
      const deoxidizers = recommendedAdditions?.deoxidizers?.some(
        (a) => Number(a.weight).toFixed(0) != Number(a.realAddedWeight).toFixed(0),
      );
      const fluxes = recommendedAdditions?.fluxes?.some((a) => a.weight != a.realAddedWeight);

      return alloys || deoxidizers || fluxes;
    }, [
      recommendedAdditions?.alloys,
      recommendedAdditions?.deoxidizers,
      recommendedAdditions?.fluxes
    ]);

  const shouldEnableNextStep4 = useMemo(
    () =>
      !currentPlantSetupConfigurationInvalidatesSteps.some(item => !!item) &&
      !!steelChemistryPrimaryFurnaceSi &&
      !!steelChemistryTappingSi &&
      !!steelChemistryPrimaryFurnaceDissolvedAl &&
      !!steelChemistryTappingDissolvedAl &&
      (!isManganeseEnabled || (
        !!steelChemistryPrimaryFurnaceMn
        && !!steelChemistryTappingMn
      )) &&
      (!isChromiumEnabled || (
        !!steelChemistryPrimaryFurnaceCr
        && !!steelChemistryTappingCr
      )) &&
      !!selectedCarryoverSlag &&
      !!deoxidizerSelection &&
      (
        isTappingMode || [fluxSelection1].filter(f => f !== 'Nenhum' && Boolean(f)).length >= 1
      )
      && !!(!useR2O3Target || !!r2O3Target),
    [
      steelChemistryPrimaryFurnaceSi,
      steelChemistryTappingSi,
      steelChemistryTappingMn,
      steelChemistryPrimaryFurnaceDissolvedAl,
      steelChemistryTappingDissolvedAl,
      steelChemistryPrimaryFurnaceMn,
      steelChemistryPrimaryFurnaceCr,
      steelChemistryTappingCr,
      isManganeseEnabled,
      isChromiumEnabled,
      selectedCarryoverSlag,
      deoxidizerSelection,
      fluxSelection1,
      isTappingMode,
      useR2O3Target,
      r2O3Target,
      currentPlantSetupConfigurationInvalidatesSteps
    ],
  );

  const shouldEnableCalculateButton = useMemo(
    () =>
    !invalidPlantSetupDisablesCalculationOnStep4 &&
    !!steelChemistryPrimaryFurnaceSi &&
    !!steelChemistryTappingSi &&
    !!steelChemistryPrimaryFurnaceDissolvedAl &&
    !!steelChemistryTappingDissolvedAl &&
    (!isManganeseEnabled || (
      !!steelChemistryPrimaryFurnaceMn
      && !!steelChemistryTappingMn
    )) &&
    (!isChromiumEnabled || (
      !!steelChemistryPrimaryFurnaceCr
      && !!steelChemistryTappingCr
    )) &&
    !!selectedCarryoverSlag &&
    !!deoxidizerSelection &&
    (
      isTappingMode || [fluxSelection1].filter(f => f !== 'Nenhum' && Boolean(f)).length >= 1
    )
    && !!(!useR2O3Target || !!r2O3Target),
  [
    invalidPlantSetupDisablesCalculationOnStep4,
    steelChemistryPrimaryFurnaceSi,
    steelChemistryTappingSi,
    steelChemistryTappingMn,
    steelChemistryPrimaryFurnaceDissolvedAl,
    steelChemistryTappingDissolvedAl,
    steelChemistryPrimaryFurnaceMn,
    steelChemistryPrimaryFurnaceCr,
    steelChemistryTappingCr,
    isManganeseEnabled,
    isChromiumEnabled,
    selectedCarryoverSlag,
    deoxidizerSelection,
    fluxSelection1,
    isTappingMode,
    useR2O3Target,
    r2O3Target
  ],
);

  const step1IsNotEmpty =
    Object.values(getStep('step1', heatCalculation)).filter(value => value !== 'none').some(value => !!value);

  const changedHeatModeAndStep1IsInvalid: boolean = (
    changedHeatMode
    && (
      step1IsNotEmpty
    )
    && (
      (heatMode === 'operation' && !shouldEnableNextStep1)
      || (heatMode === 'draft' && !shouldEnableStep1SaveAsDraft)
    )
  )

  const shouldDisableNext = useMemo(() => {
    switch (step) {
      case 0:
        return heatMode === 'operation' ? !shouldEnableNextStep1 : !shouldEnableStep1SaveAsDraft;
      case 1:
        return false;
      case hasLadleFurnaceAdditions:
        return false;
      case isCalculationSetupStep:
        return !shouldEnableNextStep4;
      case isRecommendedAdditonsStep:
        return !shouldEnableNextStep5;
      case isCalculatedSlagsStep:
        return false;
      default:
        return true;
    }
  }, [
    step,
    heatMode,
    shouldEnableNextStep1,
    shouldEnableStep1SaveAsDraft,
    shouldEnableNextStep4,
    shouldEnableNextStep5,
    hasLadleFurnaceAdditions,
    isCalculationSetupStep,
    isRecommendedAdditonsStep,
    isCalculatedSlagsStep
  ]);

  const heatData = useMemo(() => {

    function parseFlux(flux: FluxModel): ParsedFluxModel {
      return {
        name: flux.name,
        externalId: flux.externalId,

        type: flux.type,

        gradeOffset: flux?.gradeOffsets?.find((g) => g.name === heatCalculation.steelGrade)?.value || 0,

        mgO: flux.mgO,
        caO: flux.caO,
        al2O3: flux.al2O3,
        siO2: flux.siO2,
        feO: flux.feO,
        mnO: flux.mnO,
        cr2O3: flux.cr2O3,
        tiO2: flux.tiO2,
        caF2: flux.caF2,
        al: flux.al || 0,
        na2O: flux.na2O,
        k2O: flux.k2O
      };
    }

    const fluxChargeChemistry: ParsedFluxModel[] = [];
    const alloyChargeChemistry: AlloyListStateDTO[] = [];
    const deoxChargeChemistry: DeoxidizersModel[] = [];

    const fluxSelection = [fluxSelection1]
      .filter(f => f !== 'Nenhum' && Boolean(f))
      .map(f => ({ name: f }));

    for (const alloy of alloyList) {
      const chargeChemistry = [...(alloysChargeWeightListAtLF ?? []), ...alloysChargeWeightList].find(
        cc => cc.name === alloy.name,
      );
      if (!chargeChemistry) continue;

      if (alloyChargeChemistry.some(a => a.name === chargeChemistry.name)) continue;

      delete alloy.id;
      delete alloy.customerId;
      delete alloy.minorQuantity;
      alloyChargeChemistry.push(alloy);
    }

    const allDeoxUsed = [
      ...deoxidizersChargeWeightListAtLF,
      ...deoxidizersChargeWeightList,
      { name: deoxidizerSelection, weight: 0, externalId: null },
    ];

    for (const deox of allDeoxUsed) {
      const deoxChemistry = deoxidizerList.find(dc => dc.name === deox.name);
      if (!deoxChemistry) continue;

      if (deoxChargeChemistry.some(d => d.name === deoxChemistry.name)) continue;

      delete deoxChemistry.id;
      delete deoxChemistry.customerId;
      deoxChargeChemistry.push(deoxChemistry);
    }

    for (const flux of fluxList) {
      let parsedFlux: ParsedFluxModel | null = null;
      if (fluxSelection.some(f => f?.name === flux.name)) {
        parsedFlux = parseFlux(flux);
      } else {
        if ((isFixedAdditionAmountAtTapping(flux?.availability ?? '') || isFixedAdditionAmountAtLF(flux?.availability ?? '')) && flux.fixedAdditionAmount !== 0) {
          parsedFlux = parseFlux(flux);
        } else {
          const fluxWeight = [
            ...fluxesChargeWeightList,
            ...fluxesChargeWeightFixedList,
            ...fluxesChargeWeightListAtLF,
            ...fluxesChargeWeightFixedListAtLF,
          ].find(f => f.name === flux.name);

          if (fluxWeight || isLimeSource(flux.type) || isMgOSource(flux.type) || isDolomaSource(flux.type)) {
            parsedFlux = parseFlux(flux);
          }
        }
      }

      if (!parsedFlux || fluxChargeChemistry.some(f => f.name === parsedFlux?.name)) continue;
      fluxChargeChemistry.push(parsedFlux);
    }

    const slag = carryoverSlagReferenceList.find(cs => cs.id === selectedCarryoverSlag?.id);
    if (slag) {
      fluxChargeChemistry.push({
        name: 'SlagReference',
        type: FluxType.CarryoverSlag,
        gradeOffset: 0.0,
        mgO: slag.mgO,
        caO: slag.caO,
        al2O3: slag.al2O3,
        siO2: slag.siO2,
        feO: slag.feO,
        mnO: slag.mnO,
        cr2O3: slag.cr2O3,
        tiO2: slag.tiO2,
        caF2: slag.caF2,
        na2O: slag.na2O,
        k2O: slag.k2O,
        al: 0,
      });
    }

    const modifiedRecommendations: HeatRecommendationStageDTO =  {
      fluxes: recommendedAdditions?.fluxes?.map((f) => ({ ...f, weight: Number(f.realAddedWeight) })),
      alloys: recommendedAdditions?.alloys?.map((a) => ({ ...a, weight: Number(a.realAddedWeight) })),
      deoxidizers: recommendedAdditions?.deoxidizers?.map((d) => ({ ...d, weight: Number(d.realAddedWeight) })),
    };

    const modifiedRecommendationsExists: boolean =
      !!modifiedRecommendations.alloys
      || !!modifiedRecommendations.deoxidizers
      || !!modifiedRecommendations.fluxes;

    const manganesePrimaryFurnaceValue = processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
      ? parseFloat(steelChemistryPrimaryFurnaceMn) / CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR
      : parseFloat(steelChemistryPrimaryFurnaceMn);

    const chromiumPrimaryFurnaceValue = processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
      ? parseFloat(steelChemistryPrimaryFurnaceCr) / CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR
      : parseFloat(steelChemistryPrimaryFurnaceCr);

    const manganeseTappingValue = processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
      ? parseFloat(steelChemistryTappingMn) / CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR
      : parseFloat(steelChemistryTappingMn);

    const chromiumTappingValue = processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
      ? parseFloat(steelChemistryTappingCr) / CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR
      : parseFloat(steelChemistryTappingCr);

    const carryoverSlagThicknessValue = measurementSystem === SystemOfMeasurementOptions.Metric
      ? convertOtherUnitOfMeasurementIntoMeters(Number(heatCalculation.carryoverSlagWeight), UOM_CENTIMETERS_SYMBOL)
      : Number(heatCalculation.carryoverSlagWeight)
    const data: SaveHeatRequestPayload = {
      details: {
        ...isTappingMode ? {
          heatName: heatCalculation.heatName.toUpperCase(),
          calculationMode: CalculationMode.Tapping,
          saturationMode: heatCalculation.saturationMode as SaturationMode
        } : {
          heatName: heatCalculation.heatName.toUpperCase(),
          calculationMode: CalculationMode.LadleFurnace,
          ladleFurnace: heatCalculation.ladleFurnace,
          saturationMode: heatCalculation.saturationMode as SaturationMode
        },
      },
      desulfurization: (APP_DESULFURIZATION_INPUT_ENABLED && isDesulfurizationEnabled) ? {
        finalOxygenInSteel: Number(heatCalculation.finalOxygenInSteel),
        initialSulfurPercentage: Number(heatCalculation.initialSulfurPercentage)
      } : null,
      heatSettings: {
        carryoverSlagTargetReferenceInferenceType: userInfo?.selectedPlant.carryoverSlagInferenceType as CarryoverSlagInferenceType,
        carryoverSlagTargetReferenceName: userInfo?.selectedPlant?.carryoverSlagParameterValueName,
        carryoverSlagTargetReferenceUnitOfMeasurement: userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement,
        carryoverSlagTargetReferenceMinValue: !isCarryoverSlagInferenceRanged
          ? selectedCarryoverSlag?.value
          : selectedCarryoverSlag?.minValue,
        isNewCampaign: heatCalculation?.isNewCampaign,
        ladleLifeTime: heatCalculation.ladleLifeTime,
        ladleName: heatCalculation.ladleName,
        carryoverSlagTargetReferenceMaxValue: !isCarryoverSlagInferenceRanged ? null : selectedCarryoverSlag?.maxValue,
        ...isTappingMode ? {} : {
          transformerTap: heatCalculation.tap,
          arcLength: parseFloat(heatCalculation.arcLength || '0'),
          minimumSlagWeight: parseFloat(heatCalculation.minimumSlagWeight || '0'),
          minimumSlagDepth: parseFloat(heatCalculation.minimumSlagDepth || '0'),
        },
        useR2O3Target: useR2O3Target,
        r2O3Target: r2O3Target || 0,
        finalTemperature: heatCalculation.finalTemperature,
        temperatureUnit: 'C',
        weightUnit: 'Kg',
        steelGrade: heatCalculation.steelGrade,
        steelGradeCategory: selectedCategory,
        lengthUnit: 'm',
        steelChemistries: [
          {
            stageName: SteelChemistryStageName.PrimaryFurnace,
            stageType: StageType.Input,
            si:
              processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                ? parseFloat(steelChemistryPrimaryFurnaceSi) / CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR
                : parseFloat(steelChemistryPrimaryFurnaceSi),
            dissolvedAl:
              processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                ? parseFloat(steelChemistryPrimaryFurnaceDissolvedAl) / CHEMISTRY_AT_TAPPING_ALUMINUM_POINTS_CONVERSION_FACTOR
                : parseFloat(steelChemistryPrimaryFurnaceDissolvedAl),
            mn: isManganeseEnabled ? manganesePrimaryFurnaceValue : null,
            cr: isChromiumEnabled ? chromiumPrimaryFurnaceValue : null,
          },
          {
            stageName: SteelChemistryStageName.Tapping,
            stageType: StageType.Input,
            si:
              processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                ? parseFloat(steelChemistryTappingSi) / CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR
                : parseFloat(steelChemistryTappingSi),
            dissolvedAl:
              processConstraintsList?.steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                ? parseFloat(steelChemistryTappingDissolvedAl) / CHEMISTRY_AT_TAPPING_ALUMINUM_POINTS_CONVERSION_FACTOR
                : parseFloat(steelChemistryTappingDissolvedAl),
            mn: isManganeseEnabled ? manganeseTappingValue : null,
            cr: isChromiumEnabled ? chromiumTappingValue : null,
          }
        ],
        fluxSelection: fluxSelection,
        deoxidizerSelection: deoxidizerSelection?.replace(DeoxidizerType.None, '') as DeoxidizerType || '',
        alloyRecoveries: isTappingMode ? [] : [
          {
            stageName: StageName.LadleFurnace1,
            stageType: StageType.Input,
            siRecovery: Number(siRecovery || processConstraintsList?.siRecovery),
            alRecovery: Number(alRecovery || processConstraintsList?.alRecovery),
            mnRecovery: Number(mnRecovery || processConstraintsList?.mnRecovery),
            crRecovery: Number(crRecovery || processConstraintsList?.crRecovery)
          },
        ],
      },
      processConstraints: {
        ladle: {
          steelWeight: Number(processConstraintsList?.ladleSteelWeight),
          diameter: Number(processConstraintsList?.ladleDiameter),
          initialLiningThickness: Number(processConstraintsList?.initialLiningThickness),
          finalLiningThickness: Number(processConstraintsList?.finalLiningThickness),
          averageLadleLifetime: Number(processConstraintsList?.averageLadleLifetime)
        },
        weightLimits: {
          maximumSlagWeight: Number(processConstraintsList?.maximumSlagWeight),
          minimumSlagWeightAfterSkimmerUse: Number(processConstraintsList?.minimumSlagWeightAfterSkimmerUse),
          minimumSlagCarryoverWeight: Number(processConstraintsList?.minimumSlagCarryoverWeight),
          maximumSlagDeoxidizerWeight: Number(processConstraintsList?.maximumSlagDeoxidizerWeight),
        },
        mgOSource: {
          minimumWeight: Number(processConstraintsList?.mgOSourceMinimumWeight),
          maximumWeight: Number(processConstraintsList?.mgOSourceMaximumWeight),
        },
        carryoverSlagType: processConstraintsList?.carryoverSlagType as CarryoverSlagType,
        steelChemistryUnitOfMeasurement: processConstraintsList?.steelChemistryUnitOfMeasurement as SteelChemistryUnitOfMeasurement,
      },
      skimmer: {
        active: heatCalculation.skimmerActive,
        postSlagWeight: parseFloat(heatCalculation.skimmerPostSlagWeight || '0'),
      },
      chargeChemistries: {
        fluxes: fluxChargeChemistry,
        alloys: alloyChargeChemistry,
        deoxidizers: deoxChargeChemistry,
      },
      chargeWeights: {
        carryoverSlagWeight: processConstraintsList?.carryoverSlagType === CarryoverSlagType.Weight ? heatCalculation.carryoverSlagWeight : null,
        slagThickness:
          processConstraintsList?.carryoverSlagType === CarryoverSlagType.Thickness
            ? carryoverSlagThicknessValue
            : null,
        stages: [
          {
            stageName: StageName.Tapping,
            stageType: StageType.Input,
            alloys: alloysChargeWeightList,
            fluxes: [...fluxesChargeWeightList, ...fluxesChargeWeightFixedList],
            deoxidizers: deoxidizersChargeWeightList
            .filter(d => d.name !== 'Nenhum')
            .map(d => ({ ...d, weight: d.weight || 0 }))
          },
          ...modifiedRecommendationsExists && compareWeights() ? [
            {
              stageName: isTappingMode ? StageName.Tapping : recommendations?.stageName,
              stageType: StageType.ModifiedRecommendations,
              fluxes: modifiedRecommendations.fluxes ?? [],
              alloys: modifiedRecommendations.alloys ?? [],
              deoxidizers: modifiedRecommendations.deoxidizers ?? []
            } as Stage
          ] : [],
          ...!isTappingMode ? [
            {
              stageName: StageName.LadleFurnace1,
              stageType: StageType.Input,
              fluxes: [...fluxesChargeWeightListAtLF, ...fluxesChargeWeightFixedListAtLF],
              alloys: alloysChargeWeightListAtLF,
              deoxidizers: deoxidizersChargeWeightListAtLF
                .filter(d => d.name !== 'Nenhum')
                .map(d => ({ ...d, weight: d.weight || 0 })),
            }
          ] as Stage[] : [],
        ],
      },
    };
    return data
  }, [
    fluxSelection1,
    deoxidizersChargeWeightListAtLF,
    deoxidizersChargeWeightList,
    deoxidizerSelection,
    carryoverSlagReferenceList,
    recommendedAdditions?.fluxes,
    recommendedAdditions?.alloys,
    recommendedAdditions?.deoxidizers,
    processConstraintsList?.steelChemistryUnitOfMeasurement,
    processConstraintsList?.siRecovery,
    processConstraintsList?.alRecovery,
    processConstraintsList?.mnRecovery,
    processConstraintsList?.crRecovery,
    processConstraintsList?.ladleSteelWeight,
    processConstraintsList?.ladleDiameter,
    processConstraintsList?.initialLiningThickness,
    processConstraintsList?.finalLiningThickness,
    processConstraintsList?.averageLadleLifetime,
    processConstraintsList?.maximumSlagWeight,
    processConstraintsList?.minimumSlagWeightAfterSkimmerUse,
    processConstraintsList?.minimumSlagCarryoverWeight,
    processConstraintsList?.maximumSlagDeoxidizerWeight,
    processConstraintsList?.mgOSourceMinimumWeight,
    processConstraintsList?.mgOSourceMaximumWeight,
    processConstraintsList?.carryoverSlagType,
    steelChemistryPrimaryFurnaceMn,
    steelChemistryPrimaryFurnaceCr,
    steelChemistryTappingCr,
    steelChemistryTappingDissolvedAl,
    steelChemistryTappingMn,
    steelChemistryTappingSi,
    isTappingMode,
    heatCalculation.heatName,
    heatCalculation.saturationMode,
    heatCalculation.ladleFurnace,
    heatCalculation.finalOxygenInSteel,
    heatCalculation.initialSulfurPercentage,
    heatCalculation?.isNewCampaign,
    heatCalculation.ladleLifeTime,
    heatCalculation.ladleName,
    heatCalculation.tap,
    heatCalculation.arcLength,
    heatCalculation.minimumSlagWeight,
    heatCalculation.minimumSlagDepth,
    heatCalculation.finalTemperature,
    heatCalculation.steelGrade,
    heatCalculation.skimmerActive,
    heatCalculation.skimmerPostSlagWeight,
    heatCalculation.carryoverSlagWeight,
    isDesulfurizationEnabled,
    userInfo?.selectedPlant.carryoverSlagInferenceType,
    userInfo?.selectedPlant?.carryoverSlagParameterValueName,
    userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement,
    isCarryoverSlagInferenceRanged,
    selectedCarryoverSlag?.value,
    selectedCarryoverSlag?.minValue,
    selectedCarryoverSlag?.maxValue,
    selectedCarryoverSlag?.id,
    useR2O3Target,
    r2O3Target,
    selectedCategory,
    steelChemistryPrimaryFurnaceSi,
    steelChemistryPrimaryFurnaceDissolvedAl,
    isManganeseEnabled,
    isChromiumEnabled,
    siRecovery,
    alRecovery,
    mnRecovery,
    crRecovery,
    alloysChargeWeightList,
    fluxesChargeWeightList,
    fluxesChargeWeightFixedList,
    compareWeights,
    recommendations?.stageName,
    fluxesChargeWeightListAtLF,
    fluxesChargeWeightFixedListAtLF,
    alloysChargeWeightListAtLF,
    alloyList,
    deoxidizerList,
    fluxList,
    measurementSystem
  ])

  interface DraftHeatSettings extends Omit<typeof heatData.heatSettings, 'ladleName'> {
    ladleName?: string;
  }

  const loadDraftHeatData = useCallback(async (id: number) => {
    await loadHeatDataIntoFormStates(
      id,
      undefined,
      undefined,
      undefined,
      setCarryoverSlagType,
      setAlloyChargeWeightList,
      setFluxesChargeWeightList,
      initialState,
      setHeatCalculation,
      undefined,
      setDeoxidizersChargeWeightList,
      setDeoxidizersChargeWeightListAtLF,
      setDeoxidizerSelection,
      setAlloyChargeWeightListAtLF,
      setFluxesChargeWeightListAtLF,
      setSteelChemistryPrimaryFurnaceSi,
      setSteelChemistryPrimaryFurnaceDissolvedAl,
      setSteelChemistryPrimaryFurnaceMn,
      setSteelChemistryPrimaryFurnaceCr,
      setSteelChemistryTappingSi,
      setSteelChemistryTappingDissolvedAl,
      setSteelChemistryTappingMn,
      setSteelChemistryTappingCr,
      setSelectedCarryoverSlag,
      setRecommendedAdditions,
      undefined,
      setFluxSelection1,
      setSiRecovery,
      setAlRecovery,
      setMnRecovery,
      setCrRecovery,
      setUseR2O3Target,
      setR2O3Target,
      undefined,
      setRecommendations,
      setResult,
      setCalculatedSlags,
      setSlagAppearance,
      setSlagAppearanceText,
      true,
      false,
      steelGradeList,
      setIsDesulfurizationEnabled,
      setIsManganeseEnabled,
      setIsChromiumEnabled,
      userInfo?.selectedPlant.carryoverSlagInferenceType as CarryoverSlagInferenceType,
      carryoverSlagReferenceList,
      fluxesChargeWeightFixedList,
      fluxesChargeWeightFixedListAtLF,
      ladleFurnaceList,
      processConstraintsList,
    );
  }, [
    userInfo?.selectedPlant.carryoverSlagInferenceType,
    carryoverSlagReferenceList,
    fluxesChargeWeightFixedList,
    steelGradeList,
    fluxesChargeWeightFixedListAtLF,
    ladleFurnaceList,
    loadHeatDataIntoFormStates,
    processConstraintsList
  ])

  useEffect(() => {
    if(
        draftEditModeId
        && draftEditModeId !== draftState.lastSavedDraftId
        && initialLoadFinalized
        && fluxesStatus === 'succeeded'
        && loadedFluxesData
        && alloysStatus === 'succeeded'
        && slagDeoxidizersStatus === 'succeeded'
        && processConstraintsStatus === 'succeeded'
        && processConstraintsList
        && loadedCarryoverSlagsReferenceList
        && status === 'idle'
        && ladleFurnaceList.length > 0
      ) {
      setDraftId(draftEditModeId);
      loadDraftHeatData(Number(draftEditModeId));
    }
  },[
    draftEditModeId,
    setDraftId,
    draftState.lastSavedDraftId,
    getDraftHeatById,
    t,
    carryoverSlagReferenceList,
    fluxesChargeWeightFixedList,
    fluxesChargeWeightFixedListAtLF,
    loadHeatDataIntoFormStates,
    initialLoadFinalized,
    status,
    fluxesStatus,
    alloysStatus,
    slagDeoxidizersStatus,
    processConstraintsStatus,
    ladleFurnaceList,
    loadedCarryoverSlagsReferenceList,
    processConstraintsList,
    loadedFluxesData,
    loadDraftHeatData
  ])


  const calculateDraftData = useMemo(() => {
    const draftDetails = {
      ...isTappingMode ? {} : {
        ladleFurnace: heatCalculation.ladleFurnace,
      },
      calculationMode: isTappingMode ? CalculationMode.Tapping : CalculationMode.LadleFurnace,
      heatDescription: heatCalculation.heatDescription,
      saturationMode: heatCalculation.saturationMode,
      // TODO - REMOVE HEAT NAME FROM PAYLOAD
      heatName: ""
    };
    const draftHeatSettings: DraftHeatSettings = {
      ...heatData.heatSettings,
    };
    //  delete draftHeatSettings['ladleName'] TODO - revert it to send without the ladleName
    draftHeatSettings.ladleName = "0";
    return ({
      ...heatData,
      details: draftDetails,
      heatSettings: draftHeatSettings,
      desulfurization: (APP_DESULFURIZATION_INPUT_ENABLED && isDesulfurizationEnabled) ? {
        finalOxygenInSteel: Number(heatCalculation.finalOxygenInSteel),
        initialSulfurPercentage: Number(heatCalculation.initialSulfurPercentage)
      } : null
    })
  }, [
    heatData,
    heatCalculation.ladleFurnace,
    isTappingMode,
    heatCalculation.heatDescription,
    heatCalculation.saturationMode,
    heatCalculation.finalOxygenInSteel,
    heatCalculation.initialSulfurPercentage,
    isDesulfurizationEnabled
  ])

  const draftPayload = useMemo(()=> ({
    ...calculateDraftData,
    details: {
      ...calculateDraftData.details,
      shouldCalculate: calculationMadeIsStillValid,
      saturationMode: heatCalculation.saturationMode
    },
    desulfurization: (APP_DESULFURIZATION_INPUT_ENABLED && isDesulfurizationEnabled) ? {
      finalOxygenInSteel: Number(heatCalculation.finalOxygenInSteel),
      initialSulfurPercentage: Number(heatCalculation.initialSulfurPercentage)
    } : null,
    ...slagAppearance? {
      feedback: {
        slagAppearance,
        text: slagAppearanceText
      }
    } : {}
  }),[
    calculateDraftData,
    calculationMadeIsStillValid,
    slagAppearance,
    slagAppearanceText,
    heatCalculation.saturationMode,
    heatCalculation.finalOxygenInSteel,
    heatCalculation.initialSulfurPercentage,
    isDesulfurizationEnabled
  ]);


  useEffect(() => {
    setHasUnsavedDraftChanges(true);
  }, [
    //  Insert here all the inputs that user is able to change
    //  Step1
    isDesulfurizationEnabled,
    heatCalculation.heatName,
    heatCalculation.ladleName,
    heatCalculation.heatDescription,
    heatCalculation.ladleLifeTime,
    heatCalculation.isNewCampaign,
    foundSelectedLadleFurnace,
    heatCalculation.steelGrade,
    heatCalculation.steelGradeCategory,
    heatCalculation.finalTemperature,
    heatCalculation.saturationMode,
    heatCalculation.initialSulfurPercentage,
    heatCalculation.finalOxygenInSteel,
    selectedCarryoverSlag,
    heatCalculation.carryoverSlagWeight,
    heatCalculation.skimmerActive,
    heatCalculation.skimmerPostSlagWeight,
    heatCalculation.tap,
    heatCalculation.arcLength,
    heatCalculation.minimumSlagDepth,
    heatCalculation.minimumSlagWeight,
    //  Step2
    alloysChargeWeightList,
    fluxesChargeWeightList,
    deoxidizersChargeWeightList,
    //  Step3
    alloysChargeWeightListAtLF,
    fluxesChargeWeightListAtLF,
    deoxidizersChargeWeightListAtLF,
    alRecovery,
    siRecovery,
    mnRecovery,
    crRecovery,
    //  Step4
    steelChemistryPrimaryFurnaceSi,
    steelChemistryPrimaryFurnaceDissolvedAl,
    steelChemistryPrimaryFurnaceMn,
    steelChemistryPrimaryFurnaceCr,
    fluxSelection1,
    deoxidizerSelection,
    useR2O3Target,
    r2O3Target,
    isManganeseEnabled,
    isChromiumEnabled,
    //  Step5
    recommendedAdditions,
    //  Step7
    slagAppearance,
    slagAppearanceText
  ]);

  useEffect(() => {
    if(status === 'succeeded') {
    setHasUnsavedDraftChanges(false);
    }
  }, [status]);

  useEffect(() => {
    if(
      initialLoadFinalized
      && fluxesStatus === 'succeeded'
      && alloysStatus === 'succeeded'
      && slagDeoxidizersStatus === 'succeeded'
      && processConstraintsStatus === 'succeeded'
      && loadedCarryoverSlagsReferenceList
      && (
          // test if it loaded the draft data on draft edit mode
          status === 'succeeded' || (
            // or if it is idle becouse it's actually new heat screen mode
            status === 'idle' && !draftEditModeId
          )
        )
    ) {
      setHasUncalculatedChanges(lastState => {
        setDidUserAcceptedRecommendations(false);
        return lastState !== null;
      });
    }
  }, [
      // changes on step1
      heatCalculation,
      isDesulfurizationEnabled,
      //changes on step2
      fluxesChargeWeightList,
      fluxesChargeWeightFixedList,
      deoxidizersChargeWeightList,
      //changes on step3
      siRecovery,
      alRecovery,
      mnRecovery,
      // changes on step4
      alloysChargeWeightListAtLF,
      fluxesChargeWeightListAtLF,
      deoxidizersChargeWeightListAtLF,
      steelChemistryPrimaryFurnaceSi,
      steelChemistryPrimaryFurnaceDissolvedAl,
      steelChemistryPrimaryFurnaceCr,
      steelChemistryPrimaryFurnaceMn,
      isManganeseEnabled,
      isChromiumEnabled,
      selectedCarryoverSlag,
      fluxSelection1,
      deoxidizerSelection,
      useR2O3Target,
      initialLoadFinalized,
      loadedCarryoverSlagsReferenceList,
      fluxesStatus,
      alloysStatus,
      slagDeoxidizersStatus,
      processConstraintsStatus,
      status,
      draftEditModeId
    ]);

  const confirmRedirect = () => {
    if(!userConfirmedRedirect) updateConfirmRedirectState(true);
  };

  // Creating a debouncer to avoid unecessary function calls
  let timer: NodeJS.Timeout;
  const debounceWaitingTime = 500;

  // It means that if the user calls this function one time and another time 200ms after the first, the function will only be called at the second call
  // In a simmilar situation, where the second function call is 600ms after the first, the function will be called twice.
  const handleSaveAsDraft = async () => {
    // HandleSaveAsDraft will do nothing when the draft is being saved (status === loading)
    // to avoid unecessary function calls
    if(draftState.status === 'loading') return;
    try {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if(draftPayload.chargeWeights.stages.find(stage => stage.stageType === 'ModifiedRecommendations') && hasUncalculatedChanges) {
          draftPayload.chargeWeights.stages = draftPayload.chargeWeights.stages.filter(stage => stage.stageType !== 'ModifiedRecommendations');
        }

        if (draftState.lastSavedDraftId) {
          await updateDraft(draftPayload, draftState.lastSavedDraftId);
          const hadUncalculatedChanges = hasUncalculatedChanges;
          await loadDraftHeatData(Number(draftState.lastSavedDraftId));
          setHasUncalculatedChanges(hadUncalculatedChanges);
          confirmRedirect();
        } else {
          await saveAsDraft(draftPayload);
          confirmRedirect();
        }

        setHasUnsavedDraftChanges(false);

      }, debounceWaitingTime);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    if (processConstraintsStatus === 'succeeded' || processConstraintsStatus === 'error') {
      setProcessConstraintsList(processConstraintsData);
      setSteelChemistryConfigs({
        steelChemistryPrimaryFurnaceCode: processConstraintsData?.steelChemistryPrimaryFurnaceCode as string,
        steelChemistryTappingCode: processConstraintsData?.steelChemistryTappingCode as string,
        steelChemistryUnitOfMeasurement: processConstraintsData?.steelChemistryUnitOfMeasurement  ?? SteelChemistryUnitOfMeasurement.Percent,
      });
      setSiRecovery(processConstraintsData?.siRecovery.toString() || '0');
      setAlRecovery(processConstraintsData?.alRecovery.toString() || '0');
      setMnRecovery(processConstraintsData?.mnRecovery.toString() || '0');
      setCrRecovery(processConstraintsData?.crRecovery.toString() || '0');
      setCarryoverSlagType(processConstraintsData?.carryoverSlagType ?? CarryoverSlagType.Weight);
    }
  }, [processConstraintsStatus, processConstraintsData]);

  function handleStepClick(newStep: number) {
    const isOnAdditionsSteps = isTappingMode ? (step === 1) : (step === 1 || step === 2);
    if(isOnAdditionsSteps && (hasUnsavedFluxesAdditions || hasUnsavedAlloysAdditions || hasUnsavedDeoxidizersAdditions)) {
      setConfirmDialogIsVisible(true);
      setLastAttemptStepChangeValue(newStep);
      return;
    }

    const canSkipFromStep1 = heatMode === 'draft' ? shouldEnableStep1SaveAsDraft : shouldEnableNextStep1

    if(step === 0 && newStep > 0 && !canSkipFromStep1) {
      return;
    }

    if (
      isTappingMode
      ? (step <= 2 && newStep >= 3 && !calculationMadeIsStillValid)
      : (step <= 3 && newStep >= 4 && !calculationMadeIsStillValid)
    ) {
      return;
    }

    if (
      isTappingMode
      ? (newStep >= 5 && step < 5  && step5FormIsDirty)
      : (newStep >= 4 && step < 4  && step5FormIsDirty)
    ) {
      return;
    }

    if (newStep < step) {
      handleSendEvent({
        category: 'Heats/NewHeat',
        action: `User Was in Step ${step} and Now is in Step ${newStep}`,
      });
      setStep(newStep);
      return;
    }
    if ([1, 2, 3].includes(newStep) && (heatMode === 'operation' ? !shouldEnableNextStep1 : !shouldEnableStep1SaveAsDraft)) {
      return;
    }

    if (newStep === 5 && !calculationMadeIsStillValid) {
      return;
    }

    if (step === 6 && newStep === 5) {
      return;
    }

    if (
      (newStep > step)
      && (newStep === 5 || newStep === 6)
      && heatMode === 'operation'
      && !didUserAcceptedRecommendations
    ) {
      return;
    }

    setStep(newStep);
  }

  function handleBack() {
    handleSendEvent({
      category: 'Heats/NewHeat',
      action: 'User Clicked in Previous Step Button.',
    });
    if (step === 0) {
      history.goBack();
      return;
    }

    setStep(old => old - 1);
  }

  function handleNext() {
    handleSendEvent({
      category: 'Heats/NewHeat',
      action: 'User Clicked in Next Step Button.',
    });

    const isOnAdditionsSteps = isTappingMode ? (step === 1) : (step === 1 || step === 2);
    if(isOnAdditionsSteps && (hasUnsavedFluxesAdditions || hasUnsavedAlloysAdditions || hasUnsavedDeoxidizersAdditions)) {
      setConfirmDialogIsVisible(true);
      setLastAttemptStepChangeValue(step + 1);
      return;
    }
    setStep(old => old + 1);
  }

  const handleLadleFurnaceChange = useCallback((id: number) => {

    const ladleFurnaceToChange = ladleFurnaceList.find(lf => lf.id === id);

    if (ladleFurnaceToChange) {
      setTapList(ladleFurnaceToChange.tapLadleFurnaces);
      const defaultTapCode = ladleFurnaceToChange.tapLadleFurnaces.find(t => t.default === true);
      if (defaultTapCode) {
        handleChangeHeatCalculation(
          ['tap', 'arcLength', 'minimumSlagDepth', 'minimumSlagWeight', 'ladleFurnace', 'ladleFurnaceId'],
          [defaultTapCode.code, defaultTapCode.arcLength, defaultTapCode.minimumSlagDepth,
          defaultTapCode.minimumSlagWeight === undefined ? 0 : defaultTapCode.minimumSlagWeight, ladleFurnaceToChange.name, id]
        );
      }
    } else {
      handleChangeHeatCalculation(
        ['tap', 'arcLength', 'minimumSlagDepth', 'minimumSlagWeight', 'ladleFurnace', 'ladleFurnaceId'],
        ['', '', '', '', '', id]
      );
      setTapList([]);
    }
  }, [handleChangeHeatCalculation,ladleFurnaceList]);

  useEffect(() => {
    if(
        (!heatCalculation.ladleFurnace || !heatCalculation.ladleFurnace.replace("none",""))
        && !foundSelectedLadleFurnace
        && ladleFurnaceList.length === 1
        && !draftEditModeId
      ) {
      handleLadleFurnaceChange(ladleFurnaceList[0].id)
    }

  }, [
    foundSelectedLadleFurnace,
    ladleFurnaceList,
    heatCalculation.ladleFurnace,
    handleLadleFurnaceChange,
    draftEditModeId
  ])

  function renderSteps() {
    switch (step) {
      case 0:
        return (
          <Step1
            isDesulfurizationEnabled={isDesulfurizationEnabled}
            setIsDesulfurizationEnabled={setIsDesulfurizationEnabled}
            disableLadleFields={!canChangeLadle}
            selectedCarryoverSlag={selectedCarryoverSlag}
            setSelectedCarryoverSlag={setSelectedCarryoverSlag}
            carryoverSlagReferenceList={carryoverSlagReferenceList}
            isTappingMode={isTappingMode}
            selectedLadleFurnace={foundSelectedLadleFurnace}
            isInvalidCarryoverSlagWeight={isInvalidCarryoverSlagWeight}
            heatMode={heatMode}
            heatCalculation={getStep('step1', heatCalculation) as typeof stepsInitialState['step1']}
            handleChangeHeatCalculation={handleChangeHeatCalculation}
            verifyHeatName={verifyHeatName}
            ladlesList={ladlesList}
            steelGradeList={steelGradeList}
            tapList={tapList}
            ladleFurnaceList={ladleFurnaceList}
            handleLadleFurnaceChange={handleLadleFurnaceChange}
            carryoverSlagType={processConstraintsList?.carryoverSlagType}
            minimumCarryoverSlag={processConstraintsList?.minimumSlagCarryoverWeight || 0}
            isVerifyHeatLoading={checkIfHeatNameExistsStatus === 'loading'}
            maxCarryoverSlagWeightOrThickness={maxCarryoverSlagWeightOrThickness}
            plantIsIncompatible={
              plantIsInvalidOnStep1 && loadedPlantConfigurationValidations
            }
            stepIsInvalid={changedHeatModeAndStep1IsInvalid}
            changedHeatNameInput={changedHeatNameInput}
          />
        );

      case 1:
        return (
          <Step2
            alloyList={alloyList}
            fluxList={fluxList}
            deoxidizerList={deoxidizerList}
            alloysChargeWeightList={alloysChargeWeightList}
            setAlloyChargeWeightList={setAlloyChargeWeightList}
            fluxesChargeWeightList={fluxesChargeWeightList}
            setFluxesChargeWeightList={setFluxesChargeWeightList}
            fluxesChargeWeightFixedList={fluxesChargeWeightFixedList}
            deoxidizersChargeWeightList={deoxidizersChargeWeightList}
            setDeoxidizersChargeWeightList={setDeoxidizersChargeWeightList}
            plantIsIncompatible={plantIsInvalidOnStep2 && loadedPlantConfigurationValidations}
            setHasUnsavedFluxesAdditions={setHasUnsavedFluxesAdditions}
            setHasUnsavedAlloysAdditions={setHasUnsavedAlloysAdditions}
            setHasUnsavedDeoxidizersAdditions={setHasUnsavedDeoxidizersAdditions}
            userClickedToAddUnaddedAdditions={userClickedToAddUnaddedAdditions}
          />
        );

      case hasLadleFurnaceAdditions:
        return (
          <Step3
            alloyList={alloyList}
            fluxList={fluxList}
            deoxidizerList={deoxidizerList}
            siRecovery={siRecovery}
            setSiRecovery={setSiRecovery}
            alRecovery={alRecovery}
            setAlRecovery={setAlRecovery}
            mnRecovery={mnRecovery}
            setMnRecovery={setMnRecovery}
            crRecovery={crRecovery}
            setCrRecovery={setCrRecovery}
            setFluxesChargeWeightListAtLF={setFluxesChargeWeightListAtLF}
            alloysChargeWeightListAtLF={alloysChargeWeightListAtLF}
            setAlloyChargeWeightListAtLF={setAlloyChargeWeightListAtLF}
            fluxesChargeWeightListAtLF={fluxesChargeWeightListAtLF}
            fluxesChargeWeightFixedListAtLF={fluxesChargeWeightFixedListAtLF}
            deoxidizersChargeWeightListAtLF={deoxidizersChargeWeightListAtLF}
            setDeoxidizersChargeWeightListAtLF={setDeoxidizersChargeWeightListAtLF}
            plantIsIncompatible={plantIsInvalidOnStep3 && loadedPlantConfigurationValidations}
            setHasUnsavedFluxesAdditions={setHasUnsavedFluxesAdditions}
            setHasUnsavedAlloysAdditions={setHasUnsavedAlloysAdditions}
            setHasUnsavedDeoxidizersAdditions={setHasUnsavedDeoxidizersAdditions}
            userClickedToAddUnaddedAdditions={userClickedToAddUnaddedAdditions}
          />
        );
      case isCalculationSetupStep:
        return (
          <Step4
            steelChemistryConfigs={steelChemistryConfigs}
            steelChemistryPrimaryFurnaceSi={steelChemistryPrimaryFurnaceSi}
            setSteelChemistryPrimaryFurnaceSi={setSteelChemistryPrimaryFurnaceSi}
            steelChemistryPrimaryFurnaceDissolvedAl={steelChemistryPrimaryFurnaceDissolvedAl}
            setSteelChemistryPrimaryFurnaceDissolvedAl={setSteelChemistryPrimaryFurnaceDissolvedAl}
            steelChemistryPrimaryFurnaceMn={steelChemistryPrimaryFurnaceMn}
            setSteelChemistryPrimaryFurnaceMn={setSteelChemistryPrimaryFurnaceMn}
            setSteelChemistryPrimaryFurnaceCr={setSteelChemistryPrimaryFurnaceCr}
            steelChemistryPrimaryFurnaceCr={steelChemistryPrimaryFurnaceCr}
            steelChemistryTappingSi={steelChemistryTappingSi}
            setSteelChemistryTappingSi={setSteelChemistryTappingSi}
            steelChemistryTappingDissolvedAl={steelChemistryTappingDissolvedAl}
            setSteelChemistryTappingDissolvedAl={setSteelChemistryTappingDissolvedAl}
            steelChemistryTappingMn={steelChemistryTappingMn}
            setSteelChemistryTappingMn={setSteelChemistryTappingMn}
            setSteelChemistryTappingCr={setSteelChemistryTappingCr}
            steelChemistryTappingCr={steelChemistryTappingCr}
            fluxSelection1={fluxSelection1}
            setFluxSelection1={setFluxSelection1}
            deoxidizerSelection={deoxidizerSelection}
            setDeoxidizerSelection={setDeoxidizerSelection}
            useR2O3Target={useR2O3Target}
            setUseR2O3Target={setUseR2O3Target}
            r2O3Target={r2O3Target}
            setR2O3Target={setR2O3Target}
            fluxList={fluxList}
            deoxidizerList={deoxidizerList}
            plantIsIncompatibleAndCalculateIsDisabled={
              invalidPlantSetupDisablesCalculationOnStep4
              && loadedPlantConfigurationValidations
            }
            plantIsIncompatibleAndCalculateIsEnabled={
              invalidPlantSetupEnablesCalculationOnStep4
              && loadedPlantConfigurationValidations
            }
            plantIncompatibilityMessage={plantIncompatibilityMessage}
            inputChargeChemistriesFluxes={inputChargeChemistries?.fluxes}
            isTappingMode={isTappingMode}
            isManganeseEnabled={isManganeseEnabled}
            setIsManganeseEnabled={setIsManganeseEnabled}
            isChromiumEnabled={isChromiumEnabled}
            setIsChromiumEnabled={setIsChromiumEnabled}
          />
        );

      case isRecommendedAdditonsStep:
        return (
          <Step5
            heatMode={heatMode}
            result={result}
            outputDetails={outputDetails}
            recommendedAdditions={recommendedAdditions}
            setRecommendedAdditions={(rec: HeatRecommendationsDTO) => setRecommendedAdditions(rec)}
            setStep5FormIsDirty={() => {
              if (!step5FormIsDirty) {
                setStep5FormIsDirty(true);
                setDidUserAcceptedRecommendations(false);
              }
            }}
          />
        );

      case isCalculatedSlagsStep:
        if (calculatedSlags){
          calculateB2andB3(calculatedSlags)
        }
        return (
          <Step6
            initialSlag={calculatedSlags?.find(({stageName}) => stageName === 'Tapping')?.calculated}
            originalSlag={calculatedSlags?.find(({stageName, stageType}) => stageName === recommendations?.stageName && stageType === recommendations.stageType)?.calculated}
            modifiedRecommendations={calculatedSlags?.find(({stageType}) => stageType === 'ModifiedRecommendations')?.calculated || null}
            analyzedSlag={null}
          />
        );

      case isTappingMode? 5 : 6:
        return (
          <Step7
            slagAppearance={slagAppearance}
            setSlagAppearance={setSlagAppearance}
            slagAppearanceText={slagAppearanceText}
            setSlagAppearanceText={setSlagAppearanceText}
            isDraftMode={heatMode === 'draft'}
          />
        );

      default:
        break;
    }
  }

  function calculateHeat(shouldRecalculate = false) {

    const heatCalculationData = { ...heatMode === 'draft' ?  calculateDraftData : heatData}

    heatCalculationData.chargeWeights.stages = heatCalculationData.chargeWeights.stages.filter((stage) => stage?.alloys.length > 0 || stage?.fluxes.length > 0 || stage?.deoxidizers.length > 0);

    if(!shouldRecalculate && !!heatCalculationData.chargeWeights.stages.find(({stageType}) => stageType === 'ModifiedRecommendations')) {
      heatCalculationData.chargeWeights.stages = heatCalculationData.chargeWeights.stages.filter(({stageType}) => stageType !== 'ModifiedRecommendations');
    }
    Swal.fire(
      {
        position: 'top-end',
        icon: 'info',
        title: t('new-heat.form.info.calculation.inprogress'),
        showConfirmButton: false,
        showLoaderOnConfirm: false,
        html: '',
        didOpen: async () => {
          try {
            const response = await calculate(heatCalculationData);
            setIgnoreInvalidPlantSetupThatEnablesCalculation(true);
            window.onbeforeunload = () => t('new-heat.form.info.unsaved-data');

            setRecommendations(response.output.recommendations);
            setResult(response.result);
            setOutputDetails(response.output.details);

            if (!shouldRecalculate) {
              const recommendation = response.output.recommendations;
              const rec = {
                stageName: recommendation?.stageName,
                stageType: recommendation?.stageType,
                alloys: recommendation?.alloys?.map((a) => ({ ...a, realAddedWeight: a.weight })),
                deoxidizers: recommendation?.deoxidizers?.map((a) => ({ ...a, realAddedWeight: a.weight })),
                fluxes: recommendation?.fluxes?.map((a) => ({ ...a, realAddedWeight: a.weight })),
              };
              setRecommendedAdditions(rec);
            }

            setCalculatedSlags(response.output.calculatedSlags);

            if (step === (isRecommendedAdditonsStep) && compareWeights()) {
              setStep(isCalculatedSlagsStep);
              setIsRecalculation(true);
            } else {
              setStep(isRecommendedAdditonsStep);
            }

            Swal.close();

            setHasUncalculatedChanges(false);
          } catch (err) {
            const { message, details } = err as JsonReturnModel;

            if(details) {
              setSwalProps({
                icon: 'error',
                title: t('error-message.title'),
                text: message,
                details,
                show: true,
                onConfirm: () => setSwalProps(swalPropsInitialState),
              })
            }
          }
        },
      });
  }

  function clearAllFields() {
    // General
    setHeatMode(getDefaultHeatMode(userInfo?.userProfileName));
    setHasUnsavedDraftChanges(false);
    setHasUncalculatedChanges(null);
    setPreventChangeScreenModal(false);
    setChangedHeatMode(false);

    // step 1
    setHeatCalculation({
      ...initialState,
      finalOxygenInSteel: null,
      initialSulfurPercentage: null
    });
    setIsDesulfurizationEnabled(false);


    // step 2
    setAlloyChargeWeightList([]);
    setFluxesChargeWeightList([]);
    setDeoxidizersChargeWeightList([]);

    // step 3
    setAlloyChargeWeightListAtLF([]);
    setFluxesChargeWeightListAtLF([]);
    setDeoxidizersChargeWeightListAtLF([]);

    // step 4
    setSteelChemistryConfigs(undefined);
    setSteelChemistryPrimaryFurnaceSi('');
    setSteelChemistryPrimaryFurnaceDissolvedAl('');
    setSteelChemistryPrimaryFurnaceMn('');
    setSteelChemistryPrimaryFurnaceCr('');
    setSteelChemistryTappingSi('');
    setSteelChemistryTappingDissolvedAl('');
    setSteelChemistryTappingMn('');
    setSteelChemistryTappingCr('');
    setIsManganeseEnabled(false);
    setIsChromiumEnabled(false);
    setSelectedCarryoverSlag(undefined);
    setFluxSelection1('');
    setDeoxidizerSelection('');
    setUseR2O3Target(false);
    setR2O3Target('');

    // step 5
    setRecommendations(undefined);
    setResult(undefined);

    // step 6
    setCalculatedSlags(undefined);

    // step 7
    setSlagAppearance('');
    setSlagAppearanceText('');
  }

  function saveHeat() {
    if(saveHeatStatus === 'loading') return;
    clearTimeout(timer);
    timer = setTimeout(() => {
      savingHeat(
        {
          ...heatData,
          feedback: {
            slagAppearance: slagAppearance,
            text: slagAppearanceText
          }
        },
        setPreventChangeScreenModal,
        setStep,
        clearAllFields,
        loadLadles
      );
    }, debounceWaitingTime)
  }



  React.useEffect(() => {
    async function initialLoad() {
      await Promise.all([
        loadLadles(),
        loadLadleFurnace(),
        loadSteelGrade(),
        loadAlloy(),
        loadDeoxidizers(),
        loadFlux(),
        loadCarryoverSlagReference(),
        loadProcessConstraints(),
      ]);
    }

    if(!initialLoadFinalized) {
      initialLoad();
      setInitialLoadFinalized(true);
    }
  }, [
    initialLoadFinalized,
    loadAlloy,
    loadCarryoverSlagReference,
    loadDeoxidizers,
    loadFlux,
    loadLadleFurnace,
    loadLadles,
    loadProcessConstraints,
    loadSteelGrade,
  ]);

  const stepperNavigationNextFunction = () => {
    if(step === (isCalculationSetupStep)) {
      calculateHeat();
    } else if (step === (isRecommendedAdditonsStep)) {
      if(compareWeights() || isRecalculation) {
        calculateHeat(true);
        setStep5FormIsDirty(false);
        setDidUserAcceptedRecommendations(true);
      }
      else {
        handleNext();
        setDidUserAcceptedRecommendations(true);
      }
    } else {
      handleNext();
    }
  }

  const stepperNavigationNextTitle = useMemo(() => {
    switch (step) {
      case isCalculationSetupStep:
        return t('new-heat.form.button.calculate');
      case isRecommendedAdditonsStep:
        return (compareWeights() || isRecalculation)
          ? t('new-heat.form.button.recalculate')
          : t('new-heat.form.button.accept-recommendations')
      default:
        return t("new-heat.stepper-next-button.text");
    }
  }, [
    compareWeights,
    isRecalculation,
    step,
    isCalculationSetupStep,
    isRecommendedAdditonsStep,
    t
  ])

  const stepperNavigationNextTooltip = useMemo(() => {
    switch (step) {
      case isCalculationSetupStep:
        return `${(
            changedHeatModeAndStep1IsInvalid
          ) ? t('new-heat.form.disabled-save-as-draft-button.tooltip.invalid-form')
          : shouldDisableNext ? t('new-heat.form.disabled-button.tooltip.invalid-form') : ''}`;
      case isRecommendedAdditonsStep:
        return ''
      default:
        return !shouldDisableNext ? '' : t('new-heat.form.disabled-button.tooltip.invalid-form');
    }
  }, [
    step,
    t,
    shouldDisableNext,
    changedHeatModeAndStep1IsInvalid,
    isRecommendedAdditonsStep,
    isCalculationSetupStep
  ])

  const HeatModeToggle = () => {

    const StyledToggleButton = withStyles((theme: Theme) => ({
      root: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12.51px',
        height: '26.0px',
        borderRadius: '17px',
        '& span': {
          display: 'flex',
          columnGap: '5px',
          alignItems: 'center'
        },
        transition: '0.25s',
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        '&.draft': {
          paddingLeft: 0,
        },
        '&.operation': {
          paddingRight: 0,
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          fontWeight: 'bold',
          '& svg': {
            width: '20px',
            height: '20px',
          },
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.custom.primaryHoverColor.main,
        },
        '&:not(.Mui-selected)': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          fontWeight: 'bold',
          '& svg': {
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: '50%',
            width: '18px',
            height: '18px',
          },
          '& svg path': {
            color: theme.palette.common.white,
          },
        },
        '&:not(.Mui-selected).Mui-disabled': {
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.custom.disabledColor.main,
          '& svg': {
            backgroundColor: theme.palette.secondary.main,
            border: `2px solid ${theme.palette.custom.disabledColor.main}`,
          },
          '& svg path': {
            color: theme.palette.secondary.main,
          },
        },
        '&:not(.Mui-selected).draft svg': {
          transform: 'translateX(2px)',
        },
        '&:not(.Mui-selected).operation svg': {
          transform: 'translateX(-2px)',
        },
        '&:not(.Mui-selected):hover': {
          backgroundColor: theme.palette.custom.primaryHoverBackgroundColor.main,
        },
        '&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
          borderLeft: 'none',
        },
      },
    }))(ToggleButton);

    const handleHeatModeChange = (
      _: React.MouseEvent<HTMLElement>,
      newMode: HeatMode | null
    ) => {
      if (newMode) {
        setHeatMode(newMode);
        setChangedHeatMode(previousValue => previousValue || step1IsNotEmpty);
      }
    }

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '4.2px',
          justifyContent: 'center'
        }}
      >
        <Typography
          style={{
            fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '18px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '1px',
          }}
        >
          {t('new-heat.form.title.heat-mode-toggle.label')}
        </Typography>
        <ToggleButtonGroup
          style={{ display: 'flex', justifyContent: 'center' }}
          size="small"
          value={heatMode}
          onChange={handleHeatModeChange}
          exclusive
          >
          <StyledToggleButton value="draft" disabled={!hasAccess([PERMISSIONS.DRAFT_CALCULATION_CREATE])} aria-label="draft" className="draft">
              <CheckIcon
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                />
            {t('draft')}
          </StyledToggleButton>
          <StyledToggleButton value="operation" disabled={!hasAccess([PERMISSIONS.HEAT_CALCULATION_OPERATION_CREATE])}  aria-label="operation" className="operation">
            {t('new-heat.form.title.heat-mode-toggle.operation')}
            <CheckIcon
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
              />
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
    )
  }

  const theme = useTheme();

  const StyledStepper = withStyles((theme: Theme) => ({
    root: {
      padding: "8px 0 24px 0",
      border: "2px solid transparent",
      "& .MuiStepLabel-label.Mui-error": { color: theme.palette.text.primary },
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": { marginTop: '8px' },
      "& .Mui-error .MuiSvgIcon-root:not(.MuiStepIcon-active):not(.MuiStepIcon-completed)": { transform: 'translateY(-1px)' },
    },
  }))(Stepper);

  const StyledBox = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.warning.main,
      width: '24px',
      height: '24px',
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '50%',
      alignItems: 'center'
    },
  }))(Box);

  const WarningStepIconComponent = () => (
    <StyledBox>
      <StepIcon icon={<ReportProblemOutlinedIcon style={{ fontSize: "17px", display: 'flex', alignItems: 'center', objectFit: "cover" }} />} />
    </StyledBox>
  )

  const formRef = createRef<HTMLFormElement>();

  const [lastStep,setLastStep] = useState(0);

  const shouldDisableSaveHeatButton = useMemo(() => {
    return !slagAppearance || !shouldEnableNextStep1 || !shouldEnableNextStep4 || !shouldEnableNextStep5
  }, [slagAppearance, shouldEnableNextStep1, shouldEnableNextStep4, shouldEnableNextStep5])

  useEffect(() => {
    const scrollToTheTopOfTheForm = () => {
      formRef.current?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' })
    }

    if(step !== lastStep) {
      scrollToTheTopOfTheForm();
      setLastStep(step);
    }
  }, [step, formRef, lastStep]);

  return (
    <>
      {details && (
        <SwalModalComponent {...swalComponentProps}>
            <CustomAccordion
              accordionSummaryTitle={t('api.message.error.server.error-details')}
              tooltipMessage={t('api.message.error.server.error-details.tooltip.text')}
              style={{ margin: '16px auto 0', maxWidth: 'initial', width: 'initial' }}
            >
              <List>
                {details.map((item, i) => (
                  <ListItem key={`${item}-${i}`}>
                    <Typography>{item}</Typography>
                  </ListItem>
                ))}
              </List>
            </CustomAccordion>
        </SwalModalComponent>
      )}
      <SaveOnExitDialog
        when={heatMode === 'draft' && shouldEnableStep1SaveAsDraft && hasUnsavedDraftChanges}
        shouldBlockNavigation={true}
        onSave={handleSaveAsDraft}
        onCancel={() => confirmRedirect()}
        cancelText={t("confirmation-before-leaving")}
        confirmText={t("save-draft-on-exit.button.save")}
        content={t("save-draft-on-exit.content")}
        title={t("save-draft-on-exit.title")}
      />
      <SaveOnExitDialog
        when={heatMode === 'operation' && shouldEnableNextStep1 && !preventChangeScreenModal}
        shouldBlockNavigation
        shouldKeepOnScreenWhenCancel
        onSave={() => {
          confirmRedirect();
          return Promise.resolve(true);
        }}
        cancelText={t("save-operation-on-exit.button.discard")}
        confirmText={t("option-yes")}
        content={t("save-operation-on-exit.content")}
        title={t("save-operation-on-exit.title")}
      />

      <ConfirmDialog
        visible={confirmDialogIsVisible}
        setVisible={setConfirmDialogIsVisible}
        content={t("add-unadded-additions.confirm-dialog.content")}
        title={t("add-unadded-additions.confirm-dialog.title")}
        cancelText={t("add-unadded-additions.confirm-dialog.discard")}
        cancelAction={() => {
          setConfirmDialogIsVisible(false);
          setStep(lastAttemptStepChangeValue);
          setLastAttemptStepChangeValue(-1);
        }}
        confirmText={t("add-unadded-additions.confirm-dialog.save")}
        confirmAction={() => {
          setUserClickedToAddUnaddedAdditions(true);
        }}
      />

      <Loading
        dataTestId='new-heat-screen-circular-progress'
        promiseInProgress={
          fluxesStatus === 'loading'
          || alloysStatus === 'loading'
          || slagDeoxidizersStatus === 'loading'
          || processConstraintsStatus === 'loading'
          || draftState.status === 'loading'
          || status === 'loading'
          || (!!draftEditModeId && status === 'idle')
          || saveHeatStatus === 'loading'
          || !initialLoadFinalized
          || loading
        }
      />

      <Helmet>
        <title>
          {t('new-heat.form.title.new-heat')} | {APP_TITLE}
        </title>
      </Helmet>

      <MainPaper
        removePadding
        isNewHeatScreen
        title={isTappingMode ? t('new-heat.ladle-tapping') : t('new-heat.ladle-furnace-mode')}
        headerActions={
          [
            userInfo?.userProfileName !== UserRoles.OPERATOR && <HeatModeToggle key="heatModeToggle"/>,
            heatMode === 'draft' ? (
              <Tooltip
                title={`${t('new-heat.form.disabled-save-as-draft-button.tooltip.invalid-form')}`}
                placement="bottom"
                disableFocusListener={shouldEnableStep1SaveAsDraft}
                disableHoverListener={shouldEnableStep1SaveAsDraft}
                disableTouchListener={shouldEnableStep1SaveAsDraft}
              >
                <div>
                  <FormButton
                    type="submit"
                    onClick={() => handleSaveAsDraft()}
                    disabled={!shouldEnableStep1SaveAsDraft}
                    variant="primary"
                  >
                    {t('button.save')}
                  </FormButton>
                </div>
              </Tooltip>
            ) : (
              (
                <Tooltip
                  style={{ opacity: step === isFeedbackStep ? 1 : 0 }}
                  title={`${t('new-heat.form.disabled-button.tooltip.invalid-form')}`}
                  placement="bottom"
                  disableFocusListener={!!slagAppearance && !!shouldEnableNextStep1 && !!shouldEnableNextStep4 && !!shouldEnableNextStep5}
                  disableHoverListener={!!slagAppearance && !!shouldEnableNextStep1 && !!shouldEnableNextStep4 && !!shouldEnableNextStep5}
                  disableTouchListener={!!slagAppearance && !!shouldEnableNextStep1 && !!shouldEnableNextStep4 && !!shouldEnableNextStep5}
                >
                  <div>
                    <FormButton
                      type="submit"
                      onClick={() => saveHeat()}
                      disabled={shouldDisableSaveHeatButton}
                      variant="primary"
                    >
                      {t('button.save')}
                    </FormButton>
                  </div>
                </Tooltip>
              )
            )
          ]}
      >
        <form onSubmit={e => e.preventDefault()} ref={formRef}>
          <Box
            style={{
              overflowX: 'auto',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              columnGap: '8px'
            }}
            bgcolor={theme.palette.common.white}
          >
            <StepperNavigationButton
              Icon={ArrowBackRounded}
              title="new-heat.stepper-back-button.text"
              onClick={handleBack}
              disabled={step === 0}
              invisible={step === 0}
            />
            <StyledStepper activeStep={step} alternativeLabel >
              {stepsList.map((label, k) => {
                return (
                  (
                    loadedPlantConfigurationValidations &&
                    currentPlantSetupConfigurationInvalidatesSteps[k]
                  ) || (
                    changedHeatModeAndStep1IsInvalid
                    && k === 0
                  )
                )
                  ? (
                    <Step active onClick={() => handleStepClick(k)} key={label}>
                      <StepLabel error StepIconComponent={WarningStepIconComponent} >{label}</StepLabel>
                    </Step>
                  ) : (
                    <Step onClick={() => handleStepClick(k)} key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  )}
                )}
            </StyledStepper>
            <StepperNavigationButton
              Icon={ArrowForwardRounded}
              title={stepperNavigationNextTitle}
              disabled={(step === isCalculationSetupStep ? !shouldEnableCalculateButton : shouldDisableNext) || changedHeatModeAndStep1IsInvalid}
              invisible={step === isFeedbackStep}
              onClick={stepperNavigationNextFunction}
              tooltip={stepperNavigationNextTooltip}
            />
          </Box>
          {renderSteps()}
        </form>
      </MainPaper>
    </>
  );
};

export default NewHeat;
