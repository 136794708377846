import { AlloysRequest, AlloysResponse } from '.';
import { useApi } from '..';

export function useAlloysService() {
  const { get, remove, put, post } = useApi();
  const baseApi = `/v2/alloys`;

  const getAlloys = async () => {
    const { data } = await get<AlloysResponse[]>(`${baseApi}`);

    return data;
  };

  const getAlloy = async (id: number) => {
    const { data } = await get<AlloysResponse>(`${baseApi}/${id}`);

    return data;
  };

  const deleteAlloy = async (id: number) => {
    const { data } = await remove(`${baseApi}/${id}`);

    return data;
  };

  const updateAlloy = async (id: number, request: AlloysRequest) => {
    const { data } = await put(`${baseApi}/${id}`, request);

    return data;
  };

  const saveAlloy = async (request: AlloysRequest) => {
    const { data } = await post(`${baseApi}`, request);

    return data;
  };

  return {
    getAlloys,
    getAlloy,
    deleteAlloy,
    updateAlloy,
    saveAlloy,
  };
}
