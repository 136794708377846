import { useHeatsService } from '@app/api/heats';
import FormSelect from '@app/components/shared/FormSelect';
import Loading from '@app/components/shared/Loading';
import MainPaper from '@app/components/shared/MainPaper';
import { useArchivedHeatContext } from '@app/store/archivedHeat/archivedHeatContext';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { handleSendEvent, ROUTES_PATH } from '@app/utils';
import { APP_TITLE, PAGINATION, PERMISSIONS } from '@app/utils/constants';
import { Search } from '@material-ui/icons';

import { getInverseOrder } from '@app/utils/getInverseOrder';
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers'
import { format, isValid, formatISO, isBefore } from 'date-fns';
import fdownload from 'js-file-download';
import React, { ReactElement, useEffect, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useLoadArchivedHeats from '@app/hooks/useLoadHeats';
import TableNoResults from '@app/components/shared/TableNoResults';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CopyHeatModal from '../modals/CopyHeatModal';
import { HeatsResponse } from '@app/api/heats/HeatsResponse';
import { CalculationMode, useCalculationModeOptions } from '@app/utils/calculationModeOptions';
import FormButton from '@app/components/shared/FormButton';
import useScrollTop from '@app/hooks/useScrollTop';
import { DownloadButton, DuplicateButton, ViewButton } from '@app/components/shared/CustomIconButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 229px)',
    },
  }),
);

const KEY_LF_CODE = 'LadleFurnace';
const KEY_HEAT_NAME = 'HeatName';
const KEY_DATE = 'CreateDate';
const KEY_CALCULATION_MODE = 'CalculationMode';
const KEY_USER = 'User';

const ArchivedHeatsContent = (): ReactElement => {
  const [infoObject, loadArchivedHeats] = useLoadArchivedHeats();
  const history = useHistory();

  const { data, status } = infoObject;

  const total = data?.total;
  const items = data?.items;

  const classes = useStyles();
  const { t } = useTranslation();
  const [calculationsModeOptions, getCalculationModeLabel] = useCalculationModeOptions();

  const { hasAccess, state: { userInfoObject } } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const { state: { startDate, endDate }, setStartDate, setEndDate, resetDate } = useArchivedHeatContext();

  const [startDateIso, setStartDateIso] = useState<MaterialUiPickersDate | null>(null);
  const [endDateIso, setEndDateIso] = useState<MaterialUiPickersDate | null>(null);

  const { getHeatById } = useHeatsService();

  const [page, setPage] = useState<number>(1);
  const [heatName, setHeatName] = useState<string | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE);

  const [orderBy, setOrderBy] = useState<string>(KEY_DATE);
  const [order, setOrder] = useState<'desc' | 'asc'>('desc');
  const sorting = `${orderBy} ${order.toUpperCase()}`;

  const [calculationMode, setCalculationMode] = useState<CalculationMode>(CalculationMode.None);
  const [loading, setLoading] = useState(false);

  const [lastSearchStamp, setLastSearchStamp] = useState(`${null}-${null}-${null}-${0}`);

  useEffect(() => {
    if (status === "idle") {
      if(startDate !== null || endDate !== null) {
        resetDate();
      }
      loadArchivedHeats(null, null, page, rowsPerPage, heatName, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
    }
  }, [startDate, endDate, page, rowsPerPage, heatName, loadArchivedHeats, status, sorting, calculationMode, resetDate]);

  const isOrderedByLfCode = () => orderBy === KEY_LF_CODE;
  const isOrderedByHeatName = () => orderBy === KEY_HEAT_NAME;
  const isOrderedByDate = () => orderBy === KEY_DATE;
  const isOrderedByCalculationMode = () => orderBy === KEY_CALCULATION_MODE;
  const isOrderedByUser = () => orderBy === KEY_USER;

  const handleSortBy = (criteria: string) => {
    if(criteria === orderBy) {
      loadArchivedHeats(startDate, endDate, page, rowsPerPage, heatName, `${orderBy} ${getInverseOrder(order).toUpperCase()}`, calculationMode === CalculationMode.None ? null : calculationMode);
      setOrder(oldOrder => getInverseOrder(oldOrder));
    } else {
      setOrderBy(criteria);
      loadArchivedHeats(startDate, endDate, page, rowsPerPage, heatName, `${criteria} DESC`, calculationMode === CalculationMode.None ? null : calculationMode);
      setOrder('desc');
    }
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    handleSendEvent({
      category: 'Heats/ArchivedHeats',
      action: 'User Clicked to Search ArchivedHeats by Date.',
    });

    const newSearchStamp = `${startDate}-${endDate}-${heatName}-${calculationMode}`;

    const searchStampHasChanged = newSearchStamp !== lastSearchStamp;

    if(searchStampHasChanged) {
      setPage(1);
    }

    setLastSearchStamp(newSearchStamp);

    loadArchivedHeats(startDate, endDate, searchStampHasChanged ? 1 : page, rowsPerPage, heatName, sorting, calculationMode === CalculationMode.None ? null : calculationMode);

  };

  const handleDateChange = (date: MaterialUiPickersDate, isoDateCallbackFunction: (date: MaterialUiPickersDate) => void, stringDateCallbackFunction: (date: string | null) => void) => {
    if (isValid(date)) {
      stringDateCallbackFunction(formatISO(date as Date, { representation: 'date' }));
    } else {
      stringDateCallbackFunction(null)
    }
    isoDateCallbackFunction(date);
  };

  const [copyDraftModalState, setCopyModalDraftState] = useState<{
    selectedHeat: HeatsResponse | undefined;
    copyModalIsVisible: boolean;
  }>({
    selectedHeat: undefined,
    copyModalIsVisible: false,
  })

  const onDraftCopy = (draftHeatToCopy: HeatsResponse) => {
    setCopyModalDraftState({selectedHeat: draftHeatToCopy, copyModalIsVisible: true});
  }

  const renderLines = useCallback(() => {

    const fileDownloadPrepare = async (name: string, id: number) => {
      handleSendEvent({
        category: 'Heats/ArchivedHeats',
        action: 'User Clicked to Download an ArchivedHeat.',
      });
      try {
        setLoading(true);
        const response = await getHeatById(id);
        const plant = userInfo?.selectedPlant.name;
        const fileName = `${plant}-${name}-${id}-${response?.output?.details?.createDate || format(new Date(), 'dd_MM_yyyy_HH_mm_SS')}`;

        fdownload(JSON.stringify(response), `${fileName}.json`);
      } finally {
        setLoading(false);
      }
    };

    return items && items?.length > 0 ? items
      .map((row, i) => {
        const viewUrl =
          row?.calculationMode === CalculationMode.Tapping
            ? `${ROUTES_PATH.APPLICATIONS_HEATS_TAPPING_VIEW.replace(":id", String(row.id))}`
            : `${ROUTES_PATH.APPLICATIONS_HEATS_LADLE_FURNACE_VIEW.replace(":id", String(row.id))}`;

        return (
          <TableRow key={i}>
            <TableCell>
              {getCalculationModeLabel(row.calculationMode)}
            </TableCell>
            <TableCell align="left">{row?.calculationMode === CalculationMode.LadleFurnace ? row.ladleFurnace : '-'}</TableCell>
            <TableCell align="left">{row.heatName}</TableCell>
            <TableCell align="left">{format(new Date(row.createDate), t('date-time.format'))}</TableCell>
            <TableCell align="left">{row.createUser}</TableCell>
            <TableCell>
              <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', columnGap: '16px' }}>
                {hasAccess([PERMISSIONS.HEAT_CALCULATION_DETAIL]) && (
                  <ViewButton
                    tooltipMessagePlacement="top-end"
                    onClick={() => {
                      handleSendEvent({ category: 'Heats/ArchivedHeats', action: 'User Clicked to Open an ArchivedHeat.' });
                      history.push(viewUrl);
                    }}
                  />
                )}
                {hasAccess([PERMISSIONS.DRAFT_CALCULATION_CREATE]) && (
                  <DuplicateButton
                    tooltipMessage={t('heat.operation.button.tooltip.duplicate')}
                    tooltipMessagePlacement="top-end"
                    style={{ color: "#0E93DD", cursor: "pointer" }}
                    onClick={() => onDraftCopy(row)}
                  />
                )}
                <DownloadButton
                  onClick={async () => await fileDownloadPrepare(row.heatName, row.id)}
                  style={{ color: "#0E93DD", cursor: 'pointer' }}
                  tooltipMessage={t('button.export')}
                  tooltipMessagePlacement='top-end'
                />
              </Box>
            </TableCell>
          </TableRow>
        );
      }) : (
      <TableRow >
        <TableCell colSpan={12}>
          <TableNoResults firstText='heat-list.empty-table-first-text' secondText='heat-list.empty-table-second-text' />
        </TableCell>
      </TableRow>
    );
  }, [
      items,
      hasAccess,
      t,
      userInfo?.selectedPlant.name,
      getHeatById,
      getCalculationModeLabel,
      history
    ]);

  const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop<HTMLDivElement>();

  return (
    <>
      {copyDraftModalState.copyModalIsVisible && (
        <CopyHeatModal
          heatId={copyDraftModalState.selectedHeat?.id ?? -1}
          onClose={() =>
            setCopyModalDraftState({
              selectedHeat: undefined,
              copyModalIsVisible: false
            })}
          open={copyDraftModalState.copyModalIsVisible}
          heatDescriptionInitialState={
            `${copyDraftModalState.selectedHeat?.heatName} - ${t('copy-heat-modal.input.default-value.text')}`
          }
        />
      )}
      <form onSubmit={handleSearch}>
        <Helmet>
          <title>
            {t('archived-heats')} | {APP_TITLE}
          </title>
        </Helmet>

        <Loading dataTestId='archived-heats-circular-progress' promiseInProgress={status === "loading" || loading } />

        <MainPaper
          removePadding
          title={t('archived-heats')}
          headerActions={[
            <Box key="dates-wrapper" style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '8px'}}>
              <TextField
                id="heatName"
                label={t('heat.name')}
                autoComplete="off"
                style={{ maxWidth: "161px" }}
                InputProps={{ endAdornment: <Search className="initial-positioning" fontSize="small" color="secondary" /> }}
                value={heatName ?? ""}
                onChange={e => setHeatName(e.target.value || null)}
              />
              <KeyboardDatePicker
                id="date"
                label={t('heat-list.form.date-start')}
                style={{ maxWidth: "155px" }}
                value={startDateIso}
                onChange={(date) => handleDateChange(date, setStartDateIso, setStartDate)}
                autoOk={true}
                format={t('date.format')}
                placeholder={t('date-placeholder.format')}
                helperText={
                  (!!startDateIso && !isValid(startDateIso) && t('date.invalid-feedback.format')) ||
                  (!!endDateIso && !startDateIso && t('date.invalid-feedback.required')) ||
                  (!!endDateIso && !!startDateIso && isBefore(endDateIso, startDateIso)) && t('date.invalid-feedback.order')
                }
                error={
                  (!!startDateIso && !isValid(startDateIso)) ||
                  (!!endDateIso && !startDateIso) ||
                  !!endDateIso && !!startDateIso && isBefore(endDateIso, startDateIso)
                }
                cancelLabel={t('button.cancel')}
              />
              <KeyboardDatePicker
                id="date"
                label={t('heat-list.form.date-end')}
                style={{ maxWidth: "155px" }}
                value={endDateIso}
                onChange={(date) => handleDateChange(date, setEndDateIso, setEndDate)}
                autoOk={true}
                format={t('date.format')}
                placeholder={t('date-placeholder.format')}
                helperText={
                  (!!endDateIso && !isValid(endDateIso) && t('date.invalid-feedback.format')) ||
                  (!endDateIso && !!startDateIso && t('date.invalid-feedback.required'))
                  // (!!endDateIso && !!startDateIso && isBefore(endDateIso, startDateIso)) && t('date.invalid-feedback.order')
                }
                error={
                  !!endDateIso && !isValid(endDateIso) ||
                  (!endDateIso && !!startDateIso) ||
                  !!endDateIso && !!startDateIso && isBefore(endDateIso, startDateIso)
                }
                cancelLabel={t('button.cancel')}
              />
              <FormSelect
                 id="drop-down-calculation-mode"
                label={t('calculation.mode.select.option')}
                style={{ width: "200px" }}
                value={calculationMode}
                onChange={ e=> setCalculationMode((e.target.value) as CalculationMode)}
                options={calculationsModeOptions.map(row => ({
                  name: row.label,
                  value: row.value as string | number,
                }))}
                />
            </Box>
            ,

            <FormButton
              key="btn-submit"
              type="submit"
              variant="secondary"
              size="small"
              disabled={
                (!!startDateIso && !isValid(startDateIso)) || (!!endDateIso && !isValid(endDateIso)) ||
                (!!startDateIso && !endDateIso) ||
                (!startDateIso && !!endDateIso) ||
                (!!startDateIso && !!endDateIso) && isBefore(endDateIso, startDateIso)
              }
            >
              {t('filter.search')}
            </FormButton>,
          ]}>
          <Paper>
            <TableContainer className={classes.tableContainer} innerRef={tableContainerRef}>
              <Table stickyHeader className={classes.table} style={{ maxWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      width="30"
                      sortDirection={isOrderedByCalculationMode() ? order : undefined}
                    >
                      <TableSortLabel
                        active={isOrderedByCalculationMode()}
                        direction={isOrderedByCalculationMode() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_CALCULATION_MODE)}>
                        {t('calculation.mode')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" width="50" sortDirection={isOrderedByLfCode() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByLfCode()}
                        direction={isOrderedByLfCode() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_LF_CODE)}>
                        {t('heat-list.table.head.ladle-furnace')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" width="80" sortDirection={isOrderedByHeatName() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByHeatName()}
                        direction={isOrderedByHeatName() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_HEAT_NAME)}>
                        {t('heat.name')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" width="150" sortDirection={isOrderedByDate() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByDate()}
                        direction={isOrderedByDate() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_DATE)}>
                        {t('heat-list.table.head.date')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" width="120" sortDirection={isOrderedByUser() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByUser()}
                        direction={isOrderedByUser() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_USER)}>
                         {t('heat-list.table.head.create.by')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" width="120"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{["succeeded", "error"].includes(status) && renderLines()}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(_, newPage) => {
                setPage(newPage + 1);
                scrollTop();
                loadArchivedHeats(startDate, endDate, newPage + 1, rowsPerPage, heatName, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
              }}
              onRowsPerPageChange={e => {
                setPage(1);
                setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE));
                loadArchivedHeats(startDate, endDate, page, Number(e.target.value || PAGINATION.ROWS_PER_PAGE), heatName, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
              }}
              labelRowsPerPage={t('rows-per-page')}
              backIconButtonText={t('page-next')}
            />
          </Paper>
        </MainPaper>
      </ form>
    </>
  );
};

export default ArchivedHeatsContent;
