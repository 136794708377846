import { usePlantsService } from '@app/api/plants';
import { PlantsGetResponse } from '@app/api/plants';
import { useState } from 'react';
import { ListInfoObject } from "./types"

const infoObjectInitialState: ListInfoObject<PlantsGetResponse> = {
  status: 'idle',
  data: [],
}

const useLoadPlants = (): [
  ListInfoObject<PlantsGetResponse>,
  () => Promise<void>,
] => {

  const { getAllPlants } = usePlantsService();

  const [infoObject, setInfoObject] = useState<ListInfoObject<PlantsGetResponse>>(infoObjectInitialState);

  const loadPlants = async () => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getAllPlants();
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {
      
      setInfoObject({data: [], status: 'error'});
    }
  };


  return [infoObject, loadPlants];
}

export default useLoadPlants;