import { FormControl, FormControlLabel, InputLabel, RadioGroup } from '@material-ui/core';
import { ChangeEvent } from 'react';
import FormRadioButton from './FormRadioButton';

interface Option<T> {
  value: T;
  label: string;
  disabled?: boolean;
}

interface FormRadioGroupProps<T> {
  id: string;
  options: Array<Option<T>>;
  value: T;
  onChange: (value: T) => void;
  label: string;
  disabled: boolean;
}

const FormRadioGroup = <T extends number | string | boolean>({
  id,
  options,
  value,
  onChange,
  label,
  disabled
}: FormRadioGroupProps<T>) => {
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: T = ( typeof value === 'boolean' || typeof value === 'number' )
    ? JSON.parse(event.target.value) 
    : event.target.value
    onChange(newValue);
  };

  return (
    <FormControl>
      <InputLabel disabled={disabled} htmlFor={id}>{label}</InputLabel>
      <RadioGroup
        row
        aria-labelledby={id}
        name={id}
        value={value}
        onChange={handleRadioChange}
        style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              disabled={disabled || option.disabled}
              key={option.label}
              value={option.value}
              checked={option.value === value}
              style={{ display: 'flex', gap: '4px' }}
              control={
                <FormRadioButton
                  disabled={disabled || option.disabled}
                />
              }
              label={option.label}
              labelPlacement="end"
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadioGroup;