import { useApi } from '..';
import { CarryoverSlagInferenceType } from '../plants/PlantUpdateCarryoverSlagSettingsRequest';

export function useSteelGradesService() {
  const { get } = useApi();
  const baseApi = `/v2/steelGrades`;

  const getSteelGrades = async (inferenceType: CarryoverSlagInferenceType) => {
    const { data } = await get(`${baseApi}`, { params: { onlyActive: true, inferenceType } });

    return data;
  };

  return {
    getSteelGrades,
  };
}
