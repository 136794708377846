import { useMsalService } from '@app/config/useMsalService';
import { API_BASE_URL, APP_LOCAL_ENVIRONMENT, APP_ON_PREMISES, APP_VERSION, EMPTY_FUNCTION } from '@app/utils/constants';
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { camelizeKeys, pascalizeKeys } from 'humps';
import fdownload from 'js-file-download';
import { JsonReturnModel } from '.';
import Swal from 'sweetalert2';
import { handleSendEvent } from '@app/utils';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { STORAGE_LANGUAGE_KEY } from '@app/store/languageContext/languageContext';
import { useMeasurementSystemContext } from '@app/store/MeasurementSystemContext/measurementSystemContext';

export function useApi() {

  const { t } = useTranslation();
  const history = useHistory();

  const { state: { selectedMeasurementSystem } } = useMeasurementSystemContext();

  const { getAccessToken } = useMsalService();

  const params = useParams();

  const hasParams = Object.keys(params).length > 0;

  const userSelectedLanguage = localStorage.getItem(STORAGE_LANGUAGE_KEY) || navigator.language || 'en';

  const backToLogin = () => history.push('/');

  const selectAuthnAuthzMessage = (reason: string, message: string) => {
    const defaultMessage = t('login.no.roles');

    if (reason) {
      let specificReasonMessage = '';

      switch (reason) {
        case 'TokenExpired':
          specificReasonMessage = t('login.token.expired');
          break;
        case 'TokenNotPresent':
        case 'TokenSignatureInvalid':
        case 'TokenAudienceNotAllowed':
        case 'TokenIssuerNotAllowed':
        case 'TokenSignatureKeyNotFound':
        case 'TokenClaimNotFound':
        case 'TokenClaimValueNotAllowed':
        case 'JwtInvalid':
          specificReasonMessage = t('login.token.invalid');
          break;
        default:
          specificReasonMessage = defaultMessage;
          break;
      }

      return specificReasonMessage;
    } else if (message) {
      return message;
    } else {
      return defaultMessage;
    }
  }

  const checkAuthnAuthzError = (statusCode: number) => statusCode === 401 || statusCode === 403;

  const checkServerSideError = (statusCode: number) => statusCode >= 500 && statusCode <= 599;

  const checkServiceUnavailableError = (statusCode: number, statusMessage: string) => {
    const siteDisabledErrorCode = 403;
    const siteDisabledErrorMessage = 'Site Disabled';
    const serviceUnavailableErrors = [502, 503, 504];

    return serviceUnavailableErrors.includes(statusCode) ||
      siteDisabledErrorCode === statusCode && siteDisabledErrorMessage === statusMessage;
  }

  const convertSelectedLanguage = (selectedLanguage: 'en' | 'pt-BR' | 'es') => {
    switch (selectedLanguage) {
      case 'es':
        return 'es';
      case 'pt-BR':
        return 'pt-BR';
      default:
        return 'en-US';
    }
  }

  const getCustomHeaders = (headers: AxiosRequestHeaders): AxiosRequestHeaders => {
    const entries = Object.keys(headers)
      .filter(key => key.toLowerCase().startsWith('x-'))
      .map(key => [key, headers[key]]);

    return Object.fromEntries(entries);
  }

  const api = axios.create({
    baseURL: API_BASE_URL,
  });

  api.interceptors.request.use(
    async config => {
      const validConfig = config !== undefined && config.url !== undefined;

      if (validConfig) {
        try {
          const customHeaders = getCustomHeaders(config.headers || {});
          const token = await getAccessToken();
          
          if (token) {
            config.headers = {
              'authorization': `Bearer ${token}`,
              'accept-language': `${convertSelectedLanguage(userSelectedLanguage as 'en' | 'pt-BR' | 'es')}`,
              'x-client-version': `LSM-WEB_${APP_VERSION}`,
              'x-generate-new-token': `${APP_ON_PREMISES || APP_LOCAL_ENVIRONMENT}`,
              'x-measurement-system': selectedMeasurementSystem,
              ...customHeaders,
            };

            if (config.data) {
              config.data = pascalizeKeys(config.data);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }

      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response: AxiosResponse) => {
      handleSendEvent({
        category: 'API/Success',
        action: `User Made a Request With ${response.config.method?.toUpperCase()} in ${response.config.url} and Get ${response.status
          } - ${response.statusText}.`,
      });
      if (response.data && (response.headers['content-type'])?.includes('application/json')) {
        response.data = camelizeKeys(response.data);
      }

      return response;
    },
    (error: AxiosError<JsonReturnModel>) => {
      handleSendEvent({
        category: 'API/Error',
        action: `User Made a Request With ${error.config.method?.toUpperCase()} in ${error.config.url} and Get ${error.message
          }.`,
      });

      if (!error.response || checkServiceUnavailableError(error.response?.status, error.response?.statusText)) {
        Swal.fire({
          icon: 'error',
          title: t('server.message.unavailable.title'),
          text: t('server.message.unavailable.text'),
          html: '',
          willClose: backToLogin,
        });
      } else if (checkServerSideError(error.response?.status)) {
        Swal.fire({
          icon: 'error',
          title: t('error-message.title'),
          text: t('api.message.error.contact-developer-team'),
          html: '',
        });
      } else if (checkAuthnAuthzError(error.response?.status)) {
        const data = camelizeKeys(error.response?.data) as JsonReturnModel;
        const reason = error.response.headers['x-error-reason'];
        const message = selectAuthnAuthzMessage(reason, data.message);

        Swal.fire({
          icon: 'warning',
          title: t('login.no.roles.title'),
          text: message,
          html: '',
          willClose: backToLogin,
        });
      } else {
        const parsedError = camelizeKeys(error) as AxiosError<JsonReturnModel>;
        const errorResponse: JsonReturnModel = {
          status: parsedError.response?.status || 0,
          message: parsedError.response?.statusText || '',
          details: parsedError.response?.data.details
        };

        if (parsedError.response?.data) {
          const onCloseCallback = error.response?.status === 404 && hasParams ?
            () => history.goBack() :
            EMPTY_FUNCTION;

          Swal.fire({
            icon: 'error',
            title: t('error-message.title'),
            text: parsedError.response.data.message,
            html: '',
            willClose: onCloseCallback,
          })
        }

        if (!!parsedError.response?.data
          && !!parsedError.response?.data.details
          && parsedError.response?.data.details.length > 0) {

          return Promise.reject<JsonReturnModel>(parsedError.response?.data);
        } else if (errorResponse.details && errorResponse.details.length > 0) {

          return Promise.reject<JsonReturnModel>(errorResponse);
        }
      }
    },
  );

  const downloadFile = async (
    url: string,
    filename: string,
    params?: unknown,
    axiosConfig?: AxiosRequestConfig,
  ) => {
    const config: AxiosRequestConfig = {
      ...axiosConfig,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await api.post<Blob>(url, params, config);
    if (data) fdownload(data, filename);
  };

  return { post: api.post, put: api.put, get: api.get, remove: api.delete, patch: api.patch, downloadFile };
}
