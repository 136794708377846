import { UserRoles } from "@app/utils/constants";
import { SystemOfMeasurementOptions } from "../me/MeGetResponse";

export enum UserStatus {
  INVITATION_SENT = "InvitationSent", 
  ACTIVE = "Active",
  BLOCKED =  "Blocked",
  BLOCKED_BY_PLANT = "BlockedByPlant"
};
  
export interface UsersGetResponse {
  id: number;
  name: string;
  email: string;
  plants: string[];
  userProfileId: number;
  userProfileName: UserRoles; 
  status: UserStatus;
  invitationSendingDate: string;
  firstAccess: string;
  lastAccess: string;
  measurementSystem: SystemOfMeasurementOptions;
}
