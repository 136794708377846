import { useHeatsService } from '@app/api/heats';
import Loading from '@app/components/shared/Loading';
import MainPaper from '@app/components/shared/MainPaper';
import FormSelect from '@app/components/shared/FormSelect';
import { useArchivedDraftHeatContext } from '@app/store/archivedDraftHeat/archivedDraftHeatContext';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext';
import { handleSendEvent, ROUTES_PATH } from '@app/utils';
import { APP_TITLE, PAGINATION, PERMISSIONS, UserRoles } from '@app/utils/constants';
import { Search } from '@material-ui/icons';
import { getInverseOrder } from '@app/utils/getInverseOrder';
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers'
import { format, isValid, formatISO, isBefore } from 'date-fns';
import fdownload from 'js-file-download';
import React, { ReactElement, useEffect, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import useLoadDraftHeats from '@app/hooks/useLoadDraftHeats';
import TableNoResults from '@app/components/shared/TableNoResults';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import CopyHeatModal from '../modals/CopyHeatModal';
import { DraftHeatsResponse } from '@app/api/heats/HeatsResponse';
import { CalculationMode, useCalculationModeOptions } from '@app/utils/calculationModeOptions';
import FormButton from '@app/components/shared/FormButton';
import useScrollTop from '@app/hooks/useScrollTop';
import { DeleteButton, DownloadButton, DuplicateButton, EditButton, ViewButton } from '@app/components/shared/CustomIconButton';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableContent: {
      maxHeight: 'calc(100vh - 229px)',
    }
  }),
);

const KEY_LF_CODE = 'LadleFurnace';
const KEY_HEAT_DESCRIPTION = 'HeatDescription';
const KEY_DATE = 'UpdateDate';
const KEY_CALCULATION_MODE = 'CalculationMode';
const KEY_USER = 'UpdateUser';

const ArchivedDraftsContent = (): ReactElement => {
  const [infoObject, loadArchivedHeats] = useLoadDraftHeats();

  const { status, data } = infoObject;

  const items = data?.items;
  const total = data?.total

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [calculationsModeOptions, getCalculationModeLabel] = useCalculationModeOptions();

  const { hasAccess, state: { userInfoObject } } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const { state: { startDate, endDate }, setStartDate, setEndDate, resetDate } = useArchivedDraftHeatContext();
  const { state: plantState } = usePlantContext();

  const [draftIdToEdit, setDraftIdToEdit] = useState(-1);
  const [openEditAcceptanceModal, setOpenEditAcceptanceModal] = useState(false);

  const [startDateIso, setStartDateIso] = useState<MaterialUiPickersDate | null>(null);
  const [endDateIso, setEndDateIso] = useState<MaterialUiPickersDate | null>(null);

  const { getDraftHeatById, deleteDraft } = useHeatsService();

  const [page, setPage] = React.useState<number>(1);
  const [heatDescription, setHeatDescription] = React.useState<string | null>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGINATION.ROWS_PER_PAGE);

  const [orderBy, setOrderBy] = React.useState<string>(KEY_DATE);
  const [order, setOrder] = React.useState<'desc' | 'asc'>('desc');
  const sorting = `${orderBy} ${order.toUpperCase()}`;

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedDraft, setSelectedDraft] = useState<{ id: number, description: string } | undefined>(undefined);

  const [calculationMode, setCalculationMode] = useState<CalculationMode>(CalculationMode.None);
  const [loading, setLoading] = useState(false);

  const [lastSearchStamp, setLastSearchStamp] = useState(`${null}-${null}-${null}-${0}`);

  useEffect(() => {
    if (status === "idle") {
      if(startDate !== null || endDate !== null) {
        resetDate();
      }
      loadArchivedHeats(null, null, page, rowsPerPage, heatDescription, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
    }
  }, [startDate, endDate, page, rowsPerPage, heatDescription, loadArchivedHeats, status, sorting, calculationMode, resetDate]);

  const isOrderedByLfCode = () => orderBy === KEY_LF_CODE;
  const isOrderedByHeatDescription = () => orderBy === KEY_HEAT_DESCRIPTION;
  const isOrderedByDate = () => orderBy === KEY_DATE;
  const isOrderedByCalculationMode = () => orderBy === KEY_CALCULATION_MODE;
  const isOrderedByUser = () => orderBy === KEY_USER;

  const handleSortBy = (criteria: string) => {
    if(criteria === orderBy) {
      loadArchivedHeats(startDate, endDate, page, rowsPerPage, heatDescription, `${orderBy} ${getInverseOrder(order).toUpperCase()}`, calculationMode === CalculationMode.None ? null : calculationMode);
      setOrder(oldOrder => getInverseOrder(oldOrder));
    } else {
      setOrderBy(criteria);
      loadArchivedHeats(startDate, endDate, page, rowsPerPage, heatDescription, `${criteria} DESC`, calculationMode === CalculationMode.None ? null : calculationMode);
      setOrder('desc');
    }
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    handleSendEvent({
      category: 'Heats/ArchivedHeats',
      action: 'User Clicked to Search ArchivedHeats by Date.',
    });

    const newSearchStamp = `${startDate}-${endDate}-${heatDescription}-${calculationMode === CalculationMode.None ? null : calculationMode}`;

    const searchStampHasChanged = newSearchStamp !== lastSearchStamp;

    if(searchStampHasChanged) {
      setPage(1);
    }

    setLastSearchStamp(newSearchStamp);


    loadArchivedHeats(startDate, endDate, searchStampHasChanged ? 1 : page, rowsPerPage, heatDescription, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
  };

  const handleDateChange = (date: MaterialUiPickersDate, isoDateCallbackFunction: (date: MaterialUiPickersDate) => void, stringDateCallbackFunction: (date: string | null) => void) => {
    if (isValid(date)) {
      stringDateCallbackFunction(formatISO(date as Date, { representation: 'date' }));
    } else {
      stringDateCallbackFunction(null);
    }
    isoDateCallbackFunction(date);
  };

  const onClickDelete = (id: number, description: string) => {
    setSelectedDraft({ id: id, description: description });
    setOpenDeleteModal(true);
  };

  const onSubmitDelete = async () => {
    if (!selectedDraft) {
      return;
    }

    try {
      setLoading(true);
      await deleteDraft(selectedDraft.id);

      setOpenDeleteModal(false);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('draft.api.title.success-delete'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      loadArchivedHeats(startDate, endDate, page, rowsPerPage, heatDescription, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
    } catch (err) {
      loadArchivedHeats(startDate, endDate, page, rowsPerPage, heatDescription, sorting, calculationMode);
    } finally {
      setLoading(false);
    }
  };

  const renderLines = useCallback(() => {

    const fileDownloadPrepare = async (name: string, id: number) => {
      handleSendEvent({
        category: 'Heats/ArchivedDrafts',
        action: 'User Clicked to Download an ArchivedDraft.',
      });
      try {
        setLoading(true);
        const response = await getDraftHeatById(id);
        const plant = userInfo?.selectedPlant.name;
        const fileName = `${plant}-${name}-${id}-${response?.output?.details?.createDate || format(new Date(), 'dd_MM_yyyy_HH_mm_SS')}`;

        fdownload(JSON.stringify(response), `${fileName}.json`);
      } finally {
        setLoading(false)
      }
    };

    return !!items && items.length > 0 ? items
      .map((row, i) => {
        const viewUrl =
          row?.calculationMode === CalculationMode.Tapping
            ? `${ROUTES_PATH.APPLICATIONS_DRAFT_TAPPING_VIEW.replace(":id", String(row.id))}`
            : `${ROUTES_PATH.APPLICATIONS_DRAFT_LADLE_FURNACE_VIEW.replace(":id", String(row.id))}`;

        const isOwnerOrIsAdmin =
          row.isOwner
          || userInfo?.userProfileName === UserRoles.ADMINISTRATOR
          || userInfo?.userProfileName === UserRoles.PLANT_ADMIN

        return (
          <TableRow key={i}>
            <TableCell align="left">
              {getCalculationModeLabel(row.calculationMode)}
            </TableCell>
            <TableCell align="left">{row?.calculationMode === CalculationMode.LadleFurnace ? row.ladleFurnace : '-'}</TableCell>
            <TableCell align="left">{row.heatDescription}</TableCell>
            <TableCell align="left">{format(new Date(row.updateDate), t('date-time.format'))}</TableCell>
            <TableCell align="left">{row.createUser}</TableCell>
            <TableCell>
              <Box  style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', columnGap: '16px' }}>
                {hasAccess([PERMISSIONS.HEAT_CALCULATION_DETAIL]) && (
                  <ViewButton
                    tooltipMessagePlacement="top-end"
                    onClick={() => {
                      handleSendEvent({ category: 'Heats/ArchivedHeats', action: 'User Clicked to Open an ArchivedHeat.' });
                      history.push(viewUrl);
                    }}
                  />
                )}
                {hasAccess([PERMISSIONS.DRAFT_CALCULATION_CREATE]) && (
                  <DuplicateButton
                    tooltipMessagePlacement="top-end"
                    onClick={() => onDraftCopy(row)}
                  />
                )}
                {hasAccess([PERMISSIONS.HEAT_CALCULATION_DETAIL]) && (
                  <EditButton
                    tooltipMessage={t(!row.isOwner ? 'heat.draft.button.tooltip.cant-edit' : 'heat.draft.edit')}
                    tooltipMessagePlacement="top-end"
                    disabled={!row.isOwner}
                    onClick={() => {
                      setDraftIdToEdit(row.id);
                      setOpenEditAcceptanceModal(true)
                    }}
                  />
                )}
                <DownloadButton
                  tooltipMessage={t('button.export')}
                  tooltipMessagePlacement="top-end"
                  onClick={async () => await fileDownloadPrepare(row.heatDescription, row.id)}
                />
                <DeleteButton
                  tooltipMessage={t(!isOwnerOrIsAdmin ? 'heat.draft.button.tooltip.cant-delete' : 'heat.draft.button.tooltip.delete')}
                  tooltipMessagePlacement='top-end'
                  disabled={!isOwnerOrIsAdmin}
                  onClick={() => onClickDelete(row.id, row.heatDescription)}
                />
              </Box>
            </TableCell>
          </TableRow>
        );
      }) : (
      <TableRow >
        <TableCell colSpan={12}>
          <TableNoResults firstText='draft.empty-table-first-text' secondText='heat-list.empty-table-second-text' />
        </TableCell>
      </TableRow>
    );
  }, [
    items,
    hasAccess,
    t,
    userInfo?.selectedPlant.name,
    getDraftHeatById,
    getCalculationModeLabel,
    userInfo?.userProfileName,
    history
  ]);

  const [copyDraftModalState, setCopyModalDraftState] = useState<{
    selectedDraft: DraftHeatsResponse | undefined;
    copyModalIsVisible: boolean;
  }>({
    selectedDraft: undefined,
    copyModalIsVisible: false,
  })

  const onDraftCopy = (draftHeatToCopy: DraftHeatsResponse) => {
    setCopyModalDraftState({selectedDraft: draftHeatToCopy, copyModalIsVisible: true});
  }

  const onAcceptToEdit = () => {
    handleSendEvent({ category: 'Heats/ArchivedHeats-editOutlined', action: 'User Clicked to Open an ArchivedHeat.' });

    const itemToEdit = items?.find(item => item.id === draftIdToEdit);

    const editUrl =
      itemToEdit?.calculationMode === CalculationMode.Tapping
        ? `${ROUTES_PATH.APPLICATIONS_DRAFT_TAPPING_EDIT.replace(":id", String(itemToEdit?.id))}`
        : `${ROUTES_PATH.APPLICATIONS_DRAFT_LADLE_FURNACE_EDIT.replace(":id", String(itemToEdit?.id))}`;

    history.push(editUrl);
  }

  const onGoToOverviewButtonClick = () => {
    history.push(ROUTES_PATH.PLANT_OVERVIEW);
  }

  const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop<HTMLDivElement>();

  return (
    <>
      {copyDraftModalState.copyModalIsVisible && (
        <CopyHeatModal
          heatId={copyDraftModalState.selectedDraft?.id ?? -1}
          onClose={() => setCopyModalDraftState({selectedDraft: undefined, copyModalIsVisible: false})}
          open={copyDraftModalState.copyModalIsVisible}
          heatDescriptionInitialState={`${copyDraftModalState.selectedDraft?.heatDescription} - ${t('copy-heat-modal.input.default-value.text')}`}
          isDraft
        />
      )}
      <form onSubmit={handleSearch}>
        <Helmet>
          <title>
            {t('heat.draft')} | {APP_TITLE}
          </title>
        </Helmet>

        <Loading dataTestId='archived-draft-heats-screen-circular-progress' promiseInProgress={status === "loading" || loading} />

        <ConfirmDialog
          visible={openEditAcceptanceModal}
          setVisible={setOpenEditAcceptanceModal}
          title={t('draft-edit-acceptance-modal.title')}
          content={
            plantState.data.general
            ? t('draft-edit-acceptance-modal.content')
            : t('draft-edit-acceptance-modal.content-when-plant-setup-is-invalid')
          }
          cancelText={t('draft-edit-acceptance-modal.button.cancel')}
          cancelAction={() => {
            setOpenEditAcceptanceModal(false);
          }}
          confirmText={
            plantState.data.general
            ? t('draft-edit-acceptance-modal.button.continue')
            : t('overview.view')
          }
          confirmAction={
            plantState.data.general
            ? onAcceptToEdit
            : onGoToOverviewButtonClick
          }
        />

        <ConfirmDialog
          visible={openDeleteModal}
          setVisible={setOpenDeleteModal}
          title={t('confirmation')}
          content={t('draft.dialog.content', { name: selectedDraft?.description })}
          cancelText={t('button.cancel')}
          cancelAction={() => {
            setOpenDeleteModal(false);
          }}
          confirmText={t('draft.dialog.button.remove')}
          confirmAction={onSubmitDelete}
        />

        <MainPaper
          removePadding
          title={t('heat.draft')}
          headerActions={[
            <Box key="dates-wrapper" style={{display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap', gap: '8px'}}>
              <TextField
                id="heatDescription"
                label={t('heat.description')}
                autoComplete="off"
                style={{ maxWidth: "155px" }}
                InputProps={{ endAdornment: <Search className="initial-positioning" fontSize="small" color="secondary" /> }}
                value={heatDescription ?? ""}
                onChange={e => setHeatDescription(e.target.value || null)}
              />
              <KeyboardDatePicker
                id="date"
                label={t('heat-list.form.date-start')}
                style={{ maxWidth: "155px" }}
                value={startDateIso}
                onChange={(date) => handleDateChange(date, setStartDateIso, setStartDate)}
                autoOk={true}
                format={t('date.format')}
                placeholder={t('date-placeholder.format')}
                helperText={
                  (!!startDateIso && !isValid(startDateIso) && t('date.invalid-feedback.format')) ||
                  (!!endDateIso && !startDateIso && t('date.invalid-feedback.required')) ||
                  (!!endDateIso && !!startDateIso && isBefore(endDateIso, startDateIso)) && t('date.invalid-feedback.order')
                }
                error={
                  (!!startDateIso && !isValid(startDateIso)) ||
                  (!!endDateIso && !startDateIso) ||
                  !!endDateIso && !!startDateIso && isBefore(endDateIso, startDateIso)
                }
                cancelLabel={t('button.cancel')}
              />
              <KeyboardDatePicker
                id="date"
                label={t('heat-list.form.date-end')}
                style={{ maxWidth: "155px" }}
                value={endDateIso}
                onChange={(date) => handleDateChange(date, setEndDateIso, setEndDate)}
                autoOk={true}
                format={t('date.format')}
                placeholder={t('date-placeholder.format')}
                helperText={
                  (!!endDateIso && !isValid(endDateIso) && t('date.invalid-feedback.format')) ||
                  (!endDateIso && !!startDateIso && t('date.invalid-feedback.required'))
                }
                error={
                  !!endDateIso && !isValid(endDateIso) ||
                  (!endDateIso && !!startDateIso) ||
                  !!endDateIso && !!startDateIso && isBefore(endDateIso, startDateIso)
                }
                cancelLabel={t('button.cancel')}
              />
              <FormSelect
                id="drop-down-calculation-mode"
                label={t('calculation.mode.select.option')}
                style={{ width: "200px" }}
                value={calculationMode}
                onChange={ e => {
                  setCalculationMode((e.target.value) as CalculationMode | CalculationMode.None)
                }}
                options={calculationsModeOptions.map(row => ({
                  name: row.label,
                  value: row.value as string | number,
                }))}
              />
            </Box>
            ,

            <FormButton
              key="btn-submit"
              type="submit"
              variant="secondary"
              disabled={
                (!!startDateIso && !isValid(startDateIso)) || (!!endDateIso && !isValid(endDateIso)) ||
                (!!startDateIso && !endDateIso) ||
                (!startDateIso && !!endDateIso) ||
                (!!startDateIso && !!endDateIso) && isBefore(endDateIso, startDateIso)
              }
              size="small"
            >
              {t('filter.search')}
            </FormButton>,
          ]}>
          <Paper>
            <TableContainer className={classes.tableContent} innerRef={tableContainerRef}>
              <Table stickyHeader className={classes.table} style={{ maxWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="30" sortDirection={isOrderedByCalculationMode() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByCalculationMode()}
                        direction={isOrderedByCalculationMode() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_CALCULATION_MODE)}>
                        {t('calculation.mode')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" width="50" sortDirection={isOrderedByLfCode() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByLfCode()}
                        direction={isOrderedByLfCode() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_LF_CODE)}>
                        {t('heat-list.table.head.ladle-furnace')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" width="80" sortDirection={isOrderedByHeatDescription() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByHeatDescription()}
                        direction={isOrderedByHeatDescription() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_HEAT_DESCRIPTION)}>
                        {t('heat.description')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" width="150" sortDirection={isOrderedByDate() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByDate()}
                        direction={isOrderedByDate() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_DATE)}>
                        {t('draft-list.table.head.date')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" width="120" sortDirection={isOrderedByUser() ? order : undefined}>
                      <TableSortLabel
                        active={isOrderedByUser()}
                        direction={isOrderedByUser() ? order : getInverseOrder(order)}
                        onClick={() => handleSortBy(KEY_USER)}>
                        {t('heat-list.table.head.create.by')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" width="120"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{["succeeded", "error"].includes(status) && renderLines()}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(_, newPage) => {
                setPage(newPage + 1);
                scrollTop();
                loadArchivedHeats(startDate, endDate, newPage + 1, rowsPerPage, heatDescription, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
              }}
              onRowsPerPageChange={e => {
                setPage(1);
                setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE));
                loadArchivedHeats(startDate, endDate, page, Number(e.target.value || PAGINATION.ROWS_PER_PAGE), heatDescription, sorting, calculationMode === CalculationMode.None ? null : calculationMode);
              }}
              labelRowsPerPage={t('rows-per-page')}
              backIconButtonText={t('page-next')}
            />
          </Paper>
        </MainPaper>
      </ form>
    </>
  );
};

export default ArchivedDraftsContent;
