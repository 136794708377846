import { useApi } from '..';
import { GetLadlesResponse } from './GetLadlesResponse';

export function useLadlesService() {
  const { get } = useApi();

  const baseApiV2 = `/v2/ladles`;

  const getLadles = async () => {
    const { data } = await get<GetLadlesResponse[]>(`${baseApiV2}`);

    return data;
  };

  return {
    getLadles
  };
}
