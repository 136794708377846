const isNumber = (value?: number | string) => {
  if (!value) return false;

  const fvar = parseFloat(value as string);
  const svar = fvar.toString();

  return svar == value;
};

export default isNumber;
