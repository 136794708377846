const isEmptyOrWhitespaces = (value?: string | null): boolean => {
  if (!value) {
    return true;
  } else if (value.trim().length == 0) {
    return true;
  }

  return false;
}

const hasValue = (value?: string | null): boolean => {
  return !isEmptyOrWhitespaces(value);
}

const undefinedIfEmpty = (value?: string | null): string | undefined => {
  if (!value || isEmptyOrWhitespaces(value)) {
    return undefined;
  }
  
  return value;
}

export const StringUtils = {
  isEmptyOrWhitespaces: isEmptyOrWhitespaces,
  hasValue: hasValue,
  undefinedIfEmpty: undefinedIfEmpty
}