import { SteelChemistryUnitOfMeasurement } from "@app/api/processConstraints/useProcessConstraintsService";
import { CHEMISTRY_AT_TAPPING_ALUMINUM_PERCENTAGE_DECIMAL_PLACES, CHEMISTRY_AT_TAPPING_ALUMINUM_POINTS_CONVERSION_FACTOR, CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES, CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR, CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES } from "./constants";

const calculateSteelChemistry = (unitOfMeasurement: SteelChemistryUnitOfMeasurement, value: number | null | undefined, isAluminumElement = false) => {
  if (value != null) {
    let factor: number;

    if (unitOfMeasurement === SteelChemistryUnitOfMeasurement.Points && isAluminumElement) {
      factor = CHEMISTRY_AT_TAPPING_ALUMINUM_POINTS_CONVERSION_FACTOR;
    } else if (unitOfMeasurement === SteelChemistryUnitOfMeasurement.Points) {
      factor = CHEMISTRY_AT_TAPPING_GENERAL_POINTS_CONVERSION_FACTOR;
    } else {
      factor = 1;
    }

    return value * factor;
  }

  return null;
}

const formatSteelChemistry = (unitOfMeasurement: SteelChemistryUnitOfMeasurement, value: number | null | undefined, isAluminumElement = false) => {
  if (value != null) {
    let decimalPlaces: number;

    if (unitOfMeasurement === SteelChemistryUnitOfMeasurement.Percent && isAluminumElement) {
      decimalPlaces = CHEMISTRY_AT_TAPPING_ALUMINUM_PERCENTAGE_DECIMAL_PLACES;
    } else if (unitOfMeasurement === SteelChemistryUnitOfMeasurement.Percent) {
      decimalPlaces = CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES;
    } else {
      decimalPlaces = CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES;
    }

    return value.toFixed(decimalPlaces);
  }

  return null;
}

const calculateAndFormatSteelChemistry = (unitOfMeasurement: SteelChemistryUnitOfMeasurement, value: number | null | undefined, isAluminumElement = false) => {
  const calculatedValue = calculateSteelChemistry(unitOfMeasurement, value, isAluminumElement);

  return formatSteelChemistry(unitOfMeasurement, calculatedValue, isAluminumElement) as string;
}
  
export { calculateSteelChemistry, formatSteelChemistry, calculateAndFormatSteelChemistry };