import { Box } from "@material-ui/core";
import { BoxProps } from "@material-ui/core";

export interface TabPanelProps extends BoxProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    childrenBoxSize?: string;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, childrenBoxSize, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ height: childrenBoxSize }}>
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default TabPanel;
