import { AlloysRequest } from '@app/api/alloys'
import { DeoxidizersModel, FluxAvailability, FluxModel, FluxType } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';

export enum AdditionsTabs {
  Fluxes,
  Alloys,
  SlagDeoxidizers,
}

export const newAlloyInitialValue: AlloysRequest = {
  id: undefined,
  name: '',
  externalId: undefined,
  si: 0,
  mn: 0,
  cr: 0,
  al: 0,
  c: 0,
}

export const slagDeoxidizerInitialValue: DeoxidizersModel = {
  id: 0,
  name: "",
  externalId: undefined,
  isEnabled: false,
  si: 0,
  al: 0,
  ca: 0,
  c: 0,
  carbonDeoxEfficiency: 0,
  efficiencyFactor: 100,
};

export const newFluxInitialValue: FluxModel = {
  id: 0,
  externalId: undefined,
  name: "",

  mgO: 0,
  caO: 0,
  al2O3: 0,
  siO2: 0,
  feO: 0,
  mnO: 0,
  cr2O3: 0,
  tiO2: 0,
  caF2: 0,
  al: 0,
  na2O: 0,
  k2O: 0,

  fixedAdditionAmount: 100,
  fixedAdditionRecovery: 0,

  type: FluxType.GeneralFlux,
  availability: FluxAvailability.Tapping,

  mgOSourceMinimumWeight: 50,
  mgOSourceMaximumWeight: 100,
}