import { useSteelGradeCategoriesService } from '@app/api/steelGradeCategories';
import { useState } from 'react';
import { ListInfoObject } from "./types"
import { SteelGradeCategoryModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';

const infoObjectInitialState: ListInfoObject<SteelGradeCategoryModel> = {
  status: 'idle',
  data: [],
}

const useLoadSteelGradeCategories = (): [
  ListInfoObject<SteelGradeCategoryModel>,
  () => Promise<void>,
] => {

  const { getSteelGradesCategories } = useSteelGradeCategoriesService();

  const [infoObject, setInfoObject] = useState<ListInfoObject<SteelGradeCategoryModel>>(infoObjectInitialState);


  const loadSteelGradeCategories = async () => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getSteelGradesCategories();
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {

      setInfoObject({data: [], status: 'error'});
    }
  };


  return [infoObject, loadSteelGradeCategories];
}

export default useLoadSteelGradeCategories;