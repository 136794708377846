import { processConstraintsInitialValue } from '@app/api/processConstraints/ProcessConstraintReferenceParameters'
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loading from '@app/components/shared/Loading';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { APP_NA2O_K2O_ENABLED, PERMISSIONS } from '@app/utils/constants';
import { useProcessConstraintsService } from '@app/api/processConstraints';
import useLoadProcessConstraints from '@app/hooks/useLoadProcessConstraints';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext';
import { SteelChemistryUnitOfMeasurement, ProcessConstraintsDTO, UNITS_OF_MEASUREMENT } from '@app/api/processConstraints/useProcessConstraintsService';
import { hasAlphaFeaturesPermission, text2Subscript } from '@app/utils';
import FormSelect from '@app/components/shared/FormSelect';
import { CarryoverSlagType } from '@app/api/heats/HeatsResponse';
import FormButton from '@app/components/shared/FormButton';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import useRenderInputEndAdornment from '@app/hooks/useRenderInputEndAdornment';
import { text2SubscriptAsString } from '@app/utils/text2Subscript';

interface AdditionsGeneralSettingModalProps {
  onClose: () => void;
}

const AdditionsGeneralSettingModal: React.FC<AdditionsGeneralSettingModalProps> = (props: AdditionsGeneralSettingModalProps) => {

  const { onClose } = props;
  const { t } = useTranslation();
  const { hasAccess, state: { userInfoObject } } = useAuthenticationContext();
  const userInfo = userInfoObject?.data;
  const { updateProcessConstraint } = useProcessConstraintsService();
  const { loadPlantValidations } = usePlantContext();
  const plantId = userInfo?.selectedPlant?.id;
  const measurementSystem = userInfo?.measurementSystem;

  const [processConstraint, setProcessConstraint] = useState<ProcessConstraintsDTO>(processConstraintsInitialValue);

  const [processConstraintsInfoObject, loadProcessConstraints] = useLoadProcessConstraints();

  const [loading, setLoading] = useState(false);

  const { data: processContraintsData, status: processContraintsStatus } = processConstraintsInfoObject;

  const saveButtonIsDisabled =
    !processConstraint.maximumSlagDeoxidizerWeight ||
    !processConstraint.alkalisToFluorsparFactor ||
    processConstraint.alkalisToFluorsparFactor <= 0 ||
    isNaN(processConstraint.alRecovery) ||
    isNaN(processConstraint.siRecovery) ||
    isNaN(processConstraint.mnRecovery) ||
    isNaN(processConstraint.crRecovery);

  const updateProcessConstraintField = <K extends keyof ProcessConstraintsDTO>(field: K, value?: ProcessConstraintsDTO[K]) => {
    const newProcessConstraint: ProcessConstraintsDTO = {...processConstraint};
    if(
        field !== 'steelChemistryPrimaryFurnaceCode'
        && field !== 'steelChemistryTappingCode'
        && field !== 'steelChemistryUnitOfMeasurement'
        && field !== 'carryoverSlagType'
      ) {
      newProcessConstraint[field] = Number(value) as ProcessConstraintsDTO[K];
    } else {
      newProcessConstraint[field] = value as ProcessConstraintsDTO[K];
    }
    setProcessConstraint(newProcessConstraint);
  }

  const postSubmit = async () => {
    try {
      setLoading(true);
      await updateProcessConstraint(processConstraint);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setLoading(false);
      loadPlantValidations(plantId ?? '');
      onClose();
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(processContraintsStatus === 'idle') {
      loadProcessConstraints();
    }
  }, [loadProcessConstraints, processContraintsStatus]);

  useEffect(() => {
    setProcessConstraint({
      ladleSteelWeight: Number(processContraintsData?.ladleSteelWeight),
      ladleDiameter: Number(processContraintsData?.ladleDiameter),
      carryoverSlagType: processContraintsData?.carryoverSlagType as CarryoverSlagType,
      toleranceCalcMinSlagThickness: Number(processContraintsData?.toleranceCalcMinSlagThickness),
      initialLiningThickness: Number(processContraintsData?.initialLiningThickness),
      finalLiningThickness: Number(processContraintsData?.finalLiningThickness),
      averageLadleLifetime: Number(processContraintsData?.averageLadleLifetime),
      minimumSlagCarryoverWeight: Number(processContraintsData?.minimumSlagCarryoverWeight),
      minimumSlagWeightAfterSkimmerUse: Number(processContraintsData?.minimumSlagWeightAfterSkimmerUse),
      maximumSlagWeight: Number(processContraintsData?.maximumSlagWeight),
      maximumSlagDeoxidizerWeight: Number(processContraintsData?.maximumSlagDeoxidizerWeight),
      mgOSourceMinimumWeight: Number(processContraintsData?.mgOSourceMinimumWeight),
      mgOSourceMaximumWeight: Number(processContraintsData?.mgOSourceMaximumWeight),
      alRecovery: Number(processContraintsData?.alRecovery),
      siRecovery: Number(processContraintsData?.siRecovery),
      mnRecovery: Number(processContraintsData?.mnRecovery),
      crRecovery: Number(processContraintsData?.crRecovery),
      alkalisToFluorsparFactor: Number(processContraintsData?.alkalisToFluorsparFactor),
      steelChemistryUnitOfMeasurement: processContraintsData?.steelChemistryUnitOfMeasurement ?? SteelChemistryUnitOfMeasurement.Percent,
      steelChemistryPrimaryFurnaceCode: processContraintsData?.steelChemistryPrimaryFurnaceCode ?? '',
      steelChemistryTappingCode: processContraintsData?.steelChemistryTappingCode ?? '',
      measurementSystem: processContraintsData?.measurementSystem ?? SystemOfMeasurementOptions.Metric
    });
  }, [processContraintsData]);

  const alkalisToFluorsparFactorIsVisible = APP_NA2O_K2O_ENABLED && (hasAlphaFeaturesPermission(userInfo));

  const unitOfMeasurementOptions = Object.keys(UNITS_OF_MEASUREMENT).sort().map(key => {
    return {
      value: key,
      name: t(UNITS_OF_MEASUREMENT[key]),
    } as { value: SteelChemistryUnitOfMeasurement, name: string };
  });

  const { renderWeightInputEndAdornment } = useRenderInputEndAdornment();

  return (
    <>
      <Loading dataTestId='additions-general-settings-circular-progress' promiseInProgress={loading || processContraintsStatus === 'loading'} />

      <Dialog
        open
        maxWidth="sm"
      >
        <DialogTitle data-testid="additions-general-settings-modal" id="additions-general-settings-modal-title">{t('general-settings')}</DialogTitle>
        <DialogContent>
          <Box paddingBottom={3} minWidth={400}>
            <Box paddingBottom={4}>
              <Box paddingBottom={1} minWidth={400}>
                <Typography variant="body2"><b>{t('additions.general-settings.session.alloy-recovery-title')}</b></Typography>
              </Box>
              <Box style={{ display: 'flex', flex: 1, columnGap: '8px' }}>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <NumberFormat
                    name="AlRecovery"
                    id="alRecovery"
                    label="Al"
                    title={t('processConstraints.text-field.title.al-recovery')}
                    value={processConstraint.alRecovery}
                    autoComplete="off"
                    fullWidth
                    customInput={TextField}
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                    onValueChange={e => updateProcessConstraintField('alRecovery', e.floatValue)}
                    error={isNaN(processConstraint.alRecovery)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={isNaN(processConstraint.alRecovery) && t('processConstraints.text-field.title.al-recovery')}
                    inputProps={{ 'data-testid': 'edit-additions-general-settings-form.field.al' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Box>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <NumberFormat
                    name="SiRecovery"
                    id="siRecovery"
                    label="Si"
                    title={t('processConstraints.text-field.title.si-recovery')}
                    value={processConstraint.siRecovery}
                    autoComplete="off"
                    fullWidth
                    customInput={TextField}
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                    onValueChange={e => updateProcessConstraintField('siRecovery', e.floatValue)}
                    error={isNaN(processConstraint.siRecovery)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={isNaN(processConstraint.siRecovery) && t('processConstraints.text-field.title.si-recovery')}
                    inputProps={{ 'data-testid': 'edit-additions-general-settings-form.field.si' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Box>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <NumberFormat
                    name="MnRecovery"
                    id="mnRecovery"
                    label="Mn"
                    title={t('processConstraints.text-field.title.mn-recovery')}
                    value={processConstraint.mnRecovery}
                    autoComplete="off"
                    fullWidth
                    customInput={TextField}
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                    onValueChange={e => updateProcessConstraintField('mnRecovery', e.floatValue)}
                    error={isNaN(processConstraint.mnRecovery)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={isNaN(processConstraint.mnRecovery) && t('processConstraints.text-field.title.mn-recovery')}
                    inputProps={{ 'data-testid': 'edit-additions-general-settings-form.field.mn' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Box>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <NumberFormat
                    name="CrRecovery"
                    id="crRecovery"
                    label="Cr"
                    title={t('processConstraints.text-field.title.cr-recovery')}
                    value={processConstraint.crRecovery}
                    autoComplete="off"
                    fullWidth
                    customInput={TextField}
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                    onValueChange={e => updateProcessConstraintField('crRecovery', e.floatValue)}
                    error={isNaN(processConstraint.crRecovery)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={isNaN(processConstraint.crRecovery) && t('processConstraints.text-field.title.cr-recovery')}
                    inputProps={{ 'data-testid': 'edit-additions-general-settings-form.field.cr' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Box>
              </Box>
            </Box>
            <Box paddingBottom={4}>
              <Box paddingBottom={1} minWidth={400}>
                <Typography variant="body2"><b>{t('slag-deoxidizers')}</b></Typography>
              </Box>
              <Grid container>
                <NumberFormat
                  name="maximumSlagDeoxidizerWeight"
                  id="maximumSlagDeoxidizerWeight"
                  label={t('processConstraints.text-field.label.max-slag-deox-LF')}
                  title={t('slag-deoxidizers-max-weight-in-recommendations')}
                  value={processConstraint.maximumSlagDeoxidizerWeight}
                  autoComplete="off"
                  fullWidth
                  customInput={TextField}
                  decimalScale={0}
                  allowNegative={false}
                  decimalSeparator={t('decimal-scale-separator')}
                  isAllowed={({ floatValue }) => (floatValue ?? 0) <= 10000}
                  onValueChange={e => updateProcessConstraintField('maximumSlagDeoxidizerWeight', e.floatValue)}
                  error={isNaN(processConstraint.maximumSlagDeoxidizerWeight) || processConstraint.maximumSlagDeoxidizerWeight === 0}
                  helperText={
                    (isNaN(processConstraint.maximumSlagDeoxidizerWeight) && t('slag-deoxidizers-max-weight-in-recommendations')) ||
                    (processConstraint.maximumSlagDeoxidizerWeight === 0 && t('additions.text-field.general-settings.error.max-deoxidizers-max-weight-is-zero'))
                  }
                  inputProps={{ 'data-testid': 'edit-additions-general-settings-form.field.max-deoxidizers-weight' }}
                  InputProps={{endAdornment:renderWeightInputEndAdornment(measurementSystem,"lb")}}
                  disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                />
              </Grid>
            </Box>
            {alkalisToFluorsparFactorIsVisible && (
              <Box paddingBottom={4}>
                <Box paddingBottom={1} minWidth={400}>
                  <Typography variant="body2"><b>{t('additions.general-settings.session.alkalis-to-fluorspar-factor-title')}</b></Typography>
                </Box>
                <Grid container>
                  <NumberFormat
                    name="alkalisToFluorsparFactor"
                    id="alkalisToFluorsparFactor"
                    error={isNaN(processConstraint.alkalisToFluorsparFactor) || processConstraint.alkalisToFluorsparFactor === 0}
                    helperText={
                      isNaN(processConstraint.alkalisToFluorsparFactor)
                      && t('additions.text-field.general-settings.error.alkalis-to-fluorspar-factor', {fieldTitle: text2Subscript(t('processConstraints.text-field.label.alkalis-to-fluorspar-factor'))})
                      || processConstraint.alkalisToFluorsparFactor === 0 &&
                      t('additions.text-field.general-settings.error.alkalis-to-fluorspar-factor-is-zero', {fieldTitle: text2Subscript(t('processConstraints.text-field.label.alkalis-to-fluorspar-factor'))})
                    }
                    label={text2Subscript(t('processConstraints.text-field.label.alkalis-to-fluorspar-factor'))}
                    title={text2SubscriptAsString(t('processConstraints.text-field.title.alkalis-to-fluorspar-factor'))}
                    value={processConstraint.alkalisToFluorsparFactor}
                    autoComplete="off"
                    fullWidth
                    customInput={TextField}
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator={t('decimal-scale-separator')}
                    onValueChange={e => updateProcessConstraintField('alkalisToFluorsparFactor', e.floatValue)}
                    inputProps={{ 'data-testid': 'edit-additions-general-settings-form.field.alkalis-to-fluorspar-factor' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Grid>
              </Box>
            )}
             <Box paddingBottom={2}>
                <Box paddingBottom={1} minWidth={400}>
                  <Typography variant="body2"><b>{t('steel-chemistry')}</b></Typography>
                  <Typography variant="body2">{t('additions.general-settings.session.chemistry-at-tapping.description')}</Typography>
                </Box>
                <Grid container style={{ paddingBottom: '8px' }}>
                  <FormSelect
                    fullWidth
                    inputProps={{ "data-testid": "edit-additions-general-settings-form.field.chemistry-at-tapping-unit-of-measurement" }}
                    id="chemistryAtTappingUnitOfMeasurement"
                    label={t('unit-of-measurement')}
                    options={unitOfMeasurementOptions}
                    value={processConstraint?.steelChemistryUnitOfMeasurement ?? ''}
                    onChange={event => {
                      updateProcessConstraintField('steelChemistryUnitOfMeasurement', event.target.value as SteelChemistryUnitOfMeasurement)
                    }}
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Grid>
                <Grid container style={{ paddingBottom: '8px' }}>
                  <TextField
                    name="steelChemistryPrimaryFurnaceCode"
                    id="steelChemistryPrimaryFurnaceCode"
                    label={t('additions.general-settings.steel-chemistry-primary-furnace-code.input.label')}
                    inputProps={{
                      "data-testid": "edit-additions-general-settings-form.field.steel-chemistry-primary-furnace-code",
                      maxLength: 10,
                      onKeyDown: (e) => {
                        if (e.key === ' ') {
                          e.preventDefault();
                        }
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={processConstraint?.steelChemistryPrimaryFurnaceCode ?? ''}
                    onChange={e => updateProcessConstraintField('steelChemistryPrimaryFurnaceCode', e.target.value)}
                    autoComplete="off"
                    fullWidth
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    name="steelChemistryTappingCode"
                    id="steelChemistryTappingCode"
                    label={t('additions.general-settings.steel-chemistry-tapping-code.input.label')}
                    inputProps={{
                      "data-testid": "edit-additions-general-settings-form.field.steel-chemistry-tapping-code",
                      maxLength: 10,
                      onKeyDown: (e) => {
                        if (e.key === ' ') {
                          e.preventDefault();
                        }
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={processConstraint?.steelChemistryTappingCode ?? ''}
                    onChange={e => updateProcessConstraintField('steelChemistryTappingCode', e.target.value)}
                    autoComplete="off"
                    fullWidth
                    disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}
                  />
                </Grid>
              </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <FormButton
            onClick={onClose}
            variant="secondary"
            data-testid='edit-additions-general-settings-form.button.cancel'
          >
            {t('button.cancel')}
          </FormButton>
          <FormButton
            onClick={postSubmit}
            variant="primary"
            data-testid='edit-additions-general-settings-form.button.save'
            disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT]) || saveButtonIsDisabled}
          >
            {t('button.save')}
          </FormButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdditionsGeneralSettingModal;
