import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { MAX_WEIGHT } from '@app/utils/constants';
import { CalculationDiagnostic, DiagnosticSeverityType, HeatRecommendationStagePropertyDTO, HeatRecommendationsDTO, OutputDetailsDTO, ResultDTO } from '@app/api/heats/HeatsResponse';
import { FluxType } from './heatCalculationTypes';
import { Box, Grid, IconButton, List } from '@material-ui/core';
import { displayNotApplicableIfNull, hasAlphaFeaturesPermission, isNumber } from '@app/utils';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import useRenderInputEndAdornment from '@app/hooks/useRenderInputEndAdornment';
import useReturnUnitOfMeasurement from '@app/hooks/useReturnUnitOfMeasurement';
import useCallApi from '@app/hooks/useCallApi';
import { useHeatsService } from '@app/api/heats';
import { useCallback, useEffect, useState } from 'react';
import { HeatMode } from '../NewHeat';
import { useParams } from 'react-router-dom';
import CalculationDiagnosticsModal from '../modals/CalculationDiagnosticsModal';
import { ReportProblemIcon } from '@app/assets/icons/reportProblemIcon';
import CustomAccordion from '@app/components/shared/CustomAccordion';
import FormWrapper from '@app/components/shared/FormWrapper';
import FormSectionContainer from '@app/components/shared/FormSectionContainer';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import CustomTooltip from '@app/components/shared/CustomTooltip';
import IterationChartsButton from '../modals/IterationChartsButton';

const warningMessageGridStyles: React.CSSProperties = {
  border: '1px solid #ccc',
  margin: '0 auto', 
  maxWidth: '1208px',
}

const contentStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  flex: 1,
}

const tableCellStyles: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  textAlign: 'left'
}

const listItemStyles: React.CSSProperties = {
  background: 'white',
  border: '1px solid #ccc',
  lineHeight: '10px',
  display: 'flex',
  columnGap: '10px'
}

enum RecommendedAdditionType {
  Flux = 'Flux',
  Deoxidizer = 'Deoxidizer',
}

interface RecommendedAddition extends HeatRecommendationStagePropertyDTO {
  type: RecommendedAdditionType;
  hasWarning: boolean;
  keywords: string[];
}

interface Step5Props {
  recommendedAdditions?: HeatRecommendationsDTO;
  setRecommendedAdditions: (rec: HeatRecommendationsDTO) => void;
  result?: ResultDTO,
  outputDetails?: OutputDetailsDTO,
  heatMode: HeatMode;
  disabled?: boolean;
  setStep5FormIsDirty?: () => void;
}

function Step5({
  recommendedAdditions,
  setRecommendedAdditions,
  result,
  outputDetails,
  heatMode,
  disabled = false,
  setStep5FormIsDirty = () => null
}: Step5Props) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { renderWeightInputEndAdornment } = useRenderInputEndAdornment();
  const { returnLengthUnitOfMeasurement, returnWeightUnitOfMeasurement } = useReturnUnitOfMeasurement();
  const { state: { userInfoObject } } = useAuthenticationContext();
  const userInfo = userInfoObject?.data;  
  const measurementSystem = userInfo?.measurementSystem;
  const { getDiagnosticsByHeatId, getDiagnosticsByDraftId, getDiagnosticsByCalculationId } = useHeatsService();

  const [shouldShowDiagnostics] = useState((result?.completedWithWarnings || false) && hasAlphaFeaturesPermission(userInfo));
  const [calculationDiagnosticsModalVisible, setCalculationDiagnosticsModalVisible] = useState(false);
  const [calculationDiagnosticsSearchKeywords, setCalculationDiagnosticsSearchKeywords] = useState<string[]>([]);
  const [recommendations, setRecommendations] = useState<RecommendedAddition[]>([]);

  const getCalculationDiagnostics = async (): Promise<CalculationDiagnostic[]> => {
    let diagnostics: CalculationDiagnostic[] = [];

    if (shouldShowDiagnostics && outputDetails && outputDetails.calculationId) {
      diagnostics = await getDiagnosticsByCalculationId(outputDetails.calculationId);
    } else if (shouldShowDiagnostics && isNumber(id)) {
      switch (heatMode) {
        case HeatMode.Operation:
          diagnostics = await getDiagnosticsByHeatId(parseFloat(id));
          break;
        case HeatMode.Draft:
          diagnostics = await getDiagnosticsByDraftId(parseFloat(id));
          break;
      }
    }

    return diagnostics.filter(d => d.severity === DiagnosticSeverityType.Warning && d.code !== 'CompletedWithWarnings');
  }

  const [calculationDiagnosticsResult, callCalculationDiagnostics] = useCallApi(getCalculationDiagnostics, []);

  const updateStateAndDiagnosticWarnings = useCallback((recommendations: RecommendedAddition[]) => {
    if (shouldShowDiagnostics && calculationDiagnosticsResult.status === 'succeeded' && calculationDiagnosticsResult.data) {
      const fluxTypesToFilter = [FluxType.MgOOnlySource, FluxType.DolomaSource];
      const adjustedToMinimumDiagnosticCode = 'WeightAdjustedToTheMinimumRequiredValue';
      const adjustedToMaximumDiagnosticCode = 'WeightAdjustedToTheMaximumRequiredValue';
      const diagnosticCodesToFilter = [adjustedToMinimumDiagnosticCode, adjustedToMaximumDiagnosticCode];
      const diagnostics = calculationDiagnosticsResult.data.filter(diagnostic => diagnosticCodesToFilter.includes(diagnostic.code));
  
      setRecommendations(currentRecommendations => {
        const newRecommendations = [...currentRecommendations];
  
        newRecommendations.forEach(recommendation => {
          const minimumWeightMgOSource = diagnostics.find(
            diagnostic => diagnostic.code === adjustedToMinimumDiagnosticCode &&
            recommendation.type === RecommendedAdditionType.Flux && 
            recommendation.name === diagnostic.additionalInfo.fluxName &&
            fluxTypesToFilter.includes(diagnostic.additionalInfo.fluxType as FluxType));
          const maximumWeightMgOSource = diagnostics.find(
            diagnostic => diagnostic.code === adjustedToMaximumDiagnosticCode &&
            recommendation.type === RecommendedAdditionType.Flux && 
            recommendation.name === diagnostic.additionalInfo.fluxName &&
            fluxTypesToFilter.includes(diagnostic.additionalInfo.fluxType as FluxType));
          const maximumWeightSlagDeoxidizer = diagnostics.find(
            diagnostic => diagnostic.code === adjustedToMaximumDiagnosticCode &&
            recommendation.type === RecommendedAdditionType.Deoxidizer && 
            recommendation.name === diagnostic.additionalInfo.deoxidizerName);
          
          let hasWarning = false;
          const keywords: string[] = [];
  
          if (minimumWeightMgOSource) {
            hasWarning = true;
            keywords.push(
              adjustedToMinimumDiagnosticCode, 
              minimumWeightMgOSource.additionalInfo.fluxType as string,
              minimumWeightMgOSource.additionalInfo.fluxName as string);
          } else if (maximumWeightMgOSource) {
            hasWarning = true;
            keywords.push(
              adjustedToMaximumDiagnosticCode, 
              maximumWeightMgOSource.additionalInfo.fluxType as string,
              maximumWeightMgOSource.additionalInfo.fluxName as string);
          } else if (maximumWeightSlagDeoxidizer) {
            hasWarning = true;
            keywords.push(
              adjustedToMaximumDiagnosticCode, 
              maximumWeightSlagDeoxidizer.additionalInfo.deoxidizerName as string);
          }
  
          recommendation.hasWarning = hasWarning;
          recommendation.keywords = keywords;
        });
  
        return newRecommendations;
      }); 
    } else if (recommendations) {
      setRecommendations(recommendations);
    }
  }, [shouldShowDiagnostics, calculationDiagnosticsResult.status, calculationDiagnosticsResult.data]);

  useEffect(() => {
    const fluxesRecommendationsWithAdditionalInfo: RecommendedAddition[] = (recommendedAdditions?.fluxes || []).map(flux => ({
      ...flux,
      type: RecommendedAdditionType.Flux,
      hasWarning: false,
      keywords: [],
    }));
      
    const deoxidizersRecommendationsAdditionalInfo: RecommendedAddition[] = (recommendedAdditions?.deoxidizers || []).map(deoxidizer => ({
      ...deoxidizer,
      type: RecommendedAdditionType.Deoxidizer,
      hasWarning: false,
      keywords: [],
    }));

    const allRecommendations = [...fluxesRecommendationsWithAdditionalInfo, ...deoxidizersRecommendationsAdditionalInfo];

    updateStateAndDiagnosticWarnings(allRecommendations);
  }, [recommendedAdditions?.fluxes, recommendedAdditions?.deoxidizers, updateStateAndDiagnosticWarnings]);

  useEffect(() => {
    if (calculationDiagnosticsResult.status === 'idle') {
      callCalculationDiagnostics();
    }
  }, [calculationDiagnosticsResult.status, callCalculationDiagnostics]);

  const handleChangeRealAddedWeight = (recommendedAddition: RecommendedAddition, index: number, value: number | undefined) => {
    recommendedAddition.realAddedWeight = value;

    if (recommendedAdditions) {
      const newRecommendedAdditions = {...recommendedAdditions};

      if (recommendedAddition.type === RecommendedAdditionType.Flux && newRecommendedAdditions.fluxes) {
        newRecommendedAdditions.fluxes[index].realAddedWeight = value;
        newRecommendedAdditions.fluxes = [...newRecommendedAdditions.fluxes];
      } else if (recommendedAddition.type === RecommendedAdditionType.Deoxidizer && newRecommendedAdditions.deoxidizers) {
        newRecommendedAdditions.deoxidizers[index].realAddedWeight = value;
        newRecommendedAdditions.deoxidizers = [...newRecommendedAdditions.deoxidizers];
      }
  
      setRecommendedAdditions(newRecommendedAdditions);
      setStep5FormIsDirty();
    }
  }

  const renderRecommendationsList = (type: RecommendedAdditionType): JSX.Element => {
    let title = '';
    let subtitle = '';
    let typeDescription = '';
    const recommendationsItems = recommendations.filter(r => r.type === type);

    switch (type) {
      case RecommendedAdditionType.Flux:
        title = t('new-heat.form.title.add-flux');
        subtitle = t('new-heat.form.title.add-ladle-furnace-check');
        typeDescription = t('flux');
        break;
      case RecommendedAdditionType.Deoxidizer:
        title = t('slag-deoxidizers');
        subtitle = t('new-heat.form.title.add-ladle-furnace-check');
        typeDescription = t('slag-deoxidizer');
        break;
    }

    return (
      <FormSectionContainer
        title={title}
        subtitle={subtitle} >
        <List>
          <ListItem style={listItemStyles}>
            <ListItemText style={tableCellStyles}><Typography variant="body2">{typeDescription}</Typography></ListItemText>
            <ListItemText style={tableCellStyles}><Typography variant="body2">{t('weight')}</Typography></ListItemText>
            <ListItemText style={tableCellStyles}><Typography variant="body2">{t('new-heat.form.title.real-added-weight')}</Typography></ListItemText>
          </ListItem>
          {renderRecommendationItems(recommendationsItems)}
        </List>
      </FormSectionContainer>
    );
  }

  const renderRecommendationItems = (recommendationsItems: RecommendedAddition[]): JSX.Element[] => {
    const warningTooltipText = t('calculation-diagnostics.warning-tooltip');

    return recommendationsItems.map((row, i: number) => (
      <ListItem
        key={i}
        style={listItemStyles}>
        <ListItemText style={tableCellStyles}>
          <Box display='flex' alignItems='center' gridGap='10px'>
            <Typography variant="body2">{row.name}</Typography>
            {row.hasWarning && (
              <CustomTooltip title={warningTooltipText}>
                <IconButton 
                  style={{ padding: '0px' }}
                  onClick={() => openDiagnosticsModal(row)}>
                  <ReportProblemIcon noMargin size='24px' />
                </IconButton>
              </CustomTooltip>
            )}
          </Box>
        </ListItemText>
        <ListItemText style={tableCellStyles}>
          <Typography variant="body2">{`${row.weight.toFixed(0)} ${returnWeightUnitOfMeasurement(measurementSystem, "lb")}`}</Typography>
        </ListItemText>
        <Box justifyContent="flex-end" display="flex" flex="1">
          <NumberFormat
            autoComplete="off"
            disabled={disabled}
            value={row.realAddedWeight}
            onValueChange={e => handleChangeRealAddedWeight(row, i, e.floatValue)}
            InputProps={{
              endAdornment: renderWeightInputEndAdornment(measurementSystem, "lb", disabled)
            }}
            customInput={TextField}
            allowNegative={false}
            decimalScale={0}
            decimalSeparator={t('decimal-scale-separator')}
            isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_WEIGHT }
            error={!row.realAddedWeight && row.realAddedWeight !== 0}
          />
        </Box>
      </ListItem>
    ));
  }

  const renderFluxesRecommendations = (): JSX.Element => {
    return renderRecommendationsList(RecommendedAdditionType.Flux);
  }

  const renderDeoxidizerRecommendation = (): JSX.Element => {
    return renderRecommendationsList(RecommendedAdditionType.Deoxidizer);
  }

  const renderAdditionalData = (): JSX.Element => {
    if (!hasAlphaFeaturesPermission(userInfo)) {
      return (
        <></>
      )
    }
    
    return (
      <>
        <CustomAccordion
          style={{ maxWidth: 'min(calc(50% - 4px), 600px)' }}
          className="accordion"
          accordionSummaryTitle={t('new-heat.form.title.additional-carryoverslags-data')}
          tooltipMessage={t('new-heat.form.tooltip.title.additional-carryoverslags-data')} >
          <List>
            <ListItem style={listItemStyles}>
              <ListItemText style={tableCellStyles}><Typography variant="body2">{t('dynamic-ladle-diameter')}</Typography></ListItemText>
              <ListItemText style={tableCellStyles}>
                <Typography variant="body2">
                  {(result?.dynamicLadleDiameter === null || 
                    result?.dynamicLadleDiameter === undefined)
                    ? displayNotApplicableIfNull(result?.dynamicLadleDiameter, false)
                    : `${result?.dynamicLadleDiameter} ${returnLengthUnitOfMeasurement(measurementSystem, "m")}`
                  }
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              style={listItemStyles}>
              <ListItemText style={tableCellStyles}><Typography variant="body2">{t('new-heat.form.list-item.recalculated-carryover-slag-weight')}</Typography></ListItemText>
              <ListItemText style={tableCellStyles}>
                <Typography variant="body2">
                  {(result?.recalculatedCarryoverSlagWeight === null || 
                    result?.recalculatedCarryoverSlagWeight === undefined)
                    ? displayNotApplicableIfNull(result?.recalculatedCarryoverSlagWeight, false)
                    : `${result?.recalculatedCarryoverSlagWeight} ${returnWeightUnitOfMeasurement(measurementSystem, "lb")}`
                  }
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              style={listItemStyles}>
              <ListItemText style={tableCellStyles}><Typography variant="body2">{t('new-heat.form.list-item.carryover-slag-depth')}</Typography></ListItemText>
              <ListItemText style={tableCellStyles}>
                <Typography variant="body2">
                  {(result?.carryoverSlagDepth === null || 
                    result?.carryoverSlagDepth === undefined)
                    ? displayNotApplicableIfNull(result?.carryoverSlagDepth, false)
                    : `${result?.carryoverSlagDepth} ${returnLengthUnitOfMeasurement(measurementSystem, "m")}`
                  }
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              style={listItemStyles}>
              <ListItemText style={tableCellStyles}><Typography variant="body2">{t('new-heat.form.list-item.recalculated-initial-slag-depth')}</Typography></ListItemText>
              <ListItemText style={tableCellStyles}>
                <Typography variant="body2">
                  {(result?.recalculatedInitialSlagDepth === null || 
                    result?.recalculatedInitialSlagDepth === undefined)
                    ? displayNotApplicableIfNull(result?.recalculatedInitialSlagDepth, false)
                    : `${result?.recalculatedInitialSlagDepth} ${returnLengthUnitOfMeasurement(measurementSystem, "m")}`
                  }
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              style={listItemStyles}>
              <ListItemText style={tableCellStyles}><Typography variant="body2">{t('new-heat.form.list-item.recalculated-slag-weight-after-skimmer')}</Typography></ListItemText>
              <ListItemText style={tableCellStyles}>
                <Typography variant="body2">
                  {(result?.recalculatedSlagWeightAfterSkimmer === null || 
                    result?.recalculatedSlagWeightAfterSkimmer === undefined)
                    ? displayNotApplicableIfNull(result?.recalculatedSlagWeightAfterSkimmer, false)
                    : `${result?.recalculatedSlagWeightAfterSkimmer} ${returnWeightUnitOfMeasurement(measurementSystem, "lb")}`
                  }
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </CustomAccordion>
      </>
    );
  }

  const renderIterationChartsButton = (): JSX.Element => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'flex-end', width: 'min(calc(50% - 4px), 600px)' }}>
        <IterationChartsButton heatMode={heatMode} calculationId={outputDetails?.calculationId} />
      </Box>
    )
  }

  const renderCalculationDiagnosticsWarning = (): JSX.Element => {
    if (!shouldShowDiagnostics || !calculationDiagnosticsResult.data || calculationDiagnosticsResult.data.length === 0) {
      return (
        <></>
      );
    }

    const linkButton = {
      label: t('details'),
      onClick: openDiagnosticsModal,
    }

    return (
      <Grid item container style={{...warningMessageGridStyles}}>
        <WarningMessageComponent message={t('calculation-diagnostics.warning-text')} linkButton={linkButton} />
      </Grid>
    );
  }

  const openDiagnosticsModal = (recommendedAddition?: RecommendedAddition) => {
    setCalculationDiagnosticsModalVisible(true);

    if (recommendedAddition) {
      setCalculationDiagnosticsSearchKeywords(recommendedAddition.keywords);
    }
  }

  return (
    <Box style={contentStyles}>
      {calculationDiagnosticsModalVisible && (
        <CalculationDiagnosticsModal 
          diagnostics={calculationDiagnosticsResult.data || []} 
          searchKeywords={calculationDiagnosticsSearchKeywords}
          onClose={() => setCalculationDiagnosticsModalVisible(false)} />
      )}
      {renderCalculationDiagnosticsWarning()}
      <FormWrapper>
        {renderFluxesRecommendations()}
        {renderDeoxidizerRecommendation()}
      </FormWrapper>
      <Box style={{ display: 'flex', flex: 1, width: '100%', maxWidth: '1208px', gap: '8px', margin: '0 auto' }} >
        {renderAdditionalData()}
        {renderIterationChartsButton()}
      </Box>
    </Box>
  );
}

export default Step5;
