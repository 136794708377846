import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, List, ListItem, ListItemText, TextField, makeStyles, useTheme } from "@material-ui/core";
import { Dispatch, ReactNode, SetStateAction } from "react";
import FormSectionContainer from "./FormSectionContainer";
import { useTranslation } from "react-i18next";
import { MAX_WEIGHT } from "@app/utils/constants";
import NumberFormat from "react-number-format";
import FormSelect, { FormSelectOptions } from "./FormSelect";
import { HeatChargeWeightArray } from "@app/api/heats/HeatsResponse";
import { AlloyListStateDTO, DeoxidizersModel, FluxModel } from "@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes";
import { ReportProblemIcon } from "@app/assets/icons/reportProblemIcon";
import { sortArrayByNameProperty } from '@app/utils/sortObjectArrayByName';
import FormButton from "./FormButton";
import useRenderInputEndAdornment from "@app/hooks/useRenderInputEndAdornment";
import { useAuthenticationContext } from "@app/store/authentication/authenticationContext";
import useReturnUnitOfMeasurement from "@app/hooks/useReturnUnitOfMeasurement";
import { DeleteButton, EditButton } from "./CustomIconButton";

interface AdditionManagerComponentProps<T extends number | boolean | string> {
    title: string;
    dialogIsVisible: boolean;
    setDialogIsVisible: Dispatch<SetStateAction<boolean>>;
    dialogTitle: string;
    newAdditionWeight: number | undefined;
    setNewAdditionWeight: (value: number) => void;
    handleEditAdditionWeight: () => void;
    disabled: boolean;
    additionSelectOptionList: FormSelectOptions<T>;
    additionName: string;
    setAdditionName: Dispatch<SetStateAction<string>>;
    additionWeight: string | number | undefined;
    setAdditionWeight: Dispatch<SetStateAction<number | string | undefined>>;
    additionSelectLabel: string;
    handleAdd: () => void;
    isAdditionDisabled: boolean;
    listTitle: string;
    fixedAdditionsList?: HeatChargeWeightArray;
    chargeAdditionWeightList: HeatChargeWeightArray;
    plantIsIncompatible: boolean;
    additionList: Array<FluxModel | AlloyListStateDTO | DeoxidizersModel>;
    handleEditAddition: (addition: { name: string; weight: number }) => void;
    handleDeleteAddition: (addition: { name: string; weight: number }) => void;
    children?: ReactNode;
    style?: React.CSSProperties;
}

const AdditionManagerComponent = <T extends number | boolean | string>({
    title,
    dialogIsVisible,
    setDialogIsVisible,
    dialogTitle,
    newAdditionWeight,
    setNewAdditionWeight,
    handleEditAdditionWeight,
    disabled,
    additionSelectOptionList,
    additionName,
    setAdditionName,
    additionWeight,
    setAdditionWeight,
    additionSelectLabel,
    handleAdd,
    isAdditionDisabled,
    listTitle,
    fixedAdditionsList,
    chargeAdditionWeightList,
    plantIsIncompatible,
    additionList,
    handleEditAddition,
    handleDeleteAddition,
    children,
    style
}: AdditionManagerComponentProps<T>) => {
    const useStyles = makeStyles(() => ({
        line: {
          display: 'flex',
          gap: '4px',
          fontSize: '12px',
        },
        firstColumn: {
          display: 'flex',
          flex: 2,
          maxWidth: 'calc(100% - 124px)',
        },
        secondColumn: {
            display: 'flex',
            flex: 1,
            maxWidth: '60px',
          },
          thirdColumn: {
            display: 'flex',
            justifyContent: 'flex-end',
            flex: 1,
            maxWidth: '56px'
        }
      }));


    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();


    const formWrapper: React.CSSProperties = {
        display: 'flex',
        flexDirection: "row",
        gap: '8px',
        alignItems: 'flex-end'
    }

    const { renderWeightInputEndAdornment } = useRenderInputEndAdornment();
    const { returnWeightUnitOfMeasurement } = useReturnUnitOfMeasurement();
    const { state: { userInfoObject } } = useAuthenticationContext();

    const measurementSystem = userInfoObject?.data?.measurementSystem;

    return(
      <FormSectionContainer title={title} style={style}>
        <Dialog
          open={dialogIsVisible}
          onClose={() => setDialogIsVisible(false)}
          aria-labelledby={`alert-dialog-${title}-title`}
          aria-describedby={`alert-dialog-${title}-description`}
        >
          <DialogTitle
            id={`alert-dialog-${title}-title`}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <NumberFormat
                  name={`new${title}Weight`}
                  label={t('weight')}
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  value={newAdditionWeight}
                  onValueChange={e => setNewAdditionWeight(Number(e.value))}
                  customInput={TextField}
                  allowNegative={false}
                  decimalScale={0}
                  decimalSeparator={t('decimal-scale-separator')}
                  isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_WEIGHT }
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
              <FormButton variant="secondary" onClick={() => setDialogIsVisible(false)}>
                  {t('button.cancel')}
              </FormButton>
              <FormButton variant="primary" autoFocus onClick={() => handleEditAdditionWeight()}>
                  {t('button.edit')}
              </FormButton>
          </DialogActions>
        </Dialog>
          {!disabled && (
            <>
              <Box style={{ width: '100%', maxWidth: 'calc(100% - 0.1px)' }}>
                <FormSelect
                  fullWidth
                  id={title}
                  name={title}
                  options={additionSelectOptionList}
                  value={additionName}
                  onChange={e => setAdditionName(e.target.value as string)}
                  label={additionSelectLabel}
                />
              </Box>
              <Box style={formWrapper}>
                <FormControl fullWidth>
                  <NumberFormat
                    id={`${title}-weight`}
                    name={`${title}-weight`}
                    fullWidth
                    label={t('weight')}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ endAdornment: renderWeightInputEndAdornment(measurementSystem, "lb", disabled) }}
                    value={additionWeight}
                    autoComplete="off"
                    onValueChange={e => setAdditionWeight(e.floatValue)}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={0}
                    decimalSeparator={t('decimal-scale-separator')}
                    isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_WEIGHT }
                  />
                </FormControl>
                <FormButton
                    variant="primary"
                    onClick={() => handleAdd()}
                    disabled={isAdditionDisabled}
                    size="small"
                  >
                    {t('button.add')}
                  </FormButton>
              </Box>
            </>
          )}
        <List>
          <ListItem key={`${title}-list-header`} style={{ background: 'white', border: '1px solid #ccc' }} className={classes.line}>
            <ListItemText className={classes.firstColumn}>{listTitle}</ListItemText>
            <ListItemText style={{ textAlign: 'left' }} className={classes.secondColumn}>
              {t('weight')}
            </ListItemText>
            <ListItemText className={classes.thirdColumn}></ListItemText>
          </ListItem>
          {fixedAdditionsList?.map(addition => (
            <ListItem key={addition.name} style={{ background: 'white', border: '1px solid #ccc' }}  className={classes.line}>
              <ListItemText className={classes.firstColumn}>{addition.name}</ListItemText>

              <ListItemText style={{ textAlign: 'left' }} className={classes.secondColumn}>{`${Math.round(addition.weight)} ${returnWeightUnitOfMeasurement(measurementSystem, 'lb')}`}</ListItemText>
              <ListItemText className={classes.thirdColumn}></ListItemText>
            </ListItem>
          ))}
          {chargeAdditionWeightList.sort(sortArrayByNameProperty).map(addition => {
            const additionIsIncompatibleWithCurrentPlantSetupConfiguration = plantIsIncompatible && !additionList.some(additionItem => additionItem.name === addition.name);
            return (
              <ListItem
                key={addition.name}
                style={
                  !additionIsIncompatibleWithCurrentPlantSetupConfiguration
                  ? { background: 'white', border: '1px solid #ccc' }
                  : {
                    background: 'white',
                    color: theme.palette.text.disabled,
                    border: `2px solid ${theme.palette.warning.main}`,

                  }
                }
                className={classes.line}
              >
                <ListItemText className={classes.firstColumn}>{addition.name}</ListItemText>

                <ListItemText style={{ textAlign: 'left' }} className={classes.secondColumn}>{`${Math.round(addition.weight)} ${returnWeightUnitOfMeasurement(measurementSystem, 'lb')}`}</ListItemText>
                <ListItemText className={classes.thirdColumn}>
                  {!disabled && (
                    <Box style={{ display: 'flex', gap: '8px' }}>
                      {!additionIsIncompatibleWithCurrentPlantSetupConfiguration ? (
                        <EditButton 
                          onClick={() => handleEditAddition(addition)} 
                          aria-label="edit" 
                        />
                      ) : (
                          <ReportProblemIcon noMargin size="24px" />
                      )}
                      <DeleteButton
                        onClick={() => handleDeleteAddition(addition)}
                        aria-label="delete"
                        color="error"
                      />
                    </Box>
                  )}
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
        {children}
      </FormSectionContainer>
    )
}

export default AdditionManagerComponent;
