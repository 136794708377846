export enum PlantEvent
{
    BLOCK = 'Block',
    UNBLOCK = 'Unblock',
    UPDATE = 'Update'
}

export interface PlantActivityResponse {
  id: string;
  reason: string;
  event: PlantEvent;
  createdDate: string;
  createdBy: string;
}