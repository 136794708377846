import FormRadioButton from '@app/components/shared/FormRadioGroup';
import FormSectionContainer from '@app/components/shared/FormSectionContainer';
import FormWrapper from '@app/components/shared/FormWrapper';
import { Box, TextField, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';

interface Step7Props {
  disabled?: boolean;
  slagAppearance: string;
  setSlagAppearance: React.Dispatch<React.SetStateAction<string>>;
  slagAppearanceText: string;
  setSlagAppearanceText: React.Dispatch<React.SetStateAction<string>>;
  isDraftMode?: boolean;
}

function Step7({
  disabled = false,
  slagAppearance,
  setSlagAppearance,
  slagAppearanceText,
  setSlagAppearanceText,
  isDraftMode = false,
}: Step7Props) {
  const { t } = useTranslation();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px'
      }}
    >
      {!disabled && !isDraftMode && (
        <Typography
          style={{
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto'
          }}
        >
          {t('new-heat.form.warning.invalid-form')}
        </Typography>
      )}
      <FormWrapper>
        <FormSectionContainer title={t('feedback')}>
            <Box>
              <FormRadioButton
                id="slagAppearance"
                label={`${t('new-heat.form.feedback.slagAppearance.title')} ${!disabled ? '*' : ''}`}
                value={slagAppearance ?? ''}
                disabled={disabled}
                onChange={selectedValue => setSlagAppearance(selectedValue)}
                options={[
                  { value: 'crusty', label: t('new-heat.form.feedback.slagAppearance.crusty') },
                  { value: 'creamy', label: t('new-heat.form.feedback.slagAppearance.creamy') },
                  { value: 'fluid', label: t('new-heat.form.feedback.slagAppearance.fluid') },
                ]}
              />
            </Box>
            <FormControl fullWidth>
              <TextField
                name="slagAppearanceText"
                label={t('new-heat.form.feedback.slagAppearance.others.title')}
                autoComplete="off"
                variant="outlined"
                minRows="4"
                multiline
                disabled={disabled}
                value={slagAppearanceText}
                onChange={e => setSlagAppearanceText(e.target.value)}
              />
            </FormControl>
        </FormSectionContainer>
      </FormWrapper>
    </Box>
  );
}

export default Step7;
