import { CarryoverSlagReferenceGetResponse, useCarryoverSlagReferencesService } from '@app/api/carryoverSlagReferences';
import { useProcessConstraintsService } from '@app/api/processConstraints';
import { GeneralSettings, generalSettingsInitialState } from '@app/pages/CarryoverSlags/carryoverSlagsUtils';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { clearLocationState } from '@app/utils/clearLocationState';
import { CARRYOVERSLAGS } from '@app/utils/plantSetupRoutesPathTabs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ListInfoObject } from "./types"
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import { CarryoverSlagType } from '@app/api/heats/HeatsResponse';


export interface UseLoadCarryoverSlagsInfoObjectDTO extends ListInfoObject<CarryoverSlagReferenceGetResponse> {
  generalSettings: GeneralSettings,
  openGeneralSettingsModal: boolean;
}

interface LocationState {
  option?: CARRYOVERSLAGS,
}

const useLoadCarryoverSlags = (): [
  UseLoadCarryoverSlagsInfoObjectDTO,
  () => Promise<void>,
  (value: boolean) => void,
  (value: GeneralSettings) => void,
] => {
  const location = useLocation<LocationState>();

  const infoObjectInitialState: UseLoadCarryoverSlagsInfoObjectDTO = {
    status: 'idle',
    data: [],
    generalSettings: generalSettingsInitialState,
    openGeneralSettingsModal: false, 
  }
  
  const { getCarryoverSlagReferences } = useCarryoverSlagReferencesService();
  const { getProcessConstraints } = useProcessConstraintsService();

  const {
    state: { userInfoObject },
  } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const [infoObject, setInfoObject] = useState<UseLoadCarryoverSlagsInfoObjectDTO>(infoObjectInitialState);

  useEffect(() => {
    if (location.state?.option !== undefined && infoObject.status === 'succeeded') {
      clearLocationState();
    }
  }, [location.state, infoObject.status])

  const isCarryoverSlagInferenceTypeSteelGrade = userInfo?.selectedPlant.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory;

  const loadCarryoverSlags = async () => {
    try {
      const isInitialLoad = infoObject.status === 'idle';
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const carryoverSlagsReferences = await getCarryoverSlagReferences();
      const processConstraints = await getProcessConstraints();

      const newGeneralSettings: GeneralSettings = {
        carryoverSlagParameterValueName: userInfo?.selectedPlant.carryoverSlagParameterValueName,
        carryoverSlagReferenceInput: userInfo?.selectedPlant.carryoverSlagParameterValueName,
        carryoverSlagUnitOfMeasurement: userInfo?.selectedPlant.carryoverSlagUnitOfMeasurement,
        carryoverSlagInferenceType: userInfo?.selectedPlant.carryoverSlagInferenceType,
        carryoverSlagType: processConstraints?.carryoverSlagType || CarryoverSlagType.Weight,
        minimumSlagCarryoverWeight: processConstraints?.minimumSlagCarryoverWeight,
      };

      setInfoObject(prevState => ({ 
        ...prevState, 
        data: carryoverSlagsReferences, 
        status: 'succeeded',
        generalSettings: newGeneralSettings,
        openGeneralSettingsModal: 
          (
            isInitialLoad 
            && location.state?.option === CARRYOVERSLAGS.INFERENCE
          ) 
          || Object.values({
            ...isCarryoverSlagInferenceTypeSteelGrade 
            ? {
              carryoverSlagInferenceType: newGeneralSettings.carryoverSlagInferenceType,
              carryoverSlagParameterValueName: newGeneralSettings.carryoverSlagParameterValueName,
              carryoverSlagReferenceInput: newGeneralSettings.carryoverSlagReferenceInput,
              carryoverSlagType: newGeneralSettings.carryoverSlagType || CarryoverSlagType.Weight,
              minimumSlagCarryoverWeight: newGeneralSettings.minimumSlagCarryoverWeight
            } as GeneralSettings : newGeneralSettings
          }).some(cs => cs === null),
      }));
    } catch (err) {
      
      setInfoObject({
        ...infoObjectInitialState, 
        openGeneralSettingsModal: false, 
        status: 'error'
      });
    }
  };

  const setOpenGeneralSettingsModal = 
    (value: boolean) => setInfoObject(prevState => ({
      ...prevState,
      openGeneralSettingsModal: value,
    }));

  const setGeneralSettings = 
    (newGeneralSettings: GeneralSettings) => setInfoObject(prevState => ({
      ...prevState,
      generalSettings: newGeneralSettings,
      openGeneralSettingsModal: false,
    }));

  return [infoObject, loadCarryoverSlags, setOpenGeneralSettingsModal, setGeneralSettings];
}

export default useLoadCarryoverSlags;