import { LanguageActions } from './languageActions';
import { GenericContext } from '../genericContext';
import { LanguageState } from './languageState'
import { FALLBACK_LANGUAGE } from '@app/utils/constants';

export type LanguageDispatch = (action: LanguageAction) => void;
export const LANGUAGE_INITIAL_DISPATCH = (action: LanguageAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action.type);
};
export function LanguageReducer(state: LanguageState, action: LanguageAction): LanguageState {
  switch (action.type) {
    case LanguageActions.UPDATE_SYSTEM_LANGUAGE_ACTION: {
      return {...state, selectedLanguage: action.payload ?? FALLBACK_LANGUAGE};
    }
    case LanguageActions.LOAD_SYSTEM_LANGUAGE_ACTION: {
      return {...state, selectedLanguage: action.payload ?? FALLBACK_LANGUAGE};
    }

    default: {
      throw new Error(`Unidentified action: ${(action).type}`);
    }
  }
}

type LanguageAction =
  GenericContext<LanguageActions.UPDATE_SYSTEM_LANGUAGE_ACTION | LanguageActions.LOAD_SYSTEM_LANGUAGE_ACTION, 'en' | 'pt-BR' | 'es'>;

