import { GenericContext } from '@app/store/genericContext';
import { ArchivedHeatActions } from './archivedDraftHeatActions';
import { ArchivedDraftHeatState, ARCHIVED_DRAFT_HEAT_INITIAL_STATE } from './archivedDraftHeatState';

export type ArchivedDraftHeatDispatch = (action: ArchivedHeatAction) => void;
export const ARCHIVED_HEAT_INITIAL_DISPATCH = (action: ArchivedHeatAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action.type);
};

export function ArchivedHeatReducer(state: ArchivedDraftHeatState, action: ArchivedHeatAction): ArchivedDraftHeatState {
  const actionType = action.type
  switch (actionType) {
    case ArchivedHeatActions.SET_DRAFT_START_DATE: {
      return { ...state, startDate: action.payload || null };
    }

    case ArchivedHeatActions.SET_DRAFT_END_DATE: {
      return { ...state, endDate: action.payload || null };
    }

    case ArchivedHeatActions.RESET_DRAFT_DATE: {
      return { ...state, startDate: ARCHIVED_DRAFT_HEAT_INITIAL_STATE.startDate, endDate: ARCHIVED_DRAFT_HEAT_INITIAL_STATE.startDate };
    }

    default: {
      throw new Error(`Unidentified action: ${actionType}`);
    }
  }
}

type ArchivedHeatAction =
  | GenericContext<ArchivedHeatActions.SET_DRAFT_START_DATE, string | null>
  | GenericContext<ArchivedHeatActions.SET_DRAFT_END_DATE, string | null>
  | GenericContext<ArchivedHeatActions.RESET_DRAFT_DATE>;
