import { ReactNode, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import ConditionalTooltip from "./ConditionalTooltip";

interface CustomAccordionProps {
    accordionSummaryTitle: string;
    tooltipMessage: string;
    children: ReactNode;
    style?: React.CSSProperties;
    className?: string;
}

const CustomAccordion = (
      {
        accordionSummaryTitle,
        tooltipMessage,
        children,
        style = {},
        className
      } : CustomAccordionProps
    ) => {
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    const theme = useTheme();

    return (
      <Accordion
        className={className}
        style={{
          backgroundColor: theme.palette.custom.formBackground.main,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'initial',
          padding: '16px',
          ...style
        }}
        square
        expanded={isAccordionExpanded}
        onChange={() => setIsAccordionExpanded(lastState => !lastState)}
      >
          <ConditionalTooltip condition={!isAccordionExpanded} message={tooltipMessage} messagePlacement="right">
            <AccordionSummary
              style={{ margin: 'none', height: 'fit-content' }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="accordion-content"
              id="accordion-header"
            >
              <div style={{ margin: 0, width: '100%' }}>
                <Typography variant='body2' style={{ textAlign: 'center', fontWeight: 'bold' }}>{accordionSummaryTitle}</Typography>
              </div>
            </AccordionSummary>
          </ConditionalTooltip>
        <AccordionDetails
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '16px'
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    )
}

export default CustomAccordion;
