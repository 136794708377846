import { FluxCategoryParameters, useFluxesService } from '@app/api/fluxes';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import { DeleteButton, EditButton } from '@app/components/shared/CustomIconButton';
import Loading from '@app/components/shared/Loading';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { APP_TITLE, PERMISSIONS, PAGINATION, APP_NA2O_K2O_ENABLED } from '@app/utils/constants';
import { text2Subscript, handleSendEvent } from '@app/utils';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TablePagination, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { withStyles } from "@material-ui/core/styles";
import MuiTableHead from "@material-ui/core/TableHead";
import TableNoResults from '@app/components/shared/TableNoResults';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import { mapAvailabilityKeyToTranslationKey, mapTypeKeyToTranslationKey } from '@app/utils/apiKeyToTranslationKey';
import { FluxAvailability, FluxModel, FluxType } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { ListInfoObject } from '@app/hooks/types';
import useScrollTop from '@app/hooks/useScrollTop';

export interface FluxesTabProps {
  handleEdit: (id: number) => void,
  fluxesInfoObject: ListInfoObject<FluxModel>;
  loadFluxes: () => void;
  availabilities: FluxCategoryParameters[];
  types: FluxCategoryParameters[];
}

const FluxesTab = (props: FluxesTabProps): React.ReactElement => {
  const { handleEdit, fluxesInfoObject, loadFluxes, availabilities, types } = props;

  const { data, status: fluxesStatus } = fluxesInfoObject;

  const { t } = useTranslation();
  const { deleteFlux } = useFluxesService();
  const { loadPlantValidations } = usePlantContext();

  const { hasAccess } = useAuthenticationContext();
  const plantId = useAuthenticationContext().state.userInfoObject?.data?.selectedPlant?.id;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE_EXTENDED);

  const [loaded, setLoaded] = useState<boolean>(true);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState<{ id: number, name: string } | undefined>(undefined);

  const TableHead = withStyles(() => ({
    root: {
      background: '#f1f1f1'
    }
  }))(MuiTableHead);

  useEffect(() => {
    if(fluxesStatus === 'idle')
      loadFluxes();
  }, [fluxesStatus, loadFluxes]);

  const onClickDelete = (id: number, name: string) => {
    handleSendEvent({
      category: 'Additions/Fluxes',
      action: 'User Opened Delete Flux Modal.'
    });

    const product = { id: id, name: name };
    setDeleteProduct(product);
    setOpenDeleteModal(true);
  };

  const onSubmitDelete = async () => {

    handleSendEvent({
      category: 'Additions/Fluxes',
      action: 'User Deleted a Flux.'
    });

    if (!deleteProduct) return;

    try {
      setLoaded(false);

      await deleteFlux(deleteProduct.id);
      await loadPlantValidations(plantId ?? '');

      setOpenDeleteModal(false);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('flux.api.title.success-delete'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setPage(0);
      loadFluxes();
    } finally {
      setLoaded(true);
    }
  };

  const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop<HTMLDivElement>();

  return (
    <>
      <Helmet>
        <title>
          {t('fluxes')} | {APP_TITLE}
        </title>
      </Helmet>

      <Loading dataTestId='fluxes-tab-circular-progress' promiseInProgress={!loaded || fluxesStatus === 'loading'} />

      <ConfirmDialog
        visible={openDeleteModal}
        setVisible={setOpenDeleteModal}
        title={t('confirmation')}
        content={t('flux.dialog.content', { name: deleteProduct?.name })}
        cancelText={t('button.cancel')}
        cancelButtonTestId="delete-flux-dialog.cancel-button"
        confirmButtonTestId="delete-flux-dialog.confirm-button"
        cancelAction={() => {
          handleSendEvent({
            category: 'Additions/Fluxes',
            action: 'User Closed Delete Flux Modal.'
          });
          setOpenDeleteModal(false);
        }}
        confirmText={t('button.delete')}
        confirmAction={onSubmitDelete}
      />

      <Paper style={{ maxHeight: '96%', overflow: 'auto' }}>
        <TableContainer style={{ maxHeight: 'calc(100vh - 334px)' }} innerRef={tableContainerRef}>
          <Table stickyHeader size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {t('name')}
                </TableCell>
                <TableCell align="left">
                  MgO
                </TableCell>
                <TableCell align="left">
                  CaO
                </TableCell>
                <TableCell align="left">
                  {text2Subscript('Al2O3')}
                </TableCell>
                <TableCell align="left">
                  {text2Subscript('SiO2')}
                </TableCell>
                <TableCell align="left">
                  FeO
                </TableCell>
                <TableCell align="left">
                  MnO
                </TableCell>
                <TableCell align="left">
                  {text2Subscript('Cr2O3')}
                </TableCell>
                <TableCell align="left">
                  {text2Subscript('TiO2')}
                </TableCell>
                <TableCell align="left">
                  {text2Subscript('CaF2')}
                </TableCell>
                {APP_NA2O_K2O_ENABLED && (
                  <>
                    <TableCell align="left">
                      {text2Subscript('Na2O')}
                    </TableCell>
                    <TableCell align="left">
                      {text2Subscript('K2O')}
                    </TableCell>
                  </>
                )}
                <TableCell align="left">
                  Al
                </TableCell>
                <TableCell align="left">
                  {t('flux.table.head.availability')}
                </TableCell>
                <TableCell align="left">
                  {t('flux.table.head.type')}
                </TableCell>
                <TableCell align="center" width="0"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="flux-list-table-body">
              {data.length ? (
                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                  <TableRow key={i} data-testid={`flux-list-item-${row.name}-${row.id}`}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{Number(row.mgO).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.caO).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.al2O3).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.siO2).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.feO).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.mnO).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.cr2O3).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.tiO2).toFixed(2)}</TableCell>
                    <TableCell align="left">{Number(row.caF2).toFixed(2)}</TableCell>
                    {APP_NA2O_K2O_ENABLED && (
                      <>
                        <TableCell align="left">{Number(row.na2O).toFixed(2)}</TableCell>
                        <TableCell align="left">{Number(row.k2O).toFixed(2)}</TableCell>
                      </>
                    )}
                    <TableCell align="left">{Number(row.al).toFixed(2)}</TableCell>
                    <TableCell align="left">{availabilities.filter(flux => flux.key === row.availability).map(flux => t(mapAvailabilityKeyToTranslationKey(flux.key as FluxAvailability)))}</TableCell>
                    <TableCell align="left">{types.filter(flux => flux.key === row.type).map(flux => t(mapTypeKeyToTranslationKey(flux.key as FluxType)))}</TableCell>
                    <TableCell align="right">
                      <Box style={{ display: 'flex', columnGap: "10px" }}>
                        {hasAccess([PERMISSIONS.FLUXES_EDIT]) && <EditButton onClick={() => handleEdit(Number(row.id))} data-testid={`flux-edit-button-${row.name}-${row.id}`} />}
                        {hasAccess([PERMISSIONS.FLUXES_DELETE]) && <DeleteButton onClick={() => onClickDelete(Number(row.id), row.name)} data-testid={`flux-delete-button-${row.name}-${row.id}`} />}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow data-testid="no-fluxes-to-display-warning">
                  <TableCell colSpan={16}>
                    <TableNoResults firstText='flux.empty-table-first-text' secondText='flux.empty-table-second-text' />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            scrollTop();
          }}
          onRowsPerPageChange={e => {
            setPage(0);
            setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE_EXTENDED));
          }}
          labelRowsPerPage={t('rows-per-page')}
          backIconButtonText={t('page-previous')}
          nextIconButtonText={t('page-next')}
        />

      </Paper>
    </>
  );
}

export default FluxesTab;
