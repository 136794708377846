import { ConfirmRedirectState } from './confirmRedirectState';
import { ConfirmRedirectActions } from './confirmRedirectActions';
import { GenericContext } from '../genericContext';

export type PlantDispatch = (action: PlantValidationAction) => void;
export const CONFIRM_REDIRECT_INITIAL_DISPATCH = (action: PlantValidationAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action.type);
};
export function PlantValidationReducer(state: ConfirmRedirectState, action: PlantValidationAction): ConfirmRedirectState {
  const actionType = action.type
  switch (actionType) {
    case ConfirmRedirectActions.UPDATE_CONFIRM_REDIRECT_STATE: {
      return { 
        ...state, 
        userConfirmedRedirect: action.payload 
      };
    }

    case ConfirmRedirectActions.UPDATE_SHOULD_DISPLAY_PROMPT_STATE: {
      return {
        ... state,
        shouldDisplayPrompt: action.payload,
      };
    }

    default: {
      throw new Error(`Unidentified action: ${actionType}`);
    }
  }
}

type PlantValidationAction =
GenericContext<ConfirmRedirectActions, boolean> 

