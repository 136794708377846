import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import WarningBox from './WarningBox';
import ConditionalTooltip from './ConditionalTooltip';
import React, { ForwardedRef } from 'react';

export type FormSelectOptions<T> = Array<{
  value: T;
  name: string;
  tooltip?: string;
  disabled?: boolean;
}>

interface FormSelectProps<T> extends SelectProps {
  options: FormSelectOptions<T>;
  errorMessage?: string;
  id: string;
  hasTooltip?: boolean;
  emptyOption?: boolean;
  withProblemToReport?: boolean;
}

const FormSelect = <T extends number | boolean | string>(props: FormSelectProps<T>, ref: ForwardedRef<HTMLSelectElement>) => {
  const { t } = useTranslation();

  const { 
    label, 
    error, 
    options, 
    id, 
    errorMessage, 
    hasTooltip = false, 
    emptyOption, 
    withProblemToReport = false,
    ...rest 
  } = props;

  return (
    <WarningBox style={{...rest.fullWidth ? { width: '100%', maxWidth: 'calc(100% - 0.01px)' } : { width: 'initial' }}} withProblemToReport={withProblemToReport}>
      <FormControl variant="standard" fullWidth error={error}>
        {label && <InputLabel disabled={rest?.disabled} htmlFor={id} shrink>{label}</InputLabel>}
          <Select 
            ref={ref}
            id={id} 
            name={rest?.name ? rest?.name : id}
            label={label} 
            fullWidth
            className={rest.className ? `formSelectWrapper ${rest.className}`: 'formSelectWrapper'} 
            {...rest} 
            value={options.length > 0 ? rest.value : ''}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            {emptyOption && <MenuItem value="none">{t('new-heat.form.text-field.none')}</MenuItem>}
            {options.map(u => {
              return (
                  <MenuItem key={u.value as string} value={u.value as string} disabled={u.disabled}>
                    <ConditionalTooltip condition={hasTooltip && u.tooltip !== undefined} message={u.tooltip ?? ''}>
                      <>{u.name}</>
                    </ConditionalTooltip>
                  </MenuItem>
              );
            })}
          </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </WarningBox>
  );
};

const ForwaredFormSelect = React.forwardRef(FormSelect);

export default ForwaredFormSelect;
