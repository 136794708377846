export enum EQUIPMENT {
  LADLE = 'LADLE',
  LADLE_FURNACE = 'LADLEFURNACE',
}

export enum ADDITIONS {
  FLUXES = 'FLUXES',
  ALLOYS = 'ALLOYS',
  SLAG_DEOXIDIZERS = 'SLAG_DEOXIDIZERS',
}

export enum CARRYOVERSLAGS {
  INFERENCE = 'INFERENCE',
  COMPOSITION = 'COMPOSITION'
}

export type PlantSetupRoutesTabs = EQUIPMENT | ADDITIONS | CARRYOVERSLAGS | undefined;
