import { parseHeatNameValidationsPayload } from '@app/utils/parseHeatNameValidationsPayload';
import { AddAndUpdateAnalyzedSlag, GetHeatsRequest, GetDraftsRequest, HeatsResponse, HeatsRequest, SaveHeatRequestPayload, SaveHeatResponsePayload } from '.';
import { useApi } from '..';
import { CalculateDraftHeatRequestPayload, CalculateHeatRequestPayload, CalculationDiagnostic, DraftHeatsResponse, DuplicateHeatRequestPayload, IterationChartRegistrationResponse, IterationChartRequest, IterationChartResponse, IterationChartType, SaveDraftRequestPayload } from './HeatsResponse';
import { CalculationMode } from '@app/utils/calculationModeOptions';

export type GetHeatsResponseDTO = {
  items: HeatsResponse[];
  total: number;
  page: number | null;
}

export type GetDraftHeatsResponseDTO = {
  items: Array<DraftHeatsResponse>;
  total: number;
  page: number | null;
}

export interface ExistsHeatRequestDTO {
  heatName: string;
  calculationMode: CalculationMode;
}

export interface ExistsHeatResponseDTO {
  canUseName: boolean;
  canChangeLadle: boolean;
  ladleName: number;
  ladleLife: number;
  isNewCampaign: boolean;
}

export function useHeatsService() {
  const { get, post, patch, put, remove } = useApi();
  const baseApiV2 = `/v2/heats`;

  const getHeats = async (request: GetHeatsRequest) => {
    const { data } = await get<GetHeatsResponseDTO>(`${baseApiV2}`, { params: request });

    return data;
  };
  
  const deleteDraft = async (heatDraftId: number | string) => {
    const { data } = await remove<{}>(`${baseApiV2}/drafts/${heatDraftId}`);

    return data;
  };
  
  const getDraftHeats = async (request: GetDraftsRequest) => {
    const { data } = await get<GetDraftHeatsResponseDTO>(`${baseApiV2}/drafts`, { params: request });

    return data;
  };

  const getHeatById = async (id: number) => {
    const { data } = await get<HeatsResponse>(`${baseApiV2}/${id}`);

    return data;
  };

  const getDraftHeatById = async (id: number) => {
    const { data } = await get<DraftHeatsResponse>(`${baseApiV2}/drafts/${id}`);

    return data;
  };

  const verifyIfHeatNameExists = async (props: ExistsHeatRequestDTO) => {
    const { calculationMode, heatName } = props;
    const { data } = await get<ExistsHeatResponseDTO>(`${baseApiV2}/exists?heatName=${heatName}&calculationMode=${calculationMode}`);

    return parseHeatNameValidationsPayload(data);
  };

  const postHeat = async (request: SaveHeatRequestPayload) => {
    const { data } = await post<SaveHeatResponsePayload>(`${baseApiV2}`, request);

    return data;
  };

  const postDraft = async (request: SaveDraftRequestPayload) => {
      const { data } = await post<SaveHeatResponsePayload>(`${baseApiV2}/drafts`, request);

      return data;
  };

  const putDraft = async (request: SaveDraftRequestPayload, heatDraftId: number | string) => {
      const { data } = await put<SaveHeatResponsePayload>(`${baseApiV2}/drafts/${heatDraftId}`, request);
      
      return data;
  };

  const duplicateDraftHeat = async (request: DuplicateHeatRequestPayload) => {
    const { data } = await post<DraftHeatsResponse>(`${baseApiV2}/drafts/${request.heatId}/copy`, { heatDescription: request.description });

    return data;
  }

  const duplicateHeat = async (request: DuplicateHeatRequestPayload) => {
    const { data } = await post<DraftHeatsResponse>(`${baseApiV2}/${request.heatId}/copy`, { heatDescription: request.description });

    return data;
  }

  const calculateHeat = async (request: CalculateHeatRequestPayload | CalculateDraftHeatRequestPayload) => {
    const { data } = await post<SaveHeatResponsePayload>(`${baseApiV2}/calculations`, request, {
      headers: {
        'x-cache-diagnostics': 'true'
      }
    });

    return data;
  };

  const saveHeat = async (heatId: number, request: HeatsRequest) => {
    const { data } = await patch<{}>(`${baseApiV2}/${heatId}`, request);

    return data;
  };

  const postAnalyzedSlag = async (heatId: number, request: AddAndUpdateAnalyzedSlag) => {
    const { data } = await post<AddAndUpdateAnalyzedSlag>(`${baseApiV2}/${heatId}/output/realSlag`, request);

    return data;
  };

  const putAnalyzedSlag = async (heatId: number, request: AddAndUpdateAnalyzedSlag) => {
    const { data } = await put<AddAndUpdateAnalyzedSlag>(`${baseApiV2}/${heatId}/output/realSlag`, request);

    return data;
  };

  const getDiagnosticsByHeatId = async (id: number) => {
    const { data } = await get<CalculationDiagnostic[]>(`${baseApiV2}/${id}/diagnostics`);

    return data;
  };

  const getDiagnosticsByDraftId = async (id: number) => {
    const { data } = await get<CalculationDiagnostic[]>(`${baseApiV2}/drafts/${id}/diagnostics`);

    return data;
  };

  const getDiagnosticsByCalculationId = async (id: string) => {
    const { data } = await get<CalculationDiagnostic[]>(`${baseApiV2}/calculations/${id}/diagnostics`);

    return data;
  };

  const generateChartByCalculationId = async (id: string, request: IterationChartRequest) => {
    const { data } = await post<IterationChartResponse>(`${baseApiV2}/calculations/${id}/charts`, request);

    return data;
  };

  const generateChartByHeatId = async (id: number, request: IterationChartRequest) => {
    const { data } = await post<IterationChartRegistrationResponse>(`${baseApiV2}/${id}/charts`, request);

    return data;
  };

  const generateChartByDraftId = async (id: number, request: IterationChartRequest) => {
    const { data } = await post<IterationChartRegistrationResponse>(`${baseApiV2}/drafts/${id}/charts`, request);

    return data;
  };

  const getChartByHeatId = async (id: number, type?: IterationChartType, suppressChartImageData?: boolean) => {
    const { data } = await get<IterationChartResponse[]>(`${baseApiV2}/${id}/charts`, { 
      params: {
        type: type,
        suppressChartImageData: suppressChartImageData,
      } 
    });

    return data;
  };

  const getChartByDraftId = async (id: number, type?: IterationChartType, suppressChartImageData?: boolean) => {
    const { data } = await get<IterationChartResponse[]>(`${baseApiV2}/drafts/${id}/charts`, { 
      params: {
        type: type,
        suppressChartImageData: suppressChartImageData,
      } 
    });

    return data;
  };

  return {
    getHeats,
    getDraftHeats,
    getHeatById,
    duplicateDraftHeat,
    duplicateHeat,
    getDraftHeatById,
    postHeat,
    postDraft,
    putDraft,
    deleteDraft,
    calculateHeat,
    saveHeat,
    postAnalyzedSlag,
    putAnalyzedSlag,
    verifyIfHeatNameExists,
    getDiagnosticsByHeatId,
    getDiagnosticsByDraftId,
    getDiagnosticsByCalculationId,
    generateChartByCalculationId,
    generateChartByHeatId,
    generateChartByDraftId,
    getChartByHeatId,
    getChartByDraftId,
  };
}
