import { useHeatsService } from '@app/api/heats';
import { DraftHeatsResponse } from '@app/api/heats/HeatsResponse';
import { GetDraftHeatsResponseDTO } from '@app/api/heats/useHeatsService';
import { useState } from 'react';
import { InfoObject } from "./types"
import { CalculationMode } from '@app/utils/calculationModeOptions';

const useLoadDraftHeats = (): [
  InfoObject<GetDraftHeatsResponseDTO>,
  (
    startDate: string | null,
    endDate: string | null,
    page: number | null,
    rowsPerPage: number,
    heatName: string | null,
    sorting: string | null,
    calculationMode: CalculationMode | null    
  ) => Promise<void>,
  () => void,
] => {
  const initialState: InfoObject<GetDraftHeatsResponseDTO> = {
    status: "idle",
    data: {
      items: [] as DraftHeatsResponse[],
      page: null,
      total: 0
    },
  }

  const [infoObject, setInfoObject] = useState<InfoObject<GetDraftHeatsResponseDTO>>(initialState);

  const { getDraftHeats } = useHeatsService();

  const loadArchivedHeats = async (
    startDate: string | null,
    endDate: string | null,
    page: number | null,
    rowsPerPage: number,
    heatDescription: string | null,
    sorting: string | null,
    calculationMode: CalculationMode | null    
  ) => {
    try {
      setInfoObject({ ...infoObject, status: "loading" });
      const data = await getDraftHeats({ startDate, endDate, page, size: rowsPerPage, heatDescription, sorting, calculationMode });
      setInfoObject({ data, status: "succeeded" });
    } catch (err) {

      setInfoObject({ ...infoObject, status: "error" });
    }
  };

  const setIdle = () => {
    setInfoObject({ ...infoObject, status: "idle" });
  }
  return [infoObject, loadArchivedHeats, setIdle];
}

export default useLoadDraftHeats;