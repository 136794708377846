import { PlantsGetResponse, usePlantsService } from '@app/api/plants';
import { useState } from 'react';
import { InfoObject, ListInfoObject } from "./types"
import { PlantActivityResponse } from '@app/api/plants/PlantActivityResponse';

const detailsInfoObjectInitialState: InfoObject<PlantsGetResponse> = {
  status: 'idle',
  data: undefined,
}

const activitiesInfoObjectInitialState: ListInfoObject<PlantActivityResponse> = {
  status: 'idle',
  data: [],
}

const useLoadPlantById = (): [
  InfoObject<PlantsGetResponse>,
  ListInfoObject<PlantActivityResponse>,
  (id: string) => Promise<void>,
] => {

  const { getPlantById, getPlantActivitiesById } = usePlantsService();

  const [detailsInfoObject, setDetailsInfoObject] = useState<InfoObject<PlantsGetResponse>>(detailsInfoObjectInitialState);
  const [activitiesInfoObject, setActivitiesInfoObject] = useState<ListInfoObject<PlantActivityResponse>>(activitiesInfoObjectInitialState);

  const loadPlantActivities = async (id: string) => {
    try {
      setActivitiesInfoObject(prevState => ({...prevState, status: 'loading'}));

      const activities = await getPlantActivitiesById(id);

      setActivitiesInfoObject({ data: activities.reverse(), status: 'succeeded'});
    } catch (err) {

      setActivitiesInfoObject({ data: [], status: 'error'});
    }
  }

  const loadPlantById = async (id: string) => {
    try {
      setDetailsInfoObject(prevState => ({...prevState, status: 'loading'}));

      const details = await getPlantById(id);
      await loadPlantActivities(id);

      setDetailsInfoObject({ data: details, status: 'succeeded'});
    } catch (err) {

      setDetailsInfoObject({data: undefined, status: 'error'});
    }
  };

  return [detailsInfoObject, activitiesInfoObject, loadPlantById];
}

export default useLoadPlantById;
