import { FluxCategoryParameters, useFluxesService } from '@app/api/fluxes';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loading from '@app/components/shared/Loading';
import { useState, useEffect, useMemo, ChangeEvent } from 'react';
import { newFluxInitialValue } from '../additionsUtils';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { handleSendEvent } from '@app/utils';
import { FluxAvailability, FluxModel, FluxType } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import useLoadFlux from '@app/hooks/useLoadFlux';
import { APP_NA2O_K2O_ENABLED, MIN_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL, MAX_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL } from '@app/utils/constants';
import FormValidationWarning from '@app/components/shared/FormValidationWarning';
import { mapAvailabilityKeyToTranslationKey, mapTypeKeyToTranslationKey } from '@app/utils/apiKeyToTranslationKey';
import FormSelect from '@app/components/shared/FormSelect';
import FormButton from '@app/components/shared/FormButton';
import useRenderInputEndAdornment from '@app/hooks/useRenderInputEndAdornment';
import { useForm, FormProvider, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateEditFluxValidationSchema } from '@app/hooks/useCreateEditFluxValidationSchema';
import CompositionTable, { defaultComposition } from '@app/components/shared/CompositionTable';
import { numberFormatOnChangeHandler } from '@app/utils/numberFormatOnChangeHandler';
import yup from '@app/config/yup-locale';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';

interface CreateEditFluxModalProp {
  open: boolean;
  onClose: () => void;
  onSubmitChange: () => void;
  id?: number;
  availabilities: FluxCategoryParameters[];
  types: FluxCategoryParameters[];
}

const CreateEditFluxModal: React.FC<CreateEditFluxModalProp> = (props: CreateEditFluxModalProp) => {
  const { open, onClose, onSubmitChange, id, availabilities, types } = props;
  const { t } = useTranslation();
  const { saveFlux, updateFlux } = useFluxesService();
  const { loadPlantValidations } = usePlantContext();
  const userInfoObject = useAuthenticationContext().state.userInfoObject;
  const plantId = userInfoObject?.data?.selectedPlant?.id;
  const measurementSystem = userInfoObject?.data?.measurementSystem;

  const [fluxInfoObject, loadFlux] = useLoadFlux();

  const [loading, setLoading] = useState(false);
  const { objectShape } = useCreateEditFluxValidationSchema();
  const schema = yup.object().shape(objectShape);

  const methods = useForm<FluxModel>({
    defaultValues: newFluxInitialValue,
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all'
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty: formIsDirty }
  } = methods;

  const formValues = watch();
  const isFixedAddition = formValues.availability === FluxAvailability.FixedAdditionAtTapping || formValues.availability === FluxAvailability.FixedAdditionAtLadleFurnace;
  const isMgoSource = formValues.type === "MgOOnlySource" || formValues.type === "DolomaSource";

  const fluxCompositionTotal = useMemo(() => {
    return [
      formValues.mgO,
      formValues.caO,
      formValues.al2O3,
      formValues.siO2,
      formValues.feO,
      formValues.mnO,
      formValues.cr2O3,
      formValues.tiO2,
      formValues.caF2,
      ...APP_NA2O_K2O_ENABLED ? [
        formValues.na2O,
        formValues.k2O,
      ] : [],
      formValues.al,
    ].reduce((accumulator, currentValue) => accumulator + (currentValue || 0),0)
  }, [formValues]);


  const compositionTotalIsInvalid = useMemo(() => {
    return fluxCompositionTotal < 70 || fluxCompositionTotal > 105 || isNaN(fluxCompositionTotal);
  }, [fluxCompositionTotal])

  const isFluxTypeAndCompositionValid = useMemo(() => {
    const isCaOSource = formValues.type === 'CaOSource';
    const isMgOOrDolomaSource = ['MgOOnlySource', 'DolomaSource'].includes(formValues.type);

    const isGeneralFlux = !isCaOSource && !isMgOOrDolomaSource

    return isGeneralFlux
    || isCaOSource && (formValues.caO || 0) >= 50
    || isMgOOrDolomaSource && (formValues.mgO || 0) >= 20

  }, [formValues.type, formValues.mgO, formValues.caO])

  useEffect(() => {
    handleSendEvent({
      category: 'Additions/Fluxes',
      action: id ? 'User clicked in edit flux button' : 'User clicked in Add New flux button',
    });

    if(fluxInfoObject.status === 'idle' && !!id)
      loadFlux(id);
  }, [id, loadFlux, fluxInfoObject.status]);

  useEffect(() => {
    if(fluxInfoObject.data) {
      reset(fluxInfoObject.data)
    }
  }, [fluxInfoObject.data, reset])

  const submitChanges = async (data: FluxModel) => {
    handleSendEvent({
      category: 'Additions/Fluxes',
      action: 'User Saved the Flux Changes.'
    })

    try {
      setLoading(true);
      const fluxPayload = {...data};

      if (!isFixedAddition) {
        fluxPayload.fixedAdditionAmount = 0;
        fluxPayload.fixedAdditionRecovery = 0;
      }

      if (!isMgoSource) {
        fluxPayload.mgOSourceMinimumWeight = null;
        fluxPayload.mgOSourceMaximumWeight = null;
      }

      id ? await updateFlux(id, fluxPayload) : await saveFlux(fluxPayload);
      await loadPlantValidations(plantId ?? '');
      onSubmitChange();

      reset(newFluxInitialValue);
      setLoading(false);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      onClose();
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  const validationHelperText = useMemo(() => {

    let selectedTranslationKey:
      'form.error.total'
      | 'flux.form.error.total-mgo-amount'
      | 'flux.form.error.total-cao-amount';

    let finalTranslatedString;

    if(compositionTotalIsInvalid) {
      selectedTranslationKey = 'form.error.total';
      finalTranslatedString = t(selectedTranslationKey, {min: MIN_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL, max: MAX_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL});
    } else if (isMgoSource) {
      selectedTranslationKey = 'flux.form.error.total-mgo-amount'
      finalTranslatedString = t(selectedTranslationKey);
    } else {
      selectedTranslationKey = 'flux.form.error.total-cao-amount'
      finalTranslatedString = t(selectedTranslationKey);
    }

    return finalTranslatedString
  }, [
    isMgoSource,
    t,
    compositionTotalIsInvalid
  ])

  const { renderWeightInputEndAdornment } = useRenderInputEndAdornment();

  const onSubmit: SubmitHandler<FluxModel> = async (data: FluxModel) => {
    submitChanges(data);
  }

  const fluxComposition = useMemo(() => {
    return ([
      ...defaultComposition,
      { label: 'Al', inputName: 'al'}
    ])
  }, []);

  return (
    <>
      <Loading dataTestId='fluxes-modal-circular-progress' promiseInProgress={loading || fluxInfoObject.status === 'loading'} />

      <Dialog
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="add-edit-flux-modal"> {id ? t('flux.edit') : t('flux.title.add')}</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogContent>
            <Box paddingBottom={2}>
              <Box paddingBottom={2}>
                <Grid container>
                  <Grid item sm={4} md={4} lg={4} style={{ paddingRight: 16 }}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id="flux-name"
                          label={t('name')}
                          title={t('name')}
                          inputProps={{ "data-testid": "create-edit-flux-form.field.name" }}
                          autoComplete="off"
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={4} md={4} lg={4} style={{ paddingRight: 16 }}>
                    <Controller
                      name="availability"
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          className='formSelectWrapper'
                          id="flux-availability"
                          label={t('fluxAvailability')}
                          inputProps={{ "data-testid": "create-edit-flux-form.field.availability" }}
                          options={[
                            ...(availabilities.map(availability => ({value: availability.key, name: t(mapAvailabilityKeyToTranslationKey(availability.key as FluxAvailability))})))
                          ]}
                          {...field}
                          value={field.value ?? ''}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={4} md={4} lg={4} style={{ paddingRight: 16 }}>
                    <Controller
                      name='type'
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          className='formSelectWrapper'
                          id="flux-type"
                          label={t('fluxType')}
                          inputProps={{ "data-testid": "create-edit-flux-form.field.type" }}
                          options={[
                            ...(types.map(type => ({value: type.key, name: t(mapTypeKeyToTranslationKey(type.key as FluxType))})))
                          ]}
                          {...field}
                          value={field.value ?? ''}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              {
                !!errors?.name &&
                <WarningMessageComponent message={t('flux.form.error.name')} />
              }
              {
                isFixedAddition &&
                <Box paddingBottom={2}>
                  <Grid container>
                    <Grid item sm={4} md={4} lg={4} style={{ paddingRight: 16 }}>
                      <Controller
                        name="fixedAdditionRecovery"
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            id="flux-recovery"
                            label={t('flux.params.form.title.percent-recovery')}
                            title={t('flux.params.form.title.percent-recovery')}
                            autoComplete="off"
                            fullWidth
                            customInput={TextField}
                            decimalScale={2}
                            allowNegative={false}
                            error={!!errors?.fixedAdditionRecovery?.message}
                            helperText={errors?.fixedAdditionRecovery?.message}
                            decimalSeparator={t('decimal-scale-separator')}
                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              numberFormatOnChangeHandler(e, field.onChange)
                            }
                          />
                        )}
                      />

                    </Grid>
                    <Grid item sm={4} md={4} lg={4} style={{ paddingRight: 16 }}>
                      <Controller
                        name='fixedAdditionAmount'
                        control={control}
                        render={({ field }) =>(
                          <NumberFormat
                            id="constant-addition-weight"
                            label={t('flux.constant-addition-weight')}
                            title={
                              t('flux.constant-addition-weight')
                            }
                            autoComplete="off"
                            fullWidth
                            customInput={TextField}
                            decimalScale={0}
                            allowNegative={false}
                            error={!!errors?.fixedAdditionAmount?.message}
                            helperText={errors?.fixedAdditionAmount?.message}
                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= 10000}
                            decimalSeparator={t('decimal-scale-separator')}
                            InputProps={{endAdornment: renderWeightInputEndAdornment(measurementSystem, "lb")}}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              numberFormatOnChangeHandler(e, field.onChange)
                            }
                          />
                        )}
                      />

                    </Grid>
                  </Grid>
                </Box>
              }
              {
                isMgoSource &&
                <Box paddingBottom={2}>
                  <Grid container>
                    <Grid item sm={4} md={4} lg={4} style={{ paddingRight: 16 }}>
                      <Controller
                        name="mgOSourceMinimumWeight"
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            id="flux-mgo-doloma-minimum-weight-at-laddle-furnace"
                            label={t('processConstraints.text-field.label.min-amount-mgo-source')}
                            title={t('processConstraints.text-field.label.min-amount-mgo-source')}
                            autoComplete="off"
                            fullWidth
                            customInput={TextField}
                            decimalScale={0}
                            allowNegative={false}
                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= 10000}
                            error={!!errors?.mgOSourceMinimumWeight?.message}
                            helperText={errors?.mgOSourceMinimumWeight?.message}
                            decimalSeparator={t('decimal-scale-separator')}
                            InputProps={{endAdornment: renderWeightInputEndAdornment(measurementSystem, "lb")}}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              numberFormatOnChangeHandler(e, field.onChange)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={4} md={4} lg={4} style={{ paddingRight: 16 }}>
                      <Controller
                        name="mgOSourceMaximumWeight"
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            id="flux-mgo-doloma-maximum-weight-at-laddle-furnace"
                            label={t('processConstraints.text-field.label.max-amount-mgo-source')}
                            title={t('processConstraints.text-field.label.max-amount-mgo-source')}
                            autoComplete="off"
                            fullWidth
                            customInput={TextField}
                            decimalScale={0}
                            allowNegative={false}
                            error={!!errors.mgOSourceMaximumWeight?.message}
                            helperText={errors.mgOSourceMaximumWeight?.message}
                            decimalSeparator={t('decimal-scale-separator')}
                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= 10000}
                            InputProps={{endAdornment: renderWeightInputEndAdornment(measurementSystem, "lb")}}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              numberFormatOnChangeHandler(e, field.onChange)
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              }
              <Box paddingTop={2}>
              <Grid container>
                <FormProvider {...methods}>
                  <CompositionTable
                    testIdPrefix='create-edit-flux-form'
                    unitOfMeasurement="%"
                    composition={fluxComposition}
                    total={fluxCompositionTotal}
                  />
                </FormProvider>
              </Grid>
              </Box>
              {
                (
                  !isFluxTypeAndCompositionValid ||
                  compositionTotalIsInvalid
                ) && formIsDirty &&
                <Grid item xs={12}>
                  <FormValidationWarning
                    message={validationHelperText}
                  />
                </Grid>
              }
            </Box>
          </DialogContent>

          <DialogActions>
            <FormButton
              type="button"
              onClick={() => {
                reset(newFluxInitialValue);
                onClose();
              }}
              data-testid="save-or-edit-flux.button.cancel"
              variant="secondary"
            >
              {t('button.cancel')}
            </FormButton>
            <FormButton
              type="submit"
              data-testid="save-or-edit-flux.button.save"
              variant="primary"
              disabled={Object.keys(errors).length > 0 || compositionTotalIsInvalid || !isFluxTypeAndCompositionValid}
            >
              {id ? t('button.save') : t('button.add')}
            </FormButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default CreateEditFluxModal;
