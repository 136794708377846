import { AddAndUpdateAnalyzedSlag, useHeatsService } from '@app/api/heats';
import Loading from '@app/components/shared/Loading';
import MainPaper from '@app/components/shared/MainPaper';
import { handleSendEvent } from '@app/utils';
import { APP_TITLE } from '@app/utils/constants';
import { Box, Grid, Step, StepLabel, Stepper, useTheme, withStyles } from '@material-ui/core';
import { ArrowBackRounded, ArrowForwardRounded } from '@material-ui/icons';
import React, { createRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getStep, InitialState, initialState, stepsInitialState } from '../heatsUtils';
import ISelectedCarryoverSlag from './ISelectedCarryoverSlag';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';

import { calculateB2andB3, useLoadHeatInfoIntoFormStates } from '@app/hooks/useLoadHeatDataIntoFormStates'

import { LadleFurnaceModel } from "@app/api/ladleFurnaces/LadleFurnaceModel"
import { DeoxidizersModel, FluxModel } from './Steps/heatCalculationTypes';
import { CalculatedSlags, CarryoverSlagType, DeoxidizerType, HeatChargeWeightArray, HeatRecommendationsDTO, ResultDTO } from '@app/api/heats/HeatsResponse';
import { Tap } from '@app/api/ladleFurnaces';
import StepperNavigationButton from '@app/components/StepperNavigationButton';
import { SteelChemistryConfigs, SteelChemistryUnitOfMeasurement } from '@app/api/processConstraints/useProcessConstraintsService';
import useLoadProcessConstraints from '@app/hooks/useLoadProcessConstraints';
import { HeatMode } from './NewHeat';

const HeatInfo = (): React.ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const [{ data: processConstraints, status: processConstraintsStatus },loadProcessConstraints] = useLoadProcessConstraints();

  const [status, loadHeatDataIntoFormStates, heatResponseObject] = useLoadHeatInfoIntoFormStates();

  const isTappingMode = pathname.includes("tapping");

  const inputChargeChemistriesFluxes = heatResponseObject?.input.chargeChemistries.fluxes;

  const { postAnalyzedSlag, putAnalyzedSlag } = useHeatsService();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ladleFurnaceList, setLadleFurnaceList] = useState<LadleFurnaceModel[]>([]);
  const [deoxidizerList, setDeoxidizerList] = useState<DeoxidizersModel[]>([]);
  const [fluxList, setFluxList] = useState<FluxModel[]>([]);
  const [tapList, setTapList] = useState<Tap[]>([]);

  // step 1
  const MAX_CARRYOVER_SLAG_WEIGHT_OR_THICKNESS = 10000;
  const [carryoverSlagType, setCarryoverSlagType] = useState(CarryoverSlagType.Weight);
  const [isDesulfurizationEnabled, setIsDesulfurizationEnabled] = useState(false);

  // step 2
  const [alloysChargeWeightList, setAlloyChargeWeightList] = useState<HeatChargeWeightArray>([]);
  const [fluxesChargeWeightList, setFluxesChargeWeightList] = useState<HeatChargeWeightArray>([]);
  const [deoxidizersChargeWeightList, setDeoxidizersChargeWeightList] = useState<HeatChargeWeightArray>([]);

  // step 3
  const [alloysChargeWeightListAtLF, setAlloyChargeWeightListAtLF] = useState<HeatChargeWeightArray>([]);
  const [fluxesChargeWeightListAtLF, setFluxesChargeWeightListAtLF] = useState<HeatChargeWeightArray>([]);
  const [deoxidizersChargeWeightListAtLF, setDeoxidizersChargeWeightListAtLF] = useState<HeatChargeWeightArray>([]);
  const [siRecovery, setSiRecovery] = useState('');
  const [alRecovery, setAlRecovery] = useState('');
  const [mnRecovery, setMnRecovery] = useState('');
  const [crRecovery, setCrRecovery] = useState('');

  // step 4
  const [steelChemistryConfigs, setSteelChemistryConfigs] = useState<SteelChemistryConfigs>();
  const [steelChemistryPrimaryFurnaceSi, setSteelChemistryPrimaryFurnaceSi] = useState('');
  const [steelChemistryPrimaryFurnaceDissolvedAl, setSteelChemistryPrimaryFurnaceDissolvedAl] = useState('');
  const [steelChemistryPrimaryFurnaceMn, setSteelChemistryPrimaryFurnaceMn] = useState('');
  const [steelChemistryPrimaryFurnaceCr, setSteelChemistryPrimaryFurnaceCr] = useState('');
  const [steelChemistryTappingSi, setSteelChemistryTappingSi] = useState('');
  const [steelChemistryTappingDissolvedAl, setSteelChemistryTappingDissolvedAl] = useState('');
  const [steelChemistryTappingMn, setSteelChemistryTappingMn] = useState('');
  const [steelChemistryTappingCr, setSteelChemistryTappingCr] = useState('');
  const [selectedCarryoverSlag, setSelectedCarryoverSlag] = useState<ISelectedCarryoverSlag | undefined>(undefined);
  const [fluxSelection1, setFluxSelection1] = useState('');
  const [deoxidizerSelection, setDeoxidizerSelection] = useState<DeoxidizerType | ''>('');
  const [useR2O3Target, setUseR2O3Target] = useState(false);
  const [r2O3Target, setR2O3Target] = useState('');

  // step 5
  const [heatId, setHeatId] = useState<number | undefined>(undefined);
  const [recommendations, setRecommendations] = useState<HeatRecommendationsDTO | undefined>();
  const [result, setResult] = useState<ResultDTO>();
  const [isManganeseEnabled, setIsManganeseEnabled] = useState(true);
  const [isChromiumEnabled, setIsChromiumEnabled] = useState(true);

  const [recommendedAdditions, setRecommendedAdditions] = useState<HeatRecommendationsDTO | undefined>();

  // step 6
  const [calculatedSlags, setCalculatedSlags] = useState<CalculatedSlags | undefined>(undefined);
  const [analyzedSlag, setAnalyzedSlag] = useState<AddAndUpdateAnalyzedSlag | null | undefined>({
    mgO: 0,
    caO: 0,
    al2O3: 0,
    siO2: 0,
    feO: 0,
    mnO: 0,
    cr2O3: 0,
    tiO2: 0,
    caF2: 0,
    na2O: 0,
    k2O: 0
  });

  // step 7
  const [slagAppearance, setSlagAppearance] = useState('');
  const [slagAppearanceText, setSlagAppearanceText] = useState('');

  const stepsList = React.useMemo(
    () => isTappingMode ? [
      t('general-information'),
      t('new-heat.title.step-2'),
      t('new-heat.title.step-4'),
      t('new-heat.title.step-5'),
      t('calculated-slags'),
      t('feedback'),
    ] : [
      t('general-information'),
      t('new-heat.title.step-2'),
      t('new-heat.title.step-3'),
      t('new-heat.title.step-4'),
      t('new-heat.title.step-5'),
      t('calculated-slags'),
      t('feedback'),
    ],
    [t, isTappingMode],
  );

  const [heatCalculation, setHeatCalculation] = useState<InitialState>(initialState);

  function renderSteps() {
    switch (step) {
      case 0:
        return (
          <Step1
            isDesulfurizationEnabled={isDesulfurizationEnabled}
            setIsDesulfurizationEnabled={setIsDesulfurizationEnabled}
            steelGradeList={[]}
            carryoverSlagReferenceList={[]}
            selectedCarryoverSlag={selectedCarryoverSlag}
            setSelectedCarryoverSlag={setSelectedCarryoverSlag}
            isTappingMode={isTappingMode}
            selectedLadleFurnace={
              ladleFurnaceList.find(
                ladleItem =>
                  ladleItem.name === heatCalculation?.ladleFurnace
              )
            }
            heatMode={heatCalculation?.heatName ? HeatMode.Operation : HeatMode.Draft}
            disabled
            heatCalculation={getStep('step1', heatCalculation) as typeof stepsInitialState['step1']}
            tapList={tapList}
            ladleFurnaceList={ladleFurnaceList}
            carryoverSlagType={carryoverSlagType}
            maxCarryoverSlagWeightOrThickness={MAX_CARRYOVER_SLAG_WEIGHT_OR_THICKNESS}
            heatResponse={heatResponseObject}
          />
        );

      case 1:
        return (
          <Step2
            disabled
            alloyList={[]}
            fluxList={[]}
            deoxidizerList={deoxidizerList}
            alloysChargeWeightList={alloysChargeWeightList}
            setAlloyChargeWeightList={setAlloyChargeWeightList}
            fluxesChargeWeightList={fluxesChargeWeightList}
            setFluxesChargeWeightList={setFluxesChargeWeightList}
            deoxidizersChargeWeightList={deoxidizersChargeWeightList}
            setDeoxidizersChargeWeightList={setDeoxidizersChargeWeightList}
          />
        );

      case isTappingMode ? -1 : 2:
        return (
          <Step3
            disabled
            alloyList={[]}
            fluxList={[]}
            deoxidizerList={deoxidizerList}
            siRecovery={siRecovery}
            setSiRecovery={setSiRecovery}
            alRecovery={alRecovery}
            setAlRecovery={setAlRecovery}
            mnRecovery={mnRecovery}
            setMnRecovery={setMnRecovery}
            crRecovery={crRecovery}
            setCrRecovery={setCrRecovery}
            setFluxesChargeWeightListAtLF={setFluxesChargeWeightListAtLF}
            alloysChargeWeightListAtLF={alloysChargeWeightListAtLF}
            setAlloyChargeWeightListAtLF={setAlloyChargeWeightListAtLF}
            fluxesChargeWeightListAtLF={fluxesChargeWeightListAtLF}
            fluxesChargeWeightFixedListAtLF={[]}
            deoxidizersChargeWeightListAtLF={deoxidizersChargeWeightListAtLF}
            setDeoxidizersChargeWeightListAtLF={setDeoxidizersChargeWeightListAtLF}
          />
        );

      case isTappingMode ? 2 : 3:
        return (
          <Step4
            disabled
            steelChemistryConfigs={steelChemistryConfigs}
            isViewMode
            steelChemistryPrimaryFurnaceSi={steelChemistryPrimaryFurnaceSi}
            setSteelChemistryPrimaryFurnaceSi={setSteelChemistryPrimaryFurnaceSi}
            steelChemistryPrimaryFurnaceDissolvedAl={steelChemistryPrimaryFurnaceDissolvedAl}
            setSteelChemistryPrimaryFurnaceDissolvedAl={setSteelChemistryPrimaryFurnaceDissolvedAl}
            steelChemistryPrimaryFurnaceMn={steelChemistryPrimaryFurnaceMn}
            setSteelChemistryPrimaryFurnaceMn={setSteelChemistryPrimaryFurnaceMn}
            steelChemistryPrimaryFurnaceCr={steelChemistryPrimaryFurnaceCr}
            setSteelChemistryPrimaryFurnaceCr={setSteelChemistryPrimaryFurnaceCr}
            steelChemistryTappingSi={steelChemistryTappingSi}
            setSteelChemistryTappingSi={setSteelChemistryTappingSi}
            steelChemistryTappingDissolvedAl={steelChemistryTappingDissolvedAl}
            setSteelChemistryTappingDissolvedAl={setSteelChemistryTappingDissolvedAl}
            steelChemistryTappingMn={steelChemistryTappingMn}
            setSteelChemistryTappingMn={setSteelChemistryTappingMn}
            steelChemistryTappingCr={steelChemistryTappingCr}
            setSteelChemistryTappingCr={setSteelChemistryTappingCr}
            fluxSelection1={fluxSelection1}
            setFluxSelection1={setFluxSelection1}
            deoxidizerSelection={deoxidizerSelection}
            setDeoxidizerSelection={setDeoxidizerSelection}
            useR2O3Target={useR2O3Target}
            setUseR2O3Target={setUseR2O3Target}
            r2O3Target={r2O3Target}
            setR2O3Target={setR2O3Target}
            fluxList={fluxList}
            deoxidizerList={deoxidizerList}
            inputChargeChemistriesFluxes={inputChargeChemistriesFluxes}
            isTappingMode={isTappingMode}
            isManganeseEnabled={isManganeseEnabled}
            setIsManganeseEnabled={setIsChromiumEnabled}
            isChromiumEnabled={isChromiumEnabled}
            setIsChromiumEnabled={setIsChromiumEnabled}
          />
        );

      case isTappingMode ? 3 : 4:
        return (
          <Step5
            heatMode={heatCalculation?.heatName ? HeatMode.Operation : HeatMode.Draft}
            result={result}
            disabled
            recommendedAdditions={recommendedAdditions}
            setRecommendedAdditions={(rec: HeatRecommendationsDTO | undefined) => setRecommendedAdditions(rec)}
          />
        );

      case isTappingMode ? 4 : 5:
        if (calculatedSlags){
          calculateB2andB3(calculatedSlags)
        }
        return (
          <Step6
            disabled
            initialSlag={calculatedSlags?.find(({stageName}) => stageName === 'Tapping')?.calculated}
            originalSlag={calculatedSlags?.find(({stageName, stageType}) => stageName === recommendations?.stageName && stageType === recommendations.stageType)?.calculated}
            modifiedRecommendations={calculatedSlags?.find(({stageType}) => stageType === 'ModifiedRecommendations')?.calculated || null}
            analyzedSlag={analyzedSlag}
            submitAnalyzedSlag={handleSubmitAnalyzedSlag}
          />
        );

      case isTappingMode ? 5 : 6:
        return (
          <Step7
            disabled
            slagAppearance={slagAppearance}
            setSlagAppearance={setSlagAppearance}
            slagAppearanceText={slagAppearanceText}
            setSlagAppearanceText={setSlagAppearanceText}
          />
        );

      default:
        break;
    }
  }

  async function handleSubmitAnalyzedSlag(slags: AddAndUpdateAnalyzedSlag) {
    try {
      setLoading(true);
      const ss = {
        ...slags,
        stageName: recommendations?.stageName,
      };

      if (analyzedSlag) {
        await putAnalyzedSlag(Number(heatId), ss);
      } else {
        await postAnalyzedSlag(Number(heatId), ss);
      }

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setAnalyzedSlag({
        mgO: slags.mgO,
        caO: slags.caO,
        al2O3: slags.al2O3,
        siO2: slags.siO2,
        feO: slags.feO,
        mnO: slags.mnO,
        cr2O3: slags.cr2O3,
        tiO2: slags.tiO2,
        caF2: slags.caF2,
        na2O: slags.na2O,
        k2O: slags.k2O
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(processConstraintsStatus === 'idle') {
      loadProcessConstraints()
    }
  }, [loadProcessConstraints, processConstraintsStatus]);

  useEffect(() => {
    if (processConstraintsStatus === 'succeeded') {
      const steelChemistryPrimaryFurnaceCode = (
        processConstraints?.steelChemistryPrimaryFurnaceCode
      ) ?? '';

      const steelChemistryTappingCode = (
        processConstraints?.steelChemistryTappingCode
      ) ?? '';

      const steelChemistryUnitOfMeasurement = (
        heatResponseObject?.input.processConstraints.steelChemistryUnitOfMeasurement
      ) ?? SteelChemistryUnitOfMeasurement.Percent;

      setSteelChemistryConfigs({
        steelChemistryPrimaryFurnaceCode,
        steelChemistryTappingCode,
        steelChemistryUnitOfMeasurement
      })
    }
  }, [
    heatResponseObject?.input.processConstraints.steelChemistryUnitOfMeasurement,
    processConstraints?.steelChemistryPrimaryFurnaceCode,
    processConstraints?.steelChemistryTappingCode,
    processConstraintsStatus
  ])

  useEffect(() => {
    async function loadHeatData(id: number) {
      await loadHeatDataIntoFormStates(
        id,
        setAnalyzedSlag,
        setLadleFurnaceList,
        setTapList,
        setCarryoverSlagType,
        setAlloyChargeWeightList,
        setFluxesChargeWeightList,
        initialState,
        setHeatCalculation,
        setDeoxidizerList,
        setDeoxidizersChargeWeightList,
        setDeoxidizersChargeWeightListAtLF,
        setDeoxidizerSelection,
        setAlloyChargeWeightListAtLF,
        setFluxesChargeWeightListAtLF,
        setSteelChemistryPrimaryFurnaceSi,
        setSteelChemistryPrimaryFurnaceDissolvedAl,
        setSteelChemistryPrimaryFurnaceMn,
        setSteelChemistryPrimaryFurnaceCr,
        setSteelChemistryTappingSi,
        setSteelChemistryTappingDissolvedAl,
        setSteelChemistryTappingMn,
        setSteelChemistryTappingCr,
        setSelectedCarryoverSlag,
        setRecommendedAdditions,
        setFluxList,
        setFluxSelection1,
        setSiRecovery,
        setAlRecovery,
        setMnRecovery,
        setCrRecovery,
        setUseR2O3Target,
        setR2O3Target,
        setHeatId,
        setRecommendations,
        setResult,
        setCalculatedSlags,
        setSlagAppearance,
        setSlagAppearanceText,
        pathname.includes('draft'),
        true,
        [],
        setIsDesulfurizationEnabled,
        setIsManganeseEnabled,
        setIsChromiumEnabled
      );
    }
    if(status === 'idle' && processConstraintsStatus === 'succeeded' && !!id)
      loadHeatData(Number(id));
  }, [id, status, loadHeatDataIntoFormStates, processConstraintsStatus, pathname]);

  function handleBack() {
    handleSendEvent({
      category: 'Heats/HeatInfo',
      action: 'User Clicked in Previous Step Button.',
    });
    if (step === 0) {
      history.goBack();
      return;
    }

    setStep(old => old - 1);
  }

  function handleStepClick(newStep: number) {
    if(
      isTappingMode
      ? (step <= 2 && newStep > 2 && (heatResponseObject?.output?.calculatedSlags?.length ?? 0) === 0 && !heatResponseObject?.output.recommendations)
      : (step <= 3 && newStep > 3 && (heatResponseObject?.output.calculatedSlags?.length ?? 0) === 0 && !heatResponseObject?.output.recommendations)
    )
      return;
    setStep(newStep);
  }

  const theme = useTheme();

  const formRef = createRef<HTMLFormElement>();

  const [lastStep,setLastStep] = useState(0);

  useEffect(() => {
    const scrollToTheTopOfTheForm = () => {
      formRef.current?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' })
    }

    if(step !== lastStep) {
      scrollToTheTopOfTheForm();
      setLastStep(step);
    }
  }, [step, formRef, lastStep]);

  const shouldDisableNext =
    step === (isTappingMode ? 2 : 3)
    && ((calculatedSlags?.length ?? 0) <= 0) && !recommendations;

  const StyledStepper = withStyles(() => ({
    root: {
      padding: "8px 0 24px 0",
      border: "2px solid transparent",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": { marginTop: '8px' },
      "& .Mui-error .MuiSvgIcon-root:not(.MuiStepIcon-active):not(.MuiStepIcon-completed)": { transform: 'translateY(-1px)' },
    },
  }))(Stepper);

  return (
    <>
      <Loading dataTestId='heat-info-circular-progress' promiseInProgress={status === 'loading' || loading} />
      <Helmet>
        <title>
          {t('new-heat.form.title.heat')} | {APP_TITLE}
        </title>
      </Helmet>

      <MainPaper
        removePadding
        title={
          t(
            `new-heat.form.title.heat.${pathname.includes('tapping') ? 'tapping' : 'ladle-furnace'}`,
            { name: heatCalculation.heatName || heatCalculation.heatDescription
          })
        }
        subtitle={pathname.includes('draft') ? t('draft') : undefined}
      >
        <form onSubmit={e => e.preventDefault()} ref={formRef}>
          <Box
            style={{
              overflowX: 'auto',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              columnGap: '8px'
            }}
            bgcolor={theme.palette.common.white}
          >
            <StepperNavigationButton
              Icon={ArrowBackRounded}
              title="new-heat.stepper-back-button.text"
              onClick={handleBack}
              disabled={step === 0}
              invisible={step === 0}
            />
            <StyledStepper activeStep={step} alternativeLabel>
              {stepsList.map((label: string, k: number) => (
                <Step
                  onClick={() => {
                    handleSendEvent({
                      category: 'Heats/HeatInfo',
                      action: `User Was in Step ${step} and Now is in Step ${k}`,
                    });
                    handleStepClick(k);
                  }}
                  key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </StyledStepper>
            <StepperNavigationButton
              Icon={ArrowForwardRounded}
              title={t("new-heat.stepper-next-button.text")}
              disabled={step > (isTappingMode ? 4 : 5 ) || shouldDisableNext}
              invisible={step > (isTappingMode ? 4 : 5 )}
              onClick={() => {
                handleSendEvent({
                  category: 'Heats/HeatInfo',
                  action: 'User Clicked in Next Step Button.',
                });
                setStep(old => old + 1);
              }}
            />
          </Box>
          {renderSteps()}
          <Grid direction="row" alignItems="center" container spacing={2} />
        </form>
      </MainPaper>
    </>
  );
};

export default HeatInfo;
