import { PlantStatus, PlantsGetResponse } from '@app/api/plants/PlantsGetResponse';
import EllipsedText from '@app/components/shared/EllipsedText';
import TableNoResults from '@app/components/shared/TableNoResults';
import { PAGINATION, PERMISSIONS } from '@app/utils/constants';
import { getInverseOrder } from '@app/utils/getInverseOrder';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlantsOrderByType } from '../plantManagementUtils';
import useScrollTop from '@app/hooks/useScrollTop';
import { format, isAfter } from 'date-fns';
import StatusComponent from '@app/components/shared/StatusComponent';
import { BlockButton, EditButton, ViewButton } from '@app/components/shared/CustomIconButton';
import { getExpirationDate } from '@app/utils/getExpirationDate';
import CustomTooltip from '@app/components/shared/CustomTooltip';
import { useHistory } from 'react-router-dom';
import { ROUTES_PATH } from '@app/utils';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';

interface PlantManagementTableProps {
  plants: PlantsGetResponse[];
  searchInput: string;
  handleConfirmBlockModal: (plant: PlantsGetResponse) => void;
}

const KEY_PLANT_NAME: PlantsOrderByType = 'name';
const KEY_PLANT_CUSTOMER: PlantsOrderByType = 'customerName';
const KEY_PLANT_START_CONTRACT: PlantsOrderByType = 'contractStart';
const KEY_PLANT_EXPIRED_CONTRACT: PlantsOrderByType = 'contractExpiration';
const KEY_PLANT_STATUS: PlantsOrderByType = 'plantStatus';

const PlantManagementTable: React.FC<PlantManagementTableProps> = (props: PlantManagementTableProps) => {
  const { plants, searchInput, handleConfirmBlockModal } = props;
  
  const { hasAccess } = useAuthenticationContext();
  const history = useHistory();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE);

  const [orderBy, setOrderBy] = React.useState<PlantsOrderByType>(KEY_PLANT_NAME);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    setOrderBy(KEY_PLANT_NAME)
    setOrder('desc')
  }, [searchInput])

  const isOrderedByName = () => orderBy === KEY_PLANT_NAME;
  const isOrderedByCustomer = () => orderBy === KEY_PLANT_CUSTOMER;
  const isOrderedByStartContract = () => orderBy === KEY_PLANT_START_CONTRACT;
  const isOrderedByExpiredContract = () => orderBy === KEY_PLANT_EXPIRED_CONTRACT;
  const isOrderedByStatus = () => orderBy === KEY_PLANT_STATUS;

  const comparator = useCallback((a: PlantsGetResponse, b: PlantsGetResponse) => {
    const key = orderBy || KEY_PLANT_NAME;

    const orderFunction = order === 'asc' ? (x: Date, y: Date) => isAfter(x, y) : (x: Date, y: Date) => isAfter(y, x);

    if (key === 'contractStart' || key === 'contractExpiration') {
      const dateA = getExpirationDate(a[key] ?? '');
      const dateB = getExpirationDate(b[key] ?? '');
      return orderFunction(dateA, dateB) ? -1 : 0;
    }

    return order === 'asc' ? (a[key] >= b[key] ? -1 : 0) : (b[key] >= a[key] ? -1 : 0);
  }, [order, orderBy]);


  const handleSortBy = (criteria: PlantsOrderByType) => {
    setOrder(old => {
      const inverseOrder = getInverseOrder(old);

      return inverseOrder;
    });

    setOrderBy(criteria);
  };

  const formatCellDate = (dateValue: string | undefined) => {
    const result = dateValue === null || dateValue === undefined ? '' : format(new Date(dateValue), t('date.format'));
    return result;
  };

  const redirectToDetails = (plantId: string, readOnly: boolean): void => {
    history.push({
      pathname: ROUTES_PATH.USERS_PLANT_MANAGEMENT_DETAILS.replace(':id', plantId),
      state: { readOnly: readOnly }
    });
  }

  useEffect(() => {
    setPage(0);
  }, [searchInput, rowsPerPage]);

  const renderLines = () => {
    const start = rowsPerPage * page;
    const end = start + rowsPerPage;

    return plants.sort(comparator).slice(start, end).map((row, i) => {
      return (
        <TableRow key={i}>
          <TableCell align="left">
            <CustomTooltip title={row.description} placement='bottom-start'>
              {<Typography variant='body2'>{row.name}</Typography>}
            </CustomTooltip>
          </TableCell>
          <TableCell align="left">
            <EllipsedText lines={1} text={row.customerName} />
          </TableCell>
          <TableCell align="left">
            { row.plantStatus === PlantStatus.TRIAL ? 
              formatCellDate(row.trialStart) :
              formatCellDate(row.contractStart)
            }              
          </TableCell>
          <TableCell align="left">
            { row.plantStatus === PlantStatus.TRIAL ? 
              formatCellDate(row.trialExpiration) :
              formatCellDate(row.contractExpiration)
            }
          </TableCell>
          <TableCell align="left">
            <Box>
              <StatusComponent status={row.plantStatus} />
            </Box>
          </TableCell>
          <TableCell>
            <Box style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'flex-end' }}>
              {
                hasAccess([PERMISSIONS.PLANT_MANAGEMENT_DETAIL]) && (
                  <ViewButton 
                  tooltipMessage={t('admin-panel.plant-management.action-details')} 
                  onClick={() => redirectToDetails(row.id, true)} />
                )
              }
              {
                hasAccess([PERMISSIONS.PLANT_MANAGEMENT_EDIT]) && (
                  <EditButton
                  tooltipMessage={t('admin-panel.plant-management.action-edit')} 
                  onClick={() => redirectToDetails(row.id, false)} />
                )
              }
              {
                hasAccess([PERMISSIONS.PLANT_MANAGEMENT_EDIT]) && (
                  <BlockButton
                  blocked={row.plantStatus === PlantStatus.BLOCKED}
                  onClick={() => handleConfirmBlockModal(row)}/>
                )
              }
            </Box>
          </TableCell>
        </TableRow>
      );
    });
  };

  const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop();

  return (
    <>
      <Paper>
        <TableContainer style={{ maxHeight:"calc(100vh - 321px)" }} innerRef={tableContainerRef}>
          <Table stickyHeader size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <TableSortLabel
                    active={isOrderedByName()}
                    direction={isOrderedByName() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_PLANT_NAME)}
                  >
                    {t('admin-panel.plant')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={isOrderedByCustomer()}
                    direction={isOrderedByCustomer() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_PLANT_CUSTOMER)}
                  >
                    {t('admin-panel.customer')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={isOrderedByStartContract()}
                    direction={isOrderedByStartContract() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_PLANT_START_CONTRACT)}
                  >
                    {t('admin-panel.plant-management.contract.start')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={isOrderedByExpiredContract()}
                    direction={isOrderedByExpiredContract() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_PLANT_EXPIRED_CONTRACT)}
                  >
                    {t('admin-panel.plant-management.contract.expiration')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={isOrderedByStatus()}
                    direction={isOrderedByStatus() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_PLANT_STATUS)}
                  >
                    {t('admin-panel.status')}
                  </TableSortLabel>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {plants.length ? (
                renderLines()
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <TableNoResults searchInput={searchInput} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={plants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            scrollTop();
          }}
          onRowsPerPageChange={e => {
            setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE));
          }}
          labelRowsPerPage={t('rows-per-page')}
          backIconButtonText={t('page-previous')}
          nextIconButtonText={t('page-next')}
        />
      </Paper>
    </>
  );
};

export default PlantManagementTable;
