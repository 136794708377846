import { useHeatsService } from '@app/api/heats';
import { SaveHeatRequestPayload } from '@app/api/heats/HeatsResponse';
import { ROUTES_PATH } from '@app/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HooksStatus } from "./types"

const useSaveHeat = (): [
  HooksStatus,
  (
    draftData: SaveHeatRequestPayload,
    setPreventChangeScreenModal: (value: React.SetStateAction<boolean>) => void,
    setStep: (value: React.SetStateAction<number>) => void,
    clearAllFields: () => void,
    loadLadles: () => Promise<void>,
  ) => void
] => {
  const initialState: HooksStatus = "idle";

  const [status, setStatus] = useState<HooksStatus>(initialState);

  const { postHeat } = useHeatsService();

  const history = useHistory();

  const { pathname } = useLocation();

  const { t } = useTranslation();

  const saveHeat = async (
    draftData: SaveHeatRequestPayload,
    setPreventChangeScreenModal: (value: React.SetStateAction<boolean>) => void,
    setStep: (value: React.SetStateAction<number>) => void,
    clearAllFields: () => void,
    loadLadles: () => Promise<void>,
  ) => {
    try {
      setStatus("loading");
      await postHeat(draftData);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      if(pathname.includes('draft')) {
        setPreventChangeScreenModal(true);
        history.push(
          pathname.includes('ladle-furnace')
          ? ROUTES_PATH.APPLICATIONS_HEATS_LADLE_FURNACE_CREATE
          : ROUTES_PATH.APPLICATIONS_HEATS_TAPPING_CREATE
        );
        return;
      }
      setStep(0);
      await loadLadles();
      setStatus("succeeded");
      clearAllFields();
    } catch (err) {

      setStatus("error");
    }
  };
  return [status, saveHeat];
}

export default useSaveHeat;
