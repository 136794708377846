
export interface ArchivedDraftHeatState {
  startDate: string | null;
  endDate: string | null;
}

export const ARCHIVED_DRAFT_HEAT_INITIAL_STATE: ArchivedDraftHeatState = {
  startDate: null,
  endDate: null
};
