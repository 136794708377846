import { AlloysResponse } from "@app/api/alloys/AlloysResponse";
import { SaturationMode, StageName, StageType } from "@app/api/heats/HeatsResponse";

export interface ParsedFluxModel extends Omit<FluxModel, 'gradeOffsets' | 'id'> {
  gradeOffset: number,
}

export interface SteelGradeModel {
  id: number;
  name: string;
  customerId: number | string;
  categoryId?: number;
  categoryName?: string;
  saturationMode: SaturationMode;
  initialSulfurPercentage: number | null;
  finalOxygenInSteel: number | null;
}

export interface PostSteelGradeCategoryModel {
  name: string;
  steelGrades: Array<{
    name: string;
    saturationMode: SaturationMode;
    initialSulfurPercentage: number | null;
    finalOxygenInSteel: number | null;
  }>
}

export interface SteelGradeCategoryModel {
  id: number;
  name: string;
  steelGrades: Array<{
    id: number;
    name: string;
    categoryId?: number;
    categoryName?: string;
    saturationMode: SaturationMode;
    initialSulfurPercentage: number | null;
    finalOxygenInSteel: number | null;
  }>
}

export type AlloyListStateDTO = AlloysResponse

export interface DeoxidizersModel {
  id?: number;
  customerId?: string | number;
  externalId: number | null | undefined;
  isEnabled: boolean;
  name: string;
  si: number;
  al: number;
  ca: number;
  c: number;
  carbonDeoxEfficiency: number;
  efficiencyFactor: number;
}

export enum FluxType {
	None = 'None',
	GeneralFlux = 'GeneralFlux',
	CaOSource = 'CaOSource',
	MgOOnlySource = 'MgOOnlySource',
	DolomaSource = 'DolomaSource',
	CarryoverSlag = 'CarryoverSlag',
	RecycledSlag = 'RecycledSlag'
}

export enum FluxAvailability
{
	None = 'None',
	Tapping = 'Tapping',
	LadleFurnace = 'LadleFurnace',
	TappingAndLadleFurnace = 'TappingAndLadleFurnace',
	FixedAddition = 'FixedAddition',
	FixedAdditionAtTapping = 'FixedAdditionAtTapping',
	FixedAdditionAtLadleFurnace = 'FixedAdditionAtLadleFurnace'
}

export interface FluxModel {
  id?: number | string;
  name: string;
  type: FluxType;
  availability?: FluxAvailability;
  externalId?: string | number | null;
  mgO: number;
  caO: number;
  al2O3: number;
  siO2: number;
  feO: number;
  mnO: number;
  cr2O3: number;
  tiO2: number;
  caF2: number;
  na2O: number;
  k2O: number;
  al: number;
  fixedAdditionAmount?: number;
  fixedAdditionRecovery?: number;
  mgOSourceMinimumWeight?: number | null;
  mgOSourceMaximumWeight?: number | null;
  gradeOffsets?: { name: string; value: number }[];
}

export interface Recommendations {
  stageName: StageName;
  stageType: StageType;
  fluxes?: {
    name: string;
    weight: number;
    realAddedWeight: number;
    externalId: null | number | string;
  }[];
  alloys?: {
    name: string;
    weight: number;
    realAddedWeight: number;
    externalId: null | number | string;
  }[];
  deoxidizers?: {
    name: string;
    weight: number;
    realAddedWeight: number;
    externalId: null | number | string;
  }[];
}
