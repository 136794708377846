import { AlloysResponse, useAlloysService } from '@app/api/alloys';
import { useState } from 'react';
import { InfoObject } from "./types"

const infoObjectInitialState: InfoObject<AlloysResponse> = {
  status: 'idle',
  data: undefined,
}

const useLoadAlloy = (): [
  InfoObject<AlloysResponse>,
  (id: number) => Promise<void>,
] => {


  const [infoObject, setInfoObject] = useState<InfoObject<AlloysResponse>>(infoObjectInitialState);

  const { getAlloy } = useAlloysService();

  const loadAlloy = async (id: number) => {
    try {
      setInfoObject(prevState => ({... prevState, status: 'loading'}));

      const response = await getAlloy(id);
      setInfoObject({data: response, status: 'succeeded'});
    } catch (err) {

      setInfoObject({data: undefined, status: 'error'});
    } 
  };

  return [infoObject, loadAlloy];
}

export default useLoadAlloy;