import { Box } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAvailableAtTapping } from '@app/utils';
import { HeatChargeWeightArray } from '@app/api/heats/HeatsResponse';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import { AlloyListStateDTO, DeoxidizersModel, FluxModel } from './heatCalculationTypes';
import FormWrapper from '@app/components/shared/FormWrapper';
import AdditionManagerComponent from '@app/components/shared/AdditionManagerComponent';

interface Step2Props {
  disabled?: boolean;
  alloyList: AlloyListStateDTO[];
  deoxidizerList: DeoxidizersModel[];
  fluxList: FluxModel[];
  alloysChargeWeightList: HeatChargeWeightArray;
  setAlloyChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>;
  fluxesChargeWeightList: HeatChargeWeightArray;
  setFluxesChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>;
  fluxesChargeWeightFixedList?: HeatChargeWeightArray;
  deoxidizersChargeWeightList: HeatChargeWeightArray;
  setDeoxidizersChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>;
  plantIsIncompatible?: boolean;
  setHasUnsavedFluxesAdditions?: Dispatch<SetStateAction<boolean>>;
  setHasUnsavedAlloysAdditions?: Dispatch<SetStateAction<boolean>>;
  setHasUnsavedDeoxidizersAdditions?: Dispatch<SetStateAction<boolean>>;
  userClickedToAddUnaddedAdditions?: boolean;
}

function Step2({
  disabled = false,
  alloyList,
  deoxidizerList,
  fluxList,
  alloysChargeWeightList,
  setAlloyChargeWeightList,
  fluxesChargeWeightList,
  setFluxesChargeWeightList,
  fluxesChargeWeightFixedList = [],
  deoxidizersChargeWeightList,
  setDeoxidizersChargeWeightList,
  plantIsIncompatible = false,
  setHasUnsavedFluxesAdditions,
  setHasUnsavedAlloysAdditions,
  setHasUnsavedDeoxidizersAdditions,
  userClickedToAddUnaddedAdditions,
}: Step2Props) {
  const { t } = useTranslation();

  const [alloyName, setAlloyName] = useState('');
  const [alloyWeight, setAlloyWeight] = useState<number | undefined | string>();
  const [openEditAlloy, setOpenEditAlloy] = useState(false);
  const [editedAlloy, setEditedAlloy] = useState('');
  const [newAlloyWeight, setNewAlloyWeight] = useState<number | undefined>();

  const [fluxName, setFluxName] = useState('');
  const [fluxWeight, setFluxWeight] = useState<number | undefined | string>();
  const [openEditFlux, setOpenEditFlux] = useState(false);
  const [editedFlux, setEditedFlux] = useState('');
  const [newFluxWeight, setNewFluxWeight] = useState<number | undefined>();

  const [deoxidizerName, setDeoxidizerName] = useState('');
  const [deoxidizerWeight, setDeoxidizerWeight] = useState<number | undefined | string>('');
  const [openEditDeoxidizer, setOpenEditDeoxidizer] = useState(false);
  const [editedDeoxidizer, setEditedDeoxidizer] = useState('');
  const [newDeoxidizerWeight, setNewDeoxidizerWeight] = useState<number | undefined>()

  const handleAddAlloy = useCallback(() => {
    const alloy = alloyList.find(a => a.name === alloyName);
    if (!alloy) return;

    setAlloyChargeWeightList([
      ...alloysChargeWeightList,
      { name: alloyName, weight: Number(alloyWeight), externalId: alloy.externalId },
    ]);

    setAlloyWeight('');
    setAlloyName('');
  }, [
    alloyList,
    alloyName,
    alloyWeight,
    alloysChargeWeightList,
    setAlloyChargeWeightList
  ]);

  const handleAddFlux = useCallback(() => {
    const flux = fluxList.find(a => a.name === fluxName);
    if (!flux) return;

    setFluxesChargeWeightList([
      ...fluxesChargeWeightList,
      { name: fluxName, weight: Number(fluxWeight), externalId: flux.externalId },
    ]);

    setFluxName('');
    setFluxWeight('');
  }, [
    fluxList,
    fluxName,
    fluxWeight,
    fluxesChargeWeightList,
    setFluxesChargeWeightList
  ]);

  const handleAddDeoxidizer = useCallback(() => {
    const deoxidizer = deoxidizerList.find(a => a.name === deoxidizerName);
    if (!deoxidizer) return;

    setDeoxidizersChargeWeightList([
      ...deoxidizersChargeWeightList,
      { name: deoxidizerName, weight: Number(deoxidizerWeight), externalId: deoxidizer.externalId },
    ]);

    setDeoxidizerName('');
    setDeoxidizerWeight('');
  }, [
    deoxidizerList,
    deoxidizerName,
    deoxidizerWeight,
    deoxidizersChargeWeightList,
    setDeoxidizersChargeWeightList
  ]);

  const isAddAlloyDisabled = React.useMemo(() => !alloyWeight || !alloyName, [alloyName, alloyWeight]);

  const isAddFluxDisabled = React.useMemo(() => !fluxWeight || !fluxName, [fluxName, fluxWeight]);

  const isAddDeoxidizerDisabled = React.useMemo(() => !deoxidizerWeight || !deoxidizerName, [deoxidizerName, deoxidizerWeight]);

  useEffect(() => {
    if(userClickedToAddUnaddedAdditions && !isAddAlloyDisabled) {
      handleAddAlloy();
    } else if(userClickedToAddUnaddedAdditions && !isAddFluxDisabled) {
      handleAddFlux();
    } else if(userClickedToAddUnaddedAdditions && !isAddDeoxidizerDisabled) {
      handleAddDeoxidizer();
    }
  }, [
    handleAddAlloy,
    handleAddFlux,
    handleAddDeoxidizer,
    isAddAlloyDisabled,
    isAddFluxDisabled,
    isAddDeoxidizerDisabled,
    userClickedToAddUnaddedAdditions
  ])

  useEffect(() => {
    setHasUnsavedDeoxidizersAdditions?.(!isAddDeoxidizerDisabled);
  }, [isAddDeoxidizerDisabled,setHasUnsavedDeoxidizersAdditions])

  useEffect(() => {
    setHasUnsavedAlloysAdditions?.(!isAddAlloyDisabled);
  }, [isAddAlloyDisabled, setHasUnsavedAlloysAdditions]);

  useEffect(() => {
    setHasUnsavedFluxesAdditions?.(!isAddFluxDisabled);
  }, [isAddFluxDisabled, setHasUnsavedFluxesAdditions]);


  function shouldDisableAlloy(name: string) {
    return alloysChargeWeightList.some(a => a.name === name);
  }

  function shouldDisableFlux(name: string) {
    return fluxesChargeWeightList.some(f => f.name === name);
  }

  function shouldDisableDeoxidizer(name: string) {
    return deoxidizersChargeWeightList.some(d => d.name === name);
  }

  function handleEditAlloy(alloy: { name: string, weight: number | string }) {
    setEditedAlloy(alloy.name);
    setNewAlloyWeight(alloy.weight as number);
    setOpenEditAlloy(true);
  }

  function handleEditFlux(flux: { name: string, weight: number | string }) {
    setEditedFlux(flux.name);
    setNewFluxWeight(flux.weight as number);
    setOpenEditFlux(true);
  }

  function handleEditDeoxidizer(deoxidizer: { name: string, weight: number | string }) {
    setEditedDeoxidizer(deoxidizer.name);
    setNewDeoxidizerWeight(deoxidizer.weight as number);
    setOpenEditDeoxidizer(true);
  }

  function handleNewWeightAlloyChange(weight: number) {
    if (isNaN(weight)) return;

    setNewAlloyWeight(weight);
  }

  function handleNewWeightFluxChange(weight: number) {
    if (isNaN(weight)) return;

    setNewFluxWeight(weight);
  }

  function handleNewWeightDeoxidizerChange(weight: number) {
    if(isNaN(weight)) return;

    setNewDeoxidizerWeight(weight);
  }

  function handleEditWeightAlloy() {
    if (!newAlloyWeight) return;

    const alloy = alloysChargeWeightList.find(a => a.name === editedAlloy);
    const otherAlloys = alloysChargeWeightList.filter(a => a.name !== editedAlloy);

    if (!alloy) return;

    setAlloyChargeWeightList([...otherAlloys, { ...alloy, weight: Number(newAlloyWeight) }]);

    setNewAlloyWeight(undefined);
    setEditedAlloy('');
    setOpenEditAlloy(false);
  }

  function handleEditWeightFlux() {
    if (!newFluxWeight) return;

    const flux = fluxesChargeWeightList.find(a => a.name === editedFlux);
    const otherFluxes = fluxesChargeWeightList.filter(a => a.name !== editedFlux);

    if (!flux) return;

    setFluxesChargeWeightList([...otherFluxes, { ...flux, weight: Number(newFluxWeight) }]);

    setNewFluxWeight(undefined);
    setEditedFlux('');
    setOpenEditFlux(false);
  }

  function handleEditWeightDeoxidizer() {
    if (!newDeoxidizerWeight) return;

    const deoxidizer = deoxidizersChargeWeightList.find(a => a.name === editedDeoxidizer);
    const otherDeoxidizers = deoxidizersChargeWeightList.filter(a => a.name !== editedDeoxidizer);

    if (!deoxidizer) return;

    setDeoxidizersChargeWeightList([...otherDeoxidizers, { ...deoxidizer, weight: Number(newDeoxidizerWeight) }]);

    setNewDeoxidizerWeight(undefined);
    setEditedDeoxidizer('');
    setOpenEditDeoxidizer(false);
  }

  function handleDeleteAlloy(alloy: {
    name: string;
    weight: number;
    externalId?: string | number | null | undefined;
}) {
    const otherAlloys = alloysChargeWeightList.filter(a => a.name !== alloy.name);
    setAlloyChargeWeightList(otherAlloys);
  }

  function handleDeleteFlux(flux: {
    name: string;
    weight: number;
    externalId?: string | number | null | undefined;
}) {
    const otherFluxes = fluxesChargeWeightList.filter(a => a.name !== flux.name);
    setFluxesChargeWeightList(otherFluxes);
  }

  function handleDeleteDeoxidizer(flux: {
    name: string;
    weight: number;
    externalId?: string | number | null | undefined;
}) {
    const otherDeoxidizer = deoxidizersChargeWeightList.filter(a => a.name !== flux.name);
    setDeoxidizersChargeWeightList(otherDeoxidizer);
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        gap: '8px'
      }}
    >
      {plantIsIncompatible && (
        <WarningMessageComponent
          message={t("draft-edit.plant-config-error.message")}
        />
      )}
      <FormWrapper>
        <AdditionManagerComponent
          style={{ minWidth: '225px' }}
          title={t('alloys')}
          additionList={alloyList}
          additionName={alloyName}
          setAdditionName={setAlloyName}
          additionWeight={alloyWeight}
          setAdditionWeight={setAlloyWeight}
          additionSelectLabel={t('alloys')}
          additionSelectOptionList={alloyList.map(a => ({name: a.name, value: a.name, disabled: shouldDisableAlloy(a.name)}))}
          chargeAdditionWeightList={alloysChargeWeightList}
          dialogIsVisible={openEditAlloy}
          setDialogIsVisible={setOpenEditAlloy}
          dialogTitle={t('alloy.edit')}
          disabled={disabled}
          handleAdd={handleAddAlloy}
          handleDeleteAddition={handleDeleteAlloy}
          handleEditAddition={handleEditAlloy}
          handleEditAdditionWeight={handleEditWeightAlloy}
          setNewAdditionWeight={handleNewWeightAlloyChange}
          isAdditionDisabled={isAddAlloyDisabled}
          listTitle={t('alloy')}
          newAdditionWeight={newAlloyWeight}
          plantIsIncompatible={plantIsIncompatible}
        />

        <AdditionManagerComponent
          style={{ minWidth: '225px' }}
          title={t('fluxes')}
          additionList={fluxList}
          additionName={fluxName}
          setAdditionName={setFluxName}
          additionWeight={fluxWeight}
          setAdditionWeight={setFluxWeight}
          additionSelectLabel={t('fluxes')}
          additionSelectOptionList={
            fluxList
              .filter(f => isAvailableAtTapping(f.availability ?? ''))
              .map(f => ({name: f.name, value: f.name, disabled: shouldDisableFlux(f.name)}))
          }
          chargeAdditionWeightList={fluxesChargeWeightList}
          dialogIsVisible={openEditFlux}
          setDialogIsVisible={setOpenEditFlux}
          dialogTitle={t('flux.edit')}
          disabled={disabled}
          handleAdd={handleAddFlux}
          handleDeleteAddition={handleDeleteFlux}
          handleEditAddition={handleEditFlux}
          handleEditAdditionWeight={handleEditWeightFlux}
          setNewAdditionWeight={handleNewWeightFluxChange}
          isAdditionDisabled={isAddFluxDisabled}
          listTitle={t('flux')}
          newAdditionWeight={newFluxWeight}
          plantIsIncompatible={plantIsIncompatible}
          fixedAdditionsList={fluxesChargeWeightFixedList}
        />

        <AdditionManagerComponent
          style={{ minWidth: '270px' }}
          title={t('slag-deoxidizers')}
          additionList={deoxidizerList}
          additionName={deoxidizerName}
          setAdditionName={setDeoxidizerName}
          additionWeight={deoxidizerWeight}
          setAdditionWeight={setDeoxidizerWeight}
          additionSelectLabel={t('slag-deoxidizers')}
          additionSelectOptionList={deoxidizerList.map(a => ({name: a.name, value: a.name, disabled: shouldDisableDeoxidizer(a.name)}))}
          chargeAdditionWeightList={deoxidizersChargeWeightList}
          dialogIsVisible={openEditDeoxidizer}
          setDialogIsVisible={setOpenEditDeoxidizer}
          dialogTitle={t('slag-deoxidizers.edit')}
          disabled={disabled}
          handleAdd={handleAddDeoxidizer}
          handleDeleteAddition={handleDeleteDeoxidizer}
          handleEditAddition={handleEditDeoxidizer}
          handleEditAdditionWeight={handleEditWeightDeoxidizer}
          setNewAdditionWeight={handleNewWeightDeoxidizerChange}
          isAdditionDisabled={isAddDeoxidizerDisabled}
          listTitle={t('slag-deoxidizer')}
          newAdditionWeight={newDeoxidizerWeight}
          plantIsIncompatible={plantIsIncompatible}
        />
      </FormWrapper>
    </Box>
  );
}

export default Step2;
