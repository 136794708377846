import { Box, createStyles, Drawer, IconButton, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import Logo from '../components/Logo';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_TOOLBAR_HEIGHT, DRAWER_WIDTH } from '../utils/constants';
import AppMenu from './AppMenu';
import { DoubleArrow } from '@material-ui/icons';
import AppVersion from './AppVersion';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      '& .MuiDrawer-paperAnchorLeft': {
        background: theme.palette.primary.main,
        borderRight: 'none',
      },
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      minWidth: COLLAPSED_DRAWER_WIDTH,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      background: theme.palette.primary.main,
    },
    toolbarOpen: {
      height: DRAWER_TOOLBAR_HEIGHT,
    },
    chevronRight: {
      marginTop: '0px',
      transform: 'rotate(0deg)',
      transition: 'transform 0.25s'
    },
    chevronLeft: {
      marginTop: '0px',
      transform: 'rotate(180deg)',
      transition: 'transform 0.25s'
    }
  }),
);

interface NavigationProps {
  open: boolean;
  handleChangeMenuVisibility: () => void;
}

const Navigation = ({ open, handleChangeMenuVisibility }: NavigationProps) => {
  const classes = useStyles();
  return (
    <Drawer
      color="primary"
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}>
      <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <IconButton onClick={handleChangeMenuVisibility} style={{ borderRadius: 0, padding: '10px' }}>
          <DoubleArrow
            htmlColor="#fff"
            className={open ? classes.chevronLeft : classes.chevronRight}
          />
        </IconButton>
        <AppMenu open={open} />
        <Box style={{ display: 'flex', marginTop: 'auto', justifyContent: 'center', padding: '20px 0', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <Logo open={open} />
          <AppVersion />
        </Box>
      </Box>
    </Drawer>
  );
};

export default Navigation;
