import { CarryoverSlagReferenceGetResponse } from '@app/api/carryoverSlagReferences';
import { AddAndUpdateAnalyzedSlag, useHeatsService } from '@app/api/heats';
import {
  CalculatedSlags,
  CarryoverSlagType,
  DeoxidizerType,
  DraftHeatsResponse,
  HeatChargeWeightArray,
  HeatRecommendationsDTO,
  HeatsResponse,
  ResultDTO,
  StageName,
  StageType,
  SteelChemistryStageName,
} from '@app/api/heats/HeatsResponse';
import { Tap } from '@app/api/ladleFurnaces';
import { LadleFurnaceModel } from '@app/api/ladleFurnaces/LadleFurnaceModel';
import {
  SteelChemistryUnitOfMeasurement,
  ProcessConstraintsDTO,
} from '@app/api/processConstraints/useProcessConstraintsService';
import ISelectedCarryoverSlag from '@app/pages/Heats/HeatCalculation/ISelectedCarryoverSlag';
import {
  DeoxidizersModel,
  FluxModel,
  SteelGradeModel,
} from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { InitialState } from '@app/pages/Heats/heatsUtils';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HooksStatus } from './types';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import { calculateAndFormatSteelChemistry } from '@app/utils/steelChemistryFormatter';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { convertMetersIntoOtherUnitOfMeasurement } from '@app/utils/convertLengthUnits';

export function useLoadHeatInfoIntoFormStates(): [
  HooksStatus,
  (
    id: number,
    // setLoading: (value:boolean) => null,
    setAnalyzedSlag: Dispatch<SetStateAction<AddAndUpdateAnalyzedSlag | null | undefined>> | undefined,
    setLadleFurnaceList: Dispatch<SetStateAction<LadleFurnaceModel[]>> | undefined,
    setTapList: React.Dispatch<React.SetStateAction<Tap[]>> | undefined,
    setCarryoverSlagType: Dispatch<SetStateAction<CarryoverSlagType>>,
    setAlloyChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setFluxesChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    heatCalculationInitialState: InitialState,
    setHeatCalculation: Dispatch<SetStateAction<InitialState>>,
    setDeoxidizerList: Dispatch<SetStateAction<DeoxidizersModel[]>> | undefined,
    setDeoxidizersChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setDeoxidizersChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setDeoxidizerSelection: Dispatch<SetStateAction<DeoxidizerType | ''>>,
    setAlloyChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setFluxesChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setSteelChemistryPrimaryFurnaceSi: Dispatch<SetStateAction<string>>,
    setSteelChemistryPrimaryFurnaceDissolvedAl: Dispatch<SetStateAction<string>>,
    setSteelChemistryPrimaryFurnaceMn: Dispatch<SetStateAction<string>>,
    setSteelChemistryPrimaryFurnaceCr: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingSi: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingDissolvedAl: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingMn: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingCr: Dispatch<SetStateAction<string>>,
    setSelectedCarryoverSlag: Dispatch<SetStateAction<ISelectedCarryoverSlag | undefined>>,
    setRecommendedAdditions: Dispatch<SetStateAction<HeatRecommendationsDTO | undefined>>,
    setFluxList: Dispatch<SetStateAction<FluxModel[]>> | undefined,
    setFluxSelection1: Dispatch<SetStateAction<string>>,
    setSiRecovery: Dispatch<SetStateAction<string>>,
    setAlRecovery: Dispatch<SetStateAction<string>>,
    setMnRecovery: Dispatch<SetStateAction<string>>,
    setCrRecovery: Dispatch<SetStateAction<string>>,
    setUseR2O3Target: Dispatch<SetStateAction<boolean>>,
    setR2O3Target: Dispatch<SetStateAction<string>>,
    setHeatId: Dispatch<SetStateAction<number | undefined>> | undefined,
    setRecommendations: Dispatch<SetStateAction<HeatRecommendationsDTO | undefined>>,
    setResult: Dispatch<SetStateAction<ResultDTO | undefined>>,
    setCalculatedSlags: Dispatch<SetStateAction<CalculatedSlags | undefined>>,
    setSlagAppearance: Dispatch<SetStateAction<string>>,
    setSlagAppearanceText: Dispatch<SetStateAction<string>>,
    isDraft: boolean,
    isReadOnly: boolean,
    steelGradeList: SteelGradeModel[],
    setIsDesulfurizationEnabled: Dispatch<SetStateAction<boolean>>,
    setIsManganeseEnabled: Dispatch<SetStateAction<boolean>>,
    setIsChromiumEnabled: Dispatch<SetStateAction<boolean>>,
    currentPlantCarryoverSlagInferenceType?: CarryoverSlagInferenceType,
    carryoverSlagReferenceList?: CarryoverSlagReferenceGetResponse[],
    fluxesChargeWeightFixedList?: HeatChargeWeightArray,
    fluxesChargeWeightFixedListAtLF?: HeatChargeWeightArray,
    ladleFurnaceList?: LadleFurnaceModel[],
    processConstraintsList?: ProcessConstraintsDTO,
  ) => Promise<void>,
  DraftHeatsResponse | HeatsResponse | undefined,
] {
  const { getDraftHeatById, getHeatById } = useHeatsService();
  const { t } = useTranslation();
  const [status, setStatus] = useState<HooksStatus>('idle');
  const [requestOutput, setRequestOutput] = useState<HeatsResponse | DraftHeatsResponse | undefined>();
  const {
    state: { userInfoObject },
  } = useAuthenticationContext();

  const measurementSystem = userInfoObject?.data?.measurementSystem;

  async function loadHeatData(
    id: number,
    setAnalyzedSlag: Dispatch<SetStateAction<AddAndUpdateAnalyzedSlag | null | undefined>> | undefined,
    setLadleFurnaceList: Dispatch<SetStateAction<LadleFurnaceModel[]>> | undefined,
    setTapList: React.Dispatch<React.SetStateAction<Tap[]>> | undefined,
    setCarryoverSlagType: Dispatch<SetStateAction<CarryoverSlagType>>,
    setAlloyChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setFluxesChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    heatCalculationInitialState: InitialState,
    setHeatCalculation: Dispatch<SetStateAction<InitialState>>,
    setDeoxidizerList: Dispatch<SetStateAction<DeoxidizersModel[]>> | undefined,
    setDeoxidizersChargeWeightList: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setDeoxidizersChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setDeoxidizerSelection: Dispatch<SetStateAction<DeoxidizerType | ''>>,
    setAlloyChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setFluxesChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>,
    setSteelChemistryPrimaryFurnaceSi: Dispatch<SetStateAction<string>>,
    setSteelChemistryPrimaryFurnaceDissolvedAl: Dispatch<SetStateAction<string>>,
    setSteelChemistryPrimaryFurnaceMn: Dispatch<SetStateAction<string>>,
    setSteelChemistryPrimaryFurnaceCr: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingSi: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingDissolvedAl: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingMn: Dispatch<SetStateAction<string>>,
    setSteelChemistryTappingCr: Dispatch<SetStateAction<string>>,
    setSelectedCarryoverSlag: Dispatch<SetStateAction<ISelectedCarryoverSlag | undefined>>,
    setRecommendedAdditions: Dispatch<SetStateAction<HeatRecommendationsDTO | undefined>>,
    setFluxList: Dispatch<SetStateAction<FluxModel[]>> | undefined,
    setFluxSelection1: Dispatch<SetStateAction<string>>,
    setSiRecovery: Dispatch<SetStateAction<string>>,
    setAlRecovery: Dispatch<SetStateAction<string>>,
    setMnRecovery: Dispatch<SetStateAction<string>>,
    setCrRecovery: Dispatch<SetStateAction<string>>,
    setUseR2O3Target: Dispatch<SetStateAction<boolean>>,
    setR2O3Target: Dispatch<SetStateAction<string>>,
    setHeatId: Dispatch<SetStateAction<number | undefined>> | undefined,
    setRecommendations: Dispatch<SetStateAction<HeatRecommendationsDTO | undefined>>,
    setResult: Dispatch<SetStateAction<ResultDTO | undefined>>,
    setCalculatedSlags: Dispatch<SetStateAction<CalculatedSlags | undefined>>,
    setSlagAppearance: Dispatch<SetStateAction<string>>,
    setSlagAppearanceText: Dispatch<SetStateAction<string>>,
    isDraft: boolean,
    isReadOnly: boolean,
    steelGradeList: SteelGradeModel[],
    setIsDesulfurizationEnabled: Dispatch<SetStateAction<boolean>>,
    setIsManganeseEnabled: Dispatch<SetStateAction<boolean>>,
    setIsChromiumEnabled: Dispatch<SetStateAction<boolean>>,
    currentPlantCarryoverSlagInferenceType?: CarryoverSlagInferenceType,
    carryoverSlagReferenceList?: CarryoverSlagReferenceGetResponse[],
    fluxesChargeWeightFixedList?: HeatChargeWeightArray,
    fluxesChargeWeightFixedListAtLF?: HeatChargeWeightArray,
    ladleFurnaceList?: LadleFurnaceModel[],
    processConstraintsList?: ProcessConstraintsDTO,
  ) {
    try {
      setStatus('loading');
      const data = isDraft ? await getDraftHeatById(id) : await getHeatById(id);
      setRequestOutput(data);
      const analyzedSlag = data.output.calculatedSlags.find(
        calculatedSlag => calculatedSlag.analyzed !== null && calculatedSlag.stageType === StageType.Output,
      )?.analyzed?.slags;
      if (setAnalyzedSlag) setAnalyzedSlag(analyzedSlag);
      const dummyID = 1;
      const CustomerId = data.input.details.customerId;
      let TapLadleFurnace = [];
      if (!ladleFurnaceList) {
        const ladlefurnaceDatafromHeat = [
          {
            customerID: CustomerId,
            description: data.input.details.ladleFurnace,
            id: dummyID,
            name: data.input.details.ladleFurnace,
            tapLadleFurnaces: [],
          },
        ];
        setLadleFurnaceList?.(ladlefurnaceDatafromHeat);

        TapLadleFurnace = [
          {
            code: data.input.heatSettings.transformerTap,
            arcLength: data.input.heatSettings?.arcLength || 0,
            default: false,
            minimumSlagDepth: data.input.heatSettings?.minimumSlagDepth || 0,
            minimumSlagWeight: data.input.heatSettings?.minimumSlagWeight
              ? Math.floor(data.input.heatSettings?.minimumSlagWeight)
              : '',
          },
        ];
      } else {
        const ladlefurnaceDatafromHeat = ladleFurnaceList?.find(ladleFurnaceItem => {
          return (
            ladleFurnaceItem.name === data.input.details.ladleFurnace &&
            ladleFurnaceItem.tapLadleFurnaces.some(
              tapLadleFurnaceItem =>
                tapLadleFurnaceItem.arcLength === data.input.heatSettings.arcLength &&
                tapLadleFurnaceItem.code === Number(data.input.heatSettings.transformerTap) &&
                tapLadleFurnaceItem.minimumSlagDepth === data.input.heatSettings.minimumSlagDepth &&
                tapLadleFurnaceItem.minimumSlagWeight === data.input.heatSettings.minimumSlagWeight,
            )
          );
        });
        setLadleFurnaceList?.(ladlefurnaceDatafromHeat ? [ladlefurnaceDatafromHeat] : []);

        const selectedTapLadleFurnace = ladlefurnaceDatafromHeat?.tapLadleFurnaces.find(tapItem => {
          return (
            tapItem.code === Number(data.input.heatSettings.transformerTap) &&
            tapItem.arcLength === data.input.heatSettings.arcLength &&
            tapItem.minimumSlagDepth === data.input.heatSettings.minimumSlagDepth &&
            tapItem.minimumSlagWeight === data.input.heatSettings.minimumSlagWeight
          );
        });

        TapLadleFurnace = selectedTapLadleFurnace
          ? [{ ...selectedTapLadleFurnace, code: selectedTapLadleFurnace.code }]
          : [
              {
                code: Number(''),
                arcLength: 0,
                default: false,
                minimumSlagDepth: 0,
                minimumSlagWeight: 0,
              },
            ];
      }

      setTapList?.(TapLadleFurnace as Tap[]);

      const carryoverSlagType =
        processConstraintsList?.carryoverSlagType !== undefined
          ? processConstraintsList?.carryoverSlagType
          : data?.input?.processConstraints?.carryoverSlagType;

      setCarryoverSlagType(carryoverSlagType);

      setAlloyChargeWeightList(
        (data.input.chargeWeights.stages?.find(
          stage => stage.stageName === StageName.Tapping && stage.stageType === StageType.Input,
        )?.alloys as HeatChargeWeightArray) || [],
      );

      const fluxesAtTapping =
        data.input.chargeWeights.stages?.find(
          stage => stage.stageName === StageName.Tapping && stage.stageType === StageType.Input,
        )?.fluxes || [];
      const addedFluxes = fluxesAtTapping.filter(
        flux =>
          !fluxesChargeWeightFixedList || !fluxesChargeWeightFixedList.some(fixedFlux => fixedFlux.name === flux.name),
      );

      setFluxesChargeWeightList(addedFluxes);

      const carryOverslagTypeChanged =
        !!data?.input?.processConstraints &&
        data.input.processConstraints.carryoverSlagType !== processConstraintsList?.carryoverSlagType;

      setIsDesulfurizationEnabled(data.input?.desulfurization !== null);

      const slagThicknessValue =
        measurementSystem === SystemOfMeasurementOptions.Imperial
          ? data.input.chargeWeights.slagThickness
          : convertMetersIntoOtherUnitOfMeasurement(data.input.chargeWeights.slagThickness as number, 'cm');

      const carryoverSlagWeightValue =
        carryoverSlagType === CarryoverSlagType.Thickness
          ? `${slagThicknessValue}`
          : `${data.input.chargeWeights.carryoverSlagWeight}`;

      setHeatCalculation({
        ...heatCalculationInitialState,
        tap: String(TapLadleFurnace[0].code),
        saturationMode: data.input.details.saturationMode,
        initialSulfurPercentage: data.input.desulfurization?.initialSulfurPercentage ?? null,
        finalOxygenInSteel: data.input.desulfurization?.finalOxygenInSteel ?? null,
        arcLength: TapLadleFurnace[0].arcLength.toString(),
        minimumSlagDepth: String(TapLadleFurnace[0].minimumSlagDepth ?? ''),
        minimumSlagWeight: String(TapLadleFurnace[0].minimumSlagWeight ?? ''),
        ladleFurnace: data.input.details.ladleFurnace,
        ladleFurnaceId: dummyID,
        ladleLifeTime: data.input.heatSettings.ladleLifeTime,
        isNewCampaign: Boolean(data.input.heatSettings.isNewCampaign),
        heatDescription: data.input.details.heatDescription ?? '',
        ...(!isDraft
          ? {
              heatName: data.input.details.heatName,
              ladleName: data.input.heatSettings.ladleName,
            }
          : {}),
        steelGrade: data.input.heatSettings.steelGrade,
        steelGradeCategory: data.input.heatSettings.steelGradeCategory,
        finalTemperature: data.input.heatSettings.finalTemperature,
        carryoverSlagWeight: carryOverslagTypeChanged && !!processConstraintsList ? '' : carryoverSlagWeightValue,
        skimmerActive: data.input.skimmer.active,
        skimmerPostSlagWeight: data.input.skimmer.postSlagWeight,
      });
      const deoxidizerDataList = [];
      const deoxidizerChemistriesfromHeat = data.input.chargeChemistries.deoxidizers;

      const deoxAtTapping = deoxidizerChemistriesfromHeat?.find(
        d =>
          d?.name ===
          data.input.chargeWeights.stages?.find(stage => stage.stageName === StageName.Tapping)?.deoxidizers[0]?.name,
      );

      if (deoxAtTapping) {
        const deoxAtTappingData = {
          id: dummyID,
          customerId: CustomerId,
          externalId: null,
          isEnabled: true,
          name: deoxAtTapping.name,
          si: deoxAtTapping.si,
          al: deoxAtTapping.al,
          ca: deoxAtTapping.ca,
          c: deoxAtTapping.c,
          carbonDeoxEfficiency: deoxAtTapping.carbonDeoxEfficiency,
          efficiencyFactor: deoxAtTapping.efficiencyFactor,
        };
        deoxidizerDataList.push(deoxAtTappingData);
      }

      const deoxSelected = deoxidizerChemistriesfromHeat.find(
        d => d?.name === data.input.heatSettings?.deoxidizerSelection,
      );
      if (deoxSelected) {
        const deoxSelectedData = {
          id: dummyID,
          customerId: CustomerId,
          externalId: null,
          isEnabled: true,
          name: deoxSelected.name,
          si: deoxSelected.si,
          al: deoxSelected.al,
          ca: deoxSelected.ca,
          c: deoxSelected.c,
          carbonDeoxEfficiency: deoxSelected.carbonDeoxEfficiency,
          efficiencyFactor: deoxSelected.efficiencyFactor,
        };
        deoxidizerDataList.push(deoxSelectedData);
      }

      const deoxStage1 = deoxidizerChemistriesfromHeat.find(
        d =>
          d?.name ===
          data.input.chargeWeights.stages.find(stage => stage.stageName === StageName.LadleFurnace1)?.deoxidizers[0]
            ?.name,
      );
      if (deoxStage1) {
        const deoxStage1Data = {
          id: dummyID,
          customerId: CustomerId,
          externalId: null,
          isEnabled: true,
          name: deoxStage1.name,
          si: deoxStage1.si,
          al: deoxStage1.al,
          ca: deoxStage1.ca,
          c: deoxStage1.c,
          carbonDeoxEfficiency: deoxStage1.carbonDeoxEfficiency,
          efficiencyFactor: deoxStage1.efficiencyFactor,
        };
        deoxidizerDataList.push(deoxStage1Data);
      }

      setDeoxidizerList?.(deoxidizerDataList);

      setDeoxidizersChargeWeightList(
        data.input.chargeWeights.stages.find(
          stage => stage.stageName === StageName.Tapping && stage.stageType === StageType.Input,
        )?.deoxidizers || [],
      );
      setDeoxidizersChargeWeightListAtLF(
        data.input.chargeWeights.stages.find(
          stage => stage.stageName === StageName.LadleFurnace1 && stage.stageType === StageType.Input,
        )?.deoxidizers || [],
      );
      setDeoxidizerSelection(data.input.heatSettings.deoxidizerSelection || DeoxidizerType.None);

      setAlloyChargeWeightListAtLF(
        data.input.chargeWeights.stages.find(
          stage => stage.stageName === StageName.LadleFurnace1 && stage.stageType === StageType.Input,
        )?.alloys || [],
      );

      if (fluxesChargeWeightFixedListAtLF) {
        const fluxesAtLadleFurnace =
          data.input.chargeWeights.stages.find(
            stage => stage.stageName === StageName.LadleFurnace1 && stage.stageType === StageType.Input,
          )?.fluxes || [];
        setFluxesChargeWeightListAtLF(
          fluxesAtLadleFurnace.filter(
            flux => fluxesChargeWeightFixedListAtLF.find(fixedFlux => fixedFlux.name === flux.name) === undefined,
          ),
        );
      } else {
        setFluxesChargeWeightListAtLF(
          data.input.chargeWeights.stages.find(
            stage => stage.stageName === StageName.LadleFurnace1 && stage.stageType === StageType.Input,
          )?.fluxes || [],
        );
      }

      const steelChemistryUnitOfMeasurement = (
        isReadOnly
          ? data.input.processConstraints.steelChemistryUnitOfMeasurement
          : processConstraintsList?.steelChemistryUnitOfMeasurement
      ) as SteelChemistryUnitOfMeasurement;

      const steelChemistryPrimaryFurnace = data.input.heatSettings.steelChemistries.find(
        steelChemistryItem => steelChemistryItem.stageName === SteelChemistryStageName.PrimaryFurnace,
      );
      const steelChemistryTapping = data.input.heatSettings.steelChemistries.find(
        steelChemistryItem => steelChemistryItem.stageName === SteelChemistryStageName.Tapping,
      );

      setSteelChemistryPrimaryFurnaceSi(
        calculateAndFormatSteelChemistry(steelChemistryUnitOfMeasurement, steelChemistryPrimaryFurnace?.si),
      );
      setSteelChemistryPrimaryFurnaceDissolvedAl(
        calculateAndFormatSteelChemistry(
          steelChemistryUnitOfMeasurement,
          steelChemistryPrimaryFurnace?.dissolvedAl,
          true,
        ),
      );
      setSteelChemistryPrimaryFurnaceMn(
        calculateAndFormatSteelChemistry(steelChemistryUnitOfMeasurement, steelChemistryPrimaryFurnace?.mn),
      );
      setSteelChemistryPrimaryFurnaceCr(
        calculateAndFormatSteelChemistry(steelChemistryUnitOfMeasurement, steelChemistryPrimaryFurnace?.cr),
      );
      setSteelChemistryTappingSi(
        calculateAndFormatSteelChemistry(steelChemistryUnitOfMeasurement, steelChemistryTapping?.si),
      );
      setSteelChemistryTappingDissolvedAl(
        calculateAndFormatSteelChemistry(steelChemistryUnitOfMeasurement, steelChemistryTapping?.dissolvedAl, true),
      );
      setSteelChemistryTappingMn(
        calculateAndFormatSteelChemistry(steelChemistryUnitOfMeasurement, steelChemistryTapping?.mn),
      );
      setSteelChemistryTappingCr(
        calculateAndFormatSteelChemistry(steelChemistryUnitOfMeasurement, steelChemistryTapping?.cr),
      );
      setIsManganeseEnabled(steelChemistryTapping?.mn !== null && steelChemistryPrimaryFurnace?.mn !== null);
      setIsChromiumEnabled(steelChemistryTapping?.cr !== null && steelChemistryPrimaryFurnace?.cr !== null);

      const isRanged = !!data.input.heatSettings.carryoverSlagTargetReferenceMaxValue;

      const foundSelectedCarryOverSlag = carryoverSlagReferenceList?.find(carryoverSlagReferenceItem => {
        return isRanged
          ? data.input.heatSettings.carryoverSlagTargetReferenceMinValue === carryoverSlagReferenceItem.minValue &&
              data.input.heatSettings.carryoverSlagTargetReferenceMaxValue === carryoverSlagReferenceItem.maxValue
          : data.input.heatSettings.carryoverSlagTargetReferenceMinValue === carryoverSlagReferenceItem.value;
      });

      const CarryoverSlagInferenceTypeChanged = isReadOnly
        ? false
        : data.input.heatSettings.carryoverSlagTargetReferenceInferenceType !== currentPlantCarryoverSlagInferenceType;

      if (
        !CarryoverSlagInferenceTypeChanged &&
        data.input.heatSettings.carryoverSlagTargetReferenceInferenceType !==
          CarryoverSlagInferenceType.SteelGradeCategory
      ) {
        setSelectedCarryoverSlag(
          !foundSelectedCarryOverSlag && !isReadOnly
            ? undefined
            : {
                id: foundSelectedCarryOverSlag?.id,
                value: isRanged
                  ? foundSelectedCarryOverSlag?.value ?? null
                  : data.input.heatSettings.carryoverSlagTargetReferenceMinValue,
                minValue: isRanged ? data.input.heatSettings.carryoverSlagTargetReferenceMinValue : null,
                maxValue: isRanged ? data.input.heatSettings.carryoverSlagTargetReferenceMaxValue : null,
                unitOfMeasurement: data.input.heatSettings?.carryoverSlagTargetReferenceUnitOfMeasurement,
              },
        );
      } else if (!CarryoverSlagInferenceTypeChanged) {
        const categoryId = steelGradeList?.find(sgc => sgc.name === data.input.heatSettings.steelGrade)?.categoryId;
        const selectedCarryoverSlag = carryoverSlagReferenceList?.find(csr => csr.steelGradeCategoryId === categoryId);
        setSelectedCarryoverSlag({
          unitOfMeasurement: null,
          id: selectedCarryoverSlag?.id,
          value: selectedCarryoverSlag?.value,
          name: t(selectedCarryoverSlag?.name as string),
        });
      }

      const additinoalRecommendations = data?.input?.chargeWeights.stages.find(
        stage => stage.stageType === StageType.ModifiedRecommendations,
      );
      const recomendation = data.output.recommendations;
      let rec: HeatRecommendationsDTO = {
        stageName: recomendation?.stageName,
        stageType: recomendation?.stageType,
        alloys: recomendation?.alloys?.map(a => ({ ...a, realAddedWeight: a.weight })),
        deoxidizers: recomendation?.deoxidizers?.map(a => ({ ...a, realAddedWeight: a.weight })),
        fluxes: recomendation?.fluxes?.map(a => ({ ...a, realAddedWeight: a.weight })),
      };
      if (additinoalRecommendations) {
        rec = {
          stageName: additinoalRecommendations?.stageName,
          stageType: additinoalRecommendations?.stageType,
          alloys: additinoalRecommendations?.alloys?.map(a => ({
            ...a,
            weight: rec.alloys?.find(x => x.name === a.name)?.weight || 0,
            realAddedWeight: a.weight,
          })),
          deoxidizers: additinoalRecommendations?.deoxidizers?.map(a => ({
            ...a,
            weight: rec.deoxidizers?.find(x => x.name === a.name)?.weight || 0,
            realAddedWeight: a.weight,
          })),
          fluxes: additinoalRecommendations?.fluxes?.map(a => ({
            ...a,
            weight: rec.fluxes?.find(x => x.name === a.name)?.weight || 0,
            realAddedWeight: a.weight,
          })),
        };
      }

      setRecommendedAdditions(rec);

      if (setFluxList) {
        const fluxDataList = [];
        const FluxSelectionChemistriesfromHeat = data.input.chargeChemistries.fluxes;

        const fluxSelection1Chem = FluxSelectionChemistriesfromHeat.find(
          f => f.name === data.input.heatSettings.fluxSelection[0]?.name,
        );
        if (fluxSelection1Chem) {
          const fluxSelection1Data = {
            name: fluxSelection1Chem.name,
            id: dummyID,
            customerId: CustomerId,
            externalId: null,
            mgO: fluxSelection1Chem.mgO,
            caO: fluxSelection1Chem.caO,
            al2O3: fluxSelection1Chem.al2O3,
            siO2: fluxSelection1Chem.siO2,
            feO: fluxSelection1Chem.feO,
            mnO: fluxSelection1Chem.mnO,
            cr2O3: fluxSelection1Chem.cr2O3,
            tiO2: fluxSelection1Chem.tiO2,
            caF2: fluxSelection1Chem.caF2,
            al: fluxSelection1Chem.al,
            na2O: fluxSelection1Chem.na2O,
            k2O: fluxSelection1Chem.k2O,
            type: fluxSelection1Chem.type,
          };
          fluxDataList.push(fluxSelection1Data);
        }

        const fluxSelection2Chem = FluxSelectionChemistriesfromHeat.find(
          f => f.name === data.input.heatSettings.fluxSelection[1]?.name,
        );
        if (fluxSelection2Chem) {
          const fluxSelection2Data = {
            name: fluxSelection2Chem.name,
            id: dummyID,
            customerId: CustomerId,
            externalId: null,
            mgO: fluxSelection2Chem.mgO,
            caO: fluxSelection2Chem.caO,
            al2O3: fluxSelection2Chem.al2O3,
            siO2: fluxSelection2Chem.siO2,
            feO: fluxSelection2Chem.feO,
            mnO: fluxSelection2Chem.mnO,
            cr2O3: fluxSelection2Chem.cr2O3,
            tiO2: fluxSelection2Chem.tiO2,
            caF2: fluxSelection2Chem.caF2,
            al: fluxSelection2Chem.al,
            na2O: fluxSelection2Chem.na2O,
            k2O: fluxSelection2Chem.k2O,
            type: fluxSelection2Chem.type,
          };
          fluxDataList.push(fluxSelection2Data);
        }

        setFluxList(fluxDataList);
      }

      setFluxSelection1(data.input.heatSettings?.fluxSelection[0]?.name || '');

      const alloyRecoveries = data.input.heatSettings.alloyRecoveries;

      const stage1Recoveries =
        !!alloyRecoveries && alloyRecoveries?.length >= 1
          ? alloyRecoveries[0]
          : {
              alRecovery: '',
              mnRecovery: '',
              siRecovery: '',
              crRecovery: '',
            };

      const { alRecovery, mnRecovery, siRecovery, crRecovery } = stage1Recoveries;

      setAlRecovery(alRecovery?.toString() ?? '');
      setSiRecovery(siRecovery?.toString() ?? '');
      setMnRecovery(mnRecovery?.toString() ?? '');
      setCrRecovery(crRecovery?.toString() ?? '');
      setUseR2O3Target(data.input.heatSettings.useR2O3Target);
      setR2O3Target(data.input.heatSettings.r2O3Target);
      setHeatId?.(Number(data.output.details.heatId));
      setRecommendations(data.output.recommendations);
      setResult(data.result);

      // Calculated B2 and B3
      calculateB2andB3(data.output.calculatedSlags);

      setCalculatedSlags(data.output.calculatedSlags);
      setSlagAppearance(data.result?.feedback?.slagAppearance || '');
      setSlagAppearanceText(data.result?.feedback?.text || '');
      setStatus('succeeded');
    } catch (err) {
      setStatus('error');
    }
  }

  return [status, loadHeatData, requestOutput];
}

export function calculateB2andB3(data: CalculatedSlags) {
  data.forEach(element => {
    element.calculated.b2 = element.calculated.slags.caO / element.calculated.slags.siO2;
    element.calculated.b3 =
      element.calculated.slags.caO / (element.calculated.slags.siO2 + element.calculated.slags.al2O3);

    element.target.b2 = element.target.slags.caO / element.target.slags.siO2;
    element.target.b3 = element.target.slags.caO / (element.target.slags.siO2 + element.target.slags.al2O3);
  });
}
