import { Box, TextField, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAvailableAtLF } from '@app/utils';
import NumberFormat from 'react-number-format';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import { AlloyListStateDTO, DeoxidizersModel, FluxModel } from './heatCalculationTypes';
import { HeatChargeWeightArray } from '@app/api/heats/HeatsResponse';
import FormWrapper from '@app/components/shared/FormWrapper';
import AdditionManagerComponent from '@app/components/shared/AdditionManagerComponent';

interface Step3Props {
  disabled?: boolean;
  alloyList: AlloyListStateDTO[];
  deoxidizerList: DeoxidizersModel[];
  fluxList: FluxModel[];
  siRecovery: string;
  setSiRecovery: Dispatch<SetStateAction<string>>;
  alRecovery: string;
  setAlRecovery: Dispatch<SetStateAction<string>>;
  mnRecovery: string;
  setMnRecovery: Dispatch<SetStateAction<string>>;
  crRecovery: string;
  setCrRecovery: Dispatch<SetStateAction<string>>;
  alloysChargeWeightListAtLF: HeatChargeWeightArray;
  setAlloyChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>;
  fluxesChargeWeightListAtLF: HeatChargeWeightArray;
  setFluxesChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>;
  fluxesChargeWeightFixedListAtLF: HeatChargeWeightArray;
  deoxidizersChargeWeightListAtLF: HeatChargeWeightArray;
  setDeoxidizersChargeWeightListAtLF: Dispatch<SetStateAction<HeatChargeWeightArray>>;
  plantIsIncompatible?: boolean;
  setHasUnsavedFluxesAdditions?: Dispatch<SetStateAction<boolean>>;
  setHasUnsavedAlloysAdditions?: Dispatch<SetStateAction<boolean>>;
  setHasUnsavedDeoxidizersAdditions?: Dispatch<SetStateAction<boolean>>;
  userClickedToAddUnaddedAdditions?: boolean;
}

function Step3({
  disabled = false,
  alloyList,
  deoxidizerList,
  fluxList,
  siRecovery,
  setSiRecovery,
  alRecovery,
  setAlRecovery,
  mnRecovery,
  setMnRecovery,
  crRecovery,
  setCrRecovery,
  alloysChargeWeightListAtLF,
  setAlloyChargeWeightListAtLF,
  fluxesChargeWeightListAtLF,
  setFluxesChargeWeightListAtLF,
  fluxesChargeWeightFixedListAtLF,
  deoxidizersChargeWeightListAtLF,
  setDeoxidizersChargeWeightListAtLF,
  plantIsIncompatible = false,
  setHasUnsavedFluxesAdditions,
  setHasUnsavedAlloysAdditions,
  setHasUnsavedDeoxidizersAdditions,
  userClickedToAddUnaddedAdditions
}: Step3Props) {
  const { t } = useTranslation();

  const [alloyName, setAlloyName] = useState('');
  const [alloyWeight, setAlloyWeight] = useState<string | number | undefined>('');
  const [fluxName, setFluxName] = useState('');
  const [fluxWeight, setFluxWeight] = useState<string | number | undefined>('');
  const [deoxidizerName, setDeoxidizerName] = useState('');
  const [deoxidizerWeight, setDeoxidizerWeight] = useState<string | number | undefined>('');
  const [openEditAlloy, setOpenEditAlloy] = useState(false);
  const [editedAlloy, setEditedAlloy] = useState('');
  const [newAlloyWeight, setNewAlloyWeight] = useState<string | number | undefined>('');

  const [openEditFlux, setOpenEditFlux] = useState(false);
  const [editedFlux, setEditedFlux] = useState('');
  const [newFluxWeight, setNewFluxWeight] = useState<string | number | undefined>('');

  const [openEditDeoxidizer, setOpenEditDeoxidizer] = useState(false);
  const [editedDeoxidizer, setEditedDeoxidizer] = useState('');
  const [newDeoxidizerWeight, setNewDeoxidizerWeight] = useState<string | number | undefined>('');

  const handleAddAlloy = useCallback(() => {
    const alloy = alloyList.find(a => a.name === alloyName);
    if (!alloy) return;

    setAlloyChargeWeightListAtLF([
      ...alloysChargeWeightListAtLF,
      { name: alloyName, weight: Number(alloyWeight), externalId: alloy.externalId },
    ]);

    setAlloyWeight('');
    setAlloyName('');
  }, [
    alloyList,
    alloyName,
    alloyWeight,
    alloysChargeWeightListAtLF,
    setAlloyChargeWeightListAtLF
  ]);

  const handleAddFlux = useCallback(() => {
    const flux = fluxList.find(a => a.name === fluxName);
    if (!flux) return;

    setFluxesChargeWeightListAtLF([
      ...fluxesChargeWeightListAtLF,
      { name: fluxName, weight: Number(fluxWeight), externalId: flux.externalId },
    ]);

    setFluxName('');
    setFluxWeight('');
  }, [
    fluxList,
    fluxName,
    fluxWeight,
    fluxesChargeWeightListAtLF,
     setFluxesChargeWeightListAtLF
  ]);

  const handleAddDeoxidizer = useCallback(() => {
    const deoxidizer = deoxidizerList.find(a => a.name === deoxidizerName);
    if (!deoxidizer) return;

    setDeoxidizersChargeWeightListAtLF([
      ...deoxidizersChargeWeightListAtLF,
      { name: deoxidizerName, weight: Number(deoxidizerWeight), externalId: deoxidizer.externalId },
    ]);

    setDeoxidizerName('');
    setDeoxidizerWeight('');
  }, [
    deoxidizerList,
    deoxidizerName,
    deoxidizerWeight,
    deoxidizersChargeWeightListAtLF,
    setDeoxidizersChargeWeightListAtLF
  ]);

  const isAddAlloyDisabled = React.useMemo(() => !alloyWeight || !alloyName, [alloyName, alloyWeight]);

  const isAddFluxDisabled = React.useMemo(() => !fluxWeight || !fluxName, [fluxName, fluxWeight]);

  const isAddDeoxidizerDisabled = React.useMemo(() => !deoxidizerWeight || !deoxidizerName, [deoxidizerName, deoxidizerWeight]);

  useEffect(() => {
    if(userClickedToAddUnaddedAdditions && !isAddAlloyDisabled) {
      handleAddAlloy();
    } else if(userClickedToAddUnaddedAdditions && !isAddFluxDisabled) {
      handleAddFlux();
    } else if(userClickedToAddUnaddedAdditions && !isAddDeoxidizerDisabled) {
      handleAddDeoxidizer();
    }
  }, [
    handleAddAlloy,
    handleAddFlux,
    handleAddDeoxidizer,
    isAddAlloyDisabled,
    isAddFluxDisabled,
    isAddDeoxidizerDisabled,
    userClickedToAddUnaddedAdditions,
  ])

  useEffect(() => {
    setHasUnsavedAlloysAdditions?.(!isAddAlloyDisabled);
  }, [isAddAlloyDisabled, setHasUnsavedAlloysAdditions]);

  useEffect(() => {
    setHasUnsavedFluxesAdditions?.(!isAddFluxDisabled);
  }, [isAddFluxDisabled, setHasUnsavedFluxesAdditions]);

  useEffect(() => {
    setHasUnsavedDeoxidizersAdditions?.(!isAddDeoxidizerDisabled);
  }, [isAddDeoxidizerDisabled, setHasUnsavedDeoxidizersAdditions])

  function shouldDisableAlloy(name: string) {
    return alloysChargeWeightListAtLF.some(a => a.name === name);
  }

  function shouldDisableFlux(name: string) {
    return fluxesChargeWeightListAtLF.some(a => a.name === name);
  }

  function shouldDisableDeoxidizer(name: string) {
    return deoxidizersChargeWeightListAtLF.some(a => a.name === name);
  }

  function handleEditAlloy(alloy: { name: string, weight: string | number }) {
    setEditedAlloy(alloy.name);
    setOpenEditAlloy(true);
    setNewAlloyWeight(alloy.weight as string);
  }

  function handleEditFlux(flux: { name: string, weight: string | number }) {
    setEditedFlux(flux.name);
    setOpenEditFlux(true);
    setNewFluxWeight(flux.weight as string);
  }

  function handleEditDeoxidizer(deoxidizer: { name: string, weight: string | number }) {
    setEditedDeoxidizer(deoxidizer.name);
    setOpenEditDeoxidizer(true);
    setNewDeoxidizerWeight(deoxidizer.weight as string);
  }


  function handleEditWeightAlloy() {
    if (!newAlloyWeight) return;

    const alloy = alloysChargeWeightListAtLF.find(a => a.name === editedAlloy);
    const otherAlloys = alloysChargeWeightListAtLF.filter(a => a.name !== editedAlloy);

    if (!alloy) return;

    setAlloyChargeWeightListAtLF([...otherAlloys, { ...alloy, weight: Number(newAlloyWeight) }]);

    setOpenEditAlloy(false);
    setNewAlloyWeight('');
    setEditedAlloy('');
  }

  function handleEditWeightFlux() {
    if (!newFluxWeight) return;

    const flux = fluxesChargeWeightListAtLF.find(a => a.name === editedFlux);
    const otherFluxes = fluxesChargeWeightListAtLF.filter(a => a.name !== editedFlux);

    if (!flux) return;

    setFluxesChargeWeightListAtLF([...otherFluxes, { ...flux, weight: Number(newFluxWeight) }]);

    setOpenEditFlux(false);
    setNewFluxWeight('');
    setEditedFlux('');
  }

  function handleEditWeightDeoxidizer() {
    if (!newDeoxidizerWeight) return;

    const deoxidizer = deoxidizersChargeWeightListAtLF.find(a => a.name === editedDeoxidizer);
    const otherDeoxidizers = deoxidizersChargeWeightListAtLF.filter(a => a.name !== editedDeoxidizer);

    if (!deoxidizer) return;

    setDeoxidizersChargeWeightListAtLF([...otherDeoxidizers, { ...deoxidizer, weight: Number(newDeoxidizerWeight) }]);

    setOpenEditDeoxidizer(false);
    setNewDeoxidizerWeight('');
    setEditedDeoxidizer('');
  }

  function handleDeleteAlloy(alloy: { name: string }) {
    const otherAlloys = alloysChargeWeightListAtLF.filter(a => a.name !== alloy.name);
    setAlloyChargeWeightListAtLF(otherAlloys);
  }

  function handleDeleteFlux(flux: { name: string }) {
    const otherFluxes = fluxesChargeWeightListAtLF.filter(a => a.name !== flux.name);
    setFluxesChargeWeightListAtLF(otherFluxes);
  }

  function handleDeleteDeoxidizer(deoxidizer: { name: string }) {
    const otherDeoxidizers = deoxidizersChargeWeightListAtLF.filter(a => a.name !== deoxidizer.name);
    setDeoxidizersChargeWeightListAtLF(otherDeoxidizers);
  }

  const MAX_PERCENTAGE = 100;

  return (
    <Box display="flex" flexDirection="column" position="relative" style={{ gap: '8px' }}>
      {plantIsIncompatible && (
        <WarningMessageComponent
          message={t("draft-edit.plant-config-error.message")}
        />
      )}
      <FormWrapper>
        <AdditionManagerComponent
          style={{ minWidth: '225px' }}
          title={t('alloys')}
          additionList={alloyList}
          additionName={alloyName}
          setAdditionName={setAlloyName}
          additionWeight={alloyWeight}
          setAdditionWeight={setAlloyWeight}
          additionSelectLabel={t('alloys')}
          additionSelectOptionList={alloyList.map(a => ({name: a.name, value: a.name, disabled: shouldDisableAlloy(a.name)}))}
          chargeAdditionWeightList={alloysChargeWeightListAtLF}
          dialogIsVisible={openEditAlloy}
          setDialogIsVisible={setOpenEditAlloy}
          dialogTitle={t('alloy.edit')}
          disabled={disabled}
          handleAdd={handleAddAlloy}
          handleDeleteAddition={handleDeleteAlloy}
          handleEditAddition={handleEditAlloy}
          handleEditAdditionWeight={handleEditWeightAlloy}
          setNewAdditionWeight={setNewAlloyWeight}
          isAdditionDisabled={isAddAlloyDisabled}
          listTitle={t('alloy')}
          newAdditionWeight={newAlloyWeight as number}
          plantIsIncompatible={plantIsIncompatible}
        >
          <Typography style={{ textAlign: 'center', margin: '16px 0' }} variant='subtitle2'>{t('new-heat.form.title.alloy-recovery')}</Typography>
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'center' }}>
            <FormControl style={{ maxWidth: '150px' }}>
              <NumberFormat
                name="alRecovery"
                label={t('new-heat.form.text-field.al-recovery')}
                InputLabelProps={{ shrink: true }}
                value={alRecovery}
                disabled={disabled}
                autoComplete="off"
                onValueChange={e => setAlRecovery(String(e.floatValue ?? ''))}
                customInput={TextField}
                allowNegative={false}
                decimalScale={2}
                decimalSeparator={t('decimal-scale-separator')}
                isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_PERCENTAGE }
              />
            </FormControl>

            <FormControl style={{ maxWidth: '150px' }}>
              <NumberFormat
                name="siRecovery"
                label={t('new-heat.form.text-field.si-recovery')}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                value={siRecovery}
                autoComplete="off"
                onValueChange={e => setSiRecovery(String(e.floatValue ?? ''))}
                customInput={TextField}
                allowNegative={false}
                decimalScale={2}
                decimalSeparator={t('decimal-scale-separator')}
                isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_PERCENTAGE }
              />
            </FormControl>

            <FormControl style={{ maxWidth: '150px' }}>
              <NumberFormat
                name="mnRecovery"
                label={t('new-heat.form.text-field.mn-recovery')}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                value={mnRecovery}
                autoComplete="off"
                onValueChange={e => setMnRecovery(String(e.floatValue ?? ''))}
                customInput={TextField}
                allowNegative={false}
                decimalScale={2}
                decimalSeparator={t('decimal-scale-separator')}
                isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_PERCENTAGE }
              />
            </FormControl>

            <FormControl style={{ maxWidth: '150px' }}>
              <NumberFormat
                name="crRecovery"
                label={t('new-heat.form.text-field.cr-recovery')}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                value={crRecovery}
                autoComplete="off"
                onValueChange={e => setCrRecovery(String(e.floatValue ?? ''))}
                customInput={TextField}
                allowNegative={false}
                decimalScale={2}
                decimalSeparator={t('decimal-scale-separator')}
                isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_PERCENTAGE }
              />
            </FormControl>
          </Box>
        </AdditionManagerComponent>

        <AdditionManagerComponent
          style={{ minWidth: '225px' }}
          title={t('fluxes')}
          additionList={fluxList}
          additionName={fluxName}
          setAdditionName={setFluxName}
          additionWeight={fluxWeight}
          setAdditionWeight={setFluxWeight}
          additionSelectLabel={t('fluxes')}
          additionSelectOptionList={
            fluxList
              .filter(f => isAvailableAtLF(f.availability ?? ''))
              .map(f => ({name: f.name, value: f.name, disabled: shouldDisableFlux(f.name)}))
          }
          chargeAdditionWeightList={fluxesChargeWeightListAtLF}
          dialogIsVisible={openEditFlux}
          setDialogIsVisible={setOpenEditFlux}
          dialogTitle={t('flux.edit')}
          disabled={disabled}
          handleAdd={handleAddFlux}
          handleDeleteAddition={handleDeleteFlux}
          handleEditAddition={handleEditFlux}
          handleEditAdditionWeight={handleEditWeightFlux}
          setNewAdditionWeight={setNewFluxWeight}
          isAdditionDisabled={isAddFluxDisabled}
          listTitle={t('flux')}
          newAdditionWeight={newFluxWeight as number}
          plantIsIncompatible={plantIsIncompatible}
          fixedAdditionsList={fluxesChargeWeightFixedListAtLF}
        />

        <AdditionManagerComponent
          style={{ minWidth: '270px' }}
          title={t('slag-deoxidizers')}
          additionList={deoxidizerList}
          additionName={deoxidizerName}
          setAdditionName={setDeoxidizerName}
          additionWeight={deoxidizerWeight}
          setAdditionWeight={setDeoxidizerWeight}
          additionSelectLabel={t('slag-deoxidizers')}
          additionSelectOptionList={deoxidizerList.map(a => ({name: a.name, value: a.name, disabled: shouldDisableDeoxidizer(a.name)}))}
          chargeAdditionWeightList={deoxidizersChargeWeightListAtLF}
          dialogIsVisible={openEditDeoxidizer}
          setDialogIsVisible={setOpenEditDeoxidizer}
          dialogTitle={t('slag-deoxidizers.edit')}
          disabled={disabled}
          handleAdd={handleAddDeoxidizer}
          handleDeleteAddition={handleDeleteDeoxidizer}
          handleEditAddition={handleEditDeoxidizer}
          handleEditAdditionWeight={handleEditWeightDeoxidizer}
          setNewAdditionWeight={setNewDeoxidizerWeight}
          isAdditionDisabled={isAddDeoxidizerDisabled}
          listTitle={t('slag-deoxidizer')}
          newAdditionWeight={newDeoxidizerWeight as number}
          plantIsIncompatible={plantIsIncompatible}
        />
      </FormWrapper>
    </Box>
  );
}

export default Step3;
