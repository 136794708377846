import { API_BASE_URL, APP_VERSION } from '@app/utils/constants';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import axios from 'axios';
import { camelizeKeys } from 'humps';
import { ReactElement, useCallback, useEffect, useState } from 'react';

interface BackendInfoResponse {
  apiVersion: string,
  engineVersion: string,
}

const AppVersion = (): ReactElement => {
  const [backendInfo, setBackendInfo] = useState<BackendInfoResponse>();

  const loadBackendInfo = useCallback(async () => {
    const response = await axios.get<BackendInfoResponse>(`${API_BASE_URL}v2/actuators/info`);

    if (response && response.status === 200 && response.data) {
      const data = camelizeKeys(response.data) as BackendInfoResponse;
      setBackendInfo(data);
    }
  }, []);

  useEffect(() => {
    loadBackendInfo();
  }, [loadBackendInfo]);

  const renderTooltip = () => {
    return (
      <Tooltip title={<>Web Version: {APP_VERSION}<br/>API Version: {backendInfo?.apiVersion}<br/>Engine Version: {backendInfo?.engineVersion}</>}>
        <InfoOutlined fontSize='small' style={{ cursor: 'help' }} />
      </Tooltip>
    )
  }

  return (
    <Box style={{ color: '#fff' }}>
      <Box display='flex' gridGap='5px'> 
        <Typography variant="body2" >
          {APP_VERSION}
        </Typography>
        {renderTooltip()}
      </Box>
    </Box>
  )
}

export default AppVersion;