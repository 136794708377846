import { Resource } from 'i18next';

function parseFilename(filename: string) {
  return filename.replace('./', '').replace('.json', '');
}

const resources: Resource = {};

// @ts-ignore
const files = require.context(__dirname, false, /\.(json)$/);

for (const langFile of files.keys()) {
  const kvp = files(langFile);
  const key = parseFilename(langFile);
  resources[key] = {
    translation: kvp,
  };
}

export default resources;
