import MainPaper from '@app/components/shared/MainPaper';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext';
import { handleSendEvent, ROUTES_PATH } from '@app/utils';
import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SteelGradeTable from './SteelGradeTable';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import Loading from '@app/components/shared/Loading';
import useLoadSteelGradeCategories from '@app/hooks/useLoadSteelGradeCategories';
import { PostSteelGradeCategoryModel, SteelGradeCategoryModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import CreateEditSteelGradeCategoryModal from '../modals/CreateEditSteelGradeCategoryModal';
import { useSteelGradeCategoriesService } from '@app/api/steelGradeCategories';
import Swal from 'sweetalert2';
import WarningDialog from '@app/components/shared/WarningDialog';
import useLoadCarryoverSlagsReference from '@app/hooks/useLoadCarryoverSlagsReference';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import { PERMISSIONS } from '@app/utils/constants';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import FormButton from '@app/components/shared/FormButton';

const SteelGradeContent = (): React.ReactElement => {
  const { t } = useTranslation();

  const { state: plantState } = usePlantContext();

  const {
    hasAccess
  } = useAuthenticationContext();

  const [
    {
      data: steelGradeCategories,
      status: loadSteelGradeCategoriesStatus
    },
    loadSteelGradeCategories
  ] = useLoadSteelGradeCategories();

  useEffect(() => {
    if(loadSteelGradeCategoriesStatus === 'idle') {
      loadSteelGradeCategories();
    }
  }, [loadSteelGradeCategories, loadSteelGradeCategoriesStatus]);

  const isLoadingSteelGradeCategories = loadSteelGradeCategoriesStatus === 'loading';

  const [targetSteelGradeCategory, setTargetSteelGradeCategory] = useState<SteelGradeCategoryModel | undefined>(undefined);
  const [createEditSteelGradeModalIsVisible, setCreateEditSteelGradeModalIsVisible] = useState(false);

  const [deleteCategoryWarningModalIsVisible, setDeleteCategoryWarningModalIsVisible] = useState(false);
  const [confirmDeleteCategoryModalIsVisible, setConfirmDeleteCategoryModalIsVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const { saveSteelGradesCategory, updateSteelGradesCategory, deleteSteelGradesCategory } = useSteelGradeCategoriesService();

  const [
    {
      data: carryoverSlagsReference,
      status: carryoverSlagsReferenceStatus
    },
    loadCarryoverSlagsReference,
    resetLoadCarryoverSlagsReferenceStatus
  ] = useLoadCarryoverSlagsReference();

  const saveSteelGradeCategory = async (payload: SteelGradeCategoryModel | PostSteelGradeCategoryModel) => {
    try {
      setLoading(true);

      targetSteelGradeCategory !== undefined
        ? await updateSteelGradesCategory({name: payload.name, steelGrades: payload.steelGrades} as Omit<SteelGradeCategoryModel, "id">, (payload as SteelGradeCategoryModel).id)
        : await saveSteelGradesCategory(payload);

      setTargetSteelGradeCategory(undefined);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });


      loadSteelGradeCategories();
      setCreateEditSteelGradeModalIsVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const deleteSteelGradeCategory = useCallback(async () => {
    try {
      setLoading(true);

      await deleteSteelGradesCategory((targetSteelGradeCategory as SteelGradeCategoryModel).id);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('exclusion-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      loadSteelGradeCategories();
    } finally {
      resetLoadCarryoverSlagsReferenceStatus();
      setTargetSteelGradeCategory(undefined);
      setLoading(false);
      setConfirmDeleteCategoryModalIsVisible(false);
    }
  }, [ deleteSteelGradesCategory, t, resetLoadCarryoverSlagsReferenceStatus, loadSteelGradeCategories, targetSteelGradeCategory ])

  useEffect(() => {
    if(carryoverSlagsReferenceStatus === 'succeeded' && (
        carryoverSlagsReference.length > 0
        || steelGradeCategories.length === 1
      )
      ) {
      setDeleteCategoryWarningModalIsVisible(true);
    } else if (
      carryoverSlagsReferenceStatus === 'succeeded'
      && carryoverSlagsReference.length === 0
      && targetSteelGradeCategory
      && !loading
      ) {
      setConfirmDeleteCategoryModalIsVisible(true);
    }
  }, [
    steelGradeCategories.length,
    carryoverSlagsReferenceStatus,
    setDeleteCategoryWarningModalIsVisible,
    carryoverSlagsReference,
    targetSteelGradeCategory,
    deleteSteelGradeCategory,
    loading
  ]);

  return (
    <>
      <ConfirmDialog
        checkboxEnabled
        visible={confirmDeleteCategoryModalIsVisible}
        setVisible={(boolean) => {
          resetLoadCarryoverSlagsReferenceStatus();
          setTargetSteelGradeCategory(undefined);
          setConfirmDeleteCategoryModalIsVisible(boolean);
        }}
        title={t('steel-grade-category.confirm-delete-dialog.title')}
        content={t('steel-grade-category.confirm-delete-dialog.content')}
        cancelText={t('button.cancel')}
        cancelButtonTestId="confirm-delete-steel-grade-category-dialog.cancel-button"
        confirmButtonTestId="confirm-delete-steel-grade-category-dialog.confirm-button"
        cancelAction={() => {
          handleSendEvent({
            category: 'Steel Grade Categories/Category',
            action: 'User Closed Delete Steel Grade Category Modal.'
          });
          resetLoadCarryoverSlagsReferenceStatus();
          setTargetSteelGradeCategory(undefined);
          setConfirmDeleteCategoryModalIsVisible(false);
        }}
        confirmText={t('steel-grade-category.confirm-delete-dialog.button.remove')}
        confirmAction={deleteSteelGradeCategory}
      />

      <WarningDialog
        closeDialogButtonTestId='close.delete-steel-grade-category.warning-dialog.button'
        dialogIsVisible={deleteCategoryWarningModalIsVisible}
        onClose={() => {
          resetLoadCarryoverSlagsReferenceStatus();
          setTargetSteelGradeCategory(undefined);
          setDeleteCategoryWarningModalIsVisible(false);
        }}
        warningMessage={steelGradeCategories.length === 1 ? t('steel-grade-category.cannot-delete-steel-grade.amount') :  t('steel-grade-category.cannot-delete-steel-grade.related-to-carryover-slags')}
        dialogTestId='steel-grade.warning-message.dialog'
      />
      <CreateEditSteelGradeCategoryModal
        onClose={() => {
          setTargetSteelGradeCategory(undefined);
          setCreateEditSteelGradeModalIsVisible(false);
        }}
        onSubmit={saveSteelGradeCategory}
        openModal={createEditSteelGradeModalIsVisible}
        targetSteelGradeCategory={targetSteelGradeCategory}
      />
      <Loading dataTestId='steel-grade-content-circular-progress' promiseInProgress={isLoadingSteelGradeCategories || loading} />

      <MainPaper
        removePadding
        title={t('steel-grade')}
        subheader={t('steel-grade-category.main-screen.subtitle')}
        headerActions={[
          <FormButton
            data-testid="add-new.steel-grade.button"
            variant="primary"
            key='buttom-add-new-carryover-slag'
            disabled={!hasAccess([PERMISSIONS.STEEL_GRADE_CREATE])}
            onClick={() => {
              handleSendEvent({
                category: 'BasicSettings/CarryoverSlag',
                action: 'User Clicked to Create a New Steel Grade.',
              });
              setCreateEditSteelGradeModalIsVisible(true);
            }}
          >
            {t('button.add-new')}
          </FormButton>
        ]}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {
              (
                !plantState.data.general
              ) && (
                <WarningMessageComponent
                  withPadding
                  testId="steel-grade.plant-validation.warning"
                  message={t('overview.empty-message')}
                  link={{
                    label: t('overview.view'),
                    url: ROUTES_PATH.PLANT_OVERVIEW,
                  }}
                />
              )
            }
            <SteelGradeTable
              setDeleteCategory={(steelGradeCategoryItem) => {
                setTargetSteelGradeCategory(steelGradeCategoryItem);
                loadCarryoverSlagsReference(steelGradeCategoryItem.id);
              }}
              setEditCategory={(steelGradeCategoryId) => {
                setCreateEditSteelGradeModalIsVisible(true);
                setTargetSteelGradeCategory(steelGradeCategories.find(steelGradeItem => steelGradeItem.id === steelGradeCategoryId));
              }}
              data={steelGradeCategories}
            />
          </Box>
      </MainPaper>
    </>
  );
};

export default SteelGradeContent;
