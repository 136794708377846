import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

const SaveOnExitDialog = (props: Props): React.ReactElement => {
  const {
    when,
    shouldBlockNavigation,
    shouldKeepOnScreenWhenCancel = false,
    onSave,
    onCancel,
    disableSaveButton = false,
    cancelText,
    confirmText,
    content,
    title
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, lastLocation]);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }

  const handleDiscard = () => {
    onCancel?.();
    if (shouldKeepOnScreenWhenCancel) {
      setModalVisible(false);
    } else {
      setConfirmedNavigation(true);
    }
  }

  const handleSave = async () => {
    const success = await onSave();

    if (success) {
      setConfirmedNavigation(true);
    }

    setModalVisible(false);
  }

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <ConfirmDialog
        visible={modalVisible}
        setVisible={setModalVisible}
        title={title ?? t('save-on-exit.title')}
        content={content ?? t('save-on-exit.content')}
        cancelText={cancelText ?? t('save-on-exit.button.discard')}
        confirmText={confirmText ?? t('button.save')}
        cancelAction={handleDiscard}
        confirmAction={handleSave}
        disableSaveButton={disableSaveButton}
      />
    </>
  );
};

interface Props {
  when?: boolean;
  shouldBlockNavigation: boolean;
  shouldKeepOnScreenWhenCancel?: boolean;
  onSave: () => Promise<boolean | undefined>;
  onCancel?: () => void;
  disableSaveButton?: boolean;
  title?: string;
  content?: string;
  cancelText?: string;
  confirmText?: string;
}

export default SaveOnExitDialog;
