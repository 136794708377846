import { useMeService } from '@app/api/me';
import { pcaB2B, pcaB2C } from '@app/config/msal.config';
import { HooksStatus } from '@app/hooks/types';
import { MSAL_SCOPES_B2B, MSAL_SCOPES_B2C } from '@app/utils/constants';
import { AccountInfo, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { decode, JwtPayload } from 'jsonwebtoken';
import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLanguageContext } from '../languageContext/languageContext';
import { AuthenticationActions } from './authenticationActions';
import {
  AuthenticationDispatch,
  AuthenticationReducer,
  AUTHENTICATION_INITIAL_DISPATCH
} from './authenticationReducer';
import { AuthenticationState, AUTHENTICATION_INITIAL_STATE } from './authenticationState';
import { useMsalService } from '@app/config/useMsalService';
import { useLimitsService } from '@app/api/limits';
import { useMeasurementSystemContext } from '../MeasurementSystemContext/measurementSystemContext';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';

const AuthenticationStateContext = createContext<AuthenticationState>(AUTHENTICATION_INITIAL_STATE);
const AuthenticationDispatchContext = createContext<AuthenticationDispatch>(AUTHENTICATION_INITIAL_DISPATCH);

type AuthenticationProps = { children: React.ReactNode };

function AuthenticationProvider({ children }: AuthenticationProps) {
  const [state, dispatch] = useReducer(AuthenticationReducer, AUTHENTICATION_INITIAL_STATE);  
  const IsEndToEndEnvironment = localStorage.getItem('e2e.login') === 'true';
  const isB2C = localStorage.getItem('isB2C') === 'true';
  const scopes = isB2C ? MSAL_SCOPES_B2C : MSAL_SCOPES_B2B;
  const pca = isB2C ? pcaB2C : pcaB2B;
  
  return (
    IsEndToEndEnvironment ? 
      <AuthenticationStateContext.Provider value={state}>
        <AuthenticationDispatchContext.Provider value={dispatch}>{children}</AuthenticationDispatchContext.Provider>
      </AuthenticationStateContext.Provider>
    :
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{ scopes }}>
          <AuthenticationStateContext.Provider value={state}>
            <AuthenticationDispatchContext.Provider value={dispatch}>{children}</AuthenticationDispatchContext.Provider>
          </AuthenticationStateContext.Provider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
  );
}

interface AccountDTO extends Omit<AccountInfo, 'idTokenClaims'> {
  idTokenClaims: {
    roles?: string[],
  }
}

let account: AccountInfo;
const getAccount = async (msalAccounts: AccountInfo[], getAccessToken: () => Promise<string | null>): Promise<AccountInfo> => {
  if (account) {
    return account;
  }

  const IsEndToEndEnvironment = localStorage.getItem('e2e.login') === 'true';

  if (IsEndToEndEnvironment) {
    const token = decode(await getAccessToken() || '') as JwtPayload;

    account = {
        homeAccountId: '',
        environment: '',
        tenantId: token.tid,
        username: token.appid,
        localAccountId: token.oid,
        name: '',
        idTokenClaims: {
          roles: token?.roles || []
        }
    };
  } else if (msalAccounts.length > 0) {
    const token = decode(await getAccessToken() || '') as JwtPayload;

    account = msalAccounts[0];
    account.idTokenClaims = {
      ...account.idTokenClaims,
      roles: token?.roles || []
    };
  }

  return account;
}

function useAuthenticationContext() {
  const state = React.useContext(AuthenticationStateContext);
  
  const [loadStatus, setLoadStatus] = useState<HooksStatus>('idle');
  const [account, setAccount] = useState<AccountInfo>();

  const { t } = useTranslation();
  const { accounts: msalAccounts, instance } = useMsal();
  const { getAccessToken } = useMsalService();
  const { getUserInfo } = useMeService();
  const { getLimits } = useLimitsService();

  const { updateSelectedMeasurementSystem } = useMeasurementSystemContext();
  
  const history = useHistory();

  const { updateLanguageLocally, state: { selectedLanguage } } = useLanguageContext();
  
  useEffect(()=> {
    getAccount(msalAccounts, getAccessToken).then(response => {
      setAccount(response);
    });
  }, [msalAccounts, getAccessToken]);

  useEffect(() => {
    if(localStorage.getItem('isB2C') === null){
      history?.replace('/');
    }
  }, [history])

  if (state === undefined) {
    throw new Error('useAuthenticationState should be used inside an AuthenticationProvider');
  }

  const dispatch = React.useContext(AuthenticationDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useAuthenticationDispatch should be used inside an AuthenticationProvider');
  }

  const logout = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  const hasAccess = (roles?: string[]): boolean => {
    if (!roles || roles.length === 0) return true;

    // @ts-ignore
    if (!account?.idTokenClaims?.roles) return false;

    // @ts-ignore
    return (account.idTokenClaims.roles as string[]).some(x => roles.some(r => r === x));
  };

  const loadUserInfo = async () => {
    try {
      dispatch({ type: AuthenticationActions.SET_USER_INFO_OBJECT, payload: { status: 'loading', data: state.userInfoObject?.data } });
      
      const response = await getUserInfo();

      if (response.languageName !== selectedLanguage) {
        updateLanguageLocally(response.languageName);
      }

      updateSelectedMeasurementSystem(response.measurementSystem);

      await loadLimits(response.measurementSystem);

      dispatch({ type: AuthenticationActions.SET_USER_INFO_OBJECT, payload: { status: 'succeeded', data: response} });
    } catch (e) {
      dispatch({ type: AuthenticationActions.SET_USER_INFO_OBJECT, payload: { status: 'error', data: undefined } });
      throw(e);
    }
  };

  const loadLimits = useCallback(async (measurementSystem?: SystemOfMeasurementOptions) => {
    try {
      dispatch({ type: AuthenticationActions.SET_LIMITS_OBJECT, payload: { status: 'loading', data: state.limitsInfoObject?.data }});

      const response = await getLimits(measurementSystem);

      dispatch({ type: AuthenticationActions.SET_LIMITS_OBJECT, payload: { status: 'succeeded', data: response }});
    } catch (e) {
      dispatch({ type: AuthenticationActions.SET_LIMITS_OBJECT, payload: { status: 'error', data: undefined }});
    }
  }, [dispatch, getLimits, state.limitsInfoObject?.data]);

  const initialLoad = async () => {
    if ((account as AccountDTO)?.idTokenClaims?.roles) {
      try {
        setLoadStatus("loading");
        await loadUserInfo();
        setLoadStatus("succeeded");
      } catch (err) {
        setLoadStatus("error");
      } finally {
        dispatch({ type: AuthenticationActions.SET_INITIAL_LOAD_COMPLETE, payload: true });
      }
    }
  }

  useEffect(() => {
    if (account) {
      // @ts-ignore
      const roles = (account.idTokenClaims?.roles as string[]) || [];

      // Checks if user has any permission for LSM
      if (roles.length) {
        dispatch({ type: AuthenticationActions.SET_ACCOUNT_INFO, payload: account });
      }
    }

  }, [account, dispatch, logout, t]);

  return { state, logout, hasAccess, initialLoad, loadUserInfo, loadStatus };
}

export { AuthenticationProvider, useAuthenticationContext };

