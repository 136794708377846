import { AlloysResponse, useAlloysService } from '@app/api/alloys';
import { useState } from 'react';
import { ListInfoObject } from "./types"

const infoObjectInitialState: ListInfoObject<AlloysResponse> = {
  status: 'idle',
  data: [],
}

const useLoadAlloys = (): [
  ListInfoObject<AlloysResponse>,
  () => Promise<void>,
] => {


  const [infoObject, setInfoObject] = useState<ListInfoObject<AlloysResponse>>(infoObjectInitialState);

  const { getAlloys } = useAlloysService();

  const loadAlloys = async () => {
    try {
      setInfoObject(prevState => ({... prevState, status: 'loading'}));

      const response = await getAlloys();
      setInfoObject({data: response, status: 'succeeded'});
    } catch (err) {
      
      setInfoObject({data: [], status: 'error'});
    } 
  };

  return [infoObject, loadAlloys];
}

export default useLoadAlloys;