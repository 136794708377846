import { useTheme, IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';
import ConditionalTooltip, { ConditionalTooltipProps } from './ConditionalTooltip';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  FileCopyOutlined,
  LockOpenOutlined,
  LockOutlined,
  MailOutline,
  VisibilityOutlined
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface GenericButtonProps extends CustomIconButtonProps {
  icon: React.ReactElement;
  tooltipFallbackMessageTranslationKey?: string;
}

const GenericButton = ({ icon, ...props }: GenericButtonProps): React.ReactElement => {
  const { tooltipMessage, size, tooltipFallbackMessageTranslationKey, ...rest } = props;
  const { t } = useTranslation();

  return (
    <CustomIconButton
      {...rest}
      tooltipMessage={
        tooltipMessage
        || (tooltipFallbackMessageTranslationKey ? t(tooltipFallbackMessageTranslationKey) : undefined)
      }
    >
      {React.cloneElement(icon, { fontSize: size || 'medium', style: { display: 'flex', alignItems: 'center' } })}
    </CustomIconButton>
  );
};

interface CustomIconButtonProps extends Omit<IconButtonProps, 'color'> {
  tooltipMessage?: string;
  color?: string;
  errorColor?: boolean;
  size?: 'small' | 'medium';
  tooltipMessagePlacement?: ConditionalTooltipProps['messagePlacement'];
}

const CustomIconButton = ({
  children,
  tooltipMessage,
  color,
  errorColor,
  tooltipMessagePlacement,
  ...rest
}: CustomIconButtonProps): React.ReactElement => {
  const theme = useTheme();

  const defaultColor = color || theme.palette.info.main;

  return (
    <ConditionalTooltip
      condition={!!tooltipMessage} message={tooltipMessage || ''}
      messagePlacement={tooltipMessagePlacement}
    >
      <span style={{ color: defaultColor }}>
        <IconButton
          {...rest}
          color={errorColor ? 'default' : 'inherit'}
          size='small'
          style={{ backgroundColor: 'transparent', padding: 0, ...rest.style }}
        >
          {children}
        </IconButton>
      </span>
    </ConditionalTooltip>
  );
};

export const EditButton = (props: CustomIconButtonProps) => (
  <GenericButton
    {...props}
    icon={<EditOutlined />}
    tooltipFallbackMessageTranslationKey="button.edit"
  />
);

export const DownloadButton = (props: CustomIconButtonProps) => (
  <GenericButton
    {...props}
    icon={<CloudDownloadOutlined />}
    tooltipFallbackMessageTranslationKey="button.export"
  />
);

export const DuplicateButton = (props: CustomIconButtonProps) => (
  <GenericButton
    {...props}
    icon={<FileCopyOutlined />}
    tooltipFallbackMessageTranslationKey="heat.draft.button.tooltip.duplicate"
  />
);

export const ViewButton = (props: CustomIconButtonProps) => (
  <GenericButton
    {...props}
    icon={<VisibilityOutlined />}
    tooltipFallbackMessageTranslationKey="heat.draft.button.tooltip.retrieve"
  />
);

export const DeleteButton = (props: CustomIconButtonProps) => (
  <GenericButton
    {...props}
    icon={<DeleteOutlined />}
    tooltipFallbackMessageTranslationKey="button.delete"
    color="#f44366"
  />
);

export const ReinviteButton = (props: CustomIconButtonProps) => (
  <GenericButton
    {...props}
    icon={<MailOutline />}
    tooltipFallbackMessageTranslationKey="button.reinvite"
  />
)

interface BlockButtonProps extends CustomIconButtonProps {
  blocked: boolean;
}

export const BlockButton = ({ blocked, ...rest }: BlockButtonProps): React.ReactElement => {
  return (
    <GenericButton
      {...rest}
      icon={blocked ? <LockOutlined /> : <LockOpenOutlined />}
      tooltipFallbackMessageTranslationKey={blocked ? 'admin-panel.unblock' : 'button.block'}
    />
  );
};
