import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FormButton from './FormButton';

interface WarningDialogProps {
  warningMessage?: string;
  onClose: () => void;
  dialogTestId: string;
  closeDialogButtonTestId: string;
  dialogIsVisible: boolean;
}

const WarningDialog = (props: WarningDialogProps) => {
  const { t } = useTranslation();
  const {
    onClose,
    warningMessage,
    dialogTestId,
    dialogIsVisible,
    closeDialogButtonTestId,
  } = props;

  return (
    <Dialog 
      data-testid={dialogTestId} 
      open={dialogIsVisible} 
      onClose={onClose}
      PaperProps={{ 
        style: {
          padding: '24px',
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '24px' 
        } 
      }}
    >
      <DialogTitle style={{ padding: 0 }}>{t('warning-dialog.title')}</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
         <DialogContentText style={{ margin: 0 }}>{warningMessage}</DialogContentText>
      </DialogContent>

      <DialogActions style={{ padding: 0 }}>
        <FormButton 
          data-testid={closeDialogButtonTestId} 
          variant="secondary" 
          onClick={onClose}
        >
          {t('button.close')}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
