import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import FormCheckbox from './FormCheckbox';
import { Paper, PaperProps, TextField } from '@material-ui/core';
import { ForwardedRef } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FormAutocompleteWithTagsProps extends Omit<AutocompleteProps<string, true,false, boolean>, "renderInput"> {
  label?: string;
  handleCheckboxChange?: (option: string, checked: boolean) => void;
  translateOptions?: boolean;
  fixedHeight?: string;
}

const FormAutocompleteWithTags = ({ 
    label, 
    handleCheckboxChange = undefined,
    translateOptions = false,
    fixedHeight = undefined,
    freeSolo = false,
    disableCloseOnSelect = true,
    ...rest
}: FormAutocompleteWithTagsProps, ref?: ForwardedRef<HTMLInputElement>) => {

  const { t } = useTranslation();

  const CustomPaper = (props: PaperProps) => (
    <Paper elevation={8} {...props} />
  );

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
    let customStyle: React.CSSProperties;

    if (fixedHeight) {
      customStyle = { height: fixedHeight, overflow: 'auto' }
    } else {
      customStyle = { maxHeight: '120px', overflow: 'auto' }
    }

    return (
      <TextField
        {...params} 
        label={label} 
        variant='outlined'
        size='small'
        fullWidth
        InputProps={{
          ...params.InputProps,
          style: customStyle
        }} />
    )
  }

  return (
    <Autocomplete
      fullWidth
      PaperComponent={CustomPaper}
      multiple
      freeSolo={freeSolo}
      disableCloseOnSelect={disableCloseOnSelect}
      ref={ref}
      limitTags={1}
      {...rest}
      size='small'
      ChipProps={{ 
        color: 'primary',
      }}
      renderInput={renderInput}
      renderOption={(option, { selected }) => (
        <FormCheckbox
          id={option}
          value={selected}
          onChange={(checked) => handleCheckboxChange && handleCheckboxChange(option, checked)}
          label={translateOptions ? t(option) : option}
        />
      )}
    />
  );
};



const ForwaredFormAutocompleteWithTags = React.forwardRef(FormAutocompleteWithTags);

export default ForwaredFormAutocompleteWithTags;