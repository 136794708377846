import CarryoverSlagUnitOfMeasurement from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { CarryoverSlagInferenceType } from './PlantUpdateCarryoverSlagSettingsRequest';

export enum PlantStatus {
  ACTIVE = 'Active',
  TRIAL = 'Trial',
  BLOCKED = 'Blocked'
}

export interface PlantsGetResponse {
  id: string;
  name: string;
  description: string;
  customerId: number;
  customerName: string;
  plantStatus: PlantStatus;
  trialStart?: string;
  trialExpiration?: string;
  contractStart?: string;
  contractExpiration?: string;
  isExpired: boolean;
  usersCount: number;
  emailDomains: string[];
  applicationRegistrationId: string;
  createdBy?: string;
  createdDate?: string;
  carryoverSlagInferenceType?: CarryoverSlagInferenceType | null;
  carryoverSlagParameterValueName?: string | null;
  carryoverSlagUnitOfMeasurement?: CarryoverSlagUnitOfMeasurement | null;
  carryoverSlagReferenceInput?: string | null;
}
