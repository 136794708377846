import { Box, TextField, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { isAvailableAtLF, isFixedAdditionAmountAtLF, isFixedAdditionAmountAtTapping, isLimeSource, isMgOSource } from '@app/utils';
import { DeoxidizersModel, FluxModel } from './heatCalculationTypes';
import NumberFormat from 'react-number-format';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import WarningBox from '@app/components/shared/WarningBox';
import FormSelect from '@app/components/shared/FormSelect';
import { SteelChemistryConfigs, SteelChemistryUnitOfMeasurement } from '@app/api/processConstraints/useProcessConstraintsService';
import { CHEMISTRY_AT_TAPPING_ALUMINUM_PERCENTAGE_DECIMAL_PLACES, CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS, CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES, CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES } from '@app/utils/constants';
import { CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE } from '@app/utils/constants';
import { CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_POINTS } from '@app/utils/constants';
import { CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_PERCENTAGE } from '@app/utils/constants';
import { DeoxidizerType } from '@app/api/heats/HeatsResponse';
import ConditionalTooltip from '@app/components/shared/ConditionalTooltip';
import FormCheckbox from '@app/components/shared/FormCheckbox';
import FormWrapper from '@app/components/shared/FormWrapper';
import FormSectionContainer from '@app/components/shared/FormSectionContainer';

interface Step4Props {
  disabled?: boolean;
  steelChemistryPrimaryFurnaceSi: string;
  setSteelChemistryPrimaryFurnaceSi: Dispatch<SetStateAction<string>>;
  steelChemistryPrimaryFurnaceDissolvedAl: string;
  setSteelChemistryPrimaryFurnaceDissolvedAl: Dispatch<SetStateAction<string>>;
  steelChemistryPrimaryFurnaceMn: string;
  setSteelChemistryPrimaryFurnaceMn: Dispatch<SetStateAction<string>>;
  steelChemistryPrimaryFurnaceCr: string;
  setSteelChemistryPrimaryFurnaceCr: Dispatch<SetStateAction<string>>;
  steelChemistryTappingSi: string;
  setSteelChemistryTappingSi: Dispatch<SetStateAction<string>>;
  steelChemistryTappingDissolvedAl: string;
  setSteelChemistryTappingDissolvedAl: Dispatch<SetStateAction<string>>;
  steelChemistryTappingMn: string;
  setSteelChemistryTappingMn: Dispatch<SetStateAction<string>>;
  steelChemistryTappingCr: string;
  setSteelChemistryTappingCr: Dispatch<SetStateAction<string>>;
  fluxSelection1: string;
  setFluxSelection1: Dispatch<SetStateAction<string>>;
  deoxidizerSelection: DeoxidizerType | '';
  setDeoxidizerSelection: Dispatch<SetStateAction<DeoxidizerType | ''>>;
  useR2O3Target: boolean;
  setUseR2O3Target: Dispatch<SetStateAction<boolean>>;
  r2O3Target: string;
  setR2O3Target: Dispatch<SetStateAction<string>>;
  fluxList: FluxModel[];
  deoxidizerList: DeoxidizersModel[];
  plantIsIncompatibleAndCalculateIsDisabled?: boolean;
  plantIsIncompatibleAndCalculateIsEnabled?: boolean;
  plantIncompatibilityMessage?: string;
  inputChargeChemistriesFluxes?: FluxModel[];
  isViewMode?: boolean;
  isTappingMode?: boolean;
  isManganeseEnabled: boolean;
  setIsManganeseEnabled: Dispatch<SetStateAction<boolean>>;
  isChromiumEnabled: boolean;
  setIsChromiumEnabled: Dispatch<SetStateAction<boolean>>;
  steelChemistryConfigs: SteelChemistryConfigs | undefined;
}

function Step4({
  disabled = false,
  steelChemistryPrimaryFurnaceSi,
  setSteelChemistryPrimaryFurnaceSi,
  steelChemistryPrimaryFurnaceDissolvedAl,
  setSteelChemistryPrimaryFurnaceDissolvedAl,
  steelChemistryPrimaryFurnaceMn,
  setSteelChemistryPrimaryFurnaceMn,
  steelChemistryPrimaryFurnaceCr,
  setSteelChemistryPrimaryFurnaceCr,
  steelChemistryTappingSi,
  setSteelChemistryTappingSi,
  steelChemistryTappingDissolvedAl,
  setSteelChemistryTappingDissolvedAl,
  steelChemistryTappingMn,
  setSteelChemistryTappingMn,
  steelChemistryTappingCr,
  setSteelChemistryTappingCr,
  fluxSelection1,
  setFluxSelection1,
  deoxidizerSelection,
  setDeoxidizerSelection,
  useR2O3Target,
  setUseR2O3Target,
  r2O3Target,
  setR2O3Target,
  fluxList,
  deoxidizerList,
  plantIsIncompatibleAndCalculateIsDisabled = false,
  plantIsIncompatibleAndCalculateIsEnabled = false,
  plantIncompatibilityMessage = '',
  isViewMode = false,
  inputChargeChemistriesFluxes,
  isTappingMode = false,
  isManganeseEnabled,
  setIsManganeseEnabled,
  isChromiumEnabled,
  setIsChromiumEnabled,
  steelChemistryConfigs
}: Step4Props) {
  const { t } = useTranslation();

  const steelChemistryBoxWrapperStyle: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    gap: '8px',
    width: '100%'
  }

  const steelChemistryContentStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center'
  }

  const checkboxAndNumberFormatWrapperStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  }

  const steelChemistryMainBox: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }

  const {
    steelChemistryPrimaryFurnaceCode,
    steelChemistryTappingCode,
    steelChemistryUnitOfMeasurement
  } = steelChemistryConfigs || {
    steelChemistryPrimaryFurnaceCode: undefined,
    steelChemistryTappingCode: undefined,
    steelChemistryUnitOfMeasurement: undefined
  };

  const oldCaoSource = inputChargeChemistriesFluxes?.find(fluxItem => fluxItem.type === 'CaOSource');
  const oldMgoOnlyOrDolomaSource = inputChargeChemistriesFluxes?.find(fluxItem => ['DolomaSource', 'MgOOnlySource'].includes(fluxItem.type));

  const caoSource = fluxList.find(fluxItem => fluxItem.type === 'CaOSource')
  const mgoOnlyOrDolomaSource = fluxList.find(fluxItem => ['DolomaSource', 'MgOOnlySource'].includes(fluxItem.type))

  const filterFluxAdditional = React.useCallback((fl: FluxModel[]) => {
    return fl.filter(
      f =>
        isAvailableAtLF(f?.availability ?? '') &&
        !isLimeSource(f.type) &&
        !isMgOSource(f.type) &&
        !isFixedAdditionAmountAtLF(f?.availability ?? '') &&
        !isFixedAdditionAmountAtTapping(f?.availability ?? ''),
    );
  }, []);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Box
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          maxWidth: '2416px',
          margin: '0 auto',
          flexDirection: 'column',
          rowGap: '8px',
          paddingBottom: '8px',
        }}
      >
        {!disabled && (
          <Typography>{t('new-heat.form.warning.invalid-form')}</Typography>
        )}
        {
          (
            plantIsIncompatibleAndCalculateIsDisabled
            || plantIsIncompatibleAndCalculateIsEnabled
          ) && (
            <WarningMessageComponent
              message={plantIncompatibilityMessage}
            />
          )
        }
      </Box>
      <FormWrapper>
        <FormSectionContainer
          style={{ flex: 2, maxWidth: '1200px' }}
          title={t('steel-chemistry')}
        >
          <Box style={steelChemistryBoxWrapperStyle}>
            <Box style={steelChemistryContentStyles}>
              <h5 style={{ margin: 0 }}>{t('new-heat.form.title.steel-chemistry.primary-furnace')}</h5>
              {steelChemistryPrimaryFurnaceCode && (<h5 style={{ marginTop: 0 }}>{steelChemistryPrimaryFurnaceCode}</h5>)}
            </Box>
            <Box style={steelChemistryContentStyles}>
              <h5 style={{ margin: 0 }}>{t('tapping')}</h5>
              {steelChemistryTappingCode && (<h5 style={{ marginTop: 0 }}>{steelChemistryTappingCode}</h5>)}
            </Box>
          </Box>

          <ConditionalTooltip
            condition={steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points}
            message={t('hints.heat.steel-chemistry.si')}
            messagePlacement='left'
          >
            <Box style={steelChemistryMainBox}>
              <InputLabel shrink htmlFor="steelChemistryPrimaryFurnaceSi">
                {`${t('new-heat.form.text-field.steel-chemistry.si')} ${!disabled ? '*' : ''}`}
              </InputLabel>

              <Box style={steelChemistryBoxWrapperStyle}>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      autoComplete="off"
                      disabled={disabled}
                      name="steelChemistryPrimaryFurnaceSi"
                      onValueChange={e => setSteelChemistryPrimaryFurnaceSi(String(e.floatValue ?? ''))}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      value={steelChemistryPrimaryFurnaceSi}
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      autoComplete="off"
                      disabled={disabled}
                      name="steelChemistryTappingSi"
                      onValueChange={e => setSteelChemistryTappingSi(String(e.floatValue ?? ''))}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      value={steelChemistryTappingSi}
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>


          </ConditionalTooltip>

          <ConditionalTooltip
            condition={steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points}
            message={t('hints.heat.steel-chemistry.dissolved-al')}
            messagePlacement='left'
          >
            <Box style={steelChemistryMainBox}>
              <InputLabel shrink htmlFor="steelChemistryPrimaryFurnaceSi">
                {`${t('new-heat.form.text-field.steel-chemistry.dissolved-al')} ${!disabled ? '*' : ''}`}
              </InputLabel>
              <Box style={steelChemistryBoxWrapperStyle}>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      autoComplete="off"
                      disabled={disabled}
                      InputLabelProps={{ shrink: true }}
                      onValueChange={e => setSteelChemistryPrimaryFurnaceDissolvedAl(String(e.floatValue ?? ''))}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      value={steelChemistryPrimaryFurnaceDissolvedAl}
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_ALUMINUM_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      autoComplete="off"
                      disabled={disabled}
                      InputLabelProps={{ shrink: true }}
                      onValueChange={e => setSteelChemistryTappingDissolvedAl(String(e.floatValue ?? ''))}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      value={steelChemistryTappingDissolvedAl}
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_ALUMINUM_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_ALUMINUM_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>

          </ConditionalTooltip>

          <ConditionalTooltip
            condition={steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points}
            message={t('hints.heat.steel-chemistry.mn')}
            messagePlacement='left'
          >
            <Box
              style={checkboxAndNumberFormatWrapperStyles}
            >
              <FormControl>
                <Box>
                  <FormCheckbox
                    style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px' }}
                    disabled={disabled}
                    value={isManganeseEnabled}
                    id='isManganeseEnabledCheckbox'
                    label={`${t('new-heat.form.text-field.steel-chemistry.mn')} ${!disabled ? '*' : ''}`}
                    onChange={(checked) => {
                      setIsManganeseEnabled(checked);
                      if(!checked) {
                        setSteelChemistryPrimaryFurnaceMn('');
                        setSteelChemistryTappingMn('');
                      }
                    }}
                  />
                </Box>
              </FormControl>

              <Box style={steelChemistryBoxWrapperStyle}>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      autoComplete="off"
                      disabled={disabled || !isManganeseEnabled}
                      id='steelChemistryPrimaryFurnaceMn'
                      name='steelChemistryPrimaryFurnaceMn'
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      value={steelChemistryPrimaryFurnaceMn}
                      onValueChange={e => setSteelChemistryPrimaryFurnaceMn(String(e.floatValue ?? ''))}
                      customInput={TextField}
                      allowNegative={false}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      autoComplete="off"
                      allowNegative={false}
                      disabled={disabled || !isManganeseEnabled}
                      id='steelChemistryTappingMn'
                      name='steelChemistryTappingMn'
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      value={steelChemistryTappingMn}
                      onValueChange={e => setSteelChemistryTappingMn(String(e.floatValue ?? ''))}
                      customInput={TextField}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
              </Box>

            </Box>
          </ConditionalTooltip>

          <ConditionalTooltip
            condition={steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points}
            message={t('hints.heat.steel-chemistry.cr')}
            messagePlacement='left'
          >
            <Box
              style={checkboxAndNumberFormatWrapperStyles}
            >
              <FormControl>
                <Box>
                  <FormCheckbox
                    id='isChromiumEnabledCheckbox'
                    style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px'   }}
                    disabled={disabled}
                    label={`${t('new-heat.form.text-field.steel-chemistry.cr')} ${!disabled ? '*' : ''}`}
                    onChange={(checked) => {
                      setIsChromiumEnabled(checked);
                      if(!checked) {
                        setSteelChemistryPrimaryFurnaceCr('');
                        setSteelChemistryTappingCr('');
                      }
                    }}
                    value={isChromiumEnabled}
                  />
                </Box>
              </FormControl>
              <Box style={steelChemistryBoxWrapperStyle}>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      id='steelChemistryPrimaryFurnaceCr'
                      name='steelChemistryPrimaryFurnaceCr'
                      autoComplete="off"
                      allowNegative={false}
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      value={steelChemistryPrimaryFurnaceCr}
                      onValueChange={e => setSteelChemistryPrimaryFurnaceCr(String(e.floatValue ?? ''))}
                      customInput={TextField}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      disabled={disabled || !isChromiumEnabled}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
                <Box style={steelChemistryContentStyles}>
                  <FormControl fullWidth>
                    <NumberFormat
                      id='steelChemistryTappingCr'
                      name='steelChemistryTappingCr'
                      autoComplete="off"
                      allowNegative={false}
                      decimalScale={
                        steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_POINTS_DECIMAL_PLACES
                            : CHEMISTRY_AT_TAPPING_GENERAL_PERCENTAGE_DECIMAL_PLACES
                      }
                      decimalSeparator={t('decimal-scale-separator')}
                      value={steelChemistryTappingCr}
                      onValueChange={e => setSteelChemistryTappingCr(String(e.floatValue ?? ''))}
                      customInput={TextField}
                      InputProps={{
                        endAdornment:
                          steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? 'pts'
                            : '%'
                      }}
                      disabled={disabled || !isChromiumEnabled}
                      isAllowed={
                        ({floatValue}) => {
                          const maxValue =
                            steelChemistryUnitOfMeasurement === SteelChemistryUnitOfMeasurement.Points
                            ? CHEMISTRY_AT_TAPPING_GENERAL_MAX_POINTS
                            : CHEMISTRY_AT_TAPPING_GENERAL_MAX_PERCENTAGE;
                          return (floatValue ?? 0) <= maxValue;
                        }
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </ConditionalTooltip>
        </FormSectionContainer>

        <FormSectionContainer title={t('fluxes')}>
          <WarningBox withProblemToReport={!!oldCaoSource && oldCaoSource?.name !== caoSource?.name && plantIsIncompatibleAndCalculateIsEnabled} style={{ width: '100%' }}>
            <TextField
              fullWidth
              autoComplete="off"
              label={`${t('fluxType.caoSource')} ${!disabled ? '*' : ''}`}
              InputLabelProps={{ shrink: true }}
              value={isViewMode ? oldCaoSource?.name : caoSource?.name}
              disabled
              InputProps={{ disableUnderline: !!oldCaoSource && oldCaoSource?.name !== caoSource?.name && plantIsIncompatibleAndCalculateIsEnabled }}
            />
          </WarningBox>
          <WarningBox withProblemToReport={oldMgoOnlyOrDolomaSource?.name !== mgoOnlyOrDolomaSource?.name && plantIsIncompatibleAndCalculateIsEnabled} style={{ width: '100%' }}>
            <TextField
              fullWidth
              autoComplete="off"
              label={`${
                mgoOnlyOrDolomaSource?.type === 'MgOOnlySource' ?
                  t('new-heat.form.text-field.MgoSource') :
                  t('fluxType.dolomaSource')
                } ${!disabled ? '*' : ''}`
              }
              InputProps={{ disableUnderline: !!oldMgoOnlyOrDolomaSource && oldMgoOnlyOrDolomaSource?.name !== mgoOnlyOrDolomaSource?.name && plantIsIncompatibleAndCalculateIsEnabled }}
              InputLabelProps={{ shrink: true }}
              value={isViewMode ? oldMgoOnlyOrDolomaSource?.name : mgoOnlyOrDolomaSource?.name}
              disabled
            />
          </WarningBox>
          {!isTappingMode && (
            <FormSelect fullWidth
              id="HeatFluxSelection1"
              disabled={disabled}
              name="fluxSelection1"
              label={`${t('new-heat.form.text-field.flux-#1')} ${!disabled ? '*' : ''}`}
              value={fluxSelection1}
              onChange={e => setFluxSelection1(e.target.value as string)}
              withProblemToReport={
                plantIsIncompatibleAndCalculateIsDisabled && !!fluxSelection1 && !filterFluxAdditional(fluxList).some(item => item.name === fluxSelection1)
              }
              required
              options={
                isViewMode
                ? [{value: fluxSelection1, name: fluxSelection1}]
                : filterFluxAdditional(fluxList).map(
                  fluxItem => ({
                    value: fluxItem.name,
                    name: fluxItem.name,
                  })
                )
              }
            />
          )}
        </FormSectionContainer>

        <FormSectionContainer title={t('slag-deoxidizer')}>
          <Box>
            <FormSelect
              fullWidth
              id="deoxidizer"
              name="deoxidizer"
              label={`${t('slag-deoxidizer')} ${!disabled ? '*' : ''}`}
              disabled={disabled}
              options={[
                { value: DeoxidizerType.None, name: t('new-heat.form.text-field.none') },
                ...deoxidizerList.map((rowDeoxidizer) => (
                  {
                    value: rowDeoxidizer.name,
                    name: rowDeoxidizer.name,
                  }
                ))
              ]}
              value={deoxidizerSelection}
              onChange={e => {
                setDeoxidizerSelection(e.target.value as DeoxidizerType);
                if (e.target.value === DeoxidizerType.None) {
                  setUseR2O3Target(false);
                  setR2O3Target('');
                }
              }}
              required
            />
          </Box>
          {!!deoxidizerSelection.replace(DeoxidizerType.None, '') && (
            <Box>
              <FormSelect
                fullWidth
                id="oxidacaoEscoria"
                name="oxidacaoEscoria"
                label={`${t('new-heat.form.text-field.limit-feo+mno')} ${!disabled ? '*' : ''}`}
                value={useR2O3Target}
                required
                disabled={disabled}
                options={[
                  { value: true, name: t('option-yes') },
                  { value: false, name: t('option-no') }
                ]}
                onChange={e => {
                  setUseR2O3Target(e.target.value as boolean);
                  if (!e.target.value) {
                    setR2O3Target('');
                  }
                }}
              />
            </Box>

          )}

          {!!deoxidizerSelection.replace(DeoxidizerType.None, '') && useR2O3Target && (
            <Box>
              <FormSelect
                fullWidth
                id="oxidacaoEscoriaPorcentagem"
                name="oxidacaoEscoriaPorcentagem"
                label={`${t('new-heat.form.text-field.percent-feo+mno')} ${!disabled ? '*' : ''}`}
                value={r2O3Target}
                required
                disabled={disabled}
                options={[
                  { value: '3', name: '3' },
                  { value: '5', name: '5' }
                ]}
                onChange={e => setR2O3Target(e.target.value as string)}
              />
            </Box>
          )}
        </FormSectionContainer>
      </FormWrapper>
    </Box>
  );
}

export default Step4;
