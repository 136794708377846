import React, { createContext, ReactNode, useReducer } from 'react';

import { PlantState, initialPlantState } from './plantValidationState';
import { PlantDispatch, PlantValidationReducer, PLANT_INITIAL_DISPATCH } from './plantValidationReducer';
import { PlantValidationActions } from './plantValidationActions';

import { usePageValidationsService } from '@app/api/pageValidations';
import { JsonReturnModel } from '@app/api';


interface Props {
  children: JSX.Element | ReactNode;
}

const plantStateContext = createContext<PlantState>(initialPlantState);
const plantDispatchContext = createContext<PlantDispatch>(PLANT_INITIAL_DISPATCH);

const PlantStateProvider = ({ children }: Props) => {
  const [plantState, dispatch] = useReducer(PlantValidationReducer, initialPlantState);

  return (
    <plantStateContext.Provider value={plantState}>
      <plantDispatchContext.Provider value={dispatch}>
        {children}
      </plantDispatchContext.Provider>
    </plantStateContext.Provider>
  );
}

function usePlantContext() {
  const state = React.useContext(plantStateContext);
  const dispatch = React.useContext(plantDispatchContext);
  const { getValidations } = usePageValidationsService();

  const loadPlantValidations = async (plantId: string) => {
    try {
      dispatch({ type: PlantValidationActions.FETCH_PLANT_VALIDATIONS_LOADING });
      const data = await getValidations(plantId);
      dispatch({ type: PlantValidationActions.FETCH_PLANT_VALIDATIONS_SUCCEEDED, payload: data });
    } catch (error) {
      const { message } = error as JsonReturnModel
      dispatch({ type: PlantValidationActions.FETCH_PLANT_VALIDATIONS_FAILED, payload: { errorMessage: message } });
      throw new Error(error as string | undefined);
    }
  }

  return { state, loadPlantValidations }
}

export { PlantStateProvider, usePlantContext };