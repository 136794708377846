import { PlantState } from '.';
import { useApi } from '../';

export function usePageValidationsService() {
    const { get } = useApi();
    const baseApi = `/v2/plants`;

    const getValidations = async (plantId?: string): Promise<PlantState> => {
        const { data } = await get<PlantState>(`${baseApi}/${plantId}/validations`);

        return data;
    };

    return {
        getValidations
    };
}
