import { GetLadleFurnaceResponse, useLadleFurnacesService } from "@app/api/ladleFurnaces";
import { DeleteButton, EditButton } from "@app/components/shared/CustomIconButton";
import Loading from "@app/components/shared/Loading";
import { useAuthenticationContext } from "@app/store/authentication/authenticationContext";
import { handleSendEvent } from "@app/utils";
import { PAGINATION, PERMISSIONS } from "@app/utils/constants";
import { createStyles, IconButtonProps, makeStyles, Paper, SelectProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AddLadleFurnaceModal from "../modals/AddLadleFurnaceModal";
import DeleteLadleFurnaceModal from "../modals/DeleteLadleFurnaceModal";
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import { ListInfoObject } from "@app/hooks/types";
import useScrollTop from "@app/hooks/useScrollTop";

interface LadleFurnaceTabProps {
  onSubmit: (request: GetLadleFurnaceResponse, hideModal: () => void) => void;
  ladleFurnacesInfoObject: ListInfoObject<GetLadleFurnaceResponse>;
  loadLadleFurnaces: () => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContent: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    }
  }),
);

const sortByName = (a: GetLadleFurnaceResponse, b: GetLadleFurnaceResponse) =>
  a.name > b.name ? 1 : b.name > a.name ? -1 : 0;

const LadleFurnaceTab = (props: LadleFurnaceTabProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { hasAccess } = useAuthenticationContext();
  const { getLadleFurnace, deleteLadleFurnace } = useLadleFurnacesService();

  const { loadPlantValidations } = usePlantContext();

  const { onSubmit, ladleFurnacesInfoObject, loadLadleFurnaces } = props;

  const [ladleFurnaces, setLadleFurnaces] = useState<GetLadleFurnaceResponse[]>([]);
  const [editedLadleFurnace, setEditedLadleFurnace] = useState<GetLadleFurnaceResponse>();
  const [deletedLadleFurnace, setDeletedLadleFurnace] = useState<GetLadleFurnaceResponse>();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openAddEditModal, setOpenAddEditModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE_EXTENDED);
  const plantId = useAuthenticationContext().state.userInfoObject?.data?.selectedPlant?.id;

  useEffect(() => {
    if(ladleFurnacesInfoObject.status === 'idle')
      loadLadleFurnaces();
  }, [ladleFurnacesInfoObject.status, loadLadleFurnaces]);

  useEffect(() => {
    if(ladleFurnacesInfoObject.data){
      const ladleFurnaceResponse = [...ladleFurnacesInfoObject.data]
      ladleFurnaceResponse?.sort(sortByName);
      setLadleFurnaces(ladleFurnaceResponse);
    }
  }, [ladleFurnacesInfoObject.data]);

  const loadExcludeProduct = async (id: number) => {
    handleSendEvent({
      category: 'BasicSettings/LadleFurnaces',
      action: 'User Opened Delete LadleFurnace Modal.',
    });
    try {
      setLoading(true);
      const response = await getLadleFurnace(id);

      setDeletedLadleFurnace({
        id: response.id,
        name: response.name,
        tapLadleFurnaces: response.tapLadleFurnaces,
      });
      setOpenDeleteModal(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteSubmit = async (id: number) => {
    handleSendEvent({
      category: 'BasicSettings/LadleFurnaces',
      action: 'User Deleted a LadleFurnace.',
    });

    try {
      setLoading(true);
      await deleteLadleFurnace(id);
      await loadLadleFurnaces();
      await loadPlantValidations(plantId ?? '');
      setOpenDeleteModal(false);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('exclusion-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
    } finally {
      setLoading(false);
    }
  };

  const noBorderTopStyles: React.CSSProperties = {
    borderTop: 'none'
  }

  const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop<HTMLDivElement>();

  return (
    <>

      <Loading dataTestId="ladle-furnace-tab-circular-progress" promiseInProgress={ladleFurnacesInfoObject.status === 'loading' || loading} />

      {openDeleteModal && (
        <DeleteLadleFurnaceModal
          onClose={() => {
            setOpenDeleteModal(false);
            setDeletedLadleFurnace(undefined);
          }}
          onConfirm={deleteSubmit}
          ladleFurnace={deletedLadleFurnace}
        />
      )}

      {openAddEditModal && editedLadleFurnace && (
        <AddLadleFurnaceModal
          isEdit
          onClose={() => {
            setOpenAddEditModal(false);
            setEditedLadleFurnace(undefined)
          }}
          onSubmit={(request: GetLadleFurnaceResponse) => {
            onSubmit(request, () => setOpenAddEditModal(false));
          }}
          targetLadleFurnace={editedLadleFurnace}
        />
      )}
      <Paper>
        <TableContainer style={{ maxHeight: 'calc(100vh - 336px)' }} innerRef={tableContainerRef}>
          <Table size="medium" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={noBorderTopStyles}>
                  {t('name')}
                </TableCell>
                <TableCell align="left" style={noBorderTopStyles}>
                  {t('taps')}
                </TableCell>
                <TableCell align="left" style={noBorderTopStyles}>
                  {t('ladle-furnace.default-tap')}
                </TableCell>
                <TableCell align="center" style={noBorderTopStyles}></TableCell>
                <TableCell align="center" style={noBorderTopStyles}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableContent} data-testid="ladle-furnace-list-table-body">
              {ladleFurnaces.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: GetLadleFurnaceResponse, i: number) => (
                <TableRow key={i} data-testid={`ladle-furnace-list-item-${row.name}-${row.id}`}>
                  <TableCell
                    align="left"
                    data-testid={`ladle-furnace.list.item-${row.id}.description`}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.tapLadleFurnaces.map(tap => tap.code).join(', ')}</TableCell>
                  <TableCell align="left">
                    {row.tapLadleFurnaces.filter(t => t.default).map(({ code }) => code)}
                  </TableCell>
                  <TableCell align="center" width="50">
                    {hasAccess([PERMISSIONS.LADLE_FURNACE_EDIT]) && (
                      <EditButton
                        data-testid={`ladle-furnace.list.item-${row.id}.button.edit`}
                        onClick={() => {
                          setOpenAddEditModal(true);
                          setEditedLadleFurnace(row);
                        }}
                      />
                    )}{' '}
                  </TableCell>

                  <TableCell align="center" width="50">
                    {hasAccess([PERMISSIONS.LADLE_FURNACE_DELETE]) && (
                      <DeleteButton
                        data-testid={`ladle-furnace.list.item-${row.id}.button.delete`}
                        onClick={() => loadExcludeProduct(row.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          data-testid="ladle-furnace.list.table-pagination"
          backIconButtonProps={{ ['data-testid']: 'ladle-furnace.list.table-pagination.back-button' } as Partial<IconButtonProps<"button", {}>>}
          nextIconButtonProps={{ ['data-testid']: 'ladle-furnace.list.table-pagination.next-button' } as Partial<IconButtonProps<"button", {}>>}
          SelectProps={{ ['data-testid']: 'ladle-furnace.list.table-pagination.number-of-items-per-page-selector' } as Partial<SelectProps>}
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={ladleFurnaces.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            scrollTop();
          }}
          onRowsPerPageChange={e => {
            setPage(0);
            setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE_EXTENDED));
          }}
          labelRowsPerPage={t('rows-per-page')}
          backIconButtonText={t('page-previous')}
          nextIconButtonText={t('page-next')}
        />
      </Paper>
    </>
  );
};

export default LadleFurnaceTab;
