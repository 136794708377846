import { Grid, Typography, useTheme } from "@material-ui/core";
import { ReactNode } from "react";

interface FormSectionContainerProps {
    children: ReactNode;
    title: string;
    subtitle?: string;
    style?: React.CSSProperties;
}

const FormSectionContainer = ({ children, title, subtitle, style }: FormSectionContainerProps) => {
    const theme = useTheme();

    const containerStyles: React.CSSProperties = {
        display: 'flex',
        flex: 1,
        gap: '8px',
        flexDirection: 'column',
        backgroundColor: theme.palette.custom.formBackground.main,
        width: '100%',
    } 

    const wrapperStyles: React.CSSProperties = {
        ...containerStyles,
        padding: '16px',
    }
    return(
        <Grid alignItems="center" container style={{...wrapperStyles, maxWidth: '600px', ...style}}>
            <Typography 
                style={{ textAlign: 'center' }} 
                variant="subtitle2"
            >
                {title}
            </Typography>
            <Grid style={containerStyles}>
                {subtitle && (
                    <Typography style={{ textAlign: 'center' }} variant="body2">
                        {subtitle}
                    </Typography>
                )}
                {children}
            </Grid>
        </Grid>
    )
}

export default FormSectionContainer;