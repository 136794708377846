export const initialData = {
    ladleSteelWeight: 0,
    maximumSlagWeight: 0,
    minimumSlagWeightAfterSkimmerUse: 0,
    toleranceCalcMinSlagThickness: 0,
    ladleDiameter: 0,
    averageLadleLifetime: 0,
    initialLiningThickness: 0,
    finalLiningThickness: 0,
  }

export const ladleFurnaceInitialValue = {
    id: -1,
    name: '',
    tapLadleFurnaces: [
        {
            arcLength: 0,
            minimumSlagDepth: 0,
            minimumSlagWeight: 0,
            code: 0,
            default: false,
        }
    ],
}

export type LadleInitialData = typeof initialData
