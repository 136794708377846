import { MSAL_SCOPES_B2B, MSAL_SCOPES_B2C } from '@app/utils/constants';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

type Full<T> = {
  [P in keyof T]-?: T[P];
};

export function useMsalService() {
  const { accounts, instance } = useMsal();
  const isEndToEndEnvironment = localStorage.getItem('e2e.login') === 'true';

  const getApplicationAccessToken = async (): Promise<string | null> => {
    if (isEndToEndEnvironment) {
      return await Promise.resolve(localStorage.getItem('e2e.token'));
    }

    return await Promise.resolve(null);
  }

  const getUserAccessToken = async (): Promise<string | null> => {
    if (accounts.length > 0) {
      const currentAccount = accounts[0] as Full<AccountInfo>;

      const isB2C = localStorage.getItem('isB2C') === 'true';

      const request: SilentRequest = {
        scopes: isB2C ? MSAL_SCOPES_B2C : MSAL_SCOPES_B2B,
        account: currentAccount,
      };

      const accessToken = await instance
        .acquireTokenSilent(request)
        .then(response => {
          return response.accessToken;
        })
        .catch(error => {
          // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
          console.error(error);
          return null;
        });

      return accessToken;
    } else {
      throw new Error('Failed to obtain token');
    }
  }

  async function getAccessToken() {
    return await getApplicationAccessToken() || await getUserAccessToken();
  }

  return { getAccessToken };
}
