import React, { createContext, useReducer } from 'react';
import { ArchivedHeatActions } from './archivedDraftHeatActions';
import {
  ArchivedDraftHeatDispatch,
  ArchivedHeatReducer,
  ARCHIVED_HEAT_INITIAL_DISPATCH
} from './archivedDraftHeatReducer';
import { ArchivedDraftHeatState, ARCHIVED_DRAFT_HEAT_INITIAL_STATE } from './archivedDraftHeatState';

const ArchivedDraftHeatStateContext = createContext<ArchivedDraftHeatState>(ARCHIVED_DRAFT_HEAT_INITIAL_STATE);
const ArchivedDraftHeatDispatchContext = createContext<ArchivedDraftHeatDispatch>(ARCHIVED_HEAT_INITIAL_DISPATCH);

type ArchivedDraftHeatProps = { children: React.ReactNode };

function ArchivedDraftHeatProvider({ children }: ArchivedDraftHeatProps) {
  const [state, dispatch] = useReducer(ArchivedHeatReducer, ARCHIVED_DRAFT_HEAT_INITIAL_STATE);

  return (
    <ArchivedDraftHeatStateContext.Provider value={state}>
      <ArchivedDraftHeatDispatchContext.Provider value={dispatch}>{children}</ArchivedDraftHeatDispatchContext.Provider>
    </ArchivedDraftHeatStateContext.Provider>
  );
}

function useArchivedDraftHeatContext() {
  const state = React.useContext(ArchivedDraftHeatStateContext);

  if (state === undefined) {
    throw new Error('useArchivedDraftHeatState should be used inside an ArchivedDraftHeatProvider');
  }

  const dispatch = React.useContext(ArchivedDraftHeatDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useArchivedDraftHeatDispatch should be used inside an ArchivedDraftHeatProvider');
  }

  const setStartDate = (payload: string | null) => {

    dispatch({ type: ArchivedHeatActions.SET_DRAFT_START_DATE, payload });
  };

  const setEndDate = (payload: string | null) => {

    dispatch({ type: ArchivedHeatActions.SET_DRAFT_END_DATE, payload });
  };

  const resetDate = () => {

    dispatch({ type: ArchivedHeatActions.RESET_DRAFT_DATE });
  };

  return { state, setStartDate, setEndDate, resetDate };
}

export { ArchivedDraftHeatProvider, useArchivedDraftHeatContext };

