
import { useTranslation } from 'react-i18next';


export enum CalculationMode {
    None = 'None',
    Tapping = 'TappingMode',
    LadleFurnace = 'LadleFurnaceMode'
}

export function useCalculationModeOptions(): [
    Array<{
        label: string;
        value: CalculationMode;
    }>,
    (calculationModeOption: CalculationMode) => string
] {
    const { t } = useTranslation();

    const calculationModeOptions =   [
        {label: t('calculation.mode.select.label.all'), value: CalculationMode.None},
        {label: t('calculation.mode.tapping'), value: CalculationMode.Tapping },
        {label: t('calculation.mode.ladlefurnace'), value: CalculationMode.LadleFurnace }
    ]

    const  getCalculationModeLabel = (calculationMode: CalculationMode): string => {
        switch(calculationMode) {
            case CalculationMode.Tapping:
                return t('calculation.mode.tapping');
            default:
                return t('calculation.mode.ladlefurnace');
        }
    }

    return ([
      calculationModeOptions,
      getCalculationModeLabel
    ]);
  }
