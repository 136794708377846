import CarryoverSlagUnitOfMeasurement from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { CarryoverSlagType } from '../heats/HeatsResponse';

export enum CarryoverSlagInferenceType {
  None = 'None',
  Range = 'Range',
  SingleValue = 'SingleValue',
  SteelGradeCategory = 'SteelGradeCategory'
}

export interface PlantUpdateCarryoverSlagSettingsRequest {
  carryoverSlagInferenceType: CarryoverSlagInferenceType;
  carryoverSlagParameterValueName: string;
  carryoverSlagUnitOfMeasurement: CarryoverSlagUnitOfMeasurement;
  carryoverSlagReferenceInput: string;
  carryoverSlagType: CarryoverSlagType;
  minimumSlagCarryoverWeight: number;
}
