import { ReportProblemIcon } from "@app/assets/icons/reportProblemIcon";
import { Box, makeStyles, createStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { Link } from 'react-router-dom';
import FormButton from "./FormButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.primary,
            paddingLeft: '10px',
            fontSize: '14px',
            fontWeight: 'bold'
        },
    }),
);

interface WarningMessageComponentProps {
  message: string | JSX.Element;
  withPadding?: boolean;
  link?: {
    label: string;
    url: string;
  };
  linkButton?: {
    label: string;
    onClick: () => void;    
  }
  testId?: string;
}

function WarningMessageComponent({ message, withPadding = false, link, linkButton, testId }: WarningMessageComponentProps) {
  const classes = useStyles();
  const theme = useTheme();

  const renderLink = (): JSX.Element => {
    let element: JSX.Element;

    if (link) {
      element = (
        <Link className={classes.link} to={link.url}>
          {link.label}
        </Link>
      );
    } else if (linkButton) {
      element = (
        <FormButton
          size='small'
          variant='link'
          type='button'
          onClick={linkButton.onClick}>
          {linkButton.label}
        </FormButton>
      );
    } else {
      element = (
        <></>
      );
    }

    return element;
  }

  return (
    <Box
      data-testid={testId ?? 'warning-message-component-typography'}
      style={{
        display: 'flex',
        flex: 1,
        ...withPadding ? {
          padding: '12px 24px',
        } : {}
      }}
    >
      <Box 
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row" ,
          justifyContent: "space-between",
          alignItems: "center" ,
          padding: "8px 12px" ,
          gap: "12px" ,
          backgroundColor: theme.palette.custom.warningBackground.main,
          borderRadius: "4px",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "12px",
          }}
        >
          <ReportProblemIcon noMargin size="24px" />
          <Typography style={{fontSize: "14px"}}>
            {message}
          </Typography>
        </Box>
        {renderLink()}
      </Box>
    </Box>
  )
}

export default WarningMessageComponent