import { default as yup } from '@app/config/yup-locale';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddAndUpdateAnalyzedSlag } from '@app/api/heats/AddAndUpdateAnalyzedSlag';
import { MIN_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL, MAX_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL } from '@app/utils/constants';
import FormValidationWarning from '@app/components/shared/FormValidationWarning';
import FormButton from '@app/components/shared/FormButton';
import CompositionTable from '@app/components/shared/CompositionTable';
import { defaultCompositionTableSchemaObjectShape } from '@app/utils/defaultCompositionTableSchemaObjectShape';

interface FinalAnalyzedSlagModalProps {
  onClose: () => void;
  onConfirm: (plant: AddAndUpdateAnalyzedSlag) => void;
  slags: AddAndUpdateAnalyzedSlag;
}

interface IFormInput {
  mgO: number;
  caO: number;
  al2O3: number;
  siO2: number;
  feO: number;
  mnO: number;
  cr2O3: number;
  tiO2: number;
  caF2: number;
  na2O: number;
  k2O: number;
}

const schema = yup.object().shape(defaultCompositionTableSchemaObjectShape);

const FinalAnalyzedSlagModal: React.FC<FinalAnalyzedSlagModalProps> = (props: FinalAnalyzedSlagModalProps) => {
  const { onClose, onConfirm, slags } = props;
  const { t } = useTranslation();
  const methods = useForm<IFormInput>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      mgO: slags?.mgO || 0,
      caO: slags?.caO || 0,
      al2O3: slags?.al2O3 || 0,
      siO2: slags?.siO2 || 0,
      feO: slags?.feO || 0,
      mnO: slags?.mnO || 0,
      cr2O3: slags?.cr2O3 || 0,
      tiO2: slags?.tiO2 || 0,
      caF2: slags?.caF2 || 0,
      na2O: slags?.na2O || 0,
      k2O: slags?.k2O || 0,
    },
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: {
      isDirty: formIsDirty
    }
  } = methods;

  const handleClose = () => {
    onClose();
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data: AddAndUpdateAnalyzedSlag) => {
    onConfirm(data);
    onClose();
  };

  const formValues = watch();

  const total = useMemo(() => {
    return Object.values(formValues).reduce((acc, value) =>  acc + Number(value || 0), 0);
  }, [formValues]);

  const compositionIsInvalid = total < 70 || total > 105;

  return (
    <>
      <Dialog open maxWidth="lg">
        <DialogTitle id="admin-panel-add-analyzed-slag-modal">
          {t('heat.add-analyzed-slag-modal')}
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <FormProvider {...methods} >
              <CompositionTable
                testIdPrefix='final-analyzed-slag-form'
                unitOfMeasurement='%'
                total={total}
              />
            </FormProvider>
            {compositionIsInvalid && formIsDirty && (
              <FormValidationWarning
                message={t('form.error.total', {min: MIN_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL, max: MAX_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL})}
              />
            )}
          </DialogContent>

          <DialogActions>
            <FormButton
              onClick={handleClose}
              variant="link"
            >
              {t('button.cancel')}
            </FormButton>
            <FormButton
              onClick={() => reset({
                mgO: 0,
                caO: 0,
                al2O3: 0,
                siO2: 0,
                feO: 0,
                mnO: 0,
                cr2O3: 0,
                tiO2: 0,
                caF2: 0,
                na2O: 0,
                k2O: 0,
              })}
              variant="secondary"
            >
              {t('button.clear')}
            </FormButton>
            <FormButton type="submit" variant="primary" disabled={(compositionIsInvalid)}>
              {t('button.add')}
            </FormButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default FinalAnalyzedSlagModal;
