import { PlantActivityResponse, PlantEvent } from "@app/api/plants/PlantActivityResponse";
import CustomTooltip from "@app/components/shared/CustomTooltip";
import TableNoResults from "@app/components/shared/TableNoResults";
import { PAGINATION } from "@app/utils/constants";
import { DateTimeUtils } from "@app/utils/dateTimeUtils";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

export interface PlantManagementDetailsActivitiesProps {
  activities: PlantActivityResponse[];
}

const PlantManagementDetailsActivities = (props: PlantManagementDetailsActivitiesProps): ReactElement => {
  const { t } = useTranslation();
  const { activities } = props;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE);

  const getStatusTranslationKey = (statusKey: PlantEvent): string => {
    switch(statusKey) {
        case PlantEvent.BLOCK:
            return t('admin-panel.plant-management.details.activities-block-event');
        case PlantEvent.UNBLOCK:
            return t('admin-panel.plant-management.details.activities-unblock-event');
        case PlantEvent.UPDATE:
            return t('admin-panel.plant-management.details.activities-update-event');
        default:
            return '-';
    }
  }

  const renderDateCell = (date: string): JSX.Element => {
    const [formattedDate, hasValue] = DateTimeUtils.format(date, t('date-time.format'));

    return (hasValue ?
      <CustomTooltip title='(UTC+00:00)' placement='top-start'>
        <Typography variant='body2'>{formattedDate}</Typography>
      </CustomTooltip> : 
      <Typography variant='body2'>{formattedDate}</Typography>
    );
  }

  const renderReason = (reason: string): JSX.Element => {
    return (
      <Typography variant='body2' style={{ whiteSpace: 'break-spaces' }}>
        {reason}
      </Typography>
    );
  }

  const renderHeader = (): JSX.Element => {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '10%' }}>{t('plant-management-detail.date')}</TableCell>
          <TableCell style={{ width: '10%' }}>{t('plant-management-detail.event')}</TableCell>
          <TableCell style={{ width: '30%' }}>{t('plant-management-detail.user')}</TableCell>
          <TableCell style={{ width: '50%' }}>{t('plant-management-detail.reason')}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const renderBody = (): JSX.Element => {
    const start = rowsPerPage * page;
    const end = start + rowsPerPage;
    const activitiesInPage = activities.slice(start, end);

    return (
      <TableBody>
        {activitiesInPage.length > 0 ? (
          activitiesInPage.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{renderDateCell(item.createdDate)}</TableCell>
              <TableCell>{getStatusTranslationKey(item.event)}</TableCell>
              <TableCell>{item.createdBy}</TableCell>
              <TableCell>{renderReason(item.reason)}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4}>
              <TableNoResults firstText={t('admin-panel.plant-management.details.activities-empty')} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }

  return (
    <Paper style={{ maxHeight: '96%', overflow: 'auto' }}>
      <Box paddingBottom={2}>
        <Typography variant="body2">
          <b>{t('admin-panel.plant-management.details.activities')}</b>
        </Typography>
      </Box>
      <TableContainer>
        <Table size="medium" stickyHeader>
          {renderHeader()}
          {renderBody()}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={activities.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, page) => {
          setPage(page);
        }}
        onRowsPerPageChange={e => {
          setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE));
        }}
        labelRowsPerPage={t('rows-per-page')}
        backIconButtonText={t('page-previous')}
        nextIconButtonText={t('page-next')}
      />
    </Paper>
  );
}

export default PlantManagementDetailsActivities;