import CarryoverSlagUnitOfMeasurement from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { AlloysRequest } from '../alloys';
import { DeoxidizersModel, FluxModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { CalculationMode } from '@app/utils/calculationModeOptions';
import { CarryoverSlagInferenceType } from '../plants/PlantUpdateCarryoverSlagSettingsRequest';
import { SteelChemistryUnitOfMeasurement } from '../processConstraints/useProcessConstraintsService';

export interface ChargeChemistries {
  fluxes: FluxModel[];
  alloys: AlloysRequest[];
  deoxidizers: DeoxidizersModel[];
}

export enum StageName {
  Tapping = 'Tapping',
  LadleFurnace1 = 'LadleFurnace1',
  LadleFurnace2 = 'LadleFurnace2',
  LadleFurnace3 = 'LadleFurnace3',
}

export enum StageType {
  Input = 'Input',
  Output = 'Output',
  ModifiedRecommendations = 'ModifiedRecommendations',
}

export enum CarryoverSlagType {
  Weight = 'Weight',
  Thickness = 'Thickness',
}

export enum DiagnosticSeverityType {
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
}

export interface OutputDetailsDTO {
  heatId: number;
  createDate: string;
  package: string;
  packageVersion: string;
  engineVersion: string;
  calculationId: string;
}

export interface ResultDTO {
  completedWithWarnings: boolean;
  caORequired: number;
  mgORequired: number;
  alRecovery: number;
  siRecovery: number;
  mnRecovery: number;
  crRecovery: number;
  iterations: number;
  feedback?: {
    slagAppearance: null | string;
    text: null | string;
  };
  dynamicLadleDiameter: null | number;
  recalculatedCarryoverSlagWeight: null | number;
  carryoverSlagDepth: 0.0;
  recalculatedInitialSlagDepth: 0.0;
  recalculatedSlagWeightAfterSkimmer: 0.0;
}

export interface LadleFurnaceRecovery {
  stageName: StageName;
  stageType: StageType;
  siRecovery: number;
  alRecovery: number;
  mnRecovery: number;
  crRecovery: number;
}

export type Stage = {
  stageName: StageName;
  stageType: StageType;
  fluxes: HeatChargeWeightArray;
  alloys: HeatChargeWeightArray;
  deoxidizers: HeatChargeWeightArray;
};

export type ChargeWeights = {
  carryoverSlagWeight: string | null;
  slagThickness: number | null;
  stages: Array<Stage>;
};

export interface ProcessConstraints {
  ladle: {
    steelWeight: number;
    diameter: number;
    initialLiningThickness: number;
    finalLiningThickness: number;
    averageLadleLifetime: number;
  };
  weightLimits: {
    maximumSlagWeight: number;
    minimumSlagWeightAfterSkimmerUse: number;
    minimumSlagCarryoverWeight: number;
    maximumSlagDeoxidizerWeight: number;
  };
  mgOSource: {
    minimumWeight: number;
    maximumWeight: number;
  };
  carryoverSlagType: CarryoverSlagType;
  steelChemistryUnitOfMeasurement: SteelChemistryUnitOfMeasurement;
}

export interface DesulfurizationResults {
  oxidizedSlag: boolean;
  desulfurizationOption: 'None' | 'Classic' | 'Empiric';
  finalSulfurPercentage?: number;
  sulfurDistributionCoefficient?: number;
  sulfideCapacity?: number;
  opticalBasicity?: number;
}

export interface SlagComposition {
  mgO: number;
  caO: number;
  al2O3: number;
  siO2: number;
  feO: number;
  mnO: number;
  cr2O3: number;
  tiO2: number;
  caF2: number;
  k2O: number;
  na2O: number;
}

export interface CalculatedSlagInfo {
  depth: number;
  weight: number;
  b2: number;
  b3: number;
  slags: SlagComposition;
  desulfurizationResults: DesulfurizationResults;
}

export interface CalculatedSlag {
  stageName: StageName;
  stageType: StageType;
  calculated: CalculatedSlagInfo;
  target: CalculatedSlagInfo;
  analyzed: CalculatedSlagInfo | null;
}

export type CalculatedSlags = Array<CalculatedSlag>;

export type HeatChargeWeightArray = Array<{ name: string; weight: number; externalId?: string | number | null }>;

export type HeatRecommendationStagePropertyDTO = {
  name: string;
  weight: number;
  realAddedWeight?: number;
  externalId?: null | number | string;
};

export interface HeatRecommendationStageDTO {
  fluxes?: HeatRecommendationStagePropertyDTO[];
  alloys?: HeatRecommendationStagePropertyDTO[];
  deoxidizers?: HeatRecommendationStagePropertyDTO[];
}

export interface HeatRecommendationsDTO extends HeatRecommendationStageDTO {
  stageName: StageName;
  stageType: StageType;
}

export type SaturationMode = 'DualSaturation' | 'MgOSaturation' | '';

export enum DeoxidizerType {
  None = 'None',
  Al = 'Al',
  C = 'C',
  CaC2 = 'CaC2',
  FeSi = 'FeSi',
  SiC = 'SiC',
}

export enum SteelChemistryStageName {
  PrimaryFurnace = 'PrimaryFurnace',
  Tapping = 'Tapping',
}

export interface SteelChemistryItem {
  stageName: SteelChemistryStageName;
  stageType: StageType;
  si: number;
  dissolvedAl: number;
  mn: number | null;
  cr: number | null;
}

export interface CalculateHeatRequestPayload {
  details: {
    ladleFurnace?: string;
    heatName: string;
    saturationMode: SaturationMode;
  };
  heatSettings: {
    carryoverSlagTargetReferenceInferenceType: CarryoverSlagInferenceType | null;
    carryoverSlagTargetReferenceName: string | undefined | null;
    carryoverSlagTargetReferenceUnitOfMeasurement: CarryoverSlagUnitOfMeasurement | undefined | null;
    carryoverSlagTargetReferenceMinValue: number | null | undefined;
    isNewCampaign: boolean;
    ladleLifeTime: string;
    ladleName: string;
    carryoverSlagTargetReferenceMaxValue: number | null | undefined;
    transformerTap?: string;
    arcLength?: number;
    minimumSlagWeight?: number;
    minimumSlagDepth?: number;
    useR2O3Target: boolean;
    r2O3Target: string | number;
    finalTemperature: string;
    temperatureUnit: string;
    weightUnit: string;
    steelGrade: string;
    steelGradeCategory: string;
    lengthUnit: string;
    steelChemistries: Array<SteelChemistryItem>;
    fluxSelection: {
      name: string;
    }[];
    deoxidizerSelection: DeoxidizerType | '';
    alloyRecoveries: Array<LadleFurnaceRecovery>;
  };
  processConstraints: ProcessConstraints;
  desulfurization: DesulfurizationDTO | null;
  skimmer: {
    active: boolean;
    postSlagWeight: number;
  };
  chargeChemistries: ChargeChemistries;
  chargeWeights: ChargeWeights;
}

export interface SaveHeatRequestPayload extends CalculateHeatRequestPayload {
  feedback?: {
    slagAppearance: string;
    text?: string;
  };
}

export interface DuplicateHeatRequestPayload {
  heatId: number;
  description: string;
}

export interface SaveDraftRequestPayload extends Omit<SaveHeatRequestPayload, 'heatSettings' | 'details'> {
  heatSettings: {
    carryoverSlagTargetReferenceInferenceType: CarryoverSlagInferenceType | null;
    carryoverSlagTargetReferenceName: string | undefined | null;
    carryoverSlagTargetReferenceUnitOfMeasurement: CarryoverSlagUnitOfMeasurement | undefined | null;
    carryoverSlagTargetReferenceMinValue: number | null | undefined;
    isNewCampaign: boolean;
    ladleLifeTime: string;
    carryoverSlagTargetReferenceMaxValue: number | null | undefined;
    transformerTap?: string;
    arcLength?: number;
    minimumSlagWeight?: number;
    minimumSlagDepth?: number;
    useR2O3Target: boolean;
    r2O3Target: string | number;
    finalTemperature: string;
    temperatureUnit: string;
    weightUnit: string;
    steelGrade: string;
    steelGradeCategory: string;
    lengthUnit: string;
    steelChemistries: Array<SteelChemistryItem>;
    fluxSelection: {
      name: string;
    }[];
    deoxidizerSelection: DeoxidizerType | '';
    alloyRecoveries: Array<LadleFurnaceRecovery>;
  };
  details: {
    ladleFurnace?: string;
    heatName?: string;
    heatDescription: string;
    shouldCalculate: boolean;
    saturationMode: SaturationMode;
  };
}

export interface CalculateDraftHeatRequestPayload extends Omit<SaveDraftRequestPayload, 'feedback' | 'details'> {
  details: {
    ladleFurnace?: string;
    heatName?: string;
    heatDescription: string;
    saturationMode: SaturationMode;
  };
}

export interface DesulfurizationDTO {
  initialSulfurPercentage: number | null;
  finalOxygenInSteel: number | null;
}

export interface SaveHeatResponsePayload {
  input: {
    details: {
      ladleFurnace: string;
      heatName: string;
      saturationMode: SaturationMode;
    };
    heatSettings: {
      carryoverSlagTargetReferenceInferenceType: CarryoverSlagInferenceType | null;
      targetPhosphorus: null | string | number;
      carryoverSlagTargetReferenceName: string;
      carryoverSlagTargetReferenceMinValue: number;
      carryoverSlagTargetReferenceMaxValue: null | string | number;
      carryoverSlagTargetReferenceUnitOfMeasurement: CarryoverSlagUnitOfMeasurement;
      transformerTap: number;
      arcLength: number;
      minimumSlagWeight: number;
      minimumSlagDepth: number;
      ladleId: null | number | string;
      ladleName: number;
      ladleLifeTime: number;
      isNewCampaign: boolean;
      campaignNumber: null | number | string;
      useR2O3Target: boolean;
      deoxidizerSelection: DeoxidizerType | '';
      r2O3Target: number;
      finalTemperature: number;
      steelGrade: string;
      steelGradeCategory: string;
      temperatureUnit: string;
      weightUnit: string;
      lengthUnit: string;
      steelChemistries: {
        si: number;
        dissolvedAl: number;
        mn: number | null;
        cr: number | null;
      };
      fluxSelection: {
        name: string;
        externalId: null | number | string;
      }[];
      alloyRecoveries: Array<LadleFurnaceRecovery>;
    };
    processConstraints: ProcessConstraints;
    skimmer: {
      active: boolean;
      postSlagWeight: number;
    };
    chargeChemistries: ChargeChemistries;
    chargeWeights: ChargeWeights;
    desulfurization: DesulfurizationDTO | null;
  };
  output: {
    details: OutputDetailsDTO;
    recommendations: HeatRecommendationsDTO;
    calculatedSlags: CalculatedSlags;
  };
  result: ResultDTO;
}

export interface HeatsRequest {
  isHeatSimulation: boolean;
  slagAppearance: string;
  slagAppearanceText: string;
}

export interface HeatsResponse {
  id: number;
  ladleFurnace: string;
  heatName: string;
  modelVersion: string;
  createUser: string;
  updateUser: string;
  createDate: Date;
  updateDate: Date;
  customerId: number;
  isHeatSimulation: boolean;
  calculationMode: CalculationMode;
  isOwner?: boolean;
  result?: ResultDTO;
  output: {
    calculatedSlags: CalculatedSlags;
    details: {
      createDate: string;
      heatId: string;
      saturationMode: SaturationMode;
    };
    recommendations: HeatRecommendationsDTO;
  };
  input: {
    chargeChemistries: ChargeChemistries;
    skimmer: {
      active: boolean;
      postSlagWeight: string;
    };
    chargeWeights: ChargeWeights;
    heatSettings: {
      transformerTap: string;
      arcLength: number;
      minimumSlagDepth: number;
      minimumSlagWeight: number;
      steelGrade: string;
      steelGradeCategory: string;
      ladleName: string;
      ladleLifeTime: string;
      isNewCampaign: boolean;
      finalTemperature: string;
      deoxidizerSelection: DeoxidizerType | '';
      carryoverSlagTargetReferenceMinValue?: number;
      carryoverSlagTargetReferenceMaxValue?: number;
      carryoverSlagTargetReferenceInferenceType: CarryoverSlagInferenceType | null;
      carryoverSlagTargetReferenceUnitOfMeasurement: CarryoverSlagUnitOfMeasurement;
      carryoverSlagTargetReferenceName: string;
      r2O3Target: string;
      steelChemistries: Array<SteelChemistryItem>;
      fluxSelection: {
        name: string;
      }[];
      alloyRecoveries?: Array<LadleFurnaceRecovery>;
      useR2O3Target: boolean;
    };
    details: {
      heatDescription: string;
      customerId: number | string;
      ladleFurnace: string;
      heatName: string;
      calculationMode?: CalculationMode;
      saturationMode: SaturationMode;
    };
    processConstraints: ProcessConstraints;
    desulfurization: DesulfurizationDTO | null;
  };
}

export interface DraftHeatsResponse extends Omit<HeatsResponse, 'heatName' | 'input'> {
  heatDescription: string;
  input: {
    chargeChemistries: ChargeChemistries;
    skimmer: {
      active: boolean;
      postSlagWeight: string;
    };
    chargeWeights: ChargeWeights;
    heatSettings: {
      ladleName?: string;
      transformerTap: string;
      arcLength: number;
      minimumSlagDepth: number;
      minimumSlagWeight: number;
      steelGrade: string;
      steelGradeCategory: string;
      ladleLifeTime: string;
      isNewCampaign: boolean;
      finalTemperature: string;
      deoxidizerSelection: DeoxidizerType | '';
      carryoverSlagTargetReferenceMinValue?: number;
      carryoverSlagTargetReferenceMaxValue?: number;
      carryoverSlagTargetReferenceInferenceType: CarryoverSlagInferenceType | null;
      carryoverSlagTargetReferenceUnitOfMeasurement: CarryoverSlagUnitOfMeasurement;
      carryoverSlagTargetReferenceName: string;
      r2O3Target: string;
      steelChemistries: Array<SteelChemistryItem>;
      fluxSelection: {
        name: string;
      }[];
      alloyRecoveries?: Array<LadleFurnaceRecovery>;
      useR2O3Target: boolean;
    };
    details: {
      customerId: number | string;
      ladleFurnace: string;
      heatDescription: string;
      heatName?: string;
      calculationMode?: CalculationMode;
      saturationMode: SaturationMode;
    };
    processConstraints: ProcessConstraints;
    desulfurization: DesulfurizationDTO | null;
  };
}

export interface CalculationDiagnostic {
  severity: DiagnosticSeverityType;
  code: string;
  message: string;
  additionalInfo: Record<string, string | number | boolean | object>;
}

export enum IterationChartType {
  Weights = 'Weights',
  RelativeSaturation = 'RelativeSaturation',
  Slags = 'Slags',
}

export enum IterationChartStatus {
  NotRequested = 'NotRequested',
  Processing = 'Processing',
  Success = 'Success',
  Error = 'Error',
  ErrorLogAnalytics = 'ErrorLogAnalytics',
  ErrorAzureFunction = 'ErrorAzureFunction',
}

export interface IterationChartRequest {
  type: IterationChartType;
}

export interface IterationChartRegistrationResponse {
  type: IterationChartType;
  status: IterationChartStatus;
  timestamp: string;
  errorDetails: string;
}

export interface IterationChartResponse extends IterationChartRegistrationResponse {
  mimeType: string;
  data: string;
}
