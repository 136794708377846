import { CalculationDiagnostic } from '@app/api/heats/HeatsResponse';
import { Card, CardActions, CardContent, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { FileCopyOutlined, ExpandMoreOutlined } from '@material-ui/icons';
import { JsonProperty, JsonUtils } from '@app/utils/jsonUtils';
import clsx from 'clsx';
import CustomTooltip from '@app/components/shared/CustomTooltip';
import { useTranslation } from 'react-i18next';

export const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardSize: {
      height: 'fit-content',
      display: 'flex',
      padding: '16px',
      gap: '16px',
      flexDirection: 'column',
      flex: 1,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.3)'
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      columnGap: 1,
      width: '100%',
      padding: '0 !important'
    },
    cardActions: {
      padding: '0px'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  })
);

interface CalculationDiagnosticsItem {
  diagnostic: CalculationDiagnostic;
}

const CalculationDiagnosticsItem = (props: CalculationDiagnosticsItem): ReactElement => {
  const { t } = useTranslation();
  const classes = useCardStyles();
  const { diagnostic } = props;
  const [expanded, setExpanded] = useState(false);
  const [copyIconTooltipText, setCopyIconTooltipText] = useState(t('calculation-diagnostics.item.additional-info.before-copy'));
  const [jsonTable, setJsonTable] = useState<JsonProperty[]>([]);

  useEffect(() => {
    setJsonTable(JsonUtils.mapJsonAsTable(diagnostic.additionalInfo));
  }, [diagnostic.additionalInfo]);

  const expandAdditionalInformationText = t('calculation-diagnostics.item.additional-info.show-table');
  const collapseAdditionalInformationText = t('calculation-diagnostics.item.additional-info.hide-table');

  const handleCopyClick = () => {
    navigator.clipboard.writeText(JSON.stringify(diagnostic.additionalInfo, null, 2));

    setCopyIconTooltipText(t('calculation-diagnostics.item.additional-info.after-copy'));

    setTimeout(() => {
      setCopyIconTooltipText(t('calculation-diagnostics.item.additional-info.before-copy'));
    }, 2000);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderHeader = (): JSX.Element => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{t('calculation-diagnostics.item.table-header.property')}</TableCell>
          <TableCell>{t('calculation-diagnostics.item.table-header.value')}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const renderBody = (): JSX.Element => {
    return (
      <TableBody>
        {jsonTable.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  return (
    <Card className={classes.cardSize} variant='outlined'>
      <CardContent className={classes.cardContent}>
        <Typography variant='body1'>
          <b>{JsonUtils.splitAndCapitalizeText(diagnostic.code)}</b>
        </Typography>
        <Typography variant='body2'>
          {diagnostic.message}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        <IconButton
          onClick={handleCopyClick}>
          <CustomTooltip title={copyIconTooltipText} >
            <FileCopyOutlined />
          </CustomTooltip>
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick} >
          <CustomTooltip title={expanded ? collapseAdditionalInformationText : expandAdditionalInformationText} >
            <ExpandMoreOutlined />
          </CustomTooltip>
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <TableContainer>
           <Table stickyHeader>
             {renderHeader()}
             {renderBody()}
           </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default CalculationDiagnosticsItem;