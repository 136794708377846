import { FALLBACK_LANGUAGE } from '@app/utils/constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languages from '@app/locale';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    resources: languages,
    // keySeparator: true, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { useSuspense: true },
  });

export default i18n;
