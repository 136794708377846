import { GetLadlesResponse } from '@app/api/ladles';
import FormSelect, { FormSelectOptions } from '@app/components/shared/FormSelect';
import { APP_DESULFURIZATION_INPUT_ENABLED, APP_MGO_SATURATION_MODE_ENABLED, MAX_INITIAL_SULFUR_PERCENTTAGE, MAX_OXYGEN_CONTENT, MAX_WEIGHT, MIN_INITIAL_SULFUR_PERCENTAGE, MIN_OXYGEN_CONTENT, UOM_CENTIMETERS_SYMBOL, UOM_POUNDS_SYMBOL } from '@app/utils/constants';
import { Box, CircularProgress, Divider, Grid, TextField, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { InitialState, stepsInitialState } from '../../heatsUtils';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import WarningBox from '@app/components/shared/WarningBox';
import { SteelGradeModel } from './heatCalculationTypes';
import { LadleFurnaceModel } from '@app/api/ladleFurnaces/LadleFurnaceModel';
import { Tap } from '@app/api/ladleFurnaces';
import ISelectedCarryoverSlag from '../ISelectedCarryoverSlag';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { getReferenceValueLabel } from '@app/pages/CarryoverSlags/carryoverSlagsUtils';
import CarryoverSlagUnitOfMeasurement, { UNITS_OF_MEASUREMENT } from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { CarryoverSlagReferenceGetResponse } from '@app/api/carryoverSlagReferences';
import { displayNotApplicableIfNull, displayNotApplicableIfZero } from '@app/utils';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import { CarryoverSlagType, DraftHeatsResponse, HeatsResponse } from '@app/api/heats/HeatsResponse';
import FormCheckbox from '@app/components/shared/FormCheckbox';
import FormAutocompleteInput from '@app/components/shared/FormAutocompleteInput/FormAutocompleteInput';
import FormWrapper from '@app/components/shared/FormWrapper';
import FormSectionContainer from '@app/components/shared/FormSectionContainer';
import useRenderInputEndAdornment from '@app/hooks/useRenderInputEndAdornment';
import useReturnUnitOfMeasurement from '@app/hooks/useReturnUnitOfMeasurement';
import { convertMetersIntoOtherUnitOfMeasurement } from '@app/utils/convertLengthUnits';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { LimitInfo } from '@app/api/limits/limitsTypes';

interface Step1Props {
  isDesulfurizationEnabled: boolean;
  setIsDesulfurizationEnabled: Dispatch<SetStateAction<boolean>>;
  heatMode: 'operation' | 'draft';
  disabled?: boolean;
  handleChangeHeatCalculation?: (
    field: keyof InitialState | string[],
    value?: boolean | number | string | null | Array<string | number | undefined | boolean | null>
  ) => void;
  ladlesList?: GetLadlesResponse[];
  steelGradeList: SteelGradeModel[];
  tapList: Tap[];
  ladleFurnaceList: LadleFurnaceModel[];
  handleLadleFurnaceChange?: (id: number) => void;
  carryoverSlagType?: CarryoverSlagType;
  minimumCarryoverSlag?: number;
  heatCalculation: typeof stepsInitialState['step1'];
  verifyHeatName?: (name: string) => void;
  isVerifyHeatLoading?: boolean;
  maxCarryoverSlagWeightOrThickness?: number;
  plantIsIncompatible?: boolean;
  selectedLadleFurnace?: LadleFurnaceModel;
  stepIsInvalid?: boolean;
  isInvalidCarryoverSlagWeight?: boolean;
  selectedCarryoverSlag?: ISelectedCarryoverSlag;
  setSelectedCarryoverSlag: Dispatch<SetStateAction<ISelectedCarryoverSlag | undefined>>;
  carryoverSlagReferenceList: CarryoverSlagReferenceGetResponse[];
  isTappingMode?: boolean;
  disableLadleFields?: boolean;
  changedHeatNameInput?: boolean;
  heatResponse?: DraftHeatsResponse | HeatsResponse;
}

const Step1 = (props: Step1Props) => {
  const {
    isDesulfurizationEnabled,
    setIsDesulfurizationEnabled,
    heatMode,
    disabled = false,
    handleChangeHeatCalculation,
    ladlesList,
    steelGradeList,
    tapList,
    ladleFurnaceList,
    handleLadleFurnaceChange,
    carryoverSlagType = CarryoverSlagType.Weight,
    minimumCarryoverSlag,
    heatCalculation,
    verifyHeatName,
    isVerifyHeatLoading,
    maxCarryoverSlagWeightOrThickness,
    plantIsIncompatible = false,
    stepIsInvalid = false,
    selectedLadleFurnace,
    isInvalidCarryoverSlagWeight = false,
    isTappingMode = false,
    selectedCarryoverSlag,
    setSelectedCarryoverSlag,
    carryoverSlagReferenceList,
    disableLadleFields = false,
    changedHeatNameInput = false,
    heatResponse
  } = props;

  const {
    heatDescription,
    heatName,
    steelGrade,
    steelGradeCategory,
    finalTemperature,
    initialSulfurPercentage,
    finalOxygenInSteel,
    saturationMode,
    carryoverSlagWeight,
    skimmerActive,
    skimmerPostSlagWeight,
    tap,
    arcLength,
    minimumSlagDepth,
    minimumSlagWeight,
    ladleName,
    ladleLifeTime,
    isNewCampaign,
    isHeatNameInvalid,
    isLadleLifeInvalid
  } = heatCalculation;

  const { t } = useTranslation();

  const {
    state: { userInfoObject, limitsInfoObject },
  } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;
  const limitsInfo = limitsInfoObject?.data;

  const finalTemperatureLimits = limitsInfo?.finalTemperature as LimitInfo;
  const carryoverSlagWeightOrThicknessDecimalPlaces = carryoverSlagType === CarryoverSlagType.Thickness
    ? limitsInfo?.slagThickness?.decimalScale
    : limitsInfo?.carryoverSlagWeight?.decimalScale

  const measurementSystem = userInfo?.measurementSystem;

  const carryoverSlagInferenceType =
  heatResponse?.input.heatSettings.carryoverSlagTargetReferenceInferenceType
    ?? userInfo?.selectedPlant?.carryoverSlagInferenceType;

  const [tapChanged, setTapChanged] = useState(false);

  const [value, setValue] = useState<GetLadlesResponse | null>(null);
  const [inputValue, setInputValue] = useState('');

  const [carryoverSlagWeightorDepthHasChanged, setCarryoverSlagWeightOrDepthHasChanged] = useState(false);


  useEffect(() => {
    let ladle = ladlesList?.find(ll => ll.ladleName === ladleName);

    if (ladleName && !ladle) {
      ladle = {
        ladleName,
        ladleId: '',
        ladleLife: undefined,
      } as GetLadlesResponse;
    }

    setValue(ladle || null);
  }, [ladleName, ladlesList]);

  useEffect(() => {
    if(!ladleName && ladleName !== value?.ladleName && changedHeatNameInput) {
      setValue(null);
    }
  }, [ladleName, value, changedHeatNameInput])

  const foundLadle = useMemo(() => {
    return ladlesList?.find(ll => ll.ladleName === ladleName)
  }, [ladlesList, ladleName]);

  const isInvalidLadleLife = useMemo(() => {
    return (
      !['', '0'].includes(ladleLifeTime) &&
      (
        (
          !!foundLadle?.ladleLife?.currentLadleLife && (
            (
              Number(foundLadle?.ladleLife?.currentLadleLife) + 1
            ) > Number(ladleLifeTime)
          )
        ) && !isNewCampaign)
    )

  }, [ladleLifeTime, foundLadle, isNewCampaign])

  useEffect(() => {
    if(isLadleLifeInvalid !== isInvalidLadleLife && handleChangeHeatCalculation)
      handleChangeHeatCalculation('isLadleLifeInvalid', isInvalidLadleLife);
  },[isInvalidLadleLife, isLadleLifeInvalid, handleChangeHeatCalculation]);

  const handleVerifyName = async (name: string) => {
    if (verifyHeatName) verifyHeatName(name);
  };

  const handleTapChange = (tapCode: number) => {
    if(!tapChanged) setTapChanged(true);
    const defaultTapCode = tapList.find((t: { code: number }) => t.code === tapCode);
    if (defaultTapCode) {
      if (handleChangeHeatCalculation)
        handleChangeHeatCalculation(
          ['tap', 'arcLength', 'minimumSlagDepth', 'minimumSlagWeight'],
          [
            defaultTapCode.code,
            defaultTapCode.arcLength,
            defaultTapCode.minimumSlagDepth,
            Math.floor(Number(defaultTapCode.minimumSlagWeight)),
          ],
        );
    }
  };

  const handleChangeValue = (field: keyof InitialState, value?: string) => {
    if (handleChangeHeatCalculation) {
      handleChangeHeatCalculation(field, value);
    }
  };
  const  invalidLadleLifeHelperText = ladleLifeTime === '0'
    ? t('new-heat.form.error-invalid-ladle-life-zero') :
    (!['', '0'].includes(ladleLifeTime) &&
    ((!!foundLadle?.ladleLife?.currentLadleLife && ((Number(foundLadle?.ladleLife?.currentLadleLife) + 1) > Number(ladleLifeTime))) && !isNewCampaign)) ?
    (t('new-heat.form.info-invalid-ladle-life', { num: Number(foundLadle?.ladleLife?.currentLadleLife) + 1 })) : '';

  const notRecomendedLadleLifeHelperText = (!['0', ''].includes(ladleLifeTime) &&
  ((!!foundLadle?.ladleLife?.currentLadleLife && ((Number(foundLadle?.ladleLife?.currentLadleLife) + 1) !== Number(ladleLifeTime))) && !isNewCampaign)) &&
  t('new-heat.form.info-invalid-ladle-life', { num: Number(foundLadle?.ladleLife?.currentLadleLife) + 1});

  const maxFinalTemperature = finalTemperatureLimits.maximum;
  const minFinalTemperature = finalTemperatureLimits.minimum;
  const MAX_LADLE_LIFE = 1000;

  const isFinalTemperatureInvalid = finalTemperature !== '' && Number(finalTemperature) < minFinalTemperature || Number(finalTemperature) > maxFinalTemperature;
  const isInitialSulfurInvalid = initialSulfurPercentage !== null && Number(initialSulfurPercentage) < MIN_INITIAL_SULFUR_PERCENTAGE || Number(initialSulfurPercentage) > MAX_INITIAL_SULFUR_PERCENTTAGE;
  const isOxygenContentInvalid = finalOxygenInSteel !== null && Number(finalOxygenInSteel) < MIN_OXYGEN_CONTENT || Number(finalOxygenInSteel) > MAX_OXYGEN_CONTENT;

  const filter = createFilterOptions<GetLadlesResponse>();
  const tapLadleFurnaces = selectedLadleFurnace?.tapLadleFurnaces;
  const selectedTapLadleFurnace = tapLadleFurnaces?.find(tapItem => tapItem.code === Number(tap));

  const tapFieldsShouldValidate = !tapChanged
    && !!heatCalculation.ladleFurnace
    && heatCalculation.ladleFurnace !== 'none'

  const isCarryoverSlagInferenceRanged = carryoverSlagInferenceType === CarryoverSlagInferenceType.Range;
  const carryoverSlagReferenceInput = heatResponse?.input.heatSettings.carryoverSlagTargetReferenceName ?? userInfo?.selectedPlant?.carryoverSlagReferenceInput;

  const carryoverSlagInferenceLabel = getReferenceValueLabel(
    !!selectedCarryoverSlag?.maxValue,
    selectedCarryoverSlag,
  );

  const definedUnitOfMeasurement = useMemo(() => {
    return UNITS_OF_MEASUREMENT[
      heatResponse?.input.heatSettings.carryoverSlagTargetReferenceUnitOfMeasurement
      ?? userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement ?? CarryoverSlagUnitOfMeasurement.Points
    ]
  }, [
    heatResponse?.input.heatSettings.carryoverSlagTargetReferenceUnitOfMeasurement,
    userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement
  ]);

  const viewModeSelectedSteelGrade: SteelGradeModel = useMemo(() => {
    return({
      id:1,
      customerId: 1,
      name: steelGrade,
      categoryId: 1,
      categoryName: steelGradeCategory,
      finalOxygenInSteel: null,
      initialSulfurPercentage: null,
      saturationMode: 'DualSaturation'
    })
  }, [steelGrade, steelGradeCategory]);

  const selectedSteelGrade = useMemo(() => {
    return disabled ? viewModeSelectedSteelGrade : steelGradeList.find(item => item.name === steelGrade) || null
  }, [steelGrade, steelGradeList, disabled, viewModeSelectedSteelGrade]);

  const carryoverSlagReferenceInputValue = useMemo(() => {
    if(carryoverSlagInferenceType !== CarryoverSlagInferenceType.SteelGradeCategory) {
      return carryoverSlagReferenceList.find((csrl) => csrl.id === selectedCarryoverSlag?.id)?.id ?? '';
    } else if(!selectedSteelGrade) {
      return undefined
    } else {
      return carryoverSlagReferenceList.find((csrl) => csrl.id === selectedCarryoverSlag?.id)?.id ?? '';
    }
  }, [carryoverSlagReferenceList, selectedCarryoverSlag?.id, selectedSteelGrade, carryoverSlagInferenceType]);

  const carryoverSlagReferenceInputValueOptions: FormSelectOptions<number> = useMemo(() => {
    return carryoverSlagReferenceList.map(row => {
      return ({
        value: row.id,
        name: carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
          ? `${row.steelGradeCategoryName}`
          :  getReferenceValueLabel(
            isCarryoverSlagInferenceRanged,
            row,
          )
      })
    })
  }, [
    carryoverSlagReferenceList,
    isCarryoverSlagInferenceRanged,
    carryoverSlagInferenceType,
  ])

    const saturationModeOptions: FormSelectOptions<string> = [
      {
        name: t('new-heat.form.text-field.saturation-mode.option.dual-saturation'),
        value: 'DualSaturation',
        tooltip: t('hints.heat.saturation-mode.dual-saturation'),
      },
      {
        name: t('new-heat.form.text-field.saturation-mode.option.mgo-saturation'),
        value: 'MgOSaturation',
        tooltip: t('hints.heat.saturation-mode.mgo-saturation'),
        disabled: !APP_MGO_SATURATION_MODE_ENABLED,
      }
    ]

  const gridItemStyle: React.CSSProperties = {
    padding: 0,
    width: 'calc(50% - 4px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  }

  const { renderTemperatureInputEndAdornment, renderLengthInputEndAdornment, renderWeightInputEndAdornment } = useRenderInputEndAdornment();
  const { returnTemperatureUnitOfMeasurement, returnWeightUnitOfMeasurement } = useReturnUnitOfMeasurement();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          maxWidth: !isTappingMode ? '2416px' : '1808px',
          margin: '0 auto',
          flexDirection: 'column',
          rowGap: '8px',
          paddingBottom: '8px'
        }}
      >
        {!disabled && (
          <Typography>{t('new-heat.form.warning.invalid-form')}</Typography>
        )}
        {plantIsIncompatible && (
          <WarningMessageComponent
            message={t("draft-edit.plant-config-error.message")}
          />
        )}
        {stepIsInvalid && (
          <WarningMessageComponent
            message={heatMode === 'operation' ? t('heat.invalid-form-error.operation.message') : t('heat.invalid-form-error.draft.message')}
          />
        )}
      </Box>
      <FormWrapper>
        <FormSectionContainer
          title={t('new-heat.form.title.data')}
          style={{ flex: 2, maxWidth: '1200px' }}
        >
          <Grid style={{ display: 'flex', gap: '8px' }}>
            <Grid item style={gridItemStyle}>
              {heatMode === 'operation' ? (
                <>
                  <WarningBox withProblemToReport={stepIsInvalid && heatName === ''}>
                    <TextField
                      fullWidth
                      disabled={disabled}
                      label={`${t('heat.name')} ${!disabled ? '*' : ''}`}
                      autoComplete="off"
                      value={heatName}
                      style={{ fontWeight: 'bolder', width: '150w' }}
                      onChange={e => {
                        handleChangeValue('heatName', String(e.target.value).replaceAll(/[^A-Za-z0-9-]/g, ""));
                      }}
                      onBlur={(e: { target: { value: string }}) => {
                        handleVerifyName(e.target.value.toUpperCase());
                      }}
                      error={isHeatNameInvalid}
                      helperText={
                        isHeatNameInvalid && t('new-heat.form.error-invalid-heat-name')
                      }
                      InputProps={{
                        endAdornment: isVerifyHeatLoading && <CircularProgress color="inherit" size={20} />,
                        disableUnderline: stepIsInvalid && heatName === ''
                      }}
                      inputProps={{ maxLength: 25, style: { textTransform: 'uppercase' } }}
                    />
                  </WarningBox>

                  <Box>
                  <WarningBox withProblemToReport={stepIsInvalid && !value?.ladleName}>
                    {(!disabled && !disableLadleFields)
                      ? (
                        <FormAutocompleteInput
                          id="ladle-name-autocomplete"
                          disableUnderline={stepIsInvalid && inputValue === ''}
                          value={value}
                          label={`${t('new-heat.form.text-field.ladle-name')} *`}
                          onChange={(event, value) => {
                            const newValue = {...value as GetLadlesResponse};
                            if (typeof newValue === 'string') {
                              setValue({
                                ladleName: newValue,
                              });
                              handleChangeHeatCalculation?.('ladleName', newValue);
                            } else if (newValue && newValue.inputValue) {
                                setValue({
                                  ladleName: newValue.inputValue,
                                });
                                handleChangeHeatCalculation?.('ladleName', newValue.inputValue);
                            } else {
                                setValue(newValue ?? '');
                                handleChangeHeatCalculation?.('ladleName', newValue?.ladleName ?? '');
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some(option => inputValue === option.ladleName);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                ladleName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={ladlesList ?? []}
                          getOptionLabel={(option) => option.ladleName?.toString() ?? ''}
                          freeSolo
                          inputValue={inputValue}
                          onInputChange={(e, val) => {
                            if (
                              (!Number.isInteger(Number(val)) || val.includes('.')) || val.length > 7
                              ) {
                                return;
                              }
                              setInputValue(val);
                            }
                          }
                        />
                      ) : (
                        <TextField
                          fullWidth
                          label={t('new-heat.form.text-field.ladle-name')}
                          disabled
                          value={displayNotApplicableIfNull(ladleName, true)}
                        />
                      )}
                    </WarningBox>
                  </Box>
                </>

              ) : (
                <WarningBox withProblemToReport={stepIsInvalid && !heatDescription}>
                  <TextField
                    name="heatDescriptionText"
                    label={`${t('heat.description')} ${!disabled ? '*' : ''}`}
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    disabled={disabled}
                    value={heatDescription}
                    onChange={e => {
                      if(e.target.value?.length <= 250)
                      handleChangeValue('heatDescription', e.target.value ?? '');
                    }}
                    />
                </WarningBox>
              )}

              <WarningBox withProblemToReport={stepIsInvalid && ladleLifeTime === ''}>
                <NumberFormat
                  fullWidth
                  disabled={disabled || disableLadleFields}
                  label={`${t('new-heat.form.text-field.current-ladle-life')} ${!disabled ? '*': ''}`}
                  autoComplete="off"
                  value={displayNotApplicableIfNull(ladleLifeTime, true)}
                  style={{ fontWeight: 'bolder', width: '150w' }}
                  onValueChange={e => {
                    handleChangeValue('ladleLifeTime', String(e.floatValue ?? ''))
                  }}
                  error={isLadleLifeInvalid}
                  helperText={(invalidLadleLifeHelperText || notRecomendedLadleLifeHelperText)}
                  InputProps={{
                    endAdornment: t('new-heat.form.text-field.ladle-life-sufix')
                  }}
                  customInput={TextField}
                  allowNegative={false}
                  decimalScale={0}
                  decimalSeparator={t('decimal-scale-separator')}
                  isAllowed={({floatValue}) => (floatValue ?? 0) < MAX_LADLE_LIFE}
                  />
              </WarningBox>

              {heatMode === 'operation' && (
                <Box>
                  <FormCheckbox
                    disabled={disabled || disableLadleFields}
                    id='isNewCampaignInput'
                    label={t('new-heat.form.text-field.current-new-campaign')}
                    value={isNewCampaign}
                    onChange={(checked) => handleChangeHeatCalculation?.('isNewCampaign', checked)}
                  />
                </Box>
              )}
              {!isTappingMode && (
                <Box>
                  <FormSelect
                    disabled={disabled}
                    id="ladlefurnace"
                    label={`${t('ladlefurnace')} ${!disabled ? '*':''}`}
                    withProblemToReport={
                      (
                        stepIsInvalid
                        && !selectedLadleFurnace?.id
                      ) || (
                        plantIsIncompatible
                        && !!heatCalculation?.ladleFurnace
                        && heatCalculation.ladleFurnace !== 'none'
                        && !selectedLadleFurnace
                      )
                    }
                    onChange={e => {
                      handleLadleFurnaceChange?.(Number(e.target.value));
                    }}
                    options={ladleFurnaceList.map(row => ({
                      name: row.name,
                      value: row.id,
                    }))}
                    value={selectedLadleFurnace?.id ?? '' }
                  />
                </Box>
              )}

              <Box>
                <WarningBox
                  style={{ width: '100%', display: 'flex', flex: 1 }}
                  withProblemToReport={
                    (
                      stepIsInvalid
                      && !steelGrade
                    ) || (
                      plantIsIncompatible
                      && !!heatCalculation?.steelGrade
                      && !selectedSteelGrade
                    )
                  }
                >
                  <FormAutocompleteInput
                    id="steel-grade-autocomplete"
                    fullWidth
                    data-testid="steel-grade-autocomplete"
                    label={`${t('steel-grade')} ${!disabled ? '*' : ''}`}
                    options={disabled ? [viewModeSelectedSteelGrade] : steelGradeList as SteelGradeModel[]}
                    groupBy={(option) => option.categoryName || ''}
                    disabled={disabled}
                    disableUnderline={
                      (
                        stepIsInvalid
                        && !steelGrade
                      ) || (
                        plantIsIncompatible
                        && !!heatCalculation?.steelGrade
                        && !selectedSteelGrade
                      )
                    }
                    getOptionLabel={(option) => {
                      return option.name
                    }}
                    onChange={(_, value) => {
                      const selectedSteelGradeData = {...value as SteelGradeModel};
                      if(carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory) {
                        const selectedCarryoverSlag =  carryoverSlagReferenceList.find(csr => csr.steelGradeCategoryId === selectedSteelGradeData.categoryId);
                        setSelectedCarryoverSlag(
                          selectedCarryoverSlag
                          ? { unitOfMeasurement: null, id: selectedCarryoverSlag?.id, value: selectedCarryoverSlag?.value, name: selectedCarryoverSlag?.name }
                          : undefined
                        )
                      }
                      handleChangeHeatCalculation?.(
                        [
                          'steelGrade',
                          'steelGradeCategory',
                          'saturationMode',
                          ...isDesulfurizationEnabled ? [
                            'initialSulfurPercentage',
                            'finalOxygenInSteel'
                          ] : []
                        ], [
                          selectedSteelGradeData.name,
                          selectedSteelGradeData.categoryName,
                          selectedSteelGradeData.saturationMode ?? '',
                          ...isDesulfurizationEnabled ? [
                            selectedSteelGradeData.initialSulfurPercentage  ?? '',
                            selectedSteelGradeData.finalOxygenInSteel ?? ''
                          ] : []
                        ]);
                    }}
                    value={selectedSteelGrade}
                  />
                </WarningBox>
              </Box>

              <Tooltip placement="left" title={<>{t('hints.heat.temperature')}</>}>
                <Box>
                  <WarningBox
                    withProblemToReport={
                      stepIsInvalid && !finalTemperature
                    }
                  >
                    <NumberFormat
                      fullWidth
                      autoComplete="off"
                      disabled={disabled}
                      label={`${t('new-heat.form.text-field.temp-FP')} ${!disabled ? '*' : ''}`}
                      InputProps={{endAdornment: renderTemperatureInputEndAdornment(measurementSystem, disabled)}}
                      value={finalTemperature}
                      onValueChange={e => handleChangeValue('finalTemperature', String(e.value ?? ''))}
                      error={isFinalTemperatureInvalid}
                      helperText={
                        isFinalTemperatureInvalid &&
                        t('new-heat.form.text-field.helperText.temp-FP', { min: minFinalTemperature, max: maxFinalTemperature, uom: returnTemperatureUnitOfMeasurement(measurementSystem) })
                      }
                      isAllowed={({floatValue})=> (floatValue ?? 0) <= maxFinalTemperature}
                      customInput={TextField}
                      allowNegative={false}
                      decimalScale={0}
                      decimalSeparator={t('decimal-scale-separator')}
                      />
                  </WarningBox>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item style={gridItemStyle}>
              <FormSelect
                withProblemToReport={stepIsInvalid && !saturationMode}
                fullWidth
                style={{ width: '100%' }}
                id="saturationMode"
                name="saturationMode"
                label={`${t('new-heat.form.text-field.saturation-mode')} ${!disabled ? '*' : ''}`}
                value={saturationMode ?? ''}
                required
                disabled={disabled}
                hasTooltip={!disabled}
                options={saturationModeOptions}
                onChange={e => {
                  handleChangeValue('saturationMode', e.target.value as string)
                }}
              />
              {APP_DESULFURIZATION_INPUT_ENABLED && (
                <>
                  <Divider variant='middle' style={{ margin: '12.493px 0' }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <FormCheckbox
                      disabled={disabled}
                      id='isDesulfurizationEnabled'
                      label={t('new-heat.form.text-field.desulfurization-info')}
                      value={isDesulfurizationEnabled}
                      onChange={(checked) => {
                        handleChangeHeatCalculation?.([
                          'initialSulfurPercentage',
                          'finalOxygenInSteel'
                        ], [
                          checked ? selectedSteelGrade?.initialSulfurPercentage : '',
                          checked ? selectedSteelGrade?.finalOxygenInSteel : '',
                        ]);
                        setIsDesulfurizationEnabled(checked);
                      }}
                    />
                  </Box>
                </>
              )}
              {APP_DESULFURIZATION_INPUT_ENABLED && (
                <>
                  {!disabled ? (
                    <Tooltip placement="left" title={<>{t('hint.secondary-metallurgy')}</>}>
                      <Box>
                        <NumberFormat
                          fullWidth
                          autoComplete="off"
                          disabled={!isDesulfurizationEnabled}
                          label={`${t('new-heat.form.text-field.initial-sulfur')} ${isDesulfurizationEnabled ? '*' : ''}`}
                          InputProps={{
                            endAdornment: 'wt%',
                          }}
                          value={initialSulfurPercentage}
                          error={isInitialSulfurInvalid && isDesulfurizationEnabled}
                          helperText={
                            isDesulfurizationEnabled &&
                            isInitialSulfurInvalid &&
                            t('new-heat.form.text-field.helperText.initial-sulfur', { min: MIN_INITIAL_SULFUR_PERCENTAGE, max: MAX_INITIAL_SULFUR_PERCENTTAGE })
                          }
                          onValueChange={e => handleChangeValue('initialSulfurPercentage', e.value ?? null)}
                          customInput={TextField}
                          allowNegative={false}
                          decimalScale={4}
                          decimalSeparator={t('decimal-scale-separator')}
                          isAllowed={(input) =>
                            (input.floatValue ?? 0) <= MAX_INITIAL_SULFUR_PERCENTTAGE
                          }
                        />
                      </Box>
                    </Tooltip>
                  ): (
                    <Box>
                      <NumberFormat
                        fullWidth
                        autoComplete="off"
                        disabled
                        label={t('new-heat.form.text-field.initial-sulfur')}
                        InputProps={{
                          endAdornment: 'wt%',
                        }}
                        value={initialSulfurPercentage}
                        onValueChange={e => handleChangeValue('initialSulfurPercentage', e.value ?? null)}
                        customInput={TextField}
                        allowNegative={false}
                        decimalScale={4}
                        decimalSeparator={t('decimal-scale-separator')}
                        isAllowed={(input) =>
                          (input.floatValue ?? 0) <= MAX_INITIAL_SULFUR_PERCENTTAGE
                        }
                      />
                    </Box>
                  )}
                  {!disabled ? (
                    <Tooltip placement="left" title={<>{t('hint.secondary-metallurgy')}</>}>
                      <Box>
                        <NumberFormat
                          fullWidth
                          autoComplete="off"
                          disabled={!isDesulfurizationEnabled}
                          label={`${t('new-heat.form.text-field.oxygen-content')} ${isDesulfurizationEnabled ? '*' : ''}`}
                          InputProps={{
                            endAdornment: 'ppm',
                          }}
                          InputLabelProps={{ shrink: true }}
                          value={finalOxygenInSteel}
                          error={isOxygenContentInvalid && isDesulfurizationEnabled}
                          helperText={
                            isDesulfurizationEnabled &&
                            isOxygenContentInvalid &&
                            t('new-heat.form.text-field.helperText.oxygen-content', { min: MIN_OXYGEN_CONTENT, max: MAX_OXYGEN_CONTENT })
                          }
                          onValueChange={e => handleChangeValue('finalOxygenInSteel', e.value ?? '')}
                          customInput={TextField}
                          allowNegative={false}
                          decimalScale={0}
                          decimalSeparator={t('decimal-scale-separator')}
                          isAllowed={(input) =>
                            (input.floatValue ?? 0) <= MAX_OXYGEN_CONTENT
                          }
                        />
                      </Box>
                    </Tooltip>
                  ): (
                    <Box>
                      <NumberFormat
                        fullWidth
                        autoComplete="off"
                        disabled
                        label={t('new-heat.form.text-field.oxygen-content')}
                        InputProps={{
                          endAdornment: 'ppm',
                        }}
                        value={finalOxygenInSteel}
                        onValueChange={e => handleChangeValue('finalOxygenInSteel', e.value ?? null)}
                        customInput={TextField}
                        allowNegative={false}
                        decimalScale={0}
                        decimalSeparator={t('decimal-scale-separator')}
                        isAllowed={(input) =>
                          (input.floatValue ?? 0) <= MAX_OXYGEN_CONTENT
                        }
                      />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </FormSectionContainer>

        <FormSectionContainer
          title={t('carryover-slag')}
          style={{ minWidth: '180px' }}
        >
          <Box>
            {!disabled ? (
              <FormSelect
                fullWidth
                style={{ width: '100%' }}
                withProblemToReport={
                  (plantIsIncompatible || stepIsInvalid)
                  && (
                    !selectedCarryoverSlag?.id
                    || !selectedSteelGrade
                  )
                }
                id="carryoverslagReferenceInput"
                label={
                  carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
                  ? `${carryoverSlagReferenceInput} *`
                  : `${carryoverSlagReferenceInput} (
                    ${t(
                      UNITS_OF_MEASUREMENT[
                        userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement ?? CarryoverSlagUnitOfMeasurement.Points
                      ]
                    )}
                  ) *`
                }
                name="aqVazamento"
                value={carryoverSlagReferenceInputValue ?? ''}
                required
                disabled={carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory}
                options={carryoverSlagReferenceInputValueOptions}
                onChange={e => {
                  const selectedId = e.target.value as CarryoverSlagReferenceGetResponse;
                  const selectedCarryOverSlag = carryoverSlagReferenceList.find((csrl) => Number(csrl.id) === Number(selectedId));
                  setSelectedCarryoverSlag({
                    id: selectedCarryOverSlag?.id,
                    value: selectedCarryOverSlag?.value,
                    minValue: selectedCarryOverSlag?.minValue,
                    maxValue: selectedCarryOverSlag?.maxValue,
                    unitOfMeasurement:
                      heatResponse?.input.heatSettings.carryoverSlagTargetReferenceUnitOfMeasurement
                      ?? userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement
                      ?? CarryoverSlagUnitOfMeasurement.Points,
                  });
                }}
              />
            ) : (
              <TextField
                autoComplete="off"
                disabled
                label={
                  carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
                  ? carryoverSlagReferenceInput
                  :`${carryoverSlagReferenceInput} (${t(
                    definedUnitOfMeasurement
                  )})`
                }
                fullWidth
                name="aqVazamento"
                value={
                  carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory
                  ? selectedSteelGrade?.categoryName
                  : carryoverSlagInferenceLabel ?? ''
                }
              />
            )}

          </Box>
          <WarningBox
            withProblemToReport={
              (
                plantIsIncompatible && !carryoverSlagWeight
              ) || (
                stepIsInvalid && !carryoverSlagWeight
              ) || (
                plantIsIncompatible
                && !carryoverSlagWeightorDepthHasChanged
                && !!heatCalculation.carryoverSlagWeight
                && (minimumCarryoverSlag ?? 0) > Number(heatCalculation.carryoverSlagWeight)
              )
            }
          >
            <NumberFormat
              fullWidth
              disabled={disabled}
              label={
                `${
                  carryoverSlagType === CarryoverSlagType.Thickness
                  ? t('new-heat.form.text-field.carryover-slag-depth')
                  : t('new-heat.form.text-field.carryover-slag-weight')
                } ${!disabled ? '*' : ''}`
              }
              InputProps={{
                endAdornment: carryoverSlagType === CarryoverSlagType.Thickness
                  ? renderLengthInputEndAdornment(measurementSystem, UOM_CENTIMETERS_SYMBOL, disabled)
                  : renderWeightInputEndAdornment(measurementSystem, UOM_POUNDS_SYMBOL, disabled),
              }}
              value={carryoverSlagWeight}
              autoComplete="off"
              onValueChange={e => {
                setCarryoverSlagWeightOrDepthHasChanged(true);
                handleChangeValue('carryoverSlagWeight', e.value);
              }}
              error={
                !disabled
                && carryoverSlagWeight !== ''
                && isInvalidCarryoverSlagWeight
              }
              helperText={
                carryoverSlagWeight !== ''
                && isInvalidCarryoverSlagWeight
                && t('new-heat.form.error-lower-slag-carrover-weight', { min: minimumCarryoverSlag, uom: returnWeightUnitOfMeasurement(measurementSystem, 'lb') })
              }
              customInput={TextField}
              isAllowed={({floatValue}) => (floatValue ?? 0) <= Number(maxCarryoverSlagWeightOrThickness)}
              allowNegative={false}
              decimalScale={carryoverSlagWeightOrThicknessDecimalPlaces}
              decimalSeparator={t('decimal-scale-separator')}
            />
          </WarningBox>

          {!isTappingMode && (
            <Box>
              <FormSelect
                disabled={disabled}
                id="new-heat.form.text-field.has-skimmer"
                label={`${t('new-heat.form.text-field.has-skimmer')} ${!disabled ? '*' : ''}`}
                value={String(skimmerActive) === '0'? 'none' : Number(skimmerActive)}
                onChange={e => {
                  if (handleChangeHeatCalculation) handleChangeHeatCalculation('skimmerActive', Boolean(e.target.value));
                }}
                options={[
                  { name: t('option-yes'), value: 1 },
                  { name: t('option-no'), value: 0 },
                ]}
                />
            </Box>
          )}

          {skimmerActive && (
            <Box>
              <NumberFormat
                fullWidth
                disabled={disabled}
                label={`${t('new-heat.form.text-field.skimmer-weight')} ${!disabled ? '*' : ''}`}
                InputProps={{ endAdornment: renderWeightInputEndAdornment(measurementSystem, 'lb', disabled) }}
                value={skimmerPostSlagWeight}
                onValueChange={e => handleChangeValue('skimmerPostSlagWeight', String(e.value ?? ''))}
                autoComplete="off"
                customInput={TextField}
                isAllowed={({floatValue}) => (floatValue ?? 0) <= MAX_WEIGHT}
                allowNegative={false}
                decimalScale={0}
                decimalSeparator={t('decimal-scale-separator')}
              />
            </Box>
          )}
        </FormSectionContainer>

        {!isTappingMode && (
          <FormSectionContainer
            title={t('tap')}
            subtitle={t('ladlefurnace')}
            style={{ minWidth: '180px' }}
          >
            <Box>
              <FormSelect
                disabled={disabled}
                id="tap"
                label={t('tap')}
                value={tapList.find(tapItem => tapItem.code === Number(tap))?.code ?? ''}
                withProblemToReport={
                  (
                    stepIsInvalid
                    && !tap
                  ) || (
                    plantIsIncompatible
                    && !tapChanged
                    && !!heatCalculation.ladleFurnace
                    && heatCalculation.ladleFurnace !== 'none'
                    && !tapLadleFurnaces?.some(tapItem => tapItem.code === Number(tap))
                  )
                }
                onChange={e => handleTapChange(Number(e.target.value))}
                options={tapList.map((row: { code: number }) => ({ name: String(row.code), value: row.code }))}
              />
            </Box>

            <WarningBox
              withProblemToReport={
                plantIsIncompatible
                && !tapChanged
                && !!heatCalculation.ladleFurnace
                && heatCalculation.ladleFurnace !== 'none'
                && selectedTapLadleFurnace?.arcLength !== Number(arcLength)
              }

            >
              <TextField
                fullWidth
                autoComplete="off"
                InputProps={{
                  endAdornment: renderLengthInputEndAdornment(measurementSystem, UOM_CENTIMETERS_SYMBOL, true)
                }}
                label={t('arc-length')}
                value={(
                  measurementSystem === SystemOfMeasurementOptions.Imperial
                    ? Number(arcLength)
                    : convertMetersIntoOtherUnitOfMeasurement(Number(arcLength), UOM_CENTIMETERS_SYMBOL)
                ).toFixed(1)}
                disabled
              />
            </WarningBox>

            <WarningBox
              withProblemToReport={
                plantIsIncompatible
                && !tapChanged
                && !!heatCalculation.ladleFurnace
                && heatCalculation.ladleFurnace !== 'none'
                && selectedTapLadleFurnace?.minimumSlagDepth !== Number(minimumSlagDepth)
              }

            >
              <TextField
                fullWidth
                autoComplete="off"
                label={t('new-heat.form.text-field.slag-depth')}
                InputProps={{
                  endAdornment: renderLengthInputEndAdornment(measurementSystem, UOM_CENTIMETERS_SYMBOL, true)
                }}
                value={(
                  measurementSystem === SystemOfMeasurementOptions.Imperial
                    ? Number(minimumSlagDepth)
                    : convertMetersIntoOtherUnitOfMeasurement(Number(minimumSlagDepth), UOM_CENTIMETERS_SYMBOL)
                ).toFixed(1)}
                disabled
              />
            </WarningBox>

            <WarningBox
              withProblemToReport={
                plantIsIncompatible
                && tapFieldsShouldValidate
                && selectedTapLadleFurnace?.minimumSlagWeight !== Number(minimumSlagWeight)
              }

            >
              <TextField
                fullWidth
                autoComplete="off"
                label={t('new-heat.form.text-field.weight-slag')}
                InputProps={{
                  endAdornment: renderWeightInputEndAdornment(measurementSystem, UOM_POUNDS_SYMBOL, true)
                }}
                value={
                  displayNotApplicableIfZero(
                    ['number', 'string'].includes(typeof minimumSlagWeight)
                      ? Math.floor(Number(minimumSlagWeight))
                      : minimumSlagWeight,
                    true
                  )
                }
                disabled
              />
            </WarningBox>
          </FormSectionContainer>
        )}
      </FormWrapper>
    </Box>
  );
};

export default Step1;
