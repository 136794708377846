import { useState } from 'react';
import { HooksStatus } from './types';

export interface ApiResult<T> {
  data: T | undefined;
  status: HooksStatus;
}

const useCallApi = <T>(service: () => Promise<T>, idleValue: T | undefined): [
  ApiResult<T>,
  () => Promise<void>,
] => {
  const [result, setResult] = useState<ApiResult<T>>({
    status: 'idle',
    data: idleValue,
  });

  const call = async () => {
    try {
      setResult(prevState => ({...prevState, status: 'loading' }));

      const response = await service();
      setResult({ data: response, status: 'succeeded' });
    } catch (err) {

      setResult({ data: undefined, status: 'error' });
    } 
  };
  
  return [result, call];
}

export default useCallApi;