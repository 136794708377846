import { makeStyles, createStyles } from "@material-ui/core";

const useCompositionTableCellStyles = () => {

    const useStyles = () => 
    makeStyles(() => 
        createStyles({
            root: {
                padding: '16px 8px',
                '&:nth-child(1)': {
                    padding: '16px 8px 16px 16px',
                },
                '&:nth-child(2)': {
                    padding: '16px 8px 16px 16px',
                },
                '&:last-child': {
                    padding: '16px 16px 16px 8px',
                }
            },
            head: {
                paddingTop: '24px',
                paddingBottom: '24px'
            },
            input: {
                width: '78px',
                '&:not(:hover):not(:active):not(:focus):not(.Mui-focused)': {
                    border: 'none',
                    backgroundColor: 'transparent'
                },
                '&.Mui-disabled:hover': {
                    border: 'none',
                    backgroundColor: 'transparent'
                },
            }

        })
    );
  

    return { useStyles }
}

export default useCompositionTableCellStyles;