import { APP_TITLE } from '@app/utils/constants';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SteelGradeContent from './SteelGradeContent';

const SteelGradeContainer = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('steel-grade')} | {APP_TITLE}
        </title>
      </Helmet>
      <SteelGradeContent />
    </>
  );
};

export default SteelGradeContainer;
