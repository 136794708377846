import { SvgIconProps, useTheme } from "@material-ui/core"
import { ReportProblemOutlined } from "@material-ui/icons"

interface ReportProblemsProps extends SvgIconProps {
  noMargin?: boolean;
  size?: '16px' | '24px' | '30px';
}

export function ReportProblemIcon({noMargin = false, size, ...rest}: ReportProblemsProps) {
  const theme = useTheme();
  return (
    <ReportProblemOutlined 
      {...rest}
      style={{
        color: theme.palette.warning.main,
        marginRight: noMargin ? 0 : '10px',
        marginLeft: noMargin ? 0 : '20px',
        width: `${size ?? '16px'}`,
        height: `${size ?? '16px'}`,
      }} 
    />
  )
}