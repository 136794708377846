import { PlantsGetResponse } from '@app/api/plants';
import { UsersCreateRequest, UsersGetResponse, UsersUpdateRequest, useUsersService } from '@app/api/users';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import Loading from '@app/components/shared/Loading';
import { handleSendEvent } from '@app/utils';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import InviteUserModal from './modals/InviteUserModal';
import UserManagementTable from './UserManagementTable';
import { Box, Typography } from '@material-ui/core';
import { ROLES } from '../userManagementUtils';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { UserStatus } from '@app/api/users/UsersGetResponse';
import { getStatusTranslationKey } from '@app/components/shared/StatusComponent';
import { FilterDTO } from './UserManagementContainer';
import FormButton from '@app/components/shared/FormButton';
import { UpdateUserModal } from './modals/UpdateUserModal';
import useRenderFormFilterWithTags from '@app/hooks/useRenderFormFilterWithTags';

export interface UserManagementFormInputs {
  search: string;
  plants: Array<string>;
  roles: Array<string>;
  status: Array<string>;
  customers: Array<string>;
}

interface UserManagementContentProps {
  plants: PlantsGetResponse[];
  users: UsersGetResponse[];
  openInviteUserModal: boolean;
  setOpenInviteUserModal: Dispatch<SetStateAction<boolean>>;
  setUsersToChange: (userToChange: UsersUpdateRequest[]) => void;
  usersToChange: UsersUpdateRequest[];
  page: number;
  total: number;
  sortingOrder?: string;
  loadUsers: (
    filter?: string,
    plants?: string[],
    status?: string[],
    roles?: string[],
    customers?: number[],
    size?: number,
    page?: number,
    sortingOrder?: string
  ) => Promise<void>;
  isPlantAdmin: boolean;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  filter: FilterDTO;
  customersOptions: string[];
}

const UserManagementContent: React.FC<UserManagementContentProps> = (
  props: UserManagementContentProps,
) => {
  const {
    plants,
    users,
    setUsersToChange,
    usersToChange,
    page,
    total,
    loadUsers,
    sortingOrder,
    isPlantAdmin,
    rowsPerPage,
    setRowsPerPage,
    filter,
    openInviteUserModal,
    setOpenInviteUserModal,
    customersOptions
  } = props;
  const { t } = useTranslation();

  const { createUser } = useUsersService();

  const { control, reset } = useFormContext();

  const options = plants.map((plant, index) => ({ value: index + 1, name: plant.name }));
  options.unshift({ value: 0, name: t('admin-panel.select-plant') });

  const [allUsers, setAllUsers] = useState(users);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [userBlockModal, setUserBlockModal] = useState<UsersGetResponse | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userEditModal, setUserEditModal] = useState<UsersGetResponse | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAllUsers(users);
  }, [users]);

  const handleUpdateUser = (userId: number, userData: Omit<Partial<UsersGetResponse>, "id">) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Changed Some User Info.',
    });
    const newUsersToChange = [...usersToChange];
    const userDataToUpdateIndex = newUsersToChange.findIndex(user => user.id === userId);

    if(userDataToUpdateIndex < 0) {
      newUsersToChange.push({...userData, id: userId});
    } else {
      newUsersToChange[userDataToUpdateIndex] = {
        ...newUsersToChange[userDataToUpdateIndex],
        ...userData,
        id: userId
      }
    }
    setUsersToChange(newUsersToChange);
  }

  const handleChangeUserProperty = (userInfo: { userId: number; field: string; value: number | string | string[] }) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Changed Some User Info.',
    });

    const newUsersToChange = JSON.parse(JSON.stringify(usersToChange));
    const userToChangeIndex = usersToChange.findIndex(utc => utc.id === userInfo.userId);

    if (userToChangeIndex !== -1) {
      newUsersToChange[userToChangeIndex][userInfo.field] = JSON.parse(JSON.stringify(userInfo.value));
    } else {
      const user = allUsers.find(user => user.id === userInfo.userId);
      const newUserToChange = {
        ...user,
        id: user?.id,
        plants: user?.plants,
        roleId: user?.userProfileId,
        userProfileId: user?.userProfileId,
        measurementSystem: user?.measurementSystem,
        isDeleted: false,
      };
      newUsersToChange.push(newUserToChange);
      newUsersToChange[newUsersToChange.length - 1][userInfo.field] = JSON.parse(JSON.stringify(userInfo.value));
    }

    const newUsers = JSON.parse(JSON.stringify(allUsers));
    const userIndex = newUsers.findIndex((user: UsersGetResponse) => user.id === userInfo.userId);
    newUsers[userIndex][userInfo.field] = JSON.parse(JSON.stringify(userInfo.value));

    setAllUsers(newUsers);
    setUsersToChange(newUsersToChange);
  };

  const { loadUserInfo } = useAuthenticationContext();

  const handleConfirmBlockModal = (user: UsersGetResponse) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Opened Block User Modal.',
    });
    setUserBlockModal(user);
    setOpenBlockModal(true);
  };

  const handleOpenEditModal = (user: UsersGetResponse) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Opened Edit Modal.',
    });
    setOpenEditModal(true);
    setUserEditModal(user);
  };

  const { blockUser, reinviteUser } = useUsersService();

  const handleBlockUser = async (userId: number | null) => {
    const isBlockAction = userBlockModal?.status !== UserStatus.BLOCKED;

    handleSendEvent({
      category: 'Users/AdminPanel',
      action: `User ${isBlockAction ? 'Block' : 'Unblock'} a User.`,
    });

    if (!Number.isInteger(userId)) {
      return;
    }

    try {
      setLoading(true);

      await blockUser({ block: isBlockAction }, userBlockModal?.id ?? -1);
      await Promise.all([loadUsers(filter.search, filter.plants, filter.status, filter.roles, filter.customers, rowsPerPage, page, sortingOrder), loadUserInfo()]);
      setUserBlockModal(null);
      setOpenBlockModal(false);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: isBlockAction ? t('admin-panel.block.notification') : t('admin-panel.unblock.notification'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
    } catch (e) {
      setUserBlockModal(null);
      setOpenBlockModal(false);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const handleReinviteUser = async (userId: number) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Resend invitation to a User.',
    });

    if (!Number.isInteger(userId)) {
      return;
    }

    try {
      setLoading(true);
      await reinviteUser(userId);
      await Promise.all([loadUsers(filter.search, filter.plants, filter.status, filter.roles, filter.customers, rowsPerPage, page, sortingOrder), loadUserInfo()]);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('admin-panel.invite.notification'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInviteUser = async (user: UsersCreateRequest) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Invited a new User.',
    });

    try {
      setLoading(true);

      await createUser(user);

      await loadUsers(filter.search, filter.plants, filter.status, filter.roles, filter.customers, rowsPerPage, page);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('admin-panel.invite.notification'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      reset({ logins: [], roleId: '', userPlants: undefined });

      setOpenInviteUserModal(false);
    } finally {
      setLoading(false);
    }
  };

  const changePage = async (newPage: number) => {
    await loadUsers(filter.search, filter.plants, filter.status, filter.roles, filter.customers, rowsPerPage, newPage + 1, sortingOrder);
  };

  const changeRowsPerPage = async (rows: number) => {
    setRowsPerPage(rows);
    await loadUsers(filter.search, filter.plants, filter.status, filter.roles, filter.customers, rows, 1, sortingOrder);
  };

  const statusOptions = [
    getStatusTranslationKey(UserStatus.ACTIVE),
    getStatusTranslationKey(UserStatus.BLOCKED),
    getStatusTranslationKey(UserStatus.BLOCKED_BY_PLANT),
    getStatusTranslationKey(UserStatus.INVITATION_SENT),
  ]

  const { renderFilterWithTags } = useRenderFormFilterWithTags();

  const renderTextFilter = () => {
    return (
      <Box style={{ width: '100%' }}>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('filter.user')}
              autoComplete="off"
              style={{ fontWeight: 'bolder', width: '150w' }}
              fullWidth
              InputProps={{...field, endAdornment: <SearchIcon fontSize="small" color="disabled" /> }}
            />
          )}
        />
      </Box>
    );
  }

  const renderSearchForm = () => {
    const filterContainerStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      gap: '10px',
      width: '100%',
      paddingBottom: '16px'
    }

    return (
      <Box style={filterContainerStyle}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', flex: 1 }}>
          {renderTextFilter()}
          {
            !isPlantAdmin && (
              <>{renderFilterWithTags('plants', plants.map(item => item.name))}</>
            )
          }
          {renderFilterWithTags('roles', ROLES.map(item => item.name), true)}
          {renderFilterWithTags('status', statusOptions, true)}
          {
            !isPlantAdmin && (
              <>{renderFilterWithTags('customers', customersOptions)}</>
            )
          }
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flex: 0, marginTop: '22px' }}>
          <FormButton
            type="submit"
            variant="secondary"
            key="user-management-search-button"
            size="small" >
            {t('filter.search').toUpperCase()}
          </FormButton>
        </Box>
      </Box>
    );
  };

  const renderBlockUserDialog = () => (
    <ConfirmDialog
      visible={openBlockModal}
      setVisible={setOpenBlockModal}
      title={userBlockModal?.status === UserStatus.BLOCKED ? t('admin-panel.user.unblock-modal.title') : t('admin-panel.user.block-modal.title')}
      content={
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography variant='body2'><b>{`${t('e-mail')}: `}</b>{userBlockModal?.email}</Typography>
          <Typography variant='body2'>
            <b>{`${t('admin-panel.assigned.plants')}: `}</b>
              {
                plants.filter(plant => userBlockModal?.plants.includes(plant.id)).map(plant => plant.name).flatMap(p => p).toString().replaceAll(',', ', ')
              }
            </Typography>
            <Typography variant='body2'>
            <b>{`${t('user.profile')}: `}</b>
              {t(ROLES.find(item => item.value === userBlockModal?.userProfileId)?.name ?? '')}
            </Typography>
        </Box>
      }
      cancelText={t('button.cancel')}
      cancelAction={() => {
        handleSendEvent({
          category: 'Users/AdminPanel',
          action: 'User Closed Delete Modal.',
        });
        setUserBlockModal(null);
        setOpenBlockModal(false);
      }}
      confirmText={userBlockModal?.status === UserStatus.BLOCKED ? t('admin-panel.unblock') : t('admin-panel.block')}
      confirmAction={() => handleBlockUser(userBlockModal?.id || null)}
    />
  );

  const renderEditUserDialog = () => (
    <UpdateUserModal
      open={openEditModal}
      onClose={() => {
        handleSendEvent({
          category: 'Users/AdminPanel',
          action: 'User Closed Edit Modal.',
        });
        setUserEditModal(null);
        setOpenEditModal(false);
      }}
      onConfirm={(userId: number, userData:  Omit<Partial<UsersGetResponse>, "id">) => {
        handleUpdateUser(userId, userData);
      }}
      plants={plants}
      user={userEditModal}
    />
  );

  const renderInviteUserDialog = () => openInviteUserModal && (
    <InviteUserModal
      isPlantAdmin={isPlantAdmin}
      open={true}
      onClose={() => {
        handleSendEvent({
          category: 'Users/AdminPanel',
          action: 'User Closed Invite User Modal.',
        });
        setOpenInviteUserModal(false);
      }}
      onConfirm={handleInviteUser}
      plants={plants}
    />
  );

  const sortUsers = async (sortingOrder: string) => {
    await loadUsers(filter.search, filter.plants, filter.status, filter.roles, filter.customers, rowsPerPage, 1, sortingOrder);
  };

  const renderUsersTable = () => (
    <UserManagementTable
      reinviteUser={handleReinviteUser}
      isPlantAdmin={isPlantAdmin}
      users={allUsers}
      plants={plants}
      page={page - 1}
      rowsPerPage={rowsPerPage}
      changeRowsPerPage={changeRowsPerPage}
      changePage={changePage}
      total={total}
      searchInput={filter.search}
      changeUserProperty={(userInfo: { userId: number; field: string; value: number | string | string[] }) => handleChangeUserProperty(userInfo)}
      blockUser={(user: UsersGetResponse) => handleConfirmBlockModal(user)}
      editUser={(user: UsersGetResponse) => handleOpenEditModal(user)}
      sortUsers={sortUsers}
    />
  );

  return (
    <>
      <Grid>
        <Loading dataTestId='user-management-screens-circular-progress' promiseInProgress={loading} />

        {renderSearchForm()}
        {renderBlockUserDialog()}
        {renderEditUserDialog()}
        {renderInviteUserDialog()}

        {renderUsersTable()}
      </Grid>
    </>
  );
};

export default UserManagementContent;
