const text2Subscript = (text: string) => {
  const formula = [];

  for (let i = 0; i < text.length; i++) {
    if (text[i] >= '0' && text[i] <= '9') formula[i] = <sub key={i}>{text[i]}</sub>;
    else formula[i] = text[i];
  }

  return formula;
};

export function text2SubscriptAsString(inputString: string): string {
  const outputString = inputString.replace(/\d/g, (match) => String.fromCharCode(0x2080 + parseInt(match)));

  return outputString;
}

export default text2Subscript;
