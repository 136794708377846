import { Customer, useCustomersService } from '@app/api/customers';
import { useState } from 'react';
import { ListInfoObject } from "./types"


const infoObjectInitialState: ListInfoObject<Customer> = {
  status: 'idle',
  data: [],
}

const useLoadCustomers = (): [
  ListInfoObject<Customer>,
  () => Promise<void>,
] => {

  const { getCustomers } = useCustomersService();

  const [infoObject, setInfoObject] = useState<ListInfoObject<Customer>>(infoObjectInitialState);

  const loadCustomers = async () => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getCustomers();
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {
      
      setInfoObject({data: [], status: 'error'});
    }
  };


  return [infoObject, loadCustomers];
}

export default useLoadCustomers;