import logo from '@app/assets/images/logo-big.png';
import { APP_E2E_LOGIN_ENABLED, APP_ON_PREMISES } from '@app/utils/constants';
import { Box, createStyles, CssBaseline, makeStyles, Typography, Theme } from '@material-ui/core';
import { useState } from 'react';
import LoginButton from './LoginButton';
import LoginWithToken from './LoginWithToken';
import AppVersion from '@app/components/AppVersion';

const Login = () => {
  const classes = useStyles();
  const [formIsVisible, setFormIsVisible] = useState(false);

  return (
    <>
      <CssBaseline />

      <Box display="flex" flexDirection="row" height="100vh">
        <Box display="flex" flex={1}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="logo" />

            <Typography variant="h4" style={{ color: '#fff' }}>
              Ladle Slag Model
            </Typography>
            <AppVersion />
          </div>
        </Box>

        <Box display="flex" width={400} className={classes.formColumn}>
          <div style={{ width: '100%', padding: 16 }}>
            {!APP_ON_PREMISES && <LoginButton />} 
            <LoginButton isB2C />
            {APP_E2E_LOGIN_ENABLED && (
              <LoginWithToken 
                isFormVisible={formIsVisible} 
                setFormVisibility={(value: boolean) => setFormIsVisible(value)} 
              />
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      padding: '8.8rem',
      width: '100%',
      background: theme.palette.primary.main
    },
    logo: {
      width: '8.8rem',
      marginBottom: '2.2rem',
    },
    formColumn: {
      background: '#fff',
    },
  }),
);


export default Login;
