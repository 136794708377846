import { PlantsCreateRequest } from '@app/api/plants/PlantsCreateRequest';
import FormButton from '@app/components/shared/FormButton';
import { default as yup } from '@app/config/yup-locale';
import { yupResolver } from '@hookform/resolvers/yup';
import FormCheckbox from '@app/components/shared/FormCheckbox';
import { KeyboardDatePicker } from '@material-ui/pickers';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomerAutocomplete, { CustomerOptionType } from '../components/CustomerAutocomplete';
import { DateTimeUtils } from '@app/utils/dateTimeUtils';

interface CreateNewPlantModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (plant: PlantsCreateRequest) => void;
}

interface IFormInput {
  name: string;
  description: string;
  customer: CustomerOptionType | undefined;
  isTrial: boolean;
  expirationEnabled: boolean,
  startedDate?: Date;
  expiredDate?: Date;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  customer: yup.object().required().nullable(),
  isTrial: yup.boolean().required(),
  expirationEnabled: yup.boolean(),
  startedDate: yup.date().required(),
  expiredDate: yup.date().when('expirationEnabled', {
    is: (value: boolean) => value,
    then: yup.date().required(),
    otherwise: yup.date().nullable(),
  })
});

const CreateNewPlantModal: React.FC<CreateNewPlantModalProps> = (props: CreateNewPlantModalProps) => {
  const { open, onClose, onConfirm } = props;
  const { t } = useTranslation();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      customer: undefined,
      isTrial: false,
      expirationEnabled: false,
      startedDate: new Date(),
      expiredDate: undefined
    },
  });

  const watchExpirationEnabled = watch('expirationEnabled');

  useEffect(() => {
    if (!watchExpirationEnabled) {
      setValue('expiredDate', undefined);
    }
  }, [watchExpirationEnabled, setValue]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const obj: PlantsCreateRequest = {
      name: data.name,
      description: data.description,
      isTrial: data.isTrial,
      startedDate: DateTimeUtils.formatISO(data.startedDate),
      expiredDate: DateTimeUtils.formatISO(data.expiredDate)
    };

    if (data.customer?.id && data.customer?.id !== 0) {
      obj.customerId = data.customer?.id;
    } else {
      obj.customerName = data.customer?.name;
    }

    onConfirm(obj);
    reset();
  };

  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <DialogTitle id="admin-panel-create-plant-dialog-title">
          {t('admin-panel.create-plant-modal.title')}
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t('admin-panel.plant-name')}
                      InputProps={{
                        ...field,
                        endAdornment: <InputAdornment position="end">{field.value.length}/50</InputAdornment>,
                      }}
                      inputProps={{ maxLength: 50 }}
                      InputLabelProps={{shrink: true}}
                      autoComplete="off"
                      fullWidth
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name='customer'
                  control={control}
                  render={({field}) => (
                    <CustomerAutocomplete 
                      value={field.value}
                      onChange={(customer) => {
                        field.onChange(customer);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box style={{paddingTop: '5px'}}>
                  <Controller
                    name="startedDate"
                    control={control}
                    render={({ field }) => (
                      <KeyboardDatePicker
                        id="startedDate"
                        {...field}
                        fullWidth
                        label={t('admin-panel.plant-management.contract.start')}
                        autoOk={true}
                        format={t('date.format')}
                        placeholder={t('date-placeholder.format')}
                        helperText={errors.startedDate?.message}
                        error={!!errors.startedDate?.message}
                        cancelLabel={t('button.cancel')}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box style={{paddingBottom: '5px'}}>
                  <Controller
                    name='expirationEnabled'
                    control={control}
                    render={({ field }) => (
                      <FormCheckbox
                        label={t('admin-panel.plant-management.contract.expiration')}
                        id='expirationEnabled'
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="expiredDate"
                  control={control}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      id="expiredDate"
                      {...field}
                      fullWidth
                      disabled={!watchExpirationEnabled}
                      value={field.value || null}
                      autoOk={true}
                      format={t('date.format')}
                      placeholder={t('date-placeholder.format')}
                      helperText={errors.expiredDate?.message}
                      error={!!errors.expiredDate?.message}
                      cancelLabel={t('button.cancel')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="isTrial"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      id="isTrial"
                      label={t('admin-panel.plant-management.is.trial')}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t('admin-panel.description-optional')}
                      InputProps={{
                        ...field,
                        endAdornment: (
                          <InputAdornment position="end">
                            {field.value.length}/150
                          </InputAdornment>
                        ),
                      }}
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 150 }}
                      autoComplete="off"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={3}
                      error={!!errors.description?.message}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <FormButton onClick={handleClose} variant="secondary">
              {t('button.cancel')}
            </FormButton>
            <FormButton type="submit" variant="primary" disabled={!(isValid && isDirty)}>
              {t('button.add')}
            </FormButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateNewPlantModal;
