import FormButton from '@app/components/shared/FormButton';
import { handleSendEvent, ROUTES_PATH } from '@app/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface LoginButtonProps {
  isB2C?: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = (props: LoginButtonProps) => {
  const { isB2C = false } = props;

  const { t } = useTranslation();

    return (
      <Link
        onClick={() => {
          handleSendEvent({
            category: 'Login',
            action: `User Clicked in Sign In as ${isB2C ? 'External User' : 'Internal User'} Button`
          });
          localStorage.setItem('isB2C', isB2C.toString());
          localStorage.removeItem('e2e.login');
          localStorage.removeItem('e2e.token');
        }}
        to={{ pathname: ROUTES_PATH.HOME }}
        style={{ textDecoration: 'none' }}
      >
        <FormButton
          type="submit"
          style={{ marginTop: '30px' }}
          fullWidth={true}
          variant={isB2C ? "secondary" : "primary"}
        >
          {isB2C ? t('login.form.signin-b2c') : t('login.form.signin-b2b')}
        </FormButton>
      </Link>
    );
}

export default LoginButton;
