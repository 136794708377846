import { Box, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { default as yup } from '@app/config/yup-locale';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import FormButton from '@app/components/shared/FormButton';

interface LoginWithTokenProps {
  isFormVisible?: boolean;
  setFormVisibility: (value: boolean) => void;
}

export interface LoginFormData {
  token: string;
}


const LoginWithToken = (props: LoginWithTokenProps) => {

  const history = useHistory();
  
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    token: yup.string().required(),
  });

  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm<LoginFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { isFormVisible, setFormVisibility } = props;
  
  const onSubmit = async (loginData: LoginFormData) => {
    localStorage.setItem("e2e.token", loginData.token);
    localStorage.setItem("e2e.login", "true");
    localStorage.setItem("isB2C", "false");
    history.push("/app");
    setFormVisibility(false);
    reset();
  };

  return (
    <Box marginTop={1}>
      {(
        !isFormVisible 
        ? (
          <FormButton
            data-testid="e2e-login.toggle-button"
            type="button" 
            style={{ marginTop: '30px' }} 
            fullWidth={true} 
            variant='secondary'
            onClick={() => setFormVisibility(true)}
          >
            {t('login.form.signin-local-test')}
          </FormButton>
        )
        : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              data-testid="e2e-login.form.input.token"
              label='token'
              title='token'
              {...register('token')}
              autoComplete="off"
              fullWidth
              error={!!errors?.token}
              helperText={errors.token?.message}
            />
            <FormButton 
              data-testid="e2e-login.form.submit-button"
              type="submit" 
              style={{ marginTop: '30px' }} 
              fullWidth={true} 
              variant="secondary"
              disabled={!isValid}
            >
              {t('login.form.signin-local-test')}
            </FormButton>
          </form>
        )
      )}
    </Box>
  )
}

export default LoginWithToken;
