import Layout from '@app/components/Layout';
import { useRouteConfig } from '@app/config';
import RouteItem from '@app/model/RouteItem.model';
import RouteSection from '@app/model/RouteSection.model';
import Login from '@app/pages/Login';
import { ArchivedHeatProvider } from '@app/store/archivedHeat/archivedHeatContext';
import { ConfirmRedirectProvider } from '@app/store/confirmRedirectContext/confirmRedirectContext';
import { ArchivedDraftHeatProvider } from '@app/store/archivedDraftHeat/archivedDraftHeatContext';
import { AuthenticationProvider, useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { ROUTES_PATH } from '@app/utils';
import { Theme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PlantStateProvider } from '@app/store/plantValidationContext/plantValidationContext';
import { useLanguageContext } from '@app/store/languageContext/languageContext';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR, enUS, es} from 'date-fns/locale'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

export const CommonRoutes = ({ theme }: { theme: Theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <Route path="/" component={Login} exact />
    </ThemeProvider>
  );
};

export const ProtectedRoutes = ({ theme }: { theme: Theme }) => {
  const { routeSections } = useRouteConfig();

  const { state: { selectedLanguage } } = useLanguageContext();

  const localeFormatMap = {
    "en": enUS,
    "pt-BR": ptBR,
    "es": es
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeFormatMap[selectedLanguage]}>
      <AuthenticationProvider>
        <ConfirmRedirectProvider>
          <PlantStateProvider>
            <ArchivedHeatProvider>
              <ArchivedDraftHeatProvider>
                <ThemeProvider theme={theme}>
                  <Layout>
                    {routeSections.map(({ items }: RouteSection) =>
                      items.map(route =>
                        route.subRoutes ? (
                          route.subRoutes.map((item: RouteItem) => <ProtectedRoute key={item.key} route={item} />)
                        ) : (
                          <ProtectedRoute key={route.key} route={route} />
                        ),
                      ),
                    )}

                    <Route path="/app" render={() => <Redirect to={ROUTES_PATH.HOME} />} exact />
                  </Layout>
                </ThemeProvider>
              </ArchivedDraftHeatProvider>
            </ArchivedHeatProvider>
          </PlantStateProvider>
        </ConfirmRedirectProvider>
      </AuthenticationProvider>
    </MuiPickersUtilsProvider>
  );
};

const DefaultComponent = () => <div>No Component Defined.</div>;

interface ProtectedRouteProps {
  route: RouteItem;
}

const ProtectedRoute = ({ route }: ProtectedRouteProps) => {
  const {
    hasAccess,
    state: { initialLoadComplete }
  } = useAuthenticationContext();

  

  const renderComponent = () => {
    const RouteComponent = initialLoadComplete ? route?.component || DefaultComponent : React.Fragment;

    if (initialLoadComplete) {
      if (hasAccess(route.permissions)) {
        return (
          <RouteComponent />
        );
      }
  
      return (
        <Redirect to={ROUTES_PATH.HOME} />
      );
    }
  }

  return (
    <Route
      key={`${route.key}`}
      path={`${route.path}`}
      render={renderComponent}
      exact
    />
  );
};
