import { useHeatsService } from '@app/api/heats';
import { SaveDraftRequestPayload } from '@app/api/heats/HeatsResponse';
import { SetStateAction, Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { HooksStatus } from "./types"
import { SweetAlertComponentProps } from '@app/pages/Heats/HeatCalculation/NewHeat';
import { JsonReturnModel } from '@app/api';

interface useSaveHeatStateDTO {
  status: HooksStatus;
  lastSavedDraftId: number | string | null;
}

interface UseSaveOrUpdateHeatDraftProps {
  setDetailedSwalFire?: Dispatch<SetStateAction<SweetAlertComponentProps>>;
  onConfirmError?: () => void;
}

const useSaveOrUpdateHeatDraft = (props: UseSaveOrUpdateHeatDraftProps): [
  useSaveHeatStateDTO,
  (draftData: SaveDraftRequestPayload) => Promise<void>,
  (draftData: SaveDraftRequestPayload, draftId: string | number) => Promise<void>,
  (draftId: number | string | null) => void
] => {
  const initialState: useSaveHeatStateDTO = {
    status: "idle",
    lastSavedDraftId: null,
  }

  const { setDetailedSwalFire, onConfirmError = () => { return } } = props;

  const [infoObject, setInfoObject] = useState<useSaveHeatStateDTO>(initialState);

  const { postDraft, putDraft } = useHeatsService();

  const { t } = useTranslation();

  const setDraftId = (draftId: number | string | null) => {
    setInfoObject({ ...infoObject, lastSavedDraftId: draftId })
  }

  const saveDraftHeat = async (
    draftData: SaveDraftRequestPayload,
  ) => {
    try {
      setInfoObject({ ...infoObject, status: "loading" });
      const data = await postDraft(draftData);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setInfoObject({ lastSavedDraftId: data.output.details.heatId, status: "succeeded" });
    } catch (err) {

      const { message, details } = err as JsonReturnModel;

      if(details && setDetailedSwalFire) {
        setDetailedSwalFire({
          icon: 'error',
          title:  t('error-message.title'),
          text: message,
          details,
          onConfirm: onConfirmError
        })
      }

      setInfoObject({ ...infoObject, status: "error" });
    }
  };

  const updateDraftHeat = async (
    draftData: SaveDraftRequestPayload,
    heatDraftId: string | number,
  ) => {
    try {
      setInfoObject({ ...infoObject, status: "loading" });
      const data = await putDraft(draftData, heatDraftId);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      setInfoObject({ lastSavedDraftId: data.output.details.heatId, status: "succeeded" });
    } catch (err) {
      const { details, message } = err as JsonReturnModel;

      if(details && setDetailedSwalFire) {
        setDetailedSwalFire({
          icon: 'error',
          title:  t('error-message.title'),
          text: message,
          details,
          onConfirm: onConfirmError
        })
      }
      setInfoObject({ ...infoObject, status: "error" });

    }
  };
  return [infoObject, saveDraftHeat, updateDraftHeat, setDraftId];
}

export default useSaveOrUpdateHeatDraft;
