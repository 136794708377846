import React, { createContext, useReducer } from 'react';

import { MeasurementSystemState, measurementSystemInitialState } from './measurementSystemState';
import { MEASUREMENT_SYSTEM_INITIAL_DISPATCH, MeasurementSystemDispatch, measurementSystemReducer } from './measurementSystemReducer';
import { MeasurementSystemActions } from './measurementSystemActions';

import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';

interface Props {
  children: React.ReactNode;
}

const MeasurementSystemStateContext = createContext<MeasurementSystemState>(measurementSystemInitialState);
const MeasurementSystemDispatchContext = createContext<MeasurementSystemDispatch>(MEASUREMENT_SYSTEM_INITIAL_DISPATCH);

const MeasurementSystemProvider = ({ children }: Props) => {

  const [measurementSystemState, dispatch] = useReducer(measurementSystemReducer, measurementSystemInitialState);

  return (
    <MeasurementSystemStateContext.Provider value={measurementSystemState}>
      <MeasurementSystemDispatchContext.Provider value={dispatch}>
        {children}
      </MeasurementSystemDispatchContext.Provider>
    </MeasurementSystemStateContext.Provider>
  );
}

function useMeasurementSystemContext() {
  const state = React.useContext(MeasurementSystemStateContext);
  const dispatch = React.useContext(MeasurementSystemDispatchContext);
  
  const updateSelectedMeasurementSystem = (measurementSystem: SystemOfMeasurementOptions) => {
    try {
      dispatch({ type: MeasurementSystemActions.UPDATE_MEASUREMENT_SYSTEM_ACTION, payload: measurementSystem });
    } catch (error: unknown | undefined) {
      dispatch({ type: MeasurementSystemActions.UPDATE_MEASUREMENT_SYSTEM_ACTION, payload: measurementSystemInitialState.selectedMeasurementSystem });
      throw new Error(error as string | undefined);
    }
  }

  return { state, updateSelectedMeasurementSystem }
}

export { MeasurementSystemProvider, useMeasurementSystemContext };
