import React, { createContext, useReducer } from 'react';

import { LanguageState, languageInitialState } from './languageState';
import { LANGUAGE_INITIAL_DISPATCH, LanguageDispatch, LanguageReducer } from './languageReducer';
import { LanguageActions } from './languageActions';

import { useUsersService } from '@app/api/users';
import { FALLBACK_LANGUAGE } from '@app/utils/constants';

interface Props {
  children: React.ReactNode;
}

export const STORAGE_LANGUAGE_KEY = 'rhim.lsm.language';

const plantStateContext = createContext<LanguageState>(languageInitialState);
const plantDispatchContext = createContext<LanguageDispatch>(LANGUAGE_INITIAL_DISPATCH);

const LanguageProvider = ({ children }: Props) => {

  const [languageState, dispatch] = useReducer(LanguageReducer, languageInitialState);

  return (
    <plantStateContext.Provider value={languageState}>
      <plantDispatchContext.Provider value={dispatch}>
        {children}
      </plantDispatchContext.Provider>
    </plantStateContext.Provider>
  );
}

function useLanguageContext() {
  const state = React.useContext(plantStateContext);
  const dispatch = React.useContext(plantDispatchContext);
  const { setLanguage } = useUsersService();

  const updateLanguage = async (selectedLanguage: "en" | "pt-BR" | "es" | undefined) => {
    try {
      await setLanguage({ languageName: selectedLanguage });
      dispatch({ type: LanguageActions.UPDATE_SYSTEM_LANGUAGE_ACTION, payload: selectedLanguage });
    } catch (error: unknown | undefined) {
      dispatch({ type: LanguageActions.UPDATE_SYSTEM_LANGUAGE_ACTION, payload: languageInitialState.selectedLanguage });
      throw new Error(error as string | undefined);
    } finally {
      localStorage.setItem(STORAGE_LANGUAGE_KEY, selectedLanguage || FALLBACK_LANGUAGE);
    }
  }

  const updateLanguageLocally = (selectedLanguage: "en" | "pt-BR" | "es" | undefined) => {
    dispatch({ type: LanguageActions.UPDATE_SYSTEM_LANGUAGE_ACTION, payload: selectedLanguage });
    localStorage.setItem(STORAGE_LANGUAGE_KEY, selectedLanguage || FALLBACK_LANGUAGE);
  }

  return { state, updateLanguage, updateLanguageLocally }
}

export { LanguageProvider, useLanguageContext };
