import {
  MSAL_AUTHORITY_B2B, MSAL_AUTHORITY_B2C, MSAL_CLIENT_ID_B2B, MSAL_CLIENT_ID_B2C, MSAL_POST_LOGOUT_REDIRECT_URI_B2B, MSAL_POST_LOGOUT_REDIRECT_URI_B2C, MSAL_REDIRECT_URI_B2B, MSAL_REDIRECT_URI_B2C
} from '@app/utils/constants';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const msalConfigurationB2B: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID_B2B,
    authority: MSAL_AUTHORITY_B2B,
    redirectUri: MSAL_REDIRECT_URI_B2B,
    postLogoutRedirectUri: MSAL_POST_LOGOUT_REDIRECT_URI_B2B,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const msalConfigurationB2C: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID_B2C,
    authority: MSAL_AUTHORITY_B2C,
    redirectUri: MSAL_REDIRECT_URI_B2C,
    postLogoutRedirectUri: MSAL_POST_LOGOUT_REDIRECT_URI_B2C,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const pcaB2B = new PublicClientApplication(msalConfigurationB2B);
export const pcaB2C = new PublicClientApplication(msalConfigurationB2C);
