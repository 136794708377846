import React, { createContext, ReactNode, useReducer } from 'react';

import { ConfirmRedirectState, initialConfirmRedirectState } from './confirmRedirectState';
import { PlantDispatch, PlantValidationReducer, CONFIRM_REDIRECT_INITIAL_DISPATCH } from './confirmRedirectReducer';
import { ConfirmRedirectActions } from './confirmRedirectActions';

interface Props {
  children: ReactNode | JSX.Element;
}

const plantStateContext = createContext<ConfirmRedirectState>(initialConfirmRedirectState);
const plantDispatchContext = createContext<PlantDispatch>(CONFIRM_REDIRECT_INITIAL_DISPATCH);

const ConfirmRedirectProvider = ({ children }: Props) => {
  const [plantState, dispatch] = useReducer(PlantValidationReducer, initialConfirmRedirectState);

  return (
    <plantStateContext.Provider value={plantState}>
      <plantDispatchContext.Provider value={dispatch}>
        {children}
      </plantDispatchContext.Provider>
    </plantStateContext.Provider>
  );
}

function useConfirmRedirectContext() {
  const state = React.useContext(plantStateContext);
  const dispatch = React.useContext(plantDispatchContext);

  const updateConfirmRedirectState = (newState: boolean) => {
      dispatch({ type: ConfirmRedirectActions.UPDATE_CONFIRM_REDIRECT_STATE, payload: newState });
  }

  const updateShouldDisplayPromptState = (newState: boolean) => {
    dispatch({ type: ConfirmRedirectActions.UPDATE_SHOULD_DISPLAY_PROMPT_STATE, payload: newState });
  }

  return { state, updateConfirmRedirectState, updateShouldDisplayPromptState }
}

export { ConfirmRedirectProvider, useConfirmRedirectContext };