import { CarryoverSlagReferenceGetResponse } from '@app/api/carryoverSlagReferences';
import { MeGetResponse } from '@app/api/me';
import TableNoResults from '@app/components/shared/TableNoResults';
import CarryoverSlagUnitOfMeasurement, { UNITS_OF_MEASUREMENT } from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { handleSendEvent, text2Subscript } from '@app/utils';
import { APP_NA2O_K2O_ENABLED, PAGINATION, PERMISSIONS } from '@app/utils/constants';
import { Box, IconButtonProps, Paper, SelectProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SLAG_TYPE, getReferenceValueLabel } from '../carryoverSlagsUtils';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import useScrollTop from '@app/hooks/useScrollTop';
import { EditButton, DeleteButton } from '@app/components/shared/CustomIconButton';

interface CarryoverSlagsTableProps {
  data: CarryoverSlagReferenceGetResponse[];
  userInfo: MeGetResponse | undefined;
  setDeleteProduct: (item: CarryoverSlagReferenceGetResponse) => void;
  setEditCarryoverSlag: (id: number) => void;
}

const CarryoverSlagsTable = (props: CarryoverSlagsTableProps): React.ReactElement => {

  const { data, userInfo, setDeleteProduct, setEditCarryoverSlag } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE_EXTENDED);

  const { t } = useTranslation();
  const { hasAccess } = useAuthenticationContext();

  useEffect(() => {
    setPage(0);
  }, [data])

  const onClickDelete = (item: CarryoverSlagReferenceGetResponse) => {
    handleSendEvent({
      category: 'BasicSettings/CarryoverSlag',
      action: 'User Opened Delete CarryoverSlag Modal.',
    });

    setDeleteProduct(item);
  };

  const renderHeaders = () => {
    return (
      <>
        <TableRow>
          {userInfo?.selectedPlant?.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory && (
            <TableCell
              style={{ zIndex: 4 }}
              className='carryoverslag-cell'
              width="150"
              rowSpan={2}
            >
              {t(SLAG_TYPE.find(SlagType => SlagType.value === userInfo.selectedPlant.carryoverSlagInferenceType)?.nameKey || '')}
            </TableCell>
          )}
          {userInfo?.selectedPlant?.carryoverSlagInferenceType !== CarryoverSlagInferenceType.SteelGradeCategory && (
            <TableCell
              width="150"
            >
              {userInfo?.selectedPlant?.carryoverSlagParameterValueName}
            </TableCell>
          )}
          <TableCell style={{ borderBottom: 'none' }} align="center" colSpan={9} width="150">
            {t('carryover-slag.table.head.chemical-composition')}
          </TableCell>
          <TableCell align="left" width="150" style={{ borderBottom: 'none' }}></TableCell>
          {APP_NA2O_K2O_ENABLED && (
            <>
              <TableCell align="left" width="150" style={{ borderBottom: 'none' }}></TableCell>
              <TableCell align="left" width="150" style={{ borderBottom: 'none' }}></TableCell>
            </>
          )}
        </TableRow>

        <TableRow style={{ position: 'sticky', zIndex: 3 }}>
          {userInfo?.selectedPlant?.carryoverSlagInferenceType !== CarryoverSlagInferenceType.SteelGradeCategory && (
            <TableCell width="150" style={{ top: '56px' }}>
              {userInfo?.selectedPlant &&
                t(UNITS_OF_MEASUREMENT[userInfo.selectedPlant?.carryoverSlagUnitOfMeasurement ?? CarryoverSlagUnitOfMeasurement.Points])}
            </TableCell>
          )}
          <TableCell align="center" style={{ top: '56px' }} width="150">
            MgO
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            CaO
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            {text2Subscript('Al2O3')}
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            {text2Subscript('SiO2')}
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            FeO
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            MnO
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            {text2Subscript('Cr2O3')}
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            {text2Subscript('TiO2')}
          </TableCell>
          <TableCell align="center" style={{ top: '56px' }} width="150">
            {text2Subscript('CaF2')}
          </TableCell>
          {APP_NA2O_K2O_ENABLED && (
            <>
              <TableCell align="center" style={{ top: '56px' }} width="150">
                {text2Subscript('Na2O')}
              </TableCell>
              <TableCell align="center" style={{ top: '56px' }} width="150">
                {text2Subscript('K2O')}
              </TableCell>
            </>
          )}
          <TableCell style={{ top: '56px' }}></TableCell>
        </TableRow>
      </>
    );
  };

  const renderLines = () => {

    const start = rowsPerPage * page;
    const end = start + rowsPerPage;

    return (
      <>
        {data.slice(start, end).map((row: CarryoverSlagReferenceGetResponse) => (
          <TableRow key={row.id.toString()} data-testid={`carryoverslag-list-item-${row.id}`}>
            <TableCell>
              { userInfo?.selectedPlant?.carryoverSlagInferenceType !== CarryoverSlagInferenceType.SteelGradeCategory
                  ? getReferenceValueLabel(
                    userInfo?.selectedPlant?.carryoverSlagInferenceType === CarryoverSlagInferenceType.Range,
                    row,
                  )
                  : row?.steelGradeCategoryName
              }
            </TableCell>
            <TableCell align="center">
              {Number(row.mgO).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.caO).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.al2O3).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.siO2).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.feO).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.mnO).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.cr2O3).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.tiO2).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Number(row.caF2).toFixed(2)}
            </TableCell>
            {APP_NA2O_K2O_ENABLED && (
              <>
                <TableCell align="center">
                  {Number(row.na2O ?? '').toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {Number(row.k2O ?? '').toFixed(2)}
                </TableCell>
              </>
            )}
            <TableCell>
              <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px'}}>
                {
                  hasAccess([PERMISSIONS.CARRYOVER_SLAG_EDIT]) &&
                  <EditButton
                    data-testid={`carryoverslag-edit-button-${row.id}`}
                    onClick={() => {
                      handleSendEvent({
                        category: 'BasicSettings/CarryoverSlag',
                        action: 'User Clicked to Edit a CarryoverSlag.',
                      });
                      setEditCarryoverSlag(row.id);
                    }}
                  />
                }
                {
                  hasAccess([PERMISSIONS.CARRYOVER_SLAG_DELETE]) &&
                  <DeleteButton
                    data-testid={`carryoverslag-delete-button-${row.id}`}
                    onClick={() => onClickDelete(row)}
                  />
                }
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const { elementContainerRef: tableConainerRef, scrollTop } = useScrollTop<HTMLDivElement>();

  return (
    <>
      <Paper style={{ maxHeight: '96%', overflow: 'auto' }}>
        <TableContainer style={{ maxHeight: 'calc(100vh - 244px)' }} innerRef={tableConainerRef}>
          <Table size="medium" stickyHeader>
            <TableHead>
              {renderHeaders()}
            </TableHead>

            <TableBody data-testid="carryoverslags-list-table-body">
                {data.length ? (
                  renderLines()
                ) : (
                  <TableRow data-testid="empty-carryoverslags-list-message">
                    <TableCell colSpan={13}>
                      <TableNoResults firstText='carryover-slags.empty-table-first-text' secondText='carryover-slags.empty-table-second-text' />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          data-testid="carryoverslags.list.table-pagination"
          backIconButtonProps={{ ['data-testid']: 'carryoverslags.list.table-pagination.back-button' } as Partial<IconButtonProps<"button", {}>>}
          nextIconButtonProps={{ ['data-testid']: 'carryoverslags.list.table-pagination.next-button' } as Partial<IconButtonProps<"button", {}>>}
          SelectProps={{ ['data-testid']: 'carryoverslags.list.table-pagination.number-of-items-per-page-selector' } as Partial<SelectProps>}
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            scrollTop();
          }}
          onRowsPerPageChange={e => {
            setPage(0);
            setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE_EXTENDED));
          }}
          labelRowsPerPage={t('rows-per-page')}
          backIconButtonText={t('page-previous')}
          nextIconButtonText={t('page-next')}
          style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.3)'}}
        />
      </Paper>
    </>
  );
};

export default CarryoverSlagsTable;
