import { PagedResult } from '@app/api';
import { UsersGetResponse, useUsersService } from '@app/api/users';
import { useState } from 'react';
import { InfoObject } from "./types"
import { PAGINATION } from '@app/utils/constants';

const infoObjectInitialState: InfoObject<PagedResult<UsersGetResponse>> = {
  status: 'idle',
  data: undefined,
}

const useLoadUsers = (): [
  InfoObject<PagedResult<UsersGetResponse>>,
  (
    filter?: string,
    plants?: string[],
    status?: string[],
    roles?: string[],
    customers?: number[],
    size?: number,
    page?: number,
    sortingOrder?: string
  ) => Promise<void>,
] => {

  const { getUsers } = useUsersService();

  const [infoObject, setInfoObject] = useState<InfoObject<PagedResult<UsersGetResponse>>>(infoObjectInitialState);

  const loadUsers = async (
    filter?: string,
    plants?: string[],
    status?: string[],
    roles?: string[],
    customers?: number[],
    size: number = PAGINATION.ROWS_PER_PAGE,
    page?: number,
    sortingOrder?: string
  ) => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getUsers(filter, plants, status, roles, customers, size, page, sortingOrder);
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {
      
      setInfoObject({data: undefined, status: 'error'});
    }
  };

  return [infoObject, loadUsers];
}

export default useLoadUsers;