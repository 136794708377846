import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'
import { useTranslation } from 'react-i18next';
import { createStyles, CssBaseline, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { FC, ReactNode, useEffect, useReducer } from 'react';
import Swal from 'sweetalert2';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH, MAIN_CONTENT_PADDING } from '../utils/constants';
import Header from './Header';
import Navigation from './Navigation';
import Loading from './shared/Loading';
import { useLanguageContext } from '@app/store/languageContext/languageContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: `0 ${MAIN_CONTENT_PADDING}px ${MAIN_CONTENT_PADDING}px`,
      minHeight: '100vh',
      maxHeight: '100vh',
      background: theme.palette.common.white,
      marginLeft: COLLAPSED_DRAWER_WIDTH,
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: DRAWER_WIDTH,
    },
  }),
);

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
  const classes = useStyles();
  const { state: { selectedLanguage } } = useLanguageContext();
  const { t } = useTranslation();
  const {
    loadPlantValidations,
    state: {
      error,
      errorMessage,
      completedFirstLoad,
      loading
    }
  } = usePlantContext();
  const [open, toggle] = useReducer(open => !open, true);

  const {
    initialLoad,
    loadStatus,
    state: { initialLoadComplete, userInfoObject },
  } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  useEffect(() => {
    if(error)
      Swal.fire({
        icon: 'error',
        title: t('error-message.title'),
        text: errorMessage,
        html: '',
      });
  }, [error, errorMessage, t])

  const plantId = userInfo?.selectedPlant?.id;
  useEffect(() => {
    if (!loading && !completedFirstLoad && plantId && initialLoadComplete){
      loadPlantValidations(plantId);
    }
  }, [plantId, loading, loadPlantValidations, completedFirstLoad, t, initialLoadComplete]);

  useEffect(() => {
    if(!initialLoadComplete && loadStatus === 'idle')
      initialLoad();
  }, [initialLoadComplete, initialLoad, loadStatus]);

  return (
    <>
      <Loading dataTestId='layout-circular-progress' promiseInProgress={!initialLoadComplete || loading} />

      <div>
        <Navigation open={open} handleChangeMenuVisibility={toggle} />
        <div className={classes.wrapper}>
          <CssBaseline />
          <Header open={open} />

          <main className={`${clsx(classes.content, { [classes.contentShift]: open })} notranslate`} translate="no" lang={selectedLanguage}>
            <div className={classes.toolbar} />

            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
