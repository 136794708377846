import { SystemOfMeasurementOptions } from "@app/api/me/MeGetResponse"
import { InputAdornment } from "@material-ui/core"

interface FormInputEndAdornmentProps {
    measurementSystem: SystemOfMeasurementOptions;
    imperialUnitOfMeasurement: string;
    metricUnitOfMeasurement: string;
    disabled?: boolean;
}

const FormInputEndAdornment = ({ 
    measurementSystem, 
    imperialUnitOfMeasurement, 
    metricUnitOfMeasurement,
    disabled = false
}: FormInputEndAdornmentProps) => {
    return (
        <InputAdornment position="end" disableTypography={disabled} style={{ color: 'inherit' }}>
            {
                measurementSystem === SystemOfMeasurementOptions.Imperial 
                ? imperialUnitOfMeasurement
                : metricUnitOfMeasurement
            }
        </InputAdornment>
    );
}

export default FormInputEndAdornment;