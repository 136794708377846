import { Box, Radio, RadioProps, useTheme } from "@material-ui/core"
import withStyles, { CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { CSSProperties } from "react";

const FormRadioButton = (props: RadioProps) => {

    const theme = useTheme();
    const iconSize = '20px';

    const iconRootStyles:  CSSProperties | CreateCSSProperties<{}> = {
      transition: '0.25s',
      content: "''",
      width: iconSize,
      height: iconSize,
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.custom.uncheckedRadioButtonPrimaryColor.main}`,
      borderRadius: '50%',
      '.MuiIconButton-label:hover &': {
        border: `2px solid ${theme.palette.custom.uncheckedRadioButtonPrimaryHoverColor.main}`,
        backgroundColor: theme.palette.custom.formBackground.main
      },
      'input:disabled + &': {
        borderColor: theme.palette.custom.uncheckedRadioButtonSecondaryDisabledColor.main,
        backgroundColor: theme.palette.custom.formBackground.main
      }
    }
  
    const StyledUncheckedIcon = withStyles(() => ({
      root: iconRootStyles,
    }))(Box);
  
    const StyledCheckedIcon = withStyles(() => ({
      root: {
        ...iconRootStyles,
        '&:before': {
          position: 'absolute',
          zIndex: 1,
          top: '50%',
          left: '50%',
          width: '10px',
          height: '10px',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%',
          transform: 'translate(-50%, -50%)',
          content: "''",
        },
        '.Mui-focusVisible &': {
          borderColor: theme.palette.custom.outlineColor.main,
          borderWidth: '4px'
        },
        'input:disabled + &': {
          borderColor: theme.palette.custom.uncheckedRadioButtonSecondaryDisabledColor.main,
          backgroundColor: theme.palette.custom.formBackground.main,
          '&:before': {
            backgroundColor: theme.palette.custom.checkedRadioButtonPrimaryDisabledColor.main
          }
        }
      },
    }))(Box);
    
    return(
        <Radio
          size="small" 
          color="primary"
          icon={<StyledUncheckedIcon />}
          checkedIcon={<StyledCheckedIcon />}
          style={{ 
              padding: 0,
          }}
          {...props} 
        />
    )
}

export default FormRadioButton;