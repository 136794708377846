import { ReportProblemIcon } from "@app/assets/icons/reportProblemIcon";
import { Box, BoxProps, useTheme } from "@material-ui/core";
import { ReactNode } from "react";

interface WarningBoxProps extends BoxProps {
  withProblemToReport?: boolean;
  children: ReactNode;
  style?: React.CSSProperties;
}

function WarningBox({withProblemToReport = false, children, style, ...rest}: WarningBoxProps) {
  const theme = useTheme();
  const defaultStyles: React.CSSProperties | undefined =  
    withProblemToReport 
    ? { paddingLeft: '10px', border: `1px solid ${theme.palette.warning.main}`, transform: '0.25s', } 
    : undefined;
  return(
    <Box 
        {...rest}
        display="flex" 
        alignItems="center"
        style={{
          ...defaultStyles,
          ...style,
        }}
        >
        {children}
        {withProblemToReport && (<ReportProblemIcon />)}
      </Box>
  )
}

export default WarningBox;