import { limitText } from '@app/utils/limitText';
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

interface EllipsedTextProps {
  text: string;
  lines: number;
  maxDigits?: number;
}

const EllipsedText = ({ text, lines, maxDigits }: EllipsedTextProps) => {
  const hasMaxDigits = !!maxDigits && !Number.isNaN(maxDigits);
  
  const classes = useStyles(lines)();

  return <span className={classes.text}>{hasMaxDigits ? limitText(text, maxDigits) : text}</span>;
};

const useStyles = (lines: number) =>
  makeStyles(() =>
    createStyles({
      text: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lines,
      },
    }),
  );

export default EllipsedText;
