import { useProcessConstraintsService } from '@app/api/processConstraints';
import MainPaper from '@app/components/shared/MainPaper';
import { APP_TITLE, PERMISSIONS, UOM_CENTIMETERS_SYMBOL, UOM_MILLIMETERS_SYMBOL } from '@app/utils/constants';
import {  makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import AddLadleFurnaceModal from '../modals/AddLadleFurnaceModal';
import TabPanel from '../../../components/shared/TabPanel';
import LadleFurnaceTab from './LadleFurnaceTab';
import LadleTab from './LadleTab';
import Loading from '@app/components/shared/Loading';
import { ROUTES_PATH } from '@app/utils';
import { GetLadleFurnaceResponse } from '@app/api/ladleFurnaces/BaseLadleFurnaceRequest';
import { useLadleFurnacesService } from '@app/api/ladleFurnaces';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext';
import { useAuthenticationContext } from "@app/store/authentication/authenticationContext";

import { EQUIPMENT } from '@app/utils/plantSetupRoutesPathTabs'
import { clearLocationState } from '@app/utils/clearLocationState';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import { ProcessConstraintsDTO } from '@app/api/processConstraints/useProcessConstraintsService';
import useLoadProcessConstraints from '@app/hooks/useLoadProcessConstraints';
import SaveOnExitDialog from '@app/components/shared/SaveOnExitDialog';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import { useConfirmRedirectContext } from '@app/store/confirmRedirectContext/confirmRedirectContext';
import useLoadLadleFurnaces from '@app/hooks/useLoadLadleFurnaces';
import FormButton from '@app/components/shared/FormButton';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { convertMetersIntoOtherUnitOfMeasurement, convertOtherUnitOfMeasurementIntoMeters } from '@app/utils/convertLengthUnits';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { processConstraintsInitialValue } from '@app/api/processConstraints/ProcessConstraintReferenceParameters';
import useLadleTabValidationSchema from '@app/hooks/useLadleTabValidationSchema';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },

  appBar: {
    boxShadow: 'none',
  },

  header: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > :not(:first-child)': {
      marginLeft: 8,
    },
    justifyContent: 'center',
    padding: '24px',
    backgroundColor: theme.palette.background.paper,
  },

  cancelBtn: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#CFCFCF',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
}));

interface LocationState {
  option?: EQUIPMENT,
}

const Equipment = () => {
  const { state: plantState, loadPlantValidations } = usePlantContext();
  const {
    state: {
      userConfirmedRedirect,
      shouldDisplayPrompt
    },
    updateConfirmRedirectState,
    updateShouldDisplayPromptState
  } = useConfirmRedirectContext();

  const classes = useStyles();
  const location = useLocation<LocationState>();

  const { hasAccess, state: authenticationContextData } = useAuthenticationContext();
  const { updateProcessConstraint } = useProcessConstraintsService();
  const { saveLadleFurnace, updateLadleFurnace } = useLadleFurnacesService();
  const [ladleFurnacesInfoObject, loadLadleFurnaces] = useLoadLadleFurnaces();

  const { t } = useTranslation();

  const [value, setValue] = React.useState(location.state?.option === EQUIPMENT.LADLE ? 1 : 0);
  const [openAddLadleFurnaceModal, setOpenAddLadleFurnaceModal] = useState(false);

  const [processConstraintsInfoObject, loadProcessConstraints] = useLoadProcessConstraints();
  // REMOVE UNNECESSARY LOAD PROCESS CONSTRAINTS CALLS AND SEE IF THE TESTS ARE STILL OK

  const { data: processConstraints } = processConstraintsInfoObject;
  const [saveChangesModalIsVisible, setSaveChangesModalIsVisible] = useState(false);

  const [loading, setLoading] = useState(false);


  const measurementSystem = authenticationContextData.userInfoObject?.data?.measurementSystem;

  const isMeasurementSystemMetric = measurementSystem === SystemOfMeasurementOptions.Metric;

  const { schema } = useLadleTabValidationSchema();

  const methods = useForm<ProcessConstraintsDTO>({
    defaultValues: processConstraintsInitialValue,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    formState: {
      isDirty: formIsDirty,
      isValid: formIsValid,
    },
    handleSubmit,
    watch,
    reset
  } = methods;


  const formValues = watch();

  useEffect(() => {
    if(processConstraintsInfoObject.status === 'succeeded') {
      reset({
        ...processConstraints,
        averageLadleLifetime: processConstraints?.averageLadleLifetime,
        measurementSystem: processConstraints?.measurementSystem ?? '' as SystemOfMeasurementOptions,
        initialLiningThickness:
          isMeasurementSystemMetric
            ? convertMetersIntoOtherUnitOfMeasurement(processConstraints?.initialLiningThickness ?? 0, UOM_MILLIMETERS_SYMBOL)
            : processConstraints?.initialLiningThickness,
        finalLiningThickness:
          isMeasurementSystemMetric
            ? convertMetersIntoOtherUnitOfMeasurement(processConstraints?.finalLiningThickness ?? 0, UOM_MILLIMETERS_SYMBOL)
            : processConstraints?.finalLiningThickness
      })
    }
  }, [processConstraintsInfoObject.status, reset, isMeasurementSystemMetric, processConstraints])

  useEffect(() => {
    if(shouldDisplayPrompt !== formIsDirty) {
      updateShouldDisplayPromptState(formIsDirty);
    }
  }, [
    formIsDirty,
    shouldDisplayPrompt,
    updateShouldDisplayPromptState
  ])

  const plantId = authenticationContextData.userInfoObject?.data?.selectedPlant?.id

  const confirmRedirect = () => {
    if(!userConfirmedRedirect) updateConfirmRedirectState(true);
  };

  const parsePayloadToAPI = (data: ProcessConstraintsDTO) => {
    const newProcessConstraints = {...data};
    if(measurementSystem === SystemOfMeasurementOptions.Metric) {
      newProcessConstraints['initialLiningThickness'] = convertOtherUnitOfMeasurementIntoMeters(newProcessConstraints['initialLiningThickness'], UOM_MILLIMETERS_SYMBOL);
      newProcessConstraints['finalLiningThickness'] = convertOtherUnitOfMeasurementIntoMeters(newProcessConstraints['finalLiningThickness'], UOM_MILLIMETERS_SYMBOL);
    }
    return newProcessConstraints;
  };

  const isKeyNumberType = <K extends keyof ProcessConstraintsDTO>(key: K) => {
    const notNumericalKeys = [
      'chemistryAtTappingUnitOfMeasurement',
      'carryoverSlagType',
      'steelChemistryPrimaryFurnaceCode',
      'steelChemistryTappingCode',
      'steelChemistryUnitOfMeasurement',
      'measurementSystem'
    ] as Array<K>;

    return !notNumericalKeys.includes(key);
  };
  const convertDataToNumbers = <K extends keyof ProcessConstraintsDTO>(data: ProcessConstraintsDTO) => {
    const newData = {...data};

    Object.keys(newData).forEach((key) => {
      if(
        isKeyNumberType(key as K)
      ) newData[key as K] = Number(newData[key as K]) as ProcessConstraintsDTO[K]
    });
    return newData;
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if(value === 1 && newValue === 0 && formIsDirty) {
      setSaveChangesModalIsVisible(true);
    } else {
      setValue(newValue);
    }
  };

  const submitLadleChanges = async (newProcessConstraints: ProcessConstraintsDTO) => {
    try {
      setLoading(true);
      const payload = convertDataToNumbers({...processConstraints, ...newProcessConstraints});
      const parsedPayload = parsePayloadToAPI(payload);

      await updateProcessConstraint(parsedPayload);
      await loadPlantValidations(plantId ?? '');

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
      confirmRedirect();
    } finally {
      setLoading(false);
    }
  };

  const postSubmitLadleFurnace = async (request: GetLadleFurnaceResponse) => {
    try {
      setLoading(true);
      const ladleFurnaceData: GetLadleFurnaceResponse = measurementSystem === SystemOfMeasurementOptions.Metric
      ? {
          ...request,
          tapLadleFurnaces: request.tapLadleFurnaces.map(tlf => ({ ...tlf, arcLength: convertOtherUnitOfMeasurementIntoMeters(tlf.arcLength, UOM_CENTIMETERS_SYMBOL) })),
        }
      : request;

      await saveLadleFurnace(ladleFurnaceData);

      await loadPlantValidations(plantId ?? '');
      await loadLadleFurnaces();
      setOpenAddLadleFurnaceModal(false);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
    } finally {
      setLoading(false);
    }
  };

  const putSubmitLadleFurnace = async (request: GetLadleFurnaceResponse, hideModal: () => void) => {
    try {
      setLoading(true);
      const ladleFurnaceData: GetLadleFurnaceResponse = measurementSystem === SystemOfMeasurementOptions.Metric
      ? {
          ...request,
          tapLadleFurnaces: request.tapLadleFurnaces.map(tlf => ({ ...tlf, arcLength: convertOtherUnitOfMeasurementIntoMeters(tlf.arcLength, UOM_CENTIMETERS_SYMBOL) })),
        }
      : request;
      await updateLadleFurnace(request.id, ladleFurnaceData);

      await loadPlantValidations(plantId ?? '');
      await loadLadleFurnaces();
      hideModal();

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.option !== undefined) {
      clearLocationState();
    }
  }, [location.state]);

  const onSubmit: SubmitHandler<ProcessConstraintsDTO> = async (data: ProcessConstraintsDTO) => {
    await submitLadleChanges(data);
    loadProcessConstraints();
  };

  return (
    <>
      <ConfirmDialog
        visible={saveChangesModalIsVisible}
        setVisible={setSaveChangesModalIsVisible}
        title={t("ladle.save-on-exit-modal.title")}
        content={t("ladle.save-on-exit-modal.content")}
        cancelText={t('confirmation-before-leaving')}
        confirmText={t('button.save-changes')}
        cancelAction={() => {
          setValue(0);
          setSaveChangesModalIsVisible(false);
          loadProcessConstraints();
          confirmRedirect();
        }}
        confirmAction={async () => {
          await submitLadleChanges(formValues);
          loadProcessConstraints();
          setValue(0);
          setSaveChangesModalIsVisible(false);
        }}
      />
      <SaveOnExitDialog
        when={formIsDirty}
        shouldBlockNavigation={true}
        onSave={async () => {
          try {
            setLoading(true);
            await submitLadleChanges(formValues);
            loadProcessConstraints();
            return true;
          } catch (e) {
            return false;
          } finally {
            setLoading(false);
          }
        }}
        onCancel={() => {
          loadProcessConstraints();
          confirmRedirect();
        }}
        cancelText={t("confirmation-before-leaving")}
        confirmText={t("button.save-changes")}
        content={t("ladle.save-on-exit-modal.content")}
        title={t("ladle.save-on-exit-modal.title")}
      />
      <Loading
        dataTestId='equipment-screen-circular-progress'
        promiseInProgress={
          processConstraintsInfoObject.status === 'loading' || loading
        }
      />
      <Helmet>
        <title>
          {t('overview.equipment.title')} | {APP_TITLE}
        </title>
      </Helmet>
      {openAddLadleFurnaceModal && (
        <AddLadleFurnaceModal
          isEdit={false}
          onClose={() => {
            setOpenAddLadleFurnaceModal(false);
          }}
          onSubmit={postSubmitLadleFurnace}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
      <MainPaper
        title={t('overview.equipment.title')}
        subheader={t('equipment.subtitle')}
        removePadding
        headerActions={[
          <FormButton
            key="btn-add-ladlefurnace"
            data-testid="btn-add-ladlefurnace"
            variant="primary"
            title={t('button.add-new')}
            style={{ textDecoration: 'none', display: value === 0 ? 'block' : 'none' }}
            type="button"
            onClick={() => setOpenAddLadleFurnaceModal(true)}
            disabled={!hasAccess([PERMISSIONS.LADLE_FURNACE_CREATE])}>
            {t('button.add-new')}
          </FormButton>,
          <FormButton
            key="btn-cancel-ladle"
            data-testid="btn-cancel-ladle"
            variant="secondary"
            title={t('button.cancel')}
            style={{ display: value === 0 ? 'none' : 'block' }}
            type="button"
            onClick={() => {
              loadProcessConstraints();
            }}
            disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT])}>
            {t('button.cancel')}
          </FormButton>,
          <FormButton
            data-testid="btn-save-ladle"
            type="submit"
            key="btn-save-ladle"
            variant="primary"
            title={t('button.save')}
            style={{ display: value === 0 ? 'none' : 'block' }}
            disabled={!hasAccess([PERMISSIONS.PROCESS_CONSTRAINTS_EDIT]) || !formIsValid}>
            {t('button.save')}
          </FormButton>,
        ]}
      >
        {
          (
            !plantState.data.general
          ) && (
            <WarningMessageComponent
              testId="equipment.plant-validation.warning"
              withPadding
              message={t('overview.empty-message')}
              link={{
                label: t('overview.view'),
                url: ROUTES_PATH.PLANT_OVERVIEW,
              }}
            />
          )
        }
        <Tabs
          data-testid="equipment.tabs.menu"
          value={value}
          onChange={handleChange}
          className={classes.root}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={t('ladlefurnace')}
            data-testid="equipment.tabs.menu-item.ladle-furnace"
          />
          <Tab
            label={t('ladle')}
            data-testid="equipment.tabs.menu-item.ladle"
          />
        </Tabs>
        <TabPanel value={value} index={0} style={{ height: '100%' }} childrenBoxSize="100%">
          <LadleFurnaceTab
            onSubmit={putSubmitLadleFurnace}
            ladleFurnacesInfoObject={ladleFurnacesInfoObject}
            loadLadleFurnaces={loadLadleFurnaces}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormProvider {...methods}>
            <LadleTab
              processConstraintsInfoObject={processConstraintsInfoObject}
              loadProcessConstraints={loadProcessConstraints}
            />
          </FormProvider>
        </TabPanel>
      </MainPaper>
      </form>
    </>
  );
};

export default Equipment;
