import FormAutocompleteWithTags from "@app/components/shared/FormAutocompleteWithTags";
import { PlantManagementFormInputs } from "@app/pages/PlantManagement/views/PlantManagementContent";
import { UserManagementFormInputs } from "@app/pages/UserManagement/views/UserManagementContent";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

type T = Pick<UserManagementFormInputs, "plants" | "roles" | "status" | "customers">
  & Pick<PlantManagementFormInputs, "customers" | "status">;


const useRenderFormFilterWithTags = () => {
  const { control, setValue }: UseFormReturn<T> = useFormContext();

  const { t } = useTranslation();

  const renderFilterWithTags = (
    filterName: keyof T,
    options: string[],
    translateOptions?: boolean
  ) => {
    const handleChangeAutocompleteValue = (value: string[]) => {
      setValue(filterName, value || []);
    };

    return (
      <Controller
        control={control}
        name={filterName}
        render={({ field }) => (
          <FormAutocompleteWithTags
            options={options}
            {...field}
            label={t(`filter.${filterName as string}`)}
            getOptionLabel={(option) => (translateOptions ? t(option) : option)}
            translateOptions={translateOptions}
            onChange={(_, value) => {
              handleChangeAutocompleteValue(value);
            }}
          />
        )}
      />
    );
  };

  return { renderFilterWithTags };
};

export default useRenderFormFilterWithTags;
