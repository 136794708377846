import { PlantsGetResponse } from '../plants';

export enum SystemOfMeasurementOptions {
  Metric = 'Metric',
  Imperial = 'Imperial'
}

export interface MeGetResponse {
  id?: number;
  languageName: "en" | "pt-BR" | "es";
  selectedPlant: PlantsGetResponse;
  plants: PlantsGetResponse[];
  userProfileName: string;
  measurementSystem: SystemOfMeasurementOptions;
}
