import { createTheme, Theme, ThemeOptions } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    custom: {
      grey1: PaletteColor;
      grey2: PaletteColor;
      grey3: PaletteColor;
      primaryHoverColor: PaletteColor;
      primaryHoverBackgroundColor: PaletteColor;
      formBackground: PaletteColor;
      warningBackground: PaletteColor;
      outlineColor: PaletteColor;
      stepperNavigationHoverColor: PaletteColor;
      stepperNavigationActiveColor: PaletteColor;
      disabledColor: PaletteColor;
      disabledBackgroundColor: PaletteColor;
      uncheckedRadioButtonPrimaryColor: PaletteColor;
      uncheckedRadioButtonPrimaryHoverColor: PaletteColor;
      uncheckedRadioButtonSecondaryDisabledColor: PaletteColor;
      checkedRadioButtonPrimaryDisabledColor: PaletteColor;
      userStatus: {
        invitationSent: PaletteColor;
        active: PaletteColor;
        blocked: PaletteColor;
        blockedByPlant: PaletteColor;
      };
      plantStatus: {
        trial: PaletteColor;
      };
    };
  }
  interface PaletteOptions {
    custom: {
      grey1: PaletteColorOptions;
      grey2: PaletteColorOptions;
      grey3: PaletteColorOptions;
      primaryHoverColor: PaletteColorOptions;
      primaryHoverBackgroundColor: PaletteColorOptions;
      formBackground: PaletteColorOptions;
      warningBackground: PaletteColorOptions;
      outlineColor: PaletteColorOptions;
      stepperNavigationHoverColor: PaletteColorOptions;
      stepperNavigationActiveColor: PaletteColorOptions;
      disabledColor: PaletteColorOptions;
      disabledBackgroundColor: PaletteColorOptions;
      uncheckedRadioButtonPrimaryColor: PaletteColorOptions;
      uncheckedRadioButtonPrimaryHoverColor: PaletteColorOptions;
      uncheckedRadioButtonSecondaryDisabledColor: PaletteColorOptions;
      checkedRadioButtonPrimaryDisabledColor: PaletteColorOptions;
      userStatus: {
        invitationSent: PaletteColorOptions;
        active: PaletteColorOptions;
        blocked: PaletteColorOptions;
        blockedByPlant: PaletteColorOptions;
      };
      plantStatus: {
        trial: PaletteColorOptions;
      };
    };
  }
}

const defaultTypographyOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
};

const defaultWhite = '#FFF';
const primaryColor = '#003262';
const primaryOutlinedDisabledColor = '#778894';
const primaryHoverColor = '#001540';
const primaryHoverBackgroundColor = '#B5C9E5';
const primaryOutlinedFocusVisibleBackgroundColor = '#CCDAEE';
const primaryFocusVisibleBackgroundColor = '#719BCA';
const primaryOutlinedFocusVisibleBorderColor = '#286BA0';
const secondaryColor = '#DCDBDC';
const textPrimaryColor = '#262824';
const labelFontColor = '#142E3E';
const tableHeadColor = '#D8DDE1';
const tableBorderColor = '#506676';
const buttonDefaultColor = '#ECEEF0';
const tabFontColor = '#9EAAB3';
const leftMenuHoverColor = '#B9B9B9';
const secondaryDisabledColor = '#B1BBC2';
const primaryDisabledColor = '#97B4DA';

// define dark theme colors
export const darkTheme: Theme = createTheme({
  ...defaultTypographyOptions,
  overrides: {
    MuiIconButton: {
      root: {
        '&.Mui-disabled': {
          color: primaryOutlinedDisabledColor,
        },
      },
    },
    MuiChip: {
      sizeSmall: {
        margin: 0,
        maxWidth: 'calc(100% - 2px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    MuiButton: {
      root: {
        padding: '10px 20.5px',
        textAlign: 'center',
        fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '14px',
        textTransform: 'uppercase',
        height: '41px',
        '&.Mui-disabled.MuiButton-outlinedPrimary': {
          color: primaryOutlinedDisabledColor,
        },
      },
      text: {
        textDecoration: 'underline !important',
        '&:hover': {
          color: primaryHoverColor,
          backgroundColor: 'transparent !important',
        },
        '&:not(disabled).Mui-focusVisible': {
          border: `4px solid ${primaryOutlinedFocusVisibleBorderColor}`,
          color: primaryColor,
        },
        '&:active:not(:focus-visible)': {
          border: 'none',
          backgroundColor: 'transparent',
          filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25)) brightness(100%)',
        },
        '&:disabled': {
          backgroundColor: defaultWhite,
          color: primaryOutlinedDisabledColor,
          border: `2px solid ${primaryOutlinedDisabledColor}`,
        },
      },
      sizeSmall: {
        height: '32px',
      },
      outlined: {
        borderWidth: '2px',
        '&.Mui-disabled.MuiButton-outlinedPrimary': {
          border: `2px solid ${primaryOutlinedDisabledColor}`,
        },
      },
      containedPrimary: {
        color: defaultWhite,
        backgroundColor: primaryColor,
        '&:hover': {
          color: defaultWhite,
          backgroundColor: primaryHoverColor,
        },
        '&:not(disabled).Mui-focusVisible': {
          border: `4px solid ${primaryOutlinedFocusVisibleBorderColor}`,
          backgroundColor: primaryFocusVisibleBackgroundColor,
          color: defaultWhite,
        },
        '&:active:not(:focus-visible)': {
          backgroundColor: primaryHoverColor,
          boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25)',
        },
        '&:disabled': {
          border: 'none',
          backgroundColor: primaryOutlinedDisabledColor,
          color: defaultWhite,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${primaryColor}`,
        color: primaryColor,
        '&:hover': {
          border: `2px solid ${primaryHoverColor}`,
          color: primaryHoverColor,
          backgroundColor: primaryHoverBackgroundColor,
        },
        '&:not(disabled).Mui-focusVisible': {
          border: `4px solid ${primaryOutlinedFocusVisibleBorderColor}`,
          backgroundColor: primaryOutlinedFocusVisibleBackgroundColor,
          color: primaryColor,
        },
        '&:active:not(:focus-visible)': {
          backgroundColor: defaultWhite,
          boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25)',
        },
        '&:disabled': {
          backgroundColor: defaultWhite,
          color: primaryOutlinedDisabledColor,
          border: `2px solid ${primaryOutlinedDisabledColor}`,
        },
      },
    },
    MuiTypography: {
      subtitle2: {
        color: labelFontColor,
        fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: 'normal',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0px',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      multiline: {
        padding: '8px',
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        '&.Mui-focusVisible span': {
          border: `2px solid ${primaryOutlinedFocusVisibleBorderColor}`,
          borderRadius: '4px',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: '0 !important',
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        minHeight: 'initial',
        '& .MuiIconButton-root': {
          padding: '0 12px',
        },
        '&.Mui-expanded': {
          minHeight: 'initial',
        },
      },
      content: {
        margin: 0,
        height: 'fit-content',
        padding: 0,
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiPopover: {
      paper: {
        boxSizing: 'content-box',
        border: '2px solid #506676',
        transform: 'translateY(0px) translateX(-2px) !important',
        '& .MuiListItem-root': {
          borderTop: '1px solid #506676',
          borderBottom: '1px solid #506676',
        },
        '& .MuiListItem-root:first-child': {
          borderTop: 'none',
        },
        '& .MuiListItem-root:last-child': {
          borderBottom: 'none',
        },
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: primaryColor,
          borderBottom: '1px solid #506676',
          borderTop: '1px solid #506676',
          color: defaultWhite,
        },
        '&.Mui-selected:first-child, &.Mui-selected:hover:first-child': {
          borderTop: 'none',
        },
        '&.Mui-selected:last-child, &.Mui-selected:hover:last-child': {
          borderBottom: 'none',
        },
      },
    },
    MuiSelect: {
      select: {
        paddingRight: '32px !important',
      },
      icon: {
        marginTop: '-3.5px',
      },
      selectMenu: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        height: 'calc(100% - 12px)',
        '&[aria-expanded="true"]': {
          backgroundColor: buttonDefaultColor,
        },
        padding: '6px 8px',
      },
    },
    MuiInputLabel: {
      root: {
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      formControl: {
        top: 'initial',
        left: 'initial',
        position: 'initial',
      },
      outlined: {
        transform: 'none !important',
      },
      shrink: {
        transform: 'none',
        '&:not(.checkboxLabel)': {
          paddingBottom: '8px',
        },
        transformOrigin: 'top left',
        color: labelFontColor,
        fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14px',
      },
    },
    MuiInputBase: {
      root: {
        transition: '0.25s',
        backgroundColor: defaultWhite,
        boxSizing: 'border-box',
        border: '2px solid #506676',
        '&:hover': {
          backgroundColor: buttonDefaultColor,
        },
        '&:focus-within': {
          backgroundColor: buttonDefaultColor,
        },
        '&.Mui-disabled': {
          backgroundColor: buttonDefaultColor,
          border: `2px solid ${secondaryDisabledColor}`,
          color: primaryOutlinedDisabledColor,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-disabled': {
          color: primaryOutlinedDisabledColor,
        },
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
      underline: {
        '&:before': {
          display: 'none',
          borderBottom: 'none',
        },
        '&:after': {
          transition: 'none',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: 'none',
        },
      },
      input: {
        padding: '0px',
        fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        height: '100%',
      },
      root: {
        padding: 0,
        '&:not(.formSelectWrapper)': {
          padding: '0px 8px',
        },
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          width: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
        },
        '& svg.initial-positioning': {
          position: 'initial',
        },
        '& button': {
          width: '32px',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
        },
        '& button svg': {
          position: 'initial',
          top: 'initial',
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: '14px',
        lineHeight: '24px',
      },
      iconContainer: {
        '& .MuiStepIcon-completed': {
          border: `2px solid ${primaryColor}`,
          borderRadius: '50%',
          fill: defaultWhite,
          background: primaryColor,
          '-webkit-labelFontColor-smoothing': 'antialiased',
          '-moz-osx-labelFontColor-smoothing': 'grayscale',
        },
      },
    },
    MuiStepConnector: {
      active: {
        '& $lineHorizontal': {
          borderTopStyle: 'solid',
        },
      },
      completed: {
        '& $lineHorizontal': {
          borderTopStyle: 'solid',
        },
      },
      lineHorizontal: {
        borderTopStyle: 'dashed',
        borderTopWidth: '2px',
      },
      line: {
        '& $disabled': {
          borderColor: '#949494',
        },
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: defaultWhite,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: 0,
      },
      root: {
        boxShadow: 'none',
      },
    },
    MuiSlider: {
      root: {
        marginTop: '38px',
      },
      mark: {
        height: 5,
        width: '2px',
      },
      markLabel: {
        fontSize: '12px',
      },
      track: {
        height: 5,
        borderRadius: 5,
      },
      rail: {
        height: 5,
        borderRadius: 5,
      },
      thumb: {
        transform: 'translateY(1.5px)',
      },
    },
    MuiCardHeader: { root: { padding: '0px 0px 16px 0px' } },
    MuiCardContent: { root: { padding: '8px 32px 32px 32px', flex: 'initial !important' } },
    MuiCardActions: {
      root: {
        padding: '16px 8px 16px 8px',
        '& a': { textDecoration: 'none' },
      },
    },
    MuiDialogActions: { root: { padding: '24px 24px 20px' } },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
      root: {
        borderBottom: `2px solid ${tableBorderColor}`,
      },
    },
    MuiTab: {
      root: {
        height: '65.739px',
        color: tabFontColor,
        textAlign: 'center',
        fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '18px',
        lineSize: '18px',
        letterSpacing: '0.72px',
        backgroundColor: buttonDefaultColor,
        '&.Mui-selected': {
          color: labelFontColor,
          backgroundColor: tableHeadColor,
        },
        '& + &': {
          borderLeft: `2px solid ${tableBorderColor}`,
        },
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: buttonDefaultColor,
        borderBottom: `2px solid ${tableBorderColor}`,
        borderRadius: 0,
      },
      select: {
        height: '32px',
        boxSizing: 'border-box',
      },
      selectIcon: {
        height: '100%',
        width: '32px',
        padding: '5px',
      },
    },
    MuiTableHead: {
      root: {
        borderCollapse: 'collapse',
        borderTop: `2px solid ${tableBorderColor}`,
        borderBottom: `2px solid ${tableBorderColor}`,
        backgroundColor: tableHeadColor,
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: `2px solid ${tableBorderColor}`,
        '&:first-child .MuiTableCell-head': {
          borderTop: `2px solid ${tableBorderColor}`,
        },
        '&:last-child .MuiTableCell-head, &:first-child .carryoverslag-cell': {
          borderBottom: `2px solid ${tableBorderColor}`,
        },
      },
    },
    MuiTableCell: {
      root: {
        border: 0,
        padding: '16px',
        '&.table-actions :not(:first-child)': { marginLeft: 8 },
        '& .MuiInputBase-input': { paddingTop: 0, paddingBottom: 0 },
        whiteSpace: 'nowrap',
      },
      body: {
        borderBottom: `2px solid ${tableBorderColor}`,
        '&:not(:only-of-type):first-child': {
          backgroundColor: '#F5F6F7',
          minWidth: 'fit-content',
          maxWidth: '300px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          position: 'sticky',
          left: 0,
          zIndex: 2,
        },
      },
      stickyHeader: {
        backgroundColor: tableHeadColor,
      },
      head: {
        padding: '24px 16px',
        color: labelFontColor,
        fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.64px',
        backgroundColor: tableHeadColor,
        '&:first-child': {
          position: 'sticky',
          left: 0,
          zIndex: 3,
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: '57px',
    },
  },
  palette: {
    type: 'light',
    warning: {
      main: '#F8C16E',
    },
    background: { paper: defaultWhite, default: defaultWhite },
    common: {
      black: textPrimaryColor,
      white: defaultWhite,
    },
    text: {
      primary: textPrimaryColor,
      secondary: '#303030',
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    grey: {
      50: '#ECECEE',
      100: '#C5C6CB',
      200: '#9EA1A9',
      300: '#7D818C',
      400: '#5C616F',
      500: '#3C4252',
      600: '#353A48',
      700: '#2D323E',
      800: '#262933',
      900: '#1E2129',
      A100: '#C5C6CB',
      A200: '#9EA1A9',
      A400: '#5C616F',
      A700: '#2D323E',
    },
    custom: {
      grey1: { main: '#898989' },
      grey2: { main: '#828282' },
      grey3: { main: leftMenuHoverColor },
      primaryHoverColor: { main: primaryHoverColor },
      primaryHoverBackgroundColor: { main: primaryHoverBackgroundColor },
      formBackground: { main: buttonDefaultColor },
      warningBackground: { main: '#FCF1E1' },
      outlineColor: { main: primaryOutlinedFocusVisibleBorderColor },
      stepperNavigationHoverColor: { main: '#000E2F' },
      stepperNavigationActiveColor: { main: primaryHoverColor },
      disabledColor: { main: primaryOutlinedDisabledColor },
      disabledBackgroundColor: { main: secondaryDisabledColor },
      uncheckedRadioButtonPrimaryColor: { main: labelFontColor },
      uncheckedRadioButtonPrimaryHoverColor: { main: tableBorderColor },
      uncheckedRadioButtonSecondaryDisabledColor: { main: secondaryDisabledColor },
      checkedRadioButtonPrimaryDisabledColor: { main: primaryDisabledColor },
      userStatus: {
        invitationSent: { main: '#ffd966' },
        active: { main: '#6aa84f' },
        blocked: { main: '#cc0000' },
        blockedByPlant: { main: '#990000' },
      },
      plantStatus: {
        trial: { main: '#003060' },
      },
    },
  },
});

// define light theme colors
export const lightTheme: Theme = darkTheme;
