import { PlantsGetResponse } from '@app/api/plants';
import { UserStatus, UsersGetResponse } from '@app/api/users/UsersGetResponse';
import { EditButton, ReinviteButton } from '@app/components/shared/CustomIconButton';
import TableNoResults from '@app/components/shared/TableNoResults';
import { PAGINATION, PERMISSIONS } from '@app/utils/constants';
import { getInverseOrder } from '@app/utils/getInverseOrder';
import { format } from 'date-fns';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Roles, ROLES } from '../userManagementUtils';
import useScrollTop from '@app/hooks/useScrollTop';
import { useState } from 'react';
import EllipsedText from '@app/components/shared/EllipsedText';
import StatusComponent from '@app/components/shared/StatusComponent';
import { useSystemOfMeasurementOptions } from '@app/hooks/useSystemOfMeasurementOptions';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { BlockButton } from '@app/components/shared/CustomIconButton';
import CustomTooltip from '@app/components/shared/CustomTooltip';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';

interface UserManagementTableProps {
  plants: PlantsGetResponse[];
  users: UsersGetResponse[];
  changeUserProperty: (userInfo: { userId: number; field: string; value: number | string }) => void;
  blockUser: (user: UsersGetResponse) => void;
  editUser: (user: UsersGetResponse) => void;
  reinviteUser: (userId: number) => void;
  page: number;
  changePage: (page: number) => void;
  rowsPerPage: number;
  changeRowsPerPage: (rows: number) => void;
  total: number;
  searchInput: string;
  sortUsers: (sortingOrder: string) => Promise<void>;
  isPlantAdmin?: boolean
}

const KEY_USER_NAME = 'name';
const KEY_USER_EMAIL = 'email';
const KEY_USER_ROLE = 'userProfileName';
const KEY_USER_STATUS = 'status';
const KEY_USER_INVITATION_SENDING_DATE = 'invitationSendingDate';
const KEY_USER_FIRST_ACCESS = 'firstAccess';
const KEY_USER_LAST_ACCESS = 'lastAccess';
const KEY_USER_MEASUREMENT_SYSTEM = 'measurementSystem'

const UserManagementTable: React.FC<UserManagementTableProps> = (props: UserManagementTableProps) => {
  const {
    plants,
    users,
    blockUser,
    editUser,
    reinviteUser,
    page,
    changePage,
    rowsPerPage,
    changeRowsPerPage,
    total,
    searchInput,
    sortUsers,
    isPlantAdmin,
  } = props;

  const { t } = useTranslation();
  const { hasAccess } = useAuthenticationContext();

  const [orderBy, setOrderBy] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>();

  const isOrderedByName = () => orderBy === KEY_USER_NAME;
  const isOrderedByEmail = () => orderBy === KEY_USER_EMAIL;
  const isOrderedByRole = () => orderBy === KEY_USER_ROLE;
  const isOrderedByStatus = () => orderBy === KEY_USER_STATUS;
  const isOrderedByInvitationSendingDate = () => orderBy === KEY_USER_INVITATION_SENDING_DATE;
  const isOrderedByFirstAccess = () => orderBy === KEY_USER_FIRST_ACCESS;
  const isOrderedByLastAccess = () => orderBy === KEY_USER_LAST_ACCESS;
  const isOrderedByMeasurementSystem = () => orderBy === KEY_USER_MEASUREMENT_SYSTEM;

  const handleSortBy = (criteria: string) => {
    setOrder(old => {
      const inverseOrder = getInverseOrder(old);

      sortUsers(`${criteria} ${inverseOrder}`);

      return inverseOrder;
    });

    setOrderBy(criteria);
  };

  const renderPlant = (row: UsersGetResponse) => {
    const plantsNames = plants.filter(p => row.plants.includes(p.id))?.flatMap(p => p.name);
    return (
      <Grid container item direction="row" justifyContent="space-between">
        <article style={{ maxWidth: '80%' }}>
          <EllipsedText maxDigits={25} lines={1}  text={plantsNames.toString().replace(',', ', ')} />
        </article>
      </Grid>
    );
  };

  const renderRole = (row: UsersGetResponse) => {
    const user = users.find(u => u.id === row.id);
    const role = ROLES.find(r => r.value === user?.userProfileId);
    
    return t(role?.name || '');
  };

  const renderDateCell = (date: string) => {
    const hasValue = date && date.length > 0;
    const shortDate = hasValue ? format(new Date(date), t('date.format')) : '-';

    return (hasValue ?
      <CustomTooltip title={`${format(new Date(date), t('date-time.format'))} (UTC+00:00)`} placement='top-start'>
        <Typography variant='body2'>{shortDate}</Typography>
      </CustomTooltip> : 
      <Typography variant='body2'>{shortDate}</Typography>
    );
  }

  const { systemOfMeasurementOptions } = useSystemOfMeasurementOptions();

  const getMeasurementSystemLabel = (measurmentSystemOption: SystemOfMeasurementOptions) => {
    return systemOfMeasurementOptions.find(option => option.value === measurmentSystemOption)?.name;
  }

  const renderLines = () => {
    return users.map((row, i) => {
      return (
        <TableRow key={i}>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.email}</TableCell>
          {!isPlantAdmin && <TableCell align="left">{renderPlant(row)}</TableCell>}
          <TableCell align="left">{renderRole(row)}</TableCell>
          <TableCell align="left">
            <StatusComponent status={row.status}/>
          </TableCell>
          <TableCell align="left">
            {renderDateCell(row.invitationSendingDate)}
          </TableCell>
          <TableCell align="left">
            {renderDateCell(row.firstAccess)}
          </TableCell>
          <TableCell align="left">
            {renderDateCell(row.lastAccess)}
          </TableCell>
          <TableCell align="left">
            <Typography variant='body2'>{getMeasurementSystemLabel(row.measurementSystem)}</Typography>
          </TableCell>
          <TableCell align="right">
            <Box style={{ display: 'flex', gap: '16px', alignItems: "center" }}>
              {
                hasAccess([PERMISSIONS.USER_MANAGEMENT_LIST]) && (
                  <ReinviteButton
                    onClick={() => reinviteUser(row.id)}
                    disabled={
                      row.status === UserStatus.BLOCKED ||
                      row.status === UserStatus.BLOCKED_BY_PLANT
                    }
                  />
                )
              }
              {
                hasAccess([PERMISSIONS.USER_MANAGEMENT_EDIT]) && (
                  <BlockButton
                    blocked={row.status === UserStatus.BLOCKED}
                    onClick={() => blockUser(row)}
                    disabled={
                      row.status === UserStatus.BLOCKED_BY_PLANT ||
                      row.userProfileId === Roles.Admin ||
                      (
                        isPlantAdmin && (
                          row.userProfileId === Roles.PlantAdmin ||
                          row.userProfileId === Roles.KeyUser ||
                          row.userProfileId === Roles.TechnicalConsultant
                        )
                      )
                    }
                  />
                )
              }
              { 
                hasAccess([PERMISSIONS.USER_MANAGEMENT_EDIT]) && (
                  <EditButton
                    onClick={() => editUser(row)}
                    disabled={
                      row.status === UserStatus.BLOCKED ||
                      row.status === UserStatus.BLOCKED_BY_PLANT ||
                      (
                        isPlantAdmin && (
                          row.userProfileId === Roles.PlantAdmin ||
                          row.userProfileId === Roles.KeyUser ||
                          row.userProfileId === Roles.TechnicalConsultant
                        )
                      )
                    }
                  />
                )
              }
            </Box>
          </TableCell>
        </TableRow>
      );
    });
  };

 const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop<HTMLDialogElement>();

  return (
    <>
      <Paper>
        <TableContainer style={{ maxHeight: 'calc(100vh - 403px)' }} innerRef={tableContainerRef}>
          <Table stickyHeader size="medium">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sortDirection={isOrderedByName() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByName()}
                    direction={isOrderedByName() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_NAME)}>
                    {t('name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={isOrderedByEmail() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByEmail()}
                    direction={isOrderedByEmail() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_EMAIL)}>
                    {t('e-mail')}
                  </TableSortLabel>
                </TableCell>
                {
                  !isPlantAdmin &&
                  (
                    <TableCell align="left">
                      {t('admin-panel.assigned.plants')}
                    </TableCell>
                  )
                }
                <TableCell
                  align="left"
                  sortDirection={isOrderedByRole() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByRole()}
                    direction={isOrderedByRole() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_ROLE)}>
                    {t('user.profile')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={isOrderedByStatus() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByStatus()}
                    direction={isOrderedByStatus() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_STATUS)}>
                    {t('admin-panel.status')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={isOrderedByInvitationSendingDate() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByInvitationSendingDate()}
                    direction={isOrderedByInvitationSendingDate() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_INVITATION_SENDING_DATE)}>
                    {t('admin-panel.invitation-sending-date')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={isOrderedByFirstAccess() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByFirstAccess()}
                    direction={isOrderedByFirstAccess() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_FIRST_ACCESS)}>
                    {t('admin-panel.first-access')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={isOrderedByLastAccess() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByLastAccess()}
                    direction={isOrderedByLastAccess() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_LAST_ACCESS)}>
                    {t('admin-panel.last-access')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={isOrderedByMeasurementSystem() ? order : undefined}
                >
                  <TableSortLabel
                    active={isOrderedByMeasurementSystem()}
                    direction={isOrderedByMeasurementSystem() ? order : getInverseOrder(order)}
                    onClick={() => handleSortBy(KEY_USER_MEASUREMENT_SYSTEM)}>
                    {t('admin-panel.measurement-system')}
                  </TableSortLabel>
                </TableCell>
                <TableCell className="table-actions" align="center"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users.length ? (
                renderLines()
              ) : (
                <TableRow>
                  <TableCell colSpan={!isPlantAdmin ? 10 : 9}>
                    <TableNoResults searchInput={searchInput} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => {
            changePage(page)
            scrollTop();
          }}
          onRowsPerPageChange={e => {
            changeRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE));
          }}
          labelRowsPerPage={t('rows-per-page')}
          backIconButtonText={t('page-previous')}
          nextIconButtonText={t('page-next')}
        />
      </Paper>
    </>
  );
};

export default UserManagementTable;
