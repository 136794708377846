
export interface ArchivedHeatState {
  startDate: string | null;
  endDate: string | null;
}

export const ARCHIVED_HEAT_INITIAL_STATE: ArchivedHeatState = {
  startDate: null,
  endDate: null
};
