import { useProcessConstraintsService } from '@app/api/processConstraints';
import { ProcessConstraintsDTO } from '@app/api/processConstraints/useProcessConstraintsService';
import { useState } from 'react';
import { InfoObject } from "./types"


const infoObjectInitialState: InfoObject<ProcessConstraintsDTO> = {
  status: 'idle',
  data: undefined,
}

const useLoadProcessConstraints = (): [
  InfoObject<ProcessConstraintsDTO>,
  () => Promise<void>,
] => {

  const { getProcessConstraints } = useProcessConstraintsService();

  const [infoObject, setInfoObject] = useState<InfoObject<ProcessConstraintsDTO>>(infoObjectInitialState);

  const loadProcessConstraints = async () => {
    try {
      setInfoObject(prevState => ({...prevState, status: 'loading'}));

      const response = await getProcessConstraints();
      setInfoObject({ data: response, status: 'succeeded'});
    } catch (err) {

      setInfoObject({data: undefined, status: 'error'});
    }
  };


  return [infoObject, loadProcessConstraints];
}

export default useLoadProcessConstraints;