import { PlantsCreateRequest, PlantsGetResponse, PlantUpdateCarryoverSlagSettingsRequest } from '.';
import { useApi } from '..';
import { PlantActivityResponse } from './PlantActivityResponse';
import { PlantUpdateRequest } from './PlantUpdateRequest';

export function usePlantsService() {
  const { get, post, put, patch } = useApi();
  const baseApi = `/v2/Plants`;

  const getAllPlants = async () => {
    const { data } = await get<PlantsGetResponse[]>(`${baseApi}`);

    return data;
  };

  const getPlantById = async (id: string) => {
    const { data } = await get<PlantsGetResponse>(`${baseApi}/${id}`);

    return data;
  }

  const getPlantActivitiesById = async (id: string) => {
    const { data } = await get<PlantActivityResponse[]>(`${baseApi}/${id}/activities`);

    return data;
  }

  const blockPlant = async (id: string, block: boolean, reason: string) => {
    const { data } = await patch<PlantsGetResponse>(`${baseApi}/${id}`, {block: block, Reason: reason});

    return data;
  }

  const createPlant = async (request: PlantsCreateRequest) => {
    const { data } = await post<PlantsGetResponse>(`${baseApi}`, request);

    return data;
  };

  const updatePlant = async (id: string, request: PlantUpdateRequest) => {
    const { data } = await put(`${baseApi}/${id}`, request);

    return data;
  }

  const updatePlantCarryoverSlagSettings = async (request: PlantUpdateCarryoverSlagSettingsRequest) => {
    const { data } = await put(`${baseApi}/CarryoverSlagSettings`, request);

    return data;
  };

  return {
    getAllPlants,
    createPlant,
    updatePlant,
    updatePlantCarryoverSlagSettings,
    getPlantById,
    blockPlant,
    getPlantActivitiesById
  };
}
