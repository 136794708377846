import { CENTIMETERS_METERS_CONVERSION_FACTOR, MILLIMETERS_METERS_CONVERSION_FACTOR } from "./constants"

type lengthUnitOfMeasurement = 'cm' | 'mm'

export const limitDecimalPlaces = (value: number, decimalPlaces: number) => {
    return Number(value.toFixed(decimalPlaces));
}

const selectConversionFactor = (unitOfMeasurement: lengthUnitOfMeasurement) => {
    return unitOfMeasurement === 'cm' ? CENTIMETERS_METERS_CONVERSION_FACTOR : MILLIMETERS_METERS_CONVERSION_FACTOR
}

export const convertMetersIntoOtherUnitOfMeasurement = (value: number, unitOfMeasurement: lengthUnitOfMeasurement) => {
    const conversionFactor = selectConversionFactor(unitOfMeasurement);
    
    return (value * conversionFactor);
}

export const convertOtherUnitOfMeasurementIntoMeters = (value: number, unitOfMeasurement: lengthUnitOfMeasurement) => {
    const conversionFactor = selectConversionFactor(unitOfMeasurement);

    return (value / conversionFactor);
}