import qs from 'qs';
import { UsersCreateRequest, UsersGetResponse, UsersUpdateRequest } from '.';
import { PagedResult, useApi } from '..';

export function useUsersService() {
  const { put, post, get, patch } = useApi();
  const baseApi = `/v2/users`;

  const setLanguage = async (request: { languageName: 'en' | 'pt-BR' | "es" | undefined}) => {
    const { data } = await put<{}>(`${baseApi}/language`, request);

    return data;
  };

  const createUser = async (user: UsersCreateRequest) => {
    const { data } = await post(`${baseApi}`, user);

    return data;
  };

  const updateUsers = async (users: UsersUpdateRequest[]) => {
    const { data } = await put(`${baseApi}`, users);

    return data;
  };

  const blockUser = async (requestData: { block: boolean }, id: number) => {
    const { data } = await patch(`${baseApi}/${id}`, requestData);

    return data;
  }

  const reinviteUser = async (userId: number) => {
    const { data } = await post(`${baseApi}/${userId}/invitations`);

    return data
  }

  const getUsers = async (
    filter?: string, 
    plants?: string[], 
    status?: string[], 
    roles?: string[],
    customers?: number[],
    size?: number, 
    page?: number, 
    sorting?: string
  ) => {
    const params = { 
      filter,
      plantId: plants, 
      customerId: customers, 
      userStatus: status, 
      role: roles, 
      sorting,
      size,
      page
    }

    const { data } = await get<PagedResult<UsersGetResponse>>(`${baseApi}`, {
      params,
      paramsSerializer(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      },
    });

    return data;
  };

  return {
    setLanguage,
    createUser,
    updateUsers,
    getUsers,
    blockUser,
    reinviteUser
  };
}
