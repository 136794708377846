import { Paper, PaperProps } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

interface FormAutocompleteInputProps<T> extends Omit<AutocompleteProps<T, boolean, boolean, boolean>, "renderInput"> {
  id: string;
  label?: string;
  disableUnderline?: boolean;
}


const FormAutocompleteInput = <T,>({ label, id, disableUnderline, ...rest }: FormAutocompleteInputProps<T>) => {

  const CustomPaper = (props: PaperProps) => (
    <Paper elevation={8} {...props} />
  );

  return (
    <Autocomplete
      fullWidth
      PaperComponent={CustomPaper}
      renderInput={(params) => {
        return (
          <TextField 
            {...params} 
            label={label} 
            InputLabelProps={{ 
              ...params.InputLabelProps, 
              shrink: true, 
              htmlFor: id 
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline,
              name: id,
            }}
            inputProps={{ 
              ...params.inputProps, 
              id 
            }}
          />
        )
      }}
      {...rest}
    />  
  );
};

export default FormAutocompleteInput;
