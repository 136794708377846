import { UserRoles } from "./constants";
import { MeGetResponse } from "@app/api/me";

const hasAlphaFeaturesPermission = (user?: MeGetResponse) => {
  const isAdmin = user?.userProfileName === UserRoles.ADMINISTRATOR;
  const isKeyUser = user?.userProfileName === UserRoles.KEY_USER;
  const isTechnicalConsultant = user?.userProfileName === UserRoles.TECHNICAL_CONSULTANT;

  return isAdmin || isKeyUser || isTechnicalConsultant;
}

export { hasAlphaFeaturesPermission };