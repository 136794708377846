import i18next from 'i18next';
import * as yup from 'yup';
// import { MixedLocale, StringLocale } from 'yup/lib/locale';

const _mixed =
  // : Required<MixedLocale>
  {
    required: i18next.t('yup.mixed.required'),
  };

yup.setLocale({
  mixed: _mixed,
});

export default yup;
