import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlantManagementDetailsActivities, { PlantManagementDetailsActivitiesProps } from './PlantManagementDetailsActivities';
import { Box, TextField, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { IPlantDetailsForm } from './PlantManagementDetailsContainer';
import FormCheckbox from '@app/components/shared/FormCheckbox';
import FormSelect from '@app/components/shared/FormSelect';
import { plantStatusOptions } from '../PlantManagementContent';
import { PlantsGetResponse } from '@app/api/plants';
import FormAutocompleteWithTags from '@app/components/shared/FormAutocompleteWithTags';
import { AutocompleteChangeReason } from '@material-ui/lab';
import CustomerAutocomplete from '../components/CustomerAutocomplete';
import { PlantStatus } from '@app/api/plants/PlantsGetResponse';
import { DateTimeUtils } from '@app/utils/dateTimeUtils';

interface PlantManagementDetailsContentProps extends PlantManagementDetailsActivitiesProps {
  plant?: PlantsGetResponse
  readOnly: boolean
}

const rowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'flex-end',
  gap: '8px',
  width: '100%',
  paddingBottom: '16px',
}

const rowItemStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  width: '100%',
}

const rowItemExtraGapStyle: React.CSSProperties = {
  gap: '10px',
}

const rowItemFixedWidthStyle: React.CSSProperties = {
  flex: '0 0 320px',
}

const lineWithCheckboxPaddingBottom = '11px';
const checkboxPaddingBottom = '5px';

const PlantManagementDetailsContent = (props: PlantManagementDetailsContentProps): ReactElement => {
  const { t } = useTranslation();
  const { plant, readOnly, activities } = props;
  const { control, setValue, watch, trigger, getFieldState } = useFormContext<IPlantDetailsForm>();

  const [trialDatesDisabled, setTrialDatesDisabled] = useState<boolean>(true);
  const [activeDatesDisabled, setActiveDatesDisabled] = useState<boolean>(true);

  const watchStatus = watch('status');
  const watchTrialExpirationEnabled = watch('trialExpirationEnabled');
  const watchContractExpirationEnabled = watch('contractExpirationEnabled');
  const watchApplicationRegistrationIdEnabled = watch('applicationRegistrationIdEnabled');

  useEffect(() => {
    if (!watchTrialExpirationEnabled) {
      setValue('trialExpiration', undefined);
    }

    trigger('trialExpiration');
  }, [watchTrialExpirationEnabled, setValue, trigger]);

  useEffect(() => {
    if (!watchContractExpirationEnabled) {
      setValue('contractExpiration', undefined);
    }

    trigger('contractExpiration');
  }, [watchContractExpirationEnabled, setValue, trigger]);

  useEffect(() => {
    if (!watchApplicationRegistrationIdEnabled) {
      setValue('applicationRegistrationId', '');
    }
  }, [watchApplicationRegistrationIdEnabled, setValue]);

  useEffect(() => {
    const trialState = getFieldState('trialExpiration');
    const contractState = getFieldState('contractExpiration');
    
    switch (watchStatus) {
      case PlantStatus.ACTIVE:
        setTrialDatesDisabled(true);
        setActiveDatesDisabled(false);

        if (trialState.invalid) {
          setValue('trialExpirationEnabled', false);
        }
        break;
      case PlantStatus.TRIAL:
        setTrialDatesDisabled(false);
        setActiveDatesDisabled(true);

        if (contractState.invalid) {
          setValue('contractExpirationEnabled', false);
        }        
        break;
      case PlantStatus.BLOCKED:        
      default:
        setTrialDatesDisabled(true);
        setActiveDatesDisabled(true);

        if (trialState.invalid) {
          setValue('trialExpirationEnabled', false);
        }

        if (contractState.invalid) {
          setValue('contractExpirationEnabled', false);
        }
        break;
    }
  }, [watchStatus, setValue, getFieldState]);

  const emailDomainsOnChange = (_: React.ChangeEvent<{}>,
    value: string | string[] | null,
    reason: AutocompleteChangeReason
  ): void => {
    if (reason === 'clear') {
      setValue('emailDomains', []);
    } else {
      setValue('emailDomains', value as string[]);
    }
  }

  const renderGeneralInfoSection = (): JSX.Element => {
    return (
      <>
        <Box paddingBottom={2}>
          <Typography variant="body2">
            <b>{t('admin-panel.plant-management.details.section.general-info')}</b>
          </Typography>
        </Box>
        <Box style={{...rowStyle}}>
          <Box style={{...rowItemStyle,...rowItemExtraGapStyle,...rowItemFixedWidthStyle}}>
            <Box>
                <Controller
                  name='name'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}  
                      label={t('admin-panel.plant-name')}
                      autoComplete='off'
                      fullWidth
                      disabled={readOnly}
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                />
            </Box>
            
            <Box>
              <Controller
                name='customer'
                control={control}
                render={({field}) => (
                  <CustomerAutocomplete 
                    value={field.value}
                    onChange={(customer) => {
                      field.onChange(customer);
                    }}
                    disabled={readOnly}
                  />
                )}
              />
            </Box>
          </Box>

          <Box style={rowItemStyle}>
            <Box style={{ height: '100%' }}>
              <Controller
                name='description'
                control={control}
                render={({field}) => (
                  <TextField
                    {...field}  
                    label={t('admin-panel.plant-management.plant.description')}
                    autoComplete='off'
                    disabled={readOnly}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    variant='outlined'
                    inputProps={{ maxLength: 150 }}
                  />
                )}
              />
            </Box>
          </Box>

          <Box style={rowItemStyle}>
            <Box>
              <Controller
                name='emailDomains'
                control={control}
                render={({field}) => (
                  <FormAutocompleteWithTags
                    {...field}
                    id='emailDomains'
                    fullWidth
                    fixedHeight='96px'
                    label={t('admin-panel.plant-management.email.domains')}
                    multiple
                    options={field.value}
                    limitTags={undefined}
                    freeSolo                
                    onChange={emailDomainsOnChange}
                    disabled={readOnly}
                  />
                )}
              />
            </Box>
          </Box>

          <Box style={{...rowItemStyle,...rowItemExtraGapStyle}}>
            <Box>
              <TextField
                label={t('admin-panel.plant-management.user.criation')}
                value={plant?.createdBy}
                disabled
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={t('admin-panel.plant-management.date.criation')}
                value={DateTimeUtils.format(plant?.createdDate, t('date-time.format'))[0]}
                disabled
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      </>      
    );
  }

  const renderStatusAndDatesSection = (): JSX.Element => {
    return (
      <>
        <Box paddingBottom={lineWithCheckboxPaddingBottom}>
          <Typography variant="body2">
            <b>{t('admin-panel.plant-management.details.section.status-dates')}</b>
          </Typography>
        </Box>
        <Box style={rowStyle}>
          <Box style={{...rowItemStyle,...rowItemFixedWidthStyle}}>
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <FormSelect
                  className='formSelectWrapper'
                  id='status'
                  label={t('admin-panel.status')}
                  options={plantStatusOptions.map(item => ({...item, name: t(item.name)}))}
                  {...field}
                  value={field.value ?? ''}
                  disabled={readOnly}
                  fullWidth
                />
              )}
            />
          </Box>

          <Box style={rowItemStyle}>
            <Controller
              name='trialStart'
              control={control}
              render={({ field }) => (
                <KeyboardDatePicker
                  id='trialStart'
                  label={t('admin-panel.plant-management.trial.start')}
                  {...field}
                  value={field.value || null}
                  autoOk={true}
                  format={t('date.format')}
                  placeholder={t('date-placeholder.format')}
                  cancelLabel={t('button.cancel')}
                  disabled={readOnly || trialDatesDisabled}
                />
              )}
            />
          </Box>

          <Box style={rowItemStyle}>
            <Box style={{paddingBottom: checkboxPaddingBottom}}>
              <Controller
                name='trialExpirationEnabled'
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    label={t('admin-panel.plant-management.trial.expiration')}
                    id='trialExpirationEnabled'
                    {...field}
                    disabled={readOnly || trialDatesDisabled}
                  />
                )}
              />
            </Box>
            <Controller
              name='trialExpiration'
              control={control}
              render={({ field }) => (
                <KeyboardDatePicker
                  id='trialExpiration'
                  {...field}
                  disabled={readOnly || !watchTrialExpirationEnabled || trialDatesDisabled}
                  value={field.value || null}
                  autoOk={true}
                  format={t('date.format')}
                  placeholder={t('date-placeholder.format')}
                  cancelLabel={t('button.cancel')}
                />
              )}
            />
          </Box>

          <Box style={rowItemStyle}>
            <Controller
              name='contractStart'
              control={control}
              render={({ field }) => (
                <KeyboardDatePicker
                  id='contractStart'
                  label={t('admin-panel.plant-management.contract.start')}
                  {...field}
                  value={field.value || null}
                  autoOk={true}
                  format={t('date.format')}
                  placeholder={t('date-placeholder.format')}
                  cancelLabel={t('button.cancel')}
                  disabled={readOnly || activeDatesDisabled}
                />
              )}
            />
          </Box>

          <Box style={rowItemStyle}>
            <Box style={{paddingBottom: checkboxPaddingBottom}}>
              <Controller
                name='contractExpirationEnabled'
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    label={t('admin-panel.plant-management.contract.expiration')}
                    id='contractExpirationEnabled'
                    {...field}
                    disabled={readOnly || activeDatesDisabled}
                  />
                )}
              />
            </Box>
            <Controller
              name='contractExpiration'
              control={control}
              render={({ field }) => (
                <KeyboardDatePicker
                  id='contractExpiration'
                  {...field}
                  disabled={readOnly || !watchContractExpirationEnabled || activeDatesDisabled}
                  value={field.value || null}
                  autoOk={true}
                  format={t('date.format')}
                  placeholder={t('date-placeholder.format')}
                  cancelLabel={t('button.cancel')}
                />
              )}
            />
          </Box>
        </Box>
      </>      
    );
  }

  const renderAutomationSystemsSection = (): JSX.Element => {
    return (
      <>
        <Box paddingBottom={lineWithCheckboxPaddingBottom}>
          <Typography variant="body2">
            <b>{t('admin-panel.plant-management.details.section.automation')}</b>
          </Typography>
        </Box>
        <Box style={rowStyle}>
          <Box style={{...rowItemStyle,...rowItemFixedWidthStyle}}>
            <Box style={{paddingBottom: checkboxPaddingBottom}}>
              <Controller
                name='applicationRegistrationIdEnabled'
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    label={t('admin-panel.plant-management.has.registration')}
                    id='trialExpirationEnabled'
                    {...field}
                    disabled={readOnly}
                  />
                )}
              />
            </Box>
            <Controller
              name='applicationRegistrationId'
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder={t('admin-panel.plant-management.application.registration')}
                  fullWidth
                  {...field}
                  disabled={readOnly || !watchApplicationRegistrationIdEnabled}
                  inputProps={{ maxLength: 36 }}
                />
              )}
            />
          </Box>
        </Box>
      </>      
    );
  }

  return (
    <Box style={{ padding: '32px 0', display: 'flex', flexDirection: 'column' }}>
      {renderGeneralInfoSection()}
      {renderStatusAndDatesSection()}
      {renderAutomationSystemsSection()}
      <PlantManagementDetailsActivities activities={activities} />
    </Box>
  ); 
}

export default PlantManagementDetailsContent;