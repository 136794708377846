import { DeleteButton } from "@app/components/shared/CustomIconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Fab,
  Tooltip,
  useTheme
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { useEffect, useState, createRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { newSteelGradeInitialState } from "../steelGradeUtils";
import { PostSteelGradeCategoryModel, SteelGradeCategoryModel } from "@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes";
import FormValidationWarning from "@app/components/shared/FormValidationWarning";
import FormSelect, { FormSelectOptions } from "@app/components/shared/FormSelect";
import { SaturationMode } from "@app/api/heats/HeatsResponse";
import NumberFormat from "react-number-format";
import { APP_MGO_SATURATION_MODE_ENABLED, MAX_INITIAL_SULFUR_PERCENTTAGE, MAX_OXYGEN_CONTENT, MIN_INITIAL_SULFUR_PERCENTAGE, MIN_OXYGEN_CONTENT } from "@app/utils/constants";
import FormButton from "@app/components/shared/FormButton";
import useCompositionTableCellStyles from "@app/hooks/useCompositionTableStyles";
import WarningMessageComponent from "@app/components/shared/WarningMessageComponent";

interface CreateEditSteelGradeCategoryModalProps {
    openModal: boolean,
    onClose: () => void,
    onSubmit: (request: SteelGradeCategoryModel | PostSteelGradeCategoryModel) => void,
    targetSteelGradeCategory: SteelGradeCategoryModel | undefined
}



const CreateEditSteelGradeCategoryModal = (props: CreateEditSteelGradeCategoryModalProps) => {

  const tapListRef = createRef<HTMLTableRowElement>();

  const classes = useCompositionTableCellStyles().useStyles()();
  const { t } = useTranslation();

  const { openModal, onClose, onSubmit, targetSteelGradeCategory } = props;

  const isEdit = !!targetSteelGradeCategory;

  const [steelGradeCategory, setSteelGradeCategory] = useState<PostSteelGradeCategoryModel | SteelGradeCategoryModel>(newSteelGradeInitialState);

  useEffect(() => {
    if(openModal) {
      setSteelGradeCategory(newSteelGradeInitialState);
    }
  }, [openModal]);

  useEffect(() => {
    if(targetSteelGradeCategory) {
      setSteelGradeCategory(targetSteelGradeCategory);
    }
  }, [targetSteelGradeCategory]);

  const handleClose = () => {
      onClose();
  };

  const handleChangeSteelGradeCategoryName = (name: string) => {
    setSteelGradeCategory(lastSteelGradeCategory => ({...lastSteelGradeCategory, name }))
  }
  const scrollToSteelGradeListBottom = () => {
    setTimeout(() => {
      tapListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100)
  }

  const addNewSteelGrade = () => {
      const currentSteelGradeCategory = {...steelGradeCategory};
      currentSteelGradeCategory.steelGrades.push(newSteelGradeInitialState.steelGrades[0] as {
        name: string,
        id: number,
        saturationMode: 'DualSaturation',
        initialSulfurPercentage: null,
        finalOxygenInSteel: null}
      );
      setSteelGradeCategory(currentSteelGradeCategory);
      scrollToSteelGradeListBottom();
    };

    const removeSteelGrade = (index: number) => {
    const currentSteelGradeCategory = {...steelGradeCategory};
    currentSteelGradeCategory.steelGrades = currentSteelGradeCategory.steelGrades.filter((_,steelGradeIndex) => steelGradeIndex !== index);
    setSteelGradeCategory(currentSteelGradeCategory);
  }

  const handleChangeSteelGradeProperty = <K extends keyof SteelGradeCategoryModel['steelGrades'][number]>(
    steelGradeProperty: K,
    value: SteelGradeCategoryModel['steelGrades'][number][K],
    index: number
  ) => {
    const newSteelGrades = [...steelGradeCategory.steelGrades];
    newSteelGrades[index] = {
      ...newSteelGrades[index],
      [steelGradeProperty]: value,
    };

    setSteelGradeCategory((lastSteelGradeCategory) => ({
      ...lastSteelGradeCategory,
      steelGrades: newSteelGrades,
    }));
  };

  const steelGradeListRef = useRef(null);

  const hasSomeSteelGradesWithoutName = steelGradeCategory.steelGrades.some(steelGrade => !steelGrade.name);

  const hasSomeSteelGradesWithInvalidInitialSulfur = steelGradeCategory.steelGrades.some(
    steelGrade => !steelGrade.initialSulfurPercentage && typeof steelGrade.initialSulfurPercentage !== 'number'
      ? false
      : (
        (steelGrade.initialSulfurPercentage ?? 0) < MIN_INITIAL_SULFUR_PERCENTAGE
        || (steelGrade.initialSulfurPercentage ?? 0) > MAX_INITIAL_SULFUR_PERCENTTAGE
      )
    );

  const hasSomeSteelGradesWithInvalidOxygenContent = steelGradeCategory.steelGrades.some(
    steelGrade => !steelGrade.finalOxygenInSteel && typeof steelGrade.finalOxygenInSteel !== 'number'
      ? false
      : (
        (steelGrade.finalOxygenInSteel ?? 0) < MIN_OXYGEN_CONTENT
        || (steelGrade.finalOxygenInSteel ?? 0) > MAX_OXYGEN_CONTENT
      )
    );

  const saturationModeOptions: FormSelectOptions<string> = [
    {
      name: t('new-heat.form.text-field.saturation-mode.option.dual-saturation'),
      value: 'DualSaturation',
      tooltip: t('hints.heat.saturation-mode.dual-saturation'),
    },
    {
      name: t('new-heat.form.text-field.saturation-mode.option.mgo-saturation'),
      value: 'MgOSaturation',
      tooltip: t('hints.heat.saturation-mode.mgo-saturation'),
      disabled: !APP_MGO_SATURATION_MODE_ENABLED,
    }
  ];

  const theme = useTheme();

  const renderStelGrades = () => {
    return (
      <>
        {steelGradeCategory.steelGrades.map((row, i: number) => (
          <TableRow key={i} ref={steelGradeListRef} data-testid={`create-edit-steel-grade-category-form.steel-grade-item-${i}`}>
            <TableCell className={classes.root} style={{ backgroundColor: theme.palette.common.white}}>
              <TextField
                id={`steel-grade-${i}-name`}
                inputProps={{
                  "data-testid": `create-edit-steel-grade-category-form.steel-grade-item.${i}.field.name`,
                  maxLength: 30
                }}
                value={row.name}
                style={{ width: '100%' }}
                onChange={(e) => handleChangeSteelGradeProperty('name',e.target.value, i)}
                autoComplete="off"
                fullWidth
              />
            </TableCell>
            <TableCell className={classes.root}>
              <FormSelect
                fullWidth
                style={{ width: '100%' }}
                id="saturationMode"
                name="saturationMode"
                value={row.saturationMode ?? ''}
                required
                options={saturationModeOptions}
                hasTooltip
                onChange={e => {
                  handleChangeSteelGradeProperty('saturationMode' ,e.target.value as SaturationMode, i)
                }}
              />
          </TableCell>
          <TableCell className={classes.root}>
            <Tooltip placement="left" title={<>{t('hint.secondary-metallurgy')}</>}>
              <NumberFormat
                fullWidth
                autoComplete="off"
                InputProps={{
                  endAdornment: 'wt%',
                }}
                InputLabelProps={{ shrink: true }}
                value={row.initialSulfurPercentage}
                onValueChange={e => handleChangeSteelGradeProperty('initialSulfurPercentage', e.floatValue as number, i)}
                customInput={TextField}
                allowNegative={false}
                decimalScale={4}
                isAllowed={(input) =>
                  (input.floatValue ?? 0) <= MAX_INITIAL_SULFUR_PERCENTTAGE
                }
                decimalSeparator={t('decimal-scale-separator')}
              />
            </Tooltip>
          </TableCell>
          <TableCell className={classes.root}>
            <Tooltip placement="left" title={<>{t('hint.secondary-metallurgy')}</>}>
              <NumberFormat
                fullWidth
                autoComplete="off"
                InputProps={{
                  endAdornment: 'ppm',
                }}
                InputLabelProps={{ shrink: true }}
                value={row.finalOxygenInSteel}
                onValueChange={e => handleChangeSteelGradeProperty('finalOxygenInSteel',e.floatValue as number, i)}
                customInput={TextField}
                allowNegative={false}
                decimalScale={0}
                decimalSeparator={t('decimal-scale-separator')}
                isAllowed={(input) =>
                  (input.floatValue ?? 0) <= MAX_OXYGEN_CONTENT
                }
              />
            </Tooltip>
          </TableCell>
            <TableCell align="right" width="56px" style={{ backgroundColor: "white", width: 'fitContent' }}>
              <DeleteButton
                onClick={() => removeSteelGrade(i)}
                disabled={steelGradeCategory.steelGrades.length === 1}
                data-testid={`create-edit-steel-grade-category-form.steel-grade-item-${i}.delete.button`}
              />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      fullScreen
      style={{
        height: 'fit-content',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '70vw',
        maxWidth: '760px',
        margin: 'auto',
      }}
      PaperProps={{
        style: {
          padding: '24px'
        }
      }}
      open={openModal}
      onClose={handleClose}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ padding: 0, paddingBottom: '32px' }}
      >
        {isEdit === false ? t('steel-grade-category.create-modal.title') : t('steel-grade-category.edit-modal.title')}
      </DialogTitle>
      <DialogContent data-testid="create-or-edit-stee-grade-modal-content" style={{ padding: 0 }}>
          <TextField
              id="name"
              inputProps={{
                "data-testid": "create-edit-steel-grade-category-form.steel-grade-category.field.name",
                maxLength: 50
              }}
              label={t('name')}
              InputLabelProps={{shrink: true}}
              value={steelGradeCategory.name}
              style={{ width: '100%' }}
              onChange={(e) => handleChangeSteelGradeCategoryName(e.target.value)}
              autoComplete="off"
              fullWidth
          />
          {
            steelGradeCategory.name === '' &&
            <WarningMessageComponent message={t('steel-grade-category-name.text-field.tip-text')} />
          }

          <Grid container style={{ paddingTop: '32px' }}>
              <TableContainer>
                  <Table size='medium' stickyHeader>
                      <TableHead>
                          <TableRow>
                              <TableCell className={`${classes.root} ${classes.head}`}>
                                  {t('steel-grade')}
                              </TableCell>
                              <TableCell className={`${classes.root} ${classes.head}`}>
                                  {t('new-heat.form.text-field.saturation-mode')}
                              </TableCell>
                              <TableCell className={`${classes.root} ${classes.head}`}>
                                  {t('new-heat.form.text-field.initial-sulfur')}
                              </TableCell>
                              <TableCell className={`${classes.root} ${classes.head}`}>
                                  {t('new-heat.form.text-field.oxygen-content')}
                              </TableCell>
                              <TableCell className={`${classes.root} ${classes.head}`}>
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody data-testid="create-edit-steel-grade-category-form.steel-grade-list" >
                          {renderStelGrades()}
                      </TableBody>
                  </Table>
              </TableContainer>
              {
                hasSomeSteelGradesWithoutName &&
                <Grid item xs={12}>
                  <FormValidationWarning message={t('steel-grade-category.steel-grade-list-item-name.text-field.tip-text')} />
                </Grid>
              }
              {
                hasSomeSteelGradesWithInvalidInitialSulfur &&
                <Grid item xs={12}>
                  <FormValidationWarning message={t('steel-grade-category.steel-grade-list-item-initial-sulfur')} />
                </Grid>
              }
              {
                hasSomeSteelGradesWithInvalidOxygenContent &&
                <Grid item xs={12}>
                  <FormValidationWarning message={t('steel-grade-category.steel-grade-list-item-oxygen-content')} />
                </Grid>
              }
              <Grid item xs={12} style={{ display: 'flex', justifyContent:'center', padding: '16px 0px' }} >
                  <Fab
                    data-testid="create-edit-steel-grade-category.steel-grade-list.add-steel-grade.button"
                    color="primary"
                    type="button"
                    size="small"
                    aria-label="add"
                    onClick={addNewSteelGrade}
                  >
                    <AddIcon />
                  </Fab>
              </Grid>
          </Grid>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          gap: '25px',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '16px 0 0'
        }}
      >
        <FormButton
          data-testid="button.cancel"
          onClick={handleClose}
          variant="secondary"
        >
          {t('button.cancel')}
        </FormButton>
        <FormButton
          data-testid="button.save"
          variant="primary"
          style={{ margin: 0 }}
          disabled={
            steelGradeCategory.name === ''
            || hasSomeSteelGradesWithoutName
            || hasSomeSteelGradesWithInvalidInitialSulfur
            || hasSomeSteelGradesWithInvalidOxygenContent
          }
          onClick={() => onSubmit(steelGradeCategory)}
        >
          {isEdit === false ? t('button.add') : t('button.save')}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditSteelGradeCategoryModal;
