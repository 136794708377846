import { Grid } from "@material-ui/core";
import { ReactNode } from "react";

interface FormWrapperProps {
    children: ReactNode;
    style?: React.CSSProperties;
}

const FormWrapper = ({ children, style }: FormWrapperProps) => {

    const WraperStyles: React.CSSProperties = {
        gap: '8px',
        ...style
    } 

    return(
        <Grid container justifyContent="center" style={WraperStyles}>
            {children}
        </Grid>
    )
}

export default FormWrapper;