import { useApi } from '..';
import { FluxCategoryParameters } from '.'

export function useFluxesTypeService() {
  const { get } = useApi();
  const baseApi = `/v2/fluxes/types`;

  const getFluxTypes = async () => {
    const { data } = await get<FluxCategoryParameters[]>(`${baseApi}`);
    return data;
  };

  return {
    getFluxTypes,
  };
}
