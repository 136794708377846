const svg = {
  emptyState:
    'M67.1667 0.5H8.83333C4.25 0.5 0.5 4.25 0.5 8.83333V67.1667C0.5 71.75 4.20833 75.5 8.83333 75.5H67.1667C71.75 75.5 75.5 71.75 75.5 67.1667V8.83333C75.5 4.25 71.75 0.5 67.1667 0.5ZM67.1667 67.1667H8.83333V54.6667H23.6667C26.5417 59.625 31.875 63 38.0417 63C44.2083 63 49.5 59.625 52.4167 54.6667H67.1667V67.1667ZM67.1667 46.3333H46.375C46.375 50.9167 42.625 54.6667 38.0417 54.6667C33.4583 54.6667 29.7083 50.9167 29.7083 46.3333H8.83333V8.83333H67.1667V46.3333Z',
};

type Svg = keyof typeof svg;

export const getSvg = (name: Svg) => {
  return svg[name];
};
