import React, { createContext, useReducer } from 'react';
import { ArchivedHeatActions } from './archivedHeatActions';
import {
  ArchivedHeatDispatch,
  ArchivedHeatReducer,
  ARCHIVED_HEAT_INITIAL_DISPATCH
} from './archivedHeatReducer';
import { ArchivedHeatState, ARCHIVED_HEAT_INITIAL_STATE } from './archivedHeatState';

const ArchivedHeatStateContext = createContext<ArchivedHeatState>(ARCHIVED_HEAT_INITIAL_STATE);
const ArchivedHeatDispatchContext = createContext<ArchivedHeatDispatch>(ARCHIVED_HEAT_INITIAL_DISPATCH);

type ArchivedHeatProps = { children: React.ReactNode };

function ArchivedHeatProvider({ children }: ArchivedHeatProps) {
  const [state, dispatch] = useReducer(ArchivedHeatReducer, ARCHIVED_HEAT_INITIAL_STATE);

  return (
    <ArchivedHeatStateContext.Provider value={state}>
      <ArchivedHeatDispatchContext.Provider value={dispatch}>{children}</ArchivedHeatDispatchContext.Provider>
    </ArchivedHeatStateContext.Provider>
  );
}

function useArchivedHeatContext() {
  const state = React.useContext(ArchivedHeatStateContext);

  if (state === undefined) {
    throw new Error('useArchivedHeatState should be used inside an ArchivedHeatProvider');
  }

  const dispatch = React.useContext(ArchivedHeatDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useArchivedHeatDispatch should be used inside an ArchivedHeatProvider');
  }

  const setStartDate = (payload: string | null) => {

    dispatch({ type: ArchivedHeatActions.SET_START_DATE, payload });
  };

  const setEndDate = (payload: string | null) => {

    dispatch({ type: ArchivedHeatActions.SET_END_DATE, payload });
  };

  const resetDate = () => {

    dispatch({ type: ArchivedHeatActions.RESET_DATE });
  };

  return { state, setStartDate, setEndDate, resetDate };
}

export { ArchivedHeatProvider, useArchivedHeatContext };

