import { DeleteButton, EditButton } from '@app/components/shared/CustomIconButton';
import EllipsedText from '@app/components/shared/EllipsedText';
import TableNoResults from '@app/components/shared/TableNoResults';
import useScrollTop from '@app/hooks/useScrollTop';
import { SteelGradeCategoryModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { handleSendEvent } from '@app/utils';
import { PAGINATION, PERMISSIONS } from '@app/utils/constants';
import { Box, IconButtonProps, Paper, SelectProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SteelGradeTableProps {
  data: SteelGradeCategoryModel[];
  setDeleteCategory: (item: SteelGradeCategoryModel) => void;
  setEditCategory: (id: number) => void;
}

const SteelGradeTable = (props: SteelGradeTableProps): React.ReactElement => {

  const { data, setDeleteCategory, setEditCategory } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE_EXTENDED);

  const { t } = useTranslation();
  const { hasAccess } = useAuthenticationContext();

  useEffect(() => {
    setPage(0);
  }, [data])

  const onClickDelete = (item: SteelGradeCategoryModel) => {
    handleSendEvent({
      category: 'BasicSettings/Steel Grade Category',
      action: 'User Opened Delete Steel Grade Category Modal.',
    });

    setDeleteCategory(item);
  };

  const renderHeaders = () => {
    return (
      <TableRow>
        <TableCell
          width="150  "
        >
          {t('steel-grade-category.main-screen.table.column-title.category')}
        </TableCell>
        <TableCell align="left" width="150">
          {t('steel-grade')}
        </TableCell>
        <TableCell align="left" width="150"></TableCell>
      </TableRow>
  );
  };

  const renderLines = () => {

    const start = rowsPerPage * page;
    const end = start + rowsPerPage;

    return (
      <>
        {data.slice(start, end).map((row: SteelGradeCategoryModel) => (
          <TableRow key={row.name.toString()} data-testid={`steel-grade-list-item-${row.id}`}>
            <TableCell width="150">
              {row.name}
            </TableCell>
            <TableCell width="150">
              <EllipsedText maxDigits={25} lines={1}  text={row.steelGrades.map(steelGrade => steelGrade.name).join(", ")} />
            </TableCell>

            <TableCell align="right">
              <Box style={{ display: 'flex', gap: '16px', justifyContent: 'flex-end' }}>
                {
                  hasAccess([PERMISSIONS.STEEL_GRADE_EDIT]) && (
                    <EditButton
                      data-testid={`steel-grade-category-edit-button-${row.id}`}
                      onClick={() => {
                        handleSendEvent({
                          category: 'Edit/steelGrade',
                          action: 'User Clicked to Edit a Steel Grade Category.',
                        });
                        setEditCategory(row.id);
                      }}
                    />
                  )
                }
                {
                  hasAccess([PERMISSIONS.STEEL_GRADE_DELETE]) && (
                    <DeleteButton
                      data-testid={`steel-grade-category-delete-button-${row.id}`}
                      onClick={() => onClickDelete(row)}
                    />
                  )
                }
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const { elementContainerRef: tableContainerRef, scrollTop } = useScrollTop<HTMLDivElement>();

  return (
    <>
      <Paper style={{ maxHeight: '96%', overflow: 'auto' }}>
        <TableContainer style={{ maxHeight: 'calc(100vh - 267px)' }} innerRef={tableContainerRef}>
          <Table size="medium" stickyHeader>
            <TableHead>
              {renderHeaders()}
            </TableHead>

            <TableBody data-testid="steel-grade-list-table-body">
                {data.length ? (
                  renderLines()
                ) : (
                  <TableRow data-testid="empty-steel-grade-list-message">
                    <TableCell colSpan={12}>
                      <TableNoResults firstText='steel-grade-category.empty-table-first-text' secondText='steel-grade-category.empty-table-second-text' />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          data-testid="steel-grade.list.table-pagination"
          backIconButtonProps={{ ['data-testid']: 'steel-grade.list.table-pagination.back-button' } as Partial<IconButtonProps<"button", {}>>}
          nextIconButtonProps={{ ['data-testid']: 'steel-grade.list.table-pagination.next-button' } as Partial<IconButtonProps<"button", {}>>}
          SelectProps={{ ['data-testid']: 'steel-grade.list.table-pagination.number-of-items-per-page-selector' } as Partial<SelectProps>}
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            scrollTop();
          }}
          onRowsPerPageChange={e => {
            setPage(0);
            setRowsPerPage(Number(e.target.value || PAGINATION.ROWS_PER_PAGE_EXTENDED));
          }}
          labelRowsPerPage={t('rows-per-page')}
          backIconButtonText={t('page-previous')}
          nextIconButtonText={t('page-next')}
          style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.3)' }}
        />
      </Paper>
    </>
  );
};

export default SteelGradeTable;
