import { PlantsCreateRequest, usePlantsService } from '@app/api/plants';
import Loading from '@app/components/shared/Loading';
import MainPaper from '@app/components/shared/MainPaper';
import useLoadPlants from '@app/hooks/useLoadPlants';
import { ROUTES_PATH, handleSendEvent } from '@app/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import PlantManagementContent, { PlantManagementFormInputs } from './PlantManagementContent';
import FormButton from '@app/components/shared/FormButton';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { PlantsGetResponse, PlantStatus } from '@app/api/plants/PlantsGetResponse';

import { Box, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { PlantNavigationState } from '@app/pages/Overview/views/Overview';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import { PERMISSIONS } from '@app/utils/constants';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';

const PlantManagementContainer: React.FC = () => {
  const { state: routeFilter } = useLocation<PlantNavigationState>();
  const { t } = useTranslation();
  const history = useHistory();
  const { hasAccess, loadUserInfo } = useAuthenticationContext();
  const methods = useForm<PlantManagementFormInputs>({
    shouldFocusError: false,
    defaultValues: {
      search: '',
      customers: [],
      status: [],
      expired: false
    },
  });
  const { handleSubmit, setValue } = {...methods};

  const { createPlant, blockPlant } = usePlantsService();
  const [loadPlantsInfoObject, loadPlants] = useLoadPlants();
  const { data: plants, status: loadPlantsStatus } = loadPlantsInfoObject;
  
  const [loading, setLoading] = useState(false);
  const [openCreateNewPlantModal, setOpenCreateNewPlantModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [plantBlockModal, setPlantBlockModal] = useState<PlantsGetResponse | null>(null);
  const [filter, setFilter] = useState<PlantManagementFormInputs>({
    search: '',
    customers: [],
    status: [],
    expired: false,
  });

  useEffect(() => {
    if (loadPlantsStatus === 'idle') {
      loadPlants();
    }
  }, [loadPlants, loadPlantsStatus]);

  useEffect(() => {
    if (loadPlantsStatus === 'succeeded' && routeFilter && !routeFilter.alreadyUsed) {
      setValue('expired', routeFilter.expired);
      setValue('status', routeFilter.status);
      setFilter(currentFilter => ({
        ...currentFilter,
        expired: routeFilter.expired,
        status: routeFilter.status
      }));

      routeFilter.alreadyUsed = true;
    }
  }, [loadPlantsStatus, routeFilter, setValue, setFilter]);

  const handleConfirmBlockModal = (plant: PlantsGetResponse) => {
    handleSendEvent({
      category: 'Plants/AdminPanel',
      action: 'Plant Opened Block Plant Modal.',
    });
    setPlantBlockModal(plant);
    setOpenBlockModal(true);
  };

  const onCancelBlockUnblockModal = () => {
    handleSendEvent({
      category: 'Plants/AdminPanel',
      action: 'Plant Closed Delete Modal.',
    });
    setPlantBlockModal(null);
    setOpenBlockModal(false);
  }

  const onConfirmBlockUnblockModal = async (confirmationReason?: string) => {
    handleSendEvent({
      category: 'Plants/AdminPanel',
      action: 'Plants blocked.',
    });
     
    try {
      setLoading(true);

      if (confirmationReason) {
        await blockPlant(plantBlockModal?.id ?? "", !(plantBlockModal?.plantStatus === PlantStatus.BLOCKED), confirmationReason);
      }
      
      setPlantBlockModal(null);
      setOpenBlockModal(false);

      await loadPlants();
      loadUserInfo();
    } catch (e) {
      setPlantBlockModal(null);
      setOpenBlockModal(false);

      throw e;
    } finally {
      setLoading(false);
    }
  }

  const renderBlockPlantDialogContent = (): JSX.Element => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px', color: 'black'  }}>
        <Typography> {plantBlockModal?.plantStatus === PlantStatus.BLOCKED ? t('admin-panel.unblock-question') : t('admin-panel.block-question')}</Typography>
        <Typography><b>{t("admin-panel.plant")}</b>: {plantBlockModal?.name}</Typography>
        <Typography><b>{t("admin-panel.customer")}</b>: {plantBlockModal?.customerName}</Typography>
        <Typography>
          {t('admin-panel.plant.reason-modal')}
          {plantBlockModal?.plantStatus === PlantStatus.BLOCKED ? t('admin-panel.plant-management.details.activities-unblock-event') : t('admin-panel.plant-management.details.activities-block-event')}
        </Typography>
      </Box>
    )
  }

  const renderBlockPlantDialog = () => (
    <ConfirmDialog
      confirmationReasonEnabled
      fullWidth
      visible={openBlockModal}
      setVisible={setOpenBlockModal}  
      title={plantBlockModal?.plantStatus === PlantStatus.BLOCKED ? t('admin-panel.plant.unblock-modal.title') : t('admin-panel.plant.block-modal.title')}
      content={renderBlockPlantDialogContent()}
      cancelText={t('button.cancel')}
      cancelAction={onCancelBlockUnblockModal}
      confirmText={plantBlockModal?.plantStatus === PlantStatus.BLOCKED ? t('admin-panel.unblock') : t('admin-panel.block')}
      confirmAction={onConfirmBlockUnblockModal}
    />
  );  

  const handleCreatePlant = async (plant: PlantsCreateRequest) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Created New Plant.',
    });

    try {
      setLoading(true);
      const response = await createPlant(plant);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('admin-panel.create-plant.notification'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      history.push({
        pathname: ROUTES_PATH.USERS_PLANT_MANAGEMENT_DETAILS.replace(':id', response.id),
        state: { readOnly: false }
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<PlantManagementFormInputs> = (data, event) => {
    event?.preventDefault();

    if (event?.target.id !== 'plant-management-container-form') {
      return;
    }

    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Filtered the Plants.',
    });

    setFilter({...data, status: data.status});
  }

  const renderActions = (): JSX.Element[] => {
    const actions: JSX.Element[] = [];

    if (hasAccess([PERMISSIONS.PLANT_MANAGEMENT_CREATE])) {
      actions.push((
        <FormButton
          key="add-new-plant-button"
          variant="primary"
          type="button"
          onClick={() => {
            handleSendEvent({
              category: 'Users/AdminPanel',
              action: 'User Opened Create New Plant Modal.',
            });
            setOpenCreateNewPlantModal(true);
          }}>
          {t('admin-panel.add-plant').toUpperCase()}
        </FormButton>
      ));
    }

    return actions;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id="plant-management-container-form">
        <Loading dataTestId='plant-management-screen-circular-progress' promiseInProgress={loading || loadPlantsStatus === 'loading'} />
        <MainPaper
          removePadding
          title={t('admin-panel.plant-management')}
          headerActions={renderActions()}
        >
          <PlantManagementContent
            plants={plants}
            createPlant={handleCreatePlant}
            openCreateNewPlantModal={openCreateNewPlantModal}
            setOpenCreateNewPlantModal={setOpenCreateNewPlantModal}
            renderBlockPlantDialog={renderBlockPlantDialog}
            handleConfirmBlockModal={handleConfirmBlockModal}
            filter={filter}
          />
        </MainPaper>
      </form>
    </FormProvider>
  );
};

export default PlantManagementContainer;
