import Loading from '@app/components/shared/Loading';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, InputHTMLAttributes, ChangeEvent, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useSlagDeoxidizersService } from '@app/api/slagDeoxidizers';
import Swal from 'sweetalert2';
import { handleSendEvent } from '@app/utils';
import { slagDeoxidizerInitialValue } from '../additionsUtils';
import { DeoxidizersModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import useLoadSlagDeoxidizer from '@app/hooks/useLoadSlagDeoxidizer';
import FormValidationWarning from '@app/components/shared/FormValidationWarning';
import FormButton from '@app/components/shared/FormButton';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@app/config/yup-locale';
import { numberFormatOnChangeHandler } from '@app/utils/numberFormatOnChangeHandler';
import CompositionTable from '@app/components/shared/CompositionTable';

interface EditSlagDeoxidizerModalProps {
  open: boolean;
  onClose: () => void;
  onSubmitChange: () => void;
  id?: number;
}

const EditSlagDeoxidizerModal: React.FC<EditSlagDeoxidizerModalProps> = (props: EditSlagDeoxidizerModalProps) => {
  const { open, onClose, onSubmitChange, id } = props;
  const { t } = useTranslation();
  const { updateSlagDeoxidizer } = useSlagDeoxidizersService();

  const [slagDeoxidizerInfoObject, loadSlagDeoxidizer] = useLoadSlagDeoxidizer();

  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(),
    al: yup
      .number()
      .nullable()
      .transform((value) => Number.isNaN(value) ? 0 : value ),
    c: yup
      .number()
      .nullable()
      .transform((value) => Number.isNaN(value) ? 0 : value ),
    ca: yup
      .number()
      .nullable()
      .transform((value) => Number.isNaN(value) ? 0 : value ),
    si: yup
      .number()
      .nullable()
      .transform((value) => Number.isNaN(value) ? 0 : value ),
    siC: yup
      .number()
      .nullable()
      .transform((value) => Number.isNaN(value) ? 0 : value ),
    carbonDeoxEfficiency: yup
      .number()
      .required(),
    efficiencyFactor: yup
      .number()
      .typeError(t('slag-deoxidizers.text-field.error.efficiency-factor'))
      .min(1,t('slag-deoxidizers.text-field.zero-error')),

  });

  const methods = useForm<DeoxidizersModel>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {...slagDeoxidizerInitialValue},
  });

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty: formIsDirty }
  } = methods;

  const formValues = watch();

  const compositionIsInvalid = useMemo(() => {
    switch (formValues.name) {
      case 'Al': return (formValues.al || 0) === 0;
      case 'C': return (formValues.c || 0) === 0;
      case 'CaC2': return (formValues.c || 0) + (formValues.ca || 0) === 0;
      case 'FeSi': return formValues.si === 0;
      case 'SiC': return (formValues.si || 0) + (formValues.c || 0) === 0;
    }
  }, [formValues])

  const shouldCarbonDeoxidationEfficiencyBeDisabled = formValues.name === 'Al' || formValues.name === 'FeSi';

  const compositionsErrorMessage = () => {
    switch (formValues.name) {
      case 'Al': return t('slag-deoxidizers.composition-error.al');
      case 'C': return t('slag-deoxidizers.composition-error.c');
      case 'CaC2': return t('slag-deoxidizers.composition-error.cac2');
      case 'FeSi': return t('slag-deoxidizers.composition-error.fesi');
      case 'SiC': return t('slag-deoxidizers.composition-error.sic');
    }
  }

  const onSubmit = async (data: DeoxidizersModel) => {
    handleSendEvent({
      category: 'Additions/SlagDeoxidizers',
      action: 'User Saved the Slag Deoxidizer Changes.'
    })

    try {
      setLoading(true);

      if (id) {
        await updateSlagDeoxidizer(id, data);
      }

      onSubmitChange();

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      reset(slagDeoxidizerInitialValue);
      onClose();
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSendEvent({
      category: 'Additions/SlagDeoxidizers',
      action: 'User clicked in edit Slag Deoxidizer button',
    })
    if (id && slagDeoxidizerInfoObject.status === 'idle') {
      loadSlagDeoxidizer(id);
    }
  }, [id, slagDeoxidizerInfoObject.status, loadSlagDeoxidizer]);

  useEffect(() => {
    if(slagDeoxidizerInfoObject.status === 'succeeded' && slagDeoxidizerInfoObject.data)
      reset({
        id: slagDeoxidizerInfoObject.data.id,
        name: slagDeoxidizerInfoObject.data.name,
        externalId: slagDeoxidizerInfoObject.data.externalId,
        isEnabled: slagDeoxidizerInfoObject.data.isEnabled,
        si: slagDeoxidizerInfoObject.data.si,
        al: slagDeoxidizerInfoObject.data.al,
        ca: slagDeoxidizerInfoObject.data.ca,
        c: slagDeoxidizerInfoObject.data.c,
        carbonDeoxEfficiency: slagDeoxidizerInfoObject.data.carbonDeoxEfficiency,
        efficiencyFactor: slagDeoxidizerInfoObject.data.efficiencyFactor,
      });
  }, [slagDeoxidizerInfoObject.data, slagDeoxidizerInfoObject.status, reset])

  return (
    <>
      <Loading dataTestId='slag-deoxidizers-modal-circular-progress' promiseInProgress={loading || slagDeoxidizerInfoObject.status === 'loading'} />
      <Dialog
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="edit-slag-deoxidizer-modal">{t('slag-deoxidizers.edit')}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box paddingBottom={1} minWidth={400}>
              <Box paddingBottom={1}>
                <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
                  <Grid item sm={4} md={4} lg={4}>
                    <FormControl>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            id="slagDeoxidizerName"
                            label={t('name')}
                            title={t('name')}
                            inputProps={{ "data-testid": "edit-slag-deoxidizer-form.field.name" }}
                            disabled
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4} md={4} lg={4}>
                    {['Al', 'FeSi'].includes(formValues.name) ? (
                      <TextField
                        name="carbonDeoxidationEfficiency"
                        id="carbonDeoxidationEfficiency"
                        label={t('slag-deoxidizers.carbon-deoxidation-efficiency')}
                        title={t('slag-deoxidizers.carbon-deoxidation-efficiency')}
                        inputProps={{ "data-testid": "edit-slag-deoxidizer-form.field.carbon-deoxidation-efficiency" }}
                        disabled
                        value="-"
                      />
                    ) : (
                      <Controller
                        name="carbonDeoxEfficiency"
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            id="carbonDeoxidationEfficiency"
                            label={t('slag-deoxidizers.carbon-deoxidation-efficiency')}
                            title={t('slag-deoxidizers.carbon-deoxidation-efficiency')}
                            customInput={TextField}
                            decimalScale={2}
                            allowNegative={false}
                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                            decimalSeparator={t('decimal-scale-separator')}
                            autoComplete="off"
                            disabled={shouldCarbonDeoxidationEfficiencyBeDisabled}
                            error={!!errors?.carbonDeoxEfficiency}
                            helperText={!!errors?.carbonDeoxEfficiency && t('slag-deoxidizers.text-field.error.carbon-deoxidation-efficiency')}
                            inputProps={{ "data-testid": "edit-slag-deoxidizer-form.field.carbon-deoxidation-efficiency" }}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              numberFormatOnChangeHandler(e, field.onChange)
                            }
                          />
                        )}
                      />

                    )
                    }
                  </Grid>
                  <Grid item sm={4} md={4} lg={4}>
                    <Controller
                      name="efficiencyFactor"
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          id="efficiencyFactor"
                          label={t('slag-deoxidizers-efficiency-factor')}
                          title={t('slag-deoxidizers-efficiency-factor')}
                          customInput={TextField}
                          decimalScale={2}
                          allowNegative={false}
                          isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                          decimalSeparator={t('decimal-scale-separator')}
                          autoComplete="off"
                          error={!!errors?.efficiencyFactor}
                          helperText={errors?.efficiencyFactor?.message}
                          inputProps={{ "data-testid": "edit-slag-deoxidizer-form.field.efficiency-factor" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                          {...field}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            numberFormatOnChangeHandler(e, field.onChange)
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <FormControl>
                  <Controller
                    name="isEnabled"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                           <Switch
                            inputProps={
                              { "data-testid": "edit-slag-deoxidizer-form.field.enabled" } as InputHTMLAttributes<HTMLInputElement>
                            }
                            {...field}
                            checked={field.value}
                          />
                        }
                        label={formValues.isEnabled ? t('slag-deoxidizers-enabled') : t('slag-deoxidizers.text-field.title.disabled')}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box paddingTop={2}>
                <Grid container>
                  <FormProvider {...methods}>
                    <CompositionTable
                      testIdPrefix='edit-slag-deoxidizer-form'
                      unitOfMeasurement='%'
                      composition={[
                        { inputName: 'si', label: 'Si' },
                        { inputName: 'al', label: 'Al' },
                        { inputName: 'ca', label: 'Ca' },
                        { inputName: 'c', label: 'C' },
                      ]}
                    />
                  </FormProvider>
                </Grid>
              </Box>
              {
                compositionIsInvalid && formIsDirty &&
                <Grid item xs={12}>
                  <FormValidationWarning
                    message={compositionsErrorMessage() ?? ''}
                  />
                </Grid>
              }
            </Box>
          </DialogContent>

          <DialogActions>
            <FormButton
              onClick={() => {
                onClose();
                reset(slagDeoxidizerInitialValue)
              }}
              type='button'
              data-testid="edit-slag-deoxidizer-form.button.cancel"
              variant="secondary"
            >
              {t('button.cancel')}
            </FormButton>
            <FormButton
              type='submit'
              variant="primary"
              data-testid="edit-slag-deoxidizer-form.button.save"
              disabled={compositionIsInvalid || Object.keys(errors).length > 0}
            >
              {t('button.save')}
            </FormButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default EditSlagDeoxidizerModal;
