import { DeoxidizersModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { useApi } from '..';

export function useSlagDeoxidizersService() {
  const { get, put } = useApi();
  const baseApi = `/v2/slagDeoxidizers`;

  const getSlagDeoxidizers = async () => {
    const { data } = await get<DeoxidizersModel[]>(`${baseApi}`);

    return data;
  };

  const getSlagDeoxidizer = async (id: number) => {
    const { data } = await get<DeoxidizersModel>(`${baseApi}/${id}`);

    return data;
  };

  const updateSlagDeoxidizer = async (id: number, request: DeoxidizersModel) => {
    const { data } = await put<DeoxidizersModel>(`${baseApi}/${id}`, request);

    return data;
  };

  return {
    getSlagDeoxidizers,
    getSlagDeoxidizer,
    updateSlagDeoxidizer,
  };
}
