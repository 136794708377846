import FormSelect, { FormSelectOptions } from '@app/components/shared/FormSelect';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { ROLES, UserRole, Roles } from '../../userManagementUtils';
import FormButton from '@app/components/shared/FormButton';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@app/config/yup-locale';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { UserRoles } from '@app/utils/constants';
import FormAutocompleteWithTags from '@app/components/shared/FormAutocompleteWithTags';
import { useSystemOfMeasurementOptions } from '@app/hooks/useSystemOfMeasurementOptions';
import { PlantsGetResponse } from '@app/api/plants';
import { UsersGetResponse } from '@app/api/users';
import { useEffect } from 'react';
import StatusComponent from '@app/components/shared/StatusComponent';
import { InputLabel } from '@material-ui/core';

interface FormFieldsDTO {
  plants: string | string[],
  userProfileId: number,
  measurementSystem: SystemOfMeasurementOptions
}

interface UpdateuserModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (userId: number, userData: Partial<UsersGetResponse>) => void;
  plants: PlantsGetResponse[];
  user: UsersGetResponse | null;
}

export const UpdateUserModal = (props: UpdateuserModalProps) => {
  const { open, onClose, onConfirm, plants, user } = props;
  const { t } = useTranslation();

  const schema = yup.object().shape({
    userProfileId: yup.number().required(),
    plants: yup.mixed().when(
      "userProfileId",
      {
        is: (value: number) => {
          return [
            Roles.Admin.toString(),
            Roles.KeyUser.toString(),
            Roles.TechnicalConsultant.toString()
          ].includes(value.toString());
        },
        then: yup.array().of(yup.string().required()).min(1),
        otherwise: yup.string().required(),
      }
    ),
    measurementSystem: yup.string().required()
  });

  const {
    control, reset, setValue, handleSubmit, watch,
    formState: { isValid: formIsValid, errors }
  } = useForm<FormFieldsDTO>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      plants: [],
      userProfileId: -1,
      measurementSystem: SystemOfMeasurementOptions.Metric
    }
  });

  useEffect(() => {
    reset({
      plants: (
        user?.userProfileId === Roles.Admin
        || user?.userProfileId === Roles.KeyUser
        || user?.userProfileId === Roles.TechnicalConsultant
      ) ? user?.plants.map(plantId => plants.find(plant => plant.id === plantId)?.name) : user?.plants[0] ?? [],
      userProfileId: user?.userProfileId ?? -1,
      measurementSystem: user?.measurementSystem ?? SystemOfMeasurementOptions.Metric
    })
  }, [user, reset, plants]);

  const { state: { userInfoObject } } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const isPlantAdmin = userInfo?.userProfileName === UserRoles.PLANT_ADMIN;

  const roleIsDisabled = user?.userProfileId === Roles.Admin || user?.userProfileId === Roles.PlantAdmin;

  const getRoleDropdownOptions = () => {
    const availableRoles: UserRole[] = [];
    const employeesRoles = [Roles.KeyUser, Roles.TechnicalConsultant];
    const customersRoles = [Roles.PlantAdmin, Roles.Supervisor, Roles.Operator];
    const userToEditProfile = user?.userProfileId;

    if (roleIsDisabled) {
      availableRoles.push(...ROLES);
    } else if (employeesRoles.some(x => x === userToEditProfile)) {
      availableRoles.push(...ROLES.filter(x => employeesRoles.some(y => y === x.value)));
    } else if (customersRoles.some(x => x === userToEditProfile)) {
      availableRoles.push(...ROLES.filter(x => customersRoles.some(y => y === x.value)));
    }

    return availableRoles.map(r => ({ ...r, name: t(r.name) }));
  };

  const roleDropdownOptions = getRoleDropdownOptions();

  const plantsOptions: FormSelectOptions<string> = plants.map((plant) => ({ value: plant.id, name: plant.name }));

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormFieldsDTO> = (data, event) => {
    event?.preventDefault();

    if(event?.target.id !== 'update-user-form') {
      return;
    }

    onConfirm(
      user?.id ?? -1,
      {
        userProfileId: data.userProfileId,
        plants: Array.isArray(data.plants) ? data.plants.map(plantName => plants.find(plant => plantName === plant.name)?.id ?? '') : [data.plants],
        measurementSystem: data.measurementSystem
      }
    );
    reset();
    onClose();
  };

  const userRoleId = watch("userProfileId").toString();
  const userPlants = watch("plants");

  const { systemOfMeasurementOptions } = useSystemOfMeasurementOptions();

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="admin-panel.edit-user-title"
        aria-describedby="admin-panel.edit-user-description"
        maxWidth="xs"
        fullWidth
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', flexDirection: 'column' }}
          id="update-user-form"
        >
          <DialogTitle id="admin-panel.edit-user">{t('admin-panel.edit-user')}</DialogTitle>
          <DialogContent>
            <Box paddingBottom='12px'>
              <InputLabel><b>{t('name')}: </b>{user ? user.name : ''}</InputLabel>
            </Box>

            <Box paddingBottom='12px' paddingTop='8px'>
              <InputLabel><b>{t('e-mail')}: </b>{user ? user.email : ''}</InputLabel>
            </Box>

            <Box paddingBottom='12px' style={{ display: 'flex', gap: '4px', alignItems: "center" }}>
              <InputLabel style={{ paddingTop: '8px' }}><b>{t('admin-panel.status')}: </b></InputLabel>
              {user && <StatusComponent status={user.status}/>}
            </Box>

            <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <>
                <Controller
                  name="userProfileId"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      id="edit-user-role-select"
                      label={t('user.profile')}
                      options={roleDropdownOptions}
                      disabled={roleIsDisabled}
                      inputProps={{ ...field, value: field.value === -1 ? '' : field.value }}
                      error={!!errors.userProfileId?.message}
                      errorMessage={errors.userProfileId?.message}
                    />
                  )}
                />
                <Controller
                  name="measurementSystem"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      fullWidth
                      id='measurement-system'
                      label={t('user.profile.select.measurement-system.label')}
                      error={!!errors.measurementSystem?.message}
                      inputProps={{ ...field }}
                      options={systemOfMeasurementOptions}
                      errorMessage={errors.measurementSystem?.message} />
                  )} />
                { !isPlantAdmin && user && (
                  <Controller
                    name="plants"
                    control={control}
                    render={({ field }) => (
                      <>
                        {userRoleId !== Roles.Admin.toString() && userRoleId !== Roles.KeyUser.toString() && userRoleId !== Roles.TechnicalConsultant.toString() ?
                          (
                            <FormSelect
                              disabled={userRoleId === ''}
                              id="user-invite-plant-select"
                              label={t('admin-panel.plant')}
                              options={plantsOptions}
                              inputProps={{ ...field }}
                            />
                          ) : (
                            <FormAutocompleteWithTags
                              multiple
                              label={t('admin-panel.plant')}
                              id="checkboxes-tags-autocomplete"
                              options={plantsOptions.map(item => item.name)}
                              disableCloseOnSelect
                              limitTags={undefined}
                              {...field}
                              value={Array.isArray(field.value) ? field.value : [field.value] as string[]}
                              onChange={(
                                _,
                                value: string[]
                              ) => {
                                setValue("plants", value || []);
                                field.onBlur();
                              }}
                              handleCheckboxChange={(option: string, checked: boolean) => {
                                const tempValues = [...userPlants as string[]];

                                if (checked) {
                                  setValue("plants", [...tempValues, option]);
                                  field.onBlur();
                                } else {
                                  setValue("plants", tempValues?.filter((item) => item !== option) ?? []);
                                  field.onBlur();
                                }
                              }}
                            />
                          )}
                      </>
                    )}
                  />
                )}                
              </>
            </Box>
          </DialogContent>

          <DialogActions>
            <FormButton type="button" onClick={handleClose} variant="secondary">
              {t('button.cancel')}
            </FormButton>
            <FormButton type='submit' variant="primary" disabled={!formIsValid}>
              {t('button.save')}
            </FormButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
