import { Tooltip, withStyles } from "@material-ui/core";


const CustomTooltip = withStyles(theme => ({
  tooltip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    textAlign: 'center',
    fontFamily: ['Mulish', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "13px"
  }
}))(Tooltip);

export default CustomTooltip
