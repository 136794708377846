import { GetLadleFurnaceResponse, Tap } from "@app/api/ladleFurnaces";
import { DeleteButton } from "@app/components/shared/CustomIconButton";
import {
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Fab
 } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/styles";
import { useState, createRef } from "react";
import { useTranslation } from "react-i18next";
import { ladleFurnaceInitialValue } from "../equipmentUtils";
import NumberFormat from 'react-number-format';
import FormValidationWarning from "@app/components/shared/FormValidationWarning";
import FormButton from "@app/components/shared/FormButton";
import useCompositionTableCellStyles from "@app/hooks/useCompositionTableStyles";
import { useAuthenticationContext } from "@app/store/authentication/authenticationContext";
import { SystemOfMeasurementOptions } from "@app/api/me/MeGetResponse";
import { convertMetersIntoOtherUnitOfMeasurement, limitDecimalPlaces } from "@app/utils/convertLengthUnits";
import useRenderInputEndAdornment from "@app/hooks/useRenderInputEndAdornment";
import { UOM_CENTIMETERS_SYMBOL } from "@app/utils/constants";
import FormRadioButton from "@app/components/shared/FormRadioButton";
import WarningMessageComponent from "@app/components/shared/WarningMessageComponent";

interface AddLadleFurnaceModalProps {
    isEdit?: boolean,
    onClose: () => void,
    onSubmit: (request: GetLadleFurnaceResponse) => void,
    targetLadleFurnace?: GetLadleFurnaceResponse
}


const useStyles = makeStyles(() =>
    createStyles({
        contentText: {
            fontWeight: 'bold',
            marginTop: '22px',
            marginBottom: '17px'
        },
        cancelBtn: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#CFCFCF',
        }
    })
);


const AddLadleFurnaceModal = (props: AddLadleFurnaceModalProps) => {

    const tapListRef = createRef<HTMLTableRowElement>();

    const containerClasses = useStyles();
    const { t } = useTranslation();

    const { isEdit, onClose, onSubmit, targetLadleFurnace } = props;

    const ladleFurnaceInitialState = targetLadleFurnace === undefined ? {
            ...ladleFurnaceInitialValue
        } : {
            ...targetLadleFurnace
        };

    const { state: { userInfoObject } } = useAuthenticationContext();
    const measurementSystem = userInfoObject?.data?.measurementSystem;

    const [ladleFurnace, setLadleFurnace] = useState<GetLadleFurnaceResponse>(
        measurementSystem === SystemOfMeasurementOptions.Imperial
        ? {...ladleFurnaceInitialState} : {
            ...ladleFurnaceInitialState,
            tapLadleFurnaces:  [
                ...ladleFurnaceInitialState.tapLadleFurnaces.map((tlf) => ({
                    ...tlf, arcLength: limitDecimalPlaces(convertMetersIntoOtherUnitOfMeasurement(tlf.arcLength, UOM_CENTIMETERS_SYMBOL), 2)
                }))
            ]
    });

    const hasEmptyField = ladleFurnace.tapLadleFurnaces.some((tlf) => isNaN(tlf.code) || isNaN(tlf.arcLength));
    const hasDefaultTap = ladleFurnace.tapLadleFurnaces.some((tlf) => tlf.default);
    const hasUniqueTaps = () => {
        const tapIndexes = ladleFurnace.tapLadleFurnaces.map(i => i.code);
        return new Set(tapIndexes).size === tapIndexes.length;
    }

    const handleClose = () => {
        onClose();
    };

    const handleChangeInLadleFurnace = <K extends keyof GetLadleFurnaceResponse>(field: K, value?: GetLadleFurnaceResponse[K]) => {
        const newLadleFurnace = {...ladleFurnace};
        newLadleFurnace[field] = value as GetLadleFurnaceResponse[K];
        setLadleFurnace(newLadleFurnace);
    };

    const handleChangeInTap = <K extends keyof Tap>(tapIndex: number, field: K, value?: Tap[K]) => {
        setLadleFurnace(prevState => ({
            ...prevState,
            tapLadleFurnaces: prevState.tapLadleFurnaces.map((item, index) => {
                if(index === tapIndex) {
                    const newTap: Tap = {...item, [field]: value};
                    return newTap
                }   else {
                    return item
                }
            })
        }));
    };

    const handleRadioChange = (index: number) => {
        setLadleFurnace(prevState => ({...prevState, tapLadleFurnaces: [
            ...prevState.tapLadleFurnaces.map((prevTap, tapIndex) => ({
                ...prevTap,
                default: tapIndex === index
            }))
        ]}));
    };

    const removeTapFromList = (index: number) => {
        setLadleFurnace(prevState => ({
            ...prevState,
            tapLadleFurnaces: prevState.tapLadleFurnaces.filter((_, tapIndex) => tapIndex !== index)
        }));
    };

    const scrollToTapListBottom = () => {
        setTimeout(() => {
            tapListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100)
    }

    const addNewTap = () => {
        setLadleFurnace(prevState => {
            return({
                ...prevState,
                tapLadleFurnaces: [
                    ...prevState.tapLadleFurnaces,
                    {...ladleFurnaceInitialValue.tapLadleFurnaces[0]}
                ]
            })
        });
        scrollToTapListBottom();
    };

    const hasTapLadleFurnaceWithInvalidArcLength = ladleFurnace.tapLadleFurnaces.some(tapLadleFurnace => tapLadleFurnace.arcLength === 0);

    const { renderLengthInputEndAdornment } = useRenderInputEndAdornment();

    const renderModalContent = () => {

        return (
            <>
                {ladleFurnace.tapLadleFurnaces.map((row: Tap, i: number) => (
                    <TableRow key={i} ref={tapListRef} data-testid={`create-edit-ladle-furnace-form.tap-item-${i}`}>
                        <TableCell className={classes.root}>
                            <FormRadioButton
                                data-testid={`create-edit-ladle-furnace-form.tap-item-${i}.default-tap.radio`}
                                onChange={() => handleRadioChange(i)}
                                checked={row.default}
                            />
                        </TableCell>
                        <TableCell className={classes.root}>
                            <NumberFormat
                                name="tapCode"
                                id="tapCode"
                                inputProps={{ "data-testid": `create-edit-ladle-furnace-form.tap-item-${i}.tap-code.input` }}
                                InputProps={{ className: classes.input }}
                                value={ladleFurnace.tapLadleFurnaces[i].code}
                                autoComplete="off"
                                fullWidth
                                customInput={TextField}
                                allowNegative={false}
                                decimalScale={0}
                                isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                                onValueChange={e => handleChangeInTap(i, 'code', e.floatValue)}
                                error={isNaN(ladleFurnace.tapLadleFurnaces[i].code)}
                                helperText={isNaN(ladleFurnace.tapLadleFurnaces[i].code) && t('ladle-furnace.text-field.error.empty-field')}
                            />
                        </TableCell>
                        <TableCell className={classes.root}>
                            <NumberFormat
                                name="arcLength"
                                id="arcLength"
                                inputProps={{ "data-testid": `create-edit-ladle-furnace-form.tap-item-${i}.arc-length.input` }}
                                value={ladleFurnace.tapLadleFurnaces[i].arcLength}
                                autoComplete="off"
                                fullWidth
                                customInput={TextField}
                                allowNegative={false}
                                decimalScale={2}
                                decimalSeparator={t('decimal-scale-separator')}
                                isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                                onValueChange={e => handleChangeInTap(i, 'arcLength', e.floatValue)}
                                error={isNaN(ladleFurnace.tapLadleFurnaces[i].arcLength)}
                                helperText={isNaN(ladleFurnace.tapLadleFurnaces[i].arcLength) && t('ladle-furnace.text-field.error.empty-field')}
                                InputProps={{
                                    endAdornment: renderLengthInputEndAdornment(measurementSystem, UOM_CENTIMETERS_SYMBOL),
                                    className: classes.input
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <DeleteButton
                                onClick={() => removeTapFromList(i)}
                                disabled={ladleFurnace.tapLadleFurnaces.length === 1}
                                data-testid={`create-edit-ladle-furnace-form.tap-item-${i}.delete.button`}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </>
        );
    };

    const classes = useCompositionTableCellStyles().useStyles()();

    return (
        <Dialog  style={{ maxHeight: 'min(90vh, 700px)', maxWidth: '1000px', margin: 'auto' }} fullWidth maxWidth="xl" open onClose={handleClose}>
            <DialogTitle id="form-dialog-title" >{isEdit === false ? t('equipment.ladle-furnace.modal.title') : t('equipment.ladle-furnace.modal.edit-title')}</DialogTitle>
            <DialogContent data-testid="create-or-edit-ladle-furnace-modal-content">
                <TextField
                    id="name"
                    inputProps={{ "data-testid": "create-edit-ladle-furnace-form.field.name" }}
                    InputLabelProps={{shrink: true}}
                    label={t('name')}
                    value={ladleFurnace.name}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChangeInLadleFurnace('name', e.target.value)}
                    autoComplete="off"
                    fullWidth
                />
                {
                    ladleFurnace.name === '' &&
                    <WarningMessageComponent message={t('ladle-furnace.text-field.tip-text')} />
                }
                <DialogContentText className={containerClasses.contentText}>
                    {t('equipment.ladle-furnace.modal.description')}
                </DialogContentText>

                <Grid container>
                    <TableContainer>
                        <Table size='medium' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={`${classes.root} ${classes.head}`}>
                                        {t('ladle-furnace.default-tap')}
                                    </TableCell>
                                    <TableCell className={`${classes.root} ${classes.head}`}>
                                        {t('tap')}
                                    </TableCell>
                                    <TableCell className={`${classes.root} ${classes.head}`}>
                                        {t('arc-length')}
                                    </TableCell>
                                    <TableCell>
                                        {''}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody data-testid="create-edit-ladle-furnace-form.tap-list" >
                                {renderModalContent()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        !hasUniqueTaps() &&
                        <Grid item xs={12}>
                            <FormValidationWarning message={t('ladle-furnace.text-field.error.unitque-tap-code')} />
                        </Grid>
                    }
                    {
                        hasTapLadleFurnaceWithInvalidArcLength && (
                            <Grid item xs={12}>
                                <FormValidationWarning message={t('ladle-furnace.text-field.error.invalid-arc-length')} />
                            </Grid>
                        )
                    }
                    {
                        !hasDefaultTap &&
                        <Grid item xs={12}>
                            <FormValidationWarning message={t('ladle-furnace.text-field.error.missing-default-tap')} />
                        </Grid>
                    }
                    <Grid item xs={12} style={{ display: 'flex', justifyContent:'center', padding: '13px 0px' }} >
                        <Fab
                            data-testid="create-edit-ladle-furnace-form.add-tap.button"
                            color="primary"
                            type="button"
                            size="small"
                            aria-label="add"
                            onClick={addNewTap}
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <FormButton
                    data-testid="create-edit-ladle-furnace-form.button.cancel"
                    onClick={handleClose}
                    variant="secondary"
                >
                    {t('button.cancel')}
                </FormButton>
                <FormButton
                    data-testid="create-edit-ladle-furnace-form.button.save"
                    variant="primary"
                    disabled={
                        ladleFurnace.name === ''
                        || !hasDefaultTap
                        || !hasUniqueTaps()
                        || hasEmptyField
                        || hasTapLadleFurnaceWithInvalidArcLength
                    }
                    onClick={() => onSubmit(ladleFurnace)}
                >
                    {isEdit === false ? t('button.add') : t('button.save')}
                </FormButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddLadleFurnaceModal;
