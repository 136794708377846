import { useHeatsService } from '@app/api/heats';
import { DuplicateHeatRequestPayload } from '@app/api/heats/HeatsResponse';
import { CalculationMode } from '@app/utils/calculationModeOptions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HooksStatus } from "./types"

const useDuplicateHeatAndRedirectToEditPage = (): [
  HooksStatus,
  (copyHeatData: DuplicateHeatRequestPayload, isDraft?: boolean) => Promise<void>,
] => {
  const initialState: HooksStatus = 'idle';

  const [status, setStatus] = useState<HooksStatus>(initialState);

  const { duplicateDraftHeat, duplicateHeat } = useHeatsService();

  const { t } = useTranslation();

  const history = useHistory();

  const copyHeatAndRedirectToEdit = async (
    copyHeatData: DuplicateHeatRequestPayload,
    isDraft?: boolean,
  ) => {
    try {
      setStatus("loading");

      const data = isDraft ? await duplicateDraftHeat(copyHeatData) : await duplicateHeat(copyHeatData);

      const isTappingMode = data.input.details.calculationMode === CalculationMode.Tapping;

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: t('saving-success.message'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      const redirectUrl =
        `/app/heat-calculation/${
          isTappingMode
          ? 'tapping'
          : 'ladle-furnace'
        }/draft/edit`;

      setStatus("succeeded");
      history.push(`${redirectUrl}/${data.output.details.heatId}`);
    } catch (err) {
      setStatus("error");
    }
  };

  return [status, copyHeatAndRedirectToEdit];
}

export default useDuplicateHeatAndRedirectToEditPage;
