import { CalculationDiagnostic } from '@app/api/heats/HeatsResponse';
import FormButton from '@app/components/shared/FormButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, createStyles, makeStyles } from '@material-ui/core';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CalculationDiagnosticsItem, { useCardStyles } from './CalculationDiagnosticsItem';
import TableNoResults from '@app/components/shared/TableNoResults';
import CustomTooltip from '@app/components/shared/CustomTooltip';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme =>
  createStyles({
    helperText: {
      color: theme.palette.custom.grey1.main,
    },
  }),
);

interface SearchableCalculationDiagnostic extends CalculationDiagnostic {
  index: number;
  searchableCode: string;
  searchableMessage: string;
  searchableAdditionalInfo: string;
}

interface CalculationDiagnosticsModalProps {
  diagnostics: CalculationDiagnostic[];
  searchKeywords?: string[];
  onClose: () => void;
}

const convertDiagnosticsToSearchableList = (diagnostics: CalculationDiagnostic[]): SearchableCalculationDiagnostic[] => {
  return diagnostics.map((diagnostic, index) => ({
    ...diagnostic,
    index: index,
    searchableCode: diagnostic.code.toLowerCase(),
    searchableMessage: diagnostic.message.toLowerCase(),
    searchableAdditionalInfo: JSON.stringify(diagnostic.additionalInfo).toLowerCase(),
  }))
}

const CalculationDiagnosticsModal = (props: CalculationDiagnosticsModalProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const { diagnostics, searchKeywords, onClose } = props;
  const [searchText, setSearchText] = useState('');
  const [searchableList, setSearchableList] = useState<SearchableCalculationDiagnostic[]>([]);

  useEffect(() => {
    setSearchableList(convertDiagnosticsToSearchableList(diagnostics));
    
    if (searchKeywords) {
      setSearchText(searchKeywords.join(' '));
    }
  }, [diagnostics, searchKeywords]);

  const filteredDiagnostics = useMemo(() => {
    if (!searchText) {
      return searchableList;
    }

    const auxiliarySearchText = searchText.trim().toLowerCase();
    const searchKeywords = auxiliarySearchText.split(' ');

    return searchableList.filter(diagnostic => 
      searchKeywords.every(keyword => diagnostic.searchableCode.includes(keyword) ||
      diagnostic.searchableMessage.includes(keyword) ||
      diagnostic.searchableAdditionalInfo.includes(keyword)));
  }, [searchText, searchableList]);

  const handleClearSearch = () => setSearchText('');

  const renderSearchAdornment = (): JSX.Element => {
    if (searchText) {
      const tooltipText = t('button.clear');

      return (
        <IconButton onClick={handleClearSearch}>
          <CustomTooltip title={tooltipText}>
            <ClearIcon fontSize="small" color="disabled" />
          </CustomTooltip>
        </IconButton>
      )
    }

    return (
      <SearchIcon fontSize="small" color="disabled" />
    )
  }

  const renderSearchInput = (): JSX.Element => {
    return (
      <Box style={{ width: '40%' }}>
        <TextField
          autoComplete="off"
          style={{ fontWeight: 'bolder', width: '150w' }}
          fullWidth
          placeholder={t('filter.search')}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          InputProps={{ 
            endAdornment: renderSearchAdornment()
          }}
        />
      </Box>
    );
  }

  const renderSupportInformation = (): JSX.Element => {
    if (filteredDiagnostics.length === 0) {
      return (
        <Card className={cardClasses.cardSize} variant='outlined'>
          <CardContent className={cardClasses.cardContent}>
            <TableNoResults firstText={t('table.no-results.text1', { search: searchText })}>
              <Box display='flex' flexDirection='column'>
                <Typography>
                  {t('calculation-diagnostics.modal.search.no-results')}
                </Typography>
                <FormButton
                  onClick={handleClearSearch}
                  variant='link' 
                  size='small' >
                  {t('button.clear')}
                </FormButton>
              </Box>
            </TableNoResults>
          </CardContent>
        </Card>
      );
    } else if (searchText && searchableList.length !== filteredDiagnostics.length) {
      return (
        <Box display='flex' alignItems='center' justifyContent='space-between' gridGap='50px' paddingBottom='14px'>
          <Typography variant='body2' className={classes.helperText}>
            {t('calculation-diagnostics.modal.search.filtered')}
          </Typography>
          <FormButton
            onClick={handleClearSearch}
            variant='link' 
            size='small' >
            {t('button.clear')}
          </FormButton>
        </Box>
      );
    } else {
      return (
        <></>
      );
    }
  }

  const renderDisclaimerAndCloseButton = (): JSX.Element => {
    return (
      <>
        <Box display='flex' alignItems='center' gridGap='12px' width='70vh'>
          <InfoOutlined />
          <Typography variant='subtitle2'>
            {t('calculation-diagnostics.modal.disclaimer')}
          </Typography>
        </Box>
        <FormButton
          onClick={onClose}
          variant='secondary' >
          {t('button.close')}
        </FormButton>
      </>
    );
  }

  return (
    <Dialog open maxWidth='xl'>
      <DialogTitle>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {t('calculation-diagnostics.modal.title')}
          {renderSearchInput()}
        </Box>
      </DialogTitle>

      <DialogContent style={{ overflowX: 'hidden' }}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1, width: '90vh' }}>
          {renderSupportInformation()}
          {filteredDiagnostics.map((item) => (
            <CalculationDiagnosticsItem key={item.index} diagnostic={item} />
          ))}
        </Box>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-between' }}>        
        {renderDisclaimerAndCloseButton()}
      </DialogActions>      
    </Dialog>
  );
}

export default CalculationDiagnosticsModal;