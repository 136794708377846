import { useApi } from '..';
import { BaseLadleFurnaceRequest, GetLadleFurnaceResponse } from './BaseLadleFurnaceRequest';

export function useLadleFurnacesService() {
  const { get, remove, put, post } = useApi();
  const baseApi = `/v2/ladleFurnaces`;

  const getLadleFurnaces = async () => {
    const { data } = await get<GetLadleFurnaceResponse[]>(`${baseApi}`);

    return data?.sort((ladle1,ladle2) => {
      return (ladle1.name.toLowerCase() < ladle2.name.toLocaleLowerCase()) ? -1 : 1;
    });
  };

  const getLadleFurnace = async (id: number) => {
    const { data } = await get<GetLadleFurnaceResponse>(`${baseApi}/${id}`);

    return data;
  };

  const deleteLadleFurnace = async (id: number) => {
    const { data } = await remove(`${baseApi}/${id}`);

    return data;
  };

  const updateLadleFurnace = async (id: number, request: BaseLadleFurnaceRequest) => {
    const { data } = await put(`${baseApi}/${id}`, request);

    return data;
  };

  const saveLadleFurnace = async (request: BaseLadleFurnaceRequest) => {
    const { data } = await post(`${baseApi}`, request);

    return data;
  };

  return {
    getLadleFurnaces,
    getLadleFurnace,
    deleteLadleFurnace,
    updateLadleFurnace,
    saveLadleFurnace,
  };
}
