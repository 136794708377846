import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
    subTitle: {
      fontWeight: 'bold',
      color: theme.palette.custom.grey2.main,
    }
  }),
);

interface PageTitleProps {
  title: string;
  subtitle?: string;
}

const PageTitle = ({ title, subtitle }: PageTitleProps) => {
  const classes = useStyles();
  return (
    <Box style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
      <Typography variant="h5" className={classes.title} color="textSecondary">
        {title}
      </Typography>
      {subtitle && (
        <>
          <Typography variant="h5" className={classes.subTitle}>
            |
          </Typography>
          <Typography variant="h5" className={classes.subTitle}>
            {subtitle}
          </Typography>
        </>
      )}

    </Box>
  );
};

export default PageTitle;
