import { CarryoverSlagReferenceCreateRequest } from '@app/api/carryoverSlagReferences';
import CarryoverSlagUnitOfMeasurement, { UNITS_OF_MEASUREMENT } from '@app/model/CarryoverSlagUnitOfMeasurement.model';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { APP_NA2O_K2O_ENABLED, MIN_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL, MAX_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL } from '@app/utils/constants';
import FormValidationWarning from '@app/components/shared/FormValidationWarning';
import { CarryoverSlagInferenceType } from '@app/api/plants/PlantUpdateCarryoverSlagSettingsRequest';
import FormSelect, { FormSelectOptions } from '@app/components/shared/FormSelect';
import FormButton from '@app/components/shared/FormButton';
import CompositionTable from '@app/components/shared/CompositionTable';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@app/config/yup-locale';
import { numberFormatOnChangeHandler } from '@app/utils/numberFormatOnChangeHandler';
import { defaultCompositionTableSchemaObjectShape } from '@app/utils/defaultCompositionTableSchemaObjectShape';

interface CreateEditCarryoverSlagsModalProps<T> {
  open: boolean;
  onClose: () => void;
  onConfirm: (carryoverSlag: CarryoverSlagReferenceCreateRequest) => void;
  initialValue: CarryoverSlagReferenceCreateRequest;
  steelGradeCategoriesOptions: FormSelectOptions<T>;
  edit?: number;
}

const CreateEditCarryoverSlagsModal = <T extends number | boolean | string>(props: CreateEditCarryoverSlagsModalProps<T>) => {
  const { open, onClose, onConfirm, initialValue, edit = 0, steelGradeCategoriesOptions } = props;
  const { t } = useTranslation();

  const {
    state: { userInfoObject },
  } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const returnInfoObjectShape = () => {
    const commonSchema = {
      ...defaultCompositionTableSchemaObjectShape,
      maxValue: yup.number().nullable(),
      minValue: yup.number().nullable(),
      value: yup.number().nullable(),
      steelGradeCategoryId: yup.number().nullable(),
      steelGradeCategoryName: yup.string().nullable(),
    };

    switch(userInfo?.selectedPlant.carryoverSlagInferenceType) {
      case CarryoverSlagInferenceType.Range:
        return  yup.object().shape({
          ...commonSchema,
          maxValue: yup.number(),
          minValue: yup
            .number()
            .lessThan(yup.ref('maxValue'))
        });
      case CarryoverSlagInferenceType.SingleValue:
        return yup.object().shape({
          ...commonSchema,
          value: yup.number(),
        })
      case CarryoverSlagInferenceType.SteelGradeCategory:
        return yup.object().shape({
          ...commonSchema,
          steelGradeCategoryId: yup.number(),
          steelGradeCategoryName: yup.string().nullable(),
        })
      default:
        return yup.object().shape(commonSchema);
    }
  }

  const schema = returnInfoObjectShape();

  const methods = useForm<CarryoverSlagReferenceCreateRequest>({
    resolver: yupResolver(schema),
    mode: 'all',
    shouldFocusError: true,
    defaultValues: initialValue
  });

  const {
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isDirty: formIsDirty }
   } = methods;

   useEffect(() => {
    reset(initialValue)
   }, [reset, initialValue]);

   useEffect(() => {
    if(!edit && open) {
      reset();
    }
   }, [edit, open, reset, initialValue])

  const parameterValueName = userInfo?.selectedPlant?.carryoverSlagParameterValueName;
  const unitOfMeasurement = userInfo?.selectedPlant?.carryoverSlagUnitOfMeasurement;

  const referenceInputDecimalPlaces = 2;
  const referenceInputLimit = unitOfMeasurement === CarryoverSlagUnitOfMeasurement.Percent ? 1 : 1000;

  const TITLE = !edit
    ? t('carryover-slag.title.add')
    : t('carryover-slag.title.edit')

  const formValues = watch();

  const total = useMemo(() => {
      return [
        formValues.mgO,
        formValues.caO,
        formValues.al2O3,
        formValues.siO2,
        formValues.feO,
        formValues.mnO,
        formValues.cr2O3,
        formValues.tiO2,
        formValues.caF2,
        ...APP_NA2O_K2O_ENABLED ? [
          formValues.na2O,
          formValues.k2O,
        ] : []
      ].reduce((acc, value) =>  acc + Number(value || 0), 0);
    }, [formValues]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit: SubmitHandler<CarryoverSlagReferenceCreateRequest> = (data: CarryoverSlagReferenceCreateRequest) => {
    onConfirm(data);
  };

  const totalError = useMemo(() => {
    return Number(total) > 105 || Number(total) < 70;
  }, [total]);

  return (
    <Dialog
      open={open}
      aria-labelledby="add-edit-general-settings-title"
      aria-describedby="add-edit-general-settings-description"
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="add-edit-general-settings">{TITLE}</DialogTitle>
        <DialogContent data-testid="create-or-edit-carryoverslag-modal-content">
          <Box paddingBottom={3} minWidth={500}>
            <Grid container>
              <Grid container item xs={12} style={{ paddingBottom: 24 }}>
                {userInfo?.selectedPlant.carryoverSlagInferenceType === CarryoverSlagInferenceType.SingleValue && (
                  <Controller
                    name='value'
                    control={control}
                    render={({ field }) => (
                      <NumberFormat
                        autoComplete="off"
                        fullWidth
                        label={t('carryover-slag.refence-value', { ref: parameterValueName })}
                        title={t('carryover-slag.text-field.title.target', { ref: parameterValueName })}
                        customInput={TextField}
                        allowNegative={false}
                        decimalSeparator={t('decimal-scale-separator')}
                        decimalScale={referenceInputDecimalPlaces}
                        InputProps={unitOfMeasurement === CarryoverSlagUnitOfMeasurement.Percent ? {
                          endAdornment: <InputAdornment position="end">{t(UNITS_OF_MEASUREMENT[CarryoverSlagUnitOfMeasurement.Percent])}</InputAdornment>
                        } : {}}
                        inputProps={{ 'data-testid': 'create-edit-carryoverslag-form.field.value' }}
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          numberFormatOnChangeHandler(e, field.onChange)
                      }
                        isAllowed={({ floatValue }) => (floatValue ?? 0) <= referenceInputLimit}
                      />
                    )}
                  />

                )}
                {userInfo?.selectedPlant.carryoverSlagInferenceType === CarryoverSlagInferenceType.Range && (
                  <>
                    <Grid item sm={6} md={6} lg={6} style={{ paddingRight: 16 }}>
                      <Controller
                        name="minValue"
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            autoComplete="off"
                            fullWidth
                            label={t('carryover-slag.text-field.label.minValue', { ref: parameterValueName })}
                            title={t('carryover-slag.text-field.title.minValue', { ref: parameterValueName })}
                            customInput={TextField}
                            allowNegative={false}
                            decimalSeparator={t('decimal-scale-separator')}
                            decimalScale={referenceInputDecimalPlaces}
                            InputProps={unitOfMeasurement === CarryoverSlagUnitOfMeasurement.Percent ? {
                              endAdornment: <InputAdornment position="end">{t(UNITS_OF_MEASUREMENT[CarryoverSlagUnitOfMeasurement.Percent])}</InputAdornment>
                            } : {}}
                            inputProps={{ 'data-testid': 'create-edit-carryoverslag-form.field.minValue' }}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                numberFormatOnChangeHandler(e, field.onChange)
                            }
                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= referenceInputLimit}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                      <Controller
                        name='maxValue'
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            autoComplete="off"
                            fullWidth
                            label={t('carryover-slag.text-field.label.maxValue', { ref: parameterValueName })}
                            title={t('carryover-slag.text-field.title.maxValue', { ref: parameterValueName })}
                            customInput={TextField}
                            allowNegative={false}
                            decimalSeparator={t('decimal-scale-separator')}
                            decimalScale={referenceInputDecimalPlaces}
                            InputProps={
                              unitOfMeasurement === CarryoverSlagUnitOfMeasurement.Percent ? {
                                endAdornment: <InputAdornment position="end">{t(UNITS_OF_MEASUREMENT[CarryoverSlagUnitOfMeasurement.Percent])}</InputAdornment>
                              } : {}
                            }
                            inputProps={{ 'data-testid': 'create-edit-carryoverslag-form.field.maxValue' }}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                numberFormatOnChangeHandler(e, field.onChange)
                            }
                            isAllowed={({ floatValue }) => (floatValue ?? 0) <= referenceInputLimit}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {userInfo?.selectedPlant.carryoverSlagInferenceType === CarryoverSlagInferenceType.SteelGradeCategory && (
                  <Controller
                    name='steelGradeCategoryId'
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        fullWidth
                        id="create-edit-carryoverslag-form.field.reference-category-value"
                        inputProps={{ "data-testid": "create-edit-carryoverslag-form.field.reference-category-value" }}
                        label={t('carryover-slag.refence-value', { ref: t(parameterValueName as string) })}
                        options={steelGradeCategoriesOptions}
                        {...field}
                        value={field.value ?? ''}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container>
              <FormProvider {...methods}>
                <CompositionTable
                  unitOfMeasurement="%"
                  total={total}
                  testIdPrefix='create-edit-carryoverslag-form'
                />
              </FormProvider>
            </Grid>
            {
              totalError && formIsDirty &&
              <Grid item xs={12} data-testid="carryoverslags-form.total-composition.error-component">
                <FormValidationWarning message={t('form.error.total', {min: MIN_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL, max: MAX_FLUX_CARRYOVER_SLAG_CHEMISTRY_TOTAL})} />
              </Grid>
            }
          </Box>
        </DialogContent>

        <DialogActions>
          <FormButton
            type='button'
            onClick={handleClose}
            variant="secondary"
            data-testid="create-edit-ladle-furnace-form.button.cancel"
          >
            {t('button.cancel')}
          </FormButton>
          <FormButton
            type='submit'
            variant="primary"
            disabled={totalError || Object.keys(errors).length > 0}
            data-testid="create-edit-ladle-furnace-form.button.save"
          >
            {t('button.save')}
          </FormButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateEditCarryoverSlagsModal;
