import ReactGA from 'react-ga4';
import { APP_GOOGLE_ANALYTICS_ENABLED } from "../utils/constants";
import { useLocation } from 'react-router-dom';
import React, { useEffect } from "react";


const RouteChangeTracker =  () => {
  const trackPage = (page: string, title: string) => {
    if(APP_GOOGLE_ANALYTICS_ENABLED) {
      ReactGA.set({ page });
      ReactGA.send({ hitType: "pageview", page: page, title: title });
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    trackPage(pathname, document.title);
  }, [pathname])

  return <></>
}

export default RouteChangeTracker


