import { FluxAvailability } from "@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes";

const isAvailableAtTapping = (availability: string) =>
                                availability === FluxAvailability.Tapping || availability === FluxAvailability.TappingAndLadleFurnace;

const isAvailableAtLF = (availability: string) =>
                                availability === FluxAvailability.LadleFurnace || availability === FluxAvailability.TappingAndLadleFurnace;

const isFixedAdditionAmountAtTapping = (availability: string) =>
                                availability === FluxAvailability.FixedAdditionAtTapping;

const isFixedAdditionAmountAtLF = (availability: string) =>
                                availability === FluxAvailability.FixedAdditionAtLadleFurnace;

export { isAvailableAtTapping, isAvailableAtLF, isFixedAdditionAmountAtLF, isFixedAdditionAmountAtTapping};