import { UsersUpdateRequest, useUsersService } from '@app/api/users';
import Loading from '@app/components/shared/Loading';
import MainPaper from '@app/components/shared/MainPaper';
import SaveOnExitDialog from '@app/components/shared/SaveOnExitDialog';
import useLoadPlants from '@app/hooks/useLoadPlants';
import useLoadUsers from '@app/hooks/useLoadUsers';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { handleSendEvent } from '@app/utils';
import { PAGINATION, PERMISSIONS, UserRoles } from '@app/utils/constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { ROLES, getFilteredUsers } from '../userManagementUtils';
import UserManagementContent, { UserManagementFormInputs } from './UserManagementContent';
import FormButton from '@app/components/shared/FormButton';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useLoadCustomers from '@app/hooks/useLoadCustomers';
import { getStatusByItsTranslationKey } from '@app/components/shared/StatusComponent';

export interface FilterDTO extends Omit<UserManagementFormInputs, "customers"> {
  customers: Array<number>;
}

const UserManagementContainer: React.FC = () => {
  const { t } = useTranslation();

  const { updateUsers } = useUsersService();
  const { hasAccess, loadUserInfo, state: { userInfoObject } } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const [loading, setLoading] = useState(false);
  const [usersToChange, setUsersToChange] = useState<UsersUpdateRequest[]>([]);

  const [loadPlantsInfoObject, loadPlants] = useLoadPlants();
  const [loadUsersInfoObject, loadUsers] = useLoadUsers();



  const [filter, setFilter] = useState<FilterDTO>({
    search: '',
    plants: [],
    customers: [],
    roles: [],
    status: []
  });

  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE);

  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);

  const [loadCustomersInfoObject, loadCustomers] = useLoadCustomers();

  useEffect(() => {
    if(loadCustomersInfoObject.status === 'idle') {
      loadCustomers();
    }
  }, [loadCustomersInfoObject.status, loadCustomers]);

  const customersOptions = loadCustomersInfoObject?.data?.map(item => item.name) ?? [];

  const { data: plants, status: loadPlantsStatus } = loadPlantsInfoObject;
  const { data: users, status: loadUsersStatus } = loadUsersInfoObject;


  const methods = useForm<UserManagementFormInputs>({
    shouldFocusError: false,
    defaultValues: {
      search: '',
      plants: [],
      customers: [],
      roles: [],
      status: [],
    },
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    async function initialLoad() {
      if(userInfo?.userProfileName === UserRoles.PLANT_ADMIN) {
        await loadUsers();
      } else {
        await Promise.all([loadPlants(), loadUsers()]);
      }
    }

    if([loadPlantsStatus, loadUsersStatus].every(status => status === 'idle')) {
      initialLoad();
    }

  }, [
    loadPlants,
    loadUsers,
    loadUsersStatus,
    loadPlantsStatus,
    userInfo?.userProfileName
  ]);

  const renderHeaderActions = (): JSX.Element[] => {
    const actions: JSX.Element[] = [];

    if (hasAccess([PERMISSIONS.USER_MANAGEMENT_CREATE])) {
      actions.push((
        <FormButton
          type='button'
          key="user-management-invite-user-button"
          variant="primary"
          onClick={() => {
            handleSendEvent({
              category: 'Users/AdminPanel',
              action: 'User Opened Invite User Modal.',
            });
            setOpenInviteUserModal(true);
          }}>
          {t('invite-user').toUpperCase()}
        </FormButton>
      ));
    }

    return actions;
  }

  const renderFooterActions = (): JSX.Element[] => {
    const actions: JSX.Element[] = [];

    if (hasAccess([PERMISSIONS.USER_MANAGEMENT_EDIT])) {
      actions.push((
        <FormButton
          variant="secondary"
          disabled={usersToChange.length === 0}
          onClick={handleCancelChanges}
          key="admin-panel-cancel">
          {t('button.cancel')}
        </FormButton>),(
        <FormButton
          variant="primary"
          disabled={usersToChange.length === 0}
          onClick={() => handleSaveChanges(filter.search, filter.plants, filter.status, filter.roles, filter.customers, rowsPerPage, users?.page || 1)}
          key="admin-panel-save-changes">
          {t('button.save-changes')}
        </FormButton>
      ));
    }

    return actions;
  }

  const handleCancelChanges = () => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Canceled Changes in User Table.',
    });

    setUsersToChange([]);
  };

  const handleSaveChanges = async (
    filter?: string,
    plants?: string[],
    status?: string[],
    roles?: string[],
    customers?: number[],
    size: number = PAGINATION.ROWS_PER_PAGE,
    page?: number,
    sortingOrder?: string,
  ) => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Saved Changes in User Table.',
    });

    try {
      setLoading(true);

      const mappedUsers = usersToChange.map(utc => ({ ...utc, roleId: utc.userProfileId, plants: utc.plants }));
      await updateUsers(mappedUsers);

      await Promise.all([loadUsers(filter,plants,status, roles, customers,size,page,sortingOrder), loadUserInfo()]);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title:
          mappedUsers.length > 1
            ? t('admin-panel.change-users.notification')
            : t('admin-panel.change-user.notification'),
        showConfirmButton: false,
        timer: 2500,
        html: ''
      });

      setUsersToChange([]);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSaveOnExit = async (): Promise<boolean> => {
    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Saved Changes on Exit.',
    });

    let successful = false;

    try {
      await handleSaveChanges();
      successful = true;
    } catch (err) {
      successful = false;
    }

    return successful;
  };

  const onSubmit: SubmitHandler<UserManagementFormInputs> = async (data, event) => {
    event?.preventDefault();

    if (event?.target.id !== 'user-list-search-form') {
      return;
    }

    handleSendEvent({
      category: 'Users/AdminPanel',
      action: 'User Filtered the Users.',
    });

    const { customers, roles, status } = data;

    const parsedData = {
      ...data,
      plants: data.plants.map(plantName => plants.find(plant => plant.name === plantName)?.id ?? ''),
      status: status.map(item => getStatusByItsTranslationKey(item)),
      customers: customers.map(
        customerName => loadCustomersInfoObject.data.find(item => item.name === customerName)?.id as number
      ),
      roles: roles.map(roleName => ROLES.find(item => item.name === roleName)?.roleKey as string),
    }

    await loadUsers(
      parsedData.search,
      parsedData.plants,
      parsedData.status,
      parsedData.roles,
      parsedData.customers,
      rowsPerPage
    );

    setFilter(parsedData);

    reset(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id="user-list-search-form">
        <Loading dataTestId='user-management-circular-progress' promiseInProgress={loading || loadPlantsStatus === 'loading' || loadUsersStatus === 'loading'} />
        <SaveOnExitDialog shouldBlockNavigation={Boolean(usersToChange.length)} onSave={handleConfirmSaveOnExit} />
        <MainPaper
          removePadding
          title={t('admin-panel.user-management')}
          headerActions={renderHeaderActions()}
          footerActions={renderFooterActions()}>
            <UserManagementContent
              customersOptions={customersOptions}
              openInviteUserModal={openInviteUserModal}
              setOpenInviteUserModal={setOpenInviteUserModal}
              filter={filter}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              plants={plants}
              isPlantAdmin={userInfo?.userProfileName === UserRoles.PLANT_ADMIN}
              users={getFilteredUsers(users?.items || [], usersToChange)}
              page={users?.page || 1}
              total={users?.total || 0}
              loadUsers={loadUsers}
              setUsersToChange={(user: UsersUpdateRequest[]) => setUsersToChange(user)}
              usersToChange={usersToChange} />
        </MainPaper>
      </form>
    </FormProvider>
  );
};

export default UserManagementContainer;
