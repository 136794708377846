import { NOT_APPLICABLE_TEXT } from "./constants";

const hasNoValue = (value: string | number | null | undefined) => {
  return value === null || value === undefined || value === '';
}

const displayNotApplicableIfNull = (value: string | number | null | undefined, useEmptyTextForReplacement: boolean) => {
  if (hasNoValue(value)) {
    return useEmptyTextForReplacement ? '' : NOT_APPLICABLE_TEXT;
  }

  return value;
};

const displayNotApplicableIfZero = (value: string | number | null | undefined, useEmptyTextForReplacement: boolean) => {
  let workValue = value;

  if (typeof value === 'string') {
    workValue = parseFloat(value);
  }

  if (hasNoValue(workValue) || workValue === 0 || Number.isNaN(workValue)) {
    return useEmptyTextForReplacement ? '' : NOT_APPLICABLE_TEXT;
  }

  return value;
};
  
export { displayNotApplicableIfNull, displayNotApplicableIfZero };