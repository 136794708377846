import { useApi } from '..';

import { FluxModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';

export function useFluxesService() {
  const { get, remove, put, post } = useApi();
  const baseApi = `/v2/fluxes`;

  const getFluxes = async () => {
    const { data } = await get<FluxModel[]>(`${baseApi}`);

    return data;
  };

  const getFlux = async (id: number) => {
    const { data } = await get<FluxModel>(`${baseApi}/${id}`);

    return data;
  };

  interface DeleteFluxReturningType {
    message: string;
    status: number;
  }
  const deleteFlux = async (id: number) => {
    const { data } = await remove<DeleteFluxReturningType>(`${baseApi}/${id}`);

    return data;
  };

  const updateFlux = async (id: number, request: FluxModel) => {
    const { data } = await put(`${baseApi}/${id}`, request);

    return data;
  };

  const saveFlux = async (request: FluxModel) => {
    const { data } = await post(`${baseApi}`, request);

    return data;
  };

  return {
    getFluxes,
    getFlux,
    deleteFlux,
    updateFlux,
    saveFlux,
  };
}
