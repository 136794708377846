import Loading from '@app/components/shared/Loading';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { APP_TITLE, PERMISSIONS } from '@app/utils/constants';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import MuiTableHead from "@material-ui/core/TableHead";
import { ListInfoObject } from '@app/hooks/types';
import { DeoxidizersModel } from '@app/pages/Heats/HeatCalculation/Steps/heatCalculationTypes';
import { EditButton } from '@app/components/shared/CustomIconButton';

interface SlagDeoxidizersTabProps {
  handleEdit: (id: number) => void,
  slagDeoxidizersInfoObject: ListInfoObject<DeoxidizersModel>,
  loadSlagDeoxidizers: () => void,
}

const SlagDeoxidizersTab = (props: SlagDeoxidizersTabProps): React.ReactElement => {
  const { handleEdit, slagDeoxidizersInfoObject, loadSlagDeoxidizers } = props;
  const { t } = useTranslation();

  const { hasAccess } = useAuthenticationContext();
  const { data, status: slagDeoxidizersStatus } = slagDeoxidizersInfoObject;

  const TableHead = withStyles(() => ({
    root: {
      background: '#f1f1f1'
    }
  }))(MuiTableHead);

  useEffect(() => {
    if(slagDeoxidizersStatus === 'idle')
      loadSlagDeoxidizers();
  }, [slagDeoxidizersStatus, loadSlagDeoxidizers]);

  return (
    <>
      <Helmet>
        <title>
          {t('slag-deoxidizers')} | {APP_TITLE}
        </title>
      </Helmet>

      <Loading dataTestId='slag-deoxidizers-tab-circular-progress' promiseInProgress={slagDeoxidizersStatus === 'loading'} />

      <Paper>
        <TableContainer style={{ maxHeight: 'calc(100vh - 334px)' }}>
          <Table stickyHeader size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="left" width="300">
                  {t('name')}
                </TableCell>
                <TableCell align="left" width="300">
                  {t('slag-deoxidizers-enabled')}
                </TableCell>
                <TableCell align="left" width="150">
                  Si
                </TableCell>
                <TableCell align="left" width="150">
                  Al
                </TableCell>
                <TableCell align="left" width="150">
                  Ca
                </TableCell>
                <TableCell align="left" width="150">
                  C
                </TableCell>
                <TableCell align="left" width="200">
                  {t('slag-deoxidizers.carbon-deoxidation-efficiency')} (%)
                </TableCell>
                <TableCell align="left" width="200">
                  {t('slag-deoxidizers-efficiency-factor')} (%)
                </TableCell>
                <TableCell align="center" width="0"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody data-testid="slag-deoxidizers-list-table-body">
              {data.map((row, i) => (
                <TableRow key={i} data-testid={`slag-deoxidizers-list-item-${row.name}-${row.id}`}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">
                    {row.isEnabled ? t('option-yes') : t('option-no')}
                  </TableCell>
                  <TableCell align="left">{Number(row.si).toFixed(2)}</TableCell>
                  <TableCell align="left">{Number(row.al).toFixed(2)}</TableCell>
                  <TableCell align="left">{Number(row.ca).toFixed(2)}</TableCell>
                  <TableCell align="left">{Number(row.c).toFixed(2)}</TableCell>
                  <TableCell align="left">
                    {['FeSi', 'Al'].includes(row.name) ? '-' : row.carbonDeoxEfficiency}
                  </TableCell>
                  <TableCell align="left">{row.efficiencyFactor.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    {hasAccess([PERMISSIONS.SLAG_DEOXIDIZERS_EDIT]) && <EditButton data-testid={`slag-deoxidizer-edit-button-${row.name}-${row.id}`} onClick={() => handleEdit(Number(row.id))} />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default SlagDeoxidizersTab;
